import useExpertModePossible from './useExpertModePossible';
import useIsDisplayingChallengePortfolio from './useIsDisplayingChallengePortfolio';
import useIsExpertMode from './useIsExpertMode';
import useSelectedMode from './useSelectedMode';

const navigation = {
  useExpertModePossible,
  useIsDisplayingChallengePortfolio,
  useIsExpertMode,
  useSelectedMode
};

export default navigation;
