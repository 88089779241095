import PropTypes from 'prop-types';
import StyledNewlySharedInfo from './StyledNewlySharedInfo';
import MobileRowPicker from './MobileRowPicker';
import device from 'current-device';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const NewlySharedInfo = ({ challenge, ...props }) => (
  <>
    <StyledNewlySharedInfo {...props}>
      <FormattedMessageWithChallengeNaming
        id={
          device.desktop()
            ? 'portfolio.challenge.newly_shared_info'
            : 'portfolio.challenge.newly_shared_info.mobile'
        }
      />
    </StyledNewlySharedInfo>
    <MobileRowPicker challenge={challenge} />
  </>
);

NewlySharedInfo.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default NewlySharedInfo;
