import App from 'lib/App';

const useSendFeedbackToFeedbackServer = () => {
  const currentUser = App.authentication.useCurrentUser();

  const sendFeedbackToFeedbackServer = (feedback, includeEmail) => {
    const requestBody = {
      feedback
    };

    if (includeEmail) {
      requestBody.sender_email = currentUser?.email;
    }

    fetch('https://feedback.egodact.com/feedback/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify(requestBody)
    });
  };

  return sendFeedbackToFeedbackServer;
};

export default useSendFeedbackToFeedbackServer;
