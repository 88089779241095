/* eslint react/jsx-no-duplicate-props: "off" */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextFieldWrapper from './TextFieldWrapper';
import { TextField, InputAdornment } from '@material-ui/core';
import ColorPreview from './ColorPreview';
import defaultColors from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/defaultColors';

const CustomColorTextField = ({ selectedColor, onSelectColor, ...props }) => {
  const isCustomColor = !defaultColors.includes(selectedColor);
  const originalCustomColor = isCustomColor ? selectedColor : '';
  const [hexColorCode, setHexColorCode] = useState(
    originalCustomColor && originalCustomColor.replace('#', '')
  );
  const [previewColor, setPreviewColor] = useState(originalCustomColor);

  useEffect(() => {
    const selectedCustomColor = isCustomColor ? selectedColor : '';
    setHexColorCode(
      selectedCustomColor && selectedCustomColor.replace('#', '')
    );
    setPreviewColor(selectedCustomColor);
  }, [selectedColor, isCustomColor]);

  const handleChange = e => {
    const value = e.target.value;
    const valueAsValidHexColorCode = value
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, '')
      .slice(0, 6);
    setHexColorCode(valueAsValidHexColorCode);
    setPreviewColor(null);

    const isFullHexColorCode = valueAsValidHexColorCode.length === 6;
    if (isFullHexColorCode) {
      const hexColorCodeWithHashtag = `#${valueAsValidHexColorCode}`;
      setPreviewColor(hexColorCodeWithHashtag);
      onSelectColor(hexColorCodeWithHashtag);
    }
  };

  return (
    <TextFieldWrapper {...props}>
      <TextField
        margin="dense"
        variant="outlined"
        placeholder={selectedColor.replace('#', '')}
        value={hexColorCode}
        onChange={handleChange}
        inputProps={{
          style: {
            paddingTop: 10.5,
            paddingBottom: 10.5
          }
        }}
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>
        }}
        style={{
          margin: 0,
          flex: 1
        }}
      />
      {previewColor && <ColorPreview color={previewColor} />}
    </TextFieldWrapper>
  );
};

CustomColorTextField.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  noBackground: PropTypes.bool
};

CustomColorTextField.defaultProps = {
  noBackground: false
};

export default CustomColorTextField;
