import NotFoundTitle from 'lib/components/NotFoundTitle';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import CreateSloPeriod from '../../SloSystemHeader/CreateSloPeriod';
import { FormattedMessage } from 'react-intl';

const NoSloPeriods = () => (
  <NotFoundTitle>
    <FormattedMessage id="slo_system.no_slo_periods" />
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <Space height={12} />
      <CreateSloPeriod variant="text" />
    </RestrictedToPortfolioMode>
  </NotFoundTitle>
);

export default NoSloPeriods;
