import { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import { ref } from 'lib/firebase';
import { extractUiPos, getUpdatedUiPos, removeOldUiPos } from './uiPosUtils';

const useMakeDraggable = (
  type,
  item,
  previousItem,
  nextItem,
  highestUiPos,
  path,
  parentKey,
  parentUiPos,
  updateParentAfterDrag,
  horizontal = false
) => {
  const dragRef = useRef(null);

  const performDrag = (draggedItem, draggingUp) => {
    const itemUiPos = extractUiPos(item, parentKey);
    const previousItemUiPos = extractUiPos(previousItem, parentKey) || 0;
    const nextItemUiPos = extractUiPos(nextItem, parentKey) || -1;

    let newUiPos = draggingUp
      ? (itemUiPos + previousItemUiPos) / 2
      : (itemUiPos + nextItemUiPos) / 2;

    if (nextItemUiPos === -1) {
      newUiPos = Math.ceil(highestUiPos) + 1;
    }

    const uiPosRef = ref(`portfolioTemplates/${path}/${draggedItem.id}/uiPos`);

    const newUiPosObject = removeOldUiPos(
      getUpdatedUiPos(draggedItem.uiPos, parentKey, newUiPos),
      draggedItem.parentKey,
      parentKey
    );

    uiPosRef.set(newUiPosObject);

    if (
      draggedItem.updateParentAfterDrag &&
      draggedItem.parentKey !== parentKey
    ) {
      const oldParentKey = draggedItem.parentKey;
      const newParentKey = parentKey;
      draggedItem.updateParentAfterDrag(oldParentKey, newParentKey);
    }
  };

  const [, drop] = useDrop({
    accept: type,
    drop: (draggedItem, monitor) => {
      if (draggedItem.id === item.id) {
        return;
      }

      const itemUiPos = extractUiPos(item, parentKey);
      const draggedItemUiPos = extractUiPos(draggedItem, draggedItem.parentKey);

      const draggingUp =
        draggedItem.parentKey === parentKey
          ? draggedItemUiPos > itemUiPos
          : draggedItem.parentUiPos < parentUiPos;
      const draggingDown =
        draggedItem.parentKey === parentKey
          ? draggedItemUiPos < itemUiPos
          : draggedItem.parentUiPos > parentUiPos;

      if (draggedItem.parentKey !== parentKey) {
        performDrag(draggedItem, draggingUp);
        return;
      }

      if (
        (draggingUp && draggedItem.id === previousItem.id) ||
        (draggingDown && draggedItem.id === nextItem.id)
      ) {
        return;
      }

      performDrag(draggedItem, draggingUp);
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type,
      ...item,
      parentKey,
      parentUiPos,
      updateParentAfterDrag
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(dragRef));
  return [isDragging, dragRef];
};

export default useMakeDraggable;
