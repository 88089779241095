import PropTypes from 'prop-types';
import CreateChallengeOrTask from './CreateChallengeOrTask';
import Challenge from '../../Challenge';
import usePhaseDrop from './usePhaseDrop';
import { rowRef, challengeRef } from 'lib/firebase';
import PhaseContext from '../../PhaseContext';
import Phases from 'lib/constants/Phases';
import PhaseColumn from './PhaseColumn';
import PhaseLoadingSpinner from './PhaseLoadingSpinner';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const KanbanPhase = ({ phase, row, inTaskBoard, collapsed, ...props }) => {
  const attachDropHandlingRef = usePhaseDrop(phase, row, inTaskBoard);

  const childKeysRef = inTaskBoard
    ? challengeRef(row.key).child(`tasks/${phase}`)
    : rowRef(row.key).child(`challenges/${phase}`);
  const [loading, keysObject] = useFirebaseValue(childKeysRef);
  const keys = safeKeys(keysObject);

  return (
    <PhaseContext.Provider value={phase}>
      <PhaseColumn
        hasItems={loading || keys.length > 0}
        inTaskBoard={inTaskBoard}
        collapsed={collapsed}
        ref={attachDropHandlingRef}
        {...props}
      >
        {loading ? (
          <PhaseLoadingSpinner collapsed={collapsed} />
        ) : (
          <>
            {keys.map(key => (
              <Challenge
                challengeKey={key}
                asTask={inTaskBoard}
                row={row}
                collapsed={collapsed}
                key={key}
              />
            ))}
            {!collapsed && phase === Phases.TODO && (
              <CreateChallengeOrTask
                parent={row}
                useTaskVariant={inTaskBoard}
              />
            )}
          </>
        )}
      </PhaseColumn>
    </PhaseContext.Provider>
  );
};

KanbanPhase.propTypes = {
  phase: PropTypes.string.isRequired,
  row: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired,
  inTaskBoard: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default KanbanPhase;
