import PropTypes from 'prop-types';
import StyledText from './StyledText';
import getDateText from './getDateText';

const DateText = ({ timestamp, selectedWeek, ...props }) => (
  <StyledText>{getDateText(timestamp, selectedWeek)}</StyledText>
);

DateText.propTypes = {
  timestamp: PropTypes.string,
  selectedWeek: PropTypes.number.isRequired
};

export default DateText;
