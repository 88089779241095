import useAppStatePopulator from './useAppStatePopulator';
import AppStateContext from 'lib/contexts/AppStateContext';

const StatePopulator = props => {
  const [appStateLoading, appState] = useAppStatePopulator({
    firebaseRefs: [
      'instanceConfig/allowedEmails',
      'instanceConfig/allowedEmailPrefix',
      'instanceConfig/authProvider',
      'instanceConfig/defaultPlatform',
      'instanceConfig/contentSettings',
      'models'
    ]
  });

  return (
    <AppStateContext.Provider
      value={{
        isLoading: appStateLoading,
        ...appState
      }}
      {...props}
    />
  );
};

export default StatePopulator;
