import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ActionsWrapper = styled('footer')({
  display: 'flex',
  [mediaQuery(460)]: {
    display: 'block'
  }
});

export default ActionsWrapper;
