import PropTypes from 'prop-types';
import StyledChallengeName from './StyledChallengeName';

const ChallengeName = ({ challenge, ...props }) => {
  const hasCoachCommentsBadge =
    !!challenge.unreadCoachCommentsCount &&
    challenge.unreadCoachCommentsCount > 0;

  return (
    <StyledChallengeName accountForBadge={hasCoachCommentsBadge} {...props}>
      {challenge.name}
    </StyledChallengeName>
  );
};

ChallengeName.propTypes = {
  challenge: PropTypes.shape({
    name: PropTypes.string.isRequired,
    unreadCoachCommentsCount: PropTypes.number
  }).isRequired
};

export default ChallengeName;
