import App from 'lib/App';
import StyledOutdatedBrowserWarning from './StyledOutdatedBrowserWarning';
import { FormattedMessage } from 'react-intl';

const OutdatedBrowserWarning = props => {
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();
  return (
    <StyledOutdatedBrowserWarning
      wideContent={isDisplayingChallengePortfolio}
      {...props}
    >
      <FormattedMessage id="outdated_browser_detection.warning" />
    </StyledOutdatedBrowserWarning>
  );
};

export default OutdatedBrowserWarning;
