const getProgressFromPortfolio = (portfolio, partId, partSubpath) => {
  const progressForPart = portfolio?.progress?.[partId];

  if (partSubpath) {
    return progressForPart?.[partSubpath];
  }

  return progressForPart;
};

export default getProgressFromPortfolio;
