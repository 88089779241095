import isBrowserOutdated from 'components/App/OutdatedBrowserDetection/isBrowserOutdated';
import OutdatedBrowserMessage from './OutdatedBrowserMessage';
import { FormattedMessage } from 'react-intl';

const OutdatedBrowserDetection = props => {
  if (!isBrowserOutdated()) {
    return null;
  }

  return (
    <OutdatedBrowserMessage>
      <FormattedMessage id="outdated_browser_detection.warning" />
    </OutdatedBrowserMessage>
  );
};

export default OutdatedBrowserDetection;
