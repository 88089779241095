import DialogActionsWrapper from './DialogActionsWrapper';
import DialogButton from './buttons/DialogButton';
import DialogCancelButton from './buttons/DialogCancelButton';
import DialogOkButton from './buttons/DialogOkButton';
import DialogDeleteButton from './buttons/DialogDeleteButton';
import DialogDoneButton from './buttons/DialogDoneButton';
import DialogSaveButton from './buttons/DialogSaveButton';
import DialogCreateButton from './buttons/DialogCreateButton';

const DialogActions = DialogActionsWrapper;

DialogActions.Wrapper = DialogActionsWrapper;
DialogActions.Button = DialogButton;
DialogActions.CancelButton = DialogCancelButton;
DialogActions.OkButton = DialogOkButton;
DialogActions.DeleteButton = DialogDeleteButton;
DialogActions.DoneButton = DialogDoneButton;
DialogActions.SaveButton = DialogSaveButton;
DialogActions.CreateButton = DialogCreateButton;

export default DialogActions;
