import PropTypes from 'prop-types';
import useProof from './useProof';
import ProofMapper from './ProofMapper';
import PillButtons from './PillButtons';
import { Link as LinkIcon } from '@material-ui/icons';

const PillButtonAsLink = PillButtons.Button.withComponent('a');

const LevelProofReadOnly = ({ levelId, proofSubpath, ...props }) => {
  const proof = useProof(levelId, proofSubpath);

  if (!proof) {
    return null;
  }

  return (
    <ProofMapper proof={proof}>
      {([individualProofLink], i) => (
        <PillButtonAsLink
          href={individualProofLink}
          target="_blank"
          standAlone
          key={i}
          {...props}
        >
          <LinkIcon />
        </PillButtonAsLink>
      )}
    </ProofMapper>
  );
};

LevelProofReadOnly.propTypes = {
  levelId: PropTypes.string.isRequired,
  proofSubpath: PropTypes.string
};

export default LevelProofReadOnly;
