import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/react';
import { FormControlLabel } from '@material-ui/core';
import ResponsiveRadio from './ResponsiveRadio';
import RadioLabel from './RadioLabel';
import LabelText from './LabelText';
import mediaQuery from 'lib/styling/mediaQuery';

const RowAttachmentTypeOption = ({ selected, children, ...props }) => (
  <ClassNames>
    {({ css }) => (
      <FormControlLabel
        control={<ResponsiveRadio color="primary" />}
        classes={{
          root: css({
            marginRight: 0,
            [mediaQuery(420)]: {
              marginBottom: 16,
              alignItems: 'flex-start'
            }
          }),
          label: css({ flex: 1 })
        }}
        label={<RadioLabel selected={selected}>{children}</RadioLabel>}
        {...props}
      />
    )}
  </ClassNames>
);

RowAttachmentTypeOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  children: PropTypes.node
};

RowAttachmentTypeOption.LabelText = LabelText;

export default RowAttachmentTypeOption;
