import styled from '@emotion/styled';
import StyledIndicatorLabel from '../../ExpertProgressIndicator/IndicatorLabel/StyledLabel';
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledIndicatorLabelAsButton = StyledIndicatorLabel.withComponent(
  'button'
);

const ViewAllButton = styled(StyledIndicatorLabelAsButton)(
  {
    paddingTop: 0,
    paddingBottom: 0,
    height: 25,
    color: common.black,
    backgroundColor: grey[200],
    borderColor: grey[400],
    font: 'inherit',
    fontSize: 14,
    cursor: 'pointer',
    appearance: 'none',
    ':hover, :focus': {
      color: common.white,
      backgroundColor: grey[600]
    }
  },
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.background.default,
    backgroundColor: theme.palette.divider,
    borderColor: theme.palette.divider,
    ':hover, :focus': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default
    }
  }))
);

export default ViewAllButton;
