import { ref, value } from 'lib/firebase';
import deleteChildren from './deleteChildren';
import deleteSubtopic from './deleteSubtopic';

const deleteTopic = async id => {
  const topicRef = ref(`portfolioTemplates/topics/${id}`);
  const topic = await value(topicRef);

  if (!topic) {
    return;
  }

  deleteChildren(topic.subtopics, deleteSubtopic);
  topicRef.remove();
};

export default deleteTopic;
