import { useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import { rowRef, challengeRef, userRef } from 'lib/firebase';
import Phases from 'lib/constants/Phases';
import Dialog from 'lib/components/Dialog';
import PickableRows from './PickableRows';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const RowPicker = ({ open, challenge, onClose, ...props }) => {
  const [selectedRow, setSelectedRow] = useState(null);

  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const saveRowChoice = async () => {
    onClose();

    if (!selectedRow) {
      return;
    }

    const challengePhase = challenge.phase || Phases.TODO;
    await Promise.all([
      rowRef(selectedRow)
        .child(`challenges/${challengePhase}/${challenge.key}`)
        .set(true),
      challengeRef(challenge.key).update({
        phase: challengePhase,
        [`row/${currentUserUsername}`]: selectedRow
      })
    ]);

    userRef(currentUserUsername).child(`newlyShared/${challenge.key}`).remove();
  };

  return (
    <Dialog open={open} onClose={onClose} onEnterClose={onClose}>
      <Dialog.Title>
        <FormattedMessage id="portfolio.challenge.newly_shared.row_picker.title" />
      </Dialog.Title>
      <Dialog.Content style={{ overflowY: 'visible' }}>
        <Dialog.ContentText>
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.newly_shared.row_picker.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <PickableRows
        selectedRow={selectedRow}
        onSelectRow={row => setSelectedRow(row)}
      />
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={onClose} />
        <Dialog.Actions.SaveButton onClick={saveRowChoice} />
      </Dialog.Actions>
    </Dialog>
  );
};

RowPicker.propTypes = {
  open: PropTypes.bool.isRequired,
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    phase: PropTypes.oneOf(Object.values(Phases))
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default RowPicker;
