const getTileFontSize = tileWidth => {
  if (!tileWidth || tileWidth <= 1) {
    return null;
  }

  const fontSizeIncrease = (tileWidth / 560) * 12;
  const fontSize = 12 + fontSizeIncrease;
  const safeFontSize = Math.max(12, Math.min(fontSize, 24));
  return safeFontSize;
};

export default getTileFontSize;
