import useCurrentFieldRubricsRef from './useCurrentFieldRubricsRef';
import EditorPart from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/EditorPart';

const EditablePart = props => {
  const currentFieldRubricsRef = useCurrentFieldRubricsRef();
  const removePartFromField = partKey =>
    currentFieldRubricsRef.child(partKey).remove();

  return (
    <EditorPart
      subtopicKey="__IGNORE__"
      subtopicUiPos={1}
      noGroups
      noCopy
      onDelete={part => removePartFromField(part.id)}
      {...props}
    />
  );
};

export default EditablePart;
