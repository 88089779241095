import PropTypes from 'prop-types';
import { challengeRef } from 'lib/firebase';
import AvatarList from './AvatarList';
import getChallengeUsers from '../../getChallengeUsers';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const TaskAvatars = ({ task, ...props }) => {
  const [loading, challenge] = useFirebaseValue(challengeRef(task.challenge));

  if (loading) {
    return null;
  }

  const challengeUsers = getChallengeUsers(challenge);

  if (challengeUsers.length <= 1) {
    return null;
  }

  const assigneeUsernames = safeKeys(task.assignees)
    .filter(assignee => challengeUsers.includes(assignee))
    .slice(0, 4);

  return <AvatarList usernames={assigneeUsernames} {...props} />;
};

TaskAvatars.propTypes = {
  task: PropTypes.shape({
    challenge: PropTypes.string.isRequired,
    assignees: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default TaskAvatars;
