import sortByName from '../../sortByName';
import { safeValues } from 'lib/utils/safeObjectFunctions';

const getSortedVisibleUsers = (users, selectedGroupKey, currentUser) => {
  const visibleUsers = safeValues(users)
    .filter(({ email }) => email !== currentUser.email)
    .sort(sortByName);

  if (!selectedGroupKey) {
    return visibleUsers;
  }

  return visibleUsers.filter(({ groups }) => !!groups?.[selectedGroupKey]);
};

export default getSortedVisibleUsers;
