import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import App from 'lib/App';
import AddLogEntryButton from './AddLogEntryButton';
import NewLogEntryForm from './NewLogEntryForm';

const AddLogEntry = ({ logEntryName, ...props }) => {
  const { fieldRef } = useContext(FieldContext);
  const [newLogEntryFormOpen, setNewLogEntryFormOpen] = useState(false);

  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const saveNewLogEntry = logEntry => {
    setNewLogEntryFormOpen(false);
    fieldRef.push().set({
      content: logEntry,
      timestamp: Date.now(),
      user: currentUserUsername
    });
  };

  if (!newLogEntryFormOpen) {
    return (
      <AddLogEntryButton
        logEntryName={logEntryName}
        onClick={() => setNewLogEntryFormOpen(true)}
        {...props}
      />
    );
  }

  return (
    <NewLogEntryForm
      logEntryName={logEntryName}
      onSave={saveNewLogEntry}
      onCancel={() => setNewLogEntryFormOpen(false)}
    />
  );
};

AddLogEntry.propTypes = {
  logEntryName: PropTypes.string.isRequired
};

export default AddLogEntry;
