import { useState } from 'react';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';
import NotResponsiveAlert from './NotResponsiveAlert';
import MoveModeContext from './MoveModeContext';
import TabsWrapper from './TabsWrapper';
import SelectedModelTypeTabs from './SelectedModelTypeTabs';
import MoveModeButton from './MoveModeButton';
import ModelEditorForModelType from './ModelEditorForModelType';
import Space from 'lib/components/Space';

const ChallengeModelEditor = props => {
  const [selectedModelType, setSelectedModelType] = useState(
    ModelTypes.CHALLENGE
  );
  const [inMoveMode, setInMoveMode] = useState(false);

  return (
    <section {...props}>
      <NotResponsiveAlert />
      <MoveModeContext.Provider value={inMoveMode}>
        <TabsWrapper>
          <SelectedModelTypeTabs
            selectedModelType={selectedModelType}
            setSelectedModelType={setSelectedModelType}
          />
          <MoveModeButton onClick={() => setInMoveMode(!inMoveMode)} />
        </TabsWrapper>
        <ModelEditorForModelType selectedModelType={selectedModelType} />
      </MoveModeContext.Provider>
      <Space height={10} />
    </section>
  );
};

export default ChallengeModelEditor;
