import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledPageTitle = styled('h3')({
  marginLeft: 24,
  [mediaQuery(840)]: {
    display: 'none'
  }
});

export default StyledPageTitle;
