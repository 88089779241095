import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const UserEditorHeader = styled('header')({
  marginTop: 24,
  marginBottom: 18,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(768)]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
});

export default UserEditorHeader;
