import PropTypes from 'prop-types';
import App from 'lib/App';
import useShouldDisplayAdminControls from './useShouldDisplayAdminControls';
import useIsDisplayingLoginScreen from './useIsDisplayingLoginScreen';
import Modes from 'lib/constants/Modes';
import StyledHeader from './StyledHeader';
import HeaderLogo from './HeaderLogo';
import HeaderPageTitle from './HeaderPageTitle';
import HeaderAdminControls from './HeaderAdminControls';
import UserSwitcher from './UserSwitcher';
import CoachModeButton from './CoachModeButton';
import ModeSwitch from './ModeSwitch';
import HeaderActions from './HeaderActions';
import SloSystem from './SloSystem';
import EnsureChallengePortfolioControlsDesired from './EnsureChallengePortfolioControlsDesired';
import ChallengeSearch from './ChallengeSearch';
import PlanningViewToggleButton from './PlanningViewToggleButton';
import PlatformSwitch from './PlatformSwitch';
import OptionsMenu from './OptionsMenu';
import ResponsivityGreyBar from './ResponsivityGreyBar';

const AppHeader = ({
  setChallengeSearchQuery,
  selectedUser,
  setSelectedUser,
  ...props
}) => {
  const selectedMode = App.navigation.useSelectedMode();
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();
  const shouldDisplayAdminControls = useShouldDisplayAdminControls();

  const isDisplayingLoginScreen = useIsDisplayingLoginScreen();
  if (isDisplayingLoginScreen) {
    return null;
  }

  const inCoachMode = selectedMode === Modes.COACH;

  return (
    <StyledHeader
      inCoachMode={inCoachMode}
      wideContent={isDisplayingChallengePortfolio}
      {...props}
    >
      <HeaderLogo />
      <HeaderPageTitle />
      {shouldDisplayAdminControls && (
        <HeaderAdminControls
          inCoachMode={inCoachMode}
          wideContent={isDisplayingChallengePortfolio}
        >
          {inCoachMode && (
            <UserSwitcher
              selectedUser={selectedUser}
              onSelectUser={user => setSelectedUser(user)}
            />
          )}
          <CoachModeButton />
          <ModeSwitch />
        </HeaderAdminControls>
      )}
      <HeaderActions>
        <EnsureChallengePortfolioControlsDesired>
          <ChallengeSearch
            onSearch={searchQuery => setChallengeSearchQuery(searchQuery)}
          />
          <PlanningViewToggleButton />
        </EnsureChallengePortfolioControlsDesired>
        <SloSystem />
        <PlatformSwitch />
        <OptionsMenu />
      </HeaderActions>
      {shouldDisplayAdminControls && (
        <ResponsivityGreyBar inCoachMode={inCoachMode} />
      )}
    </StyledHeader>
  );
};

AppHeader.propTypes = {
  setChallengeSearchQuery: PropTypes.func.isRequired,
  selectedUser: PropTypes.object,
  setSelectedUser: PropTypes.func.isRequired
};

export default AppHeader;
