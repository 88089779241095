import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledForm from './StyledForm';
import StyledTextField from './StyledTextField';
import { IconButton } from '@material-ui/core';
import { PersonAdd as AddUserIcon } from '@material-ui/icons';

const InnerForm = ({ error, onInvite, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (error) {
      inputRef.current.focus();
    }
  }, [error]);

  return (
    <StyledForm onSubmit={onInvite}>
      <StyledTextField
        error={!!error}
        helperText={error}
        inputRef={inputRef}
        fullWidth
        {...props}
      />
      <IconButton type="submit" disabled={!props.value.trim()}>
        <AddUserIcon />
      </IconButton>
    </StyledForm>
  );
};

InnerForm.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.node,
  onInvite: PropTypes.func.isRequired
};

export default InnerForm;
