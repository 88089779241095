import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { rowRef } from 'lib/firebase';
import RowWrapper from './RowWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import { ListItemText } from '@material-ui/core';
import Space from 'lib/components/Space';
import { KeyboardArrowRight as ArrowRightIcon } from '@material-ui/icons';

const SelectableRow = ({ rowKey, onSelectRow, ...props }) => {
  const [loading, row] = useFirebaseValue(rowRef(rowKey));

  if (!loading && !row.challenges) {
    return null;
  }

  return (
    <RowWrapper
      button={!loading}
      onClick={() => !loading && onSelectRow(row)}
      {...props}
    >
      {loading ? (
        <LoadingSpinner margin={4} size={20} centered />
      ) : (
        <>
          <ListItemText primary={row.name} />
          <Space width="auto" />
          <ArrowRightIcon />
        </>
      )}
    </RowWrapper>
  );
};

SelectableRow.propTypes = {
  rowKey: PropTypes.string.isRequired,
  onSelectRow: PropTypes.func.isRequired
};

export default SelectableRow;
