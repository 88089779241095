import PropTypes from 'prop-types';
import KanbanPhasesHeader from '../../KanbanPhasesHeader';
import ChallengeBoard from '../../ChallengeBoard';

const TaskBoard = ({ challenge, ...props }) => (
  <>
    <KanbanPhasesHeader inTaskBoard />
    <ChallengeBoard row={challenge} asTaskBoard {...props} />
  </>
);

TaskBoard.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default TaskBoard;
