import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import NotSharedText from './NotSharedText';
import InviteUserForm from './InviteUserForm';
import ParticipatingUsers from './ParticipatingUsers';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const ChallengeSharing = () => {
  const { challenge } = useContext(FieldContext);
  const challengeIsShared = safeKeys(challenge.sharedWith).length > 0;
  return (
    <section>
      <FieldName />
      {challengeIsShared ? <ParticipatingUsers /> : <NotSharedText />}
      <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
        <InviteUserForm />
      </RestrictedToPortfolioMode>
    </section>
  );
};

export default ChallengeSharing;
