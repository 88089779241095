import './fonts/circular-std.css';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/auth';
import 'css/base-styles.css';

if (firebase.apps.length === 0) {
  firebase.initializeApp(global.firebaseConfig);
}

ReactDOM.render(<App />, document.getElementById('app'));
