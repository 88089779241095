import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import VisiblePortfolioTemplatesGroupContext from '../../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from '../UseAggressiveRubricCollapsingContext';
import EditorSubtopic from '../EditorSubtopic';
import AddSubtopic from '../AddSubtopic';
import sortByUiPos from 'lib/utils/sortByUiPos';
import itemMatchesVisibleGroup from 'lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import getHighestUiPosFromSortedItems from 'pages/EditorPage/utils/getHighestUiPosFromSortedItems';
import TopicWithDraggingTransparency from './TopicWithDraggingTransparency';
import TopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledTopic/TopicTitleWrapper';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import TopicContent from 'components/RubricsPortfolioComponents/StyledTopic/TopicContent';
import EditTopic from './EditTopic';
import DeleteTopic from './DeleteTopic';
import useMakeDraggable from '../useMakeDraggable';
import PortfolioDragTypes from '../PortfolioDragTypes';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const EditorTopic = ({
  topic,
  previousTopic,
  nextTopic,
  highestUiPos,
  ...props
}) => {
  const [isDragging, dragRef] = useMakeDraggable(
    PortfolioDragTypes.TOPIC,
    topic,
    previousTopic,
    nextTopic,
    highestUiPos,
    'topics'
  );

  const aggressiveRubricCollapsingEnabled = useContext(
    UseAggressiveRubricCollapsingContext
  );
  const collapsedByDefault = aggressiveRubricCollapsingEnabled;
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const allSubtopics = portfolioTemplates?.subtopics;
  const desiredSubtopicsKeys = safeKeys(topic.subtopics);
  const subtopics = desiredSubtopicsKeys
    .map(subtopicKey => allSubtopics?.[subtopicKey])
    .filter(subtopic => !!subtopic)
    .sort(sortByUiPos);

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleSubtopics = subtopics.filter(
    itemMatchesVisibleGroup(visibleGroup)
  );

  const highestSubtopicUiPos = getHighestUiPosFromSortedItems(subtopics);

  const groups = safeKeys(topic.groups);

  return (
    <TopicWithDraggingTransparency
      collapsed={collapsed}
      dragging={isDragging}
      {...props}
      ref={dragRef}
    >
      <TopicTitleWrapper editor>
        <h2>{topic.title}</h2>
        <EditTopic topic={topic} />
        <DeleteTopic topic={topic} />
        <CollapseButton
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      </TopicTitleWrapper>
      <TopicContent collapsed={collapsed}>
        {visibleSubtopics.map((subtopic, i) => (
          <EditorSubtopic
            subtopic={subtopic}
            previousSubtopic={visibleSubtopics[i - 1] || {}}
            nextSubtopic={visibleSubtopics[i + 1] || {}}
            highestUiPos={highestSubtopicUiPos}
            topicUiPos={topic.uiPos}
            editor
            key={subtopic.id}
          />
        ))}
        <AddSubtopic
          topicId={topic.id}
          topicGroups={groups}
          highestSubtopicUiPos={highestSubtopicUiPos}
        />
      </TopicContent>
    </TopicWithDraggingTransparency>
  );
};

EditorTopic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.objectOf(PropTypes.bool),
    dynamicGroups: PropTypes.objectOf(PropTypes.bool),
    uiPos: PropTypes.number.isRequired,
    subtopics: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  previousTopic: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  nextTopic: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  highestUiPos: PropTypes.number.isRequired
};

export default EditorTopic;
