import { useContext } from 'react';
import PropTypes from 'prop-types';
import ChallengeInfoContext from '../../../../ChallengeInfoContext';
import Space from 'lib/components/Space';
import { Tooltip } from '@material-ui/core';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const ChallengeUsedAsProofIndicator = ({ part, ...props }) => {
  const { asTask } = useContext(ChallengeInfoContext);

  const challengeUsedAsProof = part.challengeAttached;
  if (!challengeUsedAsProof) {
    return null;
  }

  return (
    <>
      <Space width={9} />
      <Tooltip
        title={
          <FormattedMessageWithChallengeNaming
            id={
              asTask
                ? 'portfolio.challenge.learning_goals.task_used_as_proof'
                : 'portfolio.challenge.learning_goals.challenge_used_as_proof'
            }
          />
        }
      >
        <CheckIcon {...props} />
      </Tooltip>
    </>
  );
};

ChallengeUsedAsProofIndicator.propTypes = {
  part: PropTypes.shape({
    levels: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default ChallengeUsedAsProofIndicator;
