import { ref } from 'lib/firebase';

const copyIndividualLevelToPartCopy = (level, partId, highestLevelUiPos) => {
  const id = ref('portfolioTemplates/levels').push().key;

  ref(`portfolioTemplates/levels/${id}`).set({
    partId,
    id,
    title: level.title,
    uiPos: highestLevelUiPos + 1,
    description: level.description
  });

  ref(`portfolioTemplates/parts/${partId}/levels/${id}`).set(true);
};

const copyLevelsToPartCopy = (levels, partId) =>
  levels.map((level, i) => copyIndividualLevelToPartCopy(level, partId, i));

export default copyLevelsToPartCopy;
