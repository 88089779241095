import { useState } from 'react';
import PropTypes from 'prop-types';
import PartPromptWithoutGroups from './PartPromptWithoutGroups';
import PartPromptWithGroups from './PartPromptWithGroups';
import { ref } from 'lib/firebase';
import arrToObj from 'lib/utils/arrToObj';
import EditButton from '../../PortfolioTemplatesEditorActionButtons/EditButton';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const EditPart = ({ part, noGroups, ...props }) => {
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const partRef = ref(`portfolioTemplates/parts/${part.id}`);

  const saveEdits = (title, groups, dynamicGroups) => {
    setEditPromptOpen(false);

    if (noGroups) {
      partRef.child('title').set(title);
      return;
    }

    partRef.update({
      title,
      groups: arrToObj(groups, true),
      dynamicGroups: arrToObj(dynamicGroups, true)
    });
  };

  const PartPrompt = noGroups ? PartPromptWithoutGroups : PartPromptWithGroups;

  return (
    <>
      <EditButton onClick={() => setEditPromptOpen(true)} {...props} />
      <PartPrompt
        open={editPromptOpen}
        defaultValue={part.title}
        selectedStandardGroups={safeKeys(part.groups)}
        selectedGroups={safeKeys(part.dynamicGroups)}
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
      />
    </>
  );
};

EditPart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.objectOf(PropTypes.bool),
    dynamicGroups: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  noGroups: PropTypes.bool.isRequired
};

export default EditPart;
