import { ref } from 'lib/firebase';

const updateCompoundPartSubtopicLink = (
  part,
  oldSubtopicKey,
  newSubtopicKey
) => {
  ref(
    `portfolioTemplates/parts/${part.id}/subtopicId/${oldSubtopicKey}`
  ).remove();

  ref(`portfolioTemplates/parts/${part.id}/subtopicId/${newSubtopicKey}`).set(
    true
  );
};

const updateSimplePartSubtopicLink = (part, newSubtopicKey) => {
  ref(`portfolioTemplates/parts/${part.id}/subtopicId`).set(newSubtopicKey);
};

const updateSubtopicPartLinks = (oldSubtopicKey, newSubtopicKey, part) => {
  ref(
    `portfolioTemplates/subtopics/${oldSubtopicKey}/parts/${part.id}`
  ).remove();

  ref(`portfolioTemplates/subtopics/${newSubtopicKey}/parts/${part.id}`).set(
    true
  );
};

const updatePartSubtopicAttachmentAfterDrag = (
  part,
  oldSubtopicKey,
  newSubtopicKey
) => {
  if (
    typeof part.subtopicId === 'object' &&
    oldSubtopicKey !== newSubtopicKey
  ) {
    updateCompoundPartSubtopicLink(part, oldSubtopicKey, newSubtopicKey);
  }

  if (typeof part.subtopicId !== 'object') {
    updateSimplePartSubtopicLink(part, newSubtopicKey);
  }

  updateSubtopicPartLinks(oldSubtopicKey, newSubtopicKey, part);
};

export default updatePartSubtopicAttachmentAfterDrag;
