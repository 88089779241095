import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ChallengeName from '../ChallengeName';
import InputWithEscListener from 'lib/components/InputWithEscListener';
import useFormatMessageWithChallengeNaming from 'lib/utils/useFormatMessageWithChallengeNaming';

const ChallengeNameAsInput = ChallengeName.withComponent(InputWithEscListener);

const StyledChallengeNameInput = styled(ChallengeNameAsInput)(({ theme }) => ({
  marginRight: 0,
  width: '100%',
  flex: 1,
  fontFamily: 'CircularStd'
}));

const ChallengeNameInput = ({ asTask, ...props }) => {
  const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();
  return (
    <StyledChallengeNameInput
      placeholder={formatMessageWithChallengeNaming({
        id: asTask
          ? 'portfolio.task.name.edit.input_placeholder'
          : 'portfolio.challenge.name.edit.input_placeholder'
      })}
      {...props}
    />
  );
};

ChallengeNameInput.propTypes = {
  asTask: PropTypes.bool.isRequired
};

export default ChallengeNameInput;
