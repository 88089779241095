import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import sortByUiPos from 'lib/utils/sortByUiPos';
import getHighestUiPosFromSortedItems from 'pages/EditorPage/utils/getHighestUiPosFromSortedItems';

const getHighestFieldUiPos = fields => {
  const flattenedFields = flattenFirebaseList(fields);
  const sortedFields = flattenedFields.sort(sortByUiPos);
  return getHighestUiPosFromSortedItems(sortedFields);
};

export default getHighestFieldUiPos;
