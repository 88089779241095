import { forwardRef } from 'react';

const applyPropStripping = (originalProps, strippedProps) => {
  const filteredProps = { ...originalProps };

  for (const strippedProp of strippedProps) {
    delete filteredProps[strippedProp];
  }

  return filteredProps;
};

const stripProps = (Component, ...strippedProps) =>
  forwardRef((props, ref) => {
    const filteredProps = applyPropStripping(props, strippedProps);
    return <Component {...filteredProps} ref={ref} />;
  });

export default stripProps;
