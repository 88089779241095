import styled from '@emotion/styled';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';

const StyledWrapper = styled('div')({
  marginTop: -12,
  marginBottom: -12,
  marginLeft: 'auto',
  display: 'flex'
});

const LogEntryActionsWrapper = props => (
  <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
    <StyledWrapper {...props} />
  </RestrictedToPortfolioMode>
);

export default LogEntryActionsWrapper;
