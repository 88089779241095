import { useContext } from 'react';
import ModelTypeContext from '../../../../ModelTypeContext';
import FieldContext from '../../FieldContext';
import { ref } from 'lib/firebase';

const useCurrentFieldRubricsRef = () => {
  const modelType = useContext(ModelTypeContext);
  const { field } = useContext(FieldContext);

  return ref(`models/${modelType}/rubrics/${field.key}`);
};

export default useCurrentFieldRubricsRef;
