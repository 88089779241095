import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const StyledName = styled(motion.h4)({
  fontWeight: 400,
  whiteSpace: 'nowrap'
});

const ChallengeName = props => (
  <StyledName
    variants={{
      initial: {
        marginLeft: 0,
        marginRight: 0,
        width: 0,
        opacity: 0
      },
      hover: {
        marginLeft: 12,
        marginRight: 4,
        width: 'auto',
        opacity: 1
      }
    }}
    {...props}
  />
);

export default ChallengeName;
