import { useState } from 'react';
import PropTypes from 'prop-types';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';
import { ref } from 'lib/firebase';
import Dialog from 'lib/components/Dialog';
import ManagerLoadingContent from './ManagerLoadingContent';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import TopicSelector from './TopicSelector';
import TopicPartsWithAttachmentHandling from './TopicPartsWithAttachmentHandling';

const PartAttachmentManager = ({ onClose, ...props }) => {
  const [
    portfolioTemplatesLoading,
    portfolioTemplates
  ] = useUnsyncedFirebaseValue(ref('portfolioTemplates'));
  const [selectedTopic, setSelectedTopic] = useState(null);

  return (
    <Dialog onClose={onClose} onEnterClose={onClose} {...props}>
      {portfolioTemplatesLoading ? (
        <ManagerLoadingContent />
      ) : (
        <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
          <Dialog.Steps.Wrapper hasMovedToSecondStep={!!selectedTopic}>
            <Dialog.Steps.Step isFirstStep>
              <TopicSelector onSelectTopic={topic => setSelectedTopic(topic)} />
            </Dialog.Steps.Step>
            <Dialog.Steps.Step>
              <TopicPartsWithAttachmentHandling
                selectedTopic={selectedTopic}
                onGoBack={() => setSelectedTopic(null)}
              />
            </Dialog.Steps.Step>
          </Dialog.Steps.Wrapper>
        </PortfolioTemplatesContext.Provider>
      )}
      <Dialog.Actions>
        <Dialog.Actions.DoneButton onClick={onClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

PartAttachmentManager.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PartAttachmentManager;
