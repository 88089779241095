import LoadingSpinnerWrapper from './LoadingSpinnerWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';

const RowLoadingSpinner = props => (
  <LoadingSpinnerWrapper {...props}>
    <LoadingSpinner margin={12} centered />
  </LoadingSpinnerWrapper>
);

export default RowLoadingSpinner;
