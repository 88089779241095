import PropTypes from 'prop-types';
import Space from 'lib/components/Space';
import { IconButton } from '@material-ui/core';

const PhaseSelectIconButton = ({
  hidden: shouldDisplayPlaceholder,
  ...props
}) => {
  if (shouldDisplayPlaceholder) {
    return <Space width={48} />;
  }

  return <IconButton {...props} />;
};

PhaseSelectIconButton.propTypes = {
  hidden: PropTypes.bool
};

PhaseSelectIconButton.defaultProps = {
  hidden: false
};

export default PhaseSelectIconButton;
