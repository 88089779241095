/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import { useDebounce } from 'use-debounce';
import { ref } from 'lib/firebase';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

const ExpertiseNameField = ({ expertiseKey, expertise, ...props }) => {
  const expertiseLoading = !expertise;

  const [expertiseName, setExpertiseName] = useSyncedState(
    expertise?.name || ''
  );
  const [debouncedExpertiseName] = useDebounce(expertiseName);

  useEffect(() => {
    if (expertiseLoading) {
      return;
    }

    const expertiseRef = ref(`expertises/${expertiseKey}`);

    const newExpertiseName = debouncedExpertiseName.trim();
    const oldExpertiseName = expertise.name || '';

    if (newExpertiseName !== oldExpertiseName) {
      expertiseRef.child('name').set(newExpertiseName);
    }
  }, [debouncedExpertiseName]);

  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      placeholder={intl.formatMessage({
        id: `user_manager.user_list.user.expertise_manager.expertise_items.item.name_field.${
          expertiseLoading ? 'loading_' : ''
        }placeholder`
      })}
      value={expertiseName}
      onChange={e => setExpertiseName(e.target.value)}
      disabled={expertiseLoading}
      size="small"
      fullWidth
      {...props}
    />
  );
};

ExpertiseNameField.propTypes = {
  expertiseKey: PropTypes.string.isRequired,
  expertise: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
};

export default ExpertiseNameField;
