import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import RowDeletionContext from './RowDeletionContext';
import RowOverlayWrapper from './RowOverlayWrapper';
import RowDeletionCountdownOverlay from './RowDeletionCountdownOverlay';
import deleteRowFromFirebase from 'lib/firebase/challengePortfolio/deleteRow';

const RowDeletionProvider = forwardRef(
  ({ rowKey, onDeletionStart, children, ...props }, ref) => {
    const [rowDeleting, setRowDeleting] = useState(false);
    const currentUserUsername = App.authentication.useCurrentUserUsername();

    return (
      <RowDeletionContext.Provider
        value={{
          deleteRow: () => deleteRowFromFirebase(rowKey, currentUserUsername),
          rowDeleting,
          startRowDeletion: () => {
            onDeletionStart();
            setRowDeleting(true);
          },
          cancelRowDeletion: () => setRowDeleting(false)
        }}
      >
        <RowOverlayWrapper {...props} ref={ref}>
          {children}
          {rowDeleting && <RowDeletionCountdownOverlay />}
        </RowOverlayWrapper>
      </RowDeletionContext.Provider>
    );
  }
);

RowDeletionProvider.propTypes = {
  rowKey: PropTypes.string.isRequired,
  onDeletionStart: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default RowDeletionProvider;
