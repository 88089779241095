import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../../SloContext';
import { challengeRef, ref } from 'lib/firebase';
import ChallengeWrapper from './ChallengeWrapper';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import DetachChallengeButton from './DetachChallengeButton';

const WithDetachChallengeButton = ({ challengeKey, children, ...props }) => {
  const slo = useContext(SloContext);

  const detachChallenge = () => {
    challengeRef(challengeKey).child(`attachedSlos/${slo.key}`).remove();

    ref(`slos/${slo.key}/attachedChallenges/${challengeKey}`).remove();
  };

  return (
    <ChallengeWrapper {...props}>
      {children}
      <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
        <DetachChallengeButton onClick={detachChallenge} />
      </RestrictedToPortfolioMode>
    </ChallengeWrapper>
  );
};

WithDetachChallengeButton.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default WithDetachChallengeButton;
