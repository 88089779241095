import { useContext, useState } from 'react';
import AttachedSlosContext from '../AttachedSlosContext';
import App from 'lib/App';
import ViewAttachedSlosButton from './ViewAttachedSlosButton';
import AttachedSlosDialog from './AttachedSlosDialog';

const AttachedSlos = props => {
  const attachedSlos = useContext(AttachedSlosContext);
  const [attachedSlosDialogOpen, setAttachedSlosDialogOpen] = useState(false);

  const sloSystemEnabled = App.instanceConfig.useContentSetting(
    'enableSloSystem'
  );
  if (!sloSystemEnabled || attachedSlos.length === 0) {
    return null;
  }

  return (
    <>
      <ViewAttachedSlosButton
        onClick={() => setAttachedSlosDialogOpen(true)}
        attachedSlosCount={attachedSlos.length}
        {...props}
      />
      <AttachedSlosDialog
        open={attachedSlosDialogOpen}
        onClose={() => setAttachedSlosDialogOpen(false)}
      />
    </>
  );
};

export default AttachedSlos;
