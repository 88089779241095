import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import NameIconWrapper from './NameIconWrapper';
import { Tooltip } from '@material-ui/core';
import SmallUserIcon from './SmallUserIcon';

const ExpertName = ({ expertUsername, ...props }) => {
  const [expertLoading, expert] = useFirebaseValue(userRef(expertUsername));

  if (expertLoading) {
    return null;
  }

  return (
    <NameIconWrapper className="name-icon-wrapper" {...props}>
      <Tooltip placement="top" title={expert.name}>
        <SmallUserIcon />
      </Tooltip>
    </NameIconWrapper>
  );
};

ExpertName.propTypes = {
  expertUsername: PropTypes.string.isRequired
};

export default ExpertName;
