import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ManagerWrapper = styled('section')(({ theme }) => ({
  padding: '12px 24px',
  backgroundColor: theme.palette.background.default,
  boxSizing: 'border-box',
  [mediaQuery(640)]: {
    marginBottom: 8
  }
}));

export default ManagerWrapper;
