import styled from '@emotion/styled';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import common from '@material-ui/core/colors/common';

const StyledLoadingSpinner = styled(LoadingSpinner)({
  padding: '3px 8px',
  color: common.white
});

const LabelLoadingPlaceholder = props => (
  <StyledLoadingSpinner size={15} thickness={5} {...props} />
);

export default LabelLoadingPlaceholder;
