import { useContext } from 'react';
import App from 'lib/App';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import PortfolioModes from 'lib/constants/PortfolioModes';

const useExpertModePossible = () => {
  const expertSystemEnabled = App.instanceConfig.useContentSetting(
    'enableExpertSystem'
  );

  const portfolioMode = useContext(PortfolioModeContext);

  const currentUser = App.authentication.useCurrentUser();
  const userHasExpertise = safeKeys(currentUser.expertiseKeys).length > 0;

  const expertModePossible =
    expertSystemEnabled &&
    portfolioMode === PortfolioModes.COACH &&
    currentUser.isExpert &&
    userHasExpertise;

  return expertModePossible;
};

export default useExpertModePossible;
