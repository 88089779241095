import styled from '@emotion/styled';

const BackgroundLineWithSpacing = styled('section')(({ theme }) => ({
  flex: 1,
  borderRight: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-right']),
  ':last-child': {
    borderColor: 'transparent'
  }
}));

export default BackgroundLineWithSpacing;
