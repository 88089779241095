import { useContext } from 'react';
import IsUsingPlanningViewContext from 'lib/contexts/IsUsingPlanningViewContext';
import ToggleButtonTooltip from './ToggleButtonTooltip';
import HeaderActionIconButton from '../HeaderActionIconButton';
import {
  DateRange as PlanningIcon,
  ViewColumn as KanbanBoardIcon
} from '@material-ui/icons';

const PlanningViewToggleButton = props => {
  const [isUsingPlanningView, setIsUsingPlanningView] = useContext(
    IsUsingPlanningViewContext
  );

  return (
    <ToggleButtonTooltip isUsingPlanningView={isUsingPlanningView}>
      <HeaderActionIconButton
        onClick={() => setIsUsingPlanningView(!isUsingPlanningView)}
        {...props}
      >
        {isUsingPlanningView ? <KanbanBoardIcon /> : <PlanningIcon />}
      </HeaderActionIconButton>
    </ToggleButtonTooltip>
  );
};

export default PlanningViewToggleButton;
