import { useState } from 'react';
import PropTypes from 'prop-types';
import ensureLinkIsFullUrl from 'lib/utils/ensureLinkIsFullUrl';
import { EditorState, RichUtils } from 'draft-js';
import { getSelectionText } from 'draftjs-utils';
import ToolbarButton from '../../ToolbarButton';
import { InsertLink as AddLinkIcon } from '@material-ui/icons';
import LinkPrompt from './LinkPrompt';

const AddLink = ({ editorState, setEditorState, focusOnEditor, ...props }) => {
  const [linkPromptOpen, setLinkPromptOpen] = useState(false);

  const addLink = potentiallyIncompleteLink => {
    const link = ensureLinkIsFullUrl(potentiallyIncompleteLink);

    const editorContent = editorState.getCurrentContent();
    const editorContentWithEntity = editorContent.createEntity(
      'LINK',
      'MUTABLE',
      { url: link }
    );
    const entityKey = editorContentWithEntity.getLastCreatedEntityKey();

    const newEditorState = EditorState.set(editorState, {
      currentContent: editorContentWithEntity
    });

    setEditorState(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );

    setLinkPromptOpen(false);

    focusOnEditor();
  };

  const selectionText = getSelectionText(editorState);
  const hasSelection = selectionText.length > 0;

  return (
    <>
      <ToolbarButton
        label="add_link"
        icon={AddLinkIcon}
        onClick={() => setLinkPromptOpen(true)}
        disabled={!hasSelection}
        {...props}
      />
      <LinkPrompt
        open={linkPromptOpen}
        onAdd={addLink}
        onCancel={() => setLinkPromptOpen(false)}
      />
    </>
  );
};

AddLink.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  focusOnEditor: PropTypes.func.isRequired
};

export default AddLink;
