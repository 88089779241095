import ViewAllButton from './ViewAllButton';
import SmallMoreIcon from './SmallMoreIcon';

const ViewAllExpertProgressItems = props => (
  <ViewAllButton
    className="indicator-label view-all-expert-progress-items-button"
    stackIndex={2}
    {...props}
  >
    <SmallMoreIcon />
  </ViewAllButton>
);

export default ViewAllExpertProgressItems;
