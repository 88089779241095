import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import DangerColorCircularProgress from './DangerColorCircularProgress';

const StyledCircularProgress = styled(DangerColorCircularProgress)({
  position: 'absolute',
  top: 4,
  left: 4
});

const DeletionProgressIndicator = ({ deletionProgress, ...props }) => (
  <StyledCircularProgress
    thickness={3}
    variant="static"
    value={Math.min(100, deletionProgress)}
  />
);

DeletionProgressIndicator.propTypes = {
  deletionProgress: PropTypes.number.isRequired
};

export default DeletionProgressIndicator;
