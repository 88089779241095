import translations_nl from 'translations/nl.json';
import translations_en from 'translations/en.json';
import translations_fr from 'translations/fr.json';

const translations = {
  nl: translations_nl,
  en: translations_en,
  fr: translations_fr
};

export default translations;
