import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const UserToolbarPlaceholder = styled('div')(({ theme, visible }) => ({
  height: 60,
  backgroundColor: theme.palette.background.shaded,
  borderRadius: 4,
  display: visible ? 'block' : 'none'
}));

UserToolbarPlaceholder.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default UserToolbarPlaceholder;
