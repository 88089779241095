import PropTypes from 'prop-types';
import ActionsWrapper from './ActionsWrapper';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon, Check as CheckIcon } from '@material-ui/icons';

const LevelEditorActions = ({ onClose, onSave, ...props }) => (
  <ActionsWrapper {...props}>
    <IconButton onClick={onClose}>
      <CloseIcon />
    </IconButton>
    <IconButton onClick={onSave}>
      <CheckIcon />
    </IconButton>
  </ActionsWrapper>
);

LevelEditorActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  primaryButtons: PropTypes.bool
};

LevelEditorActions.defaultProps = {
  primaryButtons: false
};

export default LevelEditorActions;
