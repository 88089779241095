import { useContext } from 'react';
import PhaseContentGroupContext from '../../../../PhaseContentGroupContext';
import FieldContext from '../../../FieldContext';
import useCurrentFieldPhaseMoving from './useCurrentFieldPhaseMoving';
import useMoveCurrentFieldToPhaseContentGroup from './useMoveCurrentFieldToPhaseContentGroup';
import useSwapUiPositionsWithField from './useSwapUiPositionsWithField';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const useFieldMoving = allHighestUiPositions => {
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const { previousField, nextField } = useContext(FieldContext);

  const [
    moveCurrentFieldToPreviousPhase,
    moveCurrentFieldToNextPhase
  ] = useCurrentFieldPhaseMoving();

  const moveCurrentFieldToPhaseContentGroup = useMoveCurrentFieldToPhaseContentGroup(
    allHighestUiPositions
  );

  const swapUiPositionsWithField = useSwapUiPositionsWithField();

  const moveUp = () => {
    if (
      !previousField &&
      currentPhaseContentGroup !== PhaseContentGroups.END_CONTENT
    ) {
      moveCurrentFieldToPreviousPhase();
      return;
    }

    if (!previousField) {
      moveCurrentFieldToPhaseContentGroup(PhaseContentGroups.LEFT_CONTENT);
      return;
    }

    swapUiPositionsWithField(previousField);
  };

  const moveDown = () => {
    if (
      !nextField &&
      currentPhaseContentGroup === PhaseContentGroups.END_CONTENT
    ) {
      moveCurrentFieldToNextPhase();
      return;
    }

    if (!nextField) {
      moveCurrentFieldToPhaseContentGroup(PhaseContentGroups.END_CONTENT);
      return;
    }

    swapUiPositionsWithField(nextField);
  };

  return [moveUp, moveDown];
};

export default useFieldMoving;
