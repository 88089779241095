import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import deleteLevelFromFirebase from 'lib/firebase/rubricsPortfolioTemplates/deleteLevel';
import { IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const DeleteLevel = ({ level, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteLevel = () => {
    setConfirmationDialogOpen(false);
    deleteLevelFromFirebase(level.id);
  };

  return (
    <>
      <IconButton onClick={() => setConfirmationDialogOpen(true)} {...props}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.level.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.level.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteLevel} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteLevel.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteLevel;
