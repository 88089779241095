import PropTypes from 'prop-types';
import FullscreenView from './FullscreenView';
import SloSystemHeader from './SloSystemHeader';
import SloPeriodList from './SloPeriodList';

const SloSystemContent = ({ onClose, ...props }) => (
  <FullscreenView {...props}>
    <SloSystemHeader onCloseSloSystem={onClose} />
    <FullscreenView.Content>
      <SloPeriodList />
    </FullscreenView.Content>
  </FullscreenView>
);

SloSystemContent.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SloSystemContent;
