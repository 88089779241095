import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledChallengeName = styled('h4')(
  ({ accountForBadge }) => ({
    marginBottom: 6,
    paddingRight: accountForBadge ? 8 : 0,
    minHeight: 15,
    maxHeight: 29,
    fontWeight: 400,
    wordWrap: 'break-word',
    overflow: 'hidden'
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    outline: `2px solid ${theme.palette.background.paper}`
  }))
);

StyledChallengeName.propTypes = {
  accountForBadge: PropTypes.bool.isRequired
};

export default StyledChallengeName;
