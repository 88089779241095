import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import PromptWithColors from '../../PromptWithColors';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import Groups from 'lib/constants/Groups';
import { FormattedMessage } from 'react-intl';

const AddPart = ({ subtopicKey, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewPart = (name, color) => {
    setAddPromptOpen(false);

    const key = ref('portfolioTemplates/parts').push().key;

    ref('models')
      .child(`learningGoals/${learningGoalsModel.key}`)
      .child(`subtopics/${subtopicKey}/parts/${key}`)
      .set(true);

    ref(`portfolioTemplates/parts/${key}`).set({
      id: key,
      title: name,
      color,
      managedFromLearningGoals: true,
      groups: {
        [Groups.STUDENTS]: true,
        [Groups.COACHES]: true
      }
    });
  };

  return (
    <>
      <TileGrid.AddButton onClick={() => setAddPromptOpen(true)} {...props}>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_part.button" />
      </TileGrid.AddButton>
      <PromptWithColors
        open={addPromptOpen}
        textFieldLabel={
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_part.input_label" />
        }
        onOk={saveNewPart}
        onCancel={() => setAddPromptOpen(false)}
        OkButton={Dialog.Actions.CreateButton}
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_part.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_part.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PromptWithColors>
    </>
  );
};

AddPart.propTypes = {
  subtopicKey: PropTypes.string.isRequired
};

export default AddPart;
