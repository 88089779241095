import { IconButton } from '@material-ui/core';
import Space from 'lib/components/Space';

const FullscreenViewHeaderActionButton = props => (
  <>
    <IconButton edge="start" {...props} />
    <Space width={12} />
  </>
);

export default FullscreenViewHeaderActionButton;
