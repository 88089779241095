import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';

const StyledIconButton = styled(IconButton)({
  marginTop: -12,
  marginBottom: -12
});

const InfoButton = props => (
  <StyledIconButton {...props}>
    <InfoIcon />
  </StyledIconButton>
);

export default InfoButton;
