import useActivePlatformIcons from 'lib/utils/useActivePlatformIcons';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ContentWrapper from './ContentWrapper';
import PlatformIcon from './PlatformIcon';
import MessageWrapper from './MessageWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const PortfolioLoadingView = props => {
  const activePlatformIcons = useActivePlatformIcons();
  return (
    <ContentWrapper>
      <PlatformIcon
        src={activePlatformIcons[PlatformTypes.PROGRESSMONITOR]}
        alt=""
      />
      <MessageWrapper>
        <LoadingSpinner />
        <Space width={12} />
        <FormattedMessage id="portfolio.loading_message" />
      </MessageWrapper>
    </ContentWrapper>
  );
};

export default PortfolioLoadingView;
