import PropTypes from 'prop-types';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import PhaseName from './PhaseName';
import phaseName from 'lib/utils/phaseName';
import { List } from '@material-ui/core';
import Phases from 'lib/constants/Phases';

const RowPhaseChallengesMapper = ({ row, phase, children, ...props }) => {
  const challengeKeysInGivenRowPhase = safeKeys(row.challenges?.[phase]);

  if (challengeKeysInGivenRowPhase.length === 0) {
    return null;
  }

  return (
    <>
      <PhaseName>{phaseName(phase)}</PhaseName>
      <List {...props}>
        {challengeKeysInGivenRowPhase.map(challengeKey =>
          children(challengeKey)
        )}
      </List>
    </>
  );
};

RowPhaseChallengesMapper.propTypes = {
  row: PropTypes.shape({
    challenges: PropTypes.shape({
      [Phases.TODO]: PropTypes.objectOf(PropTypes.bool),
      [Phases.PROGRESS]: PropTypes.objectOf(PropTypes.bool),
      [Phases.DONE]: PropTypes.objectOf(PropTypes.bool)
    })
  }).isRequired,
  phase: PropTypes.oneOf(Object.values(Phases)).isRequired,
  children: PropTypes.func.isRequired
};

export default RowPhaseChallengesMapper;
