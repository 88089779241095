import PropTypes from 'prop-types';
import FocusLockWithoutDialogFocusFighting from 'lib/components/FocusLockWithoutDialogFocusFighting';
import ClosablePageOverlay from './ClosablePageOverlay';
import ChallengeViewCard from './ChallengeViewCard';

const ChallengeViewCardWithPageOverlay = ({
  taskBoardOpen,
  onClose,
  ...props
}) => (
  <FocusLockWithoutDialogFocusFighting>
    <ClosablePageOverlay taskBoardOpen={taskBoardOpen} onClose={onClose} />
    <ChallengeViewCard elevation={3} taskBoardOpen={taskBoardOpen} {...props} />
  </FocusLockWithoutDialogFocusFighting>
);

ChallengeViewCardWithPageOverlay.propTypes = {
  taskBoardOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChallengeViewCardWithPageOverlay;
