import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import FieldNameWrapper from '../../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../../utils/FieldName/StyledFieldName';
import FieldInfo from '../../utils/FieldName/FieldInfo';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import IconButtonWithMarginCompensation from '../IconButtonWithMarginCompensation';
import { Edit as EditIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import HtmlRenderer from '../../utils/HtmlRenderer';
import AddButton from './AddButton';

const ViewText = ({ onEdit, ...props }) => {
  const { field, challenge } = useContext(FieldContext);
  const text = challenge[field.key];
  return (
    <section {...props}>
      <FieldNameWrapper>
        <StyledFieldName>{field.name}</StyledFieldName>
        {field.info && <FieldInfo />}
        {text && (
          <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
            <IconButtonWithMarginCompensation onClick={onEdit}>
              <EditIcon />
            </IconButtonWithMarginCompensation>
          </RestrictedToPortfolioMode>
        )}
      </FieldNameWrapper>
      {text && <Space height={-16} />}
      {text ? (
        <HtmlRenderer source={text.toString()} />
      ) : (
        <AddButton fieldName={field.name} onClick={onEdit} />
      )}
    </section>
  );
};

ViewText.propTypes = {
  onEdit: PropTypes.func.isRequired
};

export default ViewText;
