import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import common from '@material-ui/core/colors/common';

// In dark mode, we cannot expect school-uploaded logos to change color, so
// we have to ensure the header logo always has a white background
const LinkWithWhiteBackground = styled(Link)({
  margin: -4,
  padding: 4,
  width: 'fit-content',
  backgroundColor: common.white,
  display: 'block'
});

export default LinkWithWhiteBackground;
