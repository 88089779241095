import SettingsFieldTitle from '../SettingsFieldTitle';
import Space from 'lib/components/Space';
import { TextField } from '@material-ui/core';
import { useIntl, FormattedMessage } from 'react-intl';

const AllowedEmailPrefixField = props => {
  const intl = useIntl();
  return (
    <>
      <SettingsFieldTitle>
        <FormattedMessage id="platform_settings.login_settings.change_email_prefix" />
      </SettingsFieldTitle>
      <TextField
        variant="outlined"
        placeholder={intl.formatMessage({
          id: 'platform_settings.login_settings.email_prefix'
        })}
        size="small"
        fullWidth
        {...props}
      />
      <Space height={24} />
    </>
  );
};

export default AllowedEmailPrefixField;
