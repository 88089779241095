import PropTypes from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const LearningGoalsModelSelect = ({ children, ...props }) => (
  <Select displayEmpty {...props}>
    <MenuItem value="">
      <Space width={1} />
      <em>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.settings.reuse_learning_goals.select.none" />
      </em>
    </MenuItem>
    {children}
  </Select>
);

LearningGoalsModelSelect.propTypes = {
  children: PropTypes.node.isRequired
};

export default LearningGoalsModelSelect;
