import styled from '@emotion/styled';
import AddItemButton from '../AddItemButton';

const AddSubtopicButton = styled(AddItemButton)({
  marginTop: 22,
  ':first-child': {
    marginTop: -8
  }
});

export default AddSubtopicButton;
