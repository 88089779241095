import PropTypes from 'prop-types';
import useColorState from '../../useColorState';
import StyledForm from './StyledForm';
import ColorPreview from '../../ColorPreview';
import ColorTextField from '../../ColorTextField';
import { IconButton } from '@material-ui/core';
import { Close as CancelIcon, Check as CheckIcon } from '@material-ui/icons';

const ColorForm = ({ color: originalColor, onCancel, onSave }) => {
  const [color, setColor] = useColorState(originalColor);
  const fullHexColor = `#${color}`;

  const handleCancel = () => {
    setColor(originalColor);

    onCancel();
  };

  const handleSave = e => {
    e.preventDefault();

    if (color.length < 6) {
      return;
    }

    onSave(fullHexColor);
  };

  return (
    <StyledForm onSubmit={handleSave}>
      <ColorPreview color={fullHexColor} />
      <ColorTextField value={color} onChange={e => setColor(e.target.value)} />
      <IconButton onClick={handleCancel}>
        <CancelIcon />
      </IconButton>
      <IconButton disabled={color.length < 6} onClick={handleSave}>
        <CheckIcon />
      </IconButton>
    </StyledForm>
  );
};

ColorForm.propTypes = {
  color: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default ColorForm;
