import PropTypes from 'prop-types';
import StyledAddButton from './StyledAddButton';
import { Add as AddIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';

const AddButton = ({ children, ...props }) => (
  <StyledAddButton variant="extended" color="primary" {...props}>
    <AddIcon />
    <Space width={8} />
    {children}
  </StyledAddButton>
);

AddButton.propTypes = {
  children: PropTypes.node
};

export default AddButton;
