import MoveButtonsWrapper from './MoveButtonsWrapper';
import MoveButton from './MoveButton';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@material-ui/icons';

const iconStyles = {
  width: 16,
  height: 16
};

const MoveUpButton = props => (
  <MoveButton {...props}>
    <ArrowUpwardIcon style={iconStyles} />
  </MoveButton>
);

const MoveDownButton = props => (
  <MoveButton {...props}>
    <ArrowDownwardIcon style={iconStyles} />
  </MoveButton>
);

const MoveButtons = {
  Wrapper: MoveButtonsWrapper,
  Up: MoveUpButton,
  Down: MoveDownButton
};

export default MoveButtons;
