import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import FieldNameWrapper from './FieldNameWrapper';
import StyledFieldName from './StyledFieldName';
import FieldInfo from './FieldInfo';

const FieldName = props => {
  const { field, phaseContentGroup } = useContext(FieldContext);

  if (!field.name) {
    return null;
  }

  const isInRightPhaseContentGroup =
    phaseContentGroup === PhaseContentGroups.RIGHT_CONTENT;

  return (
    <FieldNameWrapper secondary={isInRightPhaseContentGroup} {...props}>
      <StyledFieldName bold={!isInRightPhaseContentGroup}>
        {field.name}
      </StyledFieldName>
      {field.info && <FieldInfo />}
    </FieldNameWrapper>
  );
};

export default FieldName;
