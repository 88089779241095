import { useContext } from 'react';
import PhaseContentGroupContext from '../../../PhaseContentGroupContext';
import useSelectableFieldTypesForPhaseContentGroups from './useSelectableFieldTypesForPhaseContentGroups';
import ModelTypeContext from '../../../../ModelTypeContext';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';

const useSelectableFieldTypes = () => {
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const selectableFieldTypesForCurrentPhaseContentGroup = useSelectableFieldTypesForPhaseContentGroups()[
    currentPhaseContentGroup
  ];

  const currentModelType = useContext(ModelTypeContext);
  const unavailableFieldTypesForCurrentModelType =
    currentModelType === ModelTypes.CHALLENGE
      ? [FieldTypes.MANUAL_ASSIGNEES]
      : [FieldTypes.MANUAL_CHALLENGE_SHARING];

  return selectableFieldTypesForCurrentPhaseContentGroup.filter(
    fieldType => !unavailableFieldTypesForCurrentModelType.includes(fieldType)
  );
};

export default useSelectableFieldTypes;
