import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import SelectedUserContext from 'lib/contexts/SelectedUserContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';
import PageTitle from 'lib/components/PageTitle';
import ExpertModeSettingsBar from './ExpertModeSettingsBar';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ChallengePortfolio from 'components/ChallengePortfolio';
import RubricsPortfolio from 'components/RubricsPortfolio';
import NotFoundTitle from 'lib/components/NotFoundTitle';
import { FormattedMessage } from 'react-intl';

const CoachPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const selectedUser = useContext(SelectedUserContext);
  return (
    <PortfolioModeContext.Provider value={PortfolioModes.COACH}>
      <PortfolioUserContext.Provider value={selectedUser}>
        <ExpertModeSettingsContext.AutomatedProvider>
          <PageTitle id="coach.page_title" />
          <ExpertModeSettingsBar />
          {selectedUser ? (
            selectedPlatform === PlatformTypes.CHALLENGEMONITOR ? (
              <ChallengePortfolio />
            ) : (
              <RubricsPortfolio />
            )
          ) : (
            <NotFoundTitle>
              <FormattedMessage id="coach.no_user_selected" />
            </NotFoundTitle>
          )}
        </ExpertModeSettingsContext.AutomatedProvider>
      </PortfolioUserContext.Provider>
    </PortfolioModeContext.Provider>
  );
};

export default CoachPage;
