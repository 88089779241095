const countTasksForPhase = phase => {
  if (!phase) {
    return 0;
  }

  const taskKeysWithinPhase = Object.keys(phase);
  return taskKeysWithinPhase.length;
};

const countTasks = challenge => {
  const tasks = challenge.tasks;

  if (!tasks) {
    return 0;
  }

  const phases = Object.values(tasks);
  const taskCount = phases.reduce(
    (taskCountSoFar, currentPhase) =>
      taskCountSoFar + countTasksForPhase(currentPhase),
    0
  );

  return taskCount;
};

export default countTasks;
