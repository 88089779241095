import PropTypes from 'prop-types';
import App from 'lib/App';
import StyledIcon from './StyledIcon';
import IconVariants from '../IconVariants';
import studentIcon from './sliderIcons/student.svg';
import coachIcon from './sliderIcons/coach.svg';

const SliderIcon = ({ variant, sliding, ...props }) => {
  const reversed = App.instanceConfig.useContentSetting('useReversedSliders');
  return (
    <StyledIcon
      src={variant === IconVariants.STUDENT ? studentIcon : coachIcon}
      noTransition={sliding}
      reversed={reversed}
      style={{
        top: variant === IconVariants.COACH ? 7 : null,
        bottom: variant === IconVariants.STUDENT ? 7 : null
      }}
      draggable={false}
      {...props}
    />
  );
};

SliderIcon.propTypes = {
  variant: PropTypes.oneOf(Object.values(IconVariants)).isRequired,
  sliding: PropTypes.bool.isRequired
};

export default SliderIcon;
