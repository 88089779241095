import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';

const StyledGroupSelectorButton = styled(ButtonBase)(
  ({ theme }) => ({
    padding: '10px 15px',
    width: '100%',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(),
    ':hover, :focus': {
      backgroundColor: theme.palette.action.hover
    }
  }),
  ({ theme, hasSelectedGroup }) => {
    if (!hasSelectedGroup) {
      return {
        color: theme.palette.text.secondary,
        fontStyle: 'italic'
      };
    }
  }
);

StyledGroupSelectorButton.propTypes = {
  hasSelectedGroup: PropTypes.bool.isRequired
};

export default StyledGroupSelectorButton;
