import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Paper from '@material-ui/core/Paper';
import isPropValid from '@emotion/is-prop-valid';
import mediaQuery from 'lib/styling/mediaQuery';
import { rgba } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const GapminderCard = styled(Paper, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    padding: '14px 48px',
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    borderRadius: 8,
    [mediaQuery(768)]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  }),
  ({ fixed }) => {
    if (fixed) {
      return {
        position: 'fixed',
        top: 0,
        left: 148,
        marginTop: 24,
        width: 'calc(100% - 296px)',
        boxShadow: `0 6px 54px ${rgba(common.black, 0.34)}`,
        zIndex: 3,
        [mediaQuery(1024)]: {
          left: 72,
          width: 'calc(100% - 144px)'
        },
        [mediaQuery(850)]: {
          left: 24,
          width: 'calc(100% - 48px)'
        },
        [mediaQuery(480)]: {
          marginTop: 0,
          left: 0,
          width: '100%',
          borderRadius: 0
        }
      };
    }
  },
  addBorderInHighContrastMode
);

GapminderCard.propTypes = {
  fixed: PropTypes.bool.isRequired
};

export default GapminderCard;
