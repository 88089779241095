import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const GroupFormWrapper = styled('form')({
  marginBottom: 24,
  paddingRight: 12,
  width: '50%',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(1024)]: {
    paddingRight: 0,
    width: '100%'
  }
});

export default GroupFormWrapper;
