import styled from '@emotion/styled';
import { Search as MuiSearchIcon } from '@material-ui/icons';

const SearchIcon = styled(MuiSearchIcon)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 15,
  color: theme.palette.action.active
}));

export default SearchIcon;
