import { useContext } from 'react';
import AttachedSlosContext from '../AttachedSlosContext';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import Slo from 'components/App/AppHeader/SloSystem/SloSystemContent/SloPeriodList/SloPeriod/SlosOverview/Slo';
import { FormattedMessage } from 'react-intl';

const AttachedSlosDialog = ({ onClose, ...props }) => {
  const attachedSlos = useContext(AttachedSlosContext);
  return (
    <Dialog onClose={onClose} {...props}>
      <Dialog.Title>
        <FormattedMessage id="portfolio.part.attached_slos.dialog.title" />
      </Dialog.Title>
      <Dialog.Content>
        {attachedSlos.map(sloKey => (
          <Slo sloKey={sloKey} key={sloKey} withGrouping />
        ))}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Actions.DoneButton onClick={onClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

AttachedSlosDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default AttachedSlosDialog;
