import styled from '@emotion/styled';

const DialogTitle = styled('h1')(({ theme }) => ({
  marginRight: 'auto',
  color: theme.palette.text.primary,
  fontSize: 22,
  fontWeight: 500,
  lineHeight: '36px',
  wordBreak: 'break-word'
}));

export default DialogTitle;
