import { Route, Switch } from 'react-router-dom';
import SecuredRoute from 'components/App/SecuredRoute';
import PortfolioPage from 'pages/PortfolioPage';
import OpenChallengePage from 'pages/OpenChallengePage';
import OpenTaskPage from 'pages/OpenTaskPage';
import CoachPage from 'pages/CoachPage';
import EditorPage from 'pages/EditorPage';
import AdminPage from 'pages/AdminPage';
import PlatformSettingsPage from 'pages/PlatformSettingsPage';
import NotFoundPage from 'pages/NotFoundPage';
import SelectedPlatformOverrideRoute from 'components/App/SelectedPlatformOverrideRoute';
import mode from 'lib/mode';
import Modes from 'lib/constants/Modes';
import PlatformTypes from 'lib/constants/PlatformTypes';

const AppContentRenderer = props => (
  <Switch {...props}>
    <SecuredRoute exact path={mode(Modes.PORTFOLIO).url}>
      <PortfolioPage />
    </SecuredRoute>
    <SecuredRoute exact path="/openChallenge/:key">
      <OpenChallengePage />
    </SecuredRoute>
    <SecuredRoute exact path="/openTask/:key">
      <OpenTaskPage />
    </SecuredRoute>
    <SecuredRoute
      exact
      path={mode(Modes.COACH).url}
      restrictedToRoles={mode(Modes.COACH).roleRestrictions}
    >
      <CoachPage />
    </SecuredRoute>
    <SecuredRoute
      exact
      path={mode(Modes.EDITOR).url}
      restrictedToRoles={mode(Modes.EDITOR).roleRestrictions}
    >
      <EditorPage />
    </SecuredRoute>
    <SecuredRoute
      exact
      path={mode(Modes.ADMIN).url}
      restrictedToRoles={mode(Modes.ADMIN).roleRestrictions}
    >
      <AdminPage />
    </SecuredRoute>
    <SecuredRoute
      exact
      path={mode(Modes.PLATFORM_SETTINGS).url}
      restrictedToRoles={mode(Modes.PLATFORM_SETTINGS).roleRestrictions}
    >
      <PlatformSettingsPage />
    </SecuredRoute>
    <SelectedPlatformOverrideRoute
      path="/challengemonitor"
      platform={PlatformTypes.CHALLENGEMONITOR}
    />
    <SelectedPlatformOverrideRoute
      path="/progressmonitor"
      platform={PlatformTypes.PROGRESSMONITOR}
    />
    <Route>
      <NotFoundPage />
    </Route>
  </Switch>
);

export default AppContentRenderer;
