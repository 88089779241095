import PropTypes from 'prop-types';
import useLoginHandlers from './useLoginHandlers';
import App from 'lib/App';
import AuthProviders from 'lib/constants/AuthProviders';
import LoginButton from './LoginButton';
import AuthProviderLogo from './AuthProviderLogo';
import { FormattedMessage } from 'react-intl';

const LoginButtonWithHandlers = ({ onShowEmailNotAllowedError, ...props }) => {
  const { loginWithGoogle, loginWithMicrosoft } = useLoginHandlers(
    onShowEmailNotAllowedError
  );

  const authProvider = App.instanceConfig.useSetting('authProvider');

  const login = () => {
    if (authProvider === AuthProviders.GOOGLE) {
      loginWithGoogle();
      return;
    }

    loginWithMicrosoft();
  };

  return (
    <LoginButton onClick={login} {...props}>
      <AuthProviderLogo />
      <FormattedMessage id={`login.sign_in_button.${authProvider}.label`} />
    </LoginButton>
  );
};

LoginButtonWithHandlers.propTypes = {
  onShowEmailNotAllowedError: PropTypes.func.isRequired
};

export default LoginButtonWithHandlers;
