import PropTypes from 'prop-types';
import ModelPropTypes from '../../ModelPropTypes';
import FieldContext from './FieldContext';
import FieldWrapper from './FieldWrapper';
import FieldHeader from './FieldHeader';
import FieldTypeSelect from './FieldTypeSelect';
import getTypeSpecificEditor from './getTypeSpecificEditor';

const ModelFieldEditor = ({
  field,
  previousField,
  nextField,
  allHighestUiPositions,
  ...props
}) => (
  <FieldContext.Provider value={{ field, previousField, nextField }}>
    <FieldWrapper {...props}>
      <FieldHeader allHighestUiPositions={allHighestUiPositions} />
      <FieldTypeSelect />
      {getTypeSpecificEditor(field.type)}
    </FieldWrapper>
  </FieldContext.Provider>
);

ModelFieldEditor.propTypes = {
  field: ModelPropTypes.field.isRequired,
  previousField: ModelPropTypes.field,
  nextField: ModelPropTypes.field,
  allHighestUiPositions: PropTypes.object.isRequired
};

export default ModelFieldEditor;
