/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import useCurrentFieldRef from '../../../../../useCurrentFieldRef';
import FieldContext from '../../../../../FieldContext';
import useSyncedState from 'lib/utils/useSyncedState';
import App from 'lib/App';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import ContentWrapper from './ContentWrapper';
import Space from 'lib/components/Space';
import Checkbox from '@material-ui/core/Checkbox';
import LearningGoalsModelSelectLabel from './LearningGoalsModelSelectLabel';
import LearningGoalsModelSelect from './LearningGoalsModelSelect';
import MenuItem from '@material-ui/core/MenuItem';

const ReuseLearningGoals = props => {
  const currentFieldRef = useCurrentFieldRef();
  const reusedLearningGoalsSettingRef = currentFieldRef.child(
    'reusedLearningGoals'
  );

  const { field } = useContext(FieldContext);

  const [isReusingLearningGoals, setIsReusingLearningGoals] = useSyncedState(
    !!field.reusedLearningGoals
  );

  useEffect(() => {
    if (!isReusingLearningGoals) {
      reusedLearningGoalsSettingRef.remove();
    }
  }, [isReusingLearningGoals]);

  const [
    selectedLearningGoalsKey,
    setSelectedLearningGoalsKey
  ] = useSyncedState(field.reusedLearningGoals || '');

  useEffect(() => {
    reusedLearningGoalsSettingRef.set(selectedLearningGoalsKey || null);
  }, [selectedLearningGoalsKey]);

  const { learningGoals: allLearningGoals } = App.useModels();
  const otherLearningGoalsKeys = safeKeys(allLearningGoals).filter(
    key => key !== field.key
  );

  if (otherLearningGoalsKeys.length === 0) {
    return null;
  }

  return (
    <ContentWrapper {...props}>
      <Space width={-12} />
      <Checkbox
        id="reuse-learning-goals"
        checked={isReusingLearningGoals}
        onChange={e => setIsReusingLearningGoals(e.target.checked)}
        color="primary"
      />
      <LearningGoalsModelSelectLabel htmlFor="reuse-learning-goals" />
      <LearningGoalsModelSelect
        value={selectedLearningGoalsKey}
        onChange={e => setSelectedLearningGoalsKey(e.target.value)}
        disabled={!isReusingLearningGoals}
      >
        {otherLearningGoalsKeys.map((key, i) => (
          <MenuItem value={key} key={key}>
            {allLearningGoals[key].name || i}
          </MenuItem>
        ))}
      </LearningGoalsModelSelect>
    </ContentWrapper>
  );
};

export default ReuseLearningGoals;
