import { motion } from 'framer-motion';
import PillButtons from '../../../PillButtons';

const ButtonsWrapper = motion.custom(PillButtons.Wrapper);

const ButtonsWrapperWithHover = props => (
  <ButtonsWrapper initial="initial" whileHover="hover" {...props} />
);

export default ButtonsWrapperWithHover;
