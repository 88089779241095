import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../SloContext';
import FieldContext from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/FieldContext';
import { ref } from 'lib/firebase';
import TextField from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/TextField';
import Space from 'lib/components/Space';

const ReflectionField = ({ field, ...props }) => {
  const slo = useContext(SloContext);
  const fieldValuesRef = ref(`slos/${slo.key}/reflectionFieldValues`);

  return (
    <FieldContext.Provider
      value={{
        field,
        challenge: slo.reflectionFieldValues || {},
        challengeRef: fieldValuesRef,
        fieldRef: fieldValuesRef.child(field.key)
      }}
    >
      <Space height={16} />
      <TextField {...props} />
    </FieldContext.Provider>
  );
};

ReflectionField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default ReflectionField;
