/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import weekFromDate from '../../weekFromDate';
import buildTimestampObject from './buildTimestampObject';
import { NOW } from '../index';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const useTimestamps = (progress, progressLoading) => {
  const currentYear = new Date().getFullYear();
  const currentWeek = weekFromDate(new Date()) + 1;

  const [timestamps, setTimestamps] = useState({
    [currentYear]: {
      [currentWeek]: NOW
    }
  });

  useEffect(() => {
    if (!progressLoading) {
      setTimestamps(buildTimestampObject(progress));
      return;
    }
  }, [progressLoading, safeKeys(progress).length]);

  return timestamps;
};

export default useTimestamps;
