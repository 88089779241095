import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const PartPromptWithoutGroups = ({
  selectedStandardGroups,
  selectedGroups,
  ...props
}) => (
  <Prompt emptyError="prompt.errors.name_empty" persistInput {...props}>
    <Dialog.Title>
      <FormattedMessage id="portfolio_template_editor.part.edit.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedMessage id="portfolio_template_editor.part.edit.text_no_groups" />
      </Dialog.ContentText>
    </Dialog.Content>
    <Prompt.TextField
      label={
        <FormattedMessage id="portfolio_template_editor.part.edit.input_label" />
      }
    />
  </Prompt>
);

export default PartPromptWithoutGroups;
