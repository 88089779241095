import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';
import { ref } from 'lib/firebase';
import useTimestamps from './useTimestamps';
import useGapminderState from './useGapminderState';
import useSelectedPortfolio from './useSelectedPortfolio';
import GapminderLegend from '../GapminderLegend';

export const NOW = 'now';

const PortfolioWithHistoryProvider = ({ children, ...props }) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const [portfolioHistoryLoading, portfolioHistory] = useUnsyncedFirebaseValue(
    ref(`progress/${portfolioUserUsername}`)
  );

  const timestamps = useTimestamps(portfolioHistory, portfolioHistoryLoading);

  const [
    selectedYear,
    setSelectedYear,
    selectedWeek,
    setSelectedWeek
  ] = useGapminderState(timestamps);

  const [
    livePortfolioLoading,
    selectedPortfolio,
    snapshotTimestamp
  ] = useSelectedPortfolio(
    portfolioHistory,
    timestamps,
    selectedYear,
    selectedWeek
  );

  const years = Object.keys(timestamps).sort();
  const weeks = Object.keys(timestamps[selectedYear]);

  return (
    <>
      <GapminderLegend
        portfolioHistoryLoading={portfolioHistoryLoading}
        years={years}
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
        weeks={weeks}
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        timestamp={snapshotTimestamp}
        {...props}
      />
      {children({
        portfolioLoading: livePortfolioLoading || !portfolioUser,
        portfolio: selectedPortfolio,
        portfolioIsLive: snapshotTimestamp === NOW
      })}
    </>
  );
};

PortfolioWithHistoryProvider.propTypes = {
  children: PropTypes.func.isRequired
};

export default PortfolioWithHistoryProvider;
