import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../../../../../SloContext';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import PartWrapper from './PartWrapper';
import { Checkbox, ListItemText } from '@material-ui/core';
import Space from 'lib/components/Space';

const PartWithAttachmentHandling = ({ part, ...props }) => {
  const slo = useContext(SloContext);
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const partIsAttached = !!slo.attachedParts?.[part.id];

  const togglePartAttachment = () => {
    const nextAttachmentState = partIsAttached ? null : true;

    ref(`slos/${slo.key}/attachedParts/${part.id}`).set(nextAttachmentState);
    ref(
      `portfolios/${currentUserUsername}/partSloAttachment/${part.id}/${slo.key}`
    ).set(nextAttachmentState);
  };

  return (
    <PartWrapper onClick={togglePartAttachment} button {...props}>
      <Checkbox
        color="primary"
        checked={partIsAttached}
        onChange={togglePartAttachment}
      />
      <Space width={8} />
      <ListItemText primary={part.title} />
    </PartWrapper>
  );
};

PartWrapper.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })
};

export default PartWithAttachmentHandling;
