import PropTypes from 'prop-types';
import StyledFeedbackItemFooter from './StyledFeedbackItemFooter';
import FeedbackItemDate from './FeedbackItemDate';
import PortfolioModes from 'lib/constants/PortfolioModes';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import EditFeedbackItem from './EditFeedbackItem';
import DeleteFeedbackItem from './DeleteFeedbackItem';

const FeedbackItemFooter = ({
  feedbackItem,
  setFeedbackItem,
  useInlineRef,
  ...props
}) => (
  <StyledFeedbackItemFooter>
    <FeedbackItemDate timestamp={feedbackItem.timestamp} />
    <RestrictedToPortfolioMode mode={PortfolioModes.COACH}>
      <EditFeedbackItem
        feedbackItem={feedbackItem}
        setFeedbackItem={setFeedbackItem}
        useInlineRef={useInlineRef}
      />
      <DeleteFeedbackItem
        feedbackItem={feedbackItem}
        setFeedbackItem={setFeedbackItem}
        useInlineRef={useInlineRef}
      />
    </RestrictedToPortfolioMode>
  </StyledFeedbackItemFooter>
);

FeedbackItemFooter.propTypes = {
  feedbackItem: PropTypes.shape({
    timestamp: PropTypes.number.isRequired
  }).isRequired,
  setFeedbackItem: PropTypes.func.isRequired,
  useInlineRef: PropTypes.bool.isRequired
};

export default FeedbackItemFooter;
