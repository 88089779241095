import styled from '@emotion/styled';

const NameIconWrapper = styled('div')(({ theme }) => ({
  transition: theme.transitions.create(),
  display: 'inline-flex',
  // These style properties are overridden by the hover effect on the
  // indicator label
  marginLeft: 0,
  width: 0,
  opacity: 0,
  overflow: 'hidden'
}));

export default NameIconWrapper;
