import { useIntl } from 'react-intl';
import { TextField } from '@material-ui/core';

const AllowedEmailTextField = props => {
  const intl = useIntl();
  return (
    <TextField
      variant="outlined"
      placeholder={intl.formatMessage({
        id: 'platform_settings.login_settings.allowed_email'
      })}
      size="small"
      fullWidth
      {...props}
    />
  );
};

export default AllowedEmailTextField;
