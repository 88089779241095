import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ref, value } from 'lib/firebase';
import Slo from 'components/App/AppHeader/SloSystem/SloSystemContent/SloPeriodList/SloPeriod/SlosOverview/Slo';

const sloIsAvailable = async sloKey => {
  try {
    await value(ref(`slos/${sloKey}/name`));
    return true;
  } catch {
    return false;
  }
};

const SloWithAvailabilityCheck = ({ sloKey, ...props }) => {
  const [canLoadSlo, setCanLoadSlo] = useState(false);

  useEffect(() => {
    sloIsAvailable(sloKey).then(result => setCanLoadSlo(result));
  }, [sloKey]);

  if (!canLoadSlo) {
    return null;
  }

  return <Slo sloKey={sloKey} {...props} />;
};

SloWithAvailabilityCheck.propTypes = {
  sloKey: PropTypes.string.isRequired
};

export default SloWithAvailabilityCheck;
