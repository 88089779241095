import { useContext } from 'react';
import FieldContext from '../../../../FieldContext';
import useLowestUiPosInPhaseEndContent from './useLowestUiPosInPhaseEndContent';
import useCurrentFieldRef from '../../../../useCurrentFieldRef';
import useCurrentPhaseRef from '../../../../../useCurrentPhaseRef';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const useMoveCurrentFieldToPhaseContentGroup = allHighestUiPositions => {
  const { field } = useContext(FieldContext);

  const lowestUiPosInPhaseEndContent = useLowestUiPosInPhaseEndContent();
  const newLowestUiPosForEndContent = lowestUiPosInPhaseEndContent / 2 || 1;

  const currentFieldRef = useCurrentFieldRef();
  const currentPhaseRef = useCurrentPhaseRef();

  const moveCurrentFieldToPhaseContentGroup = newPhaseContentGroup => {
    const highestUiPosInNewPhaseContentGroup =
      allHighestUiPositions[newPhaseContentGroup];

    const newUiPos =
      newPhaseContentGroup === PhaseContentGroups.END_CONTENT
        ? newLowestUiPosForEndContent
        : highestUiPosInNewPhaseContentGroup + 1;

    currentFieldRef.remove();
    currentPhaseRef
      .child(newPhaseContentGroup)
      .child(field.key)
      .set({
        ...field,
        key: null,
        uiPos: newUiPos
      });
  };

  return moveCurrentFieldToPhaseContentGroup;
};

export default useMoveCurrentFieldToPhaseContentGroup;
