import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledRefreshButton = styled(Button)({
  marginRight: 12,
  ':last-of-type': {
    marginRight: 0
  },
  [mediaQuery(400)]: {
    marginRight: 0,
    marginBottom: 12,
    display: 'flex',
    ':last-of-type': {
      marginBottom: 0
    }
  }
});

export default StyledRefreshButton;
