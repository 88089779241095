import DialogContentWrapper from '../DialogContentWrapper';
import Dialog from 'lib/components/Dialog';
import SupportTilesWrapper from '../SupportTypeSelector/SupportTilesWrapper';
import SupportTile from '../SupportTypeSelector/SupportTile';
import {
  EmailOutlined as EmailIcon,
  ErrorOutline as IssueIcon
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const SupportTileAsLink = SupportTile.withComponent('a');

const IssueReporting = () => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.issue_reporting.title" />
    </Dialog.Title>
    <Dialog.Content>
      <SupportTilesWrapper>
        <SupportTileAsLink href="mailto:bugs@egodact.com">
          <SupportTile.Icon icon={EmailIcon} />
          <SupportTile.Title>
            <FormattedMessage id="support.dialog.issue_reporting.tiles.email_title" />
          </SupportTile.Title>
        </SupportTileAsLink>
        <SupportTileAsLink
          href="https://github.com/egodact/egodact-issues"
          target="_blank"
        >
          <SupportTile.Icon icon={IssueIcon} />
          <SupportTile.Title>
            <FormattedMessage id="support.dialog.issue_reporting.tiles.github_issue_title" />
          </SupportTile.Title>
        </SupportTileAsLink>
      </SupportTilesWrapper>
    </Dialog.Content>
  </DialogContentWrapper>
);

export default IssueReporting;
