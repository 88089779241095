import PropTypes from 'prop-types';
import ActionButton from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioTemplatesEditorActionButtons/ActionButton';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

const StyledCollapseButton = styled(ActionButton, {
  shouldForwardProp: isPropValid
})(({ collapsed }) => ({
  transition: 'transform 450ms cubic-bezier(.23, 1, .32, 1) 0ms',
  transform: collapsed ? 'rotate(-90deg)' : 'none'
}));

StyledCollapseButton.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default StyledCollapseButton;
