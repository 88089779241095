import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import { rgba } from 'lib/styling/colors';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const PillButton = styled(ButtonBase)(
  ({ theme, dangerous }) => {
    const color = dangerous ? theme.palette.danger : theme.palette.primary.main;
    return {
      padding: 6,
      color: color,
      backgroundColor: rgba(color, 0.2),
      fontFamily: 'CircularStd',
      display: 'inline-flex',
      overflow: 'hidden',
      transition: theme.transitions.create(),
      ':hover, :focus': {
        color: color,
        backgroundColor: rgba(color, 0.3)
      }
    };
  },
  ({ leftHalf, rightHalf, standAlone }) => {
    if (leftHalf) {
      return {
        borderRadius: '18px 0 0 18px'
      };
    }

    if (rightHalf) {
      return {
        borderRadius: '0 18px 18px 0'
      };
    }

    if (standAlone) {
      return {
        borderRadius: 18
      };
    }
  },
  ({ standAlone, noStandAloneMargin }) => {
    if (standAlone && !noStandAloneMargin) {
      return {
        marginLeft: 6,
        marginRight: 6,
        marginBottom: 12
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    padding: 4,
    backgroundColor: 'transparent',
    border: `2px solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    ':not(:last-child)': {
      borderRight: 'none'
    },
    '> *': {
      transition: theme.transitions.create()
    },
    ':hover, :focus': {
      backgroundColor: 'transparent',
      '> *': {
        transform: 'scale(1.1)'
      }
    }
  }))
);

PillButton.propTypes = {
  leftHalf: PropTypes.bool,
  rightHalf: PropTypes.bool,
  standAlone: PropTypes.bool,
  noStandAloneMargin: PropTypes.bool,
  dangerous: PropTypes.bool
};

PillButton.defaultProps = {
  leftHalf: false,
  rightHalf: false,
  standAlone: false,
  noStandAloneMargin: false,
  dangerous: false
};

export default PillButton;
