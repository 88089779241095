/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'lib/utils/CustomPropTypes';
import ensureResultVisibleInResultList from './ensureResultVisibleInResultList';
import StyledUserResult from './StyledUserResult';
import UserAvatar from 'lib/components/UserAvatar';
import UserName from './UserName';

const UserResult = ({ user, keyboardSelected, listRef, ...props }) => {
  const resultRef = useRef(null);

  useEffect(() => {
    if (!listRef.current || !resultRef.current || !keyboardSelected) {
      return;
    }

    ensureResultVisibleInResultList(resultRef.current, listRef.current);
  }, [keyboardSelected]);

  return (
    <li
      id={`user-result-${user.email}`}
      role="option"
      aria-selected={keyboardSelected}
      ref={resultRef}
    >
      <StyledUserResult
        tabIndex={-1}
        keyboardSelected={keyboardSelected}
        {...props}
      >
        <UserAvatar user={user} size={28} />
        <UserName>{user.name}</UserName>
      </StyledUserResult>
    </li>
  );
};

UserResult.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  keyboardSelected: PropTypes.bool.isRequired,
  listRef: CustomPropTypes.ref.isRequired
};

export default UserResult;
