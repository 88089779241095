import styled from '@emotion/styled';

const SelectWrapper = styled('section')({
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  alignItems: 'center'
});

export default SelectWrapper;
