import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import DeleteButton from '../PortfolioTemplatesEditorActionButtons/DeleteButton';
import deleteTopicFromFirebase from 'lib/firebase/rubricsPortfolioTemplates/deleteTopic';
import { FormattedMessage } from 'react-intl';

const DeleteTopic = ({ topic, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteTopic = () => {
    setConfirmationDialogOpen(false);
    deleteTopicFromFirebase(topic.id);
  };

  return (
    <>
      <DeleteButton onClick={() => setConfirmationDialogOpen(true)} />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.topic.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.topic.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteTopic} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteTopic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteTopic;
