import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const PhaseName = styled('div')(({ theme }) => ({
  padding: '12px 24px',
  flex: 1,
  borderLeft: `2px solid ${theme.palette.background.border}`,
  borderRight: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border']),
  ':first-of-type': {
    borderLeft: 'none',
    borderRight: 'none'
  },
  ':last-of-type': {
    borderLeft: 'none',
    borderRight: 'none'
  },
  [mediaQuery(640)]: {
    textAlign: 'center',
    border: 'none'
  }
}));

export default PhaseName;
