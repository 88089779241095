import mode from 'lib/mode';
import Modes from 'lib/constants/Modes';
import useCurrentUrl from './useCurrentUrl';
import App from 'lib/App';

const modesForUrls = {
  [mode(Modes.PORTFOLIO).url]: Modes.PORTFOLIO,
  [mode(Modes.COACH).url]: Modes.COACH,
  [mode(Modes.EDITOR).url]: Modes.EDITOR,
  [mode(Modes.ADMIN).url]: Modes.ADMIN,
  [mode(Modes.PLATFORM_SETTINGS).url]: Modes.PLATFORM_SETTINGS
};

const useSelectedMode = () => {
  const currentUrl = useCurrentUrl();
  const attemptedSelectedMode = modesForUrls[currentUrl];

  const currentUser = App.authentication.useCurrentUser();

  if (!currentUser || !attemptedSelectedMode) {
    return null;
  }

  const restrictedToRoles = mode(attemptedSelectedMode).roleRestrictions;
  if (restrictedToRoles && !restrictedToRoles.includes(currentUser.role)) {
    return null;
  }

  return attemptedSelectedMode;
};

export default useSelectedMode;
