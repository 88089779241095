import styled from '@emotion/styled';
import OriginalStyledPart from 'components/RubricsPortfolioComponents/StyledPart';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledPart = styled(OriginalStyledPart)({
  cursor: 'initial',
  ':last-of-type': {
    paddingBottom: 12
  },
  [mediaQuery(768)]: {
    paddingBottom: 16,
    ':last-of-type': {
      paddingBottom: 16
    }
  }
});

export default StyledPart;
