import PropTypes from 'prop-types';
import InfoWrapper from './InfoWrapper';
import CreatedAtWithUserInfo from './CreatedAtWithUserInfo';
import { useIntl, FormattedMessage } from 'react-intl';

const ChallengeInfo = ({ challenge, ...props }) => {
  const intl = useIntl();
  const formattedCreatedAt = intl.formatDate(new Date(challenge.creationDate), {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <InfoWrapper {...props}>
      {!!challenge.author ? (
        <CreatedAtWithUserInfo
          userKey={challenge.author}
          createdAt={formattedCreatedAt}
        />
      ) : (
        <FormattedMessage
          id="portfolio.challenge.info.created_at"
          values={{
            createdAt: formattedCreatedAt
          }}
        />
      )}
    </InfoWrapper>
  );
};

ChallengeInfo.propTypes = {
  challenge: PropTypes.shape({
    creationDate: PropTypes.number.isRequired,
    author: PropTypes.string
  }).isRequired
};

export default ChallengeInfo;
