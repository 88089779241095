import DangerousButton from 'lib/components/DangerousButton';
import { FormattedMessage } from 'react-intl';

const DialogDeleteButton = props => (
  <DangerousButton inDialog {...props}>
    <FormattedMessage id="dialogs.actions.delete" />
  </DangerousButton>
);

export default DialogDeleteButton;
