import { useContext } from 'react';
import LearningGoalsContext from '../LearningGoalsContext';
import ChallengeInfoContext from '../ChallengeInfoContext';
import AttachmentsTitle from './AttachmentsTitle';
import AttachmentWrapper from './AttachmentWrapper';
import Attachment from './Attachment';

const LearningGoalsAttachments = props => {
  const { learningGoalsStructure } = useContext(LearningGoalsContext);
  const { asTask } = useContext(ChallengeInfoContext);

  const attachedToLearningGoalsObject = learningGoalsStructure.reduce(
    (attachmentsSoFar, topic) => ({
      ...attachmentsSoFar,
      ...topic.partsWithChallengeAttachments
    }),
    {}
  );
  const attachedToLearningGoals = Object.keys(attachedToLearningGoalsObject);

  if (attachedToLearningGoals.length === 0) {
    return null;
  }

  return (
    <section {...props}>
      <AttachmentsTitle
        plural={attachedToLearningGoals.length > 1}
        asTask={asTask}
      />
      <AttachmentWrapper>
        {attachedToLearningGoals.map((learningGoalKey, i) => (
          <Attachment
            partKey={learningGoalKey}
            appendComma={i !== attachedToLearningGoals.length - 1}
            key={learningGoalKey}
          />
        ))}
      </AttachmentWrapper>
    </section>
  );
};

export default LearningGoalsAttachments;
