import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import useSelectedMode from './useSelectedMode';
import PlatformTypes from 'lib/constants/PlatformTypes';
import Modes from 'lib/constants/Modes';

const useIsDisplayingChallengePortfolio = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const selectedMode = useSelectedMode();

  return (
    selectedPlatform === PlatformTypes.CHALLENGEMONITOR &&
    [Modes.PORTFOLIO, Modes.COACH].includes(selectedMode)
  );
};

export default useIsDisplayingChallengePortfolio;
