import App from 'lib/App';
import { userRef } from 'lib/firebase';
import { removeChallenge } from 'lib/firebase/challengePortfolio/useDeleteChallenge';

const useDeleteNewlySharedChallenge = () => {
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const removeChallengeFromNewlyShared = challengeKey =>
    userRef(currentUserUsername).child(`newlyShared/${challengeKey}`).remove();

  const deleteNewlySharedChallenge = challengeKey => {
    removeChallengeFromNewlyShared(challengeKey);
    removeChallenge(challengeKey, currentUserUsername);
  };

  return deleteNewlySharedChallenge;
};

export default useDeleteNewlySharedChallenge;
