import { useState } from 'react';
import PropTypes from 'prop-types';
import Groups from 'lib/constants/Groups';
import { ref } from 'lib/firebase';
import arrToObj from 'lib/utils/arrToObj';
import AddPartButton from './AddPartButton';
import PartPromptWithoutGroups from './PartPromptWithoutGroups';
import PartPromptWithGroups from './PartPromptWithGroups';
import { FormattedMessage } from 'react-intl';

const AddPart = ({
  subtopicId,
  subtopicGroups,
  highestPartUiPos,
  noParentAdd,
  noGroups,
  onAdd,
  ...props
}) => {
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewPart = async (
    title,
    groups = [Groups.STUDENTS, Groups.COACHES],
    dynamicGroups = []
  ) => {
    setAddPromptOpen(false);

    const id = ref('portfolioTemplates/parts').push().key;

    const parentAddPromise = noParentAdd
      ? null
      : ref(`portfolioTemplates/subtopics/${subtopicId}/parts/${id}`).set(true);

    const newPart = {
      subtopicId,
      id,
      title,
      uiPos: Math.ceil(highestPartUiPos) + 1,
      groups: arrToObj(groups, true),
      dynamicGroups: arrToObj(dynamicGroups, true)
    };

    await Promise.all([
      id,
      ref(`portfolioTemplates/parts/${id}`).set(newPart),
      parentAddPromise
    ]);

    if (onAdd) {
      onAdd(newPart);
    }
  };

  const PartPrompt = noGroups ? PartPromptWithoutGroups : PartPromptWithGroups;

  return (
    <>
      <AddPartButton onClick={() => setAddPromptOpen(true)}>
        <FormattedMessage id="portfolio_template_editor.add_part.button" />
      </AddPartButton>
      <PartPrompt
        open={addPromptOpen}
        selectedStandardGroups={subtopicGroups}
        onOk={saveNewPart}
        onCancel={() => setAddPromptOpen(false)}
      />
    </>
  );
};

AddPart.propTypes = {
  subtopicId: PropTypes.string.isRequired,
  subtopicGroups: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Groups)))
    .isRequired,
  highestPartUiPos: PropTypes.number.isRequired,
  noParentAdd: PropTypes.bool,
  noGroups: PropTypes.bool,
  onAdd: PropTypes.func
};

AddPart.defaultProps = {
  noParentAdd: false,
  noGroups: false
};

export default AddPart;
