import moment from 'moment';
import MarkersWrapper from './MarkersWrapper';
import DayMapper from './DayMapper';
import DayMarker from './DayMarker';

const DayMarkers = props => {
  const now = moment();
  return (
    <MarkersWrapper {...props}>
      <DayMapper>
        {dateInCurrentWeek => (
          <DayMarker
            isToday={dateInCurrentWeek.isSame(now, 'day')}
            key={dateInCurrentWeek.isoWeekday()}
          >
            {dateInCurrentWeek.format('ddd')}
          </DayMarker>
        )}
      </DayMapper>
    </MarkersWrapper>
  );
};

export default DayMarkers;
