import styled from '@emotion/styled';

const LoadingSpinnerWrapper = styled('section')(({ theme }) => ({
  paddingTop: 56,
  paddingBottom: 56,
  fontFamily: 'CircularStd',
  backgroundColor: theme.palette.action.hover,
  borderRadius: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

export default LoadingSpinnerWrapper;
