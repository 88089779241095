import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import { useMediaQuery } from '@material-ui/core';
import { DescriptionOutlined as DraftsIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const StyledButton = styled(Button)(({ theme, selected }) => ({
  marginLeft: 'auto',
  color: selected ? `${theme.palette.primary.main} !important` : null,
  [mediaQuery(640)]: {
    marginTop: 9,
    marginLeft: 0,
    marginRight: 'auto'
  }
}));

const DraftsButton = ({ selected, ...props }) => {
  const shouldUseOutlinedVariant = useMediaQuery('(max-width: 640px)');
  return (
    <StyledButton
      variant={shouldUseOutlinedVariant ? 'outlined' : 'text'}
      disabled={selected}
      selected={selected}
      startIcon={<DraftsIcon />}
      {...props}
    >
      <FormattedMessage id="group_names.drafts" />
    </StyledButton>
  );
};

DraftsButton.propTypes = {
  selected: PropTypes.bool.isRequired
};

export default DraftsButton;
