import { challengeRef as getChallengeRef, taskRef } from 'lib/firebase';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';

const useChallenge = (challengeKey = null, asTask = false) => {
  const challengeRef = asTask
    ? taskRef(challengeKey)
    : getChallengeRef(challengeKey);

  const [loading, challenge] = useFirebaseValue(challengeRef);

  if (!challengeKey) {
    return [false, null];
  }

  if (challenge) {
    challenge.key = challengeKey;
  }

  return [loading, challenge];
};

export default useChallenge;
