import HideOnLowWidth from 'lib/components/HideOnLowWidth';
import { Tooltip, IconButton } from '@material-ui/core';
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CopyButton = props => (
  <HideOnLowWidth width={640}>
    <Tooltip
      title={
        <FormattedMessageWithChallengeNaming id="portfolio.challenge.copy.button_tooltip" />
      }
    >
      <IconButton {...props}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  </HideOnLowWidth>
);

export default CopyButton;
