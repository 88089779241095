import styled from '@emotion/styled';

const StyledHeader = styled('header')(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.default,
  borderBottom: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['background-color', 'border-bottom']),
  display: 'flex',
  zIndex: 2
}));

export default StyledHeader;
