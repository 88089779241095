// https://weeknumber.net/how-to/javascript
const weekFromDate = dateWithMutationRisks => {
  const date = new Date(dateWithMutationRisks.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  const week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86_400_000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

export default weekFromDate;
