const getHighestUiPosFromSortedItems = sortedItems => {
  const lastItem = sortedItems[sortedItems.length - 1];

  if (!lastItem?.uiPos) {
    return 0;
  }

  return lastItem.uiPos;
};

export default getHighestUiPosFromSortedItems;
