import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';

const PortfolioTemplatesProvider = props => {
  const [loading, portfolioTemplates] = useFirebaseValue(
    ref('portfolioTemplates')
  );

  if (loading) {
    return <LoadingSpinner marginTop={112} centered />;
  }

  return (
    <PortfolioTemplatesContext.Provider value={portfolioTemplates} {...props} />
  );
};

export default PortfolioTemplatesProvider;
