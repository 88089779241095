import Phases from 'lib/constants/Phases';

const phaseNames = {
  [Phases.TODO]: 'To do',
  [Phases.PROGRESS]: 'In progress',
  [Phases.DONE]: 'Done'
};

const phaseName = phase => phaseNames[phase];

export default phaseName;
