import getLanguage from 'lib/utils/getLanguage';

const sharedMonthDateRangeAsText = (startDate, endDate) => {
  if (getLanguage() === 'en') {
    return `${startDate.format('MMM D')} – ${endDate.format('D')}`;
  }

  return `${startDate.format('D')} – ${endDate.format('D MMM')}`;
};

const dateAsText = momentObject => {
  if (getLanguage() === 'en') {
    return momentObject.format('MMM D');
  }

  return momentObject.format('D MMM');
};

const getDateRangeAsText = (startDate, endDate) => {
  const isDateRangeInSameMonth = startDate.month() === endDate.month();

  if (isDateRangeInSameMonth) {
    return sharedMonthDateRangeAsText(startDate, endDate);
  }

  return `${dateAsText(startDate)} – ${dateAsText(endDate)}`;
};

export default getDateRangeAsText;
