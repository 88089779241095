import { ref as getRef } from 'lib/firebase';

export const createStore = () => {
  const listenerStore = {};

  listenerStore.register = (ref, listener) => {
    listenerStore[ref] = listener;
  };

  listenerStore.unregisterAll = () => {
    for (const ref in listenerStore) {
      const listener = listenerStore[ref];
      getRef(ref).off('value', listener);
    }
  };

  return listenerStore;
};

export const create = listener => snapshot => {
  const value = snapshot.val();
  return listener(value);
};

export const attach = (ref, listener) => getRef(ref).on('value', listener);
