import { useContext } from 'react';
import App from 'lib/App';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import sortByUiPos from 'lib/utils/sortByUiPos';
import CollapsedPortfolioItemsContext from './CollapsedPortfolioItemsContext';
import PortfolioWrapper from './PortfolioWrapper';
import CollapseAllButton from './CollapseAllButton';
import Topic from './Topic';

const Portfolio = props => {
  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useContentSetting(
    'enableAggressiveRubricCollapsing'
  );

  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const topics = safeValues(portfolioTemplates?.topics)
    .filter(userCanAccessItem(portfolioUser, true))
    .sort(sortByUiPos);

  return (
    <CollapsedPortfolioItemsContext.AutomatedProvider>
      <PortfolioWrapper {...props}>
        {aggressiveRubricCollapsingEnabled && topics.length > 0 && (
          <CollapseAllButton />
        )}
        {topics.map((topic, index) => (
          <Topic topic={topic} isFirstTopic={index === 0} key={topic.id} />
        ))}
      </PortfolioWrapper>
    </CollapsedPortfolioItemsContext.AutomatedProvider>
  );
};

export default Portfolio;
