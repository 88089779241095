import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import useLearningGoalsModel from './useLearningGoalsModel';
import FieldName from '../utils/FieldName';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import LearningGoalsContext from './LearningGoalsContext';
import LearningGoalsAttachments from './LearningGoalsAttachments';
import ChallengeInfoContext from './ChallengeInfoContext';
import UncollapsedLearningGoals from './UncollapsedLearningGoals';

const LearningGoals = () => {
  const { challenge, asTask } = useContext(FieldContext);

  const [open, setOpen] = useState(false);

  const learningGoalsModel = useLearningGoalsModel();
  const buttonLabel = learningGoalsModel?.buttonLabel;

  return (
    <article>
      <FieldName />
      <ChallengeInfoContext.Provider
        value={{
          challenge,
          asTask
        }}
      >
        <LearningGoalsContext.AutomatedProvider
          learningGoalsModel={learningGoalsModel}
        >
          <Button variant="outlined" onClick={() => setOpen(true)}>
            {buttonLabel || (
              <FormattedMessage id="portfolio.challenge.learning_goals.button.default_label" />
            )}
          </Button>
          <LearningGoalsAttachments />
          {open && <UncollapsedLearningGoals onClose={() => setOpen(false)} />}
        </LearningGoalsContext.AutomatedProvider>
      </ChallengeInfoContext.Provider>
    </article>
  );
};

export default LearningGoals;
