/* eslint-disable react/jsx-no-target-blank */

import PropTypes from 'prop-types';
import ReportTitle from './ReportTitle';
import { Button } from '@material-ui/core';
import {
  EmailOutlined as EmailIcon,
  ErrorOutline as IssueIcon
} from '@material-ui/icons';
import Space from 'lib/components/Space';
import ReportViaSupportSystem from './ReportViaSupportSystem';
import { FormattedMessage } from 'react-intl';

const ErrorReporting = ({ error, errorComponentStack, ...props }) => (
  <>
    <ReportTitle>
      <FormattedMessage id="error_boundary.report.title" />
    </ReportTitle>
    <Button
      variant="outlined"
      startIcon={<EmailIcon />}
      component="a"
      href={`mailto:bugs@egodact.com?subject=${error}&body=${errorComponentStack}`}
    >
      <FormattedMessage id="error_boundary.report.email_button" />
    </Button>
    <Space height={12} />
    <Button
      variant="outlined"
      startIcon={<IssueIcon />}
      component="a"
      href={`https://github.com/egodact/egodact-issues/issues/new?title=${error}&body=\`${errorComponentStack}\``}
      target="_blank"
    >
      <FormattedMessage id="error_boundary.report.github_issue_button" />
    </Button>
    <ReportViaSupportSystem />
  </>
);

ErrorReporting.propTypes = {
  error: PropTypes.string.isRequired,
  errorComponentStack: PropTypes.string.isRequired
};

export default ErrorReporting;
