import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../../FieldContext';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import IndividualSettingsFieldForm from './IndividualSettingsFieldForm';
import ReuseLearningGoals from './ReuseLearningGoals';
import { FormattedMessage } from 'react-intl';

const SettingsDialog = ({ onClose, ...props }) => {
  const { field } = useContext(FieldContext);
  const learningGoalsModelKey = field.reusedLearningGoals || field.key;
  const learningGoalsModelRef = ref(
    `models/learningGoals/${learningGoalsModelKey}`
  );

  const nameRef = learningGoalsModelRef.child('name');
  const buttonLabelRef = learningGoalsModelRef.child('buttonLabel');

  const [name, setName] = useState('');
  const [buttonLabel, setButtonLabel] = useState('');

  const saveAndClose = () => {
    nameRef.set(name);
    buttonLabelRef.set(buttonLabel);
    onClose();
  };

  return (
    <Dialog onClose={saveAndClose} {...props}>
      <Dialog.Title>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.settings.title" />
      </Dialog.Title>
      <Dialog.Content>
        <IndividualSettingsFieldForm
          id="name"
          value={name}
          onChange={setName}
          firebaseRef={nameRef}
        />
        <IndividualSettingsFieldForm
          id="button_label"
          value={buttonLabel}
          onChange={setButtonLabel}
          firebaseRef={buttonLabelRef}
        />
        <ReuseLearningGoals />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Actions.DoneButton onClick={saveAndClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SettingsDialog;
