import PropTypes from 'prop-types';
import NoItemsMessage from 'components/LearningGoals/NoItemsMessage';
import LearningGoalsAppBar from 'components/LearningGoals/LearningGoalsAppBar';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import TileGrid from 'components/LearningGoals/TileGrid';
import Subtopic from './Subtopic';
import { FormattedMessage } from 'react-intl';

const Subtopics = ({ topicName, subtopics, onClose, ...props }) => (
  <>
    <LearningGoalsAppBar>
      <LearningGoalsAppBar.IconButton onClick={onClose}>
        <BackIcon />
      </LearningGoalsAppBar.IconButton>
      <LearningGoalsAppBar.Title>
        <FormattedMessage
          id="portfolio.challenge.learning_goals.subtopics_header_title"
          values={{
            topicName
          }}
        />
      </LearningGoalsAppBar.Title>
    </LearningGoalsAppBar>
    {subtopics.length === 0 ? (
      <NoItemsMessage>
        <FormattedMessage id="portfolio.challenge.learning_goals.no_subtopics" />
      </NoItemsMessage>
    ) : (
      <TileGrid {...props}>
        {subtopics.map(subtopic => (
          <Subtopic subtopic={subtopic} key={subtopic.key} />
        ))}
      </TileGrid>
    )}
  </>
);

Subtopics.propTypes = {
  topicName: PropTypes.string.isRequired,
  subtopics: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired
    })
  ),
  onClose: PropTypes.func.isRequired
};

export default Subtopics;
