import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { KeyboardArrowRight as ArrowRightIcon } from '@material-ui/icons';

const RotatableIconButton = styled(IconButton, {
  shouldForwardProp: isPropValid
})(({ theme, rotated }) => ({
  transform: rotated ? 'rotate(90deg)' : null,
  transition: theme.transitions.create()
}));

const SlosCollapseButton = ({ slosCollapsed, setSlosCollapsed, ...props }) => (
  <RotatableIconButton
    rotated={!slosCollapsed}
    onClick={() => setSlosCollapsed(!slosCollapsed)}
    {...props}
  >
    <ArrowRightIcon />
  </RotatableIconButton>
);

SlosCollapseButton.propTypes = {
  slosCollapsed: PropTypes.bool.isRequired,
  setSlosCollapsed: PropTypes.func.isRequired
};

export default SlosCollapseButton;
