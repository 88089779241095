/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import GroupsManagerButton from './GroupsManagerButton';
import PromptGroupSelectWithClearButton from './PromptGroupSelectWithClearButton';
import arrToObj from 'lib/utils/arrToObj';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { FormattedMessage } from 'react-intl';

const GroupsManager = ({ variant, itemGroups, itemGroupsRef, ...props }) => {
  const [groupsManagerOpen, setGroupsManagerOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(safeKeys(itemGroups));

  useEffect(() => {
    setSelectedGroups(safeKeys(itemGroups));
  }, [JSON.stringify(itemGroups)]);

  const saveGroupChanges = () => {
    setGroupsManagerOpen(false);
    itemGroupsRef.set(arrToObj(selectedGroups, true));
  };

  return (
    <>
      <GroupsManagerButton
        onClick={() => setGroupsManagerOpen(true)}
        {...props}
      />
      <Dialog
        open={groupsManagerOpen}
        onClose={saveGroupChanges}
        onEnterClose={saveGroupChanges}
      >
        <Dialog.Title>
          <FormattedMessage
            id={`editor.model_editor.${variant}_editor.groups_manager_dialog.title`}
          />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage
              id={`editor.model_editor.${variant}_editor.groups_manager_dialog.text`}
            />
          </Dialog.ContentText>
          <PromptGroupSelectWithClearButton
            value={selectedGroups}
            onChange={e => setSelectedGroups(e.target.value)}
            onClear={e => setSelectedGroups([])}
          />
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={saveGroupChanges} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

GroupsManager.propTypes = {
  variant: PropTypes.oneOf(['phase', 'field']).isRequired,
  itemGroups: PropTypes.objectOf(PropTypes.bool),
  itemGroupsRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default GroupsManager;
