import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SloPeriodContext from '../SloPeriodContext';
import { useDebounce } from 'use-debounce';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { ref } from 'lib/firebase';
import { TextField } from '@material-ui/core';
import PortfolioModes from 'lib/constants/PortfolioModes';
import { FormattedMessage } from 'react-intl';

const FeedbackField = ({ variant, ...props }) => {
  const sloPeriod = useContext(SloPeriodContext);
  const [feedback, setFeedback] = useState(sloPeriod.feedback?.[variant] || '');
  const [debouncedFeedback] = useDebounce(feedback, 500);

  const portfolioMode = useContext(PortfolioModeContext);

  useEffect(() => {
    if (portfolioMode === variant) {
      const feedbackRef = ref(
        `sloPeriods/${sloPeriod.key}/feedback/${variant}`
      );
      feedbackRef.set(debouncedFeedback || null);
    }
  }, [debouncedFeedback, sloPeriod.key, variant, portfolioMode]);

  return (
    <TextField
      variant="outlined"
      label={
        <FormattedMessage
          id={`slo_system.slo_period.feedback.field_labels.${variant.toLowerCase()}`}
        />
      }
      value={feedback}
      onChange={e => setFeedback(e.target.value)}
      disabled={variant !== portfolioMode}
      rows={12}
      rowsMax={12}
      multiline
      fullWidth
      {...props}
    />
  );
};

FeedbackField.propTypes = {
  variant: PropTypes.oneOf(Object.values(PortfolioModes)).isRequired
};

export default FeedbackField;
