import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import getTileHoverColor from './getTileHoverColor';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledTile = styled(motion.article)(
  ({ theme, color }) => ({
    position: 'relative',
    padding: 12,
    backgroundColor: color,
    boxSizing: 'border-box',
    wordBreak: 'break-all',
    overflow: 'hidden',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: theme.transitions.create(),
    transitionProperty: 'background-color',
    ':hover': {
      backgroundColor: getTileHoverColor(color, theme)
    },
    [mediaQuery(900)]: {
      flex: 1,
      minWidth: '33.33%',
      minHeight: '33.33vh'
    },
    [mediaQuery(650)]: {
      minWidth: '50%',
      minHeight: '50vh'
    },
    [mediaQuery(480)]: {
      minWidth: '100%'
    }
  }),
  ({ theme, color, studentCompletion, coachCompletion, hasCompletionFill }) => {
    if (!hasCompletionFill) {
      return;
    }

    const studentFillExceedsCoachFill = studentCompletion > coachCompletion;

    return {
      backgroundColor: getTileHoverColor(color, theme),
      ':hover::before, :hover::after': {
        width: '100%'
      },
      '::before, ::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        backgroundColor: color,
        transition: theme.transitions.create()
      },
      '::before': {
        width: `${studentCompletion}%`,
        opacity: studentFillExceedsCoachFill ? 0.5 : 1
      },
      '::after': {
        width: `${coachCompletion}%`,
        opacity: studentFillExceedsCoachFill ? 1 : 0.5
      }
    };
  },
  ({ hasCompletionFill, open }) => {
    if (hasCompletionFill && open) {
      return {
        '::before, ::after': {
          display: 'none'
        }
      };
    }
  },
  ({ theme, open }) => {
    if (!open) {
      return;
    }

    return {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: 2,
      display: 'block',
      ':hover': {
        backgroundColor: theme.palette.background.default
      },
      '&&': {
        width: '100%',
        height: '100%'
      }
    };
  }
);

StyledTile.propTypes = {
  hasCompletionFill: PropTypes.bool,
  studentCompletion: PropTypes.number,
  coachCompletion: PropTypes.number,
  color: PropTypes.string
};

StyledTile.defaultProps = {
  hasCompletionFill: false,
  studentCompletion: 0,
  coachCompletion: 0,
  // material-ui's common.white breaks the text color detection
  color: '#FFFFFF'
};

const TileWithHover = props => (
  <StyledTile initial="initial" whileHover="hover" {...props} />
);

export default TileWithHover;
