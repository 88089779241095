import styled from '@emotion/styled';

const LoadingSpinnerWrapper = styled('section')(({ theme }) => ({
  marginBottom: 12,
  paddingTop: 18,
  paddingBottom: 18,
  background: theme.palette.action.hover,
  borderRadius: 4,
  ':last-child': {
    marginBottom: 0
  }
}));

export default LoadingSpinnerWrapper;
