import firebase from 'firebase/compat/app';

export const ref = path => firebase.database().ref(path);

export const rowRef = key => ref(`rows/${key}`);
export const challengeRef = key => ref(`challenges/${key}`);
export const taskRef = key => ref(`tasks/${key}`);
export const userRef = key => ref(`users/${key}`);

export const value = async (ref, noKey) => {
  const snapshot = await ref.once('value');
  const firebaseValue = snapshot.val();

  if (typeof firebaseValue !== 'object' || noKey) {
    return firebaseValue;
  }

  return {
    key: ref.key,
    ...firebaseValue
  };
};
