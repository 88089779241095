import PropTypes from 'prop-types';
import StyledDropdownButton from './StyledDropdownButton';
import { ArrowDropDown as DropdownArrowIcon } from '@material-ui/icons';

const DropdownButton = props => (
  <StyledDropdownButton {...props}>
    <DropdownArrowIcon />
  </StyledDropdownButton>
);

DropdownButton.propTypes = {
  open: PropTypes.bool.isRequired
};

export default DropdownButton;
