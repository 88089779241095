import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const RowWrapper = styled('section')(({ theme, hidden }) => ({
  height: hidden ? 0 : null,
  borderBottom: hidden
    ? 'none'
    : `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-bottom']),
  opacity: hidden ? 0 : null,
  display: 'flex',
  [mediaQuery(700)]: {
    flexDirection: 'column',
    overflow: 'hidden'
  }
}));

RowWrapper.propTypes = {
  hidden: PropTypes.bool.isRequired
};

export default RowWrapper;
