import { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../UserContext';
import App from 'lib/App';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import SelectLoadingPlaceholder from './SelectLoadingPlaceholder';
import UserSelectFieldWrapper from '../UserSelectFieldWrapper';
import GroupSelectField from './GroupSelectField';
import { updateUserGroups, addUserToGroups } from '../../utils/groupUsers';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const GroupSelect = ({ asManagedGroups, ...props }) => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const [loading, groups] = useFirebaseValue(ref('groups'));

  if (loading) {
    return <SelectLoadingPlaceholder />;
  }

  const changeGroups = selectedGroups => {
    updateUserGroups(asManagedGroups)(selectedGroups, userUsername);
    addUserToGroups(asManagedGroups, groups)(selectedGroups, userUsername);
  };

  const noGroups = !groups || Object.keys(groups).length === 0;
  const selectedGroups = safeKeys(
    asManagedGroups ? user.managedGroups : user.groups
  );

  return (
    <UserSelectFieldWrapper withMarginRight {...props}>
      <GroupSelectField
        value={selectedGroups}
        onChange={e => changeGroups(e.target.value)}
        groups={groups}
        asManagedGroups={asManagedGroups}
        disabled={noGroups}
      />
    </UserSelectFieldWrapper>
  );
};

GroupSelect.propTypes = {
  asManagedGroups: PropTypes.bool.isRequired
};

export default GroupSelect;
