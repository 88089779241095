import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import TopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledTopic/TopicTitleWrapper';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import TopicContent from 'components/RubricsPortfolioComponents/StyledTopic/TopicContent';
import sortByUiPos from 'lib/utils/sortByUiPos';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import App from 'lib/App';

const cursorFix = {
  cursor: 'initial'
};

const Topic = ({ topic, children, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  const allSubtopics = portfolioTemplates?.subtopics;
  const desiredSubtopicsKeys = safeKeys(topic.subtopics);
  const subtopics = desiredSubtopicsKeys
    .map(subtopicKey => allSubtopics?.[subtopicKey])
    .filter(subtopic => !!subtopic)
    .sort(sortByUiPos);

  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useContentSetting(
    'enableAggressiveRubricCollapsing'
  );
  const collapsedByDefault = aggressiveRubricCollapsingEnabled;
  const [collapsed, setCollapsed] = useState(collapsedByDefault);

  if (subtopics.length === 0) {
    return null;
  }

  return (
    <section {...props}>
      <TopicTitleWrapper style={cursorFix} editor>
        <h2>{topic.title}</h2>
        <CollapseButton
          collapsed={collapsed}
          onClick={() => setCollapsed(!collapsed)}
        />
      </TopicTitleWrapper>
      <TopicContent collapsed={collapsed}>{children(subtopics)}</TopicContent>
    </section>
  );
};

Topic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.func.isRequired
};

export default Topic;
