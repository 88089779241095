import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ViewWrapper = styled('section')(({ hidden }) => ({
  display: hidden ? 'none' : 'initial'
}));

ViewWrapper.propTypes = {
  hidden: PropTypes.bool.isRequired
};

export default ViewWrapper;
