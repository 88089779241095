import styled from '@emotion/styled';
import { Radio } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const ResponsiveRadio = styled(Radio)({
  [mediaQuery(420)]: {
    paddingTop: 0
  }
});

export default ResponsiveRadio;
