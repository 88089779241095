import App from 'lib/App';
import StyledFooter from './StyledFooter';
import FooterLogo from './FooterLogo';
import FooterLink from './FooterLink';
import Dash from './Dash';
import { FormattedMessage } from 'react-intl';

const AppFooter = props => {
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();
  return (
    <StyledFooter wideContent={isDisplayingChallengePortfolio} {...props}>
      <FooterLogo />
      <FooterLink href="https://egodact.com/privacy" target="_blank">
        <FormattedMessage id="footer.links.privacy" />
      </FooterLink>
      <Dash />
      <FooterLink href="https://egodact.com/open-source" target="_blank">
        <FormattedMessage id="footer.links.open_source" />
      </FooterLink>
    </StyledFooter>
  );
};

export default AppFooter;
