import { createContext, useState, useEffect } from 'react';
import PlatformTypes from 'lib/constants/PlatformTypes';
import App from 'lib/App';

const SelectedPlatformContext = createContext([null, () => {}]);

const SELECTED_PLATFORM_CACHE_KEY = 'selectedPlatform';
const DEFAULT_PLATFORM = PlatformTypes.CHALLENGEMONITOR;

const AutomatedSelectedPlatformContextProvider = props => {
  const preselectedPlatform = localStorage.getItem(SELECTED_PLATFORM_CACHE_KEY);
  const configuredDefaultPlatform = App.instanceConfig.useSetting(
    'defaultPlatform'
  );

  const [selectedPlatform, setSelectedPlatform] = useState(
    preselectedPlatform || configuredDefaultPlatform || DEFAULT_PLATFORM
  );

  useEffect(() => {
    localStorage.setItem('selectedPlatform', selectedPlatform);
  }, [selectedPlatform]);

  return (
    <SelectedPlatformContext.Provider
      value={[selectedPlatform, setSelectedPlatform]}
      {...props}
    />
  );
};

SelectedPlatformContext.AutomatedProvider = AutomatedSelectedPlatformContextProvider;

export default SelectedPlatformContext;
