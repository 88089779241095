import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';
import fontConfig from './fontConfig';
import getHighContrastModeThemeAdjustments from './getHighContrastModeThemeAdjustments';
import useActivePalette from './useActivePalette';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

const buildTheme = activePalette => {
  const theme = createMuiTheme(
    {
      palette: activePalette,
      isDark: activePalette.type === 'dark',
      transitions: {
        simple: 'all 300ms ease-in-out'
      },
      ...fontConfig
    },
    getHighContrastModeThemeAdjustments(activePalette)
  );

  theme.extend = themeExtensionObject =>
    createMuiTheme(theme, themeExtensionObject);

  return theme;
};

const ThemeProvider = props => {
  const activePalette = useActivePalette();
  const theme = buildTheme(activePalette);
  return (
    <MuiThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme} {...props} />
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
