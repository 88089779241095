import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const StyledAddButton = styled(Button)({
  marginTop: 4,
  width: 'calc(100% - 48px)'
});

const AddButton = props => (
  <StyledAddButton variant="outlined" startIcon={<AddIcon />} {...props}>
    <FormattedMessage id="user_manager.user_list.user.expertise_manager.expertise_items.add_item.button" />
  </StyledAddButton>
);

export default AddButton;
