import PropTypes from 'prop-types';
import StyledIcon from './StyledIcon';

const fullSizeIconStyles = {
  width: '100%',
  height: '100%'
};

const SupportTileIcon = ({ icon: Icon, ...props }) => (
  <StyledIcon {...props}>
    <Icon style={fullSizeIconStyles} />
  </StyledIcon>
);

SupportTileIcon.propTypes = {
  icon: PropTypes.elementType.isRequired
};

export default SupportTileIcon;
