import LoadingSpinnerWrapper from './LoadingSpinnerWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const RubricsLoadingSpinner = props => (
  <LoadingSpinnerWrapper {...props}>
    <LoadingSpinner />
    <Space width={18} />
    <FormattedMessage id="portfolio.challenge.rubrics.loading_message" />
  </LoadingSpinnerWrapper>
);

export default RubricsLoadingSpinner;
