import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';

const StyledRadioWrapper = styled(FormControlLabel)({
  marginBottom: 6,
  ':last-child': {
    marginBottom: 12
  }
});

export default StyledRadioWrapper;
