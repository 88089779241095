import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const FeedbackListTitle = styled('h4')(
  ({ theme }) => ({
    marginBottom: 6,
    fontWeight: 400,
    color: theme.palette.primary.main
  }),
  ({ theme, mobileVariant }) => {
    if (mobileVariant) {
      return {
        marginBottom: 24,
        color: theme.palette.text.primary,
        fontSize: 24,
        ':last-child': {
          marginBottom: 0
        }
      };
    }
  }
);

FeedbackListTitle.propTypes = {
  mobileVariant: PropTypes.bool.isRequired
};

export default FeedbackListTitle;
