import { forwardRef } from 'react';
import { motion } from 'framer-motion';
import { IconButton } from '@material-ui/core';

const MotionIconButton = motion.custom(IconButton);

const HeaderActionIconButton = forwardRef((props, ref) => (
  <MotionIconButton
    initial={{
      scale: 0
    }}
    animate={{
      scale: 1
    }}
    {...props}
    ref={ref}
  />
));

export default HeaderActionIconButton;
