import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import Space from 'lib/components/Space';
import TextEditor from '../utils/TextEditor';

const EditText = ({ onEditClose, ...props }) => {
  const { field, fieldRef, challenge } = useContext(FieldContext);

  const handleSave = value => {
    fieldRef.set(value);

    onEditClose();
  };

  // We need to convert to string since field types can change
  const initialValue = (challenge[field.key] || '').toString();

  return (
    <section>
      <FieldName />
      <Space height={12} />
      <TextEditor initialValue={initialValue} onSave={handleSave} />
    </section>
  );
};

EditText.propTypes = {
  onEditClose: PropTypes.func.isRequired
};

export default EditText;
