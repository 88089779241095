import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const GroupWrapper = styled('article')({
  marginTop: -4,
  marginBottom: -4,
  paddingLeft: 12,
  paddingRight: 12,
  minWidth: '50%',
  maxWidth: '50%',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(1024)]: {
    width: '100%',
    minWidth: 0,
    maxWidth: '100%'
  }
});

export default GroupWrapper;
