import { IconButton } from '@material-ui/core';
import { Close as CancelIcon } from '@material-ui/icons';

const CancelDeletionButton = props => (
  <IconButton edge="end" {...props}>
    <CancelIcon />
  </IconButton>
);

export default CancelDeletionButton;
