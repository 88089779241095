import PropTypes from 'prop-types';
import Space from 'lib/components/Space';
import FieldNameWrapper from '../../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../../utils/FieldName/StyledFieldName';
import { FormattedMessage } from 'react-intl';
import TextEditor from '../../utils/TextEditor';

const NewLogEntryForm = ({ logEntryName, onSave, onCancel, ...props }) => {
  const handleSave = value => {
    if (value === null) {
      onCancel();

      return;
    }

    onSave(value);
  };

  return (
    <section>
      <Space height={18} />
      <FieldNameWrapper>
        <StyledFieldName>
          <FormattedMessage
            id="portfolio.challenge.log_entries.add.title"
            values={{ logEntryName }}
          />
        </StyledFieldName>
      </FieldNameWrapper>
      <TextEditor onSave={handleSave} />
    </section>
  );
};

NewLogEntryForm.propTypes = {
  logEntryName: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default NewLogEntryForm;
