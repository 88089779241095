import ActionButton from './ActionButton';
import { Edit as EditIcon } from '@material-ui/icons';

const EditButton = props => (
  <ActionButton {...props}>
    <EditIcon />
  </ActionButton>
);

export default EditButton;
