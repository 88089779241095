/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import weekFromDate from '../weekFromDate';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';

const useGapminderState = timestamps => {
  const currentYear = new Date().getFullYear();
  const currentWeek = weekFromDate(new Date()) + 1;

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const updateSelectedYear = year => {
    const weeks = Object.keys(timestamps[year]);
    const firstWeek = Number(weeks[0]);

    setSelectedYear(Number(year));
    setSelectedWeek(firstWeek);
  };

  const [selectedWeek, setSelectedWeek] = useState(currentWeek);

  const resetGapminder = () => {
    setSelectedYear(currentYear);
    setSelectedWeek(currentWeek);
  };

  const portfolioUser = useContext(PortfolioUserContext);
  useEffect(() => {
    resetGapminder();
  }, [portfolioUser?.email]);

  return [selectedYear, updateSelectedYear, selectedWeek, setSelectedWeek];
};

export default useGapminderState;
