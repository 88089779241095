import Roles from 'lib/constants/Roles';

const rolesAsNumber = {
  [Roles.STUDENT]: 4,
  [Roles.COACH]: 3,
  [Roles.EDITOR]: 2,
  [Roles.HALF_ADMIN]: 1,
  [Roles.ADMIN]: 0
};

const getSafeRoleNumber = role => {
  const roleNumber = rolesAsNumber[role];
  return typeof roleNumber === 'number'
    ? roleNumber
    : rolesAsNumber[Roles.STUDENT];
};

const sortByRole = (a, b) =>
  getSafeRoleNumber(a.role) - getSafeRoleNumber(b.role);

export default sortByRole;
