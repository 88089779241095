import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PossiblyDangerousIconButton from 'lib/components/PossiblyDangerousIconButton';

const ActionButton = styled(PossiblyDangerousIconButton)({
  marginTop: -6,
  marginBottom: -6,
  padding: 6,
  ':first-of-type': {
    marginLeft: 6
  }
});

ActionButton.propTypes = {
  dangerous: PropTypes.bool
};

ActionButton.defaultProps = {
  dangerous: false
};

export default ActionButton;
