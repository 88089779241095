import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const NoItemsMessage = styled('h2')(({ theme, accountForHeader }) => ({
  position: 'relative',
  top: accountForHeader ? 'calc(50% - 64px)' : '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  paddingTop: 12,
  paddingBottom: 12,
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

NoItemsMessage.propTypes = {
  accountForHeader: PropTypes.bool
};

NoItemsMessage.defaultProps = {
  accountForHeader: false
};

export default NoItemsMessage;
