import PropTypes from 'prop-types';
import FormWrapper from './FormWrapper';
import AllowedEmailTextField from '../AllowedEmailTextField';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Close as DeleteIcon } from '@material-ui/icons';

const AllowedEmailForm = ({ allowedEmail, onEdit, onRemove, ...props }) => (
  <FormWrapper {...props}>
    <AllowedEmailTextField
      value={allowedEmail}
      onChange={e => onEdit(e.target.value.trim())}
    />
    <DangerousIconButton onClick={onRemove}>
      <DeleteIcon />
    </DangerousIconButton>
  </FormWrapper>
);

AllowedEmailForm.propTypes = {
  allowedEmail: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default AllowedEmailForm;
