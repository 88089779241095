/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RowDeletionContext from '../../RowDeletionContext';
import useInterval from 'use-interval';
import DangerColorCircularProgress from './DangerColorCircularProgress';

const DeletionCountdownIndicator = ({
  deletionProgress,
  setDeletionProgress,
  ...props
}) => {
  const { deleteRow, rowDeleting } = useContext(RowDeletionContext);

  useInterval(() => {
    if (rowDeleting && deletionProgress < 110) {
      setDeletionProgress(deletionProgress + 5);
    }
  }, 500);

  useEffect(() => {
    if (!rowDeleting) {
      setDeletionProgress(0);
    }
  }, [rowDeleting]);

  const [rowDeletionFinished, setRowDeletionFinished] = useState(false);
  if (!rowDeletionFinished && rowDeleting && deletionProgress === 110) {
    deleteRow();
    setRowDeletionFinished(true);
  }

  return (
    <DangerColorCircularProgress
      variant="static"
      value={Math.min(100, deletionProgress)}
      {...props}
    />
  );
};

DeletionCountdownIndicator.propTypes = {
  deletionProgress: PropTypes.number.isRequired,
  setDeletionProgress: PropTypes.func.isRequired
};

export default DeletionCountdownIndicator;
