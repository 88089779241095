import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rgba } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const HOVER_SHADOW = `${rgba(common.black, 0.2)} 0 1px 16px, ${rgba(
  common.black,
  0.2
)} 0 1px 16px`;

const ProgressIndicator = styled('article')(
  ({ theme, progress, sliding, reversed }) => ({
    position: 'absolute',
    top: -4,
    left: reversed ? null : `${progress}%`,
    right: reversed ? `${progress}%` : null,
    transform: 'translateX(-50%)',
    width: 4,
    height: 26,
    backgroundColor: grey[600],
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    boxShadow: theme.shadows[2],
    transition: sliding ? null : theme.transitions.create(),
    ':hover': {
      boxShadow: HOVER_SHADOW,
      zIndex: 1,
      '.indicator-label': {
        boxShadow: HOVER_SHADOW,
        borderColor: grey[500]
      },
      '.indicator-label-text': {
        maxWidth: 'none'
      }
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    border: `1px solid ${theme.palette.background.default}`,
    boxSizing: 'border-box',
    ':hover .indicator-label': {
      borderColor: theme.palette.divider
    }
  }))
);

ProgressIndicator.propTypes = {
  progress: PropTypes.number.isRequired,
  sliding: PropTypes.bool,
  reversed: PropTypes.bool.isRequired
};

ProgressIndicator.defaultProps = {
  sliding: false
};

export default ProgressIndicator;
