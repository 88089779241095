import styled from '@emotion/styled';

const NameOverflowWrapper = styled('h4')({
  fontWeight: 400,
  opacity: 0.96,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export default NameOverflowWrapper;
