import { useContext } from 'react';
import PhaseContext from '../../PhaseContext';
import useCurrentPhaseRef from '../../useCurrentPhaseRef';
import GroupsManager from '../../GroupsManager';

const PhaseGroups = props => {
  const { phase } = useContext(PhaseContext);
  const phaseRef = useCurrentPhaseRef();
  return (
    <GroupsManager
      variant="phase"
      itemGroups={phase.groups}
      itemGroupsRef={phaseRef.child('groups')}
      {...props}
    />
  );
};

export default PhaseGroups;
