import styled from '@emotion/styled';

const ButtonsWrapper = styled('div')({
  marginLeft: 6,
  marginRight: 6,
  marginBottom: 12,
  display: 'inline-flex'
});

export default ButtonsWrapper;
