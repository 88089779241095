import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import GroupsManager from '../../../GroupsManager';

const FieldGroups = props => {
  const { field } = useContext(FieldContext);
  const fieldRef = useCurrentFieldRef();
  return (
    <GroupsManager
      variant="field"
      itemGroups={field.groups}
      itemGroupsRef={fieldRef.child('groups')}
      {...props}
    />
  );
};

export default FieldGroups;
