import PropTypes from 'prop-types';
import ArrowOverlay from './ArrowOverlay';
import { IconButton } from '@material-ui/core';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';

const ShiftArrow = ({ hidden, isLeftArrow, isRightArrow, ...props }) => (
  <ArrowOverlay
    hidden={hidden}
    isLeftArrowOverlay={isLeftArrow}
    isRightArrowOverlay={isRightArrow}
    {...props}
  >
    <IconButton disabled={hidden}>
      {isLeftArrow && <ArrowLeftIcon />}
      {isRightArrow && <ArrowRightIcon />}
    </IconButton>
  </ArrowOverlay>
);

ShiftArrow.propTypes = {
  hidden: PropTypes.bool.isRequired,
  isLeftArrow: PropTypes.bool,
  isRightArrow: PropTypes.bool
};

ShiftArrow.defaultProps = {
  isLeftArrow: false,
  isRightArrow: false
};

export default ShiftArrow;
