import PropTypes from 'prop-types';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import sortByUiPos from 'lib/utils/sortByUiPos';
import StyledPhase from './StyledPhase';
import PhaseName from './PhaseName';
import ReflectionField from './ReflectionField';

const ReflectionPhase = ({ phase, ...props }) => {
  const phaseFields = flattenFirebaseList(phase.fields).sort(sortByUiPos);

  return (
    <StyledPhase {...props}>
      <PhaseName>{phase.name}</PhaseName>
      {phaseFields.map(field => (
        <ReflectionField field={field} key={field.key} />
      ))}
    </StyledPhase>
  );
};

ReflectionPhase.propTypes = {
  phase: PropTypes.shape({
    name: PropTypes.string.isRequired,
    fields: PropTypes.objectOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired
};

export default ReflectionPhase;
