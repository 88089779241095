import { useContext } from 'react';
import IsUsingPlanningViewContext from 'lib/contexts/IsUsingPlanningViewContext';
import VisiblePhaseContext from './VisiblePhaseContext';
import PlanningView from './PlanningView';
import NormalView from './NormalView';

const ChallengePortfolio = props => {
  const [isUsingPlanningView] = useContext(IsUsingPlanningViewContext);
  return (
    <VisiblePhaseContext.AutomatedProvider>
      {isUsingPlanningView && <PlanningView />}
      <NormalView hidden={isUsingPlanningView} />
    </VisiblePhaseContext.AutomatedProvider>
  );
};

export default ChallengePortfolio;
