import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import EgodactLogo from 'lib/components/EgodactLogo';

const LogoLink = styled('a')(({ theme }) => ({
  marginRight: 12,
  width: 100,
  height: 39.98,
  display: 'inline-block',
  transition: theme.transitions.create(),
  ':hover': {
    opacity: 0.7
  },
  [mediaQuery(650)]: {
    marginRight: 0,
    marginBottom: 16,
    display: 'block'
  }
}));

const FooterLogo = props => (
  <LogoLink href="https://egodact.com" target="_blank" {...props}>
    <EgodactLogo />
  </LogoLink>
);

export default FooterLogo;
