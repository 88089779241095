import { IconButton } from '@material-ui/core';
import Space from 'lib/components/Space';

const AppBarIconButton = props => (
  <>
    <IconButton edge="start" color="inherit" {...props} />
    <Space width={16} />
  </>
);

export default AppBarIconButton;
