import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const RowName = styled('h2')({
  marginRight: 12,
  fontSize: 20,
  lineHeight: 1,
  display: 'inline-block',
  [mediaQuery(560)]: {
    marginRight: 'auto',
    fontSize: 18,
    lineHeight: 1.4
  }
});

export default RowName;
