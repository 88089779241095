import UserSelectFieldWrapper from '../UserSelectFieldWrapper';
import HideOnLowWidth from 'lib/components/HideOnLowWidth';
import { Skeleton } from '@material-ui/lab';

const SelectLoadingPlaceholder = props => (
  <UserSelectFieldWrapper withMarginRight {...props}>
    <HideOnLowWidth width={1200}>
      <Skeleton variant="rect" animation="wave" width={180} height={24} />
    </HideOnLowWidth>
  </UserSelectFieldWrapper>
);

export default SelectLoadingPlaceholder;
