import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryIconButton from './PrimaryIconButton';
import { PlayArrow as PlayIcon, Pause as PauseIcon } from '@material-ui/icons';
import useInterval from 'use-interval';

const PlayButton = ({ weeks, selectedWeek, setSelectedWeek, ...props }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const firstWeek = Number(weeks[0]);
  const lastWeek = Number(weeks[weeks.length - 1]);

  const play = () => {
    if (selectedWeek === lastWeek) {
      setSelectedWeek(firstWeek);
    }

    setIsPlaying(true);
  };

  const pause = () => setIsPlaying(false);

  useEffect(() => pause, []);

  const moveToNextWeek = () => {
    const nextWeek = selectedWeek + 1;

    if (nextWeek <= lastWeek) {
      setSelectedWeek(nextWeek);
    }

    if (nextWeek >= lastWeek) {
      pause();
    }
  };

  useInterval(() => {
    if (isPlaying) {
      moveToNextWeek();
    }
  }, 1000);

  const canPlay = weeks.length > 1;
  return (
    <PrimaryIconButton
      edge="end"
      onClick={isPlaying ? pause : play}
      disabled={!canPlay}
      {...props}
    >
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </PrimaryIconButton>
  );
};

PlayButton.propTypes = {
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedWeek: PropTypes.number.isRequired,
  setSelectedWeek: PropTypes.func.isRequired
};

export default PlayButton;
