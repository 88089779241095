import { useState } from 'react';
import PropTypes from 'prop-types';
import AddButton from './AddButton';
import FormWrapper from './FormWrapper';
import AllowedEmailTextField from '../AllowedEmailTextField';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const AddAllowedEmail = ({ allowedEmails, onAdd, ...props }) => {
  const [formOpen, setFormOpen] = useState(false);
  const [newAllowedEmail, setNewAllowedEmail] = useState('');
  const [showEmailAlreadyAddedError, setShowEmailAlreadyAddedError] = useState(
    false
  );

  if (!formOpen) {
    return <AddButton onClick={() => setFormOpen(true)} {...props} />;
  }

  const saveNewAllowedEmail = e => {
    e.preventDefault();

    const alreadyAddedAllowedEmails = allowedEmails.map(
      ({ allowedEmail }) => allowedEmail
    );

    if (alreadyAddedAllowedEmails.includes(newAllowedEmail)) {
      setShowEmailAlreadyAddedError(true);
      return;
    }

    onAdd(newAllowedEmail);

    setFormOpen(false);
    setNewAllowedEmail('');
    setShowEmailAlreadyAddedError(false);
  };

  return (
    <FormWrapper onSubmit={saveNewAllowedEmail}>
      <AllowedEmailTextField
        value={newAllowedEmail}
        onChange={e => setNewAllowedEmail(e.target.value.trim())}
        error={showEmailAlreadyAddedError}
        helperText={
          showEmailAlreadyAddedError && (
            <FormattedMessage id="platform_settings.login_settings.add_allowed_email.email_already_added_error" />
          )
        }
        autoFocus
      />
      <IconButton type="submit" disabled={!newAllowedEmail}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

AddAllowedEmail.propTypes = {
  allowedEmails: PropTypes.arrayOf(
    PropTypes.shape({
      allowedEmail: PropTypes.string.isRequired
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired
};

export default AddAllowedEmail;
