import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const FieldHeader = styled('header')(
  ({ theme, shadeBackground }) => ({
    margin: -12,
    marginBottom: 8,
    paddingLeft: 12,
    paddingRight: 12,
    backgroundColor: shadeBackground
      ? theme.palette.action.disabledBackground
      : theme.palette.action.selected,
    transition: theme.transitions.create(),
    display: 'flex',
    alignItems: 'center'
  }),
  addHighContrastStyles(({ theme, shadeBackground }) => ({
    backgroundColor: shadeBackground
      ? theme.palette.action.hover
      : 'transparent',
    borderBottom: `2px solid ${theme.palette.divider}`
  }))
);

FieldHeader.propTypes = {
  shadeBackground: PropTypes.bool.isRequired
};

export default FieldHeader;
