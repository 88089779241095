import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import StyledFieldName from './StyledFieldName';
import NoFieldName from './NoFieldName';
import { FormattedMessage } from 'react-intl';

const FieldName = ({ name, ...props }) => {
  const { field } = useContext(FieldContext);
  return (
    <StyledFieldName {...props}>
      {field.name || (
        <NoFieldName>
          <FormattedMessage id="editor.model_editor.field_editor.no_name" />
        </NoFieldName>
      )}
    </StyledFieldName>
  );
};

export default FieldName;
