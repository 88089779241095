/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import App from 'lib/App';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import { encode } from 'firebase-encode';

const useGetUsernameFromEmail = () => {
  const allowedEmailsObject = App.instanceConfig.useSetting('allowedEmails');
  const allowedEmails = safeValues(allowedEmailsObject);

  const getUsernameFromEmail = useCallback(
    email => {
      if (!email) {
        return null;
      }

      const emailStrippedOfAllowedEmails = allowedEmails.reduce(
        (usernameSoFar, currentAllowedEmail) =>
          usernameSoFar.replace(currentAllowedEmail, ''),
        email
      );

      if (!emailStrippedOfAllowedEmails) {
        return null;
      }

      const username = encode(emailStrippedOfAllowedEmails);
      return username;
    },
    [allowedEmails.toString()]
  );

  return getUsernameFromEmail;
};

export default useGetUsernameFromEmail;
