import styled from '@emotion/styled';
import FullscreenView from '../../../../../../FullscreenView';

const ShadedFullscreenViewContent = props => (
  <FullscreenView.Content shaded {...props} />
);

const DescriptionWrapper = styled(ShadedFullscreenViewContent)({
  paddingTop: 0,
  // Prevent high contrast mode borders around FullScreenView.Content from
  // appearing here, as the description section is integrated with the header
  borderTop: 'none !important'
});

export default DescriptionWrapper;
