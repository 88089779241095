import { useState } from 'react';
import GroupFormWrapper from './GroupFormWrapper';
import { TextField, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const NewGroupForm = props => {
  const [groupName, setGroupName] = useState('');

  const submitForm = e => {
    e.preventDefault();

    ref('groups').push({
      name: groupName.trim()
    });

    setGroupName('');
  };

  return (
    <GroupFormWrapper {...props}>
      <TextField
        label={<FormattedMessage id="group_manager.group_form.input_label" />}
        value={groupName}
        onChange={e => setGroupName(e.target.value)}
        fullWidth
      />
      <IconButton
        type="submit"
        onClick={submitForm}
        disabled={groupName.trim().length === 0}
      >
        <CheckIcon />
      </IconButton>
    </GroupFormWrapper>
  );
};

export default NewGroupForm;
