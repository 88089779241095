import PropTypes from 'prop-types';
import useColorState from '../useColorState';
import StyledForm from './StyledForm';
import ColorPreview from '../ColorPreview';
import ColorTextField from '../ColorTextField';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';

const AddColorForm = ({ onAdd, ...props }) => {
  const [color, setColor] = useColorState();
  const fullHexColor = `#${color}`;

  const handleAdd = e => {
    e.preventDefault();

    if (color.length < 6) {
      return;
    }

    onAdd(fullHexColor);

    setColor('');
  };

  return (
    <StyledForm onSubmit={handleAdd}>
      <ColorPreview color={fullHexColor} />
      <ColorTextField value={color} onChange={e => setColor(e.target.value)} />
      <IconButton onClick={handleAdd} disabled={color.length < 6}>
        <CheckIcon />
      </IconButton>
    </StyledForm>
  );
};

AddColorForm.propTypes = {
  onAdd: PropTypes.func.isRequired
};

export default AddColorForm;
