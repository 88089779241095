import SelectFieldPlaceholder from 'lib/components/SelectFieldPlaceholder';
import { Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const RoleSelectWithPlaceholder = props => {
  const renderPlaceholderOrRole = value => {
    if (!value) {
      return (
        <SelectFieldPlaceholder>
          <FormattedMessage id="user_manager.user_list.toolbar.role_select.label" />
        </SelectFieldPlaceholder>
      );
    }

    return <FormattedMessage id={`user_roles.${value}`} />;
  };

  return (
    <Select
      renderValue={renderPlaceholderOrRole}
      style={{ width: 132 }}
      displayEmpty
      {...props}
    />
  );
};

export default RoleSelectWithPlaceholder;
