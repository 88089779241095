/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { rowRef } from 'lib/firebase';
import RowsVisibilityStateContext from '../RowsVisibilityStateContext';
import useRowChallengesVisibilityState from './useRowChallengesVisibilityState';
import RowChallengesVisibilityStateContext from './RowChallengesVisibilityStateContext';
import RowWrapper from './RowWrapper';
import RowNameWrapper from './RowNameWrapper';
import RowName from './RowName';
import ChallengePlanningWrapper from './ChallengePlanningWrapper';
import ChallengePlanning from './ChallengePlanning';

const RowWithChallengePlanning = ({ rowKey, ...props }) => {
  const [loading, row] = useFirebaseValue(rowRef(rowKey));

  const [
    rowHasVisibleChallenges,
    registerChallengeVisibility
  ] = useRowChallengesVisibilityState();

  const { registerRowVisibility } = useContext(RowsVisibilityStateContext);
  const visible = !loading && row && rowHasVisibleChallenges;

  useEffect(() => {
    registerRowVisibility(rowKey, visible);
  }, [rowKey, visible]);

  if (loading || !row) {
    return null;
  }

  return (
    <RowChallengesVisibilityStateContext.Provider
      value={{ registerChallengeVisibility }}
    >
      <RowWrapper hidden={!rowHasVisibleChallenges} {...props}>
        <RowNameWrapper>
          <RowName>{row.name}</RowName>
        </RowNameWrapper>
        <ChallengePlanningWrapper>
          <ChallengePlanning row={row} />
        </ChallengePlanningWrapper>
      </RowWrapper>
    </RowChallengesVisibilityStateContext.Provider>
  );
};

RowWithChallengePlanning.propTypes = {
  rowKey: PropTypes.string.isRequired
};

export default RowWithChallengePlanning;
