import PropTypes from 'prop-types';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import Phase from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/Phase';
import PhaseContentGroupContext from './PhaseContentGroupContext';

const phaseContentGroupComponents = {
  [PhaseContentGroups.LEFT_CONTENT]: props => (
    <Phase.LeftContent style={{ marginRight: 72 }} {...props} />
  ),
  [PhaseContentGroups.RIGHT_CONTENT]: props => (
    <Phase.RightContent style={{ width: 360 }} {...props} />
  ),
  [PhaseContentGroups.END_CONTENT]: 'footer'
};

const PhaseContentGroup = ({ which, ...props }) => {
  const PhaseContentGroupComponent = phaseContentGroupComponents[which];
  return (
    <PhaseContentGroupContext.Provider value={which}>
      <PhaseContentGroupComponent {...props} />
    </PhaseContentGroupContext.Provider>
  );
};

PhaseContentGroup.propTypes = {
  which: PropTypes.oneOf(Object.values(PhaseContentGroups)).isRequired
};

export default PhaseContentGroup;
