/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSliderState from './useSliderState';
import App from 'lib/App';
import StackSizeContext from './StackSizeContext';
import SliderRail from './SliderRail';
import ProgressBars from './ProgressBars';
import ExpertProgressIndicators from './ExpertProgressIndicators';
import SlidableZone from './SlidableZone';

const RubricsSlider = ({
  studentProgress,
  coachProgress,
  expertProgress,
  onSlide,
  onDragStop,
  readOnly,
  ...props
}) => {
  const sliderRef = useRef(null);
  const [
    sliding,
    progress,
    handleMouseMove,
    handleMouseMoveActivate,
    handleMouseMoveDeactivate
  ] = useSliderState(readOnly, sliderRef);

  useEffect(() => {
    if (typeof progress === 'number') {
      const handleProgressChange = sliding ? onSlide : onDragStop;
      handleProgressChange(progress);
    }
  }, [sliding, progress]);

  const showExpertProgressIndicators = App.instanceConfig.useContentSetting(
    'enableExpertSystem'
  );

  return (
    <StackSizeContext.AutomatedProvider>
      <SliderRail
        onMouseMoveActivate={handleMouseMoveActivate}
        onMouseMoveDeactivate={handleMouseMoveDeactivate}
        onMouseMove={handleMouseMove}
        readOnly={readOnly}
        ref={sliderRef}
        {...props}
      >
        <ProgressBars
          studentProgress={studentProgress}
          coachProgress={coachProgress}
          sliding={sliding}
        />
        {showExpertProgressIndicators && (
          <ExpertProgressIndicators
            expertProgress={expertProgress}
            sliding={sliding}
          />
        )}
        {!readOnly && <SlidableZone />}
      </SliderRail>
    </StackSizeContext.AutomatedProvider>
  );
};

RubricsSlider.propTypes = {
  studentProgress: PropTypes.number.isRequired,
  coachProgress: PropTypes.number.isRequired,
  expertProgress: PropTypes.object,
  levelCount: PropTypes.number.isRequired,
  onSlide: PropTypes.func,
  onDragStop: PropTypes.func,
  readOnly: PropTypes.bool.isRequired
};

export default RubricsSlider;
