import { useState } from 'react';
import SupportButton from './SupportButton';
import SupportDialog from './SupportDialog';
import App from 'lib/App';

const SupportSystem = props => {
  const loggedIn = App.authentication.useLoggedInState();
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  if (!loggedIn) {
    return null;
  }

  return (
    <>
      <SupportButton onClick={() => setSupportDialogOpen(true)} {...props} />
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </>
  );
};

export default SupportSystem;
