import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import TileInfoContext from '../TileInfoContext';
import StyledTitle from './StyledTitle';

const TileTitle = props => {
  const theme = useTheme();
  const { fontSize: tileFontSize, color: tileColor } = useContext(
    TileInfoContext
  );

  return (
    <StyledTitle
      color={theme.palette.getContrastText(tileColor)}
      fontSize={tileFontSize}
      {...props}
    />
  );
};

export default TileTitle;
