import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const PrivacyNote = ({ asTask, ...props }) => (
  <Dialog.ContentText style={{ marginTop: 12, marginBottom: 0 }} {...props}>
    {asTask ? (
      <FormattedMessageWithChallengeNaming
        id="portfolio.challenge.link_sharing.privacy_note.as_task"
        hasHtml
      />
    ) : (
      <FormattedMessageWithChallengeNaming
        id="portfolio.challenge.link_sharing.privacy_note"
        hasHtml
      />
    )}
  </Dialog.ContentText>
);

PrivacyNote.propTypes = {
  asTask: PropTypes.bool.isRequired
};

export default PrivacyNote;
