import { forwardRef, useContext } from 'react';
import useSelectedColor from './useSelectedColor';
import FieldContext from '../../../FieldContext';
import ContentWrapper from './ContentWrapper';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import PickerBoxArrow from './PickerBoxArrow';
import ColorBoxes from './ColorBoxes';

const PickerContent = forwardRef((props, ref) => {
  const [selectedColor, setSelectedColor] = useSelectedColor();

  const { phaseContentGroup } = useContext(FieldContext);
  const inRightPhaseContentGroup =
    phaseContentGroup === PhaseContentGroups.RIGHT_CONTENT;

  return (
    <ContentWrapper
      inRightPhaseContentGroup={inRightPhaseContentGroup}
      ref={ref}
    >
      <PickerBoxArrow inRightPhaseContentGroup={inRightPhaseContentGroup} />
      <ColorBoxes
        selectedColor={selectedColor}
        onSelectColor={setSelectedColor}
      />
    </ContentWrapper>
  );
});

export default PickerContent;
