import styled from '@emotion/styled';
import InputBase from 'lib/components/InputBase';

const StyledUserSearchInput = styled(InputBase)(({ theme }) => ({
  padding: '10px 15px',
  paddingRight: 44,
  width: '100%',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  borderRadius: 4,
  boxSizing: 'border-box'
}));

export default StyledUserSearchInput;
