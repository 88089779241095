import PropTypes from 'prop-types';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const ActionButton = ({ icon, labelId, asTask, ...props }) => (
  <ListItem button {...props}>
    <ListItemIcon>{icon}</ListItemIcon>
    <ListItemText
      primary={
        <FormattedMessageWithChallengeNaming
          id={asTask ? `${labelId}.as_task` : labelId}
        />
      }
    />
  </ListItem>
);

ActionButton.propTypes = {
  icon: PropTypes.node.isRequired,
  labelId: PropTypes.string.isRequired,
  asTask: PropTypes.bool
};

ActionButton.defaultProps = {
  asTask: false
};

export default ActionButton;
