import styled from '@emotion/styled';
import LoadingSpinner from 'lib/components/LoadingSpinner';

const LoadingSpinnerWrapper = styled('div')(({ theme }) => ({
  marginRight: 6,
  marginBottom: 6,
  padding: 5,
  height: 32,
  backgroundColor: theme.palette.action.selected,
  borderRadius: '100%',
  boxSizing: 'border-box'
}));

const AssigneeLoadingSpinner = props => (
  <LoadingSpinnerWrapper {...props}>
    <LoadingSpinner color="primary" size={22} />
  </LoadingSpinnerWrapper>
);

export default AssigneeLoadingSpinner;
