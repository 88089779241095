import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const RightContent = styled('aside')({
  width: 148,
  [mediaQuery(640)]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > *': {
      marginTop: 36,
      marginBottom: 0,
      flex: 1
    },
    '& > .mobile-full-width': {
      minWidth: '100%',
      maxWidth: '100%'
    }
  }
});

export default RightContent;
