import { useContext } from 'react';
import ModelTypeContext from '../../../../ModelTypeContext';
import FieldContext from '../../FieldContext';
import useRubricsFieldParts from './useRubricsFieldParts';
import EditorWrapper from './EditorWrapper';
import EditablePart from './EditablePart';
import AddPartButton from './AddPartButton';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';

const RubricsEditor = props => {
  const modelType = useContext(ModelTypeContext);
  const { field } = useContext(FieldContext);
  const [loading, parts, portfolioTemplates] = useRubricsFieldParts(
    field.key,
    modelType
  );

  if (loading) {
    return (
      <LoadingSpinner
        marginTop={48}
        marginBottom={36}
        color="primary"
        style={{ flex: 'none' }}
        centered
      />
    );
  }

  const highestPartUiPos = parts[parts.length - 1]?.uiPos || 0;

  return (
    <EditorWrapper hasParts={parts.length !== 0} {...props}>
      <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
        {parts.map((part, i) => (
          <EditablePart
            part={part}
            previousPart={parts[i - 1] || {}}
            nextPart={parts[i + 1] || {}}
            highestUiPos={highestPartUiPos}
            key={part.id}
          />
        ))}
      </PortfolioTemplatesContext.Provider>
      <AddPartButton highestPartUiPos={highestPartUiPos} />
    </EditorWrapper>
  );
};

export default RubricsEditor;
