import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import usePrevious from './usePrevious';
import { ShiftDirections } from '../index';

const useLatestVisibleWeekRangeShiftDirection = () => {
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);
  const previousVisibleWeekRangeStartDate = usePrevious(
    visibleWeekRangeStartDate
  );

  const hasShifted =
    previousVisibleWeekRangeStartDate &&
    !previousVisibleWeekRangeStartDate.isSame(visibleWeekRangeStartDate);

  if (!hasShifted) {
    return null;
  }

  const shiftedToRight = previousVisibleWeekRangeStartDate.isBefore(
    visibleWeekRangeStartDate
  );

  return shiftedToRight ? ShiftDirections.RIGHT : ShiftDirections.LEFT;
};

export default useLatestVisibleWeekRangeShiftDirection;
