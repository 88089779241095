import { useContext } from 'react';
import PropTypes from 'prop-types';
import StyledAddButton from '../../TextField/ViewText/AddButton/StyledAddButton';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { FormattedMessage } from 'react-intl';

const AddLogEntryButton = ({ logEntryName, onClick, ...props }) => {
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <StyledAddButton
      onClick={portfolioMode === PortfolioModes.STUDENT ? onClick : null}
      disabled={portfolioMode === PortfolioModes.COACH}
      style={{ marginTop: 18 }}
      {...props}
    >
      <FormattedMessage
        id="portfolio.challenge.log_entries.add.button"
        values={{ logEntryName }}
      />
    </StyledAddButton>
  );
};

AddLogEntryButton.propTypes = {
  logEntryName: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default AddLogEntryButton;
