import PropTypes from 'prop-types';
import Phases from 'lib/constants/Phases';
import countTasks from './countTasks';
import StyledTasksButton from './StyledTasksButton';
import TasksIconButton from './TasksIconButton';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { ViewColumn as TasksIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const TasksButton = ({ challenge, ...props }) => {
  const shouldUseShortLabel = useMediaQuery('(max-width: 1024px)');
  const shouldUseIconButton = useMediaQuery('(max-width: 880px)');

  const taskCount = countTasks(challenge);

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id="portfolio.challenge.tasks_button"
            values={{
              taskCount
            }}
          />
        }
      >
        <TasksIconButton {...props}>
          <TasksIcon />
        </TasksIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledTasksButton {...props}>
      <FormattedMessage
        id={
          shouldUseShortLabel
            ? 'portfolio.challenge.tasks_button.short'
            : 'portfolio.challenge.tasks_button'
        }
        values={{
          taskCount
        }}
      />
    </StyledTasksButton>
  );
};

TasksButton.propTypes = {
  challenge: PropTypes.shape({
    tasks: PropTypes.shape({
      [Phases.TODO]: PropTypes.objectOf(PropTypes.bool),
      [Phases.PROGRESS]: PropTypes.objectOf(PropTypes.bool),
      [Phases.DONE]: PropTypes.objectOf(PropTypes.bool)
    })
  }).isRequired
};

export default TasksButton;
