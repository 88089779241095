import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Prompt from 'lib/components/Prompt';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const ChallengeNameField = ({ hasError, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (hasError) {
      inputRef.current.focus();
    }
  }, [hasError]);

  return (
    <Prompt.TextField
      label={
        <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.challenge_name_field.label" />
      }
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.challenge_name_field.errors.empty" />
        )
      }
      inputRef={inputRef}
      {...props}
    />
  );
};

ChallengeNameField.propTypes = {
  hasError: PropTypes.bool.isRequired
};

export default ChallengeNameField;
