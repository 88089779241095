/* eslint-disable react/jsx-no-target-blank */

import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ViewAllReleasesButton = () => (
  <Button
    variant="outlined"
    component="a"
    href="https://medium.com/egodact/updates/home"
    target="_blank"
    style={{
      fontSize: 13
    }}
    fullWidth
  >
    <FormattedMessage id="header.options_menu.releases_overview.view_all_releases_button" />
  </Button>
);

export default ViewAllReleasesButton;
