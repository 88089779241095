import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledHeader = styled('header')(
  ({ theme }) => ({
    padding: '16px 48px',
    backgroundColor: theme.palette.background.default,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [mediaQuery(768)]: {
      paddingLeft: 24,
      paddingRight: 24,
      width: 'calc(100% + 48px)'
    },
    [mediaQuery(450)]: {
      paddingLeft: 16,
      paddingRight: 16,
      width: 'calc(100% + 32px)'
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`
  }))
);

export default StyledHeader;
