import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import UserWrapper from './UserWrapper';
import UserLoadingPlaceholder from './UserLoadingPlaceholder';
import UserAvatar from 'lib/components/UserAvatar';
import Name from './Name';
import OriginalOwnerIndicator from './OriginalOwnerIndicator';

const User = ({ username, isOriginalOwner, ...props }) => {
  const [loading, user] = useFirebaseValue(userRef(username));

  if (!loading && !user) {
    return null;
  }

  return (
    <UserWrapper {...props}>
      {loading ? (
        <UserLoadingPlaceholder />
      ) : (
        <>
          <UserAvatar user={user} size={28} marginRight={8} />
          <Name>{user.name}</Name>
          {isOriginalOwner && <OriginalOwnerIndicator />}
        </>
      )}
    </UserWrapper>
  );
};

User.propTypes = {
  username: PropTypes.string.isRequired,
  isOriginalOwner: PropTypes.bool
};

User.defaultProps = {
  isOriginalOwner: false
};

export default User;
