import camelCase from 'camelcase';
import { encode } from 'firebase-encode';

const firebaseKeyFromName = name => {
  const databaseAcceptableName = camelCase(encode(name));
  const randomIdSuffix = Math.random().toString().replace('0.', '');
  return `${databaseAcceptableName}:${randomIdSuffix}`;
};

export default firebaseKeyFromName;
