import PropTypes from 'prop-types';
import device from 'current-device';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PhaseSelect from './PhaseSelect';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

// This is necessary because dragging cards doesn't quite work on mobile
// devices
const MobilePhaseSelect = props => {
  if (device.desktop()) {
    return null;
  }

  return (
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <PhaseSelect {...props} />
    </RestrictedToPortfolioMode>
  );
};

MobilePhaseSelect.propTypes = {
  challenge: PropTypes.object,
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  parentKey: PropTypes.string,
  asTask: PropTypes.bool.isRequired
};

export default MobilePhaseSelect;
