import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../../../UserContext';
import App from 'lib/App';
import { ref, userRef } from 'lib/firebase';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const DeleteExpertiseFromUser = ({ expertiseKey, ...props }) => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteExpertiseFromUser = () => {
    setConfirmationDialogOpen(false);

    ref(`expertises/${expertiseKey}/user`).remove();
    userRef(userUsername).child(`expertiseKeys/${expertiseKey}`).remove();
  };

  return (
    <>
      <DangerousIconButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="user_manager.user_lisr.user.expertise_manager.expertise_items.item.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="user_manager.user_list.user.expertise_manager.expertise_items.item.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteExpertiseFromUser} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteExpertiseFromUser.propTypes = {
  expertiseKey: PropTypes.string.isRequired
};

export default DeleteExpertiseFromUser;
