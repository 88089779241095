import PageTitle from 'lib/components/PageTitle';
import UserManager from './UserManager';
import GroupManager from './GroupManager';

const AdminPage = () => (
  <>
    <PageTitle id="admin.page_title" />
    <UserManager />
    <GroupManager />
  </>
);

export default AdminPage;
