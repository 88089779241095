import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const UserSelectFieldWrapper = styled('article')(({ withMarginRight }) => ({
  marginRight: withMarginRight ? 24 : null,
  display: 'flex',
  alignItems: 'center',
  ':first-of-type': {
    marginLeft: 'auto'
  },
  [mediaQuery(700)]: {
    ':first-of-type': {
      marginLeft: 0
    }
  }
}));

UserSelectFieldWrapper.propTypes = {
  withMarginRight: PropTypes.bool
};

UserSelectFieldWrapper.defaultProps = {
  withMarginRight: false
};

export default UserSelectFieldWrapper;
