import PropTypes from 'prop-types';
import RowAttachmentTypeOption from '../RowAttachmentTypeOption';
import RowTextField from './RowTextField';
import { FormattedMessage } from 'react-intl';

const NewRowOption = ({
  selected,
  newRowName,
  onChangeNewRowName,
  hasError,
  challengeNameHasError,
  ...props
}) => (
  <RowAttachmentTypeOption selected={selected} {...props}>
    <RowAttachmentTypeOption.LabelText>
      <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.new_row.label" />
    </RowAttachmentTypeOption.LabelText>
    <RowTextField
      value={newRowName}
      onChange={e => onChangeNewRowName(e.target.value)}
      disabled={!selected}
      hasError={hasError}
      challengeNameHasError={challengeNameHasError}
    />
  </RowAttachmentTypeOption>
);

NewRowOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  newRowName: PropTypes.string.isRequired,
  onChangeNewRowName: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  challengeNameHasError: PropTypes.bool.isRequired
};

export default NewRowOption;
