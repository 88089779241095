import styled from '@emotion/styled';

const ChallengeAttachmentContentWrapper = styled('section')({
  marginLeft: -4,
  marginRight: -4,
  marginBottom: 4,
  display: 'flex',
  flexWrap: 'wrap'
});

export default ChallengeAttachmentContentWrapper;
