import PropTypes from 'prop-types';
import RowActionButton from './RowActionButton';
import {
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';

const CollapseButton = ({ collapsed, ...props }) => (
  <RowActionButton {...props}>
    {collapsed ? <ArrowRightIcon /> : <ArrowDownIcon />}
  </RowActionButton>
);

CollapseButton.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default CollapseButton;
