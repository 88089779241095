import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import AvatarLoadingPlaceholder from './AvatarLoadingPlaceholder';
import InnerUserAvatar from './InnerUserAvatar';

const UserAvatar = ({ username, ...props }) => {
  const [loading, user] = useFirebaseValue(userRef(username));

  if (loading) {
    return <AvatarLoadingPlaceholder />;
  }

  if (!user) {
    return null;
  }

  return <InnerUserAvatar user={user} size={24} />;
};

UserAvatar.propTypes = {
  username: PropTypes.string
};

export default UserAvatar;
