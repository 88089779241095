import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import { rgba, lighten } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';

const darkPalette = {
  type: 'dark',
  primary: {
    main: blue[200]
  },
  secondary: {
    main: grey[400]
  },
  error: {
    main: red[200]
  },
  warning: {
    main: orange[200]
  },
  danger: red[200],
  background: {
    default: '#303030',
    shaded: grey[900],
    border: grey[800]
  },
  sliders: {
    student: lightBlue[200],
    coach: purple[300],
    both: indigo[400]
  },
  partFocusColor: orange[200],
  partFocusTextColor: rgba(common.black, 0.87),
  increaseContrastWithBackground: (color, percentage) =>
    lighten(color, percentage)
};

export default darkPalette;
