import { useContext } from 'react';
import CollapsedPortfolioItemsContext from '../CollapsedPortfolioItemsContext';
import StyledCollapseButton from './StyledCollapseButton';
import { FormattedMessage } from 'react-intl';

const CollapseAllButton = props => {
  const { canCollapseAnyPortfolioItem, collapseAllPortfolioItems } = useContext(
    CollapsedPortfolioItemsContext
  );

  if (!canCollapseAnyPortfolioItem) {
    return null;
  }

  return (
    <StyledCollapseButton
      variant="outlined"
      onClick={collapseAllPortfolioItems}
      {...props}
    >
      <FormattedMessage id="portfolio.collapse_all_button" />
    </StyledCollapseButton>
  );
};

export default CollapseAllButton;
