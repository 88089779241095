import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import VisiblePortfolioTemplatesGroupContext from '../../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from '../UseAggressiveRubricCollapsingContext';
import EditorPart from '../EditorPart';
import AddPart from '../AddPart';
import itemMatchesVisibleGroup from 'lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import isEditorOnly from '../isEditorOnly';
import sortPartsByUiPos from 'lib/rubricsPortfolioUtils/sortPartsByUiPos';
import { extractUiPos } from '../useMakeDraggable/uiPosUtils';
import StyledSubtopic from 'components/RubricsPortfolioComponents/StyledSubtopic';
import SubtopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitleWrapper';
import SubtopicTitle from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitle';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import SubtopicPartWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicPartWrapper';
import EditSubtopic from './EditSubtopic';
import DeleteSubtopic from './DeleteSubtopic';
import useMakeDraggable from '../useMakeDraggable';
import PortfolioDragTypes from '../PortfolioDragTypes';
import { ref } from 'lib/firebase';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const EditorSubtopic = ({
  subtopic,
  previousSubtopic,
  nextSubtopic,
  highestUiPos: highestSubtopicUiPos,
  topicUiPos,
  ...props
}) => {
  const [isDragging, dragRef] = useMakeDraggable(
    PortfolioDragTypes.SUBTOPIC,
    subtopic,
    previousSubtopic,
    nextSubtopic,
    highestSubtopicUiPos,
    'subtopics',
    subtopic.topicId,
    topicUiPos,
    (oldTopicId, newTopicId) => {
      ref(`portfolioTemplates/subtopics/${subtopic.id}/topicId`).set(
        newTopicId
      );

      ref(
        `portfolioTemplates/topics/${oldTopicId}/subtopics/${subtopic.id}`
      ).remove();

      ref(
        `portfolioTemplates/topics/${newTopicId}/subtopics/${subtopic.id}`
      ).set(true);
    }
  );

  const [collapsed, setCollapsed] = useState(true);

  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const allParts = portfolioTemplates?.parts;
  const desiredPartsKeys = safeKeys(subtopic.parts);
  const parts = desiredPartsKeys
    .map(partKey => allParts?.[partKey])
    .filter(part => !!part)
    .sort(sortPartsByUiPos(subtopic.id));

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleParts = parts.filter(itemMatchesVisibleGroup(visibleGroup));

  const lastPart = parts[parts.length - 1];
  const highestPartUiPos = lastPart ? extractUiPos(lastPart, subtopic.id) : 0;

  const groups = safeKeys(subtopic.groups);
  const editorOnly = isEditorOnly(groups);

  const aggressiveRubricCollapsingEnabled = useContext(
    UseAggressiveRubricCollapsingContext
  );

  return (
    <StyledSubtopic editor dragging={isDragging} {...props} ref={dragRef}>
      <SubtopicTitleWrapper editor editorOnly={editorOnly}>
        <SubtopicTitle>{subtopic.title}</SubtopicTitle>
        <EditSubtopic subtopic={subtopic} />
        <DeleteSubtopic subtopic={subtopic} />
        {aggressiveRubricCollapsingEnabled && (
          <CollapseButton
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </SubtopicTitleWrapper>
      {(!aggressiveRubricCollapsingEnabled || !collapsed) && (
        <SubtopicPartWrapper>
          {visibleParts.map((part, i) => (
            <EditorPart
              part={part}
              previousPart={visibleParts[i - 1] || {}}
              nextPart={visibleParts[i + 1] || {}}
              highestUiPos={highestPartUiPos}
              subtopicKey={subtopic.id}
              subtopicUiPos={subtopic.uiPos}
              editor
              key={part.id}
            />
          ))}
          <AddPart
            subtopicId={subtopic.id}
            subtopicGroups={groups}
            highestPartUiPos={highestPartUiPos}
          />
        </SubtopicPartWrapper>
      )}
    </StyledSubtopic>
  );
};

EditorSubtopic.propTypes = {
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.objectOf(PropTypes.bool),
    dynamicGroups: PropTypes.objectOf(PropTypes.bool),
    uiPos: PropTypes.number.isRequired,
    topicId: PropTypes.string.isRequired,
    parts: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  previousSubtopic: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  nextSubtopic: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  highestUiPos: PropTypes.number.isRequired,
  topicUiPos: PropTypes.number.isRequired
};

export default EditorSubtopic;
