import PropTypes from 'prop-types';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import EditFieldNameButton from './EditFieldNameButton';
import ClearFieldName from './ClearFieldName';
import FieldGroups from './FieldGroups';
import EditFieldInfo from './EditFieldInfo';
import DeleteField from './DeleteField';

const FieldActions = ({ onNameEdit, ...props }) => (
  <CollapsibleActionMenu {...props}>
    <EditFieldNameButton onClick={onNameEdit} />
    <ClearFieldName />
    <FieldGroups />
    <EditFieldInfo />
    <DeleteField />
  </CollapsibleActionMenu>
);

FieldActions.propTypes = {
  onNameEdit: PropTypes.func.isRequired
};

export default FieldActions;
