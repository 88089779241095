import { Button } from '@material-ui/core';
import { MoreHoriz as MoreIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ViewOlderLogEntriesButton = props => (
  <Button startIcon={<MoreIcon />} {...props}>
    <FormattedMessage id="portfolio.challenge.log_entries.view_older" />
  </Button>
);

export default ViewOlderLogEntriesButton;
