import StyledNoLevelsMessage from './StyledNoLevelsMessage';
import { FormattedMessage } from 'react-intl';

const NoLevelsMessage = props => (
  <StyledNoLevelsMessage {...props}>
    <FormattedMessage id="portfolio.no_levels" />
  </StyledNoLevelsMessage>
);

export default NoLevelsMessage;
