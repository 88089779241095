import { useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import { ref, userRef } from 'lib/firebase';
import CreateSloPeriodButton from './CreateSloPeriodButton';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const CreateSloPeriod = ({ buttonLabel, ...props }) => {
  const [sloPeriodPromptOpen, setSloPeriodPromptOpen] = useState(false);
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const createSloPeriod = name => {
    setSloPeriodPromptOpen(false);

    const { key: newSloPeriodKey } = ref('sloPeriods').push({
      name,
      user: currentUserUsername
    });

    userRef(currentUserUsername)
      .child(`sloPeriods/${newSloPeriodKey}`)
      .set(true);
  };

  return (
    <>
      <CreateSloPeriodButton
        onClick={() => setSloPeriodPromptOpen(true)}
        {...props}
      />
      <Prompt
        open={sloPeriodPromptOpen}
        onOk={createSloPeriod}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={() => setSloPeriodPromptOpen(false)}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="slo_system.slo_period.create.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="slo_system.slo_period.create.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={
            <FormattedMessage id="slo_system.slo_period.create.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

CreateSloPeriod.propTypes = {
  buttonLabel: PropTypes.node
};

export default CreateSloPeriod;
