/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import StackSizeContext from '../../StackSizeContext';
import App from 'lib/App';
import ProgressIndicator from '../ExpertProgressIndicator/ProgressIndicator';
import IndicatorLabel from '../ExpertProgressIndicator/IndicatorLabel';
import ViewAllExpertProgressItems from './ViewAllExpertProgressItems';

const MAX_COLLAPSED_STACK_SIZE = 3;

const ExpertProgressStack = ({ expertProgressStack, ...props }) => {
  const [stackCollapsed, setStackCollapsed] = useState(true);

  const uncollapsedStackSize = expertProgressStack.expertiseKeys.length;
  const stackSize = stackCollapsed
    ? Math.min(MAX_COLLAPSED_STACK_SIZE, uncollapsedStackSize)
    : uncollapsedStackSize;

  const stackExceedsMaxSize = uncollapsedStackSize > MAX_COLLAPSED_STACK_SIZE;

  const visibleStackItems =
    stackExceedsMaxSize && stackCollapsed
      ? expertProgressStack.expertiseKeys.slice(0, MAX_COLLAPSED_STACK_SIZE - 1)
      : expertProgressStack.expertiseKeys;

  const { registerStackSize, unregisterStackSize } = useContext(
    StackSizeContext
  );

  useEffect(() => {
    registerStackSize(expertProgressStack.key, stackSize);
  }, [stackSize]);

  useEffect(() => () => unregisterStackSize(expertProgressStack.key), []);

  const reversed = App.instanceConfig.useContentSetting('useReversedSliders');
  return (
    <ProgressIndicator
      progress={expertProgressStack.progress}
      reversed={reversed}
      {...props}
    >
      {visibleStackItems.map((expertiseKey, index) => (
        <IndicatorLabel
          expertiseKey={expertiseKey}
          stackIndex={index}
          key={expertiseKey || index}
        />
      ))}
      {stackExceedsMaxSize && stackCollapsed && (
        <ViewAllExpertProgressItems onClick={() => setStackCollapsed(false)} />
      )}
    </ProgressIndicator>
  );
};

ExpertProgressStack.propTypes = {
  expertProgressStack: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    expertiseKeys: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired
};

export default ExpertProgressStack;
