import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import InputWithEscListener from 'lib/components/InputWithEscListener';

const StyledInput = styled(InputWithEscListener)(
  ({ theme }) => ({
    height: 48,
    transition: theme.transitions.create()
  }),
  ({ value, visible }) => ({
    width: visible ? (!!value ? 168 : 204) : 0,
    opacity: visible ? 1 : 0
  }),
  ({ value, visible }) => {
    if (visible) {
      return {
        marginRight: !!value ? 0 : -36
      };
    }
  }
);

StyledInput.propTypes = {
  value: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired
};

export default StyledInput;
