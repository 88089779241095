import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const WrapperForm = styled('form')(({ fullWidth }) => ({
  flex: fullWidth ? 1 : null,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(560)]: {
    flex: 1
  }
}));

WrapperForm.propTypes = {
  fullWidth: PropTypes.bool.isRequired
};

export default WrapperForm;
