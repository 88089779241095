import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const RowHeader = styled('header')(
  ({ theme, collapsed, hasDarkBackground }) => ({
    padding: collapsed ? '9px 24px' : '12px 24px',
    backgroundColor: hasDarkBackground ? theme.palette.background.shaded : null,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    transition: theme.transitions.create(),
    [mediaQuery(640)]: {
      paddingLeft: 12,
      paddingRight: 12
    }
  })
);

RowHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  hasDarkBackground: PropTypes.bool.isRequired
};

export default RowHeader;
