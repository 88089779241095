/* eslint-disable react-hooks/exhaustive-deps */
import 'draft-js/dist/Draft.css';
import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState } from 'draft-js';
import escapeHtml from '../escapeHtml';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import EditorWrapper from './EditorWrapper';
import EditorToolbar from './EditorToolbar';
import EditorContentWrapper from './EditorContentWrapper';
import InnerEditor from './InnerEditor';

const TextEditor = ({
  initialValue,
  onCancel,
  onSave,
  disableSaveWhenEmpty,
  ...props
}) => {
  const editorRef = useRef(null);

  const initialEditorState = !initialValue
    ? EditorState.createEmpty()
    : EditorState.createWithContent(
        stateFromHTML(escapeHtml(initialValue || ''))
      );

  const [editorState, setEditorState] = useState(initialEditorState);

  const editorContent = editorState.getCurrentContent();
  const editorIsEmpty = !editorContent.hasText();

  const focusOnEditor = () => {
    // Without this timeout, the link detection plugin breaks
    setTimeout(() => {
      editorRef.current.focus();
    }, 100);
  };

  useEffect(focusOnEditor, []);

  const handleSave = () => {
    if (editorIsEmpty) {
      onSave(null);

      return;
    }

    onSave(stateToHTML(editorContent));
  };

  return (
    <EditorWrapper className="text-editor" {...props}>
      <EditorToolbar
        editorState={editorState}
        setEditorState={setEditorState}
        onCancel={onCancel}
        onSave={handleSave}
        saveDisabled={disableSaveWhenEmpty && editorIsEmpty}
        focusOnEditor={focusOnEditor}
      />
      <EditorContentWrapper>
        <InnerEditor
          ref={editorRef}
          editorState={editorState}
          onChange={editorState => setEditorState(editorState)}
          onEnterSave={handleSave}
        />
      </EditorContentWrapper>
    </EditorWrapper>
  );
};

TextEditor.propTypes = {
  initialValue: PropTypes.string,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  disableSaveWhenEmpty: PropTypes.bool
};

TextEditor.defaultProps = {
  disableSaveWhenEmpty: false
};

export default TextEditor;
