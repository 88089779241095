import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FeedbackListWrapper = styled('aside')(
  {
    marginLeft: 24,
    width: 232,
    [mediaQuery(1250)]: {
      display: 'none'
    }
  },
  ({ mobileVariant }) => {
    if (mobileVariant) {
      return {
        [mediaQuery(1250)]: {
          display: 'initial'
        }
      };
    }
  }
);

FeedbackListWrapper.propTypes = {
  mobileVariant: PropTypes.bool.isRequired
};

export default FeedbackListWrapper;
