import StyledSupportButton from './StyledSupportButton';
import { HelpOutline as SupportIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const SupportButton = props => (
  <StyledSupportButton variant="extended" {...props}>
    <SupportIcon />
    <Space width={12} />
    <FormattedMessage id="support.button" />
  </StyledSupportButton>
);

export default SupportButton;
