import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { darken } from 'lib/styling/colors';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import { Check as CheckIcon } from '@material-ui/icons';

const StyledIconButton = styled(IconButton)(
  ({ theme }) => ({
    padding: 6,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    ':hover': {
      backgroundColor: darken(theme.palette.primary.main, 15)
    },
    ':disabled': {
      backgroundColor: theme.palette.divider
    }
  }),
  addHighContrastStyles({
    ':disabled': {
      display: 'none'
    }
  })
);

const StyledCheckIcon = styled(CheckIcon)({
  width: 20,
  height: 20
});

const SaveButton = props => (
  <StyledIconButton size="small" {...props}>
    <StyledCheckIcon />
  </StyledIconButton>
);

export default SaveButton;
