import { Skeleton } from '@material-ui/lab';

const ChallengeNameLoadingPlaceholder = () => (
  <>
    <Skeleton variant="text" width="100%" animation="wave" />
    <Skeleton
      variant="text"
      width="40%"
      animation="wave"
      style={{
        marginRight: 'auto',
        marginBottom: 'auto'
      }}
    />
  </>
);

export default ChallengeNameLoadingPlaceholder;
