import PropTypes from 'prop-types';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import CreateChallenge from './CreateChallenge';
import CreateTask from './CreateTask';

const CreateChallengeOrTask = ({ parent, useTaskVariant, ...props }) => (
  <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
    {useTaskVariant ? (
      <CreateTask challenge={parent} {...props} />
    ) : (
      <CreateChallenge row={parent} {...props} />
    )}
  </RestrictedToPortfolioMode>
);

CreateChallengeOrTask.propTypes = {
  parent: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired,
  useTaskVariant: PropTypes.bool
};

CreateChallengeOrTask.defaultProps = {
  useTaskVariant: false
};

export default CreateChallengeOrTask;
