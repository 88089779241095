import { createContext } from 'react';

const RowDeletionContext = createContext({
  deleteRow: () => {},
  rowDeleting: false,
  startRowDeletion: () => {},
  cancelRowDeletion: () => {}
});

export default RowDeletionContext;
