import { safeKeys } from 'lib/utils/safeObjectFunctions';
import { ref, userRef } from 'lib/firebase';
import { removeChallenge } from 'lib/firebase/challengePortfolio/useDeleteChallenge';
import deleteRow from 'lib/firebase/challengePortfolio/deleteRow';
import App from 'lib/App';

const removeUserFromGroups = userGroupsPath => (username, user) => {
  const userGroups = user[userGroupsPath];
  const groupUsersPath =
    userGroupsPath === 'managedGroups' ? 'managers' : 'users';

  return Promise.all(
    safeKeys(userGroups).map(groupKey =>
      ref(`groups/${groupKey}/${groupUsersPath}/${username}`).remove()
    )
  );
};

const deleteNewlySharedFromUser = (username, user) => {
  const newlySharedChallengesKeys = Object.keys(user.newlyShared);
  return Promise.all(
    newlySharedChallengesKeys.map(challengeKey =>
      removeChallenge(challengeKey, username)
    )
  );
};

const deleteUserRows = (username, user) =>
  Promise.all(
    Object.keys(user.rows).map(rowKey => deleteRow(rowKey, username, true))
  );

const removeUserFromTheirExpertises = user =>
  Promise.all(
    Object.keys(user.expertiseKeys).map(expertiseKey =>
      ref(`expertises/${expertiseKey}/user`).remove()
    )
  );

const anonymizeCoachComments = (user, username) =>
  Promise.all(
    Object.keys(user.coachComments).map(coachCommentKey =>
      ref(`coachComments/${coachCommentKey}/user`).remove()
    )
  );

const removeUserFromAuthoredChallenges = user =>
  Promise.all(
    Object.keys(user.authoredChallenges).map(challengeKey =>
      ref(`challenges/${challengeKey}/author`).remove()
    )
  );

const useDeleteUser = () => {
  const getUsernameFromEmail = App.authentication.useGetUsernameFromEmail();

  const deleteUser = async user => {
    const username = getUsernameFromEmail(user.email);

    await Promise.all([
      removeUserFromGroups('groups')(username, user),
      removeUserFromGroups('managedGroups')(username, user),
      ref(`progress/${username}`).remove(),
      ref(`portfolios/${username}`).remove(),
      userRef(username).remove()
    ]);

    if (user.newlyShared) {
      await deleteNewlySharedFromUser(username, user);
    }

    if (user.rows) {
      await deleteUserRows(username, user);
    }

    if (user.expertiseKeys) {
      await removeUserFromTheirExpertises(user);
    }

    if (user.coachComments) {
      await anonymizeCoachComments(user, username);
    }

    if (user.authoredChallenges) {
      await removeUserFromAuthoredChallenges(user);
    }
  };

  return deleteUser;
};

export default useDeleteUser;
