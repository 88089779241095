import { useState } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import Dialog from 'lib/components/Dialog';
import ChallengeNameField from './ChallengeNameField';
import CreationDialogTitle from './CreationDialogTitle';
import ChallengeRowAttachment from './ChallengeRowAttachment';
import RowAttachmentTypes from './RowAttachmentTypes';
import useCreateRow from '../CreateRow/useCreateRow';
import getErrorsFromState from './getErrorsFromState';
import useCreateChallenge from '../../ChallengeBoard/KanbanPhase/CreateChallengeOrTask/CreateChallenge/useCreateChallenge';
import Phases from 'lib/constants/Phases';
import Errors from './Errors';

const ChallengeCreationDialog = ({
  rowKeys,
  highestRowUiPos,
  onClose,
  ...props
}) => {
  const [challengeName, setChallengeName] = useState('');

  const hasNoRows = rowKeys.length === 0;
  const [
    selectedRowAttachmentType,
    setSelectedRowAttachmentType
  ] = useSyncedState(
    hasNoRows ? RowAttachmentTypes.NEW_ROW : RowAttachmentTypes.EXISTING_ROW
  );

  const [newRowName, setNewRowName] = useState('');
  const [selectedExistingRow, setSelectedExistingRow] = useState('');

  const [errors, setErrors] = useState([]);

  const resetState = () => {
    setChallengeName('');
    setSelectedRowAttachmentType(RowAttachmentTypes.EXISTING_ROW);
    setNewRowName('');
    setSelectedExistingRow('');
  };

  const close = () => {
    setErrors([]);
    onClose();
  };

  const createRow = useCreateRow();
  const createChallenge = useCreateChallenge();

  const saveChallenge = async () => {
    const errors = getErrorsFromState(
      challengeName,
      selectedRowAttachmentType,
      selectedExistingRow,
      newRowName
    );

    setErrors(errors);

    if (errors.length !== 0) {
      return;
    }

    const rowKey =
      selectedRowAttachmentType === RowAttachmentTypes.NEW_ROW
        ? await createRow(newRowName.trim(), highestRowUiPos)
        : selectedExistingRow;

    createChallenge(challengeName, Phases.TODO, rowKey);
    resetState();
    close();
  };

  return (
    <Dialog onClose={close} onEnterClose={saveChallenge} {...props}>
      <CreationDialogTitle />
      <ChallengeNameField
        value={challengeName}
        onChange={e => setChallengeName(e.target.value)}
        hasError={errors.includes(Errors.CHALLENGE_NAME_EMPTY)}
      />
      <ChallengeRowAttachment>
        <ChallengeRowAttachment.Title />
        <ChallengeRowAttachment.TypeOptions
          selectedRowAttachmentType={selectedRowAttachmentType}
          onChangeSelectedRowAttachmentType={selectedRowAttachmentType =>
            setSelectedRowAttachmentType(selectedRowAttachmentType)
          }
        >
          <ChallengeRowAttachment.TypeOptions.ExistingRow
            value={RowAttachmentTypes.EXISTING_ROW}
            selected={
              selectedRowAttachmentType === RowAttachmentTypes.EXISTING_ROW
            }
            rowKeys={rowKeys}
            selectedRow={selectedExistingRow}
            onChangeSelectedRow={newSelectedRow =>
              setSelectedExistingRow(newSelectedRow)
            }
            disabled={hasNoRows}
            hasError={errors.includes(Errors.NO_ROW_SELECTED)}
          />
          <ChallengeRowAttachment.TypeOptions.NewRow
            value={RowAttachmentTypes.NEW_ROW}
            selected={selectedRowAttachmentType === RowAttachmentTypes.NEW_ROW}
            newRowName={newRowName}
            onChangeNewRowName={newRowName => setNewRowName(newRowName)}
            hasError={errors.includes(Errors.ROW_NAME_EMPTY)}
            challengeNameHasError={errors.includes(Errors.CHALLENGE_NAME_EMPTY)}
          />
        </ChallengeRowAttachment.TypeOptions>
      </ChallengeRowAttachment>
      <Dialog.Actions>
        <Dialog.Actions.CancelButton onClick={close} />
        <Dialog.Actions.CreateButton onClick={saveChallenge} />
      </Dialog.Actions>
    </Dialog>
  );
};

ChallengeCreationDialog.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  highestRowUiPos: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ChallengeCreationDialog;
