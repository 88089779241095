import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import LevelDialog from 'components/RubricsPortfolioComponents/LevelDialog';
import EditorLevel from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/EditorLevel';
import AddLevel from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/AddLevel';
import sortByUiPos from 'lib/utils/sortByUiPos';
import TileGrid from 'components/LearningGoals/TileGrid';
import Space from 'lib/components/Space';
import EditPart from './EditPart';
import DeletePart from './DeletePart';
import ManageAttachments from './ManageAttachments';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const Part = ({ part, subtopicKey, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const [levelDialogOpen, setLevelDialogOpen] = useState(false);

  const allLevels = portfolioTemplates?.levels;
  const desiredLevelsKeys = safeKeys(part.levels);
  const levels = desiredLevelsKeys
    .map(levelKey => allLevels?.[levelKey])
    .filter(level => !!level)
    .sort(sortByUiPos);

  const lastLevel = levels[levels.length - 1];
  const highestLevelUiPos = lastLevel ? lastLevel.uiPos : 0;

  return (
    <>
      <TileGrid.Tile color={part.color} {...props}>
        <TileGrid.Tile.ClickListener onClick={() => setLevelDialogOpen(true)} />
        <TileGrid.Tile.Title>{part.title}</TileGrid.Tile.Title>
        <TileGrid.Tile.OnlyShowOnHover>
          <Space width={9} />
        </TileGrid.Tile.OnlyShowOnHover>
        <TileGrid.Tile.OnlyShowOnHover>
          <EditPart part={part} />
        </TileGrid.Tile.OnlyShowOnHover>
        <TileGrid.Tile.OnlyShowOnHover>
          <DeletePart part={part} subtopicKey={subtopicKey} />
        </TileGrid.Tile.OnlyShowOnHover>
        <TileGrid.Tile.OnlyShowOnHover>
          <ManageAttachments part={part} />
        </TileGrid.Tile.OnlyShowOnHover>
      </TileGrid.Tile>
      <LevelDialog
        open={levelDialogOpen}
        onClose={() => setLevelDialogOpen(false)}
        editor
      >
        <LevelDialog.Title onClose={() => setLevelDialogOpen(false)}>
          {part.title}
        </LevelDialog.Title>
        <LevelDialog.Content>
          <LevelDialog.InnerContentWrapper>
            <LevelDialog.LevelWrapper>
              {levels.map((level, i) => (
                <EditorLevel
                  level={level}
                  previousLevel={levels[i - 1] || {}}
                  nextLevel={levels[i + 1] || {}}
                  highestUiPos={highestLevelUiPos}
                  editor
                  key={level.id}
                />
              ))}
              <AddLevel
                partId={part.id}
                highestLevelUiPos={highestLevelUiPos}
              />
            </LevelDialog.LevelWrapper>
          </LevelDialog.InnerContentWrapper>
        </LevelDialog.Content>
      </LevelDialog>
    </>
  );
};

Part.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string
  }).isRequired,
  subtopicKey: PropTypes.string.isRequired
};

export default Part;
