import MomentPropTypes from 'react-moment-proptypes';
import getDateRangeAsText from './getDateRangeAsText';

const WeekDateRange = ({ weekStartDate, ...props }) => {
  const weekEndDate = weekStartDate.clone().endOf('isoWeek');
  return <h2 {...props}>{getDateRangeAsText(weekStartDate, weekEndDate)}</h2>;
};

WeekDateRange.propTypes = {
  weekStartDate: MomentPropTypes.momentObj.isRequired
};

export default WeekDateRange;
