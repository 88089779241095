import PropTypes from 'prop-types';
import HeaderContentWrapper from './HeaderContentWrapper';
import PhaseName from './PhaseName';
import { useMediaQuery } from '@material-ui/core';
import Phases from 'lib/constants/Phases';
import phaseName from 'lib/utils/phaseName';
import MobilePhaseHeader from './MobilePhaseHeader';

const KanbanPhasesHeader = ({ inTaskBoard, children, ...props }) => {
  const shouldUseMobileHeader = useMediaQuery('(max-width: 640px)');

  if (shouldUseMobileHeader) {
    return <MobilePhaseHeader inTaskBoard={inTaskBoard} {...props} />;
  }

  return (
    <HeaderContentWrapper {...props}>
      <PhaseName>{phaseName(Phases.TODO)}</PhaseName>
      <PhaseName>{phaseName(Phases.PROGRESS)}</PhaseName>
      <PhaseName>{phaseName(Phases.DONE)}</PhaseName>
      {children}
    </HeaderContentWrapper>
  );
};

KanbanPhasesHeader.propTypes = {
  inTaskBoard: PropTypes.bool,
  children: PropTypes.node
};

KanbanPhasesHeader.defaultProps = {
  inTaskBoard: false
};

export default KanbanPhasesHeader;
