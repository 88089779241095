import { ref } from 'lib/firebase';

const detachPart = (part, subtopicKey) =>
  Promise.all([
    ref(
      `portfolioTemplates/subtopics/${subtopicKey}/parts/${part.id}`
    ).remove(),
    ref(
      `portfolioTemplates/parts/${part.id}/subtopicId/${subtopicKey}`
    ).remove(),
    ref(`portfolioTemplates/parts/${part.id}/uiPos/${subtopicKey}`).remove()
  ]);

export default detachPart;
