/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import useSelectionCursor from './useSelectionCursor';
import useKeydownListener from 'lib/utils/useKeydownListener';

const useResultListKeyboardNavigation = (
  inputRef,
  listRef,
  selectableUsers,
  onSelectUser,
  onKeyboardSelectUser
) => {
  const selectionCursor = useSelectionCursor(selectableUsers);
  const keyboardSelectedUser = selectableUsers[selectionCursor.index] || null;

  useKeydownListener(inputRef.current, event => {
    if (event.key === 'ArrowUp') {
      selectionCursor.moveUp();
    }

    if (event.key === 'ArrowDown') {
      selectionCursor.moveDown();
    }

    if (event.key === 'Enter' && keyboardSelectedUser) {
      onSelectUser(keyboardSelectedUser);
    }
  });

  const inputHasFocus = inputRef.current === document.activeElement;
  useEffect(() => {
    if (!inputHasFocus) {
      selectionCursor.clear();
    }
  }, [inputHasFocus]);

  useEffect(() => {
    onKeyboardSelectUser(keyboardSelectedUser?.email || null);
  }, [selectionCursor.index]);

  return selectionCursor;
};

export default useResultListKeyboardNavigation;
