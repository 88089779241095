import styled from '@emotion/styled';
import StyledChallengeCard from '../StyledChallengeCard';

const StyledLoadingChallengeCard = styled(StyledChallengeCard)({
  opacity: 0.8,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'auto'
});

export default StyledLoadingChallengeCard;
