import { useContext } from 'react';
import PropTypes from 'prop-types';
import SelectFieldPlaceholder from 'lib/components/SelectFieldPlaceholder';
import WorldLabel from './WorldLabel';
import { Select } from '@material-ui/core';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';

const selectStyles = {
  marginBottom: 12,
  width: 320,
  maxWidth: '100%'
};

const WorldSelectField = ({ placeholder, ...props }) => {
  const renderSelectedWorldsOrPlaceholder = selectedWorlds => {
    if (selectedWorlds.length === 0) {
      return <SelectFieldPlaceholder>{placeholder}</SelectFieldPlaceholder>;
    }

    return selectedWorlds.map(world => (
      <WorldLabel world={world} key={world} />
    ));
  };

  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <Select
      disabled={portfolioMode === PortfolioModes.COACH}
      renderValue={renderSelectedWorldsOrPlaceholder}
      style={selectStyles}
      displayEmpty
      multiple
      {...props}
    />
  );
};

WorldSelectField.propTypes = {
  placeholder: PropTypes.node
};

export default WorldSelectField;
