import PropTypes from 'prop-types';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const ProofMapper = ({ proof, proofRef, children }) => {
  if (!proof) {
    return null;
  }

  const isLegacySingleProofLink = typeof proof === 'string';
  if (isLegacySingleProofLink) {
    const individualProofLink = proof;
    const individualProofRef = proofRef;
    const fakeIndex = 0;
    return children([individualProofLink, individualProofRef], fakeIndex);
  }

  return Object.entries(proof).map(
    ([individualProofKey, individualProofLink], i) => {
      const individualProofRef = proofRef?.child?.(individualProofKey);
      return children([individualProofLink, individualProofRef], i);
    }
  );
};

ProofMapper.propTypes = {
  proof: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string)
  ]),
  proofRef: PropTypes.instanceOf(FirebaseDatabaseReference)
};

export default ProofMapper;
