/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { value, userRef } from 'lib/firebase';
import UserWrapper from './UserWrapper';
import UserLoadingPlaceholder from './UserLoadingPlaceholder';
import UserInfoWrapper from './UserInfoWrapper';
import LogEntryUserAvatar from './LogEntryUserAvatar';
import UserName from './UserName';
import LogEntryDate from './LogEntryDate';

const LogEntryUser = ({ logEntry, ...props }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const loadUser = async () => {
    if (!logEntry.user) {
      setLoading(false);

      return;
    }

    try {
      const user = await value(userRef(logEntry.user));

      setUser(user);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUser();
  }, [logEntry.user]);

  if (!loading && !user) {
    return (
      <UserWrapper {...props}>
        <LogEntryDate timestamp={logEntry.timestamp} />
      </UserWrapper>
    );
  }

  return (
    <UserWrapper {...props}>
      {loading ? (
        <UserLoadingPlaceholder logEntry={logEntry} />
      ) : (
        <>
          <LogEntryUserAvatar user={user} />
          <UserInfoWrapper>
            <UserName name={user.name} />
            <LogEntryDate timestamp={logEntry.timestamp} />
          </UserInfoWrapper>
        </>
      )}
    </UserWrapper>
  );
};

LogEntryUser.propTypes = {
  logEntry: PropTypes.shape({
    timestamp: PropTypes.number.isRequired,
    user: PropTypes.string
  })
};

export default LogEntryUser;
