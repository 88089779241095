import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import { rgba } from 'lib/styling/colors';
import mediaQuery from 'lib/styling/mediaQuery';
import UserSwitchArrowLabel from './UserSwitchArrowLabel';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const NormalUserSwitchArrow = styled(ButtonBase)(
  ({ theme, right }) => ({
    position: 'fixed',
    top: '50%',
    left: right ? 'unset' : 30,
    right: right ? 30 : 'unset',
    transform: 'translateY(-50%)',
    margin: 0,
    padding: '16px 20px',
    textAlign: 'center',
    color: 'transparent',
    borderRadius: 4,
    transition: theme.transitions.create(),
    ':not(:disabled):hover, :focus': {
      color: rgba(theme.palette.text.primary, 0.6),
      backgroundColor: theme.palette.background.shaded
    },
    [mediaQuery(1024)]: {
      display: 'none'
    }
  }),
  ({ disabled }) => {
    if (disabled) {
      return {
        opacity: 0.5
      };
    }
  },
  addHighContrastStyles(({ disabled }) => {
    if (disabled) {
      return {
        display: 'none'
      };
    }
  }),
  addBorderInHighContrastMode
);

NormalUserSwitchArrow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  right: PropTypes.bool
};

NormalUserSwitchArrow.defaultProps = {
  right: false
};

NormalUserSwitchArrow.Label = UserSwitchArrowLabel;

export default NormalUserSwitchArrow;
