import { useState } from 'react';
import PropTypes from 'prop-types';
import GroupWrapper from './GroupWrapper';
import GroupNameEditor from './GroupNameEditor';
import GroupName from './GroupName';
import GroupUsersText from './GroupUsersText';
import EditButton from './EditButton';
import DeleteGroup from './DeleteGroup';

const Group = ({ group, ...props }) => {
  const [nameEditing, setNameEditing] = useState(false);
  return (
    <GroupWrapper {...props}>
      {nameEditing ? (
        <GroupNameEditor group={group} onClose={() => setNameEditing(false)} />
      ) : (
        <>
          <GroupName>{group.name}</GroupName>
          <GroupUsersText group={group} />
          <EditButton onClick={() => setNameEditing(true)} />
        </>
      )}
      <DeleteGroup group={group} />
    </GroupWrapper>
  );
};

Group.propTypes = {
  group: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default Group;
