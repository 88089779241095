import styled from '@emotion/styled';

const LearningGoalsWrapper = styled('section')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  overflowY: 'auto',
  zIndex: 4
}));

export default LearningGoalsWrapper;
