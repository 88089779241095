import styled from '@emotion/styled';

const StyledMessage = styled('h2')(({ theme }) => ({
  marginTop: 24,
  marginBottom: 0,
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default StyledMessage;
