const getChallengeUsers = challenge => {
  const { originalOwner, sharedWith } = challenge;

  if (!originalOwner && !sharedWith) {
    return [];
  }

  if (originalOwner && !sharedWith) {
    return [originalOwner];
  }

  if (!originalOwner && sharedWith) {
    return Object.keys(sharedWith).sort();
  }

  return [originalOwner, ...Object.keys(sharedWith)].sort();
};

export default getChallengeUsers;
