import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import StyledSubtopic from './StyledSubtopic';
import SubtopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitleWrapper';
import SubtopicTitle from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitle';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import SubtopicPartWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicPartWrapper';
import sortPartsByUiPos from 'lib/rubricsPortfolioUtils/sortPartsByUiPos';
import { extractUiPos } from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/useMakeDraggable/uiPosUtils';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import App from 'lib/App';

const Subtopic = ({ subtopic, children, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  const allParts = portfolioTemplates?.parts;
  const desiredPartsKeys = safeKeys(subtopic.parts);
  const parts = desiredPartsKeys
    .map(partKey => allParts?.[partKey])
    .filter(part => !!part)
    .sort(sortPartsByUiPos(subtopic.id));

  const lastPart = parts[parts.length - 1];
  const highestPartUiPos = lastPart ? extractUiPos(lastPart, subtopic.id) : 0;

  const [collapsed, setCollapsed] = useState(true);
  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useContentSetting(
    'enableAggressiveRubricCollapsing'
  );

  return (
    <StyledSubtopic editor {...props}>
      <SubtopicTitleWrapper>
        <SubtopicTitle>{subtopic.title}</SubtopicTitle>
        {aggressiveRubricCollapsingEnabled && (
          <CollapseButton
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />
        )}
      </SubtopicTitleWrapper>
      {(!aggressiveRubricCollapsingEnabled || !collapsed) && (
        <SubtopicPartWrapper>
          {children(parts, highestPartUiPos)}
        </SubtopicPartWrapper>
      )}
    </StyledSubtopic>
  );
};

Subtopic.propTypes = {
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  children: PropTypes.func.isRequired
};

export default Subtopic;
