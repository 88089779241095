import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import DeleteButton from '../PortfolioTemplatesEditorActionButtons/DeleteButton';
import deletePartFromFirebase from 'lib/firebase/rubricsPortfolioTemplates/deletePart';
import { FormattedMessage } from 'react-intl';

const DeletePart = ({ part, onDelete, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deletePart = async () => {
    setConfirmationDialogOpen(false);

    await deletePartFromFirebase(part.id);

    if (onDelete) {
      onDelete(part);
    }
  };

  return (
    <>
      <DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.part.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.part.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deletePart} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeletePart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired,
  onDelete: PropTypes.func
};

export default DeletePart;
