import PropTypes from 'prop-types';
import StyledCheckboxWrapper from './StyledCheckboxWrapper';
import CheckboxWithoutPadding from './CheckboxWithoutPadding';
import Groups from 'lib/constants/Groups';
import { FormattedMessage } from 'react-intl';

const StandardGroupCheckbox = ({ group, ...props }) => (
  <StyledCheckboxWrapper
    control={<CheckboxWithoutPadding name={group} color="primary" />}
    label={<FormattedMessage id={`group_names.${group}`} />}
    {...props}
  />
);

StandardGroupCheckbox.propTypes = {
  group: PropTypes.oneOf([Groups.STUDENTS, Groups.COACHES]).isRequired
};

export default StandardGroupCheckbox;
