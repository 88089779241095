import { safeKeys } from 'lib/utils/safeObjectFunctions';
import Phases from 'lib/constants/Phases';

const getAllChallengeKeysFromRow = row => {
  if (!row.challenges) {
    return [];
  }

  return [
    ...safeKeys(row.challenges?.[Phases.TODO]),
    ...safeKeys(row.challenges?.[Phases.PROGRESS]),
    ...safeKeys(row.challenges?.[Phases.DONE])
  ];
};

export default getAllChallengeKeysFromRow;
