import { useState } from 'react';
import PropTypes from 'prop-types';
import AddSubtopicButton from './AddSubtopicButton';
import PortfolioItemWithGroupsPrompt from '../PortfolioItemWithGroupsPrompt';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import Groups from 'lib/constants/Groups';
import arrToObj from 'lib/utils/arrToObj';
import { FormattedMessage } from 'react-intl';

const AddSubtopic = ({
  topicId,
  topicGroups,
  highestSubtopicUiPos,
  noParentAdd,
  ...props
}) => {
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewSubtopic = (title, groups, dynamicGroups) => {
    setAddPromptOpen(false);

    const id = ref('portfolioTemplates/subtopics').push().key;

    !noParentAdd &&
      ref(`portfolioTemplates/topics/${topicId}/subtopics/${id}`).set(true);

    ref(`portfolioTemplates/subtopics/${id}`).set({
      topicId,
      id,
      title,
      uiPos: Math.ceil(highestSubtopicUiPos) + 1,
      groups: arrToObj(groups, true),
      dynamicGroups: arrToObj(dynamicGroups, true)
    });
  };

  return (
    <>
      <AddSubtopicButton
        color="primary"
        onClick={() => setAddPromptOpen(true)}
        {...props}
      >
        <FormattedMessage id="portfolio_template_editor.add_subtopic.button" />
      </AddSubtopicButton>
      <PortfolioItemWithGroupsPrompt
        label={
          <FormattedMessage id="portfolio_template_editor.add_subtopic.input_label" />
        }
        open={addPromptOpen}
        selectedStandardGroups={topicGroups}
        onOk={saveNewSubtopic}
        onCancel={() => setAddPromptOpen(false)}
        createsItem
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.add_subtopic.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.add_subtopic.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

AddSubtopic.propTypes = {
  topicId: PropTypes.string.isRequired,
  topicGroups: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Groups)))
    .isRequired,
  highestSubtopicUiPos: PropTypes.number.isRequired,
  noParentAdd: PropTypes.bool
};

AddSubtopic.defaultProps = {
  noParentAdd: false
};

export default AddSubtopic;
