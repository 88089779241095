import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ColorBox from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/ColorBoxes/ColorBox';
import Space from 'lib/components/Space';

const PreviewColorBox = styled(ColorBox)({
  margin: 0,
  marginRight: 18,
  minWidth: 24,
  cursor: 'default',
  ':hover': {
    transform: 'none'
  }
});

const ColorPreview = ({ color, ...props }) => {
  if (color.length !== 7) {
    return <Space width={42} />;
  }

  return <PreviewColorBox color={color} />;
};

ColorPreview.propTypes = {
  color: PropTypes.string.isRequired
};

export default ColorPreview;
