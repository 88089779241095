import { forwardRef } from 'react';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { ListItemIcon } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const EditFieldNameButton = forwardRef((props, ref) => (
  <CollapsibleActionMenu.Button {...props} ref={ref}>
    <ListItemIcon>
      <EditIcon />
    </ListItemIcon>
    <FormattedMessage id="editor.model_editor.actions.edit_name" />
  </CollapsibleActionMenu.Button>
));

export default EditFieldNameButton;
