import PropTypes from 'prop-types';
import StandardGroupsCheckboxesWrapper from './StandardGroupsCheckboxesWrapper';
import StandardGroupCheckbox from './StandardGroupCheckbox';
import Groups from 'lib/constants/Groups';

const StandardGroupCheckboxes = ({
  selectedStandardGroups,
  updateIndividualStandardGroupSelection,
  ...props
}) => (
  <StandardGroupsCheckboxesWrapper {...props}>
    <StandardGroupCheckbox
      group={Groups.STUDENTS}
      checked={!!selectedStandardGroups[Groups.STUDENTS]}
      onChange={e =>
        updateIndividualStandardGroupSelection(
          Groups.STUDENTS,
          e.target.checked
        )
      }
    />
    <StandardGroupCheckbox
      group={Groups.COACHES}
      checked={!!selectedStandardGroups[Groups.COACHES]}
      onChange={e =>
        updateIndividualStandardGroupSelection(Groups.COACHES, e.target.checked)
      }
    />
  </StandardGroupsCheckboxesWrapper>
);

StandardGroupCheckboxes.propTypes = {
  selectedStandardGroups: PropTypes.objectOf(PropTypes.bool).isRequired,
  updateIndividualStandardGroupSelection: PropTypes.func.isRequired
};

export default StandardGroupCheckboxes;
