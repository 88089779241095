import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

const StyledMessage = styled('footer')(({ theme }) => ({
  marginTop: 12,
  fontSize: 14,
  color: theme.palette.text.secondary
}));

const ReleasesOnlyAvailableInEnglishMessage = props => (
  <StyledMessage {...props}>
    <FormattedMessage id="header.options_menu.releases_overview.releases_only_available_in_english" />
  </StyledMessage>
);

export default ReleasesOnlyAvailableInEnglishMessage;
