import { useState } from 'react';
import Space from 'lib/components/Space';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import ChallengeCreationDialog from '../../CreateChallengeWithRowSelection/ChallengeCreationDialog';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreateFirstChallenge = props => {
  const [
    challengeCreationDialogOpen,
    setChallengeCreationDialogOpen
  ] = useState(false);
  return (
    <>
      <br />
      <Space height={12} />
      <Button
        color="primary"
        onClick={() => setChallengeCreationDialogOpen(true)}
        startIcon={<AddIcon />}
        {...props}
      >
        <FormattedMessageWithChallengeNaming id="portfolio.challenge_portfolio.no_challenges.create_first_challenge_button" />
      </Button>
      <ChallengeCreationDialog
        open={challengeCreationDialogOpen}
        onClose={() => setChallengeCreationDialogOpen(false)}
        rowKeys={[]}
        highestRowUiPos={0}
      />
    </>
  );
};

export default CreateFirstChallenge;
