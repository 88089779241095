import styled from '@emotion/styled';
import { DialogActions } from '@material-ui/core';

const SupportDialogActions = styled(DialogActions)({
  paddingTop: 8,
  paddingBottom: 16,
  paddingLeft: 24,
  paddingRight: 24
});

export default SupportDialogActions;
