/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import SelectedUserContext from 'lib/contexts/SelectedUserContext';
import SelectedPlatformContext from './SelectedPlatformContext';

const IsUsingPlanningViewContext = createContext([false, () => {}]);

const AutomatedIsUsingPlanningViewContextProvider = props => {
  const [isUsingPlanningView, setIsUsingPlanningView] = useState(false);

  const history = useHistory();
  const selectedUser = useContext(SelectedUserContext);
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  useEffect(() => {
    setIsUsingPlanningView(false);
  }, [history.location.pathname, selectedUser?.email, selectedPlatform]);

  return (
    <IsUsingPlanningViewContext.Provider
      value={[isUsingPlanningView, setIsUsingPlanningView]}
      {...props}
    />
  );
};

IsUsingPlanningViewContext.AutomatedProvider = AutomatedIsUsingPlanningViewContextProvider;

export default IsUsingPlanningViewContext;
