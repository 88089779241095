import PropTypes from 'prop-types';

export const uiPosPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.objectOf(PropTypes.number)
]);

export const extractUiPos = (item, parentKey) => {
  if (!item?.uiPos) {
    return null;
  }

  const isSimpleUiPos = !parentKey || typeof item.uiPos === 'number';
  if (isSimpleUiPos) {
    return item.uiPos;
  }

  const compoundUiPosObject = item.uiPos;
  const uiPos = compoundUiPosObject[parentKey];

  if (typeof uiPos !== 'number') {
    return null;
  }

  return uiPos;
};

export const getUpdatedUiPos = (oldUiPos, parentKey, newUiPos) => {
  const isSimpleUiPos =
    !parentKey ||
    typeof oldUiPos === 'number' ||
    typeof oldUiPos === 'undefined';

  if (isSimpleUiPos || !newUiPos) {
    return newUiPos;
  }

  oldUiPos[parentKey] = newUiPos;
  return oldUiPos;
};

export const removeOldUiPos = (uiPos, oldParentKey, newParentKey) => {
  const isSimpleUiPos = typeof uiPos !== 'object';
  const cannotRemove = !uiPos || !oldParentKey || !newParentKey;
  const removalUnnecessary = oldParentKey === newParentKey;

  if (isSimpleUiPos || cannotRemove || removalUnnecessary) {
    return uiPos;
  }

  delete uiPos[oldParentKey];
  return uiPos;
};
