import styled from '@emotion/styled';
import { Stars as StarIcon } from '@material-ui/icons';

const OriginalOwnerIndicator = styled(StarIcon)(({ theme }) => ({
  marginLeft: 6,
  width: 18,
  height: 18,
  color: theme.palette.primary.main
}));

export default OriginalOwnerIndicator;
