import { useContext } from 'react';
import LearningGoalsModelContext from '../LearningGoalsModelContext';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import NoItemsMessage from 'components/LearningGoals/NoItemsMessage';
import TileGrid from 'components/LearningGoals/TileGrid';
import Topic from './Topic';
import { FormattedMessage } from 'react-intl';

const Topics = props => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const topics = flattenFirebaseList(learningGoalsModel?.topics);

  if (topics.length === 0) {
    return (
      <NoItemsMessage>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.no_topics" />
      </NoItemsMessage>
    );
  }

  return (
    <TileGrid {...props}>
      {topics.map(topic => (
        <Topic topic={topic} key={topic.key} />
      ))}
    </TileGrid>
  );
};

export default Topics;
