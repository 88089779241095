import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

const StyledHomeButton = styled(Button)({
  marginTop: -12,
  marginBottom: 24,
  width: 'fit-content'
});

export default StyledHomeButton;
