import imageOne from './1.jpg';
import imageTwo from './2.jpg';
import imageThree from './3.jpg';
import imageFour from './4.jpg';
import imageFive from './5.jpg';
import imageSix from './6.jpg';
import imageSeven from './7.jpg';
import imageEight from './8.jpg';
import imageNine from './9.jpg';
import imageTen from './10.jpg';
import imageEleven from './11.jpg';
import imageTwelve from './12.jpg';
import imageThirteen from './13.jpg';
import imageFourteen from './14.jpg';
import imageFifteen from './15.jpg';
import imageSixteen from './16.jpg';
import imageSeventeen from './17.jpg';
import imageEighteen from './18.jpg';
import imageNineteen from './19.jpg';
import imageTwenty from './20.jpg';
import imageTwentyOne from './21.jpg';
import imageTwentyTwo from './22.jpg';

const images = [
  imageOne,
  imageTwo,
  imageThree,
  imageFour,
  imageFive,
  imageSix,
  imageSeven,
  imageEight,
  imageNine,
  imageTen,
  imageEleven,
  imageTwelve,
  imageThirteen,
  imageFourteen,
  imageFifteen,
  imageSixteen,
  imageSeventeen,
  imageEighteen,
  imageNineteen,
  imageTwenty,
  imageTwentyOne,
  imageTwentyTwo
];

export default images;
