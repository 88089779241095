import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import LinkSharingTitle from './LinkSharingTitle';
import LinkField from './LinkField';
import PrivacyNote from './PrivacyNote';

const LinkSharingDialog = ({ challengeKey, asTask, onClose, ...props }) => (
  <Dialog onClose={onClose} onEnterClose={onClose} {...props}>
    <LinkSharingTitle asTask={asTask} />
    <Dialog.Content>
      <LinkField challengeKey={challengeKey} asTask={asTask} />
      <PrivacyNote asTask={asTask} />
    </Dialog.Content>
    <Dialog.Actions>
      <Dialog.Actions.DoneButton onClick={onClose} />
    </Dialog.Actions>
  </Dialog>
);

LinkSharingDialog.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default LinkSharingDialog;
