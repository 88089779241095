import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import ButtonsWrapper from './ButtonsWrapper';
import OpenAttachedChallenge from './OpenAttachedChallenge';
import DeleteAttachment from './DeleteAttachment';

const AttachedChallenge = ({
  challengeKey,
  challengeUrl,
  asTask,
  levelKey,
  ...props
}) => {
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <ButtonsWrapper {...props}>
      <OpenAttachedChallenge
        challengeKey={challengeKey}
        challengeUrl={challengeUrl}
        asTask={asTask}
      />
      {portfolioMode === PortfolioModes.STUDENT && (
        <DeleteAttachment challengeKey={challengeKey} levelKey={levelKey} />
      )}
    </ButtonsWrapper>
  );
};

AttachedChallenge.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  challengeUrl: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired,
  levelKey: PropTypes.string.isRequired
};

export default AttachedChallenge;
