import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const YearLabel = styled('b')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500,
  transition: theme.transitions.create(['color'])
}));

const YearIndicator = ({ year, ...props }) => (
  <>
    {' '}
    &ndash; <YearLabel {...props}>{year}</YearLabel>
  </>
);

YearIndicator.propTypes = {
  year: PropTypes.number.isRequired
};

export default YearIndicator;
