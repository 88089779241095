import { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import StyledPhaseColumn from './StyledPhaseColumn';
import PhaseContext from '../../../PhaseContext';
import VisiblePhaseContext from 'components/ChallengePortfolio/VisiblePhaseContext';

const PhaseColumn = forwardRef(({ inTaskBoard, ...props }, ref) => {
  const phase = useContext(PhaseContext);
  const { visibleChallengePhase, visibleTaskPhase } = useContext(
    VisiblePhaseContext
  );
  const visiblePhase = inTaskBoard ? visibleTaskPhase : visibleChallengePhase;

  return (
    <StyledPhaseColumn
      invisible={phase !== visiblePhase}
      inTaskBoard={inTaskBoard}
      ref={ref}
      {...props}
    />
  );
});

PhaseColumn.propTypes = {
  hasItems: PropTypes.bool,
  inTaskBoard: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default PhaseColumn;
