import { useState, useEffect } from 'react';

const useSelectionCursor = selectableUsers => {
  const [selectionCursor, setSelectionCursor] = useState(null);

  const moveSelectionCursorUp = () => {
    if (selectionCursor === null) {
      setSelectionCursor(selectableUsers.length - 1);
      return;
    }

    setSelectionCursor(selectionCursor => {
      const shouldMoveCursorToEnd = selectionCursor === 0;
      if (shouldMoveCursorToEnd) {
        return selectableUsers.length - 1;
      }

      return selectionCursor - 1;
    });
  };

  const moveSelectionCursorDown = () => {
    if (selectionCursor === null) {
      setSelectionCursor(0);
      return;
    }

    setSelectionCursor(selectionCursor => {
      const shouldResetCursor = selectionCursor === selectableUsers.length - 1;
      if (shouldResetCursor) {
        return 0;
      }

      return selectionCursor + 1;
    });
  };

  const clearSelectionCursor = () => setSelectionCursor(null);

  const selectableUsersAsString = selectableUsers
    .map(({ email }) => email)
    .join(',');

  useEffect(() => {
    clearSelectionCursor();
  }, [selectableUsersAsString]);

  return {
    index: selectionCursor,
    moveUp: moveSelectionCursorUp,
    moveDown: moveSelectionCursorDown,
    clear: clearSelectionCursor
  };
};

export default useSelectionCursor;
