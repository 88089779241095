import styled from '@emotion/styled';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const StyledFeedbackItem = styled('article')(
  ({ theme }) => ({
    marginBottom: 12,
    padding: 20,
    fontSize: 14,
    backgroundColor: theme.palette.action.hover,
    borderRadius: 4,
    ':last-of-type': {
      marginBottom: 0
    }
  }),
  addBorderInHighContrastMode
);

export default StyledFeedbackItem;
