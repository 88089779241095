import { createContext, useState } from 'react';

const StackSizeContext = createContext({
  largestStackSize: 0,
  registerStackSize: () => {},
  unregisterStackSize: () => {}
});

const AutomatedStackSizeContextProvider = props => {
  const [stackSizes, setStackSizes] = useState({});
  const largestStackSize = Object.values(stackSizes).sort().reverse()[0] || 1;

  const registerStackSize = (stackKey, stackSize) =>
    setStackSizes(stackSizes => ({
      ...stackSizes,
      [stackKey]: stackSize
    }));

  const unregisterStackSize = stackKey =>
    setStackSizes(stackSizes => {
      const newStackSizesObject = { ...stackSizes };
      delete newStackSizesObject[stackKey];
      return newStackSizesObject;
    });

  return (
    <StackSizeContext.Provider
      value={{
        largestStackSize,
        registerStackSize,
        unregisterStackSize
      }}
      {...props}
    />
  );
};

StackSizeContext.AutomatedProvider = AutomatedStackSizeContextProvider;

export default StackSizeContext;
