import styled from '@emotion/styled';

const LoadingContentWrapper = styled('section')({
  paddingTop: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

export default LoadingContentWrapper;
