import { useTheme } from '@emotion/react';
import { darken } from 'lib/styling/colors';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const DangerColorThemeProvider = props => {
  const theme = useTheme();
  const forceDangerColorTheme = theme.extend({
    palette: {
      primary: {
        main: theme.palette.danger,
        dark: darken(theme.palette.danger, 25)
      }
    }
  });

  return <MuiThemeProvider theme={forceDangerColorTheme} {...props} />;
};

export default DangerColorThemeProvider;
