import styled from '@emotion/styled';

const SectionTitle = styled('h1')({
  paddingTop: 24,
  paddingLeft: 24,
  paddingRight: 24,
  fontSize: 20,
  lineHeight: 1
});

export default SectionTitle;
