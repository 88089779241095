import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import App from 'lib/App';

const useSelectableFieldTypesForPhaseContentGroups = () => {
  const selectableFieldTypesForPhaseContentGroups = {
    [PhaseContentGroups.LEFT_CONTENT]: [
      FieldTypes.TEXT,
      FieldTypes.SELECT,
      FieldTypes.LOGS,
      FieldTypes.RUBRICS,
      FieldTypes.LEARNING_GOALS,
      FieldTypes.MANUAL_CHALLENGE_SHARING,
      FieldTypes.MANUAL_ASSIGNEES,
      FieldTypes.MANUAL_AGORA_WORLDS
    ],
    [PhaseContentGroups.RIGHT_CONTENT]: [
      FieldTypes.DATE,
      FieldTypes.SELECT,
      FieldTypes.LINK_BUTTON,
      FieldTypes.LEARNING_GOALS,
      FieldTypes.MANUAL_PLANNED_START_DATE,
      FieldTypes.MANUAL_PLANNED_END_DATE,
      FieldTypes.MANUAL_ACTUAL_START_DATE,
      FieldTypes.MANUAL_ACTUAL_END_DATE,
      FieldTypes.MANUAL_AGORA_WORLDS,
      FieldTypes.MANUAL_COLOR_PICKER
    ]
  };

  selectableFieldTypesForPhaseContentGroups[PhaseContentGroups.END_CONTENT] =
    selectableFieldTypesForPhaseContentGroups[PhaseContentGroups.LEFT_CONTENT];

  const sloSystemEnabled = App.instanceConfig.useContentSetting(
    'enableSloSystem'
  );
  if (sloSystemEnabled) {
    selectableFieldTypesForPhaseContentGroups[
      PhaseContentGroups.LEFT_CONTENT
    ].push(FieldTypes.MANUAL_ATTACHED_SLOS);
  }

  return selectableFieldTypesForPhaseContentGroups;
};

export default useSelectableFieldTypesForPhaseContentGroups;
