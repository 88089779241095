import { FormattedMessage } from 'react-intl';
import Space from 'lib/components/Space';

const LoginWelcomeTitle = props => (
  <>
    <h1 {...props}>
      <FormattedMessage id="login.welcome_text" />
    </h1>
    <Space height={24} />
  </>
);

export default LoginWelcomeTitle;
