import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../../FieldContext';
import StyledAddButton from './StyledAddButton';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { FormattedMessage } from 'react-intl';

const AddButton = ({ onClick, ...props }) => {
  const { field } = useContext(FieldContext);
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <StyledAddButton
      onClick={portfolioMode === PortfolioModes.STUDENT ? onClick : null}
      disabled={portfolioMode === PortfolioModes.COACH}
      {...props}
    >
      <FormattedMessage
        id="portfolio.challenge.field.add_button"
        values={{
          fieldName: field.name
        }}
      />
    </StyledAddButton>
  );
};

AddButton.propTypes = {
  onClick: PropTypes.func
};

export default AddButton;
