import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

const StyledFocusButton = styled(IconButton, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    margin: -8,
    marginRight: 16,
    padding: 8,
    width: 36,
    height: 36,
    transition: theme.transitions.create(),
    [mediaQuery(768)]: {
      marginRight: 8
    }
  }),
  addHighContrastStyles({
    ':not(:disabled):hover, :focus': {
      transform: 'scale(1.1)'
    }
  })
);

export default StyledFocusButton;
