import { useState } from 'react';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import { AccountBox as AccountIcon } from '@material-ui/icons';
import SettingsBox from '../SettingsBox';
import AllowedEmailManager from './AllowedEmailManager';
import useAllowedEmailsState from './useAllowedEmailsState';
import AllowedEmailPrefixField from './AllowedEmailPrefixField';
import AuthProviderPicker from './AuthProviderPicker';
import SaveButtonWithConfirmationPrompt from './SaveButtonWithConfirmationPrompt';
import Space from 'lib/components/Space';

const LoginSettings = props => {
  const instanceConfig = App.instanceConfig.useSettings();

  const {
    allowedEmails,
    allowedEmailsAsObject: newAllowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail
  } = useAllowedEmailsState(instanceConfig.allowedEmails);
  const [allowedEmailPrefix, setAllowedEmailPrefix] = useState(
    instanceConfig.allowedEmailPrefix
  );
  const [authProvider, setAuthProvider] = useState(instanceConfig.authProvider);

  const settingsHaveChanged =
    JSON.stringify(Object.values(newAllowedEmails)) !==
      JSON.stringify(instanceConfig.allowedEmails) ||
    allowedEmailPrefix !== instanceConfig.allowedEmailPrefix ||
    authProvider !== instanceConfig.authProvider;

  const saveChanges = async () => {
    if (settingsHaveChanged) {
      await ref('instanceConfig').update({
        allowedEmails: newAllowedEmails,
        allowedEmailPrefix: allowedEmailPrefix,
        authProvider: authProvider
      });

      window.location.reload();
    }
  };

  return (
    <SettingsBox
      title={<FormattedMessage id="platform_settings.login_settings.title" />}
      icon={<AccountIcon />}
      {...props}
    >
      <AllowedEmailManager
        allowedEmails={allowedEmails}
        addAllowedEmail={addAllowedEmail}
        editAllowedEmail={editAllowedEmail}
        removeAllowedEmail={removeAllowedEmail}
      />
      <AllowedEmailPrefixField
        value={allowedEmailPrefix}
        onChange={e => setAllowedEmailPrefix(e.target.value.trim())}
      />
      <AuthProviderPicker
        value={authProvider}
        onChange={e => setAuthProvider(e.target.value)}
      />
      <Space height={4} />
      <SaveButtonWithConfirmationPrompt
        onSave={saveChanges}
        disabled={!settingsHaveChanged}
      />
    </SettingsBox>
  );
};

export default LoginSettings;
