import PropTypes from 'prop-types';
import FullscreenView from '../../../../../FullscreenView';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import EditableSloName from './EditableSloName';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import DeleteSlo from './DeleteSlo';
import SloDescription from './SloDescription';
import SloPartAttachment from './SloPartAttachment';
import SloChallengeAttachment from './SloChallengeAttachment';
import SloReflection from './SloReflection';

const SloView = ({ onClose, ...props }) => (
  <FullscreenView {...props}>
    <FullscreenView.Header forceFlex>
      <FullscreenView.Header.ActionButton onClick={onClose}>
        <ArrowBackIcon />
      </FullscreenView.Header.ActionButton>
      <EditableSloName />
      <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
        <DeleteSlo />
      </RestrictedToPortfolioMode>
    </FullscreenView.Header>
    <SloDescription />
    <SloPartAttachment />
    <SloChallengeAttachment />
    <SloReflection />
  </FullscreenView>
);

SloView.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SloView;
