import PropTypes from 'prop-types';
import ErrorScreenWrapper from './ErrorScreenWrapper';
import AllowedEmailsText from './AllowedEmailsText';
import AllowedEmailsMessage from '../AllowedEmailsMessage';
import DangerousButton from 'lib/components/DangerousButton';
import { Refresh as RetryIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const EmailNotAllowedErrorScreen = ({
  visible,
  onDismissEmailNotAllowedError,
  ...props
}) => (
  <ErrorScreenWrapper visible={visible} {...props}>
    <h2>
      <FormattedMessage id="login.email_not_allowed_error_screen.title" />
    </h2>
    <AllowedEmailsText>
      <AllowedEmailsMessage translationsGroupId="login.email_not_allowed_error_screen.allowed_emails_text" />
    </AllowedEmailsText>
    <DangerousButton
      variant="contained"
      onClick={onDismissEmailNotAllowedError}
      startIcon={<RetryIcon />}
      disabled={!visible}
      disableElevation
    >
      <FormattedMessage id="login.email_not_allowed_error_screen.try_again_button" />
    </DangerousButton>
  </ErrorScreenWrapper>
);

EmailNotAllowedErrorScreen.propTypes = {
  visible: PropTypes.bool.isRequired,
  onDismissEmailNotAllowedError: PropTypes.func.isRequired
};

export default EmailNotAllowedErrorScreen;
