import { useState } from 'react';
import usePortfolioUser from './usePortfolioUser';
import App from 'lib/App';
import { Tooltip } from '@material-ui/core';
import HeaderActionIconButton from '../HeaderActionIconButton';
import { School as SloIcon } from '@material-ui/icons';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import AutomatedPortfolioModeContextProvider from './AutomatedPortfolioModeContextProvider';
import SloSystemContent from './SloSystemContent';
import { FormattedMessage } from 'react-intl';

const SloSystem = props => {
  const [sloSystemOpen, setSloSystemOpen] = useState(false);
  const portfolioUser = usePortfolioUser();

  const sloSystemEnabled = App.instanceConfig.useContentSetting(
    'enableSloSystem'
  );
  const hasActivePortfolio = !!portfolioUser;

  if (!sloSystemEnabled || !hasActivePortfolio) {
    return null;
  }

  return (
    <>
      <Tooltip title={<FormattedMessage id="slo_system.button" />}>
        <HeaderActionIconButton
          onClick={() => {
            setSloSystemOpen(true);
            setBodyOverflow('hidden');
          }}
          {...props}
        >
          <SloIcon />
        </HeaderActionIconButton>
      </Tooltip>
      <PortfolioUserContext.Provider value={portfolioUser}>
        <AutomatedPortfolioModeContextProvider>
          {sloSystemOpen && (
            <SloSystemContent
              onClose={() => {
                setSloSystemOpen(false);
                setBodyOverflow('auto');
              }}
            />
          )}
        </AutomatedPortfolioModeContextProvider>
      </PortfolioUserContext.Provider>
    </>
  );
};

export default SloSystem;
