import { useContext } from 'react';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import sortPartsByUiPos from 'lib/rubricsPortfolioUtils/sortPartsByUiPos';

const useSubtopicParts = subtopic => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const allParts = portfolioTemplates?.parts;
  const subtopicPartsKeys = safeKeys(subtopic.parts);
  const subtopicParts = subtopicPartsKeys
    .map(partKey => allParts?.[partKey])
    .filter(part => !!part)
    .filter(userCanAccessItem(portfolioUser))
    .sort(sortPartsByUiPos(subtopic.id));

  return subtopicParts;
};

export default useSubtopicParts;
