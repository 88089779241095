import StyledRefreshButton from './StyledRefreshButton';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const RefreshButton = props => {
  const refreshPage = () => window.location.reload();
  return (
    <StyledRefreshButton
      variant="outlined"
      onClick={refreshPage}
      startIcon={<RefreshIcon />}
      {...props}
    >
      <FormattedMessage id="error_boundary.fixes.refresh_button" />
    </StyledRefreshButton>
  );
};

export default RefreshButton;
