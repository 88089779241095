import PropTypes from 'prop-types';
import FullscreenView from '../FullscreenView';
import { Close as CloseIcon } from '@material-ui/icons';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import CreateSloPeriod from './CreateSloPeriod';
import { FormattedMessage } from 'react-intl';

const SloSystemHeader = ({ onCloseSloSystem, ...props }) => (
  <FullscreenView.Header>
    <FullscreenView.Header.ActionButton onClick={onCloseSloSystem}>
      <CloseIcon />
    </FullscreenView.Header.ActionButton>
    <FullscreenView.Header.Title>
      <FormattedMessage id="slo_system.header.title" />
    </FullscreenView.Header.Title>
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <Space width="auto" />
      <CreateSloPeriod />
    </RestrictedToPortfolioMode>
  </FullscreenView.Header>
);

SloSystemHeader.propTypes = {
  onCloseSloSystem: PropTypes.func.isRequired
};

export default SloSystemHeader;
