const makeSafe = objectFlatteningFunction => object => {
  if (!object || typeof object !== 'object') {
    return [];
  }

  return objectFlatteningFunction(object);
};

export const safeKeys = makeSafe(Object.keys);
export const safeValues = makeSafe(Object.values);
export const safeEntries = makeSafe(Object.entries);
