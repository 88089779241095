import { useContext } from 'react';
import PhaseContext from '../../../../../PhaseContext';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import sortByUiPos from 'lib/utils/sortByUiPos';

const useLowestUiPosInPhaseEndContent = () => {
  const { phase } = useContext(PhaseContext);

  const endContentFields = flattenFirebaseList(
    phase[PhaseContentGroups.END_CONTENT]
  );

  const sortedFields = endContentFields.sort(sortByUiPos);

  const firstField = sortedFields[0];

  if (!firstField) {
    return null;
  }

  return firstField.uiPos;
};

export default useLowestUiPosInPhaseEndContent;
