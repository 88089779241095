import ActionButton from './ActionButton';
import { Link as LinkIcon } from '@material-ui/icons';

const LinkSharingButton = props => (
  <ActionButton
    icon={<LinkIcon />}
    labelId="portfolio.challenge.drawer.link_sharing.button"
    {...props}
  />
);

export default LinkSharingButton;
