import styled from '@emotion/styled';
import { Fab } from '@material-ui/core';

const StyledAddButton = styled(Fab)({
  position: 'fixed',
  bottom: 24,
  right: 24,
  zIndex: 1
});

export default StyledAddButton;
