import { useState } from 'react';
import EditText from './EditText';
import ViewText from './ViewText';

const TextField = () => {
  const [textEditing, setTextEditing] = useState(false);

  if (textEditing) {
    return <EditText onEditClose={() => setTextEditing(false)} />;
  }

  return <ViewText onEdit={() => setTextEditing(true)} />;
};

export default TextField;
