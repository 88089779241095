import PropTypes from 'prop-types';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import EditPhaseNameButton from './EditPhaseNameButton';
import PhaseGroups from './PhaseGroups';
import DeletePhase from './DeletePhase';

const PhaseActions = ({ onNameEdit, ...props }) => (
  <CollapsibleActionMenu {...props}>
    <EditPhaseNameButton onClick={onNameEdit} />
    <PhaseGroups />
    <DeletePhase />
  </CollapsibleActionMenu>
);

PhaseActions.propTypes = {
  onNameEdit: PropTypes.func.isRequired
};

export default PhaseActions;
