import PropTypes from 'prop-types';
import StyledTitle from './StyledTitle';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const getTitleId = (plural, asTask) => {
  if (asTask && plural) {
    return 'portfolio.challenge.learning_goals.task_is_attached_to.plural';
  }

  if (asTask) {
    return 'portfolio.challenge.learning_goals.task_is_attached_to';
  }

  if (plural) {
    return 'portfolio.challenge.learning_goals.challenge_is_attached_to.plural';
  }

  return 'portfolio.challenge.learning_goals.challenge_is_attached_to';
};

const AttachmentsTitle = ({ plural, asTask, ...props }) => (
  <StyledTitle {...props}>
    <FormattedMessageWithChallengeNaming id={getTitleId(plural, asTask)} />
  </StyledTitle>
);

AttachmentsTitle.propTypes = {
  plural: PropTypes.bool.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default AttachmentsTitle;
