import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import ControlsWrapper from './ControlsWrapper';
import { Tooltip, IconButton } from '@material-ui/core';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';
import YearIndicator from './YearIndicator';
import { FormattedMessage } from 'react-intl';

const VisibleWeekRangeControls = props => {
  const {
    visibleWeeksCount,
    shiftVisibleWeekRangeToLeft,
    shiftVisibleWeekRangeToRight
  } = useContext(VisibleWeekRangeContext);

  const usePluralTooltips = visibleWeeksCount > 1;

  return (
    <ControlsWrapper {...props}>
      <Tooltip
        title={
          <FormattedMessage
            id={`portfolio.challenge_planning_view.header.visible_week_range_controls.view_previous.${
              usePluralTooltips ? 'plural' : 'singular'
            }`}
          />
        }
      >
        <IconButton onClick={shiftVisibleWeekRangeToLeft}>
          <ArrowLeftIcon />
        </IconButton>
      </Tooltip>
      <YearIndicator />
      <Tooltip
        title={
          <FormattedMessage
            id={`portfolio.challenge_planning_view.header.visible_week_range_controls.view_next.${
              usePluralTooltips ? 'plural' : 'singular'
            }`}
          />
        }
      >
        <IconButton onClick={shiftVisibleWeekRangeToRight}>
          <ArrowRightIcon />
        </IconButton>
      </Tooltip>
    </ControlsWrapper>
  );
};

export default VisibleWeekRangeControls;
