import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const PhaseName = styled('h2')(({ theme }) => ({
  marginBottom: 18,
  color: theme.palette.primary.main,
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: 1,
  [mediaQuery(560)]: {
    fontSize: 22
  }
}));

export default PhaseName;
