export const supportedLanguages = ['nl', 'en', 'fr'];
const fallbackLanguage = 'en';

const getBrowserLanguage = () => {
  for (const language of supportedLanguages) {
    if (navigator.language.startsWith(language)) {
      return language;
    }
  }

  return fallbackLanguage;
};

const getLanguage = () => {
  const localLanguage = localStorage.getItem('language');
  if (localLanguage && supportedLanguages.includes(localLanguage)) {
    return localLanguage;
  }

  return getBrowserLanguage();
};

export default getLanguage;
