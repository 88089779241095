/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const useFirebaseValue = ref => {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState(null);

  const refAsString = ref.toString();
  useEffect(() => {
    const listener = ref.on('value', snapshot => {
      setValue(snapshot.val());
      setLoading(false);
    });

    return () => {
      ref.off('value', listener);
      setLoading(true);
      setValue(null);
    };
  }, [refAsString]);

  return [loading, value];
};

export default useFirebaseValue;
