import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import { deletePart } from './Parts/DeletePart';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

export const deleteSubtopic = (learningGoalsModelKey, subtopic) => {
  const learningGoalsModelRef = ref(
    `models/learningGoals/${learningGoalsModelKey}`
  );

  learningGoalsModelRef.child(`subtopics/${subtopic.key}`).remove();

  learningGoalsModelRef
    .child(`topics/${subtopic.topicKey}/subtopics/${subtopic.key}`)
    .remove();

  if (!subtopic.parts) {
    return;
  }

  safeKeys(subtopic.parts).map(partKey =>
    deletePart(learningGoalsModelKey, subtopic.key, partKey)
  );
};

const DeleteSubtopic = ({ subtopic, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </TileGrid.Tile.ActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.subtopic.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.subtopic.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton
            onClick={() => deleteSubtopic(learningGoalsModel.key, subtopic)}
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteSubtopic.propTypes = {
  subtopic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    topicKey: PropTypes.string.isRequired,
    parts: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default DeleteSubtopic;
