import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledWeekLabel = styled('h4')(
  ({ theme, highlighted }) => ({
    marginBottom: 4,
    color: highlighted
      ? theme.palette.primary.main
      : theme.palette.text.secondary,
    fontWeight: 400,
    transition: theme.transitions.create(['color'])
  }),
  addHighContrastStyles(({ highlighted }) => {
    if (highlighted) {
      return {
        textDecoration: 'underline'
      };
    }
  })
);

StyledWeekLabel.propTypes = {
  highlighted: PropTypes.bool.isRequired
};

export default StyledWeekLabel;
