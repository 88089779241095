import { useState } from 'react';
import App from 'lib/App';
import getProgressFromMousePosition from './getProgressFromMousePosition';

const useSliderState = (readOnly, sliderRef) => {
  const reversed = App.instanceConfig.useContentSetting('useReversedSliders');

  const [sliding, setSliding] = useState(false);
  const [progress, setProgress] = useState(null);

  const recomputeProgress = e => {
    if (readOnly) {
      return;
    }

    const newProgress = getProgressFromMousePosition(e, reversed, sliderRef);
    if (newProgress !== null) {
      setProgress(newProgress);
    }
  };

  const handleMouseMoveActivate = e => {
    const isLeftButton = !e.button || e.button === 0;
    if (isLeftButton && !sliding) {
      setSliding(true);
    }
  };

  const handleMouseMoveDeactivate = e => {
    if (sliding) {
      setSliding(false);
      recomputeProgress(e);
    }
  };

  const handleMouseMove = e => sliding && recomputeProgress(e);

  return [
    sliding,
    progress,
    handleMouseMove,
    handleMouseMoveActivate,
    handleMouseMoveDeactivate
  ];
};

export default useSliderState;
