import PropTypes from 'prop-types';
import TileInfoContext from './TileInfoContext';
import StyledTile from './StyledTile';
import TileClickListener from './TileClickListener';
import TileTitle from './TileTitle';
import OnlyShowOnHover from './OnlyShowOnHover';
import TileActionButton from './TileActionButton';

const Tile = props => (
  <TileInfoContext.Provider
    value={{
      fontSize: props.fontSize,
      color: props.color
    }}
  >
    <StyledTile {...props} />
  </TileInfoContext.Provider>
);

Tile.propTypes = {
  color: PropTypes.string,
  studentCompletion: PropTypes.number,
  coachCompletion: PropTypes.number,
  hasCompletionFill: PropTypes.bool,
  fontSize: PropTypes.number
};

Tile.defaultProps = {
  // material-ui's common.white breaks the text color detection
  color: '#FFFFFF',
  studentCompletion: 0,
  coachCompletion: 0,
  hasCompletionFill: false,
  fontSize: 24
};

Tile.ClickListener = TileClickListener;
Tile.Title = TileTitle;
Tile.OnlyShowOnHover = OnlyShowOnHover;
Tile.ActionButton = TileActionButton;

export default Tile;
