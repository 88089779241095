import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';

const FlagWrapper = styled(Avatar)({
  width: 24,
  height: 24,
  display: 'block'
});

export default FlagWrapper;
