import { useState } from 'react';
import useInterval from 'use-interval';

// This is the least optimal way in the world to do this, however, since
// react-router's useHistory() seems to be broken, we have no choice
const useCurrentUrl = () => {
  const [currentUrl, setCurrentUrl] = useState('/');

  useInterval(() => {
    if (window.location.pathname !== currentUrl) {
      setCurrentUrl(window.location.pathname);
    }
  }, 1);

  return currentUrl;
};

export default useCurrentUrl;
