import PropTypes from 'prop-types';
import StyledDateText from './StyledDateText';
import getPlannedEndDateColor from './getPlannedEndDateColor';
import formatPlannedEndDate from './formatPlannedEndDate';

const PlannedEndDatePreview = ({ date, challengeColor, ...props }) => {
  const challengeIsWhite = ['#FFF', '#FFFFFF'].includes(challengeColor);
  return (
    <StyledDateText
      color={getPlannedEndDateColor(date)}
      challengeIsWhite={challengeIsWhite}
    >
      {formatPlannedEndDate(date)}
    </StyledDateText>
  );
};

PlannedEndDatePreview.propTypes = {
  date: PropTypes.number.isRequired,
  challengeColor: PropTypes.string
};

PlannedEndDatePreview.defaultProps = {
  challengeColor: '#FFFFFF' // Using common.white here *will* break this
};

export default PlannedEndDatePreview;
