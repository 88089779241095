import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

const StyledLabel = styled('h4')(({ theme }) => ({
  marginRight: 8,
  color: theme.palette.text.secondary,
  fontWeight: 400,
  flexShrink: 0
}));

const EditorLabel = ({ id, ...props }) => (
  <StyledLabel {...props}>
    <FormattedMessage id={id} />
  </StyledLabel>
);

EditorLabel.propTypes = {
  id: PropTypes.string.isRequired
};

export default EditorLabel;
