import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import egodactLogoDefault from './egodact-logo-default.svg';
import egodactLogoWhite from './egodact-logo-white.svg';

const EgodactLogo = props => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <img
      src={theme.isDark ? egodactLogoWhite : egodactLogoDefault}
      alt={intl.formatMessage({
        id: 'egodact_logo.alt'
      })}
      {...props}
    />
  );
};

export default EgodactLogo;
