import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import LinesWrapper from './WeekMarkingBackgroundLines/LinesWrapper';
import BackgroundLineWithSpacing from './WeekMarkingBackgroundLines/BackgroundLineWithSpacing';

const DayMarkingBackgroundLines = props => {
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);
  return (
    <LinesWrapper key={visibleWeekRangeStartDate} {...props}>
      {[1, 2, 3, 4, 5, 6, 7].map(weekday => (
        <BackgroundLineWithSpacing key={weekday} />
      ))}
    </LinesWrapper>
  );
};
export default DayMarkingBackgroundLines;
