import InputWithEscListener from 'lib/components/InputWithEscListener';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

const OptionNameTextField = props => {
  const intl = useIntl();
  return (
    <InputWithEscListener
      InputComponent={TextField}
      size="small"
      placeholder={intl.formatMessage({
        id:
          'editor.model_editor.field_editor.select_editor.opion.edit_name.input_placeholder'
      })}
      style={{
        marginRight: 6,
        marginBottom: -4
      }}
      autoFocus
      fullWidth
      {...props}
    />
  );
};

export default OptionNameTextField;
