import PropTypes from 'prop-types';
import App from 'lib/App';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import sortByUiPos from 'lib/utils/sortByUiPos';
import getHighestUiPosFromSortedItems from 'pages/EditorPage/utils/getHighestUiPosFromSortedItems';
import ModelEditorCard from './ModelEditorCard';
import ModelTypeContext from './ModelTypeContext';
import ModelPhaseEditor from './ModelPhaseEditor';
import AddModelPhase from './AddModelPhase';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';

const ModelEditorForModelType = ({ selectedModelType, ...props }) => {
  const models = App.useModels();
  const model = models[selectedModelType];
  const phases = flattenFirebaseList(model.phases);
  const sortedPhases = phases.sort(sortByUiPos);
  const highestPhaseUiPos = getHighestUiPosFromSortedItems(sortedPhases);

  return (
    <ModelEditorCard variant="outlined" {...props}>
      <ModelTypeContext.Provider value={selectedModelType}>
        {sortedPhases.map((phase, index) => (
          <ModelPhaseEditor
            phase={phase}
            previousPhase={sortedPhases[index - 1]}
            nextPhase={sortedPhases[index + 1]}
            key={phase.key}
          />
        ))}
        <AddModelPhase highestPhaseUiPos={highestPhaseUiPos} />
      </ModelTypeContext.Provider>
    </ModelEditorCard>
  );
};

ModelEditorForModelType.propTypes = {
  selectedModelType: PropTypes.oneOf([ModelTypes.CHALLENGE, ModelTypes.TASK])
    .isRequired
};

export default ModelEditorForModelType;
