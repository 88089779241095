import useSelectedTheme from 'lib/utils/useSelectedTheme';
import useHighContrastModeEnabled from 'lib/utils/useHighContrastModeEnabled';
import Themes from 'lib/constants/Themes';
import palettes from './palettes';

const useActivePalette = () => {
  const [selectedTheme] = useSelectedTheme();
  const [highContrastModeEnabled] = useHighContrastModeEnabled();

  if (!highContrastModeEnabled && selectedTheme === Themes.LIGHT) {
    return palettes.light;
  }

  if (highContrastModeEnabled && selectedTheme === Themes.LIGHT) {
    return palettes.lightHighContrast;
  }

  if (!highContrastModeEnabled && selectedTheme === Themes.DARK) {
    return palettes.dark;
  }

  if (highContrastModeEnabled && selectedTheme === Themes.DARK) {
    return palettes.darkHighContrast;
  }
};

export default useActivePalette;
