import { useContext, useState } from 'react';
import FieldContext from '../../../../FieldContext';
import App from 'lib/App';
import useDeleteNewlySharedChallenge from './useDeleteNewlySharedChallenge';
import useDeleteChallenge from 'lib/firebase/challengePortfolio/useDeleteChallenge';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import LeaveButton from './LeaveButton';
import Dialog from 'lib/components/Dialog';
import DialogLeaveButton from './DialogLeaveButton';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const LeaveChallenge = props => {
  const { challenge } = useContext(FieldContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const currentUserUsername = App.authentication.useCurrentUserUsername();
  const deleteNewlySharedChallenge = useDeleteNewlySharedChallenge();
  const deleteChallenge = useDeleteChallenge();

  const leaveChallenge = () => {
    setConfirmationDialogOpen(false);

    const rowKey = challenge.row[currentUserUsername];
    const challengeIsNewlyShared = !rowKey;

    if (challengeIsNewlyShared) {
      deleteNewlySharedChallenge(challenge.key);
      return;
    }

    deleteChallenge(challenge.key, rowKey);
  };

  const closeDialog = () => {
    setConfirmationDialogOpen(false);
    setBodyOverflow('hidden');
  };

  return (
    <>
      <LeaveButton onClick={() => setConfirmationDialogOpen(true)} {...props} />
      <Dialog open={confirmationDialogOpen} onClose={closeDialog} dangerous>
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.sharing.leave.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton onClick={closeDialog} />
          <DialogLeaveButton onClick={leaveChallenge} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default LeaveChallenge;
