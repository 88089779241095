import styled from '@emotion/styled';

const InnerDescriptionWrapper = styled('section')({
  width: 720,
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap'
});

export default InnerDescriptionWrapper;
