/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../FieldContext';
import useSyncedState from 'lib/utils/useSyncedState';
import { useDebounce } from 'use-debounce';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import KeyValueEditor from '../utils/KeyValueEditor';

const LinkButtonConfigFieldEditor = ({ id, ...props }) => {
  const { field } = useContext(FieldContext);

  const initialValue = field.linkButtonConfig?.[id];
  const [value, setValue] = useSyncedState(initialValue || '');
  const [debouncedValue] = useDebounce(value, 500);

  const fieldRef = useCurrentFieldRef();
  useEffect(() => {
    const newValue = value.trim();
    if (newValue !== initialValue) {
      fieldRef.child(`linkButtonConfig/${id}`).set(newValue);
    }
  }, [debouncedValue]);

  return (
    <KeyValueEditor.Wrapper>
      <KeyValueEditor.Label
        id={`editor.model_editor.field_editor.link_button_editor.config_fields.${id}.label`}
      />
      <KeyValueEditor.TextField
        placeholderId={`editor.model_editor.field_editor.link_button_editor.config_fields.${id}.input_placeholder`}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </KeyValueEditor.Wrapper>
  );
};

LinkButtonConfigFieldEditor.propTypes = {
  id: PropTypes.oneOf(['label', 'link', 'iconUrl']).isRequired
};

export default LinkButtonConfigFieldEditor;
