import PropTypes from 'prop-types';
import SettingsFieldTitle from '../../SettingsFieldTitle';
import UploadBox from './UploadBox';
import { FormattedMessage } from 'react-intl';

const LogoUploadSection = ({ logo, onSetLogoFiles, ...props }) => (
  <>
    <SettingsFieldTitle>
      <FormattedMessage id="platform_settings.general_settings.logo_upload.title" />
    </SettingsFieldTitle>
    <UploadBox preview={logo} onDrop={onSetLogoFiles} {...props}>
      <FormattedMessage id="platform_settings.general_settings.logo_upload.box_label" />
    </UploadBox>
  </>
);

LogoUploadSection.propTypes = {
  logo: PropTypes.string,
  onSetLogoFiles: PropTypes.func.isRequired
};

export default LogoUploadSection;
