import DialogButton from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogSaveButton = props => (
  <DialogButton color="primary" {...props}>
    <FormattedMessage id="dialogs.actions.save" />
  </DialogButton>
);

export default DialogSaveButton;
