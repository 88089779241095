import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ContentWrapper = styled('div')(({ hidden }) => ({
  width: '100%',
  height: '100%',
  fontFamily: 'CircularStd',
  display: hidden ? 'none' : 'flex',
  flexDirection: 'column'
}));

ContentWrapper.propTypes = {
  hidden: PropTypes.bool.isRequired
};

export default ContentWrapper;
