import Phases from 'lib/constants/Phases';

const fixChallengeDates = item => {
  const phase = item.phase;

  if (phase === Phases.TODO) {
    item.startDate = null;
    item.endDate = null;
  }

  if (phase === Phases.PROGRESS) {
    item.startDate = Date.now();
    item.endDate = null;
  }

  if (phase === Phases.DONE && !item.startDate) {
    item.startDate = Date.now();
  }

  if (phase === Phases.DONE) {
    item.endDate = Date.now();
  }

  return item;
};

export default fixChallengeDates;
