import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledPhaseText = styled('span')(
  ({ theme, challengeColor }) => ({
    marginBottom: 6,
    // #FFFFFF actually means (or should mean) "challenge is the default color"
    // hence that we want to use the secondary text color when the challenge
    // color is #FFFFFF
    color:
      challengeColor && challengeColor !== '#FFFFFF'
        ? theme.palette.getContrastText(challengeColor)
        : theme.palette.text.secondary
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    outline: `2px solid ${theme.palette.background.paper}`
  }))
);

StyledPhaseText.propTypes = {
  challengeColor: PropTypes.string
};

export default StyledPhaseText;
