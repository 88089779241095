import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const StyledButton = styled(Button)(
  ({ theme }) => ({
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.selected,
    overflow: 'hidden',
    ':hover': {
      backgroundColor: theme.palette.action.disabled
    }
  }),
  addHighContrastStyles({
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  }),
  addBorderInHighContrastMode
);

const LoginButton = props => (
  <StyledButton variant="contained" disableElevation {...props} />
);

export default LoginButton;
