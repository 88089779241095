import PropTypes from 'prop-types';
import TopicWrapper from './TopicWrapper';
import { ListItemText } from '@material-ui/core';
import Space from 'lib/components/Space';
import { KeyboardArrowRight as ArrowRightIcon } from '@material-ui/icons';

const SelectableTopic = ({ topic, ...props }) => {
  if (!topic.subtopics) {
    return null;
  }

  return (
    <TopicWrapper button {...props}>
      <ListItemText primary={topic.title} />
      <Space width="auto" />
      <ArrowRightIcon />
    </TopicWrapper>
  );
};

SelectableTopic.propTypes = {
  topic: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtopics: PropTypes.object
  }).isRequired
};

export default SelectableTopic;
