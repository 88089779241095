import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const AttachedSlosContext = createContext([]);

const AutomatedAttachedSlosContextProvider = ({ part, ...props }) => {
  const portfolio = useContext(PortfolioContext);
  const attachedSlos = safeKeys(portfolio?.partSloAttachment?.[part.id]);

  return <AttachedSlosContext.Provider value={attachedSlos} {...props} />;
};

AutomatedAttachedSlosContextProvider.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

AttachedSlosContext.AutomatedProvider = AutomatedAttachedSlosContextProvider;

export default AttachedSlosContext;
