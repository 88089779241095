import Dialog from 'lib/components/Dialog';
import { TextField } from '@material-ui/core';
import device from 'current-device';

const PromptTextField = props => (
  <Dialog.Content style={{ marginTop: -12 }}>
    <TextField
      variant="filled"
      rows={4}
      rowsMax={6}
      autoFocus={device.desktop()}
      fullWidth
      {...props}
    />
  </Dialog.Content>
);

export default PromptTextField;
