import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import useCollapsedState from './useCollapsedState';
import Subtopic from './Subtopic';
import TopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledTopic/TopicTitleWrapper';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import TopicContent from 'components/RubricsPortfolioComponents/StyledTopic/TopicContent';
import sortByUiPos from 'lib/utils/sortByUiPos';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const Topic = ({ topic, isFirstTopic, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const [collapsed, toggleCollapsed] = useCollapsedState(
    topic.id,
    isFirstTopic
  );

  const allSubtopics = portfolioTemplates?.subtopics;
  const desiredSubtopicsKeys = safeKeys(topic.subtopics);
  const subtopics = desiredSubtopicsKeys
    .map(subtopicKey => allSubtopics?.[subtopicKey])
    .filter(subtopic => !!subtopic)
    .filter(userCanAccessItem(portfolioUser))
    .sort(sortByUiPos);

  return (
    <section {...props}>
      <TopicTitleWrapper onClick={toggleCollapsed}>
        <h2>{topic.title}</h2>
        <CollapseButton collapsed={collapsed} />
      </TopicTitleWrapper>
      <TopicContent collapsed={collapsed}>
        {subtopics.map(subtopic => (
          <Subtopic
            subtopic={subtopic}
            belongsToFirstTopic={isFirstTopic}
            key={subtopic.id}
          />
        ))}
      </TopicContent>
    </section>
  );
};

Topic.propTypes = {
  topic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtopics: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  isFirstTopic: PropTypes.bool.isRequired
};

export default Topic;
