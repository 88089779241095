import PropTypes from 'prop-types';
import artisticWorldImage from './worldImages/artistic.png';
import scientificWorldImage from './worldImages/scientific.png';
import socialEthicWorldImage from './worldImages/social_ethic.png';
import societalWorldImage from './worldImages/societal.png';
import spiritualWorldImage from './worldImages/spiritual.png';
import worlds from '../worlds';

const worldImages = {
  artistic: artisticWorldImage,
  scientific: scientificWorldImage,
  social_ethic: socialEthicWorldImage,
  societal: societalWorldImage,
  spiritual: spiritualWorldImage
};

const WorldImage = ({ world, ...props }) => {
  const worldImage = worldImages[world];
  return <img src={worldImage} alt="" {...props} />;
};

WorldImage.propTypes = {
  world: PropTypes.oneOf(worlds).isRequired
};

export default WorldImage;
