import PropTypes from 'prop-types';
import StyledSelect from './StyledSelect';
import { MenuItem } from '@material-ui/core';
import showRowNameOrPlaceholder from './showRowNameOrPlaceholder';
import RowName from './RowName';

const RowSelect = ({ rowKeys, ...props }) => (
  <StyledSelect renderValue={showRowNameOrPlaceholder} displayEmpty {...props}>
    {rowKeys.map(rowKey => (
      <MenuItem value={rowKey} key={rowKey}>
        <RowName rowKey={rowKey} />
      </MenuItem>
    ))}
  </StyledSelect>
);

RowSelect.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default RowSelect;
