import { useContext } from 'react';
import FieldContext from './FieldContext';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import TextField from './TypeSpecificFieldRenderers/TextField';
import DateField from './TypeSpecificFieldRenderers/DateField';
import SelectField from './TypeSpecificFieldRenderers/SelectField';
import LogEntries from './TypeSpecificFieldRenderers/LogEntries';
import LinkButton from './TypeSpecificFieldRenderers/LinkButton';
import Rubrics from './TypeSpecificFieldRenderers/Rubrics';
import LearningGoals from './TypeSpecificFieldRenderers/LearningGoals';
import PlannedStartDate from './TypeSpecificFieldRenderers/ChallengeDates/PlannedStartDate';
import PlannedEndDate from './TypeSpecificFieldRenderers/ChallengeDates/PlannedEndDate';
import StartDate from './TypeSpecificFieldRenderers/ChallengeDates/StartDate';
import EndDate from './TypeSpecificFieldRenderers/ChallengeDates/EndDate';
import ChallengeSharing from './TypeSpecificFieldRenderers/ChallengeSharing';
import TaskAssignment from './TypeSpecificFieldRenderers/TaskAssignment';
import WorldSelect from './TypeSpecificFieldRenderers/WorldSelect';
import ColorPicker from './TypeSpecificFieldRenderers/ColorPicker';
import AttachedSlos from './TypeSpecificFieldRenderers/AttachedSlos';

const FieldRenderersForFieldTypes = {
  [FieldTypes.TEXT]: TextField,
  [FieldTypes.DATE]: DateField,
  [FieldTypes.SELECT]: SelectField,
  [FieldTypes.LOGS]: LogEntries,
  [FieldTypes.LINK_BUTTON]: LinkButton,
  [FieldTypes.RUBRICS]: Rubrics,
  [FieldTypes.LEARNING_GOALS]: LearningGoals,
  [FieldTypes.MANUAL_PLANNED_START_DATE]: PlannedStartDate,
  [FieldTypes.MANUAL_PLANNED_END_DATE]: PlannedEndDate,
  [FieldTypes.MANUAL_ACTUAL_START_DATE]: StartDate,
  [FieldTypes.MANUAL_ACTUAL_END_DATE]: EndDate,
  [FieldTypes.MANUAL_CHALLENGE_SHARING]: ChallengeSharing,
  [FieldTypes.MANUAL_ASSIGNEES]: TaskAssignment,
  [FieldTypes.MANUAL_AGORA_WORLDS]: WorldSelect,
  [FieldTypes.MANUAL_COLOR_PICKER]: ColorPicker,
  [FieldTypes.MANUAL_ATTACHED_SLOS]: AttachedSlos
};

const IndividualFieldRenderer = () => {
  const { field } = useContext(FieldContext);
  const FieldRenderer = FieldRenderersForFieldTypes[field.type];

  if (!FieldRenderer) {
    return null;
  }

  return <FieldRenderer />;
};

export default IndividualFieldRenderer;
