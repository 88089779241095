import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { WHITE } from '../defaultColors';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const ColorBox = styled('div')(
  ({ theme }) => ({
    margin: 3,
    width: 24,
    height: 24,
    borderRadius: 4,
    boxSizing: 'border-box',
    display: 'inline-block',
    cursor: 'pointer',
    transition: theme.transitions.create(),
    ':hover': {
      transform: 'scale(1.1)'
    }
  }),
  ({ color }) => ({
    backgroundColor: color
  }),
  ({ theme, color }) => {
    if (!theme.isDark && color === WHITE) {
      return {
        border: `1px solid ${theme.palette.divider}`
      };
    }
  },
  ({ theme, color, selected }) => {
    if (theme.isDark && selected && color === WHITE) {
      return {
        border: `1px solid ${theme.palette.action.selected}`
      };
    }

    if (selected) {
      return {
        border: `1px solid ${theme.palette.action.active}`
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`
  }))
);

ColorBox.propTypes = {
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

ColorBox.defaultProps = {
  selected: false
};

export default ColorBox;
