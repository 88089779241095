import styled from '@emotion/styled';
import { Button } from '@material-ui/core';

const StyledAddButton = styled(Button)({
  marginBottom: 6,
  height: 32,
  minHeight: 32,
  lineHeight: 1
});

export default StyledAddButton;
