import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ArrowOverlay = styled('div')(
  ({ theme, hidden }) => ({
    position: 'absolute',
    top: 0,
    width: hidden ? 0 : 108,
    height: '100%',
    transition: theme.transitions.create(),
    opacity: hidden ? 0 : 1,
    zIndex: hidden ? -1 : 1,
    display: 'flex',
    alignItems: 'center',
    [mediaQuery(460)]: {
      position: 'relative',
      left: 0,
      right: 0,
      marginTop: 12,
      width: 'auto',
      background: 'none',
      border: 'none',
      display: 'inline-block'
    }
  }),
  ({ theme, isLeftArrowOverlay }) => {
    if (isLeftArrowOverlay) {
      return {
        left: -72,
        background: `linear-gradient(to right, ${theme.palette.background.paper}, transparent)`,
        borderLeft: `12px solid ${theme.palette.background.paper}`
      };
    }
  },
  ({ theme, isRightArrowOverlay }) => {
    if (isRightArrowOverlay) {
      return {
        right: -72,
        background: `linear-gradient(to right, transparent, ${theme.palette.background.paper})`,
        borderRight: `12px solid ${theme.palette.background.paper}`,
        justifyContent: 'flex-end',
        [mediaQuery(460)]: {
          marginLeft: 'calc(100% - 96px)'
        }
      };
    }
  }
);

ArrowOverlay.propTypes = {
  hidden: PropTypes.bool.isRequired,
  isLeftArrowOverlay: PropTypes.bool,
  isRightArrowOverlay: PropTypes.bool
};

ArrowOverlay.defaultProps = {
  isLeftArrowOverlay: false,
  isRightArrowOverlay: false
};

export default ArrowOverlay;
