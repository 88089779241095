import PropTypes from 'prop-types';
import SelectFieldPlaceholder from 'lib/components/SelectFieldPlaceholder';
import { FormattedMessage } from 'react-intl';

const SelectPlaceholder = ({ asManagedGroups }) => (
  <SelectFieldPlaceholder>
    {asManagedGroups ? (
      <FormattedMessage id="user_manager.user_list.user.group_select.labels.managed_groups" />
    ) : (
      <FormattedMessage id="user_manager.user_list.user.group_select.labels.groups" />
    )}
  </SelectFieldPlaceholder>
);

SelectPlaceholder.propTypes = {
  asManagedGroups: PropTypes.bool.isRequired
};

export default SelectPlaceholder;
