import StyledFooter from './StyledFooter';
import FooterLogo from 'components/App/AppFooter/FooterLogo';

const ErrorScreenFooter = props => (
  <StyledFooter {...props}>
    <FooterLogo style={{ marginBottom: 0 }} />
  </StyledFooter>
);

export default ErrorScreenFooter;
