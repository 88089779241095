import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import StyledListItem from './StyledListItem';
import UserAvatar from 'lib/components/UserAvatar';
import { ListItemText } from '@material-ui/core';

const UserListItem = forwardRef(({ username, ...props }, ref) => {
  const [loading, user] = useFirebaseValue(userRef(username));

  if (loading || !user) {
    return null;
  }

  return (
    <StyledListItem button={!loading} {...props} ref={ref}>
      <UserAvatar user={user} marginRight={12} />
      <ListItemText primary={user.name} />
    </StyledListItem>
  );
});

UserListItem.propTypes = {
  username: PropTypes.string.isRequired
};

export default UserListItem;
