import { Button } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const SendButton = props => (
  <Button
    color="primary"
    variant="contained"
    startIcon={<SendIcon />}
    disableElevation
    {...props}
  >
    <FormattedMessage id="support.dialog.feedback_form.send_button" />
  </Button>
);

export default SendButton;
