import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import App from 'lib/App';

const useLearningGoalsModel = () => {
  const { field } = useContext(FieldContext);

  const models = App.useModels();

  if (!models?.learningGoals) {
    return null;
  }

  const learningGoalsModelKey = field.reusedLearningGoals || field.key;
  return models.learningGoals[learningGoalsModelKey];
};

export default useLearningGoalsModel;
