import useCurrentUser from './useCurrentUser';
import useCurrentUserPopulator from './useCurrentUserPopulator';
import useCurrentUserUsername from './useCurrentUserUsername';
import useGetUsernameFromEmail from './useGetUsernameFromEmail';
import useIsUserAllowed from './useIsUserAllowed';
import useLoggedInState from './useLoggedInState';
import useUserUsername from './useUserUsername';

const authentication = {
  useCurrentUser,
  useCurrentUserPopulator,
  useCurrentUserUsername,
  useGetUsernameFromEmail,
  useIsUserAllowed,
  useLoggedInState,
  useUserUsername
};

export default authentication;
