import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledFooter = styled('footer')(
  ({ theme }) => ({
    position: 'relative',
    paddingLeft: 148,
    paddingRight: 148,
    width: '100%',
    height: 80,
    fontFamily: 'CircularStd',
    backgroundColor: theme.palette.background.shaded,
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24
    },
    [mediaQuery(650)]: {
      paddingTop: 24,
      paddingBottom: 24,
      height: 'auto',
      display: 'block'
    }
  }),
  ({ wideContent }) => {
    if (wideContent) {
      return {
        paddingLeft: '24px !important',
        paddingRight: '24px !important'
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    borderTop: `2px solid ${theme.palette.divider}`
  }))
);

StyledFooter.propTypes = {
  wideContent: PropTypes.bool.isRequired
};

export default StyledFooter;
