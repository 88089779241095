import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const MarkersWrapper = styled('footer')(({ theme }) => ({
  marginTop: 18,
  marginBottom: -18,
  marginLeft: -12,
  marginRight: -12,
  display: 'flex',
  borderTop: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-top']),
  [mediaQuery(700)]: {
    display: 'none'
  }
}));

export default MarkersWrapper;
