import styled from '@emotion/styled';

const StyledTitle = styled('h4')(({ theme }) => ({
  marginTop: 12,
  marginBottom: 4,
  fontWeight: 400,
  color: theme.palette.text.secondary
}));

export default StyledTitle;
