import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledTitle = styled('h4')({
  fontWeight: 400,
  [mediaQuery(700)]: {
    display: 'none'
  }
});

export default StyledTitle;
