import { createContext, useState } from 'react';

const DialogManagerContext = createContext({
  isAnyDialogOpen: false,
  markDialogAsOpen: () => {},
  markDialogAsClosed: () => {}
});

const AutomatedDialogManagerContextProvider = props => {
  const [openDialogs, setOpenDialogs] = useState({});

  const isAnyDialogOpen = Object.keys(openDialogs).length > 0;

  const markDialogAsOpen = dialogKey =>
    setOpenDialogs(openDialogs => ({
      ...openDialogs,
      [dialogKey]: true
    }));

  const markDialogAsClosed = dialogKey =>
    setOpenDialogs(openDialogs => {
      const newOpenDialogs = { ...openDialogs };
      delete newOpenDialogs[dialogKey];
      return newOpenDialogs;
    });

  return (
    <DialogManagerContext.Provider
      value={{
        isAnyDialogOpen,
        markDialogAsOpen,
        markDialogAsClosed
      }}
      {...props}
    />
  );
};

DialogManagerContext.AutomatedProvider = AutomatedDialogManagerContextProvider;

export default DialogManagerContext;
