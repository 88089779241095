import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ReflectionPhasesWrapper = styled('section')(
  ({ theme, phaseCount, phaseShifts }) => ({
    marginTop: 24,
    marginRight: -24,
    transform: `translateX(${phaseShifts * -(100 / phaseCount)}%)`,
    width: 'fit-content',
    display: 'flex',
    transition: theme.transitions.create()
  })
);

ReflectionPhasesWrapper.propTypes = {
  phaseCount: PropTypes.number.isRequired,
  phaseShifts: PropTypes.number.isRequired
};

export default ReflectionPhasesWrapper;
