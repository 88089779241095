import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import Space from 'lib/components/Space';
import ClearButton from './ClearButton';
import { Search as SearchIcon } from '@material-ui/icons';

const UserSearchTextField = ({
  value,
  onClearSearch,
  onSearchClick,
  ...props
}) => {
  const intl = useIntl();
  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder={intl.formatMessage({
        id: 'user_manager.user_search.input_label'
      })}
      value={value}
      InputProps={{
        inputProps: {
          style: {
            textOverflow: 'ellipsis'
          }
        },
        endAdornment: (
          <InputAdornment position="end">
            <Space width={-8} />
            <ClearButton
              visible={!!value}
              disabled={!value}
              onClick={onClearSearch}
            />
            <IconButton type="submit" onClick={onSearchClick} disabled={!value}>
              <SearchIcon />
            </IconButton>
            <Space width={-14} />
          </InputAdornment>
        )
      }}
      style={{
        overflow: 'hidden'
      }}
      fullWidth
      {...props}
    />
  );
};

UserSearchTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired
};

export default UserSearchTextField;
