import styled from '@emotion/styled';
import { Paper, Popover } from '@material-ui/core';

const MenuContentWrapper = styled(Paper)({
  paddingTop: 8,
  paddingBottom: 8,
  width: 320
});

const WideMenu = props => (
  <Popover
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    PaperProps={{
      component: MenuContentWrapper
    }}
    {...props}
  />
);

export default WideMenu;
