import PropTypes from 'prop-types';
import { Tooltip, IconButton } from '@material-ui/core';
import { AssignmentInd as ExpertIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ExpertiseButton = ({ userIsExpert, ...props }) => (
  <Tooltip
    title={
      <FormattedMessage id="user_manager.user_list.user.expertise_manager.button_tooltip" />
    }
  >
    <IconButton color={userIsExpert ? 'primary' : 'default'} {...props}>
      <ExpertIcon />
    </IconButton>
  </Tooltip>
);

ExpertiseButton.propTypes = {
  userIsExpert: PropTypes.bool.isRequired
};

export default ExpertiseButton;
