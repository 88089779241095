import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledGroup = styled(ButtonBase)(
  ({ theme }) => ({
    margin: 18,
    color: theme.palette.text.secondary,
    fontFamily: 'CircularStd',
    ':hover, :focus': {
      color: theme.palette.text.primary
    },
    [mediaQuery(640)]: {
      margin: 0,
      paddingTop: 9,
      paddingBottom: 9
    }
  }),
  ({ theme, selected }) => {
    if (selected) {
      return {
        fontWeight: 500,
        color: theme.palette.text.primary
      };
    }
  }
);

StyledGroup.propTypes = {
  selected: PropTypes.bool
};

StyledGroup.defaultProps = {
  selected: false
};

export default StyledGroup;
