import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Button } from '@material-ui/core';
import { ExitToApp as ExitIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const LogoutButton = props => {
  const isLoggedIn = !!firebase.auth().currentUser;
  if (!isLoggedIn) {
    return null;
  }

  const logout = () => {
    firebase.auth().signOut();
    localStorage.clear();
    window.location.reload();
  };

  return (
    <Button
      variant="outlined"
      onClick={logout}
      startIcon={<ExitIcon />}
      {...props}
    >
      <FormattedMessage id="error_boundary.fixes.logout_button" />
    </Button>
  );
};

export default LogoutButton;
