import { useContext } from 'react';
import CollapsedRowsContext from '../../CollapsedRowsContext';

const useCollapsedState = rowKey => {
  const { isRowCollapsed, toggleRowCollapsed } = useContext(
    CollapsedRowsContext
  );

  const collapsed = isRowCollapsed(rowKey);
  const toggleCollapsed = () => toggleRowCollapsed(rowKey);

  return [collapsed, toggleCollapsed];
};

export default useCollapsedState;
