import { useContext } from 'react';
import ChallengeSearchQueryContext from 'components/ChallengeSearchQueryContext';
import matchesSearch from 'lib/utils/matchesSearch';

const useChallengeSearchQueryMatching = challengeName => {
  const searchQuery = useContext(ChallengeSearchQueryContext);

  if (!challengeName) {
    return [false, false];
  }

  const challengeMatchesSearchQuery = matchesSearch(challengeName, searchQuery);

  const challengeFaded = !challengeMatchesSearchQuery;
  const challengeHighlighted = !!searchQuery && challengeMatchesSearchQuery;
  return [challengeFaded, challengeHighlighted];
};

export default useChallengeSearchQueryMatching;
