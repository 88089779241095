import { useState } from 'react';
import PropTypes from 'prop-types';
import ManagerWrapper from './ManagerWrapper';
import ColorForm from './ColorForm';
import ColorPreview from '../ColorPreview';
import Space from 'lib/components/Space';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon, Close as DeleteIcon } from '@material-ui/icons';
import DangerousIconButton from 'lib/components/DangerousIconButton';

const ColorManager = ({ color, onUpdate, onDelete, ...props }) => {
  const [editing, setEditing] = useState(false);

  return (
    <ManagerWrapper {...props}>
      {editing ? (
        <ColorForm
          color={color}
          onCancel={() => setEditing(false)}
          onSave={updatedColor => {
            onUpdate(updatedColor);

            setEditing(false);
          }}
        />
      ) : (
        <>
          <ColorPreview color={color} />
          <span>{color}</span>
          <Space width="auto" />
          <IconButton onClick={() => setEditing(true)}>
            <EditIcon />
          </IconButton>
          <DangerousIconButton onClick={onDelete}>
            <DeleteIcon />
          </DangerousIconButton>
        </>
      )}
    </ManagerWrapper>
  );
};

ColorManager.propTypes = {
  color: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ColorManager;
