import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import PhaseContext from '../../../../PhaseContext';
import DeleteButton from './DeleteButton';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const DeleteChallenge = ({
  challenge,
  parentKey,
  deleteChallenge: deleteChallengeFromDatabase,
  ButtonComponent,
  asTask,
  ...props
}) => {
  const phase = useContext(PhaseContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteChallenge = () => {
    setConfirmationDialogOpen(false);
    deleteChallengeFromDatabase(challenge.key, parentKey, phase);
  };

  const closeDialog = () => {
    setConfirmationDialogOpen(false);
    setBodyOverflow('hidden');
  };

  const challengeIsShared = safeKeys(challenge.sharedWith).length > 0;

  return (
    <>
      <ButtonComponent
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={challengeIsShared}
      />
      <Dialog open={confirmationDialogOpen} onClose={closeDialog} dangerous>
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming
              id={
                asTask
                  ? 'portfolio.challenge.delete.text.as_task'
                  : 'portfolio.challenge.delete.text'
              }
            />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton onClick={closeDialog} />
          <Dialog.Actions.DeleteButton onClick={deleteChallenge} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteChallenge.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    sharedWith: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  parentKey: PropTypes.string,
  deleteChallenge: PropTypes.func.isRequired,
  ButtonComponent: PropTypes.elementType,
  asTask: PropTypes.bool.isRequired
};

DeleteChallenge.defaultProps = {
  ButtonComponent: DeleteButton
};

export default DeleteChallenge;
