import PropTypes from 'prop-types';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';
import { useTheme } from '@emotion/react';

const getArrowIcon = (left, right) => {
  if (left) {
    return ArrowLeftIcon;
  }

  if (right) {
    return ArrowRightIcon;
  }

  return null;
};

const UserSwitchArrowIcon = ({ floating, left, right, ...props }) => {
  const theme = useTheme();
  const normalIconStyles = {
    width: 40,
    height: 40,
    fill: theme.palette.text.primary
  };

  const ArrowIcon = getArrowIcon(left, right);

  return <ArrowIcon style={floating ? null : normalIconStyles} {...props} />;
};

UserSwitchArrowIcon.propTypes = {
  floating: PropTypes.bool.isRequired,
  left: PropTypes.bool,
  right: PropTypes.bool
};

UserSwitchArrowIcon.defaultProps = {
  left: false,
  right: false
};

export default UserSwitchArrowIcon;
