/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const asValidPercentage = number => Math.max(Math.min(number, 100), 0);

const useProgressWithSelectedWeekSyncing = (selectedWeek, weeks) => {
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    const firstWeek = Number(weeks[0]);
    const lastWeek = Number(weeks[weeks.length - 1]);
    const weekCount = lastWeek - firstWeek + 1;

    const progressFromSelectedWeek =
      ((selectedWeek - firstWeek + 1) / weekCount) * 100;

    setProgress(asValidPercentage(progressFromSelectedWeek));
  }, [selectedWeek, setProgress, weeks.toString()]);

  return [progress, setProgress];
};

export default useProgressWithSelectedWeekSyncing;
