import ButtonWrapper from './ButtonWrapper';
import ButtonLabel from './ButtonLabel';
import AddIconWrapper from './AddIconWrapper';
import { Add as AddIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const CreateRowButton = props => (
  <ButtonWrapper id="create-row-button" {...props}>
    <ButtonLabel id="button-label" elevation={3}>
      <FormattedMessage id="portfolio.create_row.button" />
    </ButtonLabel>
    <AddIconWrapper id="icon-wrapper">
      <AddIcon />
    </AddIconWrapper>
  </ButtonWrapper>
);

export default CreateRowButton;
