import SelectPlaceholder from './SelectPlaceholder';
import GroupCountText from './GroupCountText';

const renderValue = asManagedGroups => selectedGroups => {
  if (selectedGroups.length === 0) {
    return <SelectPlaceholder asManagedGroups={asManagedGroups} />;
  }

  return (
    <GroupCountText
      groupCount={selectedGroups.length}
      asManagedGroups={asManagedGroups}
    />
  );
};

export default renderValue;
