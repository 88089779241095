/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import Dialog from 'lib/components/Dialog';
import PromptControlsWrapper from './PromptControlsWrapper';
import StandardGroupCheckboxes from './StandardGroupCheckboxes';
import PromptGroupSelect from './PromptGroupSelect';
import PromptTextField from './PromptTextField';
import useSelectedStandardGroupsState from './useSelectedStandardGroupsState';

const PortfolioItemWithGroupsPrompt = ({
  label,
  placeholder,
  defaultValue,
  selectedStandardGroups: defaultSelectedStandardGroups,
  selectedGroups: defaultSelectedGroups,
  createsItem,
  open,
  onOk,
  onCancel,
  children,
  ...props
}) => {
  const [input, setInput] = useSyncedState(defaultValue);
  const {
    selectedStandardGroups,
    replaceSelectedStandardGroups,
    updateIndividualStandardGroupSelection,
    getSelectedStandardGroupsAsKeysArray
  } = useSelectedStandardGroupsState(defaultSelectedStandardGroups);
  const [selectedGroups, setSelectedGroups] = useState(defaultSelectedGroups);
  const [hasEmptyError, setHasEmptyError] = useState(false);

  useEffect(() => {
    replaceSelectedStandardGroups(defaultSelectedStandardGroups);
  }, [JSON.stringify(defaultSelectedStandardGroups)]);

  useEffect(() => {
    setSelectedGroups(defaultSelectedGroups);
  }, [JSON.stringify(defaultSelectedGroups)]);

  const resetState = () => {
    setInput(defaultValue);
    replaceSelectedStandardGroups(defaultSelectedStandardGroups);
    setSelectedGroups(defaultSelectedGroups);
    setHasEmptyError(false);
  };

  const handleOk = () => {
    if (input.trim().length === 0) {
      setHasEmptyError(true);
      return;
    }

    onOk(input.trim(), getSelectedStandardGroupsAsKeysArray(), selectedGroups);

    if (createsItem) {
      resetState();
    }
  };

  const handleCancel = () => {
    setHasEmptyError(false);
    onCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      onEnterClose={handleOk}
      {...props}
    >
      {children}
      <PromptControlsWrapper>
        <StandardGroupCheckboxes
          selectedStandardGroups={selectedStandardGroups}
          updateIndividualStandardGroupSelection={
            updateIndividualStandardGroupSelection
          }
        />
        <PromptGroupSelect
          value={selectedGroups}
          onChange={e => setSelectedGroups(e.target.value)}
        />
        <PromptTextField
          label={label}
          value={input}
          onChange={e => setInput(e.target.value)}
          hasEmptyError={hasEmptyError}
        />
      </PromptControlsWrapper>
      <Dialog.Actions addTopMargin>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        {createsItem ? (
          <Dialog.Actions.CreateButton onClick={handleOk} />
        ) : (
          <Dialog.Actions.SaveButton onClick={handleOk} />
        )}
      </Dialog.Actions>
    </Dialog>
  );
};

PortfolioItemWithGroupsPrompt.propTypes = {
  label: PropTypes.node.isRequired,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  selectedStandardGroups: PropTypes.arrayOf(PropTypes.string),
  selectedGroups: PropTypes.arrayOf(PropTypes.string),
  createsItem: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.node
};

PortfolioItemWithGroupsPrompt.defaultProps = {
  defaultValue: '',
  selectedStandardGroups: [],
  selectedGroups: [],
  createsItem: false
};

export default PortfolioItemWithGroupsPrompt;
