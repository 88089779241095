import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const SwitcherWrapper = styled('header')(
  ({ theme }) => ({
    paddingLeft: 18,
    paddingRight: 18,
    backgroundColor: theme.palette.background.shaded,
    borderRadius: 4,
    overflow: 'hidden',
    display: 'flex',
    boxSizing: 'border-box',
    [mediaQuery(640)]: {
      paddingTop: 9,
      paddingBottom: 18,
      flexDirection: 'column'
    }
  }),
  addBorderInHighContrastMode
);

export default SwitcherWrapper;
