import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import GroupListWrapper from './GroupListWrapper';
import Group from './Group';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import NoSearchResults from '../../UserManager/NoSearchResults';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';

const GroupList = props => {
  const [loading, groupsObject] = useFirebaseValue(ref('groups'));

  if (loading) {
    return <LoadingSpinner margin={24} centered />;
  }

  const groups = flattenFirebaseList(groupsObject);
  return (
    <GroupListWrapper {...props}>
      {groups.length === 0 ? (
        <NoSearchResults style={{ marginLeft: 12 }}>
          <FormattedMessage id="group_manager.group_list.no_groups" />
        </NoSearchResults>
      ) : (
        groups.map(group => <Group group={group} key={group.key} />)
      )}
    </GroupListWrapper>
  );
};

export default GroupList;
