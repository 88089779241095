import PropTypes from 'prop-types';
import LinkTextField from './LinkTextField';
import selectLinkInputContent from '../../selectLinkInputContent';

const LinkField = ({ challengeKey, asTask, ...props }) => {
  const baseUrl = window.location.origin;
  const path = asTask ? 'openTask' : 'openChallenge';
  const link = `${baseUrl}/${path}/${challengeKey}`;
  return (
    <LinkTextField link={link} onClick={selectLinkInputContent} {...props} />
  );
};

LinkField.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default LinkField;
