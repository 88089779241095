import { useState } from 'react';
import LoadingSpinnerWithText from 'lib/components/LoadingSpinnerWithText';
import PortfolioTemplatesEditor from './PortfolioTemplatesEditor';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import VisiblePortfolioTemplatesGroupContext from './VisiblePortfolioTemplatesGroupContext';
import VisibleGroupSwitcher from './VisibleGroupSwitcher';
import Space from 'lib/components/Space';
import { ref } from 'lib/firebase';
import Groups from 'lib/constants/Groups';
import GroupIndicatingSnackbar from './GroupIndicatingSnackbar';
import { FormattedMessage } from 'react-intl';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';

const DEFAULT_VISIBLE_GROUP = Groups.ALL;

const RubricsPortfolioTemplatesEditor = () => {
  const [visibleGroup, setVisibleGroup] = useState(DEFAULT_VISIBLE_GROUP);
  const [loading, portfolioTemplates] = useFirebaseValue(
    ref('portfolioTemplates')
  );

  const [
    groupIndicatingSnackbarOpen,
    setGroupIndicatingSnackbarOpen
  ] = useState(false);

  if (loading) {
    return (
      <LoadingSpinnerWithText>
        <FormattedMessage id="editor.portfolio_template_editor_with_group_switcher.loading_message" />
      </LoadingSpinnerWithText>
    );
  }

  const handleVisibleGroupChange = newVisibleGroup => {
    setVisibleGroup(newVisibleGroup);
    setGroupIndicatingSnackbarOpen(true);
  };

  return (
    <>
      <VisibleGroupSwitcher
        visibleGroup={visibleGroup}
        onChangeVisibleGroup={handleVisibleGroupChange}
      />
      <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
        <VisiblePortfolioTemplatesGroupContext.Provider value={visibleGroup}>
          <PortfolioTemplatesEditor />
        </VisiblePortfolioTemplatesGroupContext.Provider>
      </PortfolioTemplatesContext.Provider>
      <Space height={15} />
      <GroupIndicatingSnackbar
        group={visibleGroup}
        open={groupIndicatingSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setGroupIndicatingSnackbarOpen(false)}
      />
    </>
  );
};

export default RubricsPortfolioTemplatesEditor;
