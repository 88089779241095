import { rowRef, taskRef, value, challengeRef, ref } from 'lib/firebase';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import App from 'lib/App';
import Phases from 'lib/constants/Phases';

export const removeChallengeFromRow = (key, rowKey, phase) =>
  rowRef(rowKey).child(`challenges/${phase}/${key}`).remove();

const userIsLastChallengeUser = (challenge, username) => {
  const sharedWith = safeKeys(challenge.sharedWith);
  return (
    sharedWith.length === 0 ||
    (!challenge.originalOwner &&
      sharedWith.length === 1 &&
      sharedWith[0] === username)
  );
};

const removeChallengeTasks = async key => {
  const challengeTasks = await value(challengeRef(key).child('tasks'));
  const taskPhasesKeys = safeKeys(challengeTasks);

  if (!taskPhasesKeys) {
    return;
  }

  await Promise.all(
    taskPhasesKeys.map(phase => {
      const taskKeys = safeKeys(taskPhasesKeys[phase]);
      return Promise.all(taskKeys.map(taskKey => taskRef(taskKey).remove()));
    })
  );
};

const removeUserFromChildTasksAssignees = (challenge, username) => {
  if (!challenge.tasks) {
    return;
  }

  const tasksKeys = Object.values(challenge.tasks).reduce(
    (allTasksKeysSoFar, tasksKeys) => [
      ...allTasksKeysSoFar,
      ...Object.keys(tasksKeys)
    ],
    []
  );

  return Promise.all(
    tasksKeys.map(taskKey =>
      taskRef(taskKey).child(`assignees/${username}`).remove()
    )
  );
};

const fieldIsLogEntriesField = field => {
  if (typeof field !== 'object') {
    return false;
  }

  const keys = Object.keys(field);

  if (keys.length === 0) {
    return false;
  }

  const firstFieldEntry = field[keys[0]];

  if (typeof firstFieldEntry !== 'object') {
    return false;
  }

  return (
    typeof firstFieldEntry.content === 'string' &&
    typeof firstFieldEntry.timestamp === 'number'
  );
};

const getLogEntryFields = item =>
  Object.entries(item).filter(([, fieldValue]) =>
    fieldIsLogEntriesField(fieldValue)
  );

// This code makes me want to cry and we should definitely move towards a better
// back-end solution than Firebase

const anonymizeLogEntries = (item, username, itemRef) =>
  Promise.all(
    getLogEntryFields(item).map(([fieldKey, logEntries]) =>
      Promise.all(
        Object.entries(logEntries)
          .filter(([, logEntry]) => logEntry.user === username)
          .map(([logEntryKey]) =>
            ref(itemRef)
              .child(item.key)
              .child(`${fieldKey}/${logEntryKey}/user`)
              .remove()
          )
      )
    )
  );

const anonymizeChildTasksLogEntries = (challenge, username) => {
  if (!challenge.tasks) {
    return;
  }

  const taskKeys = [
    ...safeKeys(challenge.tasks.todo),
    ...safeKeys(challenge.tasks.progress),
    ...safeKeys(challenge.tasks.done)
  ];

  return Promise.all(
    taskKeys.map(async taskKey => {
      const task = await value(taskRef(taskKey));

      await anonymizeLogEntries(task, username, 'tasks');
    })
  );
};

export const removeChallenge = async (key, username) => {
  const challenge = await value(challengeRef(key));
  const userIsLast = userIsLastChallengeUser(challenge, username);

  if (userIsLast) {
    await removeChallengeTasks(key);
    await challengeRef(key).remove();
    return;
  }

  await Promise.all([
    anonymizeLogEntries(challenge, username, 'challenges'),
    anonymizeChildTasksLogEntries(challenge, username),
    removeUserFromChildTasksAssignees(challenge, username),
    challengeRef(key).child(`row/${username}`).remove()
  ]);

  if (challenge.originalOwner === username) {
    challengeRef(key).child('originalOwner').remove();
    return;
  }

  challengeRef(key).child(`sharedWith/${username}`).remove();
};

const useDeleteChallenge = () => {
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const deleteChallenge = (key, rowKey) =>
    Promise.all([
      removeChallengeFromRow(key, rowKey, Phases.TODO),
      removeChallengeFromRow(key, rowKey, Phases.PROGRESS),
      removeChallengeFromRow(key, rowKey, Phases.DONE),
      removeChallenge(key, currentUserUsername)
    ]);

  return deleteChallenge;
};

export default useDeleteChallenge;
