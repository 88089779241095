import styled from '@emotion/styled';
import UserAvatar from 'lib/components/UserAvatar';

const InnerUserAvatar = styled(UserAvatar)({
  marginRight: 6,
  ':last-child': {
    marginRight: 0
  }
});

export default InnerUserAvatar;
