import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import App from 'lib/App';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import NoPageTitle from './NoPageTitle';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ChallengePortfolio from 'components/ChallengePortfolio';
import RubricsPortfolio from 'components/RubricsPortfolio';

const PortfolioPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const currentUser = App.authentication.useCurrentUser();
  return (
    <PortfolioUserContext.Provider value={currentUser}>
      <NoPageTitle />
      {selectedPlatform === PlatformTypes.CHALLENGEMONITOR ? (
        <ChallengePortfolio />
      ) : (
        <RubricsPortfolio />
      )}
    </PortfolioUserContext.Provider>
  );
};

export default PortfolioPage;
