import PropTypes from 'prop-types';
import NotFoundTitle from 'lib/components/NotFoundTitle';
import Space from 'lib/components/Space';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const NotFoundTextWithHomepageButton = ({ asTask, ...props }) => (
  <NotFoundTitle {...props}>
    <FormattedMessageWithChallengeNaming
      id={
        asTask
          ? 'open_challenge.not_found.title.task'
          : 'open_challenge.not_found.title.challenge'
      }
    />
    <br />
    <Space height={12} />
    <Button color="primary" component={Link} to="/">
      <FormattedMessage id="open_challenge.not_found.home_button" />
    </Button>
  </NotFoundTitle>
);

NotFoundTextWithHomepageButton.propTypes = {
  asTask: PropTypes.bool.isRequired
};

export default NotFoundTextWithHomepageButton;
