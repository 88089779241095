import useLoginWithGoogle from './useLoginWithGoogle';
import useLoginWithMicrosoft from './useLoginWithMicrosoft';

const useLoginHandlers = onShowEmailNotAllowedError => {
  const loginWithGoogle = useLoginWithGoogle();
  const loginWithMicrosoft = useLoginWithMicrosoft();

  return {
    loginWithGoogle: loginWithGoogle(onShowEmailNotAllowedError),
    loginWithMicrosoft: loginWithMicrosoft(onShowEmailNotAllowedError)
  };
};

export default useLoginHandlers;
