import styled from '@emotion/styled';
import FullscreenViewContent from './FullscreenViewContent';
import FullscreenViewHeader from './FullscreenViewHeader';

const FullscreenView = styled('section')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto'
}));

FullscreenView.Content = FullscreenViewContent;
FullscreenView.Header = FullscreenViewHeader;

export default FullscreenView;
