import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import { rgba } from 'lib/styling/colors';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import { FormattedMessage } from 'react-intl';

const ButtonWrapper = styled('section')({
  position: 'absolute',
  top: 33,
  right: 84,
  [mediaQuery(840)]: {
    top: 106,
    right: 24
  },
  [mediaQuery(560)]: {
    position: 'initial',
    top: 'unset',
    right: 'unset',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 24,
    textAlign: 'center'
  }
});

const StyledButton = styled(Button)(
  ({ theme }) => ({
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: rgba(theme.palette.primary.main, 0.15),
    ':hover': {
      backgroundColor: rgba(theme.palette.primary.main, 0.25)
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.text.primary}`
  }))
);

const ViewAttachedSlosButton = ({ attachedSlosCount, ...props }) => (
  <ButtonWrapper {...props}>
    <StyledButton variant="text" color="primary" size="small">
      {attachedSlosCount === 1 ? (
        <FormattedMessage id="portfolio.part.attached_slos.button.singular" />
      ) : (
        <FormattedMessage
          id="portfolio.part.attached_slos.button.plural"
          values={{
            attachedSlosCount
          }}
        />
      )}
    </StyledButton>
  </ButtonWrapper>
);

export default ViewAttachedSlosButton;
