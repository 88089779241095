import { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import getCollapsedRowsCache from './getCollapsedRowsCache';
import arrToObj from 'lib/utils/arrToObj';

const CollapsedRowsContext = createContext({
  isRowCollapsed: () => {},
  toggleRowCollapsed: () => {},
  collapseAllRows: () => {},
  uncollapseAllRows: () => {}
});

const AutomatedCollapsedRowsContextProvider = ({ rowKeys, ...props }) => {
  const [collapsedRows, setCollapsedRows] = useState(getCollapsedRowsCache());

  const isRowCollapsed = rowKey => !!collapsedRows[rowKey];

  const updateRowCollapsedInCache = (rowKey, collapsed) =>
    localStorage.setItem(`row-collapsed:${rowKey}`, collapsed);

  const toggleRowCollapsed = rowKey => {
    const alreadyCollapsed = !!collapsedRows[rowKey];
    updateRowCollapsedInCache(rowKey, !alreadyCollapsed);
    setCollapsedRows({
      ...collapsedRows,
      [rowKey]: !alreadyCollapsed
    });
  };

  const collapseAllRows = () => {
    rowKeys.forEach(rowKey => updateRowCollapsedInCache(rowKey, true));
    setCollapsedRows(arrToObj(rowKeys, true));
  };

  const uncollapseAllRows = () => {
    rowKeys.forEach(rowKey => updateRowCollapsedInCache(rowKey, false));
    setCollapsedRows({});
  };

  return (
    <CollapsedRowsContext.Provider
      value={{
        isRowCollapsed,
        toggleRowCollapsed,
        collapseAllRows,
        uncollapseAllRows
      }}
      {...props}
    />
  );
};

AutomatedCollapsedRowsContextProvider.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired
};

CollapsedRowsContext.AutomatedProvider = AutomatedCollapsedRowsContextProvider;

export default CollapsedRowsContext;
