import { useState } from 'react';
import ButtonsWrapper from './ButtonsWrapper';
import ManageButton from './ManageButton';
import LearningGoalsSettings from './LearningGoalsSettings';
import LearningGoalsManager from './LearningGoalsManager';
import setBodyOverflow from 'lib/styling/setBodyOverflow';

const LearningGoalsEditor = props => {
  const [learningGoalsManagerOpen, setLearningGoalsManagerOpen] = useState(
    false
  );
  return (
    <>
      <ButtonsWrapper>
        <ManageButton
          onClick={() => {
            setLearningGoalsManagerOpen(true);
            setBodyOverflow('hidden');
          }}
          {...props}
        />
        <LearningGoalsSettings />
      </ButtonsWrapper>
      {learningGoalsManagerOpen && (
        <LearningGoalsManager
          onClose={() => {
            setLearningGoalsManagerOpen(false);
            setBodyOverflow('auto');
          }}
        />
      )}
    </>
  );
};

export default LearningGoalsEditor;
