import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import VisiblePortfolioTemplatesGroupContext from '../VisiblePortfolioTemplatesGroupContext';
import PortfolioItemWithGroupsPrompt from './PortfolioItemWithGroupsPrompt';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import Groups from 'lib/constants/Groups';
import arrToObj from 'lib/utils/arrToObj';
import Space from 'lib/components/Space';
import AddItemButton from './AddItemButton';
import { FormattedMessage } from 'react-intl';

const AddTopic = ({ highestTopicUiPos, ...props }) => {
  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewTopic = (title, groups, dynamicGroups) => {
    setAddPromptOpen(false);

    const id = ref('portfolioTemplates/topics').push().key;
    ref(`portfolioTemplates/topics/${id}`).set({
      id,
      title,
      uiPos: Math.ceil(highestTopicUiPos) + 1,
      groups: arrToObj(groups, true),
      dynamicGroups: arrToObj(dynamicGroups, true)
    });
  };

  return (
    <>
      <Space height={36} />
      <AddItemButton
        variant="contained"
        color="primary"
        onClick={() => setAddPromptOpen(true)}
        disableElevation
        {...props}
      >
        <FormattedMessage id="portfolio_template_editor.add_topic.button" />
      </AddItemButton>
      <PortfolioItemWithGroupsPrompt
        label={
          <FormattedMessage id="portfolio_template_editor.add_topic.input_label" />
        }
        open={addPromptOpen}
        selectedStandardGroups={
          visibleGroup === Groups.ALL
            ? [Groups.STUDENTS, Groups.COACHES]
            : [visibleGroup]
        }
        onOk={saveNewTopic}
        onCancel={() => setAddPromptOpen(false)}
        createsItem
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.add_topic.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.add_topic.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

AddTopic.propTypes = {
  highestTopicUiPos: PropTypes.number.isRequired
};

export default AddTopic;
