import { useContext } from 'react';
import FieldContext from '../FieldContext';
import useCurrentFieldRef from '../useCurrentFieldRef';
import useSelectableFieldTypes from './useSelectableFieldTypes';
import { Select, MenuItem } from '@material-ui/core';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const FieldTypeSelect = props => {
  const { field } = useContext(FieldContext);

  const fieldRef = useCurrentFieldRef();
  const handleChange = e => fieldRef.child('type').set(e.target.value);

  const selectableFieldTypes = useSelectableFieldTypes();

  return (
    <Select value={field.type} onChange={handleChange} fullWidth {...props}>
      {selectableFieldTypes.map(fieldType => (
        <MenuItem value={fieldType} key={fieldType}>
          <FormattedMessageWithChallengeNaming
            id={`editor.model_editor.field_editor.type_select.types.${fieldType}`}
          />
        </MenuItem>
      ))}
    </Select>
  );
};

export default FieldTypeSelect;
