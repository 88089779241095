import { useContext, useState } from 'react';
import UserContext from '../UserContext';
import ExpertiseButton from './ExpertiseButton';
import ExpertiseDialog from './ExpertiseDialog';

const ExpertiseManager = () => {
  const user = useContext(UserContext);
  const [expertiseDialogOpen, setExpertiseDialogOpen] = useState(false);

  return (
    <>
      <ExpertiseButton
        userIsExpert={!!user.isExpert}
        onClick={() => setExpertiseDialogOpen(true)}
      />
      <ExpertiseDialog
        open={expertiseDialogOpen}
        onClose={() => setExpertiseDialogOpen(false)}
      />
    </>
  );
};

export default ExpertiseManager;
