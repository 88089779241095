import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const UploadDropzone = styled('section')(
  ({ theme }) => ({
    marginBottom: 16,
    width: '50%',
    boxSizing: 'border-box',
    ':focus .upload-preview-box': {
      borderColor: theme.palette.primary.main
    }
  }),
  addHighContrastStyles({
    ':focus .upload-preview-box': {
      textDecoration: 'underline'
    }
  })
);

export default UploadDropzone;
