import { useContext } from 'react';
import PropTypes from 'prop-types';
import PhaseContext from '../../PhaseContext';
import Phases from 'lib/constants/Phases';
import StyledLoadingChallengeCard from './StyledLoadingChallengeCard';
import ChallengeNameLoadingPlaceholder from './ChallengeNameLoadingPlaceholder';

const LoadingChallengeCard = ({ collapsed, withPhaseText, ...props }) => {
  const phase = useContext(PhaseContext);
  return (
    <StyledLoadingChallengeCard
      collapsed={collapsed}
      big={phase !== Phases.DONE || withPhaseText}
      disabled
      {...props}
    >
      {!collapsed && <ChallengeNameLoadingPlaceholder />}
    </StyledLoadingChallengeCard>
  );
};

LoadingChallengeCard.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  withPhaseText: PropTypes.bool.isRequired
};

export default LoadingChallengeCard;
