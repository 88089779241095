import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import Subtopics from './Subtopics';

const Topic = ({ topic, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <TileGrid.Tile
      open={open}
      color={topic.color}
      studentCompletion={topic.progress.student}
      coachCompletion={topic.progress.coach}
      hasCompletionFill
      {...props}
    >
      {!open && (
        <>
          <TileGrid.Tile.ClickListener onClick={() => setOpen(true)} />
          <TileGrid.Tile.Title onClick={() => setOpen(true)}>
            {topic.name}
          </TileGrid.Tile.Title>
        </>
      )}
      {open && (
        <>
          <TileGrid.AppBarMarginFix />
          <Subtopics
            topicName={topic.name}
            subtopics={topic.subtopics}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </TileGrid.Tile>
  );
};

Topic.propTypes = {
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    subtopics: PropTypes.arrayOf(PropTypes.object),
    progress: PropTypes.shape({
      student: PropTypes.number,
      coach: PropTypes.number
    })
  })
};

export default Topic;
