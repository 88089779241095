import { useState } from 'react';

const useFirebaseData = initialState => {
  const [firebaseData, setFirebaseData] = useState(initialState);

  const updateFirebaseData = (ref, value) =>
    setFirebaseData(oldFirebaseData => {
      const refHasScoping = ref.includes('/');

      if (!refHasScoping) {
        return {
          ...oldFirebaseData,
          [ref]: value
        };
      }

      const [refScoping, refKey] = ref.split('/');

      return {
        ...oldFirebaseData,
        [refScoping]: {
          ...oldFirebaseData[refScoping],
          [refKey]: value
        }
      };
    });

  return [firebaseData, updateFirebaseData];
};

export default useFirebaseData;
