import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Drawer } from '@material-ui/core';
import OpenFeedbackButton from './OpenFeedbackButton';
import AddFeedbackButton from './AddFeedbackButton';
import StyledFeedbackList from './StyledFeedbackList';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import AddFeedback from '../AddFeedback';
import CustomAddFeedbackButton from './CustomAddFeedbackButton';
import setBodyOverflow from 'lib/styling/setBodyOverflow';

const MobileCoachFeedback = ({ feedback, ...props }) => {
  const [feedbackDrawerOpen, setFeedbackDrawerOpen] = useState(false);
  const usesMobileCoachFeedback = useMediaQuery('(max-width: 1250px)');

  const actuallyOpen = feedbackDrawerOpen && usesMobileCoachFeedback;

  const hasFeedback = Object.keys(feedback).length > 0;

  return (
    <>
      {hasFeedback ? (
        <OpenFeedbackButton onClick={() => setFeedbackDrawerOpen(true)} />
      ) : (
        <RestrictedToPortfolioMode mode={PortfolioModes.COACH}>
          <AddFeedbackButton onClick={() => setFeedbackDrawerOpen(true)} />
        </RestrictedToPortfolioMode>
      )}
      <Drawer
        anchor="right"
        open={actuallyOpen}
        onClose={() => {
          setFeedbackDrawerOpen(false);
          setBodyOverflow('hidden');
        }}
      >
        <StyledFeedbackList feedback={feedback} mobileVariant />
        <RestrictedToPortfolioMode mode={PortfolioModes.COACH}>
          <AddFeedback ButtonComponent={CustomAddFeedbackButton} />
        </RestrictedToPortfolioMode>
      </Drawer>
    </>
  );
};

MobileCoachFeedback.propTypes = {
  feedback: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.number.isRequired
      })
    ])
  ).isRequired
};

export default MobileCoachFeedback;
