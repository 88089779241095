import { useState } from 'react';
import SettingsButton from './SettingsButton';
import SettingsDialog from './SettingsDialog';

const LearningGoalsSettings = props => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SettingsButton onClick={() => setOpen(true)} {...props} />
      <SettingsDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default LearningGoalsSettings;
