import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';

const Attachment = ({ partKey, appendComma, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  if (!portfolioTemplates || !portfolioTemplates.parts) {
    return null;
  }

  const part = portfolioTemplates.parts[partKey];

  if (!part) {
    return null;
  }

  if (appendComma) {
    return `${part.title}, `;
  }

  return part.title;
};

Attachment.propTypes = {
  partKey: PropTypes.string.isRequired,
  appendComma: PropTypes.bool
};

Attachment.defaultProps = {
  appendComma: false
};

export default Attachment;
