import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SloPeriodStepsWrapper = styled('div')(
  ({ theme, hasMovedToSecondStep }) => ({
    transform: hasMovedToSecondStep ? 'translateX(-50%)' : 'none',
    width: '200%',
    display: 'flex',
    transition: theme.transitions.create()
  })
);

SloPeriodStepsWrapper.propTypes = {
  hasMovedToSecondStep: PropTypes.bool.isRequired
};

export default SloPeriodStepsWrapper;
