import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import { taskRef, challengeRef as getChallengeRef } from 'lib/firebase';
import ChallengeViewCardWithPageOverlay from './ChallengeViewCardWithPageOverlay';
import TaskBoard from './TaskBoard';
import ChallengeContentWrapper from './ChallengeContentWrapper';
import NewlySharedInfo from './NewlySharedInfo';
import ChallengeHeader from './ChallengeHeader';
import useDeleteChallenge from 'lib/firebase/challengePortfolio/useDeleteChallenge';
import deleteTask from 'lib/firebase/challengePortfolio/deleteTask';
import ChallengeInfo from './ChallengeInfo';
import MobilePhaseSelect from './MobilePhaseSelect';
import ChallengeFields from './ChallengeFields';

const ChallengeView = ({ challenge, asTask, isNewlyShared, onClose }) => {
  const [taskBoardOpen, setTaskBoardOpen] = useState(false);

  const closeChallengeOrTaskBoard = () =>
    taskBoardOpen ? setTaskBoardOpen(false) : onClose();

  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const challengeRef = asTask
    ? taskRef(challenge.key)
    : getChallengeRef(challenge.key);
  const parentKey = asTask
    ? challenge.challenge
    : challenge.row?.[portfolioUserUsername];

  const deleteChallenge = useDeleteChallenge();

  return (
    <ChallengeViewCardWithPageOverlay
      taskBoardOpen={taskBoardOpen}
      onClose={closeChallengeOrTaskBoard}
    >
      {taskBoardOpen && !asTask ? (
        <TaskBoard challenge={challenge} />
      ) : (
        <ChallengeContentWrapper>
          {isNewlyShared && <NewlySharedInfo challenge={challenge} />}
          <ChallengeHeader
            challenge={challenge}
            parentKey={parentKey}
            onTasksOpen={() => setTaskBoardOpen(true)}
            challengeRef={challengeRef}
            deleteChallenge={asTask ? deleteTask : deleteChallenge}
            onCloseChallenge={onClose}
            asTask={asTask}
          />
          <ChallengeInfo challenge={challenge} />
          {!isNewlyShared && (
            <MobilePhaseSelect
              challenge={challenge}
              challengeRef={challengeRef}
              parentKey={parentKey}
              asTask={asTask}
            />
          )}
          <ChallengeFields
            asTask={asTask}
            challenge={challenge}
            challengeRef={challengeRef}
          />
        </ChallengeContentWrapper>
      )}
    </ChallengeViewCardWithPageOverlay>
  );
};

ChallengeView.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    creationDate: PropTypes.number.isRequired,
    challenge: PropTypes.string,
    row: PropTypes.objectOf(PropTypes.string)
  }).isRequired,
  asTask: PropTypes.bool.isRequired,
  isNewlyShared: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

ChallengeView.defaultProps = {
  isNewlyShared: false
};

export default ChallengeView;
