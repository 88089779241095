import styled from '@emotion/styled';
import Phase from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/Phase';
import InputWithEscListener from 'lib/components/InputWithEscListener';
import mediaQuery from 'lib/styling/mediaQuery';

const PhaseNameAsInput = Phase.Name.withComponent(InputWithEscListener);

const PhaseNameInput = styled(PhaseNameAsInput)({
  marginBottom: 0,
  flex: 1,
  fontFamily: 'CircularStd',
  fontSize: 24,
  fontWeight: 500,
  [mediaQuery(560)]: {
    fontSize: 22
  }
});

export default PhaseNameInput;
