import { useContext, useState } from 'react';
import SloPeriodContext from '../SloPeriodContext';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const CreateSlo = props => {
  const sloPeriod = useContext(SloPeriodContext);
  const [sloPromptOpen, setSloPromptOpen] = useState(false);

  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const createSlo = name => {
    setSloPromptOpen(false);

    const { key: newSloKey } = ref('slos').push({
      name,
      periodKey: sloPeriod.key,
      user: currentUserUsername
    });

    ref(`sloPeriods/${sloPeriod.key}/slos/${newSloKey}`).set(true);
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        color="secondary"
        onClick={() => setSloPromptOpen(true)}
        {...props}
      >
        <FormattedMessage id="slo_system.slo.create.button" />
      </Button>
      <Prompt
        open={sloPromptOpen}
        onOk={createSlo}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={() => setSloPromptOpen(false)}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="slo_system.slo.create.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="slo_system.slo.create.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={<FormattedMessage id="slo_system.slo.create.input_label" />}
        />
      </Prompt>
    </>
  );
};

export default CreateSlo;
