import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { ArrowBack as BackIcon } from '@material-ui/icons';

const StyledIconButton = styled(IconButton)({
  marginTop: -12,
  marginBottom: -12
});

const BackButton = props => (
  <StyledIconButton edge="start" {...props}>
    <BackIcon />
  </StyledIconButton>
);

export default BackButton;
