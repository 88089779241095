import InnerUserAvatar from './InnerUserAvatar';
import { Skeleton } from '@material-ui/lab';

const SkeletonWithMargin = InnerUserAvatar.withComponent(Skeleton);

const AvatarLoadingPlaceholder = props => (
  <SkeletonWithMargin
    variant="circle"
    width={24}
    height={24}
    animation="wave"
    {...props}
  />
);

export default AvatarLoadingPlaceholder;
