import { forwardRef } from 'react';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { ListItemIcon } from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const RowCollapseButton = forwardRef(({ rowCollapsed, ...props }, ref) => (
  <CollapsibleActionMenu.Button {...props} ref={ref}>
    <ListItemIcon>
      {rowCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
    </ListItemIcon>
    <FormattedMessage
      id={`portfolio.row.mobile_row_actions.collapse_toggle.${
        rowCollapsed ? 'uncollapse' : 'collapse'
      }`}
    />
  </CollapsibleActionMenu.Button>
));

export default RowCollapseButton;
