import { useContext, useState } from 'react';
import PhaseContentGroupContext from '../PhaseContentGroupContext';
import useCurrentPhaseRef from '../useCurrentPhaseRef';
import useHighestFieldUiPos from './useHighestFieldUiPos';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import firebaseKeyFromName from '../../firebaseKeyFromName';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import { Button } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const AddModelField = props => {
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const phaseRef = useCurrentPhaseRef();
  const phaseContentGroup = useContext(PhaseContentGroupContext);
  const highestFieldUiPos = useHighestFieldUiPos();

  const saveNewField = name => {
    setAddPromptOpen(false);

    const defaultFieldType =
      phaseContentGroup === PhaseContentGroups.RIGHT_CONTENT
        ? FieldTypes.DATE
        : FieldTypes.TEXT;

    const fieldKey = firebaseKeyFromName(name);
    phaseRef
      .child(phaseContentGroup)
      .child(fieldKey)
      .set({
        name,
        type: defaultFieldType,
        uiPos: highestFieldUiPos + 1
      });
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setAddPromptOpen(true)}
        startIcon={<AddIcon />}
        fullWidth
        {...props}
      >
        <FormattedMessage id="editor.model_editor.add_field.button" />
      </Button>
      <Space height={18} />
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewField}
        OkButton={Dialog.Actions.CreateButton}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.add_field.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={<FormattedMessage id="editor.model_editor.add_field.label" />}
        />
      </Prompt>
    </>
  );
};

export default AddModelField;
