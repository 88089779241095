import { useContext } from 'react';
import SloPeriodContext from '../SloPeriodContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import Slo from './Slo';

const SlosOverview = props => {
  const sloPeriod = useContext(SloPeriodContext);

  const sloKeys = safeKeys(sloPeriod.slos);
  const hasSlos = sloKeys.length > 0;
  const activeSloKey = sloKeys[sloKeys.length - 1];

  return (
    <section {...props}>
      {hasSlos &&
        sloKeys.map(sloKey => (
          <Slo
            sloKey={sloKey}
            isActiveSlo={sloKey === activeSloKey}
            key={sloKey}
          />
        ))}
    </section>
  );
};

export default SlosOverview;
