import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Edit as EditIcon } from '@material-ui/icons';
import PromptWithColors from '../../PromptWithColors';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const EditSubtopic = ({ subtopic, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const saveEdits = (name, color) => {
    setEditPromptOpen(false);

    ref('models')
      .child(`learningGoals/${learningGoalsModel.key}`)
      .child(`subtopics/${subtopic.key}`)
      .update({
        name,
        color
      });
  };

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setEditPromptOpen(true)}
        {...props}
      >
        <EditIcon />
      </TileGrid.Tile.ActionButton>
      <PromptWithColors
        open={editPromptOpen}
        defaultValue={subtopic.name}
        color={subtopic.color}
        textFieldLabel={
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.subtopic.edit.input_label" />
        }
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
        persistInput
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.subtopic.edit.title" />
        </Dialog.Title>
      </PromptWithColors>
    </>
  );
};

EditSubtopic.propTypes = {
  subtopic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

export default EditSubtopic;
