import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowLeft as ArrowLeftIcon } from '@material-ui/icons';

const StyledIconButton = styled(IconButton)({
  marginTop: -12,
  marginBottom: -12,
  marginLeft: -24
});

const BackButton = props => (
  <StyledIconButton {...props}>
    <ArrowLeftIcon />
  </StyledIconButton>
);

export default BackButton;
