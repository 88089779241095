/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import IsUsingPlanningViewContext from 'lib/contexts/IsUsingPlanningViewContext';
import SearchWrapper from './SearchWrapper';
import SearchInput from './SearchInput';
import ClearButton from './ClearButton';
import SearchButton from './SearchButton';

const ChallengeSearch = ({ onSearch, ...props }) => {
  const inputRef = useRef(null);
  const [collapsed, setCollapsed] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [lastSearchQuery, setLastSearchQuery] = useState('');

  const search = () => {
    const trimmedSearchQuery = searchQuery.toLowerCase().trim();
    if (trimmedSearchQuery !== lastSearchQuery) {
      setLastSearchQuery(trimmedSearchQuery);
      onSearch(trimmedSearchQuery);
    }
  };

  const uncollapseOrSearch = e => {
    e.preventDefault();

    if (collapsed) {
      setCollapsed(false);

      // This timeout prevents a race condition where we attempt to focus the
      // input before it has become un-disabled, which wouldn't work
      setTimeout(() => {
        inputRef.current.focus();
      }, 100);

      return;
    }

    search();
  };

  const clearSearch = () => {
    setSearchQuery('');
    setLastSearchQuery('');
    onSearch('');
    setCollapsed(true);
  };

  const history = useHistory();

  useEffect(() => {
    clearSearch();
  }, [history.location.pathname]);

  const [isUsingPlanningView] = useContext(IsUsingPlanningViewContext);
  if (isUsingPlanningView) {
    return null;
  }

  return (
    <SearchWrapper
      collapsed={collapsed}
      onSubmit={uncollapseOrSearch}
      {...props}
    >
      <SearchInput
        value={searchQuery}
        onChange={newValue => setSearchQuery(newValue)}
        onBlur={() => {
          if (searchQuery.trim() === '') {
            clearSearch();
          }
        }}
        onEmptied={() => {
          clearSearch();
          setCollapsed(false);
        }}
        onEscPress={clearSearch}
        visible={!collapsed}
        disabled={collapsed}
        ref={inputRef}
      />
      <ClearButton
        visible={!collapsed && !!searchQuery}
        disabled={collapsed || !searchQuery}
        onClick={clearSearch}
      />
      <SearchButton disabled={!collapsed && !searchQuery} />
    </SearchWrapper>
  );
};

ChallengeSearch.propTypes = {
  onSearch: PropTypes.func.isRequired
};

export default ChallengeSearch;
