import { useState } from 'react';
import AddButton from './AddButton';
import AddExpertiseForm from './AddExpertiseForm';

const AddExpertise = props => {
  const [formOpen, setFormOpen] = useState(false);

  if (!formOpen) {
    return <AddButton onClick={() => setFormOpen(true)} {...props} />;
  }

  return <AddExpertiseForm />;
};

export default AddExpertise;
