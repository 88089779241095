import Dialog from 'lib/components/Dialog';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreationDialogTitle = props => (
  <Dialog.Title {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.title" />
  </Dialog.Title>
);

export default CreationDialogTitle;
