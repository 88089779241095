import { useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import GroupSelectorWrapper from './GroupSelectorWrapper';
import GroupSelectorButton from './GroupSelectorButton';
import GroupSelectorGroupList from './GroupSelectorGroupList';
import { ClickAwayListener } from '@material-ui/core';

const GroupSelector = ({ selectedGroupKey, onGroupChange, ...props }) => {
  const currentUser = App.authentication.useCurrentUser();
  const [loading, groups] = useFirebaseValue(ref('groups'));
  const [isSelectingGroup, setIsSelectingGroup] = useState(false);

  if (loading || !currentUser.managedGroups || !groups) {
    return null;
  }

  const changeGroup = groupKey => {
    setIsSelectingGroup(false);

    if (groupKey !== selectedGroupKey) {
      onGroupChange(groupKey);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsSelectingGroup(false)}>
      <GroupSelectorWrapper {...props}>
        <GroupSelectorButton
          selectedGroup={groups[selectedGroupKey]}
          isSelectingGroup={isSelectingGroup}
          onClick={() => setIsSelectingGroup(!isSelectingGroup)}
        />
        <GroupSelectorGroupList
          groups={groups}
          selectedGroupKey={selectedGroupKey}
          onGroupChange={changeGroup}
          open={isSelectingGroup}
        />
      </GroupSelectorWrapper>
    </ClickAwayListener>
  );
};

GroupSelector.propTypes = {
  selectedGroupKey: PropTypes.string,
  onGroupChange: PropTypes.func.isRequired
};

export default GroupSelector;
