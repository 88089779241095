import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';

const DeleteButton = props => (
  <DangerousIconButton edge="end" {...props}>
    <DeleteIcon />
  </DangerousIconButton>
);

export default DeleteButton;
