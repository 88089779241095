import styled from '@emotion/styled';
import FeedbackList from '../FeedbackList';

const StyledFeedbackList = styled(FeedbackList)({
  marginLeft: 0,
  padding: 24,
  width: 448,
  maxWidth: 'calc(100vw - 48px)',
  boxSizing: 'border-box'
});

export default StyledFeedbackList;
