import styled from '@emotion/styled';

const StyledNoLevelsMessage = styled('h3')(({ theme }) => ({
  paddingTop: 48,
  paddingBottom: 48,
  width: '100%',
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export default StyledNoLevelsMessage;
