import App from 'lib/App';
import SettingsBarWrapper from './SettingsBarWrapper';
import IsViewingAsExpertToggle from './IsViewingAsExpertToggle';
import ActiveExpertiseKeySelect from './ActiveExpertiseKeySelect';

const ExpertModeSettingsBar = props => {
  const currentUser = App.authentication.useCurrentUser();

  const expertModePossible = App.navigation.useExpertModePossible();
  if (!expertModePossible) {
    return null;
  }

  const expertiseKeys = Object.keys(currentUser.expertiseKeys);
  const userCanChangeActiveExpertiseKey = expertiseKeys.length > 1;

  if (
    !currentUser.retainsCoachFunctionality &&
    !userCanChangeActiveExpertiseKey
  ) {
    return null;
  }

  return (
    <SettingsBarWrapper {...props}>
      {currentUser.retainsCoachFunctionality && <IsViewingAsExpertToggle />}
      {userCanChangeActiveExpertiseKey && <ActiveExpertiseKeySelect />}
    </SettingsBarWrapper>
  );
};

export default ExpertModeSettingsBar;
