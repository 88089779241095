import { useContext } from 'react';
import PropTypes from 'prop-types';
import PhaseContentGroupContext from '../../../../PhaseContentGroupContext';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import { TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

const EditorTextField = ({ placeholderId, ...props }) => {
  const intl = useIntl();

  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const useFullWidth =
    currentPhaseContentGroup === PhaseContentGroups.RIGHT_CONTENT;

  return (
    <TextField
      placeholder={intl.formatMessage({
        id: placeholderId
      })}
      fullWidth={useFullWidth}
      {...props}
    />
  );
};

EditorTextField.propTypes = {
  placeholderId: PropTypes.string.isRequired
};

export default EditorTextField;
