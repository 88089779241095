import useSetting from './useSetting';

const defaultAppContentSettings = {
  useReversedSliders: true,
  enableUserSwitcherArrows: false,
  useProfilePictures: true,
  enableExpertSystem: false,
  enableAggressiveRubricCollapsing: false,
  enableSloSystem: false,
  customChallengeNaming: {
    enabled: false
  }
};

export const extractContentSetting = (appContentSettings, settingKey) => {
  const settingValue = appContentSettings?.[settingKey];

  if (typeof settingValue === 'undefined') {
    const defaultValue = defaultAppContentSettings[settingKey];
    return defaultValue;
  }

  return settingValue;
};

const useAppContentSetting = settingKey => {
  const appContentSettings = useSetting('contentSettings');

  return extractContentSetting(appContentSettings, settingKey);
};

export default useAppContentSetting;
