const booleanStringToBoolean = booleanString => booleanString === 'true';

const getCollapsedRowsCache = () => {
  const collapsedRowsCache = {};

  for (const key in localStorage) {
    const isIndicatingRowCollapsedState = key.startsWith('row-collapsed:');
    if (isIndicatingRowCollapsedState) {
      const rowKey = key.replace('row-collapsed:', '');
      collapsedRowsCache[rowKey] = booleanStringToBoolean(
        localStorage.getItem(key)
      );
    }
  }

  return collapsedRowsCache;
};

export default getCollapsedRowsCache;
