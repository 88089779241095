import getReleasesForCurrentLanguage from './getReleasesForCurrentLanguage';
import SubmenuContentWrapper from '../utils/SubmenuContentWrapper';
import Release from './Release';
import ViewAllReleasesButton from './ViewAllReleasesButton';
import getLanguage from 'lib/utils/getLanguage';
import ReleasesOnlyAvailableInEnglishMessage from './ReleasesOnlyAvailableInEnglishMessage';

const Releases = () => {
  const releases = getReleasesForCurrentLanguage();
  return (
    <SubmenuContentWrapper>
      {releases.slice(0, 3).map((release, i) => (
        <Release release={release} key={i} />
      ))}
      <ViewAllReleasesButton />
      {getLanguage() !== 'en' && <ReleasesOnlyAvailableInEnglishMessage />}
    </SubmenuContentWrapper>
  );
};

export default Releases;
