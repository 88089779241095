import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import firebaseKeyFromName from '../firebaseKeyFromName';
import ModelTypeContext from '../ModelTypeContext';
import { ref } from 'lib/firebase';
import Separator from './Separator';
import { Button } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const AddModelPhase = ({ highestPhaseUiPos, ...props }) => {
  const modelType = useContext(ModelTypeContext);
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewModelPhase = name => {
    setAddPromptOpen(false);

    const phaseKey = firebaseKeyFromName(name);
    ref(`models/${modelType}/phases/${phaseKey}`).set({
      name,
      uiPos: highestPhaseUiPos + 1
    });
  };

  return (
    <>
      <Separator />
      <Button
        onClick={() => setAddPromptOpen(true)}
        startIcon={<AddIcon />}
        fullWidth
      >
        <FormattedMessage id="editor.model_editor.add_phase.button" />
      </Button>
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewModelPhase}
        OkButton={Dialog.Actions.CreateButton}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.add_phase.title" />
        </Dialog.Title>
        <Prompt.TextField
          label={<FormattedMessage id="editor.model_editor.add_phase.label" />}
        />
      </Prompt>
    </>
  );
};

AddModelPhase.propTypes = {
  highestPhaseUiPos: PropTypes.number.isRequired
};

export default AddModelPhase;
