import styled from '@emotion/styled';
import InputWithEscListener from 'lib/components/InputWithEscListener';
import mediaQuery from 'lib/styling/mediaQuery';

const NameInput = styled(InputWithEscListener)(({ theme }) => ({
  marginRight: 12,
  minWidth: 0,
  fontFamily: 'CircularStd',
  fontSize: 24,
  flex: 1,
  [mediaQuery(640)]: {
    fontSize: 20
  }
}));

export default NameInput;
