import { useEffect } from 'react';

const useKeydownListener = (targetElement, keydownHandler) => {
  useEffect(() => {
    if (targetElement) {
      targetElement.addEventListener('keydown', keydownHandler);
      return () => targetElement.removeEventListener('keydown', keydownHandler);
    }
  });
};

export default useKeydownListener;
