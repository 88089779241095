import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import App from 'lib/App';

const StyledAvatar = styled(Avatar)(
  addHighContrastStyles(({ theme }) => ({
    border: `1px solid ${theme.palette.background.default}`
  }))
);

const AutomatedAvatar = ({ user, ...props }) => {
  const useProfilePictures = App.instanceConfig.useContentSetting(
    'useProfilePictures'
  );
  return (
    <StyledAvatar src={useProfilePictures ? user.photoUrl : null} {...props}>
      {user.name.charAt(0)}
    </StyledAvatar>
  );
};

AutomatedAvatar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string
  }).isRequired
};

export default AutomatedAvatar;
