import { useContext } from 'react';
import PhaseContext from '../PhaseContext';
import PhaseContentGroupContext from '../PhaseContentGroupContext';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import sortByUiPos from 'lib/utils/sortByUiPos';
import useAllHighestUiPositions from './useAllHighestUiPositions';
import ModelFieldEditor from '../ModelFieldEditor';

const ModelFieldEditorList = props => {
  const { phase: currentPhase } = useContext(PhaseContext);
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const fields = flattenFirebaseList(currentPhase[currentPhaseContentGroup]);
  const sortedFields = fields.sort(sortByUiPos);

  const allHighestUiPositions = useAllHighestUiPositions();

  return sortedFields.map((field, index) => (
    <ModelFieldEditor
      field={field}
      previousField={sortedFields[index - 1]}
      nextField={sortedFields[index + 1]}
      allHighestUiPositions={allHighestUiPositions}
      key={field.key}
      {...props}
    />
  ));
};

export default ModelFieldEditorList;
