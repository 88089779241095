import DangerousButton from 'lib/components/DangerousButton';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const DialogLeaveButton = props => (
  <DangerousButton inDialog {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.dialog_leave_button" />
  </DangerousButton>
);

export default DialogLeaveButton;
