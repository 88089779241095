import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useIntl } from 'react-intl';
import { Tooltip, Button } from '@material-ui/core';

const StyledButton = styled(Button)({
  padding: 4,
  minWidth: 0
});

const ToolbarButton = ({ label, icon: IconComponent, selected, ...props }) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: `portfolio.challenge.field.text_editor.toolbar_buttons.${label}`
      })}
    >
      <StyledButton size="small" {...props}>
        <IconComponent color={selected ? 'primary' : 'inherit'} />
      </StyledButton>
    </Tooltip>
  );
};

ToolbarButton.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  selected: PropTypes.bool
};

ToolbarButton.defaultProps = {
  selected: false
};

export default ToolbarButton;
