const arrayBufferToBase64 = arrayBuffer => {
  const bytes = new Uint8Array(arrayBuffer);
  const binary = bytes.reduce(
    (binary, byte) => (binary += String.fromCharCode(byte)),
    ''
  );

  return window.btoa(binary);
};

const fetchMicrosoftProfilePicture = async credential => {
  const response = await fetch(
    'https://graph.microsoft.com/beta/me/photos/48x48/$value',
    {
      headers: {
        Authorization: `Bearer ${credential.accessToken}`
      }
    }
  );

  if (response.status !== 200) {
    return null;
  }

  const arrayBuffer = await response.arrayBuffer();

  if (!arrayBuffer) {
    return null;
  }

  const base64Flag = 'data:image/jpeg;base64,';
  const base64String = arrayBufferToBase64(arrayBuffer);

  const fullImageString = `${base64Flag}${base64String}`;
  return fullImageString;
};

export default fetchMicrosoftProfilePicture;
