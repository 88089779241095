import PropTypes from 'prop-types';
import ChallengePhasesWrapper from './ChallengePhasesWrapper';
import TaskPhasesWrapper from './TaskPhasesWrapper';
import KanbanPhase from './KanbanPhase';
import Phases from 'lib/constants/Phases';

const ChallengeBoard = ({ asTaskBoard, ...props }) => {
  const PhasesWrapper = asTaskBoard
    ? TaskPhasesWrapper
    : ChallengePhasesWrapper;

  return (
    <PhasesWrapper>
      <KanbanPhase phase={Phases.TODO} inTaskBoard={asTaskBoard} {...props} />
      <KanbanPhase
        phase={Phases.PROGRESS}
        inTaskBoard={asTaskBoard}
        {...props}
      />
      <KanbanPhase phase={Phases.DONE} inTaskBoard={asTaskBoard} {...props} />
    </PhasesWrapper>
  );
};

ChallengeBoard.propTypes = {
  asTaskBoard: PropTypes.bool,
  collapsed: PropTypes.bool
};

ChallengeBoard.defaultProps = {
  asTaskBoard: false,
  collapsed: false
};

export default ChallengeBoard;
