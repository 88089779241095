const getGrid = (tileCount, width, height) => {
  if (tileCount === 1) {
    return {
      rows: 1,
      columns: 1
    };
  }

  if (width <= 450) {
    return {
      rows: Infinity,
      columns: 1
    };
  }

  if (width <= 650) {
    return {
      rows: Infinity,
      columns: 2
    };
  }

  if (width <= 900) {
    return {
      rows: Infinity,
      columns: 3
    };
  }

  const rowCount = Math.floor(Math.sqrt(tileCount - 1));
  return {
    rows: rowCount,
    columns: Math.ceil(tileCount / rowCount)
  };
};

export default getGrid;
