import { TextField, InputAdornment } from '@material-ui/core';

const ColorTextField = props => (
  <TextField
    placeholder="ABABAB"
    InputProps={{
      startAdornment: <InputAdornment position="start">#</InputAdornment>,
      style: {
        fontFamily: 'Inter'
      }
    }}
    fullWidth
    {...props}
  />
);

export default ColorTextField;
