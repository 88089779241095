import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import { rgba } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';
import bounceAnimation from './bounceAnimation';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

const StyledChallengeCard = styled(ButtonBase, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    position: 'relative',
    padding: 6,
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8,
    width: 130,
    height: 74,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    lineHeight: 1,
    fontSize: 14,
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    overflow: 'hidden',
    display: 'inline-flex',
    flexDirection: 'column',
    boxShadow: `0 2px 1px -1px ${rgba(common.black, 0.2)}, 0 1px 1px 0 ${rgba(
      common.black,
      0.14
    )}, 0 1px 3px 0 ${rgba(common.black, 0.12)}`,
    borderRadius: 4,
    transition: theme.transitions.create()
  }),
  ({ theme, big, collapsed, dragging, highlighted, faded, color }) => {
    const styles = [];

    if (big) {
      styles.push({
        height: 99
      });
    }

    if (collapsed) {
      styles.push({
        width: 6,
        height: 6,
        backgroundColor: theme.palette.action.disabled,
        boxShadow: 'none'
      });
    }

    if (!collapsed) {
      styles.push({
        ':not(:active):hover': {
          boxShadow: `0 3px 3px -2px ${rgba(
            common.black,
            0.2
          )}, 0 3px 4px 0 ${rgba(common.black, 0.14)}, 0 1px 8px 0 ${rgba(
            common.black,
            0.12
          )}`
        },
        ':active': {
          transform: 'scale(0.975)'
        }
      });
    }

    if (dragging || faded) {
      styles.push({
        opacity: 0.5
      });
    }

    if (highlighted) {
      styles.push({
        animation: `${bounceAnimation} 1s cubic-bezier(.23, 1, .32, 1)`,
        zIndex: 1
      });
    }

    if (color && color !== '#FFFFFF') {
      styles.push({
        color: theme.palette.getContrastText(color),
        backgroundColor: color
      });
    }

    return styles;
  },
  addHighContrastStyles(({ theme }) => ({
    border: `2px solid ${theme.palette.divider}`
  })),
  addHighContrastStyles(({ theme, collapsed }) => {
    if (collapsed) {
      return {
        backgroundColor: theme.palette.divider
      };
    }
  })
);

StyledChallengeCard.propTypes = {
  big: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  dragging: PropTypes.bool,
  highlighted: PropTypes.bool,
  faded: PropTypes.bool
};

StyledChallengeCard.defaultProps = {
  dragging: false,
  highlighted: false,
  faded: false
};

export default StyledChallengeCard;
