import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const HeaderWrapper = styled('header')({
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(640)]: {
    alignItems: 'flex-start'
  }
});

export default HeaderWrapper;
