import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledPhase = styled('section')(
  ({ theme }) => ({
    marginRight: 24,
    padding: 12,
    width: 600,
    flexShrink: 0,
    flexGrow: 0,
    backgroundColor: theme.palette.action.hover,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    // Text field header
    // This is the cleanest way to do this until a better integration between
    // challenge fields and the SLO system is achieved.
    header: {
      backgroundColor: 'transparent !important'
    },
    [mediaQuery(460)]: {
      width: 'calc(100vw - 48px)'
    }
  }),
  addHighContrastStyles({
    backgroundColor: 'transparent'
  })
);

export default StyledPhase;
