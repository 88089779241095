import PropTypes from 'prop-types';
import LoadingSpinnerWithText from 'lib/components/LoadingSpinnerWithText';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const ChallengeLoadingIndicator = ({ asTask, ...props }) => (
  <LoadingSpinnerWithText {...props}>
    <FormattedMessageWithChallengeNaming
      id={
        asTask
          ? 'open_challenge.loading_message.task'
          : 'open_challenge.loading_message.challenge'
      }
    />
  </LoadingSpinnerWithText>
);

ChallengeLoadingIndicator.propTypes = {
  asTask: PropTypes.bool.isRequired
};

export default ChallengeLoadingIndicator;
