import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import StyledAvatar from './StyledAvatar';
import getRandomColor from 'lib/styling/getRandomColor';
import App from 'lib/App';

const UserAvatar = ({ user, ...props }) => {
  const useProfilePictures = App.instanceConfig.useContentSetting(
    'useProfilePictures'
  );
  const showProfilePicture = useProfilePictures && !!user.photoUrl;

  const [avatarColor] = useState(getRandomColor());

  const theme = useTheme();
  const avatarColorShade = theme.isDark ? 200 : 600;

  return (
    <StyledAvatar
      src={showProfilePicture ? user.photoUrl : null}
      backgroundColor={
        theme.palette.isHighContrast
          ? theme.palette.divider
          : avatarColor[avatarColorShade]
      }
      {...props}
    >
      {user.name.charAt(0)}
    </StyledAvatar>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    photoUrl: PropTypes.string
  }).isRequired,
  size: PropTypes.number,
  marginRight: PropTypes.number
};

export default memo(
  UserAvatar,
  (prevProps, nextProps) =>
    prevProps.user.name === nextProps.user.name &&
    prevProps.user.photoUrl === nextProps.user.photoUrl &&
    prevProps.size === nextProps.size &&
    prevProps.marginRight === nextProps.marginRight
);
