import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import { deleteSubtopic } from './Subtopics/DeleteSubtopic';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const DeleteTopic = ({ topicKey, topic, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteTopic = () => {
    ref(
      `models/learningGoals/${learningGoalsModel.key}/topics/${topicKey}`
    ).remove();

    if (!topic.subtopics) {
      return;
    }

    safeKeys(topic.subtopics).forEach(subtopicKey => {
      const subtopic = learningGoalsModel.subtopics?.[subtopicKey];
      deleteSubtopic(learningGoalsModel.key, {
        key: subtopicKey,
        ...subtopic
      });
    });
  };

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </TileGrid.Tile.ActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.topic.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.topic.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteTopic} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteTopic.propTypes = {
  topicKey: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    subtopics: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default DeleteTopic;
