import PropTypes from 'prop-types';
import StyledGroupSelect from './StyledGroupSelect';
import { MenuItem } from '@material-ui/core';
import renderValueOrPlaceholder from './renderValueOrPlaceholder';
import { safeEntries } from 'lib/utils/safeObjectFunctions';

const GroupSelectField = ({ groups, asManagedGroups, ...props }) => (
  <StyledGroupSelect
    renderValue={renderValueOrPlaceholder(asManagedGroups)}
    multiple
    displayEmpty
    {...props}
  >
    {safeEntries(groups).map(([key, group]) => (
      <MenuItem value={key} key={key}>
        {group.name}
      </MenuItem>
    ))}
  </StyledGroupSelect>
);

GroupSelectField.propTypes = {
  groups: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired
    })
  ),
  asManagedGroups: PropTypes.bool.isRequired
};

export default GroupSelectField;
