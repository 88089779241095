import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import EditField from './EditField';
import { useIntl, FormattedMessage } from 'react-intl';

const DescriptionField = ({ showEmptyError, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (showEmptyError) {
      inputRef.current.focus();
    }
  }, [showEmptyError]);

  const intl = useIntl();
  return (
    <EditField
      placeholder={intl.formatMessage({
        id: 'portfolio_template_editor.level.edit.description_input_placeholder'
      })}
      error={showEmptyError}
      helperText={
        showEmptyError ? (
          <FormattedMessage id="portfolio_template_editor.level.edit.error" />
        ) : null
      }
      multiline
      rows={4}
      rowsMax={6}
      inputRef={inputRef}
      {...props}
    />
  );
};

DescriptionField.propTypes = {
  showEmptyError: PropTypes.bool.isRequired
};

export default DescriptionField;
