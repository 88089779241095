import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Edit as EditIcon } from '@material-ui/icons';
import PromptWithColors from '../PromptWithColors';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const EditTopic = ({ topicKey, topic, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const saveEdits = (name, color) => {
    setEditPromptOpen(false);

    ref(
      `models/learningGoals/${learningGoalsModel.key}/topics/${topicKey}`
    ).update({
      name,
      color
    });
  };

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setEditPromptOpen(true)}
        {...props}
      >
        <EditIcon />
      </TileGrid.Tile.ActionButton>
      <PromptWithColors
        open={editPromptOpen}
        defaultValue={topic.name}
        color={topic.color}
        textFieldLabel={
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.topic.edit.input_label" />
        }
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
        persistInput
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.topic.edit.title" />
        </Dialog.Title>
      </PromptWithColors>
    </>
  );
};

EditTopic.propTypes = {
  topicKey: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};

export default EditTopic;
