import { useContext } from 'react';
import App from 'lib/App';
import SelectedUserContext from 'lib/contexts/SelectedUserContext';
import Modes from 'lib/constants/Modes';

const usePortfolioUser = () => {
  const selectedMode = App.navigation.useSelectedMode();

  const currentUser = App.authentication.useCurrentUser();
  const selectedUser = useContext(SelectedUserContext);

  if (![Modes.PORTFOLIO, Modes.COACH].includes(selectedMode)) {
    return null;
  }

  if (selectedMode === Modes.PORTFOLIO) {
    return currentUser;
  }

  return selectedUser;
};

export default usePortfolioUser;
