import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

const LoginScreenPageTitle = props => {
  const intl = useIntl();
  return (
    <Helmet {...props}>
      <title>{intl.formatMessage({ id: 'login.page_title' })}</title>
    </Helmet>
  );
};

export default LoginScreenPageTitle;
