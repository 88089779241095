import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const CountLabel = styled('h4')(({ theme }) => ({
  marginRight: 12,
  paddingLeft: 12,
  fontWeight: 400,
  color: theme.palette.text.secondary,
  [mediaQuery(640)]: {
    display: 'none'
  }
}));

export default CountLabel;
