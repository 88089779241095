import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import { lighten } from 'lib/styling/colors';

const darkHighContrastPalette = {
  isHighContrast: true,
  type: 'dark',
  primary: {
    main: common.white
  },
  secondary: {
    main: common.white
  },
  error: {
    main: common.white
  },
  warning: {
    main: common.white
  },
  danger: common.white,
  background: {
    default: common.black,
    shaded: grey[900],
    border: common.white
  },
  sliders: {
    student: lightBlue[100],
    coach: purple[400],
    both: common.white
  },
  partFocusColor: common.white,
  partFocusTextColor: common.black,
  text: {
    primary: common.white,
    secondary: common.white,
    disabled: common.white
  },
  divider: common.white,
  increaseContrastWithBackground: (color, percentage) =>
    lighten(color, percentage)
};

export default darkHighContrastPalette;
