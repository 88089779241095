import { useContext } from 'react';
import PropTypes from 'prop-types';
import useFieldMoving from './useFieldMoving';
import usePossibleMovesState from './usePossibleMovesState';
import MoveModeContext from '../../../../../MoveModeContext';
import MoveButtons from '../../../UiPosMoveButtons';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const FieldUiPosMoving = ({ allHighestUiPositions, ...props }) => {
  const [moveUp, moveDown] = useFieldMoving(allHighestUiPositions);
  const [canMoveUp, canMoveDown] = usePossibleMovesState();

  const inMoveMode = useContext(MoveModeContext);
  if (!inMoveMode) {
    return null;
  }

  return (
    <MoveButtons.Wrapper {...props}>
      {canMoveUp && <MoveButtons.Up onClick={moveUp} />}
      {canMoveDown && <MoveButtons.Down onClick={moveDown} />}
    </MoveButtons.Wrapper>
  );
};

FieldUiPosMoving.propTypes = {
  allHighestUiPositions: PropTypes.shape({
    [PhaseContentGroups.LEFT_CONTENT]: PropTypes.number.isRequired,
    [PhaseContentGroups.RIGHT_CONTENT]: PropTypes.number.isRequired,
    [PhaseContentGroups.END_CONTENT]: PropTypes.number.isRequired
  }).isRequired
};

export default FieldUiPosMoving;
