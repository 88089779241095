import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledFooter = styled('footer')(
  ({ theme }) => ({
    paddingLeft: 24,
    paddingRight: 24,
    height: 80,
    backgroundColor: theme.palette.background.shaded,
    display: 'flex',
    alignItems: 'center'
  }),
  addHighContrastStyles(({ theme }) => ({
    borderTop: `2px solid ${theme.palette.divider}`
  }))
);

export default StyledFooter;
