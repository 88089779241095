import { useState } from 'react';
import PropTypes from 'prop-types';
import { ref } from 'lib/firebase';
import UserSelectFieldWrapper from '../../User/UserSelectFieldWrapper';
import RoleSelectWithPlaceholder from './RoleSelectWithPlaceholder';
import Roles from 'lib/constants/Roles';
import { MenuItem, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const MultipleUsersRoleSelect = ({ usernames, ...props }) => {
  const [selectedRole, setSelectedRole] = useState('');

  const saveRoleChanges = () =>
    Promise.all(
      usernames.map(username => ref(`users/${username}/role`).set(selectedRole))
    );

  return (
    <UserSelectFieldWrapper {...props}>
      <RoleSelectWithPlaceholder
        value={selectedRole}
        onChange={e => setSelectedRole(e.target.value)}
      >
        {Object.values(Roles)
          .filter(role => role !== Roles.ADMIN)
          .map(role => (
            <MenuItem value={role} key={role}>
              <FormattedMessage id={`user_roles.${role}`} />
            </MenuItem>
          ))}
      </RoleSelectWithPlaceholder>
      <IconButton disabled={!selectedRole} onClick={saveRoleChanges}>
        <CheckIcon />
      </IconButton>
    </UserSelectFieldWrapper>
  );
};

MultipleUsersRoleSelect.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultipleUsersRoleSelect;
