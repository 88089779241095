import PropTypes from 'prop-types';
import AvatarsWrapper from './AvatarsWrapper';
import UserAvatar from './UserAvatar';

const AvatarList = ({ usernames, ...props }) => (
  <AvatarsWrapper>
    {usernames.sort().map(username => (
      <UserAvatar username={username} key={username} />
    ))}
  </AvatarsWrapper>
);

AvatarList.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default AvatarList;
