import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const SaveButton = props => (
  <Button variant="contained" color="primary" disableElevation {...props}>
    <FormattedMessage id="platform_settings.save" />
  </Button>
);

export default SaveButton;
