import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import UsersWrapper from './UsersWrapper';
import getChallengeUsers from '../../../../../../getChallengeUsers';
import User from './User';

const ParticipatingUsers = props => {
  const { challenge } = useContext(FieldContext);
  return (
    <UsersWrapper {...props}>
      {getChallengeUsers(challenge).map(username => (
        <User
          username={username}
          isOriginalOwner={
            !!challenge.author && challenge.author === username
          }
          key={username}
        />
      ))}
    </UsersWrapper>
  );
};

export default ParticipatingUsers;
