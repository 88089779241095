import StyledAddFeedbackButton from './StyledAddFeedbackButton';
import { AddCommentOutlined as AddFeedbackIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const CustomAddFeedbackButton = props => (
  <StyledAddFeedbackButton
    color="primary"
    variant="outlined"
    startIcon={<AddFeedbackIcon />}
    {...props}
  >
    <FormattedMessage id="portfolio.challenge.coach_feedback.add.button.mobile" />
  </StyledAddFeedbackButton>
);

export default CustomAddFeedbackButton;
