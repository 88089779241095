import { useContext } from 'react';
import UserContext from '../UserContext';
import StyledUserName from './StyledUserName';
import Roles from 'lib/constants/Roles';
import SuperAdminLabel from './SuperAdminLabel';
import { FormattedMessage } from 'react-intl';

const UserName = props => {
  const user = useContext(UserContext);
  return (
    <StyledUserName {...props}>
      {user.name}
      {user.role === Roles.ADMIN && (
        <SuperAdminLabel>
          <FormattedMessage id="user_roles.admin" />
        </SuperAdminLabel>
      )}
    </StyledUserName>
  );
};

export default UserName;
