import { useContext } from 'react';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import FieldContext from '../../FieldContext';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const MultipleOptionsSelectableCheckbox = props => {
  const { field } = useContext(FieldContext);

  const fieldRef = useCurrentFieldRef();
  const updateValueInFirebase = e => {
    const multipleOptionsSelectable = e.target.checked;
    fieldRef.child('multipleOptionsSelectable').set(multipleOptionsSelectable);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={!!field.multipleOptionsSelectable}
          onChange={updateValueInFirebase}
          color="primary"
        />
      }
      label={
        <FormattedMessage id="editor.model_editor.field_editor.select_editor.multiple_options_selectable_checkbox.label" />
      }
    />
  );
};

export default MultipleOptionsSelectableCheckbox;
