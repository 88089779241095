import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../../../FieldContext';
import App from 'lib/App';
import { userRef, ref } from 'lib/firebase';
import FeedbackItemActionButton from './FeedbackItemActionButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const DeleteFeedbackItem = ({
  feedbackItem,
  setFeedbackItem,
  useInlineRef,
  ...props
}) => {
  const { field, challengeRef } = useContext(FieldContext);
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteFeedbackItem = () => {
    setConfirmationDialogOpen(false);

    challengeRef
      .child(`coachComments/${field.key}/${feedbackItem.key}`)
      .remove();

    if (useInlineRef) {
      return;
    }

    ref(`coachComments/${feedbackItem.key}`).remove();

    userRef(currentUserUsername)
      .child(`coachComments/${feedbackItem.key}`)
      .remove();
  };

  return (
    <>
      <FeedbackItemActionButton
        onClick={() => setConfirmationDialogOpen(true)}
        edge="end"
        dangerous
        {...props}
      >
        <DeleteIcon fontSize="small" />
      </FeedbackItemActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteFeedbackItem} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteFeedbackItem.propTypes = {
  feedbackItem: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired,
  setFeedbackItem: PropTypes.func.isRequired,
  useInlineRef: PropTypes.bool.isRequired
};

export default DeleteFeedbackItem;
