import { challengeRef } from 'lib/firebase';

const updateTaskPhase = (taskKey, oldPhase, newPhase, challenge) => {
  if (!challenge?.key) {
    return;
  }

  challengeRef(challenge.key).child(`tasks/${oldPhase}/${taskKey}`).remove();
  challengeRef(challenge.key).child(`tasks/${newPhase}/${taskKey}`).set(true);
};

export default updateTaskPhase;
