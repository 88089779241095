import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ExitToApp as ExitIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import firebase from 'firebase/compat/app';

const LogoutButton = props => {
  const logout = () => firebase.auth().signOut();
  return (
    <ListItem onClick={logout} button {...props}>
      <ListItemIcon>
        <ExitIcon />
      </ListItemIcon>
      <ListItemText
        primary={<FormattedMessage id="header.options_menu.options.logout" />}
      />
    </ListItem>
  );
};

export default LogoutButton;
