import PropTypes from 'prop-types';
import ContentWrapper from './ContentWrapper';
import SelectWrapper from './SelectWrapper';
import PromptGroupSelect from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/PortfolioItemWithGroupsPrompt/PromptGroupSelect';
import ClearButton from './ClearButton';

const PromptGroupSelectWithClearButton = ({ onClear, ...props }) => {
  const showClearButton = props.value.length > 0;
  return (
    <ContentWrapper>
      <SelectWrapper>
        <PromptGroupSelect {...props} />
      </SelectWrapper>
      {showClearButton && <ClearButton onClick={onClear} />}
    </ContentWrapper>
  );
};

PromptGroupSelectWithClearButton.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClear: PropTypes.func.isRequired
};

export default PromptGroupSelectWithClearButton;
