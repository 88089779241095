import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import App from 'lib/App';
import { FormattedMessage } from 'react-intl';

const CreatedAtWithUserInfo = ({ userKey, createdAt, ...props }) => {
  const [loading, user] = useFirebaseValue(userRef(userKey));
  const currentUser = App.authentication.useCurrentUser();

  if (loading || !user) {
    return (
      <FormattedMessage
        id="portfolio.challenge.info.created_at"
        values={{
          createdAt
        }}
        {...props}
      />
    );
  }

  if (user.email === currentUser.email) {
    return (
      <FormattedMessage
        id="portfolio.challenge.info.created_at_by_you"
        values={{
          createdAt
        }}
        {...props}
      />
    );
  }

  return (
    <FormattedMessage
      id="portfolio.challenge.info.created_at_by"
      values={{
        author: user.name,
        createdAt
      }}
      {...props}
    />
  );
};

CreatedAtWithUserInfo.propTypes = {
  userKey: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired
};

export default CreatedAtWithUserInfo;
