import styled from '@emotion/styled';
import WrapperWithWeekRangeShiftingAnimations from '../WrapperWithWeekRangeShiftingAnimations';

const AnimatedWeekRangeWrapper = styled(WrapperWithWeekRangeShiftingAnimations)(
  {
    flex: 1,
    display: 'flex'
  }
);

export default AnimatedWeekRangeWrapper;
