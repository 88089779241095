import Groups from 'lib/constants/Groups';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const itemMatchesVisibleGroup = (visibleGroup, isTopic = false) => ({
  groups
}) => {
  const itemGroups = safeKeys(groups);
  switch (visibleGroup) {
    case Groups.STUDENTS:
    case Groups.COACHES: {
      return itemGroups.includes(visibleGroup);
    }
    case Groups.DRAFTS: {
      if (isTopic) {
        return (
          itemGroups.length === 0 ||
          (itemGroups.length === 1 && itemGroups[0] === Groups.EDITORS)
        );
      }

      return true;
    }
    case Groups.ALL: {
      return true;
    }
    default: {
      return false;
    }
  }
};

export default itemMatchesVisibleGroup;
