import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledDeleteButton = styled(DangerousIconButton)({
  [mediaQuery(640)]: {
    display: 'none'
  }
});

const DeleteButton = ({ disabled, ...props }) => (
  <StyledDeleteButton disabled={disabled} {...props}>
    <DeleteIcon />
  </StyledDeleteButton>
);

DeleteButton.propTypes = {
  disabled: PropTypes.bool
};

DeleteButton.defaultProps = {
  disabled: false
};

export default DeleteButton;
