/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import App from 'lib/App';
import { userRef } from 'lib/firebase';

const useContinuousUnavailableNewlySharedChallengesCleanUp = unavailableNewlySharedChallengesKeys => {
  const currentUser = App.authentication.useCurrentUser();
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  useEffect(() => {
    if (
      !currentUser ||
      !currentUser.newlyShared ||
      unavailableNewlySharedChallengesKeys.length === 0
    ) {
      return;
    }

    const unavailableNewlySharedChallengesDeletionChangeset = {};

    unavailableNewlySharedChallengesKeys.forEach(challengeKey => {
      unavailableNewlySharedChallengesDeletionChangeset[challengeKey] = null;
    });

    userRef(currentUserUsername)
      .child('newlyShared')
      .update(unavailableNewlySharedChallengesDeletionChangeset);
  }, [JSON.stringify(unavailableNewlySharedChallengesKeys)]);
};

export default useContinuousUnavailableNewlySharedChallengesCleanUp;
