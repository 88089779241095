import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';

const RestrictedToPortfolioMode = ({
  mode: allowedPortfolioMode,
  children
}) => {
  const currentPortfolioMode = useContext(PortfolioModeContext);

  if (currentPortfolioMode !== allowedPortfolioMode) {
    return null;
  }

  return children;
};

RestrictedToPortfolioMode.propTypes = {
  mode: PropTypes.oneOf(Object.values(PortfolioModes)).isRequired,
  children: PropTypes.node
};

export default RestrictedToPortfolioMode;
