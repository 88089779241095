import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import getProgressFromPortfolio from './getProgressFromPortfolio';
import PreconfiguredLevelDialog from './PreconfiguredLevelDialog';
import LevelDialog from 'components/RubricsPortfolioComponents/LevelDialog';
import NoLevelsMessage from 'components/RubricsPortfolioComponents/NoLevelsMessage';
import Level from '../Level';
import Slider from './Slider';
import PartSlider from './PartSlider';
import FocusButton from './FocusButton';
import StyledPart from 'components/RubricsPortfolioComponents/StyledPart';
import FocusIndicator from './FocusIndicator';
import PartTitle from 'components/RubricsPortfolioComponents/StyledPart/PartTitle';
import sortByUiPos from 'lib/utils/sortByUiPos';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const Part = ({ part, subtopicIcon, partSubpath, noFocusButton, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolio = useContext(PortfolioContext);

  const [levelDialogOpen, setLevelDialogOpen] = useState(false);

  const allLevels = portfolioTemplates?.levels;
  const desiredLevelsKeys = safeKeys(part.levels);
  const levels = desiredLevelsKeys
    .map(levelKey => allLevels?.[levelKey])
    .filter(level => !!level)
    .sort(sortByUiPos);

  const progress = getProgressFromPortfolio(portfolio, part.id, partSubpath);
  const hasFocus = portfolio?.focus?.[part.id] || false;

  return (
    <StyledPart {...props}>
      <FocusIndicator hasFocus={hasFocus}>
        {!noFocusButton && <FocusButton hasFocus={hasFocus} partId={part.id} />}
        <PartTitle hasFocus={hasFocus} onClick={() => setLevelDialogOpen(true)}>
          {part.title}
        </PartTitle>
      </FocusIndicator>
      <PartSlider
        progress={progress}
        onClick={() => setLevelDialogOpen(true)}
      />
      <PreconfiguredLevelDialog
        part={part}
        subtopicIcon={subtopicIcon}
        levelCount={levels.length}
        open={levelDialogOpen}
        onClose={() => setLevelDialogOpen(false)}
      >
        <LevelDialog.LevelWrapper>
          {levels.length === 0 ? (
            <NoLevelsMessage />
          ) : (
            levels.map(level => (
              <Level level={level} proofSubpath={partSubpath} key={level.id} />
            ))
          )}
        </LevelDialog.LevelWrapper>
        <Slider
          partId={part.id}
          partSubpath={partSubpath}
          levelCount={levels.length}
        />
      </PreconfiguredLevelDialog>
    </StyledPart>
  );
};

Part.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    levels: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  subtopicIcon: PropTypes.string,
  partSubpath: PropTypes.string,
  noFocusButton: PropTypes.bool,
  mobileOutlined: PropTypes.bool
};

Part.defaultProps = {
  partSubpath: '',
  noFocusButton: false,
  mobileOutlined: false
};

export default Part;
