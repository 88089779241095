import PropTypes from 'prop-types';
import StyledDeleteButton from './StyledDeleteButton';
import { ListItemIcon } from '@material-ui/core';
import StyledDeleteIcon from './StyledDeleteIcon';

const ActionMenuDeleteButton = ({ children, ...props }) => (
  <StyledDeleteButton {...props}>
    <ListItemIcon>
      <StyledDeleteIcon />
    </ListItemIcon>
    {children}
  </StyledDeleteButton>
);

ActionMenuDeleteButton.propTypes = {
  children: PropTypes.node
};

export default ActionMenuDeleteButton;
