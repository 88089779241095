import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ActionButton from '../ActionButton';
import { rgba } from 'lib/styling/colors';

const StyledDeleteButton = styled(ActionButton)(({ theme, disabled }) => {
  if (!disabled) {
    return {
      color: theme.palette.danger,
      ':hover, :focus': {
        backgroundColor: rgba(theme.palette.danger, 0.08)
      }
    };
  }
});

StyledDeleteButton.propTypes = {
  disabled: PropTypes.bool
};

StyledDeleteButton.defaultProps = {
  disabled: false
};

export default StyledDeleteButton;
