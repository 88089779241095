import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import Dialog from 'lib/components/Dialog';
import Prompt from 'lib/components/Prompt';
import AddFeedbackButton from './AddFeedbackButton';
import { FormattedMessage } from 'react-intl';
import App from 'lib/App';
import { ref, userRef } from 'lib/firebase';

const AddFeedback = ({ ButtonComponent, ...props }) => {
  const { field, challenge, challengeRef } = useContext(FieldContext);
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewFeedback = feedbackItem => {
    const feedbackKey = ref('coachComments')
      .push({
        content: feedbackItem,
        timestamp: Date.now(),
        item: challenge.key,
        user: currentUserUsername
      })
      .getKey();

    userRef(currentUserUsername)
      .child(`coachComments/${feedbackKey}`)
      .set(true);

    challengeRef.child(`coachComments/${field.key}/${feedbackKey}`).set(true);

    challengeRef
      .child('unreadCoachCommentsCount')
      .set((challenge.unreadCoachCommentsCount || 0) + 1);

    setAddPromptOpen(false);
  };

  return (
    <>
      <ButtonComponent onClick={() => setAddPromptOpen(true)} {...props} />
      <Prompt
        open={addPromptOpen}
        onCancel={() => setAddPromptOpen(false)}
        onOk={saveNewFeedback}
        emptyError="portfolio.challenge.coach_feedback.feedback_empty_error"
        multiline
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.add.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.add.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={
            <FormattedMessage id="portfolio.challenge.coach_feedback.add.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

AddFeedback.propTypes = {
  belowFeedbackList: PropTypes.bool,
  ButtonComponent: PropTypes.elementType
};

AddFeedback.defaultProps = {
  belowFeedbackList: false,
  ButtonComponent: AddFeedbackButton
};

export default AddFeedback;
