import useMediaQuery from '@material-ui/core/useMediaQuery';

const useNumberOfVisibleLevels = () => {
  const numberOfVisibleLevelsEqualsOne = useMediaQuery('(max-width: 500px)');
  const numberOfVisibleLevelsEqualsTwo = useMediaQuery('(max-width: 768px)');

  if (numberOfVisibleLevelsEqualsOne) {
    return 1;
  }

  if (numberOfVisibleLevelsEqualsTwo) {
    return 2;
  }

  return 3;
};

export default useNumberOfVisibleLevels;
