import PropTypes from 'prop-types';
import stripProps from 'lib/utils/stripProps';
import { IconButton } from '@material-ui/core';
import styled from '@emotion/styled';
import { Close as CancelIcon } from '@material-ui/icons';

const StyledIconButton = styled(stripProps(IconButton, 'visible'))(
  ({ theme, visible }) => ({
    transform: visible ? 'scale(1)' : 'scale(0)',
    transition: theme.transitions.create()
  })
);

const CancelButton = ({ visible, ...props }) => (
  <StyledIconButton
    type="button"
    disabled={!visible}
    visible={visible}
    {...props}
  >
    <CancelIcon />
  </StyledIconButton>
);

CancelButton.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default CancelButton;
