import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledTitle = styled('h2')(
  ({ color, fontSize }) => ({
    position: 'relative',
    color,
    fontSize,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center'
  }),
  addHighContrastStyles(({ theme }) => ({
    padding: 6,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  }))
);

StyledTitle.propTypes = {
  color: PropTypes.string.isRequired,
  fontSize: PropTypes.number
};

export default StyledTitle;
