import PropTypes from 'prop-types';
import InnerTabsWrapper from './InnerTabsWrapper';
import { Tabs, Tab } from '@material-ui/core';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';
import { FormattedMessage } from 'react-intl';

const SelectedModelTypeTabs = ({
  selectedModelType,
  setSelectedModelType,
  ...props
}) => (
  <InnerTabsWrapper variant="outlined" {...props}>
    <Tabs
      value={selectedModelType}
      indicatorColor="primary"
      textColor="primary"
      onChange={(e, newSelectedModelType) =>
        setSelectedModelType(newSelectedModelType)
      }
    >
      <Tab
        label={
          <FormattedMessageWithChallengeNaming id="editor.model_editor.model_type_tabs.challenge" />
        }
        value={ModelTypes.CHALLENGE}
      />
      <Tab
        label={
          <FormattedMessage id="editor.model_editor.model_type_tabs.task" />
        }
        value={ModelTypes.TASK}
      />
    </Tabs>
  </InnerTabsWrapper>
);

SelectedModelTypeTabs.propTypes = {
  selectedModelType: PropTypes.oneOf([ModelTypes.CHALLENGE, ModelTypes.TASK])
    .isRequired,
  setSelectedModelType: PropTypes.func.isRequired
};

export default SelectedModelTypeTabs;
