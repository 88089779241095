import styled from '@emotion/styled';
import UserAvatar from 'lib/components/UserAvatar';
import mediaQuery from 'lib/styling/mediaQuery';

const LogEntryUserAvatar = styled(UserAvatar)({
  '&&&': {
    marginRight: 12,
    width: 30,
    height: 30,
    fontSize: 16,
    [mediaQuery(1024)]: {
      width: 36,
      height: 36,
      fontSize: 19
    }
  }
});

export default LogEntryUserAvatar;
