import { useContext } from 'react';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import StyledButton from './StyledButton';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const CancelWeekZoomButton = props => {
  const { cancelWeekZoom } = useContext(VisibleWeekRangeContext);
  return (
    <StyledButton
      color="primary"
      onClick={cancelWeekZoom}
      startIcon={<BackIcon />}
      {...props}
    >
      <FormattedMessage id="portfolio.challenge_planning_view.header.week_marker.cancel_zoom_button" />
    </StyledButton>
  );
};

export default CancelWeekZoomButton;
