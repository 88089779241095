import PropTypes from 'prop-types';
import ColorManager from './ColorManager';
import AddColorForm from './AddColorForm';

const ColorsManager = ({ colors, setColors }) => (
  <>
    {colors.map((color, index) => (
      <ColorManager
        color={color}
        onUpdate={updatedColor => {
          setColors(colors => {
            const updatedColors = [...colors];

            updatedColors[index] = updatedColor;

            return updatedColors;
          });
        }}
        onDelete={() => {
          setColors(colors => {
            const updatedColors = [...colors];

            updatedColors.splice(index, 1);

            return updatedColors;
          });
        }}
        key={color}
      />
    ))}
    <AddColorForm onAdd={color => setColors(colors => [...colors, color])} />
  </>
);

ColorsManager.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setColors: PropTypes.func.isRequired
};

export default ColorsManager;
