import { Button } from '@material-ui/core';
import { KeyboardArrowLeft as BackIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const pushToLeftStyles = {
  marginRight: 'auto'
};

const BackButton = props => (
  <Button
    variant="outlined"
    startIcon={<BackIcon />}
    style={pushToLeftStyles}
    {...props}
  >
    <FormattedMessage id="support.dialog.actions.back" />
  </Button>
);

export default BackButton;
