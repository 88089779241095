import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const DayMarker = styled('div')(
  ({ theme, isToday }) => ({
    paddingTop: 2,
    paddingBottom: 2,
    flex: 1,
    color: isToday ? theme.palette.primary.main : null,
    fontWeight: isToday ? 500 : 400,
    borderRight: `2px solid ${theme.palette.background.border}`,
    transition: theme.transitions.create(),
    boxSizing: 'border-box',
    ':last-child': {
      borderColor: 'transparent'
    }
  }),
  addHighContrastStyles(({ isToday }) => {
    if (isToday) {
      return {
        textDecoration: 'underline'
      };
    }
  })
);

DayMarker.propTypes = {
  isToday: PropTypes.bool.isRequired
};

export default DayMarker;
