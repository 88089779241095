import asDesirableNumber from './asDesirableNumber';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const attachSubtopicsToParentTopics = (topics, subtopics) =>
  topics.map(topic => {
    const accumulatedProgress = {
      student: 0,
      coach: 0,
      weight: 0
    };

    const correspondingSubtopicsKeys = safeKeys(topic.subtopics);
    const correspondingSubtopics = correspondingSubtopicsKeys
      .map(subtopicKey => subtopics[subtopicKey])
      .filter(subtopic => !!subtopic);

    correspondingSubtopics.forEach(({ progress, parts }) => {
      const progressWeight = parts.length;
      accumulatedProgress.student += progressWeight * (progress.student || 0);
      accumulatedProgress.coach += progressWeight * (progress.coach || 0);
      accumulatedProgress.weight += progressWeight;
    });

    const partsWithChallengeAttachments = correspondingSubtopics.reduce(
      (partsWithChallengeAttachmentsSoFar, subtopic) => ({
        ...partsWithChallengeAttachmentsSoFar,
        ...subtopic.partsWithChallengeAttachments
      }),
      {}
    );

    const topicProgress = {
      student: asDesirableNumber(
        accumulatedProgress.student / accumulatedProgress.weight
      ),
      coach: asDesirableNumber(
        accumulatedProgress.coach / accumulatedProgress.weight
      )
    };

    return {
      ...topic,
      subtopics: correspondingSubtopics,
      progress: topicProgress,
      partsWithChallengeAttachments
    };
  });

export default attachSubtopicsToParentTopics;
