import { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { ListItemIcon } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import InfoEditor from './InfoEditor';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';

const fieldTypesWithBuiltInInfo = [FieldTypes.MANUAL_AGORA_WORLDS];

const EditFieldInfo = props => {
  const { field } = useContext(FieldContext);
  const [editPromptOpen, setEditPromptOpen] = useState(false);
  return (
    <>
      <CollapsibleActionMenu.Button
        onClick={() => setEditPromptOpen(true)}
        disabled={!field.name || fieldTypesWithBuiltInInfo.includes(field.type)}
      >
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <FormattedMessage id="editor.model_editor.actions.explanation" />
      </CollapsibleActionMenu.Button>
      <InfoEditor
        open={editPromptOpen && !!field.name}
        onClose={() => setEditPromptOpen(false)}
      />
    </>
  );
};

export default EditFieldInfo;
