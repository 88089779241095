import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import StyledLevel from 'components/RubricsPortfolioComponents/StyledLevel';
import LevelTitle from 'components/RubricsPortfolioComponents/StyledLevel/LevelTitle';
import LevelDescription from 'components/RubricsPortfolioComponents/StyledLevel/LevelDescription';
import LevelEditor from './LevelEditor';
import DeleteLevel from './DeleteLevel';
import useMakeDraggable from '../useMakeDraggable';
import PortfolioDragTypes from '../PortfolioDragTypes';

const EditorLevel = ({
  level,
  previousLevel,
  nextLevel,
  highestUiPos,
  ...props
}) => {
  const [isDragging, dragRef] = useMakeDraggable(
    PortfolioDragTypes.LEVEL,
    level,
    previousLevel,
    nextLevel,
    highestUiPos,
    'levels',
    null,
    null,
    null,
    true
  );
  const [levelEditorOpen, setLevelEditorOpen] = useState(false);

  if (levelEditorOpen) {
    return (
      <LevelEditor level={level} onClose={() => setLevelEditorOpen(false)} />
    );
  }

  return (
    <StyledLevel editor dragging={isDragging} {...props} ref={dragRef}>
      {level.title && <LevelTitle>{level.title}</LevelTitle>}
      <LevelDescription>{level.description}</LevelDescription>
      <div>
        <IconButton onClick={() => setLevelEditorOpen(true)}>
          <EditIcon />
        </IconButton>
        <DeleteLevel level={level} />
      </div>
    </StyledLevel>
  );
};

EditorLevel.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    uiPos: PropTypes.number.isRequired
  }).isRequired,
  previousLevel: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  nextLevel: PropTypes.shape({
    uiPos: PropTypes.number
  }).isRequired,
  highestUiPos: PropTypes.number.isRequired
};

export default EditorLevel;
