import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const NameWrapper = styled('section')(
  ({
    theme,
    contentVisibilityCompensationDistance,
    contentMaxVisibleWidth
  }) => ({
    position: 'relative',
    left: `${contentVisibilityCompensationDistance}%`,
    paddingLeft: 6,
    width: `${contentMaxVisibleWidth}%`,
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    transition: theme.transitions.simple
  })
);

NameWrapper.propTypes = {
  contentVisibilityCompensationDistance: PropTypes.number.isRequired,
  contentMaxVisibleWidth: PropTypes.number.isRequired
};

export default NameWrapper;
