import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';

const useSlo = sloKey => {
  const [sloLoading, slo] = useFirebaseValue(ref(`slos/${sloKey}`));

  if (!sloLoading && slo) {
    slo.key = sloKey;
  }

  return [sloLoading, slo];
};

export default useSlo;
