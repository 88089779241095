import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledGroupSelectorGroupList = styled('ul')(({ theme, open }) => ({
  margin: '0 auto',
  padding: 0,
  maxHeight: 420,
  listStyle: 'none',
  borderTop: `2px solid ${theme.palette.divider}`,
  display: open ? 'block' : 'none',
  overflowY: 'auto'
}));

StyledGroupSelectorGroupList.propTypes = {
  open: PropTypes.bool.isRequired
};

export default StyledGroupSelectorGroupList;
