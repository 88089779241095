import { useContext } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import PhaseContext from '../../PhaseContext';
import useCurrentPhaseRef from '../../useCurrentPhaseRef';
import FormWrapper from './FormWrapper';
import PhaseNameInput from './PhaseNameInput';
import CancelButton from 'lib/components/CancelButton';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const PhaseNameEditor = ({ onClose, ...props }) => {
  const { phase } = useContext(PhaseContext);
  const [newPhaseName, setNewPhaseName] = useSyncedState(phase.name);

  const currentPhaseRef = useCurrentPhaseRef();
  const saveNameChange = e => {
    e.preventDefault();

    if (newPhaseName.trim()) {
      currentPhaseRef.child('name').set(newPhaseName.trim());
      onClose();
    }
  };

  const cancelNameChange = () => {
    onClose();
    setNewPhaseName(phase.name);
  };

  const intl = useIntl();
  return (
    <FormWrapper className="phase-name-form" onSubmit={saveNameChange}>
      <PhaseNameInput
        placeholder={intl.formatMessage({
          id: 'editor.model_editor.phase_editor.edit_name.input_placeholder'
        })}
        value={newPhaseName}
        onChange={e => setNewPhaseName(e.target.value)}
        onEscPress={cancelNameChange}
        autoFocus
      />
      <CancelButton
        visible={newPhaseName !== phase.name}
        onClick={cancelNameChange}
      />
      <IconButton edge="end" type="submit" disabled={!newPhaseName.trim()}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

PhaseNameEditor.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default PhaseNameEditor;
