import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useChallengeSearchQueryMatching from './useChallengeSearchQueryMatching';
import StyledChallengeCard from '../../StyledChallengeCard';

const ChallengeCardWithSearchMatching = forwardRef(
  ({ challengeName, ...props }, ref) => {
    const [faded, highlighted] = useChallengeSearchQueryMatching(challengeName);
    return (
      <StyledChallengeCard
        highlighted={highlighted}
        faded={faded}
        ref={ref}
        {...props}
      />
    );
  }
);

ChallengeCardWithSearchMatching.propTypes = {
  challengeName: PropTypes.string.isRequired
};

export default ChallengeCardWithSearchMatching;
