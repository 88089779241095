import { useState, useContext } from 'react';
import SloPeriodContext from '../SloPeriodContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import { value, ref } from 'lib/firebase';
import useDeleteSlo from '../SlosOverview/Slo/SloView/DeleteSlo/useDeleteSlo';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const DeleteSloPeriod = props => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const sloPeriod = useContext(SloPeriodContext);

  const deleteSlo = useDeleteSlo();

  const deleteSloPeriod = async () => {
    for (const sloKey of safeKeys(sloPeriod.slos)) {
      const slo = await value(ref(`slos/${sloKey}`));
      deleteSlo(slo, true);
    }

    ref(`sloPeriods/${sloPeriod.key}`).remove();
    ref(`users/${sloPeriod.user}/sloPeriods/${sloPeriod.key}`).remove();
  };

  return (
    <>
      <DangerousIconButton
        onClick={e => {
          e.stopPropagation();
          setConfirmationDialogOpen(true);
        }}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        onClick={e => e.stopPropagation()}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="slo_system.slo_period.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="slo_system.slo_period.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteSloPeriod} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteSloPeriod;
