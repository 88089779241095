import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledButton = styled(Button)({
  marginLeft: 8,
  ':first-of-type': {
    marginLeft: 0
  },
  [mediaQuery(640)]: {
    marginBottom: 12,
    marginLeft: 0,
    lineHeight: '36px',
    ':last-of-type': {
      marginBottom: 0
    }
  }
});

export default StyledButton;
