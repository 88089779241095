import styled from '@emotion/styled';
import PillButtons from '../../PillButtons';

const AttachButton = styled(PillButtons.Button)({
  marginBottom: 12,
  paddingLeft: 16,
  paddingRight: 16,
  borderRadius: 24
});

export default AttachButton;
