import { useContext } from 'react';
import LatestVisibleWeekRangeShiftDirectionContext, {
  ShiftDirections
} from './LatestVisibleWeekRangeShiftDirectionContext';
import { motion } from 'framer-motion';

const WrapperWithWeekRangeShiftingAnimations = props => {
  const latestVisibleWeekRangeShiftDirection = useContext(
    LatestVisibleWeekRangeShiftDirectionContext
  );

  const startingPointsForShiftDirections = {
    [ShiftDirections.LEFT]: '-100%',
    [ShiftDirections.RIGHT]: '100%',
    [null]: 0
  };

  return (
    <motion.section
      initial={{
        x:
          startingPointsForShiftDirections[latestVisibleWeekRangeShiftDirection]
      }}
      animate={{
        x: 0
      }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut'
      }}
      {...props}
    />
  );
};

export default WrapperWithWeekRangeShiftingAnimations;
