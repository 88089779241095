/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import useForceUpdate from 'use-force-update';
import useKeydownListener from 'lib/utils/useKeydownListener';
import composeRefs from '@seznam/compose-react-refs';
import InputBase from 'lib/components/InputBase';

const InputWithEscListener = forwardRef(
  ({ onEscPress, InputComponent, ...props }, externalRef) => {
    const inputRef = useRef(null);
    const forceUpdate = useForceUpdate();

    useKeydownListener(inputRef.current, event => {
      if (event.key === 'Escape') {
        onEscPress();
      }
    });

    return (
      <InputComponent
        {...props}
        ref={composeRefs(inputRef, externalRef)}
        onFocus={e => {
          // We need to force-update here to force the keydown listener to
          // "refresh". If we don't do this, it won't work until the user has
          // changed the input value
          forceUpdate();

          if (typeof props.onFocus === 'function') {
            props.onFocus(e);
          }
        }}
      />
    );
  }
);

InputWithEscListener.propTypes = {
  onEscPress: PropTypes.func.isRequired,
  InputComponent: PropTypes.elementType
};

InputWithEscListener.defaultProps = {
  InputComponent: InputBase
};

export default InputWithEscListener;
