import { value, rowRef, userRef } from 'lib/firebase';
import { removeChallenge } from './useDeleteChallenge';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const removeRowChallenges = async (key, username) => {
  const rowChallenges = await value(rowRef(key).child('challenges'), true);
  const challengePhasesKeys = safeKeys(rowChallenges);

  if (!challengePhasesKeys) {
    return;
  }

  const allChallengeKeys = challengePhasesKeys.reduce(
    (challengeKeysSoFar, currentPhaseKey) => {
      const challengeKeysForCurrentPhase = safeKeys(
        rowChallenges[currentPhaseKey]
      );
      return [...challengeKeysSoFar, ...challengeKeysForCurrentPhase];
    },
    []
  );

  return Promise.all(
    allChallengeKeys.map(challengeKey =>
      removeChallenge(challengeKey, username)
    )
  );
};

const removeRowFromUser = (key, username) =>
  userRef(username).child(`rows/${key}`).remove();

const deleteRow = async (key, username, skipRemoveRowFromUser = false) => {
  await removeRowChallenges(key, username);

  if (!skipRemoveRowFromUser) {
    removeRowFromUser(key, username);
  }

  rowRef(key).remove();
};

export default deleteRow;
