import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';

const StyledTextField = styled(TextField)(({ error }) => {
  if (error) {
    return {
      marginTop: 6
    };
  }
});

StyledTextField.propTypes = {
  error: PropTypes.bool
};

StyledTextField.defaultProps = {
  error: false
};

export default StyledTextField;
