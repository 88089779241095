import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';

const HideOnLowWidth = ({ width, children, ...props }) => {
  const shouldHide = useMediaQuery(`(max-width: ${width}px)`);
  return shouldHide ? null : children;
};

HideOnLowWidth.propTypes = {
  width: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default HideOnLowWidth;
