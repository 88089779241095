import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { rowRef } from 'lib/firebase';
import StyledListItem from './StyledListItem';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import { ListItemText } from '@material-ui/core';

const PickableRow = ({ rowKey, ...props }) => {
  const [loading, row] = useFirebaseValue(rowRef(rowKey));
  return (
    <StyledListItem button {...props}>
      {loading ? (
        <LoadingSpinner margin={4} size={20} centered />
      ) : (
        <ListItemText primary={row.name} />
      )}
    </StyledListItem>
  );
};

PickableRow.propTypes = {
  rowKey: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func
};

PickableRow.defaultProps = {
  selected: false
};

export default PickableRow;
