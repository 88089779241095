import { useState } from 'react';
import PropTypes from 'prop-types';
import AddLevelWrapper from './AddLevelWrapper';
import LevelForm from './LevelForm';
import AddLevelButton from './AddLevelButton';

const AddLevel = ({ partId, highestLevelUiPos, noParentAdd, ...props }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <AddLevelWrapper
      collapsed={collapsed}
      onClick={() => collapsed && setCollapsed(false)}
      {...props}
    >
      {collapsed ? (
        <AddLevelButton onClick={() => setCollapsed(false)} />
      ) : (
        <LevelForm
          partId={partId}
          highestLevelUiPos={highestLevelUiPos}
          noParentAdd={noParentAdd}
          onClose={() => setCollapsed(true)}
        />
      )}
    </AddLevelWrapper>
  );
};

AddLevel.propTypes = {
  partId: PropTypes.string.isRequired,
  highestLevelUiPos: PropTypes.number.isRequired,
  noParentAdd: PropTypes.bool
};

AddLevel.defaultProps = {
  noParentAdd: false
};

export default AddLevel;
