import PropTypes from 'prop-types';
import useUserRowKeys from 'components/ChallengePortfolio/useUserRowKeys';
import { List } from '@material-ui/core';
import PickableRow from './PickableRow';

const PickableRows = ({ selectedRow, onSelectRow, ...props }) => {
  const rowKeysWithUiPos = useUserRowKeys();
  const rowKeys = rowKeysWithUiPos.map(([rowKey]) => rowKey);

  return (
    <List style={{ overflowY: 'auto' }} {...props}>
      {rowKeys.map(rowKey => (
        <PickableRow
          rowKey={rowKey}
          selected={rowKey === selectedRow}
          onClick={() => onSelectRow(rowKey)}
          key={rowKey}
        />
      ))}
    </List>
  );
};

PickableRows.propTypes = {
  selectedRow: PropTypes.string,
  onSelectRow: PropTypes.func.isRequired
};

export default PickableRows;
