import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import LevelDialog from 'components/RubricsPortfolioComponents/LevelDialog';
import NoLevelsMessage from 'components/RubricsPortfolioComponents/NoLevelsMessage';
import Level from 'components/RubricsPortfolio/Portfolio/Level';
import PreconfiguredLevelDialog from 'components/RubricsPortfolio/Portfolio/Part/PreconfiguredLevelDialog';
import Slider from 'components/RubricsPortfolio/Portfolio/Part/Slider';
import ChallengeInfoContext from '../../../../ChallengeInfoContext';
import sortByUiPos from 'lib/utils/sortByUiPos';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const AutomatedLevelDialog = ({ part, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const { challenge, asTask } = useContext(ChallengeInfoContext);

  if (challenge) {
    challenge.asTask = asTask;
  }

  const allLevels = portfolioTemplates?.levels;
  const levelKeys = safeKeys(part.levels);
  const levels = levelKeys
    .map(levelKey => allLevels?.[levelKey])
    .filter(level => !!level)
    .sort(sortByUiPos);

  return (
    <PreconfiguredLevelDialog part={part} levelCount={levels.length} {...props}>
      <LevelDialog.LevelWrapper>
        {levels.length === 0 ? (
          <NoLevelsMessage />
        ) : (
          levels.map(level => (
            <Level level={level} challenge={challenge} key={level.id} />
          ))
        )}
      </LevelDialog.LevelWrapper>
      <Slider partId={part.id} levelCount={levels.length} />
    </PreconfiguredLevelDialog>
  );
};

AutomatedLevelDialog.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    levels: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default AutomatedLevelDialog;
