import { useContext, useState, useEffect } from 'react';
import AppStateContext from 'lib/contexts/AppStateContext';
import useFirebaseLoggedInState from './useFirebaseLoggedInState';
import usePreventFirstTimeSignInInfiniteLoadingScreen from './usePreventFirstTimeSignInInfiniteLoadingScreen';
import useGetUsernameFromEmail from '../useGetUsernameFromEmail';
import firebase from 'firebase/compat/app';
import { ref } from 'lib/firebase';
import Roles from 'lib/constants/Roles';

const useCurrentUserPopulator = () => {
  const appState = useContext(AppStateContext);

  const loggedIn = useFirebaseLoggedInState();
  const [currentUserLoading, setCurrentUserLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);

  const updateForcingValue = usePreventFirstTimeSignInInfiniteLoadingScreen(
    loggedIn,
    currentUserLoading
  );

  const getUsernameFromEmail = useGetUsernameFromEmail();

  useEffect(() => {
    if (appState.isLoading) {
      return;
    }

    if (!loggedIn) {
      setCurrentUserLoading(false);
      setCurrentUser(null);
      return;
    }

    setCurrentUserLoading(true);

    const currentUserInFirebaseAuth = firebase.auth().currentUser;

    if (!currentUserInFirebaseAuth) {
      return;
    }

    const currentUserUsername = getUsernameFromEmail(
      currentUserInFirebaseAuth.email
    );
    const userRef = ref(`users/${currentUserUsername}`);

    userRef.on('value', snapshot => {
      const user = snapshot.val();

      if (user && !user.role) {
        user.role = Roles.STUDENT;
      }

      setCurrentUser(user);
      setCurrentUserLoading(false);
    });

    return () => userRef.off();
  }, [loggedIn, appState.isLoading, updateForcingValue, getUsernameFromEmail]);

  return [currentUserLoading, currentUser];
};

export default useCurrentUserPopulator;
