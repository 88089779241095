import { Tooltip } from '@material-ui/core';
import StyledIconButton from './StyledIconButton';
import { Settings as SettingsIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const SettingsButton = props => (
  <Tooltip
    title={
      <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.settings.title" />
    }
  >
    <StyledIconButton {...props}>
      <SettingsIcon />
    </StyledIconButton>
  </Tooltip>
);

export default SettingsButton;
