import { safeKeys } from 'lib/utils/safeObjectFunctions';

const itemIsVisibleToPortfolioUser = (item, user) => {
  if (!item.groups) {
    return true;
  }

  if (!user || (!user.groups && !user.managedGroups)) {
    return false;
  }

  const itemGroups = safeKeys(item.groups);
  const allUserGroups = [
    ...safeKeys(user.groups),
    ...safeKeys(user.managedGroups)
  ];

  return allUserGroups.reduce(
    (hasMatch, group) => hasMatch || itemGroups.includes(group),
    false
  );
};

export default itemIsVisibleToPortfolioUser;
