import getLanguage from 'lib/utils/getLanguage';

const releases = {
  en: [
    {
      title: 'November release',
      summary: 'Tons of improvements',
      link:
        'https://medium.com/egodact/november-release-tons-of-improvements-16db9f94eb9'
    },
    {
      title: 'May update',
      summary: 'Redesign and mobile support',
      link:
        'https://medium.com/egodact/may-update-redesign-responsivity-9fefe84a89ad'
    },
    {
      title: 'March update',
      summary: 'Coach feedback and general improvements',
      link:
        'https://medium.com/egodact/march-update-egodact-software-12c664e3cfcd'
    },
    {
      title: 'February update',
      summary: 'Platform switch button and dynamic challenge & task model',
      link:
        'https://medium.com/egodact/february-update-egodact-software-7b2d3c4e6230'
    },
    {
      title: 'January update',
      summary: 'Task assignment, planned end preview and release notes',
      link:
        'https://medium.com/egodact/january-update-egodact-software-396822872722'
    }
  ],
  nl: [
    {
      title: 'November update',
      summary: 'Vele kleinere verbeteringen',
      link:
        'https://medium.com/egodact/november-release-tons-of-improvements-16db9f94eb9'
    },
    {
      title: 'Mei update',
      summary: 'Redesign en ondersteuning voor mobiele devices',
      link:
        'https://medium.com/egodact/may-update-redesign-responsivity-9fefe84a89ad'
    },
    {
      title: 'Maart update',
      summary: 'Coach feedback en algemene verbeteringen',
      link:
        'https://medium.com/egodact/march-update-egodact-software-12c664e3cfcd'
    },
    {
      title: 'Februari update',
      summary: 'Platform switch knop en dynamisch challenge & taak model',
      link:
        'https://medium.com/egodact/february-update-egodact-software-7b2d3c4e6230'
    },
    {
      title: 'Januari update',
      summary: 'Taken toewijzen, planned end preview en update notes',
      link:
        'https://medium.com/egodact/january-update-egodact-software-396822872722'
    }
  ]
};

const getReleasesForCurrentLanguage = () => {
  const releasesForCurrentLanguage =
    getLanguage() === 'nl' ? releases.nl : releases.en;

  return releasesForCurrentLanguage;
};

export default getReleasesForCurrentLanguage;
