import DialogButton from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogOkButton = props => (
  <DialogButton color="primary" {...props}>
    <FormattedMessage id="dialogs.actions.ok" />
  </DialogButton>
);

export default DialogOkButton;
