import { StyledActionButton as AlwaysVisibleRowActionButton } from '../RowActionButton/StyledActionButton';
import { Check as CheckIcon } from '@material-ui/icons';

const SaveButtonWithEnterTrigger = props => (
  <AlwaysVisibleRowActionButton type="submit" edge="end" {...props}>
    <CheckIcon />
  </AlwaysVisibleRowActionButton>
);

export default SaveButtonWithEnterTrigger;
