import useSetting from './useSetting';
import { extractContentSetting } from './useContentSetting';

const useReadContentSetting = () => {
  const appContentSettings = useSetting('contentSettings');

  return settingKey => extractContentSetting(appContentSettings, settingKey);
};

export default useReadContentSetting;
