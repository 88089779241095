const getPageTitleId = (challenge, asTask) => {
  if (!asTask && challenge) {
    return 'open_challenge.page_title.challenge';
  }

  if (!asTask && !challenge) {
    return 'open_challenge.not_found.page_title.challenge';
  }

  if (asTask && challenge) {
    return 'open_challenge.page_title.task';
  }

  if (asTask && !challenge) {
    return 'open_challenge.not_found.page_title.task';
  }
};

export default getPageTitleId;
