import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import blue from '@material-ui/core/colors/blue';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledDateText = styled('div')(
  ({ theme }) => ({
    marginBottom: 6,
    transition: theme.transitions.create()
  }),
  ({ theme, color, challengeIsWhite }) => {
    if (color === null) {
      return {
        color: 'inherit',
        opacity: 0.6
      };
    }

    const asBoxStyles = {
      padding: 4,
      fontSize: 14,
      borderRadius: 4
    };

    const shadedColor = color[theme.isDark ? 200 : 500];

    if (color === orange || color === red) {
      return {
        ...asBoxStyles,
        color: theme.palette.getContrastText(shadedColor),
        backgroundColor: shadedColor
      };
    }

    if (color === blue && !challengeIsWhite) {
      return {
        ...asBoxStyles,
        color: shadedColor,
        backgroundColor: theme.palette.getContrastText(shadedColor)
      };
    }

    return {
      color: shadedColor
    };
  },
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main
  }))
);

StyledDateText.propTypes = {
  color: PropTypes.object,
  challengeIsWhite: PropTypes.bool.isRequired
};

export default StyledDateText;
