import StyledMessage from './StyledMessage';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import CreateFirstChallenge from './CreateFirstChallenge';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const NoChallengesMessage = props => (
  <StyledMessage {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_portfolio.no_challenges" />
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <CreateFirstChallenge />
    </RestrictedToPortfolioMode>
  </StyledMessage>
);

export default NoChallengesMessage;
