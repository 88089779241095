import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';

const PartTitleWrapper = styled('h4')({
  display: 'inline-block'
});

const StyledPartTitle = styled(ButtonBase)(
  ({ theme }) => ({
    fontWeight: 400,
    transition: theme.transitions.create(),
    ':hover': {
      opacity: 0.5
    }
  }),
  ({ theme, hasFocus }) => {
    if (hasFocus) {
      return {
        color: theme.palette.partFocusTextColor,
        ':hover': {
          opacity: 0.7
        }
      };
    }
  },
  ({ theme, editorOnly }) => {
    if (editorOnly) {
      return {
        color: theme.palette.text.disabled
      };
    }
  }
);

const PartTitle = props => (
  <PartTitleWrapper>
    <StyledPartTitle {...props} />
  </PartTitleWrapper>
);

PartTitle.propTypes = {
  hasFocus: PropTypes.bool,
  editorOnly: PropTypes.bool
};

PartTitle.defaultProps = {
  hasFocus: false,
  editorOnly: false
};

export default PartTitle;
