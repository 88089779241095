import styled from '@emotion/styled';
import Phase from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/Phase';

const PhaseWithFirstPhaseNameFormMarginCorrection = styled(Phase)({
  ':first-child .phase-name-form': {
    marginTop: -24
  }
});

export default PhaseWithFirstPhaseNameFormMarginCorrection;
