import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const getStackPositionTopOffset = stackIndex =>
  `calc(${stackIndex * 100}% + ${stackIndex * 6}px)`;

const StyledLabel = styled('h4')(
  ({ theme, stackIndex }) => ({
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: stackIndex
      ? `translate(-50%, ${getStackPositionTopOffset(stackIndex)})`
      : 'translateX(-50%)',
    paddingLeft: 4,
    paddingRight: 4,
    minWidth: 36,
    color: common.white,
    backgroundColor: grey[600],
    fontWeight: 400,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    boxSizing: 'border-box',
    // User selection of the label text works weirdly with the sliding handling
    userSelect: 'none',
    boxShadow: theme.shadows[2],
    // ProgressIndicator manages the hover effect on this label, but we should
    // add the transition here
    transition: theme.transitions.create(),
    // This allows for a smoother border hover effect
    border: `2px solid ${grey[600]}`,
    ':hover .name-icon-wrapper': {
      marginLeft: 6,
      width: 18,
      opacity: 1,
      overflow: 'visible'
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.background.default,
    backgroundColor: theme.palette.divider,
    borderColor: theme.palette.divider
  }))
);

StyledLabel.propTypes = {
  stackIndex: PropTypes.number
};

export default StyledLabel;
