import PropTypes from 'prop-types';
import StyledWorld from './StyledWorld';
import StyledWorldImage from './StyledWorldImage';
import worlds from '../../worlds';
import { FormattedMessage } from 'react-intl';

const WorldLabel = ({ world, ...props }) => (
  <StyledWorld {...props}>
    <StyledWorldImage world={world} />
    <FormattedMessage
      id={`portfolio.challenge.world_select.world_labels.${world}_world`}
    />
  </StyledWorld>
);

WorldLabel.propTypes = {
  world: PropTypes.oneOf(worlds).isRequired
};

export default WorldLabel;
