import { useState, useContext } from 'react';
import useDeleteSlo from './useDeleteSlo';
import SloContext from '../../SloContext';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const DeleteSlo = props => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteSlo = useDeleteSlo();
  const slo = useContext(SloContext);

  return (
    <>
      <DangerousIconButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="slo_system.slo.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="slo_system.slo.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={() => deleteSlo(slo)} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeleteSlo;
