import DialogContentWrapper from '../../DialogContentWrapper';
import Dialog from 'lib/components/Dialog';
import EmailButton from './EmailButton';
import { FormattedMessage } from 'react-intl';

const ContactInfo = () => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.contact_info.title" />
    </Dialog.Title>
    <Dialog.Content style={{ overflowX: 'hidden' }}>
      <EmailButton href="mailto:contact@egodact.com">
        contact@egodact.com
      </EmailButton>
    </Dialog.Content>
  </DialogContentWrapper>
);

export default ContactInfo;
