import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme, ClassNames } from '@emotion/react';
import { Slider } from '@material-ui/core';

const StyledSlider = ({ onChange, onChangeCommitted, ...props }) => {
  const theme = useTheme();
  const [hasTransition, setHasTransition] = useState(true);

  return (
    <ClassNames>
      {({ css }) => (
        <Slider
          classes={{
            rail: css({
              height: 18,
              backgroundColor: theme.palette.action.selected,
              opacity: 1,
              borderRadius: 9
            }),
            track: css({
              height: 18,
              backgroundColor: theme.palette.primary.light,
              borderTopLeftRadius: 9,
              borderBottomLeftRadius: 9,
              transition: hasTransition ? theme.transitions.create() : null
            }),
            thumb: css({
              width: 28,
              height: 28,
              backgroundColor: theme.palette.primary.dark,
              border: theme.palette.isHighContrast
                ? `2px solid ${theme.palette.background.default}`
                : null,
              transition: hasTransition ? theme.transitions.create() : null
            })
          }}
          onChange={(_, newValue) => {
            setHasTransition(false);
            onChange(newValue);
          }}
          onChangeCommitted={(_, value) => {
            onChangeCommitted(value);
            setHasTransition(true);
          }}
          {...props}
        />
      )}
    </ClassNames>
  );
};

StyledSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeCommitted: PropTypes.func.isRequired
};

export default StyledSlider;
