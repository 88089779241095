import { useContext } from 'react';
import UserContext from '../../UserContext';
import App from 'lib/App';
import { FormControlLabel, Switch } from '@material-ui/core';
import { userRef } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const UserIsExpertSwitch = props => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const toggleUserExpertStatus = e => {
    const userBecomesExpert = e.target.checked;
    userRef(userUsername).child('isExpert').set(userBecomesExpert);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={!!user.isExpert}
          onChange={toggleUserExpertStatus}
        />
      }
      label={
        <FormattedMessage id="user_manager.user_list.user.expertise_manager.user_is_expert_switch.label" />
      }
    />
  );
};

export default UserIsExpertSwitch;
