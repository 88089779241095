import { useContext } from 'react';
import CollapsedPortfolioItemsContext from './CollapsedPortfolioItemsContext';

const useCollapsedState = (itemId, uncollapsedByDefault) => {
  const { isPortfolioItemCollapsed, togglePortfolioItemCollapsed } = useContext(
    CollapsedPortfolioItemsContext
  );

  const collapsed = isPortfolioItemCollapsed(itemId, uncollapsedByDefault);
  const toggleCollapsed = () =>
    togglePortfolioItemCollapsed(itemId, uncollapsedByDefault);

  return [collapsed, toggleCollapsed];
};

export default useCollapsedState;
