import { forwardRef } from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const NoneMenuItem = forwardRef((props, ref) => (
  <MenuItem {...props} ref={ref}>
    <em>
      <FormattedMessage id="portfolio.challenge.select_field.options.none" />
    </em>
  </MenuItem>
));

export default NoneMenuItem;
