import { createContext, useState } from 'react';
import moment from 'moment';
import useResponsiveVisibleWeeksCountCalculation from './useResponsiveVisibleWeeksCountCalculation';

const VisibleWeekRangeContext = createContext();

const ONLY_TWO_WEEKS_VISIBLE_WINDOW_WIDTH_THRESHOLD = 1000;

const AutomatedVisibleWeekRangeContextProvider = props => {
  const currentIsoWeekStartDate = moment().startOf('isoWeek');
  const previousIsoWeekStartDate = currentIsoWeekStartDate
    .clone()
    .subtract(1, 'weeks');

  const startWithCurrentWeek =
    window.innerWidth <= ONLY_TWO_WEEKS_VISIBLE_WINDOW_WIDTH_THRESHOLD;

  const [visibleWeekRangeStartDate, setVisibleWeekRangeStartDate] = useState(
    startWithCurrentWeek ? currentIsoWeekStartDate : previousIsoWeekStartDate
  );

  const [isZoomedInOnWeek, setIsZoomedInOnWeek] = useState(false);
  const [
    visibleWeekRangeStartDateBeforeZoom,
    setVisibleWeekRangeStartDateBeforeZoom
  ] = useState(null);

  const zoomInOnWeek = weekStartDate => {
    setIsZoomedInOnWeek(true);
    setVisibleWeekRangeStartDateBeforeZoom(visibleWeekRangeStartDate.clone());
    setVisibleWeekRangeStartDate(weekStartDate.clone());
  };

  const cancelWeekZoom = () => {
    setIsZoomedInOnWeek(false);
    setVisibleWeekRangeStartDate(visibleWeekRangeStartDateBeforeZoom);
    setVisibleWeekRangeStartDateBeforeZoom(null);
  };

  const originalVisibleWeeksCount = useResponsiveVisibleWeeksCountCalculation();
  const visibleWeeksCount = isZoomedInOnWeek ? 1 : originalVisibleWeeksCount;

  const visibleWeekRangeEndDate = visibleWeekRangeStartDate
    .clone()
    .add(visibleWeeksCount, 'weeks');

  const shiftWeekAmount = Math.ceil(visibleWeeksCount / 2);

  const shiftVisibleWeekRangeToLeft = () => {
    setVisibleWeekRangeStartDate(
      visibleWeekRangeStartDate
        .clone()
        .subtract(shiftWeekAmount, 'weeks')
        .startOf('isoWeek')
    );
  };

  const shiftVisibleWeekRangeToRight = () => {
    setVisibleWeekRangeStartDate(
      visibleWeekRangeStartDate
        .clone()
        .add(shiftWeekAmount, 'weeks')
        .startOf('isoWeek')
    );
  };

  return (
    <VisibleWeekRangeContext.Provider
      value={{
        visibleWeekRangeStartDate,
        visibleWeekRangeEndDate,
        visibleWeeksCount,
        shiftVisibleWeekRangeToLeft,
        shiftVisibleWeekRangeToRight,
        isZoomedInOnWeek,
        zoomInOnWeek,
        cancelWeekZoom
      }}
      {...props}
    />
  );
};

VisibleWeekRangeContext.AutomatedProvider = AutomatedVisibleWeekRangeContextProvider;

export default VisibleWeekRangeContext;
