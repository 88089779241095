import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import stripProps from 'lib/utils/stripProps';
import PossiblyDangerousIconButton from 'lib/components/PossiblyDangerousIconButton';
import mediaQuery from 'lib/styling/mediaQuery';
import device from 'current-device';

export const StyledActionButton = styled(
  motion.custom(
    stripProps(
      PossiblyDangerousIconButton,
      'small',
      'right',
      'correctRightMargin'
    )
  )
)(
  {
    marginTop: -12,
    marginBottom: -12,
    display: 'inline-block',
    ':focus': {
      transform: 'none !important',
      opacity: '1 !important'
    },
    [mediaQuery(560)]: {
      marginLeft: -6,
      marginRight: -6,
      ':last-of-type': {
        marginRight: 0
      }
    },
    [mediaQuery(640)]: {
      transform: 'none',
      opacity: '1'
    }
  },
  ({ small }) => {
    if (small) {
      return {
        padding: 6,
        width: 38,
        height: 38,
        [mediaQuery(560)]: {
          margin: -6
        }
      };
    }
  },
  ({ right }) => {
    if (right) {
      return {
        marginLeft: 'auto'
      };
    }
  },
  ({ correctRightMargin }) => {
    if (correctRightMargin) {
      return {
        marginRight: -6
      };
    }
  }
);

StyledActionButton.propTypes = {
  small: PropTypes.bool,
  right: PropTypes.bool,
  correctRightMargin: PropTypes.bool
};

StyledActionButton.defaultProps = {
  small: false,
  right: false,
  correctRightMargin: false
};

const AnimatedActionButton = props => {
  const shouldAlwaysShowButton = !device.desktop();
  return (
    <StyledActionButton
      variants={{
        initial: {
          y: shouldAlwaysShowButton ? 0 : 5,
          opacity: shouldAlwaysShowButton ? 1 : 0
        },
        hover: {
          y: 0,
          opacity: 1
        }
      }}
      {...props}
    />
  );
};

export default AnimatedActionButton;
