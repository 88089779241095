import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledPhaseColumn = styled('section')(
  ({ theme }) => ({
    position: 'relative',
    paddingTop: 12,
    paddingLeft: 20,
    flex: 1,
    boxSizing: 'border-box',
    borderLeft: `2px solid ${theme.palette.background.border}`,
    borderRight: `2px solid ${theme.palette.background.border}`,
    transition: theme.transitions.create(['border']),
    display: 'flex',
    alignContent: 'flex-start',
    flexWrap: 'wrap',
    ':first-child': {
      border: 'none'
    },
    ':last-child': {
      border: 'none'
    },
    [mediaQuery(640)]: {
      paddingLeft: 8,
      width: '100%',
      border: 'none'
    }
  }),
  ({ hasItems, inTaskBoard, collapsed, invisible }) => ({
    paddingBottom: collapsed || hasItems ? 4 : 36,
    paddingRight: inTaskBoard ? 20 : 36,
    minHeight: collapsed ? 36 : null,
    [mediaQuery(640)]: {
      // Un-setting the position is necessary for correct display of the create
      // task button
      position: inTaskBoard ? 'unset' : null,
      paddingRight: inTaskBoard ? 8 : 36,
      display: invisible ? 'none' : null
    }
  })
);

StyledPhaseColumn.propTypes = {
  hasItems: PropTypes.bool,
  inTaskBoard: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  invisible: PropTypes.bool
};

StyledPhaseColumn.defaultProps = {
  hasItems: false,
  invisible: false
};

export default StyledPhaseColumn;
