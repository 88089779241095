import useAddCopySuffix from 'lib/utils/useAddCopySuffix';
import { ref } from 'lib/firebase';

const useCopyPartShallow = () => {
  const addCopySuffix = useAddCopySuffix();

  const copyPartShallow = (part, highestPartUiPos, noParentAdd) => {
    const id = ref('portfolioTemplates/parts').push().key;

    !noParentAdd &&
      ref(`portfolioTemplates/subtopics/${part.subtopicId}/parts/${id}`).set(
        true
      );

    ref(`portfolioTemplates/parts/${id}`).set({
      subtopicId: part.subtopicId,
      id,
      title: addCopySuffix(part.title),
      uiPos: Math.ceil(highestPartUiPos) + 1,
      groups: part.groups || null,
      dynamicGroups: part.dynamicGroups || null
    });

    return id;
  };

  return copyPartShallow;
};

export default useCopyPartShallow;
