import sanitizeHtml from 'sanitize-html';

const escapeHtml = dirtyHtml =>
  sanitizeHtml(dirtyHtml, {
    allowedTags: ['br', 'p', 'ul', 'ol', 'li', 'strong', 'em', 'del', 'a'],
    allowedAttributes: {
      a: ['href', 'target']
    },
    transformTags: {
      a: (tagName, attributes) => ({
        tagName,
        attribs: {
          ...attributes,
          target: '_blank'
        }
      })
    }
  });

export default escapeHtml;
