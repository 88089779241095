import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const BackgroundImage = styled(motion.figure)(({ src }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  margin: 0,
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
  backgroundImage: `url('${src}')`,
  backgroundSize: 'cover'
}));

const SlideshowImage = props => (
  <BackgroundImage
    initial={{
      opacity: 0
    }}
    animate={{
      opacity: 1,
      transition: {
        delay: 0.4,
        duration: 0.6
      }
    }}
    exit={{
      opacity: 0,
      transition: {
        duration: 0.4
      }
    }}
    {...props}
  />
);

SlideshowImage.propTypes = {
  src: PropTypes.string.isRequired
};

export default SlideshowImage;
