import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledLinkIconButton = styled(IconButton)({
  [mediaQuery(640)]: {
    display: 'none'
  }
});

export default StyledLinkIconButton;
