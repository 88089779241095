import MobileCoachFeedbackButton from './MobileCoachFeedbackButton';
import { AddCommentOutlined as AddFeedbackIcon } from '@material-ui/icons';

const AddFeedbackButton = props => (
  <MobileCoachFeedbackButton {...props}>
    <AddFeedbackIcon />
  </MobileCoachFeedbackButton>
);

export default AddFeedbackButton;
