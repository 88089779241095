/* eslint-disable react-hooks/exhaustive-deps */
import { useRef } from 'react';
import PropTypes from 'prop-types';
import CustomPropTypes from 'lib/utils/CustomPropTypes';
import useResultListKeyboardNavigation from './useResultListKeyboardNavigation';
import StyledUserList from './StyledUserList';
import UserResult from './UserResult';
import NoUserResults from './NoUserResults';
import matchesSearch from 'lib/utils/matchesSearch';
import UserSwitchArrows from './UserSwitchArrows';
import { FormattedMessage } from 'react-intl';
import useUsers from './useUsers';
import App from 'lib/App';

const UserResultList = ({
  selectedUser,
  onSelectUser,
  onKeyboardSelectUser,
  searchInput,
  selectedGroupKey,
  collapsed,
  inputRef,
  ...props
}) => {
  const [loading, users] = useUsers(selectedUser, selectedGroupKey);
  const listRef = useRef(null);

  const selectableUsers = !loading
    ? users.visible.filter(({ name }) => matchesSearch(name, searchInput))
    : [];

  const selectionCursor = useResultListKeyboardNavigation(
    inputRef,
    listRef,
    selectableUsers,
    onSelectUser,
    onKeyboardSelectUser
  );

  const showUserSwitchingArrows = App.instanceConfig.useContentSetting(
    'enableUserSwitcherArrows'
  );

  if (loading) {
    return null;
  }

  // Note that we use onMouseDown instead of onClick in UserResult since
  // clicking also triggers onBlur on the UserSearchInput, thereby making the
  // user result list disappear causing user clicking to not work
  return (
    <>
      <StyledUserList
        id="user-result-list"
        role="listbox"
        collapsed={collapsed}
        {...props}
        ref={listRef}
      >
        {selectableUsers.length > 0 ? (
          selectableUsers.map((user, index) => (
            <UserResult
              user={user}
              onMouseDown={() => onSelectUser(user)}
              keyboardSelected={index === selectionCursor.index}
              listRef={listRef}
              key={user.email}
            />
          ))
        ) : (
          <NoUserResults>
            <FormattedMessage id="user_switcher.user_results.no_results" />
          </NoUserResults>
        )}
      </StyledUserList>
      {showUserSwitchingArrows && (
        <UserSwitchArrows
          previousArrowDisabled={!users.previous}
          nextArrowDisabled={!users.next}
          onPreviousUser={() => onSelectUser(users.previous)}
          onNextUser={() => onSelectUser(users.next)}
        />
      )}
    </>
  );
};

UserResultList.propTypes = {
  selectedUser: PropTypes.object,
  onSelectUser: PropTypes.func.isRequired,
  onKeyboardSelectUser: PropTypes.func.isRequired,
  searchInput: PropTypes.string.isRequired,
  selectedGroupKey: PropTypes.string,
  collapsed: PropTypes.bool.isRequired,
  inputRef: CustomPropTypes.ref.isRequired
};

export default UserResultList;
