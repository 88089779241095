/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import FieldContext from '../FieldContext';
import useSyncedState from 'lib/utils/useSyncedState';
import { useDebounce } from 'use-debounce';
import useCurrentFieldRef from '../useCurrentFieldRef';
import KeyValueEditor from './utils/KeyValueEditor';

const LogEntryNameEditor = props => {
  const { field } = useContext(FieldContext);
  const [logEntryName, setLogEntryName] = useSyncedState(
    field.logEntryName || ''
  );
  const [debouncedLogEntryName] = useDebounce(logEntryName, 500);

  const fieldRef = useCurrentFieldRef();
  useEffect(() => {
    const newLogEntryName = debouncedLogEntryName.trim();
    const oldLogEntryName = field.logEntryName || '';

    if (newLogEntryName !== oldLogEntryName) {
      fieldRef.child('logEntryName').set(newLogEntryName);
    }
  }, [debouncedLogEntryName]);

  return (
    <KeyValueEditor.Wrapper {...props}>
      <KeyValueEditor.Label id="editor.model_editor.field_editor.log_entry_name_editor.label" />
      <KeyValueEditor.TextField
        placeholderId="editor.model_editor.field_editor.log_entry_name_editor.input_placeholder"
        value={logEntryName}
        onChange={e => setLogEntryName(e.target.value)}
      />
    </KeyValueEditor.Wrapper>
  );
};

export default LogEntryNameEditor;
