import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';

const ChallengeNameLoadingPlaceholder = props => {
  const [randomNumber] = useState(Math.random());
  const placeholderWidth = 20 + randomNumber * 60;
  return (
    <Skeleton
      variant="text"
      width={`${placeholderWidth}%`}
      animation="wave"
      {...props}
    />
  );
};

export default ChallengeNameLoadingPlaceholder;
