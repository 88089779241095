import PropTypes from 'prop-types';
import StyledPart from './StyledPart';
import StyledPartTitle from './StyledPartTitle';
import RemoveAttachmentButton from './RemoveAttachmentButton';
import { LinkOff as RemoveAttachmentIcon } from '@material-ui/icons';
import detachPart from 'lib/firebase/rubricsPortfolioTemplates/detachPart';

const Part = ({ part, subtopic, ...props }) => {
  const removeAttachment = () => detachPart(part, subtopic.id);
  return (
    <StyledPart editor {...props}>
      <StyledPartTitle>{part.title}</StyledPartTitle>
      {part.managedFromLearningGoals && (
        <RemoveAttachmentButton onClick={removeAttachment}>
          <RemoveAttachmentIcon />
        </RemoveAttachmentButton>
      )}
    </StyledPart>
  );
};

Part.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    managedFromLearningGoals: PropTypes.bool
  }).isRequired,
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default Part;
