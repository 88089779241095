import PropTypes from 'prop-types';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import HideOnLowWidth from 'lib/components/HideOnLowWidth';
import RowActionButton from './RowActionButton';
import {
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@material-ui/icons';

const RowMoveButtons = ({
  onMoveUp,
  onMoveDown,
  canMoveUp,
  canMoveDown,
  ...props
}) => (
  <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
    <HideOnLowWidth width={640} {...props}>
      <RowActionButton
        onClick={() => {
          canMoveUp && onMoveUp();
        }}
        disabled={!canMoveUp}
        small
        right
      >
        <ArrowUpwardIcon />
      </RowActionButton>
      <RowActionButton
        onClick={() => {
          canMoveDown && onMoveDown();
        }}
        disabled={!canMoveDown}
        small
        correctRightMargin
      >
        <ArrowDownwardIcon />
      </RowActionButton>
    </HideOnLowWidth>
  </RestrictedToPortfolioMode>
);

RowMoveButtons.propTypes = {
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  canMoveUp: PropTypes.bool.isRequired,
  canMoveDown: PropTypes.bool.isRequired
};

export default RowMoveButtons;
