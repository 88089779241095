import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import DeleteButton from '../PortfolioTemplatesEditorActionButtons/DeleteButton';
import deleteSubtopicFromFirebase from 'lib/firebase/rubricsPortfolioTemplates/deleteSubtopic';
import { FormattedMessage } from 'react-intl';

const DeleteSubtopic = ({ subtopic, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteSubtopic = () => {
    setConfirmationDialogOpen(false);
    deleteSubtopicFromFirebase(subtopic.id);
  };

  return (
    <>
      <DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.subtopic.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.subtopic.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteSubtopic} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteSubtopic.propTypes = {
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired
  }).isRequired
};

export default DeleteSubtopic;
