const addHighContrastStyles = stylesOrStyleFunction => options => {
  const { theme } = options;

  if (!theme.palette.isHighContrast) {
    return;
  }

  if (typeof stylesOrStyleFunction === 'function') {
    return stylesOrStyleFunction(options);
  }

  return stylesOrStyleFunction;
};

export default addHighContrastStyles;
