import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import LoadingScreenWrapper from './LoadingScreenWrapper';
import StyledEgodactLogo from './StyledEgodactLogo';
import LoadingSpinner from 'lib/components/LoadingSpinner';

const LoadingScreen = ({ active, children, ...props }) => (
  <>
    <AnimatePresence>
      {active && (
        <LoadingScreenWrapper key="loading-screen" {...props}>
          <StyledEgodactLogo />
          <LoadingSpinner centered />
        </LoadingScreenWrapper>
      )}
    </AnimatePresence>
    {!active && children}
  </>
);

LoadingScreen.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default LoadingScreen;
