import { Skeleton } from '@material-ui/lab';
import Space from 'lib/components/Space';

const SelectLoadingPlaceholder = () => (
  <>
    <Space height={16} />
    <Skeleton variant="rect" animation="wave" width={206} height={24} />
    <Space height={16} />
  </>
);

export default SelectLoadingPlaceholder;
