import PropTypes from 'prop-types';
import ChallengeAvatars from './ChallengeAvatars';
import TaskAvatars from './TaskAvatars';

const UserAvatars = ({ challenge, asTask, ...props }) => {
  if (asTask) {
    return <TaskAvatars task={challenge} {...props} />;
  }

  return <ChallengeAvatars challenge={challenge} {...props} />;
};

UserAvatars.propTypes = {
  challenge: PropTypes.object.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default UserAvatars;
