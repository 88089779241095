const selectionContainsAnyLinks = (editorState, selection) => {
  const content = editorState.getCurrentContent();
  const blocksArray = content.getBlocksAsArray();

  const startBlockKey = selection.getStartKey();
  const endBlockKey = selection.getEndKey();

  const blockKeys = blocksArray.map(block => block.key);
  const selectedBlockKeys = blockKeys.slice(
    blockKeys.indexOf(startBlockKey),
    blockKeys.indexOf(endBlockKey) + 1
  );

  let hasAnyLinksSelected = false;

  blocksArray.forEach(block => {
    if (hasAnyLinksSelected) {
      return;
    }

    if (!selectedBlockKeys.includes(block.key)) {
      return;
    }

    block.findEntityRanges(
      character => {
        if (hasAnyLinksSelected) {
          return false;
        }

        const entityKey = character.getEntity();

        if (entityKey === null) {
          return false;
        }

        const entity = content.getEntity(entityKey);

        if (entity.getType() !== 'LINK') {
          return false;
        }

        return true;
      },
      (startOffset, endOffset) => {
        if (hasAnyLinksSelected) {
          return;
        }

        if (
          block.key === startBlockKey &&
          endOffset < selection.getStartOffset()
        ) {
          return;
        }

        if (
          block.key === endBlockKey &&
          startOffset > selection.getEndOffset()
        ) {
          return;
        }

        hasAnyLinksSelected = true;
      }
    );
  });

  return hasAnyLinksSelected;
};

export default selectionContainsAnyLinks;
