import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import RowActionButton from '../RowActionButton';
import { Edit as EditIcon } from '@material-ui/icons';

const EditButton = props => (
  <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
    <RowActionButton {...props}>
      <EditIcon />
    </RowActionButton>
  </RestrictedToPortfolioMode>
);

export default EditButton;
