import { useState } from 'react';
import PropTypes from 'prop-types';
import PillButtons from '../PillButtons';
import Dialog from 'lib/components/Dialog';
import { Delete as DeleteIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { FormattedMessage } from 'react-intl';

const DeleteProof = ({ proofRef, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteProof = () => {
    setConfirmationDialogOpen(false);
    proofRef.remove();
  };

  return (
    <>
      <PillButtons.Button
        onClick={() => setConfirmationDialogOpen(true)}
        rightHalf
        dangerous
        {...props}
      >
        <DeleteIcon />
      </PillButtons.Button>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.level.proof.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.level.proof.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteProof} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteProof.propTypes = {
  proofRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default DeleteProof;
