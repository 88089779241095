import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import createLinkDetectionPlugin from './link-detection-plugin';
import createListPlugin from 'draft-js-list-plugin';
import Editor from '@draft-js-plugins/editor';
import { KeyBindingUtil } from 'draft-js';

const linkDetectionPlugin = createLinkDetectionPlugin();
const listPlugin = createListPlugin({
  olRegex: /1[.)]/
});

const convertKeyEventToCommand = (event, editorManager) => {
  if (event.key === 'Enter' && KeyBindingUtil.hasCommandModifier(event)) {
    return 'enter-save';
  }

  return listPlugin.keyBindingFn(event, editorManager);
};

const InnerEditor = forwardRef(({ onEnterSave, ...props }, ref) => {
  const handleKeyCommand = command => {
    if (command === 'enter-save') {
      onEnterSave();

      return 'handled';
    }

    return 'not-handled';
  };

  return (
    <Editor
      ref={ref}
      plugins={[linkDetectionPlugin, listPlugin]}
      handleKeyCommand={handleKeyCommand}
      keyBindingFn={convertKeyEventToCommand}
      spellCheck
      {...props}
    />
  );
});

InnerEditor.propTypes = {
  onEnterSave: PropTypes.func.isRequired
};

export default InnerEditor;
