import styled from '@emotion/styled';

const InputBase = styled('input')(({ theme }) => ({
  padding: 0,
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  font: 'inherit',
  border: 'none',
  transition: theme.transitions.create(['color']),
  outlineWidth: 0
}));

export default InputBase;
