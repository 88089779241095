import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import RowLoadingSpinner from './RowLoadingSpinner';
import RowDeletionProvider from './RowDeletionProvider';
import RowHoverStateProvider from './RowHoverStateProvider';
import ChallengeBoard from '../../ChallengeBoard';
import CollapseButton from './CollapseButton';
import DeleteRow from './DeleteRow';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { rowRef } from 'lib/firebase';
import useRowMoveHandlers from './useRowMoveHandlers';
import useCollapsedState from './useCollapsedState';
import RowHeader from './RowHeader';
import device from 'current-device';
import EditableRowName from './EditableRowName';
import RowMoveButtons from './RowMoveButtons';
import MobileRowActions from './MobileRowActions';

const Row = ({
  rowKey,
  uiPos,
  previousRowKey,
  previousRowUiPos,
  nextRowKey,
  nextRowUiPos,
  highestUiPos,
  ...props
}) => {
  const [loading, row] = useFirebaseValue(rowRef(rowKey));
  const [moveUp, moveDown] = useRowMoveHandlers(
    rowKey,
    uiPos,
    previousRowKey,
    previousRowUiPos,
    nextRowKey,
    nextRowUiPos
  );

  const [collapsed, toggleCollapsed] = useCollapsedState(rowKey);
  const [nameEditing, setNameEditing] = useState(false);

  const useMobileVariant = useMediaQuery('(max-width: 560px)');

  if (loading) {
    return <RowLoadingSpinner />;
  }

  if (!row) {
    return null;
  }

  row.key = rowKey;

  return (
    <RowDeletionProvider
      rowKey={row.key}
      onDeletionStart={() => collapsed && toggleCollapsed()}
    >
      <RowHoverStateProvider {...props}>
        <RowHeader
          collapsed={collapsed}
          hasDarkBackground={nameEditing}
          onClick={!device.desktop() && !nameEditing ? toggleCollapsed : null}
        >
          <EditableRowName
            row={row}
            editing={nameEditing}
            setEditing={setNameEditing}
          />
          {!nameEditing && !useMobileVariant && (
            <>
              <DeleteRow row={row} />
              <CollapseButton collapsed={collapsed} onClick={toggleCollapsed} />
              <RowMoveButtons
                onMoveUp={moveUp}
                onMoveDown={moveDown}
                canMoveUp={!!previousRowKey}
                canMoveDown={!!nextRowKey}
              />
            </>
          )}
          {!nameEditing && useMobileVariant && (
            <MobileRowActions
              row={row}
              setRowNameEditing={setNameEditing}
              rowCollapsed={collapsed}
              toggleRowCollapsed={toggleCollapsed}
            />
          )}
        </RowHeader>
        <ChallengeBoard row={row} collapsed={collapsed} />
      </RowHoverStateProvider>
    </RowDeletionProvider>
  );
};

Row.propTypes = {
  rowKey: PropTypes.string.isRequired,
  uiPos: PropTypes.number.isRequired,
  previousRowKey: PropTypes.string,
  previousRowUiPos: PropTypes.number,
  nextRowKey: PropTypes.string,
  nextRowUiPos: PropTypes.number,
  highestUiPos: PropTypes.number.isRequired
};

export default Row;
