import { safeValues } from 'lib/utils/safeObjectFunctions';

const convertIndividualAllowedEmailToText = (
  allowedEmail,
  allowedEmailPrefix
) =>
  allowedEmailPrefix
    ? `${allowedEmailPrefix}*****${allowedEmail}`
    : allowedEmail;

const allowedEmailsText = ({
  allowedEmails: allowedEmailsObject,
  allowedEmailPrefix
}) => {
  const allowedEmails = safeValues(allowedEmailsObject);

  if (allowedEmails.length === 1) {
    return convertIndividualAllowedEmailToText(
      allowedEmails[0],
      allowedEmailPrefix
    );
  }

  const texts = allowedEmails.map(individualAllowedEmail =>
    convertIndividualAllowedEmailToText(
      individualAllowedEmail,
      allowedEmailPrefix
    )
  );

  const firstAllowedEmailsText = texts.slice(0, texts.length - 1).join(', ');
  const lastAllowedEmailText = texts[texts.length - 1];

  return `${firstAllowedEmailsText} & ${lastAllowedEmailText}`;
};

export default allowedEmailsText;
