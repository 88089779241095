import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const UserSwitcherWrapper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  marginLeft: 'auto',
  marginRight: 12,
  width: 250,
  fontFamily: 'CircularStd',
  border: `2px solid ${theme.palette.divider}`,
  boxShadow: 'none',
  display: 'inline-block',
  zIndex: 1,
  [mediaQuery(850)]: {
    width: 200
  },
  [mediaQuery(700)]: {
    marginRight: 0,
    width: '100%',
    boxSizing: 'border-box'
  }
}));

export default UserSwitcherWrapper;
