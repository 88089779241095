import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import { Fab } from '@material-ui/core';

const StyledAddFeedbackButton = styled(stripProps(Fab, 'belowFeedbackList'))(
  ({ theme }) => ({
    transition: theme.transitions.create()
  }),
  ({ belowFeedbackList }) => {
    if (belowFeedbackList) {
      return {
        width: 232
      };
    }

    return {
      position: 'absolute',
      bottom: 0,
      right: 0
    };
  }
);

StyledAddFeedbackButton.propTypes = {
  belowFeedbackList: PropTypes.bool.isRequired
};

export default StyledAddFeedbackButton;
