import { useContext } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../../LearningGoalsModelContext';
import LearningGoalsAppBar from 'components/LearningGoals/LearningGoalsAppBar';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import NoItemsMessage from 'components/LearningGoals/NoItemsMessage';
import TileGrid from 'components/LearningGoals/TileGrid';
import Subtopic from './Subtopic';
import { FormattedMessage } from 'react-intl';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const Subtopics = ({ topic, onClose, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);

  const allSubtopics = learningGoalsModel?.subtopics;
  const desiredSubtopicsKeys = safeKeys(topic.subtopics);
  const subtopics = desiredSubtopicsKeys
    .map(subtopicKey => {
      const subtopic = allSubtopics?.[subtopicKey];

      if (!subtopic) {
        return null;
      }

      return {
        key: subtopicKey,
        ...subtopic
      };
    })
    .filter(subtopic => !!subtopic);

  return (
    <>
      <LearningGoalsAppBar>
        <LearningGoalsAppBar.IconButton onClick={onClose}>
          <BackIcon />
        </LearningGoalsAppBar.IconButton>
        <LearningGoalsAppBar.Title>
          <FormattedMessage
            id="editor.model_editor.field_editor.learning_goals_editor.manager.manage_subtopics_title"
            values={{
              topicName: topic.name
            }}
          />
        </LearningGoalsAppBar.Title>
      </LearningGoalsAppBar>
      {subtopics.length === 0 ? (
        <NoItemsMessage accountForHeader>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.no_subtopics" />
        </NoItemsMessage>
      ) : (
        <TileGrid {...props}>
          {subtopics.map(subtopic => (
            <Subtopic subtopic={subtopic} key={subtopic.key} />
          ))}
        </TileGrid>
      )}
    </>
  );
};

Subtopics.propTypes = {
  topic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default Subtopics;
