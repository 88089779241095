import { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Space from 'lib/components/Space';
import { Button } from '@material-ui/core';
import { HelpOutline as SupportIcon } from '@material-ui/icons';
import SupportDialog from 'components/App/SupportSystem/SupportDialog';
import { FormattedMessage } from 'react-intl';

const ReportViaSupportSystem = props => {
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  const isLoggedIn = !!firebase.auth().currentUser;
  if (!isLoggedIn) {
    return null;
  }

  return (
    <>
      <Space height={12} />
      <Button
        variant="outlined"
        onClick={() => setSupportDialogOpen(true)}
        startIcon={<SupportIcon />}
        {...props}
      >
        <FormattedMessage id="error_boundary.report.support_button" />
      </Button>
      <SupportDialog
        open={supportDialogOpen}
        onClose={() => setSupportDialogOpen(false)}
      />
    </>
  );
};

export default ReportViaSupportSystem;
