import PropTypes from 'prop-types';
import LearningGoalsWrapper from 'components/LearningGoals/LearningGoalsWrapper';
import LearningGoalsManagerHeader from './LearningGoalsManagerHeader';
import LearningGoalsModelContext from './LearningGoalsModelContext';
import PortfolioTemplatesProvider from 'components/LearningGoals/PortfolioTemplatesProvider';
import Topics from './Topics';
import AddTopic from './AddTopic';

const LearningGoalsManager = ({ onClose, ...props }) => (
  <LearningGoalsWrapper {...props}>
    <LearningGoalsManagerHeader onClose={onClose} />
    <LearningGoalsModelContext.AutomatedProvider>
      <PortfolioTemplatesProvider>
        <Topics />
      </PortfolioTemplatesProvider>
      <AddTopic />
    </LearningGoalsModelContext.AutomatedProvider>
  </LearningGoalsWrapper>
);

LearningGoalsManager.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default LearningGoalsManager;
