import { useContext, useState } from 'react';
import FieldContext from '../../../FieldContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import App from 'lib/App';
import { value, userRef } from 'lib/firebase';
import FormWrapper from './FormWrapper';
import InnerForm from './InnerForm';
import LeaveChallenge from './LeaveChallenge';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const InviteUserForm = props => {
  const { challenge, challengeRef } = useContext(FieldContext);

  const [email, setEmail] = useState('');
  const [errorId, setErrorId] = useState(null);

  const sharedWith = safeKeys(challenge.sharedWith);

  const getUsernameFromEmail = App.authentication.useGetUsernameFromEmail();
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const inviteUser = async e => {
    e.preventDefault();

    if (!email.trim()) {
      return;
    }

    setErrorId(null);

    const username = getUsernameFromEmail(email.trim());

    if (username === currentUserUsername) {
      setErrorId('is_self');
      return;
    }

    if (sharedWith.includes(username) || challenge.originalOwner === username) {
      setErrorId('already_added');
      return;
    }

    const user = await value(userRef(username), true);

    if (!user || username === 'undefined') {
      setErrorId('doesnt_exist');
      return;
    }

    setEmail('');

    challengeRef.child(`sharedWith/${username}`).set(true);
    userRef(username).child(`newlyShared/${challenge.key}`).set(true);
  };

  return (
    <FormWrapper>
      <InnerForm
        label={
          <FormattedMessage id="portfolio.challenge.sharing.add_user.input_label" />
        }
        value={email}
        onChange={e => setEmail(e.target.value)}
        error={
          errorId && (
            <FormattedMessageWithChallengeNaming
              id={`portfolio.challenge.sharing.add_user.errors.${errorId}`}
            />
          )
        }
        onInvite={inviteUser}
      />
      {sharedWith.length > 0 && <LeaveChallenge />}
      {!!errorId && <Space height={14} />}
    </FormWrapper>
  );
};

export default InviteUserForm;
