import PropTypes from 'prop-types';
import { FormControlLabel, Radio } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ThemeOption = ({ value, ...props }) => (
  <FormControlLabel
    control={<Radio color="primary" />}
    value={value}
    label={
      <FormattedMessage
        id={`header.options_menu.display_settings.appearance.options.${value}`}
      />
    }
    {...props}
  />
);

ThemeOption.propTypes = {
  value: PropTypes.string.isRequired
};

export default ThemeOption;
