import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Palette as ThemeIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const DisplaySettingsButton = props => (
  <ListItem button {...props}>
    <ListItemIcon>
      <ThemeIcon />
    </ListItemIcon>
    <ListItemText
      primary={
        <FormattedMessage id="header.options_menu.options.display_settings" />
      }
    />
  </ListItem>
);

export default DisplaySettingsButton;
