/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import useSendFeedbackToFeedbackServer from './useSendFeedbackToFeedbackServer';
import DialogContentWrapper from '../../DialogContentWrapper';
import Dialog from 'lib/components/Dialog';
import Prompt from 'lib/components/Prompt';
import { FormattedMessage } from 'react-intl';
import SendButton from './SendButton';
import IncludeEmailCheckbox from './IncludeEmailCheckbox';

const FeedbackForm = ({
  setDialogActions,
  onGoBack,
  onDialogClose,
  ...props
}) => {
  const [feedback, setFeedback] = useState('');
  const [showEmptyError, setShowEmptyError] = useState(false);
  const [includeEmail, setIncludeEmail] = useState(false);

  const inputRef = useRef(null);

  const sendFeedbackToFeedbackServer = useSendFeedbackToFeedbackServer();

  const sendFeedback = () => {
    if (feedback.trim().length === 0) {
      inputRef.current.focus();
      setShowEmptyError(true);
      return;
    }

    setShowEmptyError(false);

    sendFeedbackToFeedbackServer(feedback, includeEmail);

    onGoBack();
    onDialogClose();
  };

  useEffect(() => {
    setDialogActions(<SendButton onClick={sendFeedback} />);
    return () => setDialogActions(null);
  }, [feedback, includeEmail]);

  return (
    <DialogContentWrapper>
      <Dialog.Title>
        <FormattedMessage id="support.dialog.feedback_form.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="support.dialog.feedback_form.text" />
        </Dialog.ContentText>
      </Dialog.Content>
      <Prompt.TextField
        label={
          <FormattedMessage id="support.dialog.feedback_form.input_label" />
        }
        value={feedback}
        onChange={e => setFeedback(e.target.value)}
        error={showEmptyError}
        helperText={
          showEmptyError && (
            <FormattedMessage id="support.dialog.feedback_form.empty_error" />
          )
        }
        autoFocus={false}
        rows={6}
        inputRef={inputRef}
        multiline
        {...props}
      />
      <IncludeEmailCheckbox
        checked={includeEmail}
        onChange={e => setIncludeEmail(e.target.checked)}
      />
    </DialogContentWrapper>
  );
};

FeedbackForm.propTypes = {
  setDialogActions: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired
};

export default FeedbackForm;
