import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const TasksIconButton = styled(IconButton)({
  [mediaQuery(640)]: {
    marginLeft: -12,
    marginRight: -12
  }
});

export default TasksIconButton;
