import styled from '@emotion/styled';

const MoveButtonsWrapper = styled('div')(({ theme }) => ({
  marginTop: 5,
  marginBottom: 5,
  backgroundColor: theme.palette.action.selected,
  borderRadius: 19
}));

export default MoveButtonsWrapper;
