import { useContext } from 'react';
import PhaseContext from '../../../../PhaseContext';
import useAddCopySuffix from 'lib/utils/useAddCopySuffix';
import App from 'lib/App';
import { ref } from 'lib/firebase';

const useMakeChallengeCopyWithoutTasks = () => {
  const challengePhase = useContext(PhaseContext);
  const addCopySuffix = useAddCopySuffix();

  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const makeChallengeCopyWithoutTasks = (challenge, rowKey) => {
    const challengeCopy = {
      ...challenge,
      key: null,
      coachComments: null,
      name: addCopySuffix(challenge.name),
      creationDate: Date.now(),
      originalOwner: currentUserUsername,
      sharedWith: null,
      row: {
        [currentUserUsername]: rowKey
      },
      phase: challengePhase,
      tasks: null
    };

    return ref('challenges').push(challengeCopy).key;
  };

  return makeChallengeCopyWithoutTasks;
};

export default useMakeChallengeCopyWithoutTasks;
