import PropTypes from 'prop-types';
import { ref } from 'lib/firebase';
import SelectLoadingPlaceholder from './SelectLoadingPlaceholder';
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormHelperText
} from '@material-ui/core';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { safeKeys, safeEntries } from 'lib/utils/safeObjectFunctions';

const PromptGroupSelect = ({ value, onChange, ...props }) => {
  const [loading, groups] = useFirebaseValue(ref('groups'));

  if (loading) {
    return <SelectLoadingPlaceholder />;
  }

  return (
    <>
      <FormControl style={{ maxWidth: '100%' }}>
        <InputLabel htmlFor="prompt-group-select">
          <FormattedMessage id="dialogs.checklist_prompt_with_group_select.group_select.label" />
        </InputLabel>
        <Select
          value={value}
          onChange={onChange}
          input={<Input id="prompt-group-select" />}
          style={{ minWidth: 206 }}
          disabled={!groups || safeKeys(groups).length === 0}
          multiple
          {...props}
        >
          {safeEntries(groups).map(([key, group]) => (
            <MenuItem value={key} key={key}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>
        <FormattedMessage id="dialogs.checklist_prompt_with_group_select.group_select.text" />
      </FormHelperText>
      <Space height={16} />
    </>
  );
};

PromptGroupSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PromptGroupSelect;
