import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import RowDeletionContext from '../RowDeletionProvider/RowDeletionContext';
import countRowChallenges from './countRowChallenges';
import DeleteRowButton from './DeleteRowButton';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const DeleteRow = ({ row, ButtonComponent, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { deleteRow, startRowDeletion } = useContext(RowDeletionContext);

  const rowChallengeCount = countRowChallenges(row);
  const rowHasNoChallenges = rowChallengeCount === 0;

  const triggerRowDeletion = () => {
    setConfirmationDialogOpen(false);

    if (rowHasNoChallenges) {
      deleteRow();
      return;
    }

    startRowDeletion();
  };

  return (
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <ButtonComponent
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      />
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.row.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming
              id={
                rowHasNoChallenges
                  ? 'portfolio.row.delete.text.empty_row'
                  : 'portfolio.row.delete.text'
              }
            />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={triggerRowDeletion} />
        </Dialog.Actions>
      </Dialog>
    </RestrictedToPortfolioMode>
  );
};

DeleteRow.propTypes = {
  row: PropTypes.object.isRequired,
  ButtonComponent: PropTypes.elementType
};

DeleteRow.defaultProps = {
  ButtonComponent: DeleteRowButton
};

export default DeleteRow;
