import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import useResizeObserver from 'use-resize-observer';
import getGrid from './getGrid';
import getTileFontSize from './getTileFontSize';
import TileWrapper from './TileWrapper';
import AppBarMarginFix from './AppBarMarginFix';
import Tile from './Tile';
import AddButton from './AddButton';

const TileGrid = ({ children, ...props }) => {
  const tileCount = Children.count(children);
  const {
    ref: wrapperRef,
    width: gridWidth = 1,
    height: gridHeight = 1
  } = useResizeObserver();
  const { rows, columns } = getGrid(tileCount, gridWidth, gridHeight);

  const absoluteTileWidth = (1 / columns) * gridWidth;
  const tileFontSize = getTileFontSize(absoluteTileWidth);

  return (
    <TileWrapper rows={rows} columns={columns} {...props} ref={wrapperRef}>
      {Children.map(children, child =>
        cloneElement(child, {
          fontSize: tileFontSize
        })
      )}
    </TileWrapper>
  );
};

TileGrid.propTypes = {
  children: PropTypes.node
};

TileGrid.AppBarMarginFix = AppBarMarginFix;
TileGrid.Tile = Tile;
TileGrid.AddButton = AddButton;

export default TileGrid;
