import { useState } from 'react';
import App from 'lib/App';
import readImageAsDataUrl from './readImageAsDataUrl';

const useUploadedLogoState = () => {
  const [logoAsDataUrl, setLogoAsDataUrl] = useState('');
  const [, originalInstanceLogo] = App.instanceConfig.useInstanceLogo();

  const updateLogo = async uploadedFiles => {
    const logoFile = uploadedFiles[0];
    if (logoFile) {
      const logoAsDataUrl = await readImageAsDataUrl(logoFile);
      setLogoAsDataUrl(logoAsDataUrl);
    }
  };

  const logoHasChanged = !!logoAsDataUrl;

  return [
    logoAsDataUrl || originalInstanceLogo || '',
    updateLogo,
    logoHasChanged
  ];
};

export default useUploadedLogoState;
