import { useState } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { challengeRef } from 'lib/firebase';
import ChallengePlanningBar from './ChallengePlanningBar';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import ChallengeName from './ChallengeName';
import ChallengePreviewCard from './ChallengePreviewCard';
import ChallengeView from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView';

const Challenge = ({ challengeKey, ...props }) => {
  const [loading, challenge] = useFirebaseValue(challengeRef(challengeKey));
  const [challengeOpen, setChallengeOpen] = useState(false);

  if (loading || !challenge) {
    return null;
  }

  if (!challenge.plannedStartDate || !challenge.plannedEndDate) {
    return null;
  }

  challenge.key = challengeKey;

  return (
    <>
      <ChallengePlanningBar
        challenge={challenge}
        onClick={() => {
          setChallengeOpen(true);
          setBodyOverflow('hidden');
        }}
        {...props}
      >
        {(contentVisibilityCompensationDistance, contentMaxVisibleWidth) => (
          <>
            <ChallengeName
              contentVisibilityCompensationDistance={
                contentVisibilityCompensationDistance
              }
              contentMaxVisibleWidth={contentMaxVisibleWidth}
            >
              {challenge.name}
            </ChallengeName>
            <ChallengePreviewCard
              challenge={challenge}
              contentVisibilityCompensationDistance={
                contentVisibilityCompensationDistance
              }
            />
          </>
        )}
      </ChallengePlanningBar>
      {challengeOpen && (
        <ChallengeView
          challenge={challenge}
          asTask={false}
          onClose={() => {
            setChallengeOpen(false);
            setBodyOverflow('auto');
          }}
        />
      )}
    </>
  );
};

Challenge.propTypes = {
  challengeKey: PropTypes.string.isRequired
};

export default Challenge;
