import PropTypes from 'prop-types';
import PageWrapper from './PageWrapper';
import ScreenWrapper from './ScreenWrapper';
import ErrorIcon from './ErrorIcon';
import ScreenContentWrapper from './ScreenContentWrapper';
import ErrorInfo from './ErrorInfo';
import ErrorReporting from './ErrorReporting';
import ErrorScreenFooter from './ErrorScreenFooter';

const ErrorScreen = ({ error, errorComponentStack, ...props }) => (
  <PageWrapper>
    <ScreenWrapper {...props}>
      <ErrorIcon />
      <ScreenContentWrapper>
        <ErrorInfo />
        <ErrorReporting
          error={error}
          errorComponentStack={errorComponentStack}
        />
      </ScreenContentWrapper>
    </ScreenWrapper>
    <ErrorScreenFooter />
  </PageWrapper>
);

ErrorScreen.propTypes = {
  error: PropTypes.string.isRequired,
  errorComponentStack: PropTypes.string.isRequired
};

export default ErrorScreen;
