import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const SettingsBarWrapper = styled('header')(
  ({ theme }) => ({
    marginLeft: -148,
    marginRight: -148,
    paddingLeft: 148,
    paddingRight: 148,
    paddingTop: 8,
    paddingBottom: 8,
    backgroundColor: theme.palette.background.shaded,
    transition: theme.transitions.create(['background-color', 'border-bottom']),
    display: 'flex',
    alignItems: 'center',
    [mediaQuery(1400)]: {
      paddingTop: 12,
      paddingBottom: 12
    },
    [mediaQuery(1024)]: {
      marginLeft: -72,
      marginRight: -72,
      paddingLeft: 72,
      paddingRight: 72
    },
    [mediaQuery(850)]: {
      marginLeft: -24,
      marginRight: -24,
      paddingLeft: 24,
      paddingRight: 24
    },
    [mediaQuery(640)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  }),
  ({ theme, isDisplayingChallengePortfolio }) => {
    if (isDisplayingChallengePortfolio) {
      return {
        marginLeft: '0 !important',
        marginRight: '0 !important',
        paddingLeft: '24px !important',
        paddingRight: '24px !important',
        borderBottom: `2px solid ${theme.palette.background.border}`
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    borderBottom: `2px solid ${theme.palette.divider}`
  }))
);

SettingsBarWrapper.propTypes = {
  isDisplayingChallengePortfolio: PropTypes.bool.isRequired
};

export default SettingsBarWrapper;
