import { useContext } from 'react';
import PropTypes from 'prop-types';
import RowDeletionContext from '../RowDeletionProvider/RowDeletionContext';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import RowActionButton from '../RowActionButton';
import EditRowNameButton from './EditRowNameButton';
import DeleteRow from '../DeleteRow';
import DeleteRowButton from './DeleteRowButton';
import { Divider } from '@material-ui/core';
import RowCollapseButton from './RowCollapseButton';

const MobileRowActions = ({
  row,
  setRowNameEditing,
  rowCollapsed,
  toggleRowCollapsed,
  ...props
}) => {
  const { rowDeleting } = useContext(RowDeletionContext);

  const conditionalActionMenuProps = rowDeleting
    ? {
        open: false
      }
    : {};

  // The stopPropagation() call prevents row action menu clicks from propagating
  // to the row header, thereby inadvertently triggering row collapsing
  return (
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <CollapsibleActionMenu
        ButtonComponent={RowActionButton}
        onClick={e => e.stopPropagation()}
        {...conditionalActionMenuProps}
        {...props}
      >
        <EditRowNameButton onClick={() => setRowNameEditing(true)} />
        <DeleteRow row={row} ButtonComponent={DeleteRowButton} />
        <Divider component="li" />
        <RowCollapseButton
          rowCollapsed={rowCollapsed}
          onClick={toggleRowCollapsed}
        />
      </CollapsibleActionMenu>
    </RestrictedToPortfolioMode>
  );
};

MobileRowActions.propTypes = {
  row: PropTypes.object.isRequired,
  setRowNameEditing: PropTypes.func.isRequired,
  rowCollapsed: PropTypes.bool.isRequired,
  toggleRowCollapsed: PropTypes.func.isRequired
};

export default MobileRowActions;
