import PropTypes from 'prop-types';
import FlagWrapper from './FlagWrapper';
import StyledIcon from './StyledIcon';
import getFlagIconForLanguage from './getFlagIconForLanguage';
import { supportedLanguages } from 'lib/utils/getLanguage';

const FlagIcon = ({ language, ...props }) => (
  <FlagWrapper {...props}>
    <StyledIcon src={getFlagIconForLanguage(language)} alt="" />
  </FlagWrapper>
);

FlagIcon.propTypes = {
  language: PropTypes.oneOf(supportedLanguages).isRequired
};

export default FlagIcon;
