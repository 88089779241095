import PropTypes from 'prop-types';
import StyledPhaseText from './StyledPhaseText';
import phaseName from 'lib/utils/phaseName';
import Phases from 'lib/constants/Phases';

const PhaseText = ({ challenge, ...props }) => (
  <StyledPhaseText challengeColor={challenge.color} {...props}>
    {phaseName(challenge.phase)}
  </StyledPhaseText>
);

PhaseText.propTypes = {
  challenge: PropTypes.shape({
    phase: PropTypes.oneOf(Object.values(Phases)).isRequired,
    color: PropTypes.string
  }).isRequired
};

export default PhaseText;
