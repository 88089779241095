import { useContext } from 'react';
import TileInfoContext from '../TileInfoContext';
import { useTheme } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import StyledActionButton from './StyledActionButton';

const TileActionButton = props => {
  const { color: tileColor } = useContext(TileInfoContext);

  const theme = useTheme();
  const correctButtonColorTheme = theme.extend({
    palette: {
      primary: {
        main: theme.palette.isHighContrast
          ? theme.palette.text.primary
          : theme.palette.getContrastText(tileColor)
      }
    }
  });

  return (
    <MuiThemeProvider theme={correctButtonColorTheme}>
      <StyledActionButton color="primary" {...props} />
    </MuiThemeProvider>
  );
};

export default TileActionButton;
