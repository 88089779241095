import { useContext } from 'react';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import FieldContext from '../../FieldContext';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { ListItemIcon } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ClearFieldName = props => {
  const fieldRef = useCurrentFieldRef();
  const clearName = () => fieldRef.child('name').remove();

  const { field } = useContext(FieldContext);
  const nameAlreadyCleared = !field.name || field.name.trim() === '';

  return (
    <CollapsibleActionMenu.Button
      onClick={clearName}
      disabled={nameAlreadyCleared}
      {...props}
    >
      <ListItemIcon>
        <ClearIcon />
      </ListItemIcon>
      <FormattedMessage id="editor.model_editor.actions.clear_name" />
    </CollapsibleActionMenu.Button>
  );
};

export default ClearFieldName;
