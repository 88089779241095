import { useIntl } from 'react-intl';

const useDefaultChallengeNaming = () => {
  const intl = useIntl();
  return {
    defaultChallengeNameSingular: intl.formatMessage({
      id: 'default_challenge_name.singular'
    }),
    defaultChallengeNamePlural: intl.formatMessage({
      id: 'default_challenge_name.plural'
    })
  };
};

export default useDefaultChallengeNaming;
