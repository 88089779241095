import { useState } from 'react';

const useSelectedUsers = () => {
  const [selectedUsers, setSelectedUsers] = useState({});

  const addUserToSelection = username => {
    setSelectedUsers({
      ...selectedUsers,
      [username]: true
    });
  };

  const removeUserFromSelection = username => {
    const newSelectedUsers = { ...selectedUsers };
    delete newSelectedUsers[username];
    setSelectedUsers(newSelectedUsers);
  };

  const setUserSelected = (username, selected) => {
    if (selected) {
      addUserToSelection(username);
      return;
    }

    removeUserFromSelection(username);
  };

  const clearSelectedUsers = () => setSelectedUsers({});

  return {
    selectedUsers,
    setUserSelected,
    clearSelectedUsers
  };
};

export default useSelectedUsers;
