import ErrorTitle from './ErrorTitle';
import TitleErrorIcon from './TitleErrorIcon';
import ErrorText from './ErrorText';
import OutdatedBrowserDetection from './OutdatedBrowserDetection';
import RefreshButton from './RefreshButton';
import LogoutButton from './LogoutButton';
import { FormattedMessage } from 'react-intl';

const ErrorInfo = () => (
  <>
    <ErrorTitle>
      <TitleErrorIcon />
      <FormattedMessage id="error_boundary.title" />
    </ErrorTitle>
    <ErrorText>
      <FormattedMessage id="error_boundary.text" />
    </ErrorText>
    <OutdatedBrowserDetection />
    <RefreshButton />
    <LogoutButton />
  </>
);

export default ErrorInfo;
