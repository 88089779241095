import ActionButton from './ActionButton';
import { Delete as DeleteIcon } from '@material-ui/icons';

const DeleteButton = props => (
  <ActionButton dangerous {...props}>
    <DeleteIcon />
  </ActionButton>
);

export default DeleteButton;
