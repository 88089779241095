import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import App from 'lib/App';
import useSlo from './useSlo';
import SloLoadingPlaceholder from './SloLoadingPlaceholder';
import SloContext from './SloContext';
import SloListEntry from './SloListEntry';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';
import SloView from './SloView';

const Slo = ({ sloKey, withGrouping, ...props }) => {
  const portfolioMode = useContext(PortfolioModeContext);
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const [sloOpen, setSloOpen] = useState(false);
  const [sloLoading, slo] = useSlo(sloKey);

  if (sloLoading) {
    return <SloLoadingPlaceholder withGrouping={withGrouping} />;
  }

  if (!slo) {
    return null;
  }

  const sloIsViewedByOtherStudent =
    portfolioMode === PortfolioModes.STUDENT &&
    currentUserUsername !== slo.user;

  return (
    <SloContext.Provider value={slo}>
      <SloListEntry
        onOpenSlo={() => setSloOpen(true)}
        withGrouping={withGrouping}
        {...props}
      />
      <PortfolioModeContext.Provider
        value={sloIsViewedByOtherStudent ? PortfolioModes.COACH : portfolioMode}
      >
        <PortfolioIsReadOnlyContext.Provider value={sloIsViewedByOtherStudent}>
          {sloOpen && <SloView onClose={() => setSloOpen(false)} />}
        </PortfolioIsReadOnlyContext.Provider>
      </PortfolioModeContext.Provider>
    </SloContext.Provider>
  );
};

Slo.propTypes = {
  sloKey: PropTypes.string.isRequired,
  isActiveSlo: PropTypes.bool,
  withGrouping: PropTypes.bool
};

Slo.defaultProps = {
  isActiveSlo: false,
  withGrouping: false
};

export default Slo;
