import { forwardRef } from 'react';
import DangerColorThemeProvider from 'lib/components/DangerColorThemeProvider';
import { IconButton } from '@material-ui/core';

const DangerousIconButton = forwardRef((props, ref) => (
  <DangerColorThemeProvider>
    <IconButton color="primary" ref={ref} {...props} />
  </DangerColorThemeProvider>
));

export default DangerousIconButton;
