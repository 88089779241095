import styled from '@emotion/styled';
import OriginalStyledSubtopic from 'components/RubricsPortfolioComponents/StyledSubtopic';

const StyledSubtopic = styled(OriginalStyledSubtopic)({
  marginBottom: 42,
  cursor: 'initial',
  ':last-of-type': {
    marginBottom: 18
  }
});

export default StyledSubtopic;
