import styled from '@emotion/styled';

const LegendItem = styled('li')({
  marginRight: 20,
  marginBottom: 20,
  fontFamily: 'CircularStd',
  display: 'inline-flex',
  alignItems: 'center',
  ':last-child': {
    marginRight: 0
  }
});

export default LegendItem;
