import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import FormWrapper from './FormWrapper';
import OptionNameTextField from './OptionNameTextField';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const OptionNameEditor = ({ option, optionRef, onClose, ...props }) => {
  const [newOptionName, setNewOptionName] = useSyncedState(option.name);

  const saveNameChange = e => {
    e.preventDefault();

    if (newOptionName.trim()) {
      optionRef.child('name').set(newOptionName);
      onClose();
    }
  };

  const cancelNameChange = () => {
    onClose();
    setNewOptionName(option.name);
  };

  return (
    <FormWrapper onSubmit={saveNameChange} {...props}>
      <OptionNameTextField
        value={newOptionName}
        onChange={e => setNewOptionName(e.target.value)}
        onEscPress={cancelNameChange}
      />
      <IconButton type="submit" disabled={!newOptionName.trim()}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

OptionNameEditor.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  optionRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  onClose: PropTypes.func.isRequired
};

export default OptionNameEditor;
