import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import FormWrapper from './FormWrapper';
import ChallengeNameInput from './ChallengeNameInput';
import HideOnLowWidth from 'lib/components/HideOnLowWidth';
import CancelButton from 'lib/components/CancelButton';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const ChallengeNameEditor = ({
  challenge,
  challengeRef,
  onCancel,
  asTask,
  ...props
}) => {
  const [newChallengeName, setNewChallengeName] = useSyncedState(
    challenge.name
  );

  const saveNameChange = e => {
    e.preventDefault();

    if (newChallengeName.trim()) {
      challengeRef.child('name').set(newChallengeName.trim());
      onCancel();
    }
  };

  const cancelNameChange = () => {
    onCancel();
    setNewChallengeName(challenge.name);
  };

  return (
    <FormWrapper onSubmit={saveNameChange} {...props}>
      <ChallengeNameInput
        value={newChallengeName}
        onChange={e => setNewChallengeName(e.target.value)}
        onEscPress={cancelNameChange}
        asTask={asTask}
        autoFocus
      />
      <HideOnLowWidth width={700}>
        <CancelButton
          visible={newChallengeName !== challenge.name}
          onClick={cancelNameChange}
        />
      </HideOnLowWidth>
      <IconButton type="submit" disabled={!newChallengeName.trim()}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

ChallengeNameEditor.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  onCancel: PropTypes.func.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default ChallengeNameEditor;
