import MobileCoachFeedbackButton from './MobileCoachFeedbackButton';
import { Comment as FeedbackIcon } from '@material-ui/icons';

const OpenFeedbackButton = props => (
  <MobileCoachFeedbackButton color="primary" {...props}>
    <FeedbackIcon />
  </MobileCoachFeedbackButton>
);

export default OpenFeedbackButton;
