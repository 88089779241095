import { useState, useEffect } from 'react';

const useSyncedState = firebaseValue => {
  const [value, setValue] = useState(firebaseValue);

  useEffect(() => {
    setValue(firebaseValue);
  }, [firebaseValue]);

  return [value, setValue];
};

export default useSyncedState;
