import PropTypes from 'prop-types';
import SupportTypes from '../SupportTypes';
import ContactInfo from './ContactInfo';
import FeedbackForm from './FeedbackForm';
import IssueReporting from './IssueReporting';

const SupportContentRenderer = ({ selectedSupportType, ...props }) => {
  switch (selectedSupportType) {
    case SupportTypes.CONTACT: {
      return <ContactInfo {...props} />;
    }
    case SupportTypes.FEEDBACK: {
      return <FeedbackForm {...props} />;
    }
    case SupportTypes.ISSUE_REPORTING: {
      return <IssueReporting {...props} />;
    }
    default: {
      return null;
    }
  }
};

SupportContentRenderer.propTypes = {
  selectedSupportType: PropTypes.oneOf(Object.values(SupportTypes)),
  setDialogActions: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired
};

export default SupportContentRenderer;
