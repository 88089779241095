import {
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatStrikethrough as StrikethroughIcon,
  FormatListBulleted as UnorderedListIcon,
  FormatListNumbered as OrderedListIcon
} from '@material-ui/icons';

export const inlineStyles = [
  {
    label: 'bold',
    identifier: 'BOLD',
    icon: BoldIcon
  },
  {
    label: 'italic',
    identifier: 'ITALIC',
    icon: ItalicIcon
  },
  {
    label: 'strikethrough',
    identifier: 'STRIKETHROUGH',
    icon: StrikethroughIcon
  }
];

export const blockTypes = [
  {
    label: 'unordered_list',
    identifier: 'unordered-list-item',
    icon: UnorderedListIcon
  },
  {
    label: 'ordered_list',
    identifier: 'ordered-list-item',
    icon: OrderedListIcon
  }
];
