import { useState } from 'react';
import SloListEntryWrapper from './SloListEntryWrapper';
import { Skeleton } from '@material-ui/lab';

const SloLoadingPlaceholder = props => {
  const [randomNumber] = useState(Math.random());
  const nameLoadingPlaceholderWidth = 20 + randomNumber * 40;
  return (
    <SloListEntryWrapper {...props}>
      <Skeleton
        variant="text"
        width={`${nameLoadingPlaceholderWidth}%`}
        animation="wave"
      />
    </SloListEntryWrapper>
  );
};

export default SloLoadingPlaceholder;
