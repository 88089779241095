import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

const EditButton = props => (
  <IconButton {...props}>
    <EditIcon />
  </IconButton>
);

export default EditButton;
