import PropTypes from 'prop-types';
import App from 'lib/App';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import allowedEmailsText from './allowedEmailsText';
import { FormattedHTMLMessage } from 'react-intl';

const AllowedEmailsMessage = ({ translationsGroupId, ...props }) => {
  const instanceConfig = App.instanceConfig.useSettings();

  const allowedEmails = safeValues(instanceConfig.allowedEmails);
  const hasMultipleAllowedEmails = allowedEmails.length > 1;

  if (hasMultipleAllowedEmails) {
    return (
      <FormattedHTMLMessage
        id={`${translationsGroupId}.plural`}
        values={{
          allowedEmailsText: allowedEmailsText(instanceConfig)
        }}
        {...props}
      />
    );
  }

  return (
    <FormattedHTMLMessage
      id={`${translationsGroupId}.singular`}
      values={{
        allowedEmailText: allowedEmailsText(instanceConfig)
      }}
      {...props}
    />
  );
};

AllowedEmailsMessage.propTypes = {
  translationsGroupId: PropTypes.string
};

AllowedEmailsMessage.defaultProps = {
  translationsGroupId: 'login.allowed_emails_text'
};

export default AllowedEmailsMessage;
