import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import FormWrapper from './FormWrapper';
import GroupNameInput from './GroupNameInput';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const GroupNameEditor = ({ group, onClose, ...props }) => {
  const [newGroupName, setNewGroupName] = useSyncedState(group.name);

  const saveNameChange = e => {
    e.preventDefault();

    if (newGroupName.trim()) {
      ref(`groups/${group.key}/name`).set(newGroupName.trim());
      onClose();
    }
  };

  const cancelNameChange = () => {
    onClose();
    setNewGroupName(group.name);
  };

  const intl = useIntl();
  return (
    <FormWrapper onSubmit={saveNameChange}>
      <GroupNameInput
        placeholder={intl.formatMessage({
          id: 'group_manager.group_form.input_label'
        })}
        value={newGroupName}
        onChange={e => setNewGroupName(e.target.value)}
        onEscPress={cancelNameChange}
        autoFocus
      />
      <IconButton type="submit" disabled={!newGroupName.trim()}>
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

GroupNameEditor.propTypes = {
  group: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default GroupNameEditor;
