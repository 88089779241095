import App from 'lib/App';
import Modes from 'lib/constants/Modes';
import WelcomeMessage from './WelcomeMessage';
import StyledPageTitle from './StyledPageTitle';
import { FormattedMessage } from 'react-intl';

const HeaderPageTitle = props => {
  const selectedMode = App.navigation.useSelectedMode();

  if (!selectedMode) {
    return null;
  }

  if (selectedMode === Modes.PORTFOLIO) {
    return <WelcomeMessage />;
  }

  return (
    <StyledPageTitle {...props}>
      <FormattedMessage id={`header.page_titles.${selectedMode}`} />
    </StyledPageTitle>
  );
};

export default HeaderPageTitle;
