import { useState } from 'react';

const useAllowedEmailsState = initialAllowedEmails => {
  const [allowedEmails, setAllowedEmails] = useState(
    initialAllowedEmails || {}
  );

  const allowedEmailsArray = Object.entries(allowedEmails).map(
    ([id, allowedEmail]) => ({
      id,
      allowedEmail
    })
  );

  const addAllowedEmail = newAllowedEmail => {
    const allowedEmailIds = Object.keys(allowedEmails);
    const highestId = Number(allowedEmailIds.sort().reverse()[0] || -1);
    const newAllowedEmailId = highestId + 1;

    setAllowedEmails({
      ...allowedEmails,
      [newAllowedEmailId]: newAllowedEmail
    });
  };

  const editAllowedEmail = (id, updatedAllowedEmail) => {
    setAllowedEmails({
      ...allowedEmails,
      [id]: updatedAllowedEmail
    });
  };

  const removeAllowedEmail = id => {
    const newAllowedEmails = { ...allowedEmails };
    delete newAllowedEmails[id];

    const newAllowedEmailsWithoutIdGaps = {
      ...Object.values(newAllowedEmails)
    };

    setAllowedEmails(newAllowedEmailsWithoutIdGaps);
  };

  return {
    allowedEmails: allowedEmailsArray,
    allowedEmailsAsObject: allowedEmails,
    addAllowedEmail,
    editAllowedEmail,
    removeAllowedEmail
  };
};

export default useAllowedEmailsState;
