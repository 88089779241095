const getElementBoundaries = element => {
  const boundingClientRect = element.getBoundingClientRect();
  return {
    start: boundingClientRect.top,
    end: boundingClientRect.top + boundingClientRect.height
  };
};

const ensureResultVisibleInResultList = (resultElement, listElement) => {
  const resultBoundaries = getElementBoundaries(resultElement);
  const listBoundaries = getElementBoundaries(listElement);

  const resultUnderflowsList = resultBoundaries.start < listBoundaries.start;
  const resultOverflowsList = resultBoundaries.end > listBoundaries.end;

  if (!resultUnderflowsList && !resultOverflowsList) {
    return;
  }

  const overflow = resultUnderflowsList
    ? resultBoundaries.start - listBoundaries.start
    : resultBoundaries.end - listBoundaries.end;

  const currentListScroll = listElement.scrollTop;
  const newListScroll = currentListScroll + overflow;

  listElement.scroll(0, newListScroll);
};

export default ensureResultVisibleInResultList;
