import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const DoneButton = props => (
  <Button variant="outlined" color="primary" {...props}>
    <FormattedMessage id="support.dialog.actions.done" />
  </Button>
);

export default DoneButton;
