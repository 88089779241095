import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const SubtopicIcon = styled('img')(
  {
    marginRight: 16,
    width: 40,
    height: 40,
    [mediaQuery(768)]: {
      display: 'none'
    }
  },
  addHighContrastStyles({
    display: 'none'
  })
);

export default SubtopicIcon;
