import getLanguage from 'lib/utils/getLanguage';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import FlagIcon from '../FlagIcon';
import { FormattedMessage } from 'react-intl';

const LanguagePickerButton = props => {
  const selectedLanguage = getLanguage();
  return (
    <ListItem button {...props}>
      <ListItemIcon>
        <FlagIcon language={selectedLanguage} />
      </ListItemIcon>
      <ListItemText
        primary={
          <FormattedMessage id="header.options_menu.options.language_picker" />
        }
      />
    </ListItem>
  );
};

export default LanguagePickerButton;
