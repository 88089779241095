import PropTypes from 'prop-types';
import ChallengeSearchQueryContext from 'components/ChallengeSearchQueryContext';
import SelectedUserContext from 'lib/contexts/SelectedUserContext';

const GlobalStateProvider = ({
  challengeSearchQuery,
  selectedUser,
  ...props
}) => (
  <ChallengeSearchQueryContext.Provider value={challengeSearchQuery}>
    <SelectedUserContext.Provider value={selectedUser} {...props} />
  </ChallengeSearchQueryContext.Provider>
);

GlobalStateProvider.propTypes = {
  challengeSearchQuery: PropTypes.string.isRequired,
  selectedUser: PropTypes.object
};

export default GlobalStateProvider;
