import styled from '@emotion/styled';
import { ErrorOutline as MuiErrorIcon } from '@material-ui/icons';
import mediaQuery from 'lib/styling/mediaQuery';

const TitleErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  marginRight: 12,
  width: 48,
  height: 48,
  color: theme.palette.danger,
  display: 'none',
  [mediaQuery(540)]: {
    display: 'block'
  },
  [mediaQuery(460)]: {
    width: 36,
    height: 36
  },
  [mediaQuery(400)]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 12,
    width: 48,
    height: 48
  }
}));

export default TitleErrorIcon;
