import { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../UserContext';
import Dialog from 'lib/components/Dialog';
import UserIsExpertSwitch from './UserIsExpertSwitch';
import ExpertisesRequiredNotice from './ExpertisesRequiredNotice';
import ExpertiseManager from './ExpertiseManager';
import UserRetainsCoachFunctionalitySwitch from './UserRetainsCoachFunctionalitySwitch';
import { FormattedMessage } from 'react-intl';

const ExpertiseDialog = ({ onClose, ...props }) => {
  const user = useContext(UserContext);
  return (
    <Dialog onClose={onClose} {...props}>
      <Dialog.Title>
        <FormattedMessage id="user_manager.user_list.user.expertise_manager.title" />
      </Dialog.Title>
      <Dialog.Content>
        <UserIsExpertSwitch />
        {user.isExpert && !user.expertiseKeys && <ExpertisesRequiredNotice />}
        {user.isExpert && (
          <>
            <ExpertiseManager />
            <UserRetainsCoachFunctionalitySwitch />
          </>
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Actions.DoneButton onClick={onClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

ExpertiseDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ExpertiseDialog;
