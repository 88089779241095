import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import { lighten } from 'lib/styling/colors';

const ResponsiveDialogPaper = styled(Paper, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    margin: 24,
    width: '100%',
    maxHeight: 'calc(100% - 48px)',
    overflowX: 'hidden',
    transition: theme.transitions.create(),
    [mediaQuery(420)]: {
      margin: 12,
      maxHeight: 'calc(100% - 24px)'
    }
  }),
  ({ theme, dangerous }) => {
    if (!theme.palette.isHighContrast && dangerous) {
      return {
        borderLeft: `4px solid transparent`,
        borderLeftColor: theme.isDark
          ? theme.palette.danger
          : lighten(theme.palette.danger, 10),
        [mediaQuery(640)]: {
          borderLeft: 'none'
        }
      };
    }
  }
);

ResponsiveDialogPaper.propTypes = {
  dangerous: PropTypes.bool
};

ResponsiveDialogPaper.defaultProps = {
  dangerous: false
};

export default ResponsiveDialogPaper;
