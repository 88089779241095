import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { challengeRef } from 'lib/firebase';
import getChallengeUsers from '../../../../../getChallengeUsers';

const useChallengeUsers = challengeKey => {
  const [loading, challenge] = useFirebaseValue(challengeRef(challengeKey));

  if (loading) {
    return [true, null];
  }

  return [false, getChallengeUsers(challenge)];
};

export default useChallengeUsers;
