import styled from '@emotion/styled';

const PhaseSelectWrapper = styled('section')({
  marginTop: -4,
  marginBottom: 28,
  display: 'flex',
  alignItems: 'center'
});

export default PhaseSelectWrapper;
