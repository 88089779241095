import useCurrentFieldRubricsRef from './useCurrentFieldRubricsRef';
import AddPart from 'pages/EditorPage/RubricsPortfolioTemplatesEditor/PortfolioTemplatesEditor/AddPart';

const AddPartButton = props => {
  const currentFieldRubricsRef = useCurrentFieldRubricsRef();
  const addPartToField = partKey =>
    currentFieldRubricsRef.child(partKey).set(true);

  return (
    <AddPart
      subtopicId="__IGNORE__"
      subtopicGroups={['students']}
      noParentAdd
      noGroups
      onAdd={part => addPartToField(part.id)}
      {...props}
    />
  );
};

export default AddPartButton;
