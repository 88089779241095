import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledContentWrapper = styled('main')(
  ({ theme }) => ({
    position: 'relative',
    paddingLeft: 148,
    paddingRight: 148,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(),
    [mediaQuery(1024)]: {
      paddingLeft: 72,
      paddingRight: 72
    },
    [mediaQuery(850)]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  }),
  ({ wideContent }) => {
    if (wideContent) {
      return {
        padding: '0 !important'
      };
    }
  }
);

StyledContentWrapper.propTypes = {
  wideContent: PropTypes.bool.isRequired
};

export default StyledContentWrapper;
