import PropTypes from 'prop-types';
import UploadDropzone from './UploadDropzone';
import PreviewBox from './PreviewBox';
import PreviewOverlay from './PreviewOverlay';
import UploadLabel from './UploadLabel';
import { useDropzone } from 'react-dropzone';

const UploadBox = ({ preview, onDrop, children, ...props }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop
  });

  return (
    <UploadDropzone {...getRootProps(props)}>
      <input {...getInputProps()} />
      <PreviewBox className="upload-preview-box" preview={preview}>
        <PreviewOverlay />
        <UploadLabel>{children}</UploadLabel>
      </PreviewBox>
    </UploadDropzone>
  );
};

UploadBox.propTypes = {
  preview: PropTypes.string.isRequired,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default UploadBox;
