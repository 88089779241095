import Roles from 'lib/constants/Roles';
import Modes from 'lib/constants/Modes';

const ModesForRole = {
  [Roles.STUDENT]: [Modes.PORTFOLIO],
  [Roles.COACH]: [Modes.PORTFOLIO, Modes.COACH],
  [Roles.EDITOR]: [Modes.PORTFOLIO, Modes.COACH, Modes.EDITOR],
  [Roles.HALF_ADMIN]: [Modes.PORTFOLIO, Modes.COACH, Modes.EDITOR, Modes.ADMIN],
  [Roles.ADMIN]: [
    Modes.PORTFOLIO,
    Modes.COACH,
    Modes.EDITOR,
    Modes.ADMIN,
    Modes.PLATFORM_SETTINGS
  ]
};

export default ModesForRole;
