import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../../../FieldContext';
import { ref } from 'lib/firebase';
import Space from 'lib/components/Space';
import FeedbackItemActionButton from './FeedbackItemActionButton';
import { Edit as EditIcon } from '@material-ui/icons';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const EditFeedbackItem = ({
  feedbackItem,
  setFeedbackItem,
  useInlineRef,
  ...props
}) => {
  const { field, challengeRef } = useContext(FieldContext);
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const saveEdits = newFeedbackItem => {
    setEditPromptOpen(false);

    setFeedbackItem(feedbackItem => ({
      ...feedbackItem,
      content: newFeedbackItem
    }));

    if (useInlineRef) {
      challengeRef
        .child(`coachComments/${field.key}/${feedbackItem.key}/content`)
        .set(newFeedbackItem);

      return;
    }

    ref(`coachComments/${feedbackItem.key}/content`).set(newFeedbackItem);
  };

  return (
    <>
      <Space width="auto" />
      <FeedbackItemActionButton
        onClick={() => setEditPromptOpen(true)}
        {...props}
      >
        <EditIcon fontSize="small" />
      </FeedbackItemActionButton>
      <Prompt
        open={editPromptOpen}
        defaultValue={feedbackItem.content}
        onCancel={() => setEditPromptOpen(false)}
        onOk={saveEdits}
        emptyError="portfolio.challenge.coach_feedback.feedback_empty_error"
        multiline
        persistInput
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={
            <FormattedMessage id="portfolio.challenge.coach_feedback.feedback_item.edit.input_label" />
          }
        />
      </Prompt>
    </>
  );
};

EditFeedbackItem.propTypes = {
  feedbackItem: PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }).isRequired,
  setFeedbackItem: PropTypes.func.isRequired,
  useInlineRef: PropTypes.bool.isRequired
};

export default EditFeedbackItem;
