import PropTypes from 'prop-types';
import AddLink from './AddLink';
import RemoveLink from './RemoveLink';

const LinkControls = ({ editorState, setEditorState, focusOnEditor }) => (
  <>
    <AddLink
      editorState={editorState}
      setEditorState={setEditorState}
      focusOnEditor={focusOnEditor}
    />
    <RemoveLink
      editorState={editorState}
      setEditorState={setEditorState}
      focusOnEditor={focusOnEditor}
    />
  </>
);

LinkControls.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  focusOnEditor: PropTypes.func.isRequired
};

export default LinkControls;
