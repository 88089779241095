import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import useParentRef from './utils/useParentRef';
import useChallengeIsNewlyShared from './utils/useChallengeIsNewlyShared';
import DateField from '../DateField';
import isLater from './utils/isLater';
import Phases from 'lib/constants/Phases';

const StartDate = () => {
  const { challenge, challengeRef, asTask } = useContext(FieldContext);
  const parentRef = useParentRef(challenge, asTask);
  const challengeIsNewlyShared = useChallengeIsNewlyShared(challenge);

  return (
    <DateField
      shouldDisableDate={date =>
        !!challenge.endDate && isLater(date, new Date(challenge.endDate))
      }
      afterChange={() => {
        if (
          (!asTask && challengeIsNewlyShared) ||
          challenge.phase !== Phases.TODO
        ) {
          return;
        }

        challengeRef.child('phase').set(Phases.PROGRESS);

        parentRef.child(challenge.phase).child(challenge.key).remove();
        parentRef.child(Phases.PROGRESS).child(challenge.key).set(true);
      }}
      clearable={false}
    />
  );
};

export default StartDate;
