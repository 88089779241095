import ActionButton from './ActionButton';
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons';

const CopyButton = props => (
  <ActionButton
    icon={<CopyIcon />}
    labelId="portfolio.challenge.drawer.copy.button"
    {...props}
  />
);

export default CopyButton;
