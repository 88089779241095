import { useState } from 'react';
import PropTypes from 'prop-types';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import CloseChallengeContext from './CloseChallengeContext';
import MobileChallengeActions from './MobileChallengeActions';
import EditButton from './EditButton';
import ChallengeLinkSharing from './ChallengeLinkSharing';
import ChallengeNameEditor from './ChallengeNameEditor';
import DeleteChallenge from './DeleteChallenge';
import CopyChallenge from './CopyChallenge';
import HeaderWrapper from './HeaderWrapper';
import InnerHeaderWrapper from './InnerHeaderWrapper';
import ChallengeName from './ChallengeName';
import TasksButton from './TasksButton';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const ChallengeHeader = ({
  challenge,
  parentKey,
  phase,
  onTasksOpen,
  challengeRef,
  deleteChallenge,
  onCloseChallenge,
  asTask,
  ...props
}) => {
  const [nameEditing, setNameEditing] = useState(false);

  if (nameEditing) {
    return (
      <ChallengeNameEditor
        challenge={challenge}
        challengeRef={challengeRef}
        onCancel={() => setNameEditing(false)}
        asTask={asTask}
      />
    );
  }

  return (
    <HeaderWrapper {...props}>
      <InnerHeaderWrapper>
        <ChallengeName>{challenge.name}</ChallengeName>
        <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
          <CloseChallengeContext.Provider value={onCloseChallenge}>
            <MobileChallengeActions
              challenge={challenge}
              parentKey={parentKey}
              deleteChallenge={deleteChallenge}
              onEdit={() => setNameEditing(true)}
              asTask={asTask}
            />
            <EditButton onClick={() => setNameEditing(true)} />
            {!asTask && parentKey && (
              <CopyChallenge challenge={challenge} rowKey={parentKey} />
            )}
            <DeleteChallenge
              challenge={challenge}
              parentKey={parentKey}
              deleteChallenge={deleteChallenge}
              asTask={asTask}
            />
            <ChallengeLinkSharing
              challengeKey={challenge.key}
              parentKey={parentKey}
              asTask={asTask}
            />
          </CloseChallengeContext.Provider>
        </RestrictedToPortfolioMode>
      </InnerHeaderWrapper>
      {!asTask && <TasksButton challenge={challenge} onClick={onTasksOpen} />}
    </HeaderWrapper>
  );
};

ChallengeHeader.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  parentKey: PropTypes.string,
  onTasksOpen: PropTypes.func,
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  deleteChallenge: PropTypes.func.isRequired,
  onCloseChallenge: PropTypes.func.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default ChallengeHeader;
