import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import { userRef } from 'lib/firebase';

const useRowMoveHandlers = (
  rowKey,
  uiPos,
  previousRowKey,
  previousRowUiPos,
  nextRowKey,
  nextRowUiPos
) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const setRowUiPos = (key, uiPos) =>
    userRef(portfolioUserUsername).child(`rows/${key}`).set(uiPos);

  const swapPositionWith = (rowKeyToSwapWith, uiPosToSwapWith) => {
    setRowUiPos(rowKeyToSwapWith, uiPos);
    setRowUiPos(rowKey, uiPosToSwapWith);
  };

  const moveUp = () => swapPositionWith(previousRowKey, previousRowUiPos);
  const moveDown = () => swapPositionWith(nextRowKey, nextRowUiPos);

  return [moveUp, moveDown];
};

export default useRowMoveHandlers;
