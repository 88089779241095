import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';
import { rgba } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';
import mediaQuery from 'lib/styling/mediaQuery';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const CardWrapper = styled(Paper, {
  shouldForwardProp: isPropValid
})(
  ({ theme, contentVisibilityCompensationDistance }) => ({
    position: 'absolute',
    top: 0,
    left: `${contentVisibilityCompensationDistance}%`,
    width: 240,
    maxWidth: 'calc(20vw - 12px)',
    boxShadow: `0 6px 54px ${rgba(common.black, 0.34)}`,
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    transitionProperty: 'transform',
    [mediaQuery(700)]: {
      display: 'none'
    },
    // These styles will be countered by the appearance animation, which is
    // managed by the wrapper element
    transform: 'translateX(calc(-100% - 24px))',
    marginRight: 12,
    padding: 0,
    height: 0,
    opacity: 0,
    zIndex: -1
  }),
  addBorderInHighContrastMode
);

CardWrapper.propTypes = {
  contentVisibilityCompensationDistance: PropTypes.number.isRequired
};

export default CardWrapper;
