import PropTypes from 'prop-types';
import useCopyPartShallow from './useCopyPartShallow';
import copyLevelsToPart from './copyLevelsToPart';
import ActionButton from '../../PortfolioTemplatesEditorActionButtons/ActionButton';
import { ControlPointDuplicate as CopyIcon } from '@material-ui/icons';

const CopyPart = ({ part, levels, highestUiPos, noParentAdd, ...props }) => {
  const copyPartShallow = useCopyPartShallow();

  const copyPartWithLevels = () => {
    const partCopyId = copyPartShallow(part, highestUiPos, noParentAdd);
    copyLevelsToPart(levels, partCopyId);
  };

  return (
    <ActionButton onClick={copyPartWithLevels} {...props}>
      <CopyIcon />
    </ActionButton>
  );
};

CopyPart.propTypes = {
  part: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  highestUiPos: PropTypes.number.isRequired,
  noParentAdd: PropTypes.bool.isRequired
};

export default CopyPart;
