import PropTypes from 'prop-types';
import StyledHeader from './StyledHeader';
import BackButton from './BackButton';
import StyledTitle from './StyledTitle';

const DialogTitleWithBackButton = ({
  onBackButtonClick,
  children,
  ...props
}) => (
  <StyledHeader {...props}>
    <BackButton onClick={onBackButtonClick} />
    <StyledTitle>{children}</StyledTitle>
  </StyledHeader>
);

DialogTitleWithBackButton.propTypes = {
  onBackButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default DialogTitleWithBackButton;
