import styled from '@emotion/styled';
import PropTypes from 'prop-types';

const RadioLabel = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    transition: theme.transitions.create()
  }),
  ({ selected }) => {
    if (!selected) {
      return {
        opacity: 0.5
      };
    }
  }
);

RadioLabel.propTypes = {
  selected: PropTypes.bool.isRequired
};

export default RadioLabel;
