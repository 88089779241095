import { useContext, useState } from 'react';
import UserContext from '../../../../../UserContext';
import App from 'lib/App';
import { ref, userRef } from 'lib/firebase';
import FormWrapper from './FormWrapper';
import { TextField, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const AddExpertiseForm = props => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const [expertiseName, setExpertiseName] = useState('');

  const saveExpertise = e => {
    e.preventDefault();

    const { key: newExpertiseKey } = ref('expertises').push({
      name: expertiseName.trim(),
      user: userUsername
    });

    userRef(userUsername).child(`expertiseKeys/${newExpertiseKey}`).set(true);

    setExpertiseName('');
  };

  const intl = useIntl();

  return (
    <FormWrapper {...props}>
      <TextField
        placeholder={intl.formatMessage({
          id:
            'user_manager.user_list.user.expertise_manager.expertise_items.add_item.name_field.placeholder'
        })}
        value={expertiseName}
        onChange={e => setExpertiseName(e.target.value)}
        size="small"
        autoFocus
        fullWidth
      />
      <IconButton
        type="submit"
        onClick={saveExpertise}
        disabled={!expertiseName.trim()}
      >
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

export default AddExpertiseForm;
