import styled from '@emotion/styled';

const ChallengeWrapper = styled('div')({
  paddingTop: 18,
  paddingBottom: 18,
  paddingLeft: 20,
  paddingRight: 20,
  display: 'flex',
  flexWrap: 'wrap'
});

export default ChallengeWrapper;
