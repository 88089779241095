import { useState } from 'react';
import PropTypes from 'prop-types';
import useCreateRow from './useCreateRow';
import CreateRowButton from './CreateRowButton';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreateRow = ({ highestRowUiPos, ...props }) => {
  const [rowPromptOpen, setRowPromptOpen] = useState(false);

  const createRow = useCreateRow();

  const saveNewRow = name => {
    setRowPromptOpen(false);
    createRow(name, highestRowUiPos);
  };

  return (
    <>
      <CreateRowButton onClick={() => setRowPromptOpen(true)} {...props} />
      <Prompt
        open={rowPromptOpen}
        onOk={saveNewRow}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={() => setRowPromptOpen(false)}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.create_row.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessageWithChallengeNaming id="portfolio.create_row.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Prompt.TextField
          label={<FormattedMessage id="portfolio.create_row.input_label" />}
        />
      </Prompt>
    </>
  );
};

CreateRow.propTypes = {
  highestRowUiPos: PropTypes.number.isRequired
};

export default CreateRow;
