import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import LearningGoalsAppBar from 'components/LearningGoals/LearningGoalsAppBar';
import { ArrowBack as BackIcon } from '@material-ui/icons';
import NoItemsMessage from 'components/LearningGoals/NoItemsMessage';
import TileGrid from 'components/LearningGoals/TileGrid';
import Part from './Part';
import { FormattedMessage } from 'react-intl';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const Parts = ({ subtopic, onClose, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  const allParts = portfolioTemplates?.parts;
  const desiredPartsKeys = safeKeys(subtopic.parts);
  const parts = desiredPartsKeys
    .map(partKey => allParts?.[partKey])
    .filter(part => !!part);

  return (
    <>
      <LearningGoalsAppBar>
        <LearningGoalsAppBar.IconButton onClick={onClose}>
          <BackIcon />
        </LearningGoalsAppBar.IconButton>
        <LearningGoalsAppBar.Title>
          <FormattedMessage
            id="editor.model_editor.field_editor.learning_goals_editor.manager.manage_parts_title"
            values={{
              subtopicName: subtopic.name
            }}
          />
        </LearningGoalsAppBar.Title>
      </LearningGoalsAppBar>
      {parts.length === 0 ? (
        <NoItemsMessage accountForHeader>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.no_parts" />
        </NoItemsMessage>
      ) : (
        <TileGrid {...props}>
          {parts.map(part => (
            <Part part={part} subtopicKey={subtopic.key} key={part.id} />
          ))}
        </TileGrid>
      )}
    </>
  );
};

Parts.propTypes = {
  subtopic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    parts: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default Parts;
