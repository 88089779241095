import { Children, cloneElement } from 'react';
import PromptTextField from './PromptTextField';

const setupChildTextField = (
  children,
  { value, onChange, error, inputRef, multiline }
) =>
  Children.map(children, child => {
    if (child.type !== PromptTextField) {
      return child;
    }

    return cloneElement(child, {
      value,
      onChange,
      helperText: error,
      error: !!error,
      inputRef,
      multiline
    });
  });

export default setupChildTextField;
