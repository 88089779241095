import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const StyledWrapper = styled(motion.section)(({ theme }) => ({
  borderBottom: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['border-bottom']),
  display: 'block',
  overflow: 'hidden'
}));

const SectionWrapper = props => (
  <StyledWrapper
    initial={{
      height: 0,
      opacity: 0
    }}
    animate={{
      height: 'auto',
      opacity: 1
    }}
    exit={{
      height: 0,
      opacity: 0
    }}
    {...props}
  />
);

export default SectionWrapper;
