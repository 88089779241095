import { useContext } from 'react';
import PortfolioContext from 'lib/contexts/PortfolioContext';

const convertChallengeKeyToUrl = (challengeKey, asTask) => {
  const openChallengeOrOpenTask = asTask ? 'openTask' : 'openChallenge';
  return `${openChallengeOrOpenTask}/${challengeKey}`;
};

const useAttachedChallenges = levelKey => {
  const portfolio = useContext(PortfolioContext);

  if (!portfolio?.challengeProof?.[levelKey]) {
    return null;
  }

  const attachedChallenges = portfolio.challengeProof[levelKey];

  const challengeUrlObject = Object.entries(attachedChallenges).reduce(
    (challengeUrlObject, [challengeKey, { asTask }]) => {
      const challengeUrl = convertChallengeKeyToUrl(challengeKey, asTask);

      challengeUrlObject[challengeKey] = {
        url: challengeUrl,
        asTask
      };

      return challengeUrlObject;
    },
    {}
  );

  return challengeUrlObject;
};

export default useAttachedChallenges;
