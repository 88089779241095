import PropTypes from 'prop-types';
import ColorPickerWrapper from './ColorPickerWrapper';
import ColorPickerTitle from './ColorPickerTitle';
import ColorBoxes from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/ColorBoxes';
import CustomColorTextField from './CustomColorTextField';
import { FormattedMessage } from 'react-intl';

const ColorPicker = ({ selectedColor, onSelectColor, ...props }) => (
  <ColorPickerWrapper>
    <ColorPickerTitle>
      <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.prompt_with_colors.color_picker.title" />
    </ColorPickerTitle>
    <ColorBoxes selectedColor={selectedColor} onSelectColor={onSelectColor} />
    <CustomColorTextField
      selectedColor={selectedColor}
      onSelectColor={onSelectColor}
      noBackground
    />
  </ColorPickerWrapper>
);

ColorPicker.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired
};

export default ColorPicker;
