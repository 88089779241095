import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../SloContext';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import InnerDescriptionWrapper from '../InnerDescriptionWrapper';
import DescriptionTextField from './DescriptionTextField';
import { Button } from '@material-ui/core';
import { Cancel as CancelIcon, Check as CheckIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const DescriptionEditor = ({ onClose, ...props }) => {
  const slo = useContext(SloContext);
  const originalSloDescription = slo.description || '';

  const [sloDescription, setSloDescription] = useSyncedState(
    originalSloDescription
  );

  const cancelEdits = () => {
    setSloDescription(originalSloDescription);
    onClose();
  };

  const saveEdits = () => {
    ref(`slos/${slo.key}/description`).set(sloDescription.trim());
    onClose();
  };

  return (
    <InnerDescriptionWrapper>
      <DescriptionTextField
        value={sloDescription}
        onChange={e => setSloDescription(e.target.value)}
      />
      <Button
        size="small"
        onClick={cancelEdits}
        startIcon={<CancelIcon />}
        style={{
          marginLeft: 'auto',
          marginRight: 12
        }}
      >
        <FormattedMessage id="dialogs.actions.cancel" />
      </Button>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={saveEdits}
        disabled={sloDescription.trim() === originalSloDescription}
        startIcon={<CheckIcon />}
        disableElevation
      >
        <FormattedMessage id="dialogs.actions.save" />
      </Button>
    </InnerDescriptionWrapper>
  );
};

DescriptionEditor.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default DescriptionEditor;
