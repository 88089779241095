import styled from '@emotion/styled';

const ErrorText = styled('p')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 12,
  color: theme.palette.text.secondary,
  maxWidth: 500
}));

export default ErrorText;
