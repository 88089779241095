import styled from '@emotion/styled';
import red from '@material-ui/core/colors/red';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const PhaseName = styled('h3')(
  ({ theme }) => ({
    marginBottom: 18,
    color: theme.isDark ? red[200] : red[700],
    fontWeight: 500,
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 0.75
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary
  }))
);

export default PhaseName;
