import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import StyledButton from './StyledButton';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const InnerLinkButton = props => {
  const { phaseContentGroup } = useContext(FieldContext);
  return (
    <StyledButton
      component="a"
      target="_blank"
      fullWidth={phaseContentGroup === PhaseContentGroups.RIGHT_CONTENT}
      {...props}
    ></StyledButton>
  );
};

export default InnerLinkButton;
