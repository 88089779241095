import styled from '@emotion/styled';

const OptionWrapper = styled('section')({
  marginTop: -12,
  marginBottom: -12,
  display: 'flex',
  alignItems: 'center'
});

export default OptionWrapper;
