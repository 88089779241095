import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import DateField from '../DateField';
import isLater from './utils/isLater';

const PlannedStartDate = () => {
  const { challenge } = useContext(FieldContext);
  return (
    <DateField
      shouldDisableDate={date =>
        !!challenge.plannedEndDate &&
        isLater(date, new Date(challenge.plannedEndDate))
      }
    />
  );
};

export default PlannedStartDate;
