import { FormHelperText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Space from 'lib/components/Space';

const ExpertisesRequiredNotice = props => (
  <>
    <FormHelperText {...props}>
      <FormattedMessage id="user_manager.user_list.user.expertise_manager.expertises_required_notice" />
    </FormHelperText>
    <Space height={16} />
  </>
);

export default ExpertisesRequiredNotice;
