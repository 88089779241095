import PropTypes from 'prop-types';
import Groups from 'lib/constants/Groups';
import SwitcherWrapper from './SwitcherWrapper';
import StyledGroup from './StyledGroup';
import DraftsButton from './DraftsButton';
import { FormattedMessage } from 'react-intl';

const VisibleGroupSwitcher = ({
  visibleGroup,
  onChangeVisibleGroup,
  ...props
}) => {
  const updateVisibleGroup = newVisibleGroup => {
    if (newVisibleGroup !== visibleGroup) {
      onChangeVisibleGroup(newVisibleGroup);
    }
  };

  const shownGroups = [Groups.ALL, Groups.STUDENTS, Groups.COACHES];

  return (
    <SwitcherWrapper {...props}>
      {shownGroups.map(group => (
        <StyledGroup
          selected={visibleGroup === group}
          onClick={() => updateVisibleGroup(group)}
          key={group}
        >
          <FormattedMessage id={`group_names.${group}`} />
        </StyledGroup>
      ))}
      <DraftsButton
        selected={visibleGroup === Groups.DRAFTS}
        onClick={() => updateVisibleGroup(Groups.DRAFTS)}
      />
    </SwitcherWrapper>
  );
};

VisibleGroupSwitcher.propTypes = {
  visibleGroup: PropTypes.oneOf(Object.values(Groups)).isRequired,
  onChangeVisibleGroup: PropTypes.func.isRequired
};

export default VisibleGroupSwitcher;
