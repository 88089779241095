import { ref } from 'lib/firebase';
import arrToObj from 'lib/utils/arrToObj';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

export const updateUserGroups = asManagedGroups => (groupKeys, username) => {
  const groups = arrToObj(groupKeys, true);
  const groupsPath = asManagedGroups ? 'managedGroups' : 'groups';
  return ref(`users/${username}/${groupsPath}`).set(groups);
};

export const addUserToGroups = (asManagedGroups, groups) => (
  selectedGroupKeys,
  username
) => {
  const unselectedGroupKeys = safeKeys(groups).filter(
    groupKey => !selectedGroupKeys.includes(groupKey)
  );
  return Promise.all([
    ...selectedGroupKeys.map(groupKey =>
      groupUserRef(asManagedGroups)(groupKey, username).set(true)
    ),
    ...unselectedGroupKeys.map(groupKey =>
      groupUserRef(asManagedGroups)(groupKey, username).remove()
    )
  ]);
};

const groupUserRef = asManagedGroups => (groupKey, username) => {
  const usersPath = asManagedGroups ? 'managers' : 'users';
  return ref(`groups/${groupKey}/${usersPath}/${username}`);
};
