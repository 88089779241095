import { createContext, useContext } from 'react';
import FieldContext from '../../../FieldContext';
import App from 'lib/App';

const LearningGoalsModelContext = createContext(null);

const AutomatedLearningGoalsModelContextProvider = props => {
  const { field } = useContext(FieldContext);

  const learningGoalsModelKey = field.reusedLearningGoals || field.key;
  const { learningGoals } = App.useModels();
  const learningGoalsModel = learningGoals?.[learningGoalsModelKey] || {};

  learningGoalsModel.key = learningGoalsModelKey;

  return (
    <LearningGoalsModelContext.Provider value={learningGoalsModel} {...props} />
  );
};

LearningGoalsModelContext.AutomatedProvider = AutomatedLearningGoalsModelContextProvider;

export default LearningGoalsModelContext;
