import { useState } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import UserSelectFieldWrapper from '../User/UserSelectFieldWrapper';
import GroupSelectField from '../User/GroupSelect/GroupSelectField';
import { updateUserGroups, addUserToGroups } from '../utils/groupUsers';

const MultipleUsersGroupSelect = ({ usernames, asManagedGroups, ...props }) => {
  const [, groups] = useFirebaseValue(ref('groups'));
  const [selectedGroups, setSelectedGroups] = useState([]);

  const saveGroupChanges = () => usernames.map(updateGroupsForUser);

  const updateGroupsForUser = username => {
    updateUserGroups(asManagedGroups)(selectedGroups, username);
    addUserToGroups(asManagedGroups, groups)(selectedGroups, username);
  };

  return (
    <UserSelectFieldWrapper withMarginRight {...props}>
      <GroupSelectField
        value={selectedGroups}
        onChange={e => setSelectedGroups(e.target.value)}
        groups={groups}
        asManagedGroups={asManagedGroups}
        MenuProps={{
          onExit: saveGroupChanges
        }}
      />
    </UserSelectFieldWrapper>
  );
};

MultipleUsersGroupSelect.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired,
  asManagedGroups: PropTypes.bool
};

MultipleUsersGroupSelect.defaultProps = {
  asManagedGroups: false
};

export default MultipleUsersGroupSelect;
