import Modes from 'lib/constants/Modes';

const modeUrls = {
  [Modes.PORTFOLIO]: '/',
  [Modes.COACH]: '/coach',
  [Modes.EDITOR]: '/editor',
  [Modes.ADMIN]: '/admin',
  [Modes.PLATFORM_SETTINGS]: '/platform-settings'
};

export default modeUrls;
