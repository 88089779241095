import styled from '@emotion/styled';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const EmailButton = styled('a')(
  ({ theme }) => ({
    padding: '36px 12px',
    width: '100%',
    textAlign: 'center',
    fontFamily: 'CircularStd',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
    display: 'inline-block',
    borderRadius: 4,
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    ':hover, :focus': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.selected,
      transform: 'scale(1.05)'
    }
  }),
  addBorderInHighContrastMode
);

export default EmailButton;
