import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useUsers from './useUsers';
import useSelectedUsers from './useSelectedUsers';
import App from 'lib/App';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import UserToolbar from './UserToolbar';
import UserListWrapper from './UserListWrapper';
import NoSearchResults from '../NoSearchResults';
import User from './User';
import { FormattedMessage } from 'react-intl';
import UserPagination from './UserPagination';
import Space from 'lib/components/Space';

const USERS_PER_PAGE = 10;

const UserList = ({ searchQuery, ...props }) => {
  const [loading, users, usersObject, markUserAsDeleted] = useUsers(
    searchQuery
  );
  const {
    selectedUsers,
    setUserSelected,
    clearSelectedUsers
  } = useSelectedUsers();

  const getUsernameFromEmail = App.authentication.useGetUsernameFromEmail();

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleSelectUser = (username, e) => {
    const userBecomesSelected = e.target.checked;
    setUserSelected(username, userBecomesSelected);
  };

  if (loading) {
    return <LoadingSpinner margin={24} centered />;
  }

  const pageCount = Math.ceil(users.length / USERS_PER_PAGE);
  const safePage = Math.min(page, pageCount);

  const shownUsers = users.slice(
    (safePage - 1) * USERS_PER_PAGE,
    safePage * USERS_PER_PAGE
  );

  return (
    <>
      <UserToolbar
        users={usersObject}
        selectedUsers={selectedUsers}
        onSelectedUsersClear={clearSelectedUsers}
        onMarkUserAsDeleted={markUserAsDeleted}
      />
      <UserListWrapper {...props}>
        {users.length === 0 ? (
          <NoSearchResults>
            <FormattedMessage id="user_manager.user_list.no_search_results" />
          </NoSearchResults>
        ) : (
          shownUsers.map(user => (
            <User
              user={user}
              selected={!!selectedUsers[getUsernameFromEmail(user.email)]}
              onSelectUser={e =>
                handleSelectUser(getUsernameFromEmail(user.email), e)
              }
              onMarkAsDeleted={() =>
                markUserAsDeleted(getUsernameFromEmail(user.email))
              }
              key={user.email}
            />
          ))
        )}
      </UserListWrapper>
      {pageCount > 1 && (
        <UserPagination
          count={pageCount}
          page={safePage}
          onChange={page => setPage(page)}
        />
      )}
      <Space height={36} />
    </>
  );
};

UserList.propTypes = {
  searchQuery: PropTypes.string
};

UserList.defaultProps = {
  searchQuery: ''
};

export default UserList;
