const selectLinkInputContent = () => {
  setTimeout(() => {
    const input = document.getElementById('shareable-link');
    if (input) {
      input.select();
    }
  }, 100);
};

export default selectLinkInputContent;
