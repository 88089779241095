import { useState } from 'react';
import PropTypes from 'prop-types';
import OptionWrapper from './OptionWrapper';
import OptionNameEditor from './OptionNameEditor';
import OptionName from './OptionName';
import EditButton from './EditButton';
import DeleteOption from './DeleteOption';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const OptionEditor = ({ option, optionRef, ...props }) => {
  const [nameEditing, setNameEditing] = useState(false);
  return (
    <OptionWrapper {...props}>
      {nameEditing ? (
        <OptionNameEditor
          option={option}
          optionRef={optionRef}
          onClose={() => setNameEditing(false)}
        />
      ) : (
        <>
          <OptionName>{option.name}</OptionName>
          <EditButton onClick={() => setNameEditing(true)} />
        </>
      )}
      <DeleteOption optionRef={optionRef} />
    </OptionWrapper>
  );
};

OptionEditor.propTypes = {
  option: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  optionRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default OptionEditor;
