import { ref, value } from 'lib/firebase';
import { deleteLoadedPart } from './deletePart';
import detachPart from './detachPart';

const deleteOrDetachPart = subtopicKey => async id => {
  const partRef = ref(`portfolioTemplates/parts/${id}`);
  const part = await value(partRef);

  if (!part) {
    return;
  }

  if (part.managedFromLearningGoals) {
    return detachPart(part, subtopicKey);
  }

  return deleteLoadedPart(part);
};

export default deleteOrDetachPart;
