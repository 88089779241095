import { ref, value } from 'lib/firebase';
import deleteChildren from './deleteChildren';
import deleteOrDetachPart from './deleteOrDetachPart';

const deleteSubtopic = async id => {
  const subtopicRef = ref(`portfolioTemplates/subtopics/${id}`);
  const subtopic = await value(subtopicRef);

  if (!subtopic) {
    return;
  }

  deleteChildren(subtopic.parts, deleteOrDetachPart(subtopic.id));
  deleteSubtopicFromTopic(id, subtopic.topicId);
  subtopicRef.remove();
};

const deleteSubtopicFromTopic = (id, topicId) =>
  ref(`portfolioTemplates/topics/${topicId}/subtopics/${id}`).remove();

export default deleteSubtopic;
