import { useContext } from 'react';
import VisiblePhaseContext from 'components/ChallengePortfolio/VisiblePhaseContext';
import Phases from 'lib/constants/Phases';

const usePhaseSwitching = inTaskBoard => {
  const {
    visibleChallengePhase,
    setVisibleChallengePhase,
    visibleTaskPhase,
    setVisibleTaskPhase
  } = useContext(VisiblePhaseContext);

  const visiblePhase = inTaskBoard ? visibleTaskPhase : visibleChallengePhase;
  const setVisiblePhase = inTaskBoard
    ? setVisibleTaskPhase
    : setVisibleChallengePhase;

  const switchToPreviousPhase = () => {
    const previousPhase =
      visiblePhase === Phases.PROGRESS ? Phases.TODO : Phases.PROGRESS;
    setVisiblePhase(previousPhase);
  };

  const switchToNextPhase = () => {
    const nextPhase =
      visiblePhase === Phases.TODO ? Phases.PROGRESS : Phases.DONE;
    setVisiblePhase(nextPhase);
  };

  return [visiblePhase, switchToPreviousPhase, switchToNextPhase];
};

export default usePhaseSwitching;
