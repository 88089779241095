import { useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import Dialog from 'lib/components/Dialog';
import SettingsCheckbox from './SettingsCheckbox';
import SettingsDialogActions from './SettingsDialogActions';
import { FormattedMessage } from 'react-intl';

const SettingsDialog = ({ onClose, ...props }) => {
  const readAppContentSetting = App.instanceConfig.useReadContentSetting();

  const [useReversedSliders, setUseReversedSliders] = useState(
    readAppContentSetting('useReversedSliders')
  );
  const [enableUserSwitcherArrows, setEnableUserSwitcherArrows] = useState(
    readAppContentSetting('enableUserSwitcherArrows')
  );
  const [useProfilePictures, setUseProfilePictures] = useState(
    readAppContentSetting('useProfilePictures')
  );
  const [enableExpertSystem, setEnableExpertSystem] = useState(
    readAppContentSetting('enableExpertSystem')
  );
  const [
    enableAggressiveRubricCollapsing,
    setEnableAggressiveRubricCollapsing
  ] = useState(readAppContentSetting('enableAggressiveRubricCollapsing'));

  const settingsHaveChanged =
    useReversedSliders !== readAppContentSetting('useReversedSliders') ||
    enableUserSwitcherArrows !==
      readAppContentSetting('enableUserSwitcherArrows') ||
    useProfilePictures !== readAppContentSetting('useProfilePictures') ||
    enableExpertSystem !== readAppContentSetting('enableExpertSystem') ||
    enableAggressiveRubricCollapsing !==
      readAppContentSetting('enableAggressiveRubricCollapsing');

  const saveChanges = async () => {
    onClose();

    await ref('instanceConfig/contentSettings').update({
      useReversedSliders,
      enableUserSwitcherArrows,
      useProfilePictures,
      enableExpertSystem,
      enableAggressiveRubricCollapsing
    });

    window.location.reload();
  };

  return (
    <Dialog
      onClose={onClose}
      onEnterClose={saveChanges}
      dangerous={settingsHaveChanged}
      {...props}
    >
      <Dialog.Title>
        <FormattedMessage id="platform_settings.content_settings.title" />
      </Dialog.Title>
      <Dialog.Content>
        <Dialog.ContentText>
          <FormattedMessage id="platform_settings.content_settings.text" />
        </Dialog.ContentText>
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.use_reversed_sliders.label"
          checked={useReversedSliders}
          onChange={e => setUseReversedSliders(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.enable_user_switcher_arrows.label"
          checked={enableUserSwitcherArrows}
          onChange={e => setEnableUserSwitcherArrows(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.use_profile_pictures.label"
          checked={useProfilePictures}
          onChange={e => setUseProfilePictures(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.enable_expert_system.label"
          checked={enableExpertSystem}
          onChange={e => setEnableExpertSystem(e.target.checked)}
        />
        <SettingsCheckbox
          label="platform_settings.content_settings.checkboxes.enable_aggressive_rubric_collapsing"
          checked={enableAggressiveRubricCollapsing}
          onChange={e => setEnableAggressiveRubricCollapsing(e.target.checked)}
        />
      </Dialog.Content>
      <SettingsDialogActions
        onClose={onClose}
        onSave={saveChanges}
        settingsHaveChanged={settingsHaveChanged}
      />
    </Dialog>
  );
};

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SettingsDialog;
