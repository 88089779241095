import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const DialogActionsWrapper = styled('footer')(({ addTopMargin }) => ({
  paddingTop: 8,
  paddingBottom: 16,
  paddingLeft: 24,
  paddingRight: 24,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [mediaQuery(640)]: {
    paddingTop: addTopMargin ? 32 : 8,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'initial'
  }
}));

DialogActionsWrapper.propTypes = {
  addTopMargin: PropTypes.bool
};

DialogActionsWrapper.defaultProps = {
  addTopMargin: false
};

export default DialogActionsWrapper;
