/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import DialogManagerContext from 'lib/contexts/DialogManagerContext';
import ResponsiveDialog from './ResponsiveDialog';
import BackdropWithDesaturation from './BackdropWithDesaturation';
import {
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import DialogActions from './DialogActions';
import DialogSteps from './DialogSteps';

const Dialog = ({ open, ...props }) => {
  const [dialogKey] = useState(`dialog-${Math.random()}`);
  const { markDialogAsOpen, markDialogAsClosed } = useContext(
    DialogManagerContext
  );

  useEffect(() => {
    if (open) {
      markDialogAsOpen(dialogKey);
      return;
    }

    markDialogAsClosed(dialogKey);
  }, [open]);

  return <ResponsiveDialog open={open} {...props} />;
};

Dialog.Backdrop = BackdropWithDesaturation;
Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.ContentText = DialogContentText;
Dialog.Actions = DialogActions;
Dialog.Steps = DialogSteps;

export default Dialog;
