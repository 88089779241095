import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledIcon = styled('img')(({ theme, reversed, noTransition }) => ({
  position: 'absolute',
  left: reversed ? 0 : 'unset',
  right: reversed ? 'unset' : 0,
  transform: reversed ? 'translateX(-50%)' : 'translateX(50%)',
  width: 50,
  height: 50,
  transition: noTransition ? 'none' : theme.transitions.create(),
  display: 'block',
  zIndex: 3,
  userDrag: 'none',
  userSelect: 'none'
}));

StyledIcon.propTypes = {
  reversed: PropTypes.bool.isRequired,
  noTransition: PropTypes.bool.isRequired
};

export default StyledIcon;
