import App from 'lib/App';
import useExistingNewlySharedChallengesKeys from './useExistingNewlySharedChallengesKeys';
import useContinuousUnavailableNewlySharedChallengesCleanUp from './useContinuousUnavailableNewlySharedChallengesCleanUp';
import { AnimatePresence } from 'framer-motion';
import SectionWrapper from './SectionWrapper';
import SectionTitle from './SectionTitle';
import ChallengeWrapper from './ChallengeWrapper';
import Challenge from '../Challenge';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const NewlySharedChallenges = props => {
  const currentUser = App.authentication.useCurrentUser();
  const [
    existingNewlySharedChallengesKeysLoading,
    newlySharedChallenges,
    unavailableChallengesKeys
  ] = useExistingNewlySharedChallengesKeys(currentUser);

  useContinuousUnavailableNewlySharedChallengesCleanUp(
    unavailableChallengesKeys
  );

  const canDisplayNewlySharedChallenges =
    !!currentUser &&
    !existingNewlySharedChallengesKeysLoading &&
    newlySharedChallenges.length > 0;

  return (
    <AnimatePresence>
      {canDisplayNewlySharedChallenges ? (
        <SectionWrapper {...props} key="section-wrapper">
          <SectionTitle>
            {newlySharedChallenges.length === 1 ? (
              <FormattedMessageWithChallengeNaming id="newly_shared_challenge.title.singular" />
            ) : (
              <FormattedMessageWithChallengeNaming
                id="newly_shared_challenge.title.plural"
                values={{
                  challengeCount: newlySharedChallenges.length
                }}
              />
            )}
          </SectionTitle>
          <ChallengeWrapper>
            {newlySharedChallenges.map(challengeKey => (
              <Challenge
                challengeKey={challengeKey}
                isNewlyShared
                key={challengeKey}
              />
            ))}
          </ChallengeWrapper>
        </SectionWrapper>
      ) : null}
    </AnimatePresence>
  );
};

export default NewlySharedChallenges;
