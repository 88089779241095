import { ref } from 'lib/firebase';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';
import useSyncedState from 'lib/utils/useSyncedState';
import matchesSearch from 'lib/utils/matchesSearch';
import sortByRole from './sortByRole';

const useUsers = searchQuery => {
  const [loading, usersWithoutDeletionMarkings] = useUnsyncedFirebaseValue(
    ref('users')
  );
  const [users, setUsers] = useSyncedState(usersWithoutDeletionMarkings);

  if (loading || !usersWithoutDeletionMarkings) {
    return [loading, [], true, null];
  }

  const usersAsArray = Object.values(users)
    .filter(user => !!user)
    .sort(sortByRole);
  const usersWithSearchMatching = usersAsArray.filter(user =>
    matchesSearch(user.name, searchQuery || '')
  );

  const markUserAsDeleted = username =>
    setUsers(users => ({
      ...users,
      [username]: null
    }));

  return [loading, usersWithSearchMatching, users, markUserAsDeleted];
};

export default useUsers;
