import useHighContrastModeEnabled from 'lib/utils/useHighContrastModeEnabled';
import { FormControlLabel, Switch } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const HighContrastSwitch = props => {
  const [
    highContrastModeEnabled,
    setHighContrastModeEnabled
  ] = useHighContrastModeEnabled();

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={highContrastModeEnabled}
          onChange={e => setHighContrastModeEnabled(e.target.checked)}
        />
      }
      label={
        <FormattedMessage id="header.options_menu.display_settings.enable_high_contrast_mode" />
      }
      {...props}
    />
  );
};

export default HighContrastSwitch;
