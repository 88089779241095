import { darken, lighten } from 'lib/styling/colors';

const LIGHT_CONTRAST_TEXT_VALUE = '#fff';

const getTileHoverColor = (hexTileColor, theme) => {
  if (!hexTileColor) {
    return null;
  }

  if (hexTileColor === '#000000') {
    return '#212121';
  }

  const tileIsDark =
    theme.palette.getContrastText(hexTileColor) === LIGHT_CONTRAST_TEXT_VALUE;

  return tileIsDark ? lighten(hexTileColor, 20) : darken(hexTileColor, 20);
};

export default getTileHoverColor;
