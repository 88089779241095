import moment from 'moment';

const isLater = (dateOneAsDateTime, dateTwo) => {
  const dateOne = dateOneAsDateTime.toDate();

  const firstMoment = moment(dateOne).startOf('day');
  const secondMoment = moment(dateTwo).startOf('day');

  return firstMoment.isAfter(secondMoment);
};

export default isLater;
