import PropTypes from 'prop-types';
import useTopicSubtopics from './useTopicSubtopics';
import Dialog from 'lib/components/Dialog';
import SubtopicsWrapper from './SubtopicsWrapper';
import SubtopicWithAttachableParts from './SubtopicWithAttachableParts';
import { FormattedMessage } from 'react-intl';

const TopicPartsWithAttachmentHandling = ({ selectedTopic, onGoBack }) => {
  const topicSubtopics = useTopicSubtopics(selectedTopic);

  if (!selectedTopic) {
    return null;
  }

  return (
    <>
      <Dialog.Steps.Step.TitleWithBackButton onBackButtonClick={onGoBack}>
        {selectedTopic.title}
      </Dialog.Steps.Step.TitleWithBackButton>
      <SubtopicsWrapper>
        {topicSubtopics.length === 0 ? (
          <Dialog.Content>
            <Dialog.ContentText>
              <FormattedMessage id="slo_system.slo.part_attachment.management_dialog.no_parts" />
            </Dialog.ContentText>
          </Dialog.Content>
        ) : (
          topicSubtopics.map(subtopic => (
            <SubtopicWithAttachableParts
              subtopic={subtopic}
              key={subtopic.id}
            />
          ))
        )}
      </SubtopicsWrapper>
    </>
  );
};

TopicPartsWithAttachmentHandling.propTypes = {
  selectedTopic: PropTypes.shape({
    title: PropTypes.string.isRequired
  })
};

export default TopicPartsWithAttachmentHandling;
