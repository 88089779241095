import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import useChallengeUsers from './useChallengeUsers';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import ChallengeNotSharedText from './ChallengeNotSharedText';
import AssigneesManager from './AssigneesManager';

const TaskAssignment = () => {
  const { challenge: task } = useContext(FieldContext);
  const [loading, challengeUsers] = useChallengeUsers(task.challenge);

  return (
    <section>
      <FieldName />
      {loading ? (
        <LoadingSpinner margin={24} centered />
      ) : (
        <>
          {!challengeUsers || challengeUsers.length <= 1 ? (
            <ChallengeNotSharedText />
          ) : (
            <AssigneesManager task={task} challengeUsers={challengeUsers} />
          )}
        </>
      )}
    </section>
  );
};

export default TaskAssignment;
