import styled from '@emotion/styled';

const FormWrapper = styled('section')({
  display: 'flex',
  alignItems: 'center',
  ':first-of-type': {
    marginTop: -4
  }
});

export default FormWrapper;
