import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import { WHITE } from './defaultColors';

const useSelectedColor = () => {
  const { challenge, challengeRef } = useContext(FieldContext);

  const selectedColor = (challenge.color || WHITE).toString().toUpperCase();
  const setSelectedColor = color => challengeRef.child('color').set(color);

  return [selectedColor, setSelectedColor];
};

export default useSelectedColor;
