import { useState } from 'react';

const useRowChallengesVisibilityState = () => {
  const [challengesVisibilityState, setChallengesVisibilityState] = useState(
    {}
  );

  const registerChallengeVisibility = (challengeKey, visible) => {
    setChallengesVisibilityState(challengesVisibilityState => ({
      ...challengesVisibilityState,
      [challengeKey]: visible
    }));
  };

  const rowHasVisibleChallenges = Object.values(
    challengesVisibilityState
  ).includes(true);

  return [rowHasVisibleChallenges, registerChallengeVisibility];
};

export default useRowChallengesVisibilityState;
