import PropTypes from 'prop-types';
import { RichUtils, SelectionState } from 'draft-js';
import {
  getSelectedBlock,
  getSelectionEntity,
  getEntityRange,
  getSelectionText
} from 'draftjs-utils';
import selectionContainsAnyLinks from './selectionContainsAnyLinks';
import ToolbarButton from '../../ToolbarButton';
import { LinkOff as RemoveLinkIcon } from '@material-ui/icons';

const RemoveLink = ({
  editorState,
  setEditorState,
  focusOnEditor,
  ...props
}) => {
  const removeLinkFromSelection = () => {
    setEditorState(
      RichUtils.toggleLink(editorState, editorState.getSelection(), null)
    );

    focusOnEditor();
  };

  const removeLinkFromCurrentEntity = () => {
    const currentBlock = getSelectedBlock(editorState);
    const currentEntityKey = getSelectionEntity(editorState);
    const currentEntityRange = getEntityRange(editorState, currentEntityKey);

    const emptySelection = SelectionState.createEmpty(currentBlock.key);
    const currentEntitySelection = emptySelection.merge({
      anchorOffset: currentEntityRange.start,
      focusOffset: currentEntityRange.end
    });

    setEditorState(
      RichUtils.toggleLink(editorState, currentEntitySelection, null)
    );

    focusOnEditor();
  };

  const removeLink = () => {
    const selectionText = getSelectionText(editorState);
    const hasSelection = selectionText.length > 0;

    if (hasSelection) {
      removeLinkFromSelection();

      return;
    }

    removeLinkFromCurrentEntity();
  };

  const hasAnyLinksSelected = selectionContainsAnyLinks(
    editorState,
    editorState.getSelection()
  );

  if (!hasAnyLinksSelected) {
    return null;
  }

  return (
    <ToolbarButton
      label="remove_link"
      icon={RemoveLinkIcon}
      onClick={removeLink}
      {...props}
    />
  );
};

RemoveLink.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  focusOnEditor: PropTypes.func.isRequired
};

export default RemoveLink;
