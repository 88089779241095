import { useContext } from 'react';
import useCurrentPhaseRef from '../../../../useCurrentPhaseRef';
import PhaseContentGroupContext from '../../../../PhaseContentGroupContext';
import FieldContext from '../../../FieldContext';

const useSwapUiPositionsWithField = () => {
  const currentPhaseRef = useCurrentPhaseRef();
  const phaseContentGroup = useContext(PhaseContentGroupContext);
  const updateFieldUiPos = (fieldKey, uiPos) =>
    currentPhaseRef.child(`${phaseContentGroup}/${fieldKey}/uiPos`).set(uiPos);

  const { field } = useContext(FieldContext);
  const swapFieldUiPositions = fieldToSwapWith => {
    updateFieldUiPos(field.key, fieldToSwapWith.uiPos);
    updateFieldUiPos(fieldToSwapWith.key, field.uiPos);
  };

  return swapFieldUiPositions;
};

export default useSwapUiPositionsWithField;
