import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import FullscreenViewContentTitle from './FullscreenViewContentTitle';

const FullscreenViewContent = styled('section')(
  ({ theme, shaded }) => ({
    padding: '24px 72px',
    backgroundColor: shaded ? theme.palette.action.hover : null,
    overflow: 'hidden',
    flexShrink: 0,
    [mediaQuery(860)]: {
      paddingLeft: 24,
      paddingRight: 24
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: 'transparent',
    borderTop: `2px solid ${theme.palette.divider}`
  }))
);

FullscreenViewContent.propTypes = {
  shaded: PropTypes.bool
};

FullscreenViewContent.defaultProps = {
  shaded: false
};

FullscreenViewContent.Title = FullscreenViewContentTitle;

export default FullscreenViewContent;
