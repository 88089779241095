import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Delete as DeleteIcon } from '@material-ui/icons';

const StyledDeleteIcon = styled(DeleteIcon)(({ theme, disabled }) => {
  if (!disabled) {
    return {
      color: theme.palette.danger
    };
  }
});

StyledDeleteIcon.propTypes = {
  disabled: PropTypes.bool
};

StyledDeleteIcon.defaultProps = {
  disabled: false
};

export default StyledDeleteIcon;
