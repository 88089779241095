import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Avatar } from '@material-ui/core';
import isPropValid from '@emotion/is-prop-valid';

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: isPropValid
})(({ size, marginRight, backgroundColor }) => ({
  margin: 0,
  marginRight:
    typeof marginRight === 'number' ? marginRight : size / 2 + size / 10,
  backgroundColor: backgroundColor,
  fontWeight: 300,
  '&&&': {
    width: size,
    height: size,
    fontSize: size / 2
  }
}));

StyledAvatar.propTypes = {
  size: PropTypes.number,
  marginRight: PropTypes.number,
  backgroundColor: PropTypes.string.isRequired
};

StyledAvatar.defaultProps = {
  size: 40
};

export default StyledAvatar;
