import { useState } from 'react';
import PropTypes from 'prop-types';
import OptionFormWrapper from './OptionFormWrapper';
import { TextField, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { useIntl } from 'react-intl';

const AddOptionForm = ({ optionsRef, ...props }) => {
  const [newOption, setNewOption] = useState('');

  const saveNewOption = e => {
    e.preventDefault();

    if (newOption.trim()) {
      setNewOption('');
      optionsRef.push({
        name: newOption.trim()
      });
    }
  };

  const intl = useIntl();
  return (
    <OptionFormWrapper onSubmit={saveNewOption}>
      <TextField
        placeholder={intl.formatMessage({
          id:
            'editor.model_editor.field_editor.select_editor.add_option.input_placeholder'
        })}
        value={newOption}
        onChange={e => setNewOption(e.target.value)}
        fullWidth
      />
      <IconButton type="submit" disabled={!newOption.trim()}>
        <CheckIcon />
      </IconButton>
    </OptionFormWrapper>
  );
};

AddOptionForm.propTypes = {
  optionsRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default AddOptionForm;
