import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import RowSelector from './RowSelector';
import RowChallengesWithAttachmentHandling from './RowChallengesWithAttachmentHandling';

const ChallengeAttachmentManager = ({ onClose, ...props }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Dialog onClose={onClose} onEnterClose={onClose} {...props}>
      <Dialog.Steps.Wrapper hasMovedToSecondStep={!!selectedRow}>
        <Dialog.Steps.Step isFirstStep>
          <RowSelector onSelectRow={row => setSelectedRow(row)} />
        </Dialog.Steps.Step>
        <Dialog.Steps.Step>
          <RowChallengesWithAttachmentHandling
            selectedRow={selectedRow}
            onGoBack={() => setSelectedRow(null)}
          />
        </Dialog.Steps.Step>
      </Dialog.Steps.Wrapper>
      <Dialog.Actions>
        <Dialog.Actions.DoneButton onClick={onClose} />
      </Dialog.Actions>
    </Dialog>
  );
};

ChallengeAttachmentManager.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ChallengeAttachmentManager;
