import PropTypes from 'prop-types';
import StyledDeleteButton from './StyledDeleteButton';
import StyledDeleteIcon from './StyledDeleteIcon';

const DeleteButton = ({ disabled, ...props }) => (
  <StyledDeleteButton
    icon={<StyledDeleteIcon disabled={disabled} />}
    labelId="portfolio.challenge.drawer.delete.button"
    disabled={disabled}
    {...props}
  />
);

DeleteButton.propTypes = {
  disabled: PropTypes.bool
};

DeleteButton.defaultProps = {
  disabled: false
};

export default DeleteButton;
