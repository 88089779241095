import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const SubtopicTitleWrapper = styled('h3')(
  ({ theme, clickable, editorOnly }) => ({
    marginBottom: 12,
    color: editorOnly ? theme.palette.text.secondary : null,
    display: 'flex',
    alignItems: 'center',
    cursor: clickable ? 'pointer' : null
  })
);

SubtopicTitleWrapper.propTypes = {
  clickable: PropTypes.bool,
  editorOnly: PropTypes.bool
};

SubtopicTitleWrapper.defaultProps = {
  clickable: false,
  editorOnly: false
};

export default SubtopicTitleWrapper;
