const getEventXOffset = e => {
  if (typeof e.pageX === 'number') {
    return e.pageX;
  }

  const firstTouch = e.touches?.[0];
  return firstTouch?.clientX;
};

export default getEventXOffset;
