import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import moment from 'moment';

const StyledDate = styled('span')(({ theme }) => ({
  marginBottom: 0,
  marginLeft: 12,
  color: theme.palette.text.secondary,
  fontWeight: 400,
  fontSize: 15,
  ':first-child': {
    marginLeft: 0
  },
  [mediaQuery(1024)]: {
    marginLeft: 0,
    fontSize: 14
  }
}));

const LogEntryDate = ({ timestamp, ...props }) => (
  <StyledDate>{moment(timestamp).calendar({ lastWeek: 'L' })}</StyledDate>
);

LogEntryDate.propTypes = {
  timestamp: PropTypes.number.isRequired
};

export default LogEntryDate;
