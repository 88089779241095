import Dialog from 'lib/components/Dialog';
import { FormControl } from '@material-ui/core';
import SectionTitle from './SectionTitle';
import RowAttachmentTypeOptions from './RowAttachmentTypeOptions';

const ChallengeRowAttachment = props => (
  <Dialog.Content style={{ overflowY: 'visible' }}>
    <FormControl color="primary" style={{ width: '100%' }} {...props} />
  </Dialog.Content>
);

ChallengeRowAttachment.Title = SectionTitle;
ChallengeRowAttachment.TypeOptions = RowAttachmentTypeOptions;

export default ChallengeRowAttachment;
