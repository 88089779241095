import styled from '@emotion/styled';
import notFoundImage from './not-found-image.svg';

const StyledImage = styled('img')({
  margin: '24px auto',
  display: 'block'
});

const NotFoundImage = props => (
  <StyledImage src={notFoundImage} alt="" {...props} />
);

export default NotFoundImage;
