import { useContext } from 'react';
import UserContext from '../../../UserContext';
import ManagerTitle from './ManagerTitle';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import IndividualExpertiseManager from './IndividualExpertiseManager';
import AddExpertise from './AddExpertise';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const ExpertiseManager = () => {
  const user = useContext(UserContext);
  return (
    <>
      <ManagerTitle>
        <FormattedMessage id="user_manager.user_list.user.expertise_manager.expertise_items.title" />
      </ManagerTitle>
      {safeKeys(user.expertiseKeys).map(expertiseKey => (
        <IndividualExpertiseManager
          expertiseKey={expertiseKey}
          key={expertiseKey}
        />
      ))}
      <AddExpertise />
      <Space height={12} />
    </>
  );
};

export default ExpertiseManager;
