import { useState } from 'react';

const useRowsVisibilityState = () => {
  const [rowsVisibilityState, setRowsVisibilityState] = useState({});

  const registerRowVisibility = (rowKey, visible) => {
    setRowsVisibilityState(rowsVisibilityState => ({
      ...rowsVisibilityState,
      [rowKey]: visible
    }));
  };

  const noRowsVisible = !Object.values(rowsVisibilityState).includes(true);

  return [noRowsVisible, registerRowVisibility];
};

export default useRowsVisibilityState;
