import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledContent = styled('section')({
  position: 'relative'
});

const TopicContent = ({ collapsed, ...props }) => {
  if (collapsed) {
    return null;
  }

  return <StyledContent {...props} />;
};

TopicContent.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default TopicContent;
