import VisibleWeekRangeContext from './VisibleWeekRangeContext';
import LatestVisibleWeekRangeShiftDirectionContext from './LatestVisibleWeekRangeShiftDirectionContext';
import PlanningHeader from './PlanningHeader';
import RowsWithChallengePlanning from './RowsWithChallengePlanning';

const PlanningView = () => (
  <VisibleWeekRangeContext.AutomatedProvider>
    <LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider>
      <PlanningHeader />
      <RowsWithChallengePlanning />
    </LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider>
  </VisibleWeekRangeContext.AutomatedProvider>
);

export default PlanningView;
