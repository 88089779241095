import { useContext } from 'react';
import FieldContext from '../../../FieldContext';
import { safeKeys, safeEntries } from 'lib/utils/safeObjectFunctions';
import arrToObj from 'lib/utils/arrToObj';
import useRenderSelectValueOrPlaceholder from './useRenderSelectValueOrPlaceholder';
import StyledSelect from './StyledSelect';
import NoneMenuItem from './NoneMenuItem';
import { MenuItem } from '@material-ui/core';

const InnerSelect = props => {
  const { field, fieldRef, challenge } = useContext(FieldContext);

  const selectedOptionsInFirebase = safeKeys(challenge[field.key]);
  const selectValue = field.multipleOptionsSelectable
    ? selectedOptionsInFirebase
    : selectedOptionsInFirebase[0] || '';

  const handleChange = e => {
    const nextValue = e.target.value;
    const asFirebaseValue = Array.isArray(nextValue)
      ? arrToObj(nextValue, true)
      : nextValue === ''
      ? null
      : {
          [nextValue]: true
        };

    fieldRef.set(asFirebaseValue);
  };

  const renderSelectValueOrPlaceholder = useRenderSelectValueOrPlaceholder();

  return (
    <StyledSelect
      value={selectValue}
      onChange={handleChange}
      multiple={!!field.multipleOptionsSelectable}
      renderValue={renderSelectValueOrPlaceholder}
      displayEmpty
      {...props}
    >
      {!field.multipleOptionsSelectable && <NoneMenuItem value="" />}
      {safeEntries(field.options).map(([key, option]) => (
        <MenuItem value={key} key={key}>
          {option.name}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export default InnerSelect;
