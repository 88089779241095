import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FocusIndicator = styled('div')(
  ({ theme }) => ({
    marginRight: 12,
    display: 'inline-flex',
    alignItems: 'center',
    transition: theme.transitions.create(),
    [mediaQuery(768)]: {
      marginRight: 0
    }
  }),
  ({ theme, hasFocus }) => {
    if (hasFocus) {
      return {
        marginLeft: -6,
        padding: 6,
        backgroundColor: theme.palette.partFocusColor,
        borderRadius: 4
      };
    }
  }
);

FocusIndicator.propTypes = {
  hasFocus: PropTypes.bool
};

FocusIndicator.defaultProps = {
  hasFocus: false
};

export default FocusIndicator;
