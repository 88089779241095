import lightPalette from './light';
import lightHighContrastPalette from './lightHighContrast';
import darkPalette from './dark';
import darkHighContrastPalette from './darkHighContrast';

const palettes = {
  light: lightPalette,
  lightHighContrast: lightHighContrastPalette,
  dark: darkPalette,
  darkHighContrast: darkHighContrastPalette
};

export default palettes;
