import PropTypes from 'prop-types';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import CountLabel from './CountLabel';
import { FormattedMessage } from 'react-intl';

const SloCount = ({ sloPeriod, ...props }) => {
  const sloCount = safeKeys(sloPeriod.slos).length;

  if (sloCount === 0) {
    return (
      <CountLabel {...props}>
        <FormattedMessage id="slo_system.slo_period.slo_count.no_slos" />
      </CountLabel>
    );
  }

  return (
    <CountLabel {...props}>
      {sloCount === 1 ? (
        <FormattedMessage id="slo_system.slo_period.slo_count.singular" />
      ) : (
        <FormattedMessage
          id="slo_system.slo_period.slo_count.plural"
          values={{ sloCount }}
        />
      )}
    </CountLabel>
  );
};

SloCount.propTypes = {
  sloPeriod: PropTypes.shape({
    slos: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default SloCount;
