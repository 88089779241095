import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ToggleButtonTooltip = ({ isUsingPlanningView, ...props }) => (
  <Tooltip
    title={
      <FormattedMessage
        id={`portfolio.challenge_planning_view.toggle_button.${
          isUsingPlanningView ? 'normal_view' : 'planning_view'
        }`}
      />
    }
    {...props}
  />
);

ToggleButtonTooltip.propTypes = {
  isUsingPlanningView: PropTypes.bool.isRequired
};

export default ToggleButtonTooltip;
