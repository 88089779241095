import ActionButton from './ActionButton';
import { Edit as EditIcon } from '@material-ui/icons';

const EditButton = props => (
  <ActionButton
    icon={<EditIcon />}
    labelId="portfolio.challenge.drawer.edit.button"
    {...props}
  />
);

export default EditButton;
