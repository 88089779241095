import styled from '@emotion/styled';

const StyledIcon = styled('figure')(({ theme }) => ({
  marginTop: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 12,
  width: 36,
  height: 36,
  opacity: !theme.palette.isHighContrast ? 0.7 : null
}));

export default StyledIcon;
