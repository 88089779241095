import PropTypes from 'prop-types';
import useFormatMessageWithChallengeNaming from 'lib/utils/useFormatMessageWithChallengeNaming';
import { Helmet } from 'react-helmet';
import getPageTitleId from './getPageTitleId';

const OpenChallengePageTitle = ({ challenge, asTask, ...props }) => {
  const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();
  return (
    <Helmet
      title={formatMessageWithChallengeNaming({
        id: getPageTitleId(challenge, asTask)
      })}
      {...props}
    />
  );
};

OpenChallengePageTitle.propTypes = {
  challenge: PropTypes.object,
  asTask: PropTypes.bool.isRequired
};

export default OpenChallengePageTitle;
