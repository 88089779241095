import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const ResponsivityGreyBar = styled('div')(
  ({ theme }) => ({
    position: 'absolute',
    top: 72,
    left: 0,
    width: '100%',
    height: 72,
    backgroundColor: theme.palette.action.hover,
    display: 'none'
  }),
  ({ inCoachMode }) => {
    if (inCoachMode) {
      return {
        [mediaQuery(1400)]: {
          display: 'block'
        },
        [mediaQuery(700)]: {
          display: 'none'
        }
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`
  }))
);

ResponsivityGreyBar.propTypes = {
  inCoachMode: PropTypes.bool.isRequired
};

export default ResponsivityGreyBar;
