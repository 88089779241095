import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import StyledWeekLabel from './StyledWeekLabel';
import YearIndicator from './YearIndicator';
import { FormattedMessage } from 'react-intl';

const WeekLabel = ({ weekStartDate, ...props }) => {
  const weekNumber = weekStartDate.isoWeek();
  const weekYear = weekStartDate.clone().endOf('isoWeek').year();

  const currentWeekNumber = moment().isoWeek();
  const currentYear = moment().year();

  const representedWeekIsCurrentWeek =
    weekNumber === currentWeekNumber && weekYear === currentYear;

  return (
    <StyledWeekLabel highlighted={representedWeekIsCurrentWeek} {...props}>
      <FormattedMessage
        id="portfolio.challenge_planning_view.header.week_marker.week_label"
        values={{
          weekNumber
        }}
      />
      {weekNumber === 1 && <YearIndicator year={weekYear} />}
    </StyledWeekLabel>
  );
};

WeekLabel.propTypes = {
  weekStartDate: MomentPropTypes.momentObj.isRequired
};

export default WeekLabel;
