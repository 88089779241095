import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';

const useChallengeName = (challengeKey, asTask) => {
  const challengesRef = asTask ? 'tasks' : 'challenges';
  const [, challengeName] = useFirebaseValue(
    ref(`${challengesRef}/${challengeKey}/name`)
  );

  return challengeName;
};

export default useChallengeName;
