import { Button } from '@material-ui/core';
import { KeyboardArrowLeft as BackIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const BackButton = props => (
  <Button startIcon={<BackIcon />} color="secondary" {...props}>
    <FormattedMessage id="slo_system.slo_period.feedback.back_button" />
  </Button>
);

export default BackButton;
