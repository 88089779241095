import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import { Paper } from '@material-ui/core';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledWrapper = styled(stripProps(Paper, 'clickable', 'withGrouping'))(
  ({ theme, clickable, withGrouping }) => ({
    marginBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    height: 42,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    transition: theme.transitions.create(),
    cursor: clickable ? 'pointer' : null,
    ':last-of-type': {
      marginBottom: 12
    },
    ':hover': {
      borderColor:
        !withGrouping && clickable ? theme.palette.action.disabled : null
    }
  }),
  ({ theme, clickable, withGrouping }) => {
    if (withGrouping) {
      return {
        marginBottom: 0,
        borderRadius: 0,
        ':first-child': {
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4
        },
        ':last-child': {
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        },
        ':not(:last-child)': {
          borderBottom: 'none'
        },
        ':hover': {
          backgroundColor: clickable ? theme.palette.action.hover : null
        }
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    ':hover': {
      textDecoration: 'underline',
      borderColor: theme.palette.divider
    }
  }))
);

const SloListEntryWrapper = props => (
  <StyledWrapper variant="outlined" {...props} />
);

SloListEntryWrapper.propTypes = {
  clickable: PropTypes.bool,
  withGrouping: PropTypes.bool
};

SloListEntryWrapper.defaultProps = {
  clickable: false,
  withGrouping: false
};

export default SloListEntryWrapper;
