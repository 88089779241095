import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import App from 'lib/App';
import FieldName from '../utils/FieldName';
import SloWithAvailabilityCheck from './SloWithAvailabilityCheck';

const AttachedSlos = () => {
  const { challenge } = useContext(FieldContext);

  const sloSystemEnabled = App.instanceConfig.useContentSetting(
    'enableSloSystem'
  );

  if (!challenge.attachedSlos || !sloSystemEnabled) {
    return null;
  }

  return (
    <section>
      <FieldName />
      <div>
        {Object.keys(challenge.attachedSlos).map(sloKey => (
          <SloWithAvailabilityCheck sloKey={sloKey} key={sloKey} withGrouping />
        ))}
      </div>
    </section>
  );
};

export default AttachedSlos;
