import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import Space from 'lib/components/Space';
import EditSubtopic from './EditSubtopic';
import DeleteSubtopic from './DeleteSubtopic';
import Parts from './Parts';
import AddPart from './AddPart';

const Subtopic = ({ subtopic, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <TileGrid.Tile open={open} color={subtopic.color} {...props}>
      {!open && (
        <>
          <TileGrid.Tile.ClickListener onClick={() => setOpen(true)} />
          <TileGrid.Tile.Title>{subtopic.name}</TileGrid.Tile.Title>
          <TileGrid.Tile.OnlyShowOnHover>
            <Space width={9} />
          </TileGrid.Tile.OnlyShowOnHover>
          <TileGrid.Tile.OnlyShowOnHover>
            <EditSubtopic subtopic={subtopic} />
          </TileGrid.Tile.OnlyShowOnHover>
          <TileGrid.Tile.OnlyShowOnHover>
            <DeleteSubtopic subtopic={subtopic} />
          </TileGrid.Tile.OnlyShowOnHover>
        </>
      )}
      {open && (
        <>
          <TileGrid.AppBarMarginFix />
          <Parts subtopic={subtopic} onClose={() => setOpen(false)} />
          <AddPart subtopicKey={subtopic.key} />
        </>
      )}
    </TileGrid.Tile>
  );
};

Subtopic.propTypes = {
  subtopic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string
  }).isRequired
};

export default Subtopic;
