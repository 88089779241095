import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import { CircularProgress } from '@material-ui/core';

const StyledCircularProgress = styled(
  stripProps(
    CircularProgress,
    'centered',
    'margin',
    'marginTop',
    'marginBottom'
  )
)(
  ({ theme, margin, marginTop, marginBottom }) => ({
    marginTop: marginTop || margin,
    marginBottom: marginBottom || margin,
    transition: theme.transitions.create()
  }),
  ({ centered }) => {
    if (centered) {
      return {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block'
      };
    }
  }
);

const LoadingSpinner = props => (
  <StyledCircularProgress
    size={24}
    color="secondary"
    disableShrink
    {...props}
  />
);

LoadingSpinner.propTypes = {
  centered: PropTypes.bool,
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

LoadingSpinner.defaultProps = {
  centered: false,
  margin: 0
};

export default LoadingSpinner;
