import { useContext } from 'react';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { challengeRef as getChallengeRef, taskRef } from 'lib/firebase';

const useMarkCoachCommentsAsRead = (challengeKey, asTask) => {
  const portfolioMode = useContext(PortfolioModeContext);

  if (portfolioMode === PortfolioModes.COACH) {
    return () => {};
  }

  const challengeRef = asTask
    ? taskRef(challengeKey)
    : getChallengeRef(challengeKey);
  return () => challengeRef.child('unreadCoachCommentsCount').remove();
};

export default useMarkCoachCommentsAsRead;
