import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const SloPeriodName = styled('h2')({
  [mediaQuery(640)]: {
    marginRight: -12,
    fontSize: 20
  }
});

export default SloPeriodName;
