import styled from '@emotion/styled';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const StyledText = styled('h4')(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontWeight: 400
}));

const ChallengeNotSharedText = props => (
  <StyledText {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.task.assignment.challenge_not_shared" />
  </StyledText>
);

export default ChallengeNotSharedText;
