import Color from 'color';
import common from '@material-ui/core/colors/common';

export const safeColor = color => {
  try {
    return new Color(color);
  } catch {
    return new Color(common.white);
  }
};

export const rgba = (color, alpha) =>
  safeColor(color).alpha(alpha).rgb().string();

export const lighten = (color, percentage) =>
  mutateColor(color, 'lighten', percentage);

export const darken = (color, percentage) =>
  mutateColor(color, 'darken', percentage);

const mutateColor = (color, mutatorFunction, percentage) =>
  safeColor(color)
    [mutatorFunction](percentage / 100)
    .rgb()
    .toString();
