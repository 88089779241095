import styled from '@emotion/styled';
import { Fab } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const GapminderButton = styled(Fab)(({ theme }) => ({
  marginTop: -14,
  marginBottom: -14,
  transition: theme.transitions.create(),
  boxShadow: `${theme.shadows[2]} !important`,
  [mediaQuery(600)]: {
    marginTop: 24,
    marginBottom: 0,
    maxWidth: '100%'
  }
}));

export default GapminderButton;
