import { createContext, useState } from 'react';
import Phases from 'lib/constants/Phases';

const VisiblePhaseContext = createContext();

const AutomatedVisiblePhaseContextProvider = props => {
  const [visibleChallengePhase, setVisibleChallengePhase] = useState(
    Phases.TODO
  );
  const [visibleTaskPhase, setVisibleTaskPhase] = useState(Phases.TODO);

  return (
    <VisiblePhaseContext.Provider
      value={{
        visibleChallengePhase,
        setVisibleChallengePhase,
        visibleTaskPhase,
        setVisibleTaskPhase
      }}
      {...props}
    />
  );
};

VisiblePhaseContext.AutomatedProvider = AutomatedVisiblePhaseContextProvider;

export default VisiblePhaseContext;
