import styled from '@emotion/styled';

const TileClickListener = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 1
});

export default TileClickListener;
