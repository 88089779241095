import PropTypes from 'prop-types';
import useUserRowKeys from '../useUserRowKeys';
import getHighestRowUiPos from './getHighestRowUiPos';
import ViewWrapper from './ViewWrapper';
import CollapsedRowsContext from './CollapsedRowsContext';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import NewlySharedChallenges from './NewlySharedChallenges';
import KanbanPhasesHeader from './KanbanPhasesHeader';
import Rows from './Rows';
import CollapseAllRowsButton from './CollapseAllRowsButton';
import CreateChallengeWithRowSelection from './CreateChallengeWithRowSelection';

const NormalView = ({ hidden }) => {
  const rowKeys = useUserRowKeys();
  const highestRowUiPos = getHighestRowUiPos(rowKeys);

  const flattenedRowKeys = rowKeys.map(([rowKey]) => rowKey);

  return (
    <ViewWrapper hidden={hidden}>
      <CollapsedRowsContext.AutomatedProvider rowKeys={flattenedRowKeys}>
        <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
          <CreateChallengeWithRowSelection
            rowKeys={flattenedRowKeys}
            highestRowUiPos={highestRowUiPos}
          />
          <NewlySharedChallenges />
        </RestrictedToPortfolioMode>
        <KanbanPhasesHeader>
          <CollapseAllRowsButton rowKeys={flattenedRowKeys} />
        </KanbanPhasesHeader>
        <Rows rowKeys={rowKeys} highestRowUiPos={highestRowUiPos} />
      </CollapsedRowsContext.AutomatedProvider>
    </ViewWrapper>
  );
};

NormalView.propTypes = {
  hidden: PropTypes.bool.isRequired
};

export default NormalView;
