import { useContext } from 'react';
import UserContext from '../../UserContext';
import App from 'lib/App';
import { FormControlLabel, Switch } from '@material-ui/core';
import { userRef } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const UserRetainsCoachFunctionalitySwitch = props => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const toggleUserCoachFunctionalityRetention = e => {
    const coachFunctionalityBecomesRetained = e.target.checked;

    userRef(userUsername)
      .child('retainsCoachFunctionality')
      .set(coachFunctionalityBecomesRetained);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          color="primary"
          checked={!!user.retainsCoachFunctionality}
          onChange={toggleUserCoachFunctionalityRetention}
        />
      }
      label={
        <FormattedMessage id="user_manager.user_list.user.expertise_manager.user_retains_coach_functionality_switch.label" />
      }
    />
  );
};

export default UserRetainsCoachFunctionalitySwitch;
