import { useContext } from 'react';
import DialogManagerContext from 'lib/contexts/DialogManagerContext';
import FocusLock from 'react-focus-lock';

const FocusLockWithoutDialogFocusFighting = props => {
  const { isAnyDialogOpen } = useContext(DialogManagerContext);

  // TODO FocusLock is giving too many issues with dialogs; re-enable when ready
  return (
    <FocusLock disabled={true || isAnyDialogOpen} returnFocus {...props} />
  );
};

export default FocusLockWithoutDialogFocusFighting;
