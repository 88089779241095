import styled from '@emotion/styled';
import { rgba } from 'lib/styling/colors';
import common from '@material-ui/core/colors/common';

const PreviewOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: rgba(common.black, 0.5)
});

export default PreviewOverlay;
