import { useState } from 'react';
import PropTypes from 'prop-types';
import PortfolioItemWithGroupsPrompt from '../PortfolioItemWithGroupsPrompt';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import arrToObj from 'lib/utils/arrToObj';
import EditButton from '../PortfolioTemplatesEditorActionButtons/EditButton';
import { FormattedMessage } from 'react-intl';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const EditSubtopic = ({ subtopic, ...props }) => {
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const saveEdits = (title, groups, dynamicGroups) => {
    setEditPromptOpen(false);

    ref(`portfolioTemplates/subtopics/${subtopic.id}`).update({
      title,
      groups: arrToObj(groups, true),
      dynamicGroups: arrToObj(dynamicGroups, true)
    });
  };

  return (
    <>
      <EditButton onClick={() => setEditPromptOpen(true)} {...props} />
      <PortfolioItemWithGroupsPrompt
        label={
          <FormattedMessage id="portfolio_template_editor.subtopic.edit.input_label" />
        }
        open={editPromptOpen}
        defaultValue={subtopic.title}
        selectedStandardGroups={safeKeys(subtopic.groups)}
        selectedGroups={safeKeys(subtopic.dynamicGroups)}
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio_template_editor.subtopic.edit.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio_template_editor.subtopic.edit.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PortfolioItemWithGroupsPrompt>
    </>
  );
};

EditSubtopic.propTypes = {
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.objectOf(PropTypes.bool),
    dynamicGroups: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default EditSubtopic;
