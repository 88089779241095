import Space from 'lib/components/Space';
import GroupList from './GroupList';
import NewGroupForm from './NewGroupForm';
import { FormattedMessage } from 'react-intl';

const GroupManager = () => (
  <section>
    <h2>
      <FormattedMessage id="group_manager.title" />
    </h2>
    <Space height={11} />
    <GroupList />
    <NewGroupForm />
  </section>
);

export default GroupManager;
