import Dialog from 'lib/components/Dialog';

const LevelDialogContent = props => (
  <Dialog.Content
    style={{
      paddingBottom: 25,
      overflowX: 'hidden'
    }}
    {...props}
  />
);

export default LevelDialogContent;
