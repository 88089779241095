import { useState } from 'react';
import PropTypes from 'prop-types';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import Dialog from 'lib/components/Dialog';
import { Close as DeleteIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { FormattedMessage } from 'react-intl';

const DeleteOption = ({ option, optionRef, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteOption = () => {
    setConfirmationDialogOpen(false);
    optionRef.remove();
  };

  return (
    <>
      <DangerousIconButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.select_editor.opion.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.select_editor.opion.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteOption} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteOption.propTypes = {
  optionRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default DeleteOption;
