import PropTypes from 'prop-types';
import RowAttachmentTypeOption from '../RowAttachmentTypeOption';
import RowSelectWrapper from './RowSelectWrapper';
import RowSelect from './RowSelect';
import { FormHelperText } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ExistingRowOption = ({
  selected,
  rowKeys,
  selectedRow,
  onChangeSelectedRow,
  hasError,
  ...props
}) => (
  <RowAttachmentTypeOption
    selected={selected}
    style={{ marginBottom: hasError ? 22 : null }}
    {...props}
  >
    <RowAttachmentTypeOption.LabelText>
      <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.label" />
    </RowAttachmentTypeOption.LabelText>
    <RowSelectWrapper error={hasError}>
      <RowSelect
        rowKeys={rowKeys}
        value={selectedRow}
        onChange={e => onChangeSelectedRow(e.target.value)}
        disabled={!selected}
      />
      {hasError && (
        <FormHelperText style={{ marginBottom: -22 }}>
          <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.row_select.errors.empty" />
        </FormHelperText>
      )}
    </RowSelectWrapper>
  </RowAttachmentTypeOption>
);

ExistingRowOption.propTypes = {
  selected: PropTypes.bool.isRequired,
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRow: PropTypes.string.isRequired,
  onChangeSelectedRow: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired
};

export default ExistingRowOption;
