import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import AppBarIconButton from './AppBarIconButton';
import AppBarTitle from './AppBarTitle';

const appBarStyles = {
  zIndex: 1
};

const LearningGoalsAppBar = ({ children, ...props }) => {
  const theme = useTheme();
  const paperAppBarTheme = theme.extend({
    palette: {
      primary: {
        main: theme.palette.background.paper
      }
    }
  });

  return (
    <MuiThemeProvider theme={paperAppBarTheme}>
      <AppBar
        position="absolute"
        color="inherit"
        style={appBarStyles}
        {...props}
      >
        <Toolbar>{children}</Toolbar>
      </AppBar>
    </MuiThemeProvider>
  );
};

LearningGoalsAppBar.propTypes = {
  children: PropTypes.node
};

LearningGoalsAppBar.IconButton = AppBarIconButton;
LearningGoalsAppBar.Title = AppBarTitle;

export default LearningGoalsAppBar;
