import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const AppBarMarginFix = styled('div')({
  marginBottom: 64,
  [mediaQuery(600)]: {
    marginBottom: 56
  }
});

export default AppBarMarginFix;
