import { useContext, useState } from 'react';
import SloContext from '../SloContext';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import WrapperForm from '../../../EditableSloPeriodName/WrapperForm';
import NameInput from '../../../EditableSloPeriodName/NameInput';
import CancelButton from 'lib/components/CancelButton';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon, Edit as EditIcon } from '@material-ui/icons';
import SloPeriodName from '../../../EditableSloPeriodName/SloPeriodName';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import { useIntl } from 'react-intl';

const EditableSloName = props => {
  const slo = useContext(SloContext);

  const [editing, setEditing] = useState(false);
  const [newSloName, setNewSloName] = useSyncedState(slo.name);

  const saveNameChange = () => {
    if (newSloName.trim() !== '') {
      ref(`slos/${slo.key}/name`).set(newSloName);
      setEditing(false);
    }
  };

  const cancelNameChange = () => {
    setNewSloName(slo.name);
    setEditing(false);
  };

  const intl = useIntl();
  return (
    <WrapperForm onSubmit={e => e.preventDefault()} fullWidth={editing}>
      {editing ? (
        <>
          <NameInput
            placeholder={intl.formatMessage({
              id: 'slo_system.slo.edit.input_placeholder'
            })}
            value={newSloName}
            onChange={e => setNewSloName(e.target.value)}
            onEscPress={cancelNameChange}
            autoFocus
          />
          <CancelButton
            visible={newSloName !== slo.name}
            onClick={cancelNameChange}
          />
          <IconButton type="submit" onClick={saveNameChange}>
            <CheckIcon />
          </IconButton>
        </>
      ) : (
        <>
          <SloPeriodName>{slo.name}</SloPeriodName>
          <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
            <Space width={12} />
            <IconButton onClick={() => setEditing(true)}>
              <EditIcon />
            </IconButton>
          </RestrictedToPortfolioMode>
        </>
      )}
    </WrapperForm>
  );
};

export default EditableSloName;
