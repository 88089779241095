import dutchFlag from './flagIcons/dutch-flag.svg';
import britishFlag from './flagIcons/british-flag.svg';
import frenchFlag from './flagIcons/french-flag.svg';

const getFlagIconForLanguage = language => {
  switch (language) {
    case 'en': {
      return britishFlag;
    }
    case 'fr': {
      return frenchFlag;
    }
    default: {
      return dutchFlag;
    }
  }
};

export default getFlagIconForLanguage;
