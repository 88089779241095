/* eslint-disable react-hooks/exhaustive-deps */
import { useContext } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import FieldContext from '../../FieldContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import StyledDateField from './StyledDateField';
import FieldName from '../utils/FieldName';
import Space from 'lib/components/Space';
import WrappedDatePicker from './WrappedDatePicker';
import PortfolioModes from 'lib/constants/PortfolioModes';

const DateField = ({ afterChange, ...props }) => {
  const { field, fieldRef, challenge } = useContext(FieldContext);

  const portfolioMode = useContext(PortfolioModeContext);
  const [date, setDate] = useSyncedState(challenge[field.key]);

  const timestampFromDateTime = dateTime =>
    !dateTime ? null : dateTime.toDate().getTime();

  const saveDateChange = dateAsDateTime => {
    const timestamp = timestampFromDateTime(dateAsDateTime);

    setDate(timestamp);
    fieldRef.set(timestamp);

    if (afterChange) {
      afterChange(timestamp);
    }

    setBodyOverflow('hidden');
  };

  return (
    <StyledDateField>
      <FieldName />
      <Space height={-8} />
      <WrappedDatePicker
        value={date}
        onChange={dateAsDateTime => {
          if (dateAsDateTime) {
            setDate(timestampFromDateTime(dateAsDateTime));
          }
        }}
        onAccept={saveDateChange}
        onClose={() => setBodyOverflow('hidden')}
        format="L"
        disabled={portfolioMode === PortfolioModes.COACH}
        {...props}
      />
    </StyledDateField>
  );
};

DateField.propTypes = {
  afterChange: PropTypes.func,
  clearable: PropTypes.bool
};

DateField.defaultProps = {
  clearable: true
};

export default DateField;
