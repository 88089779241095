import { useState } from 'react';
import App from 'lib/App';
import modesForRole from './modesForRole';
import Modes from 'lib/constants/Modes';
import Roles from 'lib/constants/Roles';
import { ClickAwayListener } from '@material-ui/core';
import EnsureProperModeSwitchAlignment from './EnsureProperModeSwitchAlignment';
import StyledSwitch from './StyledSwitch';
import DropdownButton from './DropdownButton';
import Mode from './Mode';

const ModeSwitch = props => {
  const currentUser = App.authentication.useCurrentUser();
  const [open, setOpen] = useState(false);

  const shownModes = modesForRole[currentUser.role];
  const selectedMode = App.navigation.useSelectedMode();

  const replacedByCoachModeButton =
    selectedMode === Modes.PORTFOLIO && currentUser.role === Roles.COACH;

  if (!shownModes || !selectedMode || replacedByCoachModeButton) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <EnsureProperModeSwitchAlignment>
        <StyledSwitch {...props}>
          <DropdownButton open={open} onClick={() => setOpen(!open)} />
          {shownModes.map(mode => (
            <Mode
              mode={mode}
              selected={mode === selectedMode}
              modeSwitchOpen={open}
              onClick={() => setOpen(!open)}
              key={mode}
            />
          ))}
        </StyledSwitch>
      </EnsureProperModeSwitchAlignment>
    </ClickAwayListener>
  );
};

export default ModeSwitch;
