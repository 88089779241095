import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import Part from '../Part';
import getSubtopicIconFromTitle from './getSubtopicIconFromTitle';
import StyledSubtopic from 'components/RubricsPortfolioComponents/StyledSubtopic';
import SubtopicTitleWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitleWrapper';
import SubtopicTitle from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicTitle';
import CollapseButton from 'components/RubricsPortfolioComponents/CollapseButton';
import SubtopicPartWrapper from 'components/RubricsPortfolioComponents/StyledSubtopic/SubtopicPartWrapper';
import SubtopicIcon from './SubtopicIcon';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import sortPartsByUiPos from 'lib/rubricsPortfolioUtils/sortPartsByUiPos';
import useCollapsedState from '../useCollapsedState';
import App from 'lib/App';

const Subtopic = ({ subtopic, belongsToFirstTopic, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const allParts = portfolioTemplates?.parts;
  const desiredPartsKeys = safeKeys(subtopic.parts);
  const parts = desiredPartsKeys
    .map(partKey => allParts?.[partKey])
    .filter(part => !!part)
    .filter(userCanAccessItem(portfolioUser))
    .sort(sortPartsByUiPos(subtopic.id));

  const subtopicIcon = getSubtopicIconFromTitle(subtopic.title);

  const [collapsed, toggleCollapsed] = useCollapsedState(
    subtopic.id,
    belongsToFirstTopic
  );

  const aggressiveRubricCollapsingEnabled = App.instanceConfig.useContentSetting(
    'enableAggressiveRubricCollapsing'
  );

  return (
    <StyledSubtopic {...props}>
      <SubtopicTitleWrapper
        onClick={toggleCollapsed}
        clickable={aggressiveRubricCollapsingEnabled}
      >
        {!!subtopicIcon && <SubtopicIcon src={subtopicIcon} alt="" />}
        <SubtopicTitle>{subtopic.title}</SubtopicTitle>
        {aggressiveRubricCollapsingEnabled && (
          <CollapseButton collapsed={collapsed} />
        )}
      </SubtopicTitleWrapper>
      {(!aggressiveRubricCollapsingEnabled || !collapsed) && (
        <SubtopicPartWrapper>
          {parts.map(part => (
            <Part part={part} subtopicIcon={subtopicIcon} key={part.id} />
          ))}
        </SubtopicPartWrapper>
      )}
    </StyledSubtopic>
  );
};

Subtopic.propTypes = {
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    parts: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  belongsToFirstTopic: PropTypes.bool
};

Subtopic.defaultProps = {
  belongsToFirstTopic: false
};

export default Subtopic;
