import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledSubtopic = styled('div')(
  {
    marginBottom: 42,
    width: '100%',
    ':last-of-type': {
      marginBottom: 0
    }
  },
  ({ theme, editor }) => {
    if (editor) {
      return {
        transition: theme.transitions.create(),
        cursor: 'move'
      };
    }
  },
  ({ editor, dragging }) => {
    if (editor && dragging) {
      return {
        opacity: 0.5
      };
    }
  }
);

StyledSubtopic.propTypes = {
  editor: PropTypes.bool,
  dragging: PropTypes.bool
};

StyledSubtopic.defaultProps = {
  editor: false,
  dragging: false
};

export default StyledSubtopic;
