import { useEffect } from 'react';
import useSelectedTheme from 'lib/utils/useSelectedTheme';
import useHighContrastModeEnabled from 'lib/utils/useHighContrastModeEnabled';
import Themes from 'lib/constants/Themes';

const useSyncDisplaySettingsToBody = () => {
  const [selectedTheme] = useSelectedTheme();

  useEffect(() => {
    if (selectedTheme === Themes.DARK) {
      document.body.classList.add('is-dark');
      return;
    }

    document.body.classList.remove('is-dark');
  }, [selectedTheme]);

  const [highContrastModeEnabled] = useHighContrastModeEnabled();

  useEffect(() => {
    if (highContrastModeEnabled) {
      document.body.classList.add('is-high-contrast');
      return;
    }

    document.body.classList.remove('is-high-contrast');
  }, [highContrastModeEnabled]);
};

export default useSyncDisplaySettingsToBody;
