import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

const StyledPart = styled(Paper, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    position: 'relative',
    paddingTop: 12,
    width: '100%',
    display: 'inline-flex',
    transition: theme.transitions.create(),
    boxSizing: 'border-box',
    alignItems: 'center',
    ':last-of-type': {
      paddingBottom: 12
    },
    '@media (min-width: 769px)': {
      backgroundColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none'
    },
    [mediaQuery(768)]: {
      marginBottom: 18,
      padding: '16px 24px',
      paddingBottom: 28,
      display: 'block',
      borderRadius: 8,
      overflow: 'hidden',
      ':last-of-type': {
        marginBottom: -6,
        paddingBottom: 28
      }
    }
  }),
  ({ editor }) => {
    if (editor) {
      return {
        cursor: 'move',
        ':last-of-type': {
          paddingBottom: 0
        },
        [mediaQuery(768)]: {
          paddingBottom: 16,
          ':last-of-type': {
            marginBottom: 18,
            paddingBottom: 16
          }
        }
      };
    }

    return {
      lineHeight: 1
    };
  },
  ({ editor, dragging }) => {
    if (editor && dragging) {
      return {
        opacity: 0.5
      };
    }
  },
  ({ theme, mobileOutlined }) => {
    if (mobileOutlined) {
      return {
        boxShadow: 'none',
        [mediaQuery(768)]: {
          border: `1px solid ${theme.palette.divider}`
        }
      };
    }
  },
  addHighContrastStyles(({ theme, mobileOutlined }) => {
    if (!mobileOutlined) {
      return {
        [mediaQuery(768)]: {
          border: `2px solid ${theme.palette.divider}`
        }
      };
    }
  })
);

StyledPart.propTypes = {
  editor: PropTypes.bool,
  dragging: PropTypes.bool,
  mobileOutlined: PropTypes.bool
};

StyledPart.defaultProps = {
  editor: false,
  dragging: false,
  mobileOutlined: false
};

export default StyledPart;
