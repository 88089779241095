import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import getResponsiveStyles from './getResponsiveStyles';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledSliderRail = styled('div')(
  ({ theme, levelCount, readOnly, largestStackSize }) => ({
    position: 'relative',
    marginBottom: Math.max(50, largestStackSize * 32 + 6),
    height: 14,
    backgroundColor: theme.palette.action.selected,
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    borderRadius: 4,
    cursor: readOnly ? 'auto' : 'pointer',
    ...getResponsiveStyles(levelCount)
  }),
  addHighContrastStyles(({ theme }) => ({
    height: 16,
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.divider}`
  }))
);

StyledSliderRail.propTypes = {
  levelCount: PropTypes.number.isRequired,
  readOnly: PropTypes.bool.isRequired,
  largestStackSize: PropTypes.number.isRequired
};

export default StyledSliderRail;
