import styled from '@emotion/styled';
import DangerousButton from 'lib/components/DangerousButton';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Tooltip, useMediaQuery } from '@material-ui/core';
import { ExitToApp as LeaveIcon } from '@material-ui/icons';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const StyledLeaveButton = styled(DangerousButton)({
  marginLeft: 6,
  height: 36
});

const LeaveButton = props => {
  const shouldUseIconButton = useMediaQuery('(max-width: 1024px)');

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.button" />
        }
      >
        <DangerousIconButton {...props}>
          <LeaveIcon />
        </DangerousIconButton>
      </Tooltip>
    );
  }

  return (
    <StyledLeaveButton {...props}>
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.leave.button" />
    </StyledLeaveButton>
  );
};

export default LeaveButton;
