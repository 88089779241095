import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledActionButton = styled(IconButton)(
  {
    position: 'relative',
    marginTop: -9,
    marginBottom: -9,
    padding: 9,
    zIndex: 2
  },
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create(),
    ':hover, :focus': {
      backgroundColor: theme.palette.background.default,
      transform: 'scale(1.1)'
    }
  }))
);

export default StyledActionButton;
