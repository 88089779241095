import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import { safeEntries } from 'lib/utils/safeObjectFunctions';

const useUserRowKeys = () => {
  const portfolioUser = useContext(PortfolioUserContext);

  if (!portfolioUser?.rows) {
    return [];
  }

  const rowKeysWithUiPos = portfolioUser.rows;
  const sortedRowKeys = safeEntries(rowKeysWithUiPos)
    .sort((a, b) => {
      const aUiPos = a[1];
      const bUiPos = b[1];
      return aUiPos - bUiPos;
    })
    .reverse();

  return sortedRowKeys;
};

export default useUserRowKeys;
