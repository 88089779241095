import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const SettingTitle = styled('h4')({
  marginRight: 8,
  fontWeight: 400,
  [mediaQuery(640)]: {
    marginRight: 0,
    marginBottom: 4,
    fontWeight: 500
  }
});

export default SettingTitle;
