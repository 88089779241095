import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { ListItemIcon } from '@material-ui/core';
import { Group as GroupIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const GroupsManagerButton = props => (
  <CollapsibleActionMenu.Button {...props}>
    <ListItemIcon>
      <GroupIcon />
    </ListItemIcon>
    <FormattedMessage id="editor.model_editor.actions.manage_groups" />
  </CollapsibleActionMenu.Button>
);

export default GroupsManagerButton;
