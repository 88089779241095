import AuthProviders from 'lib/constants/AuthProviders';

const getLogoMarginStyles = authProvider => {
  if (authProvider === AuthProviders.GOOGLE) {
    return {
      margin: -12.5,
      marginRight: -0.5
    };
  }

  return {
    marginRight: 12
  };
};

export default getLogoMarginStyles;
