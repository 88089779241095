import PropTypes from 'prop-types';
import App from 'lib/App';
import { Route } from 'react-router-dom';
import LoginScreen from './LoginScreen';
import NotFoundPage from 'pages/NotFoundPage';
import Roles from 'lib/constants/Roles';

const SecuredRoute = ({
  children,
  restrictedToRoles: allowedRoles,
  ...props
}) => {
  const currentUser = App.authentication.useCurrentUser();
  const loggedIn = !!currentUser;

  if (!allowedRoles) {
    return <Route {...props}>{loggedIn ? children : <LoginScreen />}</Route>;
  }

  const hasAccess = allowedRoles.includes(currentUser?.role);
  return (
    <Route {...props}>
      {loggedIn ? hasAccess ? children : <NotFoundPage /> : <LoginScreen />}
    </Route>
  );
};

SecuredRoute.propTypes = {
  children: PropTypes.node.isRequired,
  restrictedToRoles: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Roles)))
};

export default SecuredRoute;
