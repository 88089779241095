import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Tooltip, IconButton } from '@material-ui/core';
import device from 'current-device';
import { Add as AddIcon } from '@material-ui/icons';

const ButtonContainer = styled('footer')(({ alwaysVisible }) => ({
  position: 'absolute',
  bottom: 0,
  right: alwaysVisible ? 12 : 0,
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'flex-end'
}));

const MotionIconButton = motion.custom(
  styled(IconButton)({
    ':focus': {
      transform: 'none !important',
      opacity: '1 !important'
    }
  })
);

const CreateButton = ({ tooltip, isCreateTaskButton, ...props }) => {
  const shouldAlwaysShowButton = !device.desktop();
  return (
    <ButtonContainer alwaysVisible={shouldAlwaysShowButton}>
      <Tooltip title={tooltip}>
        <MotionIconButton
          variants={{
            initial: {
              scale: isCreateTaskButton || shouldAlwaysShowButton ? 1 : 0,
              opacity: isCreateTaskButton || shouldAlwaysShowButton ? 1 : 0
            },
            hover: {
              scale: 1,
              opacity: 1
            }
          }}
          {...props}
        >
          <AddIcon />
        </MotionIconButton>
      </Tooltip>
    </ButtonContainer>
  );
};

CreateButton.propTypes = {
  tooltip: PropTypes.node,
  isCreateTaskButton: PropTypes.bool.isRequired
};

export default CreateButton;
