import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import FullscreenViewHeaderTitle from './FullscreenViewHeaderTitle';
import FullscreenViewHeaderActionButton from './FullscreenViewHeaderActionButton';

const FullscreenViewHeader = styled('header')(
  ({ theme, forceFlex }) => ({
    padding: 24,
    paddingRight: 72,
    backgroundColor: theme.palette.action.hover,
    display: 'flex',
    alignItems: 'center',
    [mediaQuery(860)]: {
      paddingRight: 24
    },
    [mediaQuery(640)]: {
      display: forceFlex ? 'flex' : 'block'
    }
  }),
  addHighContrastStyles({
    backgroundColor: 'transparent'
  })
);

FullscreenViewHeader.propTypes = {
  forceFlex: PropTypes.bool
};

FullscreenViewHeader.defaultProps = {
  forceFlex: false
};

FullscreenViewHeader.Title = FullscreenViewHeaderTitle;
FullscreenViewHeader.ActionButton = FullscreenViewHeaderActionButton;

export default FullscreenViewHeader;
