import { Tooltip } from '@material-ui/core';
import IndicatorIcon from './IndicatorIcon';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const OriginalOwnerIndicator = props => (
  <Tooltip
    title={
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.sharing.users.original_owner_tooltip" />
    }
    {...props}
  >
    <IndicatorIcon />
  </Tooltip>
);

export default OriginalOwnerIndicator;
