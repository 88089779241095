import App from 'lib/App';
import useIsDisplayingNotFoundPage from './useIsDisplayingNotFoundPage';

const useIsDisplayingLoginScreen = () => {
  const loggedIn = App.authentication.useLoggedInState();
  const isDisplayingNotFoundPage = useIsDisplayingNotFoundPage();
  return !loggedIn && !isDisplayingNotFoundPage;
};

export default useIsDisplayingLoginScreen;
