import PropTypes from 'prop-types';
import StyledGroupSelectorButton from './StyledGroupSelectorButton';
import GroupSelectorButtonLabel from './GroupSelectorButtonLabel';
import GroupSelectorButtonDropdownArrow from './GroupSelectorButtonDropdownArrow';
import { FormattedMessage } from 'react-intl';

const GroupSelectorButton = ({ selectedGroup, isSelectingGroup, ...props }) => (
  <StyledGroupSelectorButton hasSelectedGroup={!!selectedGroup} {...props}>
    <GroupSelectorButtonLabel>
      {selectedGroup?.name || (
        <FormattedMessage id="user_switcher.group_selector.button.placeholder" />
      )}
    </GroupSelectorButtonLabel>
    <GroupSelectorButtonDropdownArrow rotated={isSelectingGroup} />
  </StyledGroupSelectorButton>
);

GroupSelectorButton.propTypes = {
  selectedGroup: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  isSelectingGroup: PropTypes.bool.isRequired
};

export default GroupSelectorButton;
