import App from 'lib/App';
import ActionsWrapper from './ActionsWrapper';

const HeaderActions = props => {
  const loggedIn = App.authentication.useLoggedInState();

  if (!loggedIn) {
    return null;
  }

  return <ActionsWrapper {...props} />;
};

export default HeaderActions;
