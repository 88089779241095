import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import Parts from './Parts';

const Subtopic = ({ subtopic, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <TileGrid.Tile
      open={open}
      color={subtopic.color}
      studentCompletion={subtopic.progress.student}
      coachCompletion={subtopic.progress.coach}
      hasCompletionFill
      {...props}
    >
      {!open && (
        <>
          <TileGrid.Tile.ClickListener onClick={() => setOpen(true)} />
          <TileGrid.Tile.Title onClick={() => setOpen(true)}>
            {subtopic.name}
          </TileGrid.Tile.Title>
        </>
      )}
      {open && (
        <>
          <TileGrid.AppBarMarginFix />
          <Parts
            subtopicName={subtopic.name}
            parts={subtopic.parts}
            onClose={() => setOpen(false)}
          />
        </>
      )}
    </TileGrid.Tile>
  );
};

Subtopic.propTypes = {
  subtopic: PropTypes.shape({
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    parts: PropTypes.arrayOf(PropTypes.object),
    progress: PropTypes.shape({
      student: PropTypes.number,
      coach: PropTypes.number
    })
  })
};

export default Subtopic;
