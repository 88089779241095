import PropTypes from 'prop-types';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import DangerousDialogSaveButton from './DangerousDialogSaveButton';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const CONFIRMATION_TEXT = 'Egodact';

const ConfirmationPrompt = ({ onConfirm, ...props }) => (
  <Prompt
    emptyError="platform_settings.login_settings.save_confirmation_prompt.errors.confirmation_text_empty"
    showError={confirmationText =>
      confirmationText !== CONFIRMATION_TEXT && (
        <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.errors.incorrect_confirmation_text" />
      )
    }
    onOk={onConfirm}
    OkButton={DangerousDialogSaveButton}
    disableEnterClose
    {...props}
  >
    <Dialog.Title>
      <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedHTMLMessage id="platform_settings.login_settings.save_confirmation_prompt.text" />
      </Dialog.ContentText>
    </Dialog.Content>
    <Prompt.TextField
      label={
        <FormattedMessage id="platform_settings.login_settings.save_confirmation_prompt.input_label" />
      }
    />
  </Prompt>
);

ConfirmationPrompt.propTypes = {
  onConfirm: PropTypes.func.isRequired
};

export default ConfirmationPrompt;
