import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import { Skeleton } from '@material-ui/lab';

const generateSkeletonWidthPercentage = () => 40 + Math.random() * 60;

const RowName = ({ rowKey, ...props }) => {
  const [loading, rowName] = useFirebaseValue(ref(`rows/${rowKey}/name`));
  const [skeletonWidthPercentage, setSkeletonWidthPercentage] = useState(
    generateSkeletonWidthPercentage()
  );

  useEffect(() => {
    setSkeletonWidthPercentage(generateSkeletonWidthPercentage());
  }, [rowKey]);

  if (loading) {
    return (
      <Skeleton
        variant="text"
        width={`${skeletonWidthPercentage}%`}
        animation="wave"
      />
    );
  }

  return rowName;
};

RowName.propTypes = {
  rowKey: PropTypes.string.isRequired
};

export default RowName;
