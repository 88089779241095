import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import AssigneeLoadingSpinner from './AssigneeLoadingSpinner';
import AssigneeChip from './AssigneeChip';

const Assignee = ({ username, taskKey, ...props }) => {
  const [loading, user] = useFirebaseValue(userRef(username));

  if (loading) {
    return <AssigneeLoadingSpinner />;
  }

  if (!user) {
    return null;
  }

  return (
    <AssigneeChip
      username={username}
      user={user}
      taskKey={taskKey}
      {...props}
    />
  );
};

Assignee.propTypes = {
  username: PropTypes.string.isRequired,
  taskKey: PropTypes.string.isRequired
};

export default Assignee;
