import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import StyledLabel from './StyledLabel';
import LabelLoadingPlaceholder from './LabelLoadingPlaceholder';
import LabelText from './LabelText';
import ExpertName from './ExpertName';
import { FormattedMessage } from 'react-intl';

const IndicatorLabel = ({ expertiseKey, ...props }) => {
  const [expertiseLoading, expertise] = useFirebaseValue(
    ref(`expertises/${expertiseKey}`)
  );

  return (
    <StyledLabel className="indicator-label" {...props}>
      {expertiseLoading ? (
        <LabelLoadingPlaceholder />
      ) : (
        <LabelText
          className="indicator-label-text"
          hasExpertiseName={!!expertise?.name}
        >
          {expertise?.name || (
            <FormattedMessage id="portfolio.slider.expert_indicators.no_expertise_name" />
          )}
        </LabelText>
      )}
      {!expertiseLoading && expertise?.user && (
        <ExpertName expertUsername={expertise.user} />
      )}
    </StyledLabel>
  );
};

IndicatorLabel.propTypes = {
  expertiseKey: PropTypes.string,
  stackIndex: PropTypes.number
};

export default IndicatorLabel;
