/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';

const useProgress = (initialProgress, isCoach, isExpertMode) => {
  const [progress, setProgress] = useState(initialProgress || {});

  useEffect(() => {
    setProgress(initialProgress || {});
  }, [JSON.stringify(initialProgress)]);

  const { activeExpertiseKey } = useContext(ExpertModeSettingsContext);

  const updateProgress = nextProgress => {
    const nextProgressObject = { ...progress };

    if (!isCoach) {
      nextProgressObject.student = nextProgress;
    }

    if (isCoach && !isExpertMode) {
      nextProgressObject.coach = nextProgress;
    }

    if (isCoach && isExpertMode) {
      nextProgressObject.experts = { ...progress.experts };
      nextProgressObject.experts[activeExpertiseKey] = {
        progress: nextProgress,
        expertiseKey: activeExpertiseKey
      };
    }

    setProgress(nextProgressObject);
  };

  return [progress, updateProgress];
};

export default useProgress;
