import useLogin from './useLogin';
import firebase from 'firebase/compat/app';

const useLoginWithMicrosoft = () => {
  const login = useLogin();

  const loginWithMicrosoft = onShowEmailNotAllowedError => () => {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');

    provider.setCustomParameters({
      prompt: 'select_account'
    });

    return login(provider, onShowEmailNotAllowedError);
  };

  return loginWithMicrosoft;
};

export default useLoginWithMicrosoft;
