const fullWidthAlertProps = {
  PaperProps: {
    style: {
      marginLeft: 15,
      marginRight: 15,
      width: '100%',
      maxWidth: '100%'
    }
  },
  maxWidth: false
};

export default fullWidthAlertProps;
