import styled from '@emotion/styled';

const FormWrapper = styled('form')(({ theme }) => ({
  marginLeft: -24,
  marginRight: -24,
  padding: 24,
  flex: 1,
  backgroundColor: theme.palette.action.hover,
  display: 'flex',
  alignItems: 'center'
}));

export default FormWrapper;
