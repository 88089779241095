import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/react';
import { Badge } from '@material-ui/core';

const UnreadCoachCommentsBadge = ({
  unreadCoachCommentsCount,
  collapsed,
  ...props
}) => (
  <ClassNames>
    {({ css }) => (
      <Badge
        badgeContent={unreadCoachCommentsCount}
        variant={collapsed ? 'dot' : 'standard'}
        color="primary"
        classes={{
          badge: css({
            transform:
              unreadCoachCommentsCount === 0
                ? 'scale(0) translate(10%, -30%)'
                : 'translate(10%, -30%)'
          })
        }}
        {...props}
      />
    )}
  </ClassNames>
);

UnreadCoachCommentsBadge.propTypes = {
  unreadCoachCommentsCount: PropTypes.number,
  collapsed: PropTypes.bool.isRequired
};

UnreadCoachCommentsBadge.defaultProps = {
  unreadCoachCommentsCount: 0
};

export default UnreadCoachCommentsBadge;
