import { useContext } from 'react';
import StackSizeContext from '../StackSizeContext';
import StyledSlidableZone from './StyledSlidableZone';

const SlidableZone = props => {
  const { largestStackSize } = useContext(StackSizeContext);
  return <StyledSlidableZone largestStackSize={largestStackSize} {...props} />;
};

export default SlidableZone;
