import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const StyledWrapper = styled(motion.section)({
  padding: 24,
  flex: 1,
  display: 'flex'
});

const ScreenWrapper = props => (
  <StyledWrapper
    initial={{
      scale: 0.99,
      opacity: 0
    }}
    animate={{
      scale: 1,
      opacity: 1
    }}
    transition={{
      duration: 0.4
    }}
    {...props}
  />
);

export default ScreenWrapper;
