import PropTypes from 'prop-types';
import useSubtopicParts from './useSubtopicParts';
import SubtopicTitle from './SubtopicTitle';
import { List } from '@material-ui/core';
import PartWithAttachmentHandling from './PartWithAttachmentHandling';

const SubtopicWithAttachableParts = ({ subtopic }) => {
  const subtopicParts = useSubtopicParts(subtopic);

  if (subtopicParts.length === 0) {
    return null;
  }

  return (
    <>
      <SubtopicTitle>{subtopic.title}</SubtopicTitle>
      <List>
        {subtopicParts.map(part => (
          <PartWithAttachmentHandling part={part} key={part.id} />
        ))}
      </List>
    </>
  );
};

SubtopicWithAttachableParts.propTypes = {
  subtopic: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

export default SubtopicWithAttachableParts;
