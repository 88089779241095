import styled from '@emotion/styled';
import { Select } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledGroupSelect = styled(Select)({
  width: 180,
  [mediaQuery(1200)]: {
    display: 'none'
  }
});

export default StyledGroupSelect;
