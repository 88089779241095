import useCachedSelectedTheme from './useCachedSelectedTheme';
import useDetermineDarkModeAutomatically from 'lib/utils/useDetermineDarkModeAutomatically';
import { useMediaQuery } from '@material-ui/core';
import Themes from 'lib/constants/Themes';

const useSelectedTheme = () => {
  const [
    cachedSelectedTheme,
    setCachedSelectedTheme
  ] = useCachedSelectedTheme();
  const [determineDarkModeAutomatically] = useDetermineDarkModeAutomatically();

  const browserPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const browserThemePreference = browserPrefersDarkMode
    ? Themes.DARK
    : Themes.LIGHT;

  if (determineDarkModeAutomatically) {
    return [browserThemePreference, setCachedSelectedTheme];
  }

  return [cachedSelectedTheme, setCachedSelectedTheme];
};

export default useSelectedTheme;
