import { useContext } from 'react';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { AddCircle as AddIcon } from '@material-ui/icons';
import PortfolioModes from 'lib/constants/PortfolioModes';
import { FormattedMessage } from 'react-intl';

const StyledButton = styled(Button)({
  [mediaQuery(768)]: {
    marginTop: 18
  }
});

const ManageButton = props => {
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <StyledButton
      startIcon={<AddIcon />}
      disabled={portfolioMode !== PortfolioModes.STUDENT}
      {...props}
    >
      <FormattedMessage id="slo_system.slo.part_attachment.manage_button" />
    </StyledButton>
  );
};

export default ManageButton;
