import PropTypes from 'prop-types';
import App from 'lib/App';
import { useTheme } from '@emotion/react';
import ProgressBar from './ProgressBar';
import SliderIcon from './SliderIcon';
import IconVariants from './IconVariants';

const reversedBorderRadiusCorrectionStyles = (progress, studentProgress) => ({
  borderBottomRightRadius: 4,
  borderBottomLeftRadius: progress > 99 ? 4 : null,
  borderTopLeftRadius:
    progress === studentProgress ? 9 : progress > 99 ? 4 : null,
  borderTopRightRadius: studentProgress === 0 ? 9 : 4
});

const normalBorderRadiusCorrectionStyles = (progress, studentProgress) => ({
  borderBottomLeftRadius: 4,
  borderBottomRightRadius: progress > 99 ? 4 : null,
  borderTopRightRadius:
    progress === studentProgress ? 9 : progress > 99 ? 4 : null,
  borderTopLeftRadius: studentProgress === 0 ? 9 : 4
});

const CoachProgressBar = ({ progress, studentProgress, sliding, ...props }) => {
  const useReversedSliders = App.instanceConfig.useContentSetting(
    'useReversedSliders'
  );
  const theme = useTheme();
  return (
    <ProgressBar
      progress={progress}
      otherProgress={studentProgress}
      color={theme.palette.sliders.coach}
      noTransition={sliding}
      style={
        useReversedSliders
          ? reversedBorderRadiusCorrectionStyles(progress, studentProgress)
          : normalBorderRadiusCorrectionStyles(progress, studentProgress)
      }
      {...props}
    >
      <SliderIcon variant={IconVariants.COACH} sliding={sliding} />
    </ProgressBar>
  );
};

CoachProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  studentProgress: PropTypes.number.isRequired,
  sliding: PropTypes.bool.isRequired
};

export default CoachProgressBar;
