import { useRef } from 'react';
import PropTypes from 'prop-types';
import useElementIsInViewport from 'lib/utils/useElementIsInViewport';
import Space from 'lib/components/Space';
import GapminderPlaceholder from './GapminderPlaceholder';
import GapminderCard from './GapminderCard';
import GapminderControlsWrapper from './GapminderControlsWrapper';
import CloseButton from './CloseButton';
import YearSelect from './YearSelect';
import PlayButton from './PlayButton';
import WeekSlider from './WeekSlider';
import DateText from './DateText';

const Gapminder = ({
  years,
  selectedYear,
  setSelectedYear,
  weeks,
  selectedWeek,
  setSelectedWeek,
  timestamp,
  onClose
}) => {
  const nonFixedGapminderStartRef = useRef(null);
  const nonFixedGapminderInViewport = useElementIsInViewport(
    nonFixedGapminderStartRef
  );
  const isGapminderFixed = !nonFixedGapminderInViewport;

  return (
    <>
      <Space height={24} />
      <div ref={nonFixedGapminderStartRef} />
      <GapminderPlaceholder visible={isGapminderFixed} />
      <GapminderCard fixed={isGapminderFixed}>
        <GapminderControlsWrapper>
          <CloseButton onClick={onClose} />
          <YearSelect
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
          <PlayButton
            weeks={weeks}
            selectedWeek={selectedWeek}
            setSelectedWeek={setSelectedWeek}
          />
        </GapminderControlsWrapper>
        <WeekSlider
          selectedYear={selectedYear}
          weeks={weeks}
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
        />
        <DateText timestamp={timestamp} selectedWeek={selectedWeek} />
      </GapminderCard>
    </>
  );
};

Gapminder.propTypes = {
  years: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedYear: PropTypes.number.isRequired,
  setSelectedYear: PropTypes.func.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedWeek: PropTypes.number.isRequired,
  setSelectedWeek: PropTypes.func.isRequired,
  timestamp: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default Gapminder;
