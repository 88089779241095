import { createContext, useContext } from 'react';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import App from 'lib/App';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';

const UseAggressiveRubricCollapsingContext = createContext(false);

const shouldUseAggressiveRubricCollapsingForPerformance = portfolioTemplates => {
  if (!portfolioTemplates) {
    return false;
  }

  const totalItemCount =
    safeKeys(portfolioTemplates.topics).length +
    safeKeys(portfolioTemplates.subtopics).length +
    safeKeys(portfolioTemplates.parts).length;

  return totalItemCount > 100;
};

const AutomatedUseAggressiveRubricCollapsingContextProvider = props => {
  const aggressiveRubricCollapsingContentSettingEnabled = App.instanceConfig.useContentSetting(
    'enableAggressiveRubricCollapsing'
  );

  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  return (
    <UseAggressiveRubricCollapsingContext.Provider
      value={
        aggressiveRubricCollapsingContentSettingEnabled ||
        shouldUseAggressiveRubricCollapsingForPerformance(portfolioTemplates)
      }
      {...props}
    />
  );
};

UseAggressiveRubricCollapsingContext.AutomatedProvider = AutomatedUseAggressiveRubricCollapsingContextProvider;

export default UseAggressiveRubricCollapsingContext;
