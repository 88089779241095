import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const MenuContentBlock = styled('section')(
  {
    width: '50%'
  },
  ({ invisible }) => {
    if (invisible) {
      return {
        height: 0,
        overflowY: 'hidden'
      };
    }
  }
);

MenuContentBlock.propTypes = {
  invisible: PropTypes.bool
};

MenuContentBlock.defaultProps = {
  invisible: false
};

export default MenuContentBlock;
