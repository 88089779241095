import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FullscreenViewHeaderTitle = styled('h2')({
  [mediaQuery(640)]: {
    marginLeft: -6,
    fontSize: 20,
    lineHeight: '48px',
    verticalAlign: 'middle',
    display: 'inline-block'
  }
});

export default FullscreenViewHeaderTitle;
