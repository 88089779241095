import { useContext } from 'react';
import useExpertModePossible from './useExpertModePossible';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';

const useIsExpertMode = () => {
  const expertModePossible = useExpertModePossible();
  const { isViewingAsExpert } = useContext(ExpertModeSettingsContext);

  const isExpertMode = expertModePossible && isViewingAsExpert;
  return isExpertMode;
};

export default useIsExpertMode;
