import App from 'lib/App';
import Roles from 'lib/constants/Roles';
import useIsDisplayingNotFoundPage from './useIsDisplayingNotFoundPage';

const useShouldDisplayAdminControls = () => {
  const currentUser = App.authentication.useCurrentUser();
  const userIsAdminLike = !!currentUser && currentUser.role !== Roles.STUDENT;

  const isDisplayingNotFoundPage = useIsDisplayingNotFoundPage();

  return userIsAdminLike && !isDisplayingNotFoundPage;
};

export default useShouldDisplayAdminControls;
