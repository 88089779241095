import PropTypes from 'prop-types';
import FeedbackListWrapper from './FeedbackListWrapper';
import FeedbackListTitle from './FeedbackListTitle';
import { FormattedMessage } from 'react-intl';
import FeedbackItem from './FeedbackItem';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import AddFeedback from '../AddFeedback';

const FeedbackList = ({ feedback, mobileVariant, ...props }) => (
  <FeedbackListWrapper mobileVariant={mobileVariant} {...props}>
    <FeedbackListTitle mobileVariant={mobileVariant}>
      <FormattedMessage id="portfolio.challenge.coach_feedback.title" />
    </FeedbackListTitle>
    {Object.entries(feedback).map(([feedbackKey, feedbackItem]) => (
      <FeedbackItem
        feedbackKey={feedbackKey}
        feedbackItem={feedbackItem}
        key={feedbackKey}
      />
    ))}
    <RestrictedToPortfolioMode mode={PortfolioModes.COACH}>
      <AddFeedback belowFeedbackList />
    </RestrictedToPortfolioMode>
  </FeedbackListWrapper>
);

FeedbackList.propTypes = {
  feedback: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        timestamp: PropTypes.number.isRequired
      })
    ])
  ).isRequired,
  mobileVariant: PropTypes.bool
};

FeedbackList.defaultProps = {
  mobileVariant: false
};

export default FeedbackList;
