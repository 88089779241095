import { useContext } from 'react';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';

const useCanDisplayPartAttachmentManager = () => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  return (
    !!portfolioTemplates &&
    !!portfolioTemplates.topics &&
    !!portfolioTemplates.subtopics
  );
};

export default useCanDisplayPartAttachmentManager;
