import styled from '@emotion/styled';
import { HighlightOff as ClearIcon } from '@material-ui/icons';

const ClearSelectedGroupIcon = styled(ClearIcon)({
  marginRight: 10,
  width: 20,
  height: 20
});

export default ClearSelectedGroupIcon;
