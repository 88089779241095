import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledLevel = styled('article')(
  ({ theme }) => ({
    position: 'relative',
    marginTop: 0,
    marginLeft: 12.5,
    marginRight: 12.5,
    padding: 15,
    lineHeight: 1.5,
    flex: 1,
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: 4,
    boxSizing: 'border-box',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: theme.transitions.create()
  }),
  ({ editor }) => {
    if (!editor) {
      return {
        [mediaQuery(900)]: {
          flexGrow: 0,
          flexShrink: 0,
          ...generateWidthStyles(3)
        },
        [mediaQuery(768)]: generateWidthStyles(2),
        [mediaQuery(500)]: generateWidthStyles(1)
      };
    }
  },
  ({ editor }) => {
    if (editor) {
      return {
        cursor: 'move'
      };
    }
  },
  ({ editor, dragging }) => {
    if (editor && dragging) {
      return {
        opacity: 0.5
      };
    }
  }
);

export const buildWidthString = levelsVisibleWithoutScrolling => {
  const negativeMarginCompensation = 25 - 25 / levelsVisibleWithoutScrolling;
  return `(((100vw - 78px) / ${levelsVisibleWithoutScrolling}) - ${negativeMarginCompensation}px)`;
};

export const generateWidthStyles = levelsVisibleWithoutScrolling => {
  const widthString = `calc(${buildWidthString(
    levelsVisibleWithoutScrolling
  )})`;
  return {
    minWidth: widthString,
    maxWidth: widthString
  };
};

StyledLevel.propTypes = {
  editor: PropTypes.bool,
  dragging: PropTypes.bool
};

StyledLevel.defaultProps = {
  editor: false,
  dragging: false
};

export default StyledLevel;
