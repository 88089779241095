import ButtonWrapper from './ButtonWrapper';
import AddIcon from './AddIcon';
import ButtonLabel from './ButtonLabel';
import { FormattedMessage } from 'react-intl';

const AddLevelButton = props => (
  <ButtonWrapper {...props}>
    <AddIcon />
    <ButtonLabel>
      <FormattedMessage id="portfolio_template_editor.add_level.title" />
    </ButtonLabel>
  </ButtonWrapper>
);

export default AddLevelButton;
