import styled from '@emotion/styled';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const StyledTitle = styled('h3')({
  marginTop: 6,
  marginBottom: 8
});

const SectionTitle = props => (
  <StyledTitle {...props}>
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.row_attachment.title" />
  </StyledTitle>
);

export default SectionTitle;
