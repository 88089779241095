import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledSlidableZone = styled('div')(({ largestStackSize }) => ({
  position: 'absolute',
  top: -50,
  bottom: -Math.max(50, largestStackSize * 32 + 6),
  width: '100%',
  zIndex: 4
}));

StyledSlidableZone.propTypes = {
  largestStackSize: PropTypes.number.isRequired
};

export default StyledSlidableZone;
