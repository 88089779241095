import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const capitalizeFirstChar = str =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

const plannedEndDateIsYesterday = (plannedEndDate, today, timestamp) => {
  const correctedPlannedEndDate = plannedEndDate
    .clone()
    .hours(0)
    .minutes(0)
    .seconds(0);
  const difference = today.diff(correctedPlannedEndDate, 'days');
  return difference === 1 || (difference === 0 && Date.now() > timestamp);
};

const plannedEndDateIsTomorrow = (plannedEndDate, today, timestamp) => {
  const correctedPlannedEndDate = plannedEndDate
    .clone()
    .hours(23)
    .minutes(59)
    .seconds(59);
  const difference = today.diff(correctedPlannedEndDate, 'days');
  return difference === -1 || (difference === 0 && Date.now() < timestamp);
};

const formatPlannedEndDate = timestamp => {
  const today = moment(new Date());
  const plannedEndDate = moment(timestamp);
  const difference = today.diff(plannedEndDate, 'days');
  const isToday =
    difference === 0 && new Date().getDate() === new Date(timestamp).getDate();

  if (isToday) {
    return (
      <FormattedMessage id="portfolio.challenge.card.planned_end_date.today" />
    );
  }

  if (plannedEndDateIsYesterday(plannedEndDate, today, timestamp)) {
    return (
      <FormattedMessage id="portfolio.challenge.card.planned_end_date.yesterday" />
    );
  }

  if (plannedEndDateIsTomorrow(plannedEndDate, today, timestamp)) {
    return (
      <FormattedMessage id="portfolio.challenge.card.planned_end_date.tomorrow" />
    );
  }

  if (Math.abs(difference) <= 7) {
    return capitalizeFirstChar(moment(plannedEndDate).fromNow());
  }

  return plannedEndDate.calendar();
};

export default formatPlannedEndDate;
