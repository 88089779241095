import PropTypes from 'prop-types';
import ExpertProgressIndicator from './ExpertProgressIndicator';

const ExpertProgressIndicators = ({ expertProgress, reversed, ...props }) => {
  if (!expertProgress) {
    return null;
  }

  return Object.values(expertProgress).map((expertProgressItem, index) => (
    <ExpertProgressIndicator
      progress={expertProgressItem.progress}
      reversed={reversed}
      key={expertProgressItem.expertiseKey || index}
    />
  ));
};

ExpertProgressIndicators.propTypes = {
  expertProgress: PropTypes.objectOf(
    PropTypes.shape({
      progress: PropTypes.number.isRequired,
      expertiseKey: PropTypes.string
    })
  ),
  reversed: PropTypes.bool.isRequired
};

export default ExpertProgressIndicators;
