import PropTypes from 'prop-types';
import HeaderContentWrapper from './HeaderContentWrapper';
import BackButton from './BackButton';
import Space from 'lib/components/Space';
import SubmenuTypes from '../SubmenuTypes';
import { FormattedMessage } from 'react-intl';

const SubmenuHeader = ({ selectedSubmenu, onGoBack, ...props }) => {
  if (!selectedSubmenu) {
    return null;
  }

  return (
    <HeaderContentWrapper {...props}>
      <BackButton onClick={onGoBack} />
      <Space width={6} />
      <h3>
        <FormattedMessage
          id={`header.options_menu.options.${selectedSubmenu.toLowerCase()}`}
        />
      </h3>
    </HeaderContentWrapper>
  );
};

SubmenuHeader.propTypes = {
  selectedSubmenu: PropTypes.oneOf(Object.values(SubmenuTypes)),
  onGoBack: PropTypes.func.isRequired
};

export default SubmenuHeader;
