import useCurrentUser from './useCurrentUser';
import useGetUsernameFromEmail from './useGetUsernameFromEmail';

const useCurrentUserUsername = () => {
  const currentUser = useCurrentUser();
  const getUsernameFromEmail = useGetUsernameFromEmail();

  if (!currentUser) {
    return null;
  }

  return getUsernameFromEmail(currentUser.email);
};

export default useCurrentUserUsername;
