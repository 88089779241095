import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import useParentRef from './utils/useParentRef';
import useChallengeIsNewlyShared from './utils/useChallengeIsNewlyShared';
import DateField from '../DateField';
import isEarlier from './utils/isEarlier';
import Phases from 'lib/constants/Phases';

const EndDate = () => {
  const { challenge, challengeRef, asTask } = useContext(FieldContext);
  const parentRef = useParentRef(challenge, asTask);
  const challengeIsNewlyShared = useChallengeIsNewlyShared(challenge);

  return (
    <DateField
      shouldDisableDate={date =>
        !!challenge.startDate && isEarlier(date, new Date(challenge.startDate))
      }
      afterChange={date => {
        if (
          (!asTask && challengeIsNewlyShared) ||
          challenge.phase === Phases.DONE
        ) {
          return;
        }

        if (!challenge.startDate) {
          challengeRef.child('startDate').set(date);
        }

        challengeRef.child('phase').set(Phases.DONE);

        parentRef.child(challenge.phase).child(challenge.key).remove();
        parentRef.child(Phases.DONE).child(challenge.key).set(true);
      }}
      clearable={false}
    />
  );
};

export default EndDate;
