import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../FieldContext';
import FieldNameWrapper from '../utils/FieldName/FieldNameWrapper';
import StyledFieldName from '../utils/FieldName/StyledFieldName';
import InfoButton from '../utils/FieldName/FieldInfo/InfoButton';

const CustomFieldName = ({ onInfoButtonClick, ...props }) => {
  const { field } = useContext(FieldContext);
  return (
    <FieldNameWrapper>
      <StyledFieldName>{field.name}</StyledFieldName>
      <InfoButton onClick={onInfoButtonClick} />
    </FieldNameWrapper>
  );
};

CustomFieldName.propTypes = {
  onInfoButtonClick: PropTypes.func.isRequired
};

export default CustomFieldName;
