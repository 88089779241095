import PropTypes from 'prop-types';
import OpenChallengePageTitle from './OpenChallengePageTitle';
import ChallengeLoadingIndicator from './ChallengeLoadingIndicator';
import NotFoundTextWithHomepageButton from './NotFoundTextWithHomepageButton';
import ChallengeView from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView';
import { useHistory, useParams } from 'react-router-dom';
import ChallengeUserStateSetup from './ChallengeUserStateSetup';
import useChallenge from './useChallenge';

const OpenChallengePage = ({ asTask }) => {
  const history = useHistory();
  const { key: challengeKey } = useParams();
  const [challengeLoading, challenge] = useChallenge(challengeKey, asTask);

  if (challengeLoading) {
    return <ChallengeLoadingIndicator asTask={asTask} />;
  }

  return (
    <>
      <OpenChallengePageTitle challenge={challenge} asTask={asTask} />
      {!challenge ? (
        <NotFoundTextWithHomepageButton asTask={asTask} />
      ) : (
        <ChallengeUserStateSetup challenge={challenge}>
          <ChallengeView
            challenge={challenge}
            asTask={asTask}
            onClose={() => history.push('/')}
          />
        </ChallengeUserStateSetup>
      )}
    </>
  );
};

OpenChallengePage.propTypes = {
  asTask: PropTypes.bool
};

OpenChallengePage.defaultProps = {
  asTask: false
};

export default OpenChallengePage;
