import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const TopicTitleWrapper = styled('header')(({ theme, editor }) => ({
  position: 'relative',
  paddingTop: 24,
  paddingBottom: 24,
  transition: theme.transitions.create(),
  cursor: editor ? 'move' : 'pointer',
  display: 'flex',
  alignItems: 'center'
}));

TopicTitleWrapper.propTypes = {
  editor: PropTypes.bool
};

TopicTitleWrapper.defaultProps = {
  editor: false
};

export default TopicTitleWrapper;
