import { useState } from 'react';
import ManageButton from './ManageButton';
import PartAttachmentManager from './PartAttachmentManager';

const ManageAttachedParts = props => {
  const [partAttachmentManagerOpen, setPartAttachmentManagerOpen] = useState(
    false
  );

  return (
    <>
      <ManageButton
        onClick={() => setPartAttachmentManagerOpen(true)}
        {...props}
      />
      <PartAttachmentManager
        open={partAttachmentManagerOpen}
        onClose={() => setPartAttachmentManagerOpen(false)}
      />
    </>
  );
};

export default ManageAttachedParts;
