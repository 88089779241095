import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const StyledWrapper = styled(motion.article)({
  margin: 6,
  flex: 1,
  display: 'flex',
  alignItems: 'center'
});

const UserWrapper = props => (
  <StyledWrapper
    initial={{ y: 10, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    {...props}
  />
);

export default UserWrapper;
