import { useState } from 'react';
import PropTypes from 'prop-types';
import device from 'current-device';
import Space from 'lib/components/Space';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import RowPicker from './RowPicker';
import setBodyOverflow from 'lib/styling/setBodyOverflow';

// This is necessary because dragging cards doesn't quite work on mobile
// devices
const MobileRowPicker = ({ challenge, ...props }) => {
  const [pickerOpen, setPickerOpen] = useState(false);

  if (device.desktop()) {
    return null;
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setPickerOpen(true)}
        fullWidth
        {...props}
      >
        <FormattedMessage id="portfolio.challenge.newly_shared.row_picker.button" />
      </Button>
      <Space height={36} />
      <RowPicker
        open={pickerOpen}
        challenge={challenge}
        onClose={() => {
          setPickerOpen(false);
          setBodyOverflow('hidden');
        }}
      />
    </>
  );
};

MobileRowPicker.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default MobileRowPicker;
