import asDesirableNumber from '../asDesirableNumber';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const attachDefaultProgressToPart = part => ({
  ...part,
  progress: {
    student: 0,
    coach: 0
  }
});

const getSubtopicWithCorrespondingPartsAndProgress = (
  subtopicKey,
  subtopic,
  portfolioTemplates,
  portfolio,
  challenge
) => {
  const accumulatedProgress = {
    student: 0,
    coach: 0
  };

  const partsWithChallengeAttachments = {};

  const correspondingPartsKeys = safeKeys(subtopic.parts);
  const correspondingParts = correspondingPartsKeys
    .map(partKey => portfolioTemplates.parts[partKey])
    .filter(part => !!part)
    .map(part => {
      if (!portfolio || !portfolio.progress || !portfolio.progress[part.id]) {
        return attachDefaultProgressToPart(part);
      }

      const progress = portfolio.progress[part.id];

      accumulatedProgress.student += progress.student || 0;
      accumulatedProgress.coach += progress.coach || 0;

      return {
        ...part,
        progress
      };
    })
    .map(part => {
      if (!portfolio || !portfolio.challengeProof || !part.levels) {
        return {
          ...part,
          challengeAttached: false
        };
      }

      const challengeHasBeenAttachedToPart = Object.keys(part.levels).reduce(
        (attached, levelKey) =>
          attached || !!portfolio.challengeProof[levelKey]?.[challenge.key],
        false
      );

      if (challengeHasBeenAttachedToPart) {
        partsWithChallengeAttachments[part.id] = true;
      }

      return {
        ...part,
        challengeAttached: challengeHasBeenAttachedToPart
      };
    });

  const accumulatedProgressPotential = correspondingParts.length * 100;

  const subtopicProgress = {
    student: asDesirableNumber(
      (accumulatedProgress.student / accumulatedProgressPotential) * 100
    ),
    coach: asDesirableNumber(
      (accumulatedProgress.coach / accumulatedProgressPotential) * 100
    )
  };

  return {
    ...subtopic,
    key: subtopicKey,
    parts: correspondingParts,
    progress: subtopicProgress,
    partsWithChallengeAttachments
  };
};

export default getSubtopicWithCorrespondingPartsAndProgress;
