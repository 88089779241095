import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import Prompt from 'lib/components/Prompt';
import { WHITE } from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/defaultColors';
import ColorPicker from './ColorPicker';

const PromptWithColors = ({
  color,
  textFieldLabel,
  children,
  onOk,
  ...props
}) => {
  const [selectedColor, setSelectedColor] = useSyncedState(color);
  return (
    <Prompt
      onOk={name => onOk(name, selectedColor)}
      emptyError="prompt.errors.name_empty"
      {...props}
    >
      {children}
      <ColorPicker
        selectedColor={selectedColor}
        onSelectColor={setSelectedColor}
      />
      <Prompt.TextField label={textFieldLabel} />
    </Prompt>
  );
};

PromptWithColors.propTypes = {
  color: PropTypes.string,
  textFieldLabel: PropTypes.node,
  onOk: PropTypes.func.isRequired,
  children: PropTypes.node
};

PromptWithColors.defaultProps = {
  color: WHITE
};

export default PromptWithColors;
