import { useState } from 'react';
import { Skeleton } from '@material-ui/lab';

const NameLoadingPlaceholder = props => {
  const [randomNumber] = useState(Math.random());
  const placeholderWidth = 40 + randomNumber * 60;
  return (
    <Skeleton
      variant="text"
      width={`${placeholderWidth}%`}
      animation="wave"
      {...props}
    />
  );
};

export default NameLoadingPlaceholder;
