import App from 'lib/App';
import HeaderActionIconButton from '../HeaderActionIconButton';
import UserAvatar from 'lib/components/UserAvatar';

const UserAvatarMenuButton = props => {
  const currentUser = App.authentication.useCurrentUser();

  return (
    <HeaderActionIconButton {...props}>
      <UserAvatar user={currentUser} size={32} marginRight={0} />
    </HeaderActionIconButton>
  );
};

export default UserAvatarMenuButton;
