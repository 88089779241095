import Modes from 'lib/constants/Modes';
import PortfolioModes from 'lib/constants/PortfolioModes';
import App from 'lib/App';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';

const getPortfolioModeFromSelectedMode = selectedMode => {
  switch (selectedMode) {
    case Modes.PORTFOLIO: {
      return PortfolioModes.STUDENT;
    }
    case Modes.COACH: {
      return PortfolioModes.COACH;
    }
    default: {
      return null;
    }
  }
};

const AutomatedPortfolioModeContextProvider = props => {
  const selectedMode = App.navigation.useSelectedMode();
  const portfolioMode = getPortfolioModeFromSelectedMode(selectedMode);
  return <PortfolioModeContext.Provider value={portfolioMode} {...props} />;
};

export default AutomatedPortfolioModeContextProvider;
