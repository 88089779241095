import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import egodactIconDefault from './egodact-icon-default.png';
import egodactIconDarkMode from './egodact-icon-dark-mode.png';

const StyledIcon = styled('img')({
  marginRight: 14,
  width: 32,
  height: 32
});

const EgodactIcon = props => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <StyledIcon
      src={theme.isDark ? egodactIconDarkMode : egodactIconDefault}
      alt={intl.formatMessage({
        id: 'login.header.icon_alt'
      })}
      {...props}
    />
  );
};

export default EgodactIcon;
