import { useContext } from 'react';
import PortfolioLoadingView from './PortfolioLoadingView';
import Portfolio from './Portfolio';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import PortfolioWithHistoryProvider from './PortfolioWithHistoryProvider';
import Space from 'lib/components/Space';
import { ref } from 'lib/firebase';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';

const getPortfolioMode = (portfolioMode, portfolioIsLive) => {
  if (portfolioMode === PortfolioModes.STUDENT && !portfolioIsLive) {
    return PortfolioModes.COACH;
  }
  return portfolioMode;
};

const RubricsPortfolio = props => {
  const portfolioMode = useContext(PortfolioModeContext);
  const [
    portfolioTemplatesLoading,
    portfolioTemplates
  ] = useUnsyncedFirebaseValue(ref('portfolioTemplates'));

  return (
    <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
      <PortfolioWithHistoryProvider>
        {({ portfolioLoading, portfolio, portfolioIsLive }) =>
          !portfolioTemplatesLoading && !portfolioLoading ? (
            <PortfolioModeContext.Provider
              value={getPortfolioMode(portfolioMode, portfolioIsLive)}
            >
              <PortfolioIsReadOnlyContext.Provider value={!portfolioIsLive}>
                <PortfolioContext.Provider value={portfolio}>
                  <Portfolio {...props} />
                </PortfolioContext.Provider>
              </PortfolioIsReadOnlyContext.Provider>
            </PortfolioModeContext.Provider>
          ) : (
            <PortfolioLoadingView />
          )
        }
      </PortfolioWithHistoryProvider>
      <Space height={15} />
    </PortfolioTemplatesContext.Provider>
  );
};

export default RubricsPortfolio;
