import styled from '@emotion/styled';
import ActionMenuButton from '../ActionMenuButton';
import { rgba } from 'lib/styling/colors';

const StyledDeleteButton = styled(ActionMenuButton)(({ theme }) => ({
  color: theme.palette.danger,
  ':hover, :focus': {
    backgroundColor: rgba(theme.palette.danger, 0.08)
  }
}));

export default StyledDeleteButton;
