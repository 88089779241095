import { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from './UserContext';
import App from 'lib/App';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import Roles from 'lib/constants/Roles';
import UserSelectFieldWrapper from './UserSelectFieldWrapper';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const RoleSelect = ({ disabled, ...props }) => {
  const user = useContext(UserContext);
  const userUsername = App.authentication.useUserUsername(user);

  const [selectedRole, setSelectedRole] = useSyncedState(user.role);

  const saveRoleChange = () => {
    ref(`users/${userUsername}/role`).set(selectedRole);
  };

  return (
    <UserSelectFieldWrapper>
      <Select
        value={selectedRole}
        onChange={e => setSelectedRole(e.target.value)}
        disabled={disabled}
        style={{ width: 132 }}
        {...props}
      >
        {Object.values(Roles)
          .filter(role => role !== Roles.ADMIN || user.role === Roles.ADMIN)
          .map(role => (
            <MenuItem value={role} key={role}>
              <FormattedMessage id={`user_roles.${role}`} />
            </MenuItem>
          ))}
      </Select>
      <IconButton
        disabled={selectedRole === user.role || disabled}
        onClick={saveRoleChange}
      >
        <CheckIcon />
      </IconButton>
    </UserSelectFieldWrapper>
  );
};

RoleSelect.propTypes = {
  disabled: PropTypes.bool
};

RoleSelect.defaultProps = {
  disabled: false
};

export default RoleSelect;
