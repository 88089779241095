import App from 'lib/App';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import getSortedVisibleUsers from './getSortedVisibleUsers';

const useUsers = (selectedUser, selectedGroupKey) => {
  const currentUser = App.authentication.useCurrentUser();
  const [loading, users] = useFirebaseValue(ref('users'));

  if (loading) {
    return [true, null];
  }

  const visibleUsers = getSortedVisibleUsers(
    users,
    selectedGroupKey,
    currentUser
  );

  const selectedUserIndex = selectedUser
    ? visibleUsers.findIndex(user => selectedUser.email === user.email)
    : null;

  const previousUser =
    selectedUserIndex === null ? null : visibleUsers[selectedUserIndex - 1];
  const nextUser =
    selectedUserIndex === null ? null : visibleUsers[selectedUserIndex + 1];

  return [
    false,
    {
      all: users,
      visible: visibleUsers,
      previous: previousUser,
      next: nextUser
    }
  ];
};

export default useUsers;
