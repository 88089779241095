import { useContext } from 'react';
import MomentPropTypes from 'react-moment-proptypes';
import VisibleWeekRangeContext from '../../VisibleWeekRangeContext';
import { useMediaQuery } from '@material-ui/core';
import MarkerWrapper from './MarkerWrapper';
import ContentWrapper from './ContentWrapper';
import WeekLabel from './WeekLabel';
import WeekDateRange from './WeekDateRange';
import DayMarkers from './DayMarkers';

const WeekMarker = ({ weekStartDate, ...props }) => {
  const { isZoomedInOnWeek, zoomInOnWeek } = useContext(
    VisibleWeekRangeContext
  );

  const disableZoom = useMediaQuery('(max-width: 700px)');

  return (
    <MarkerWrapper
      hasZoom={isZoomedInOnWeek}
      onClick={() => zoomInOnWeek(weekStartDate)}
      disabled={isZoomedInOnWeek || disableZoom}
      {...props}
    >
      <ContentWrapper className="content-wrapper">
        <WeekLabel weekStartDate={weekStartDate} />
        <WeekDateRange weekStartDate={weekStartDate} />
      </ContentWrapper>
      {isZoomedInOnWeek && <DayMarkers />}
    </MarkerWrapper>
  );
};

WeekMarker.propTypes = {
  weekStartDate: MomentPropTypes.momentObj.isRequired
};

export default WeekMarker;
