import { useContext } from 'react';
import SloContext from '../SloContext';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { userRef } from 'lib/firebase';
import UserAvatar from 'lib/components/UserAvatar';

const SloUserAvatar = props => {
  const slo = useContext(SloContext);
  const [loading, user] = useFirebaseValue(userRef(slo.user));

  if (loading) {
    return null;
  }

  return <UserAvatar user={user} size={24} {...props} />;
};

export default SloUserAvatar;
