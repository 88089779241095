import { useState } from 'react';
import PropTypes from 'prop-types';
import MakeCreateRowVisibleOnHover from './MakeCreateRowVisibleOnHover';
import CreateRow from './CreateRow';
import CreateChallengeButton from './CreateChallengeButton';
import ChallengeCreationDialog from './ChallengeCreationDialog';

const CreateChallengeWithRowSelection = ({
  rowKeys,
  highestRowUiPos,
  ...props
}) => {
  const [
    challengeCreationDialogOpen,
    setChallengeCreationDialogOpen
  ] = useState(false);

  return (
    <MakeCreateRowVisibleOnHover {...props}>
      <CreateRow highestRowUiPos={highestRowUiPos} />
      <CreateChallengeButton
        onClick={() => setChallengeCreationDialogOpen(true)}
      />
      <ChallengeCreationDialog
        open={challengeCreationDialogOpen}
        onClose={() => setChallengeCreationDialogOpen(false)}
        rowKeys={rowKeys}
        highestRowUiPos={highestRowUiPos}
      />
    </MakeCreateRowVisibleOnHover>
  );
};

CreateChallengeWithRowSelection.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  highestRowUiPos: PropTypes.number.isRequired
};

export default CreateChallengeWithRowSelection;
