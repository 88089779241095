import { Button } from '@material-ui/core';
import LoadingSpinner from 'lib/components/LoadingSpinner';

const ButtonLoadingSpinner = props => (
  <Button variant="outlined" style={{ minWidth: 148 }} disabled {...props}>
    <LoadingSpinner margin={3} size={18} />
  </Button>
);

export default ButtonLoadingSpinner;
