import { useContext } from 'react';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';
import ToggleWrapper from './ToggleWrapper';
import SettingTitle from '../SettingTitle';
import { ButtonGroup } from '@material-ui/core';
import SelectableButton from './SelectableButton';
import { FormattedMessage } from 'react-intl';

const IsViewingAsExpertToggle = props => {
  const { isViewingAsExpert, setIsViewingAsExpert } = useContext(
    ExpertModeSettingsContext
  );

  return (
    <ToggleWrapper {...props}>
      <SettingTitle>
        <FormattedMessage id="coach.expert_mode_settings_bar.is_viewing_as_expert_toggle.title" />
      </SettingTitle>
      <ButtonGroup size="small">
        <SelectableButton
          selected={!isViewingAsExpert}
          onClick={() => setIsViewingAsExpert(false)}
        >
          <FormattedMessage id="coach.expert_mode_settings_bar.is_viewing_as_expert_toggle.buttons.coach" />
        </SelectableButton>
        <SelectableButton
          selected={isViewingAsExpert}
          onClick={() => setIsViewingAsExpert(true)}
        >
          <FormattedMessage id="coach.expert_mode_settings_bar.is_viewing_as_expert_toggle.buttons.expert" />
        </SelectableButton>
      </ButtonGroup>
    </ToggleWrapper>
  );
};

export default IsViewingAsExpertToggle;
