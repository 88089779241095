import styled from '@emotion/styled';

const NoUserResults = styled('h4')(({ theme }) => ({
  padding: '10px 15px',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontWeight: 400
}));

export default NoUserResults;
