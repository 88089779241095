import PropTypes from 'prop-types';
import SettingsBoxWrapper from './SettingsBoxWrapper';
import SettingsBoxTitle from './SettingsBoxTitle';
import SettingsBoxIcon from './SettingsBoxIcon';

const SettingsBox = ({ title, icon, children, ...props }) => (
  <SettingsBoxWrapper {...props}>
    <SettingsBoxTitle>
      <SettingsBoxIcon>{icon}</SettingsBoxIcon>
      {title}
    </SettingsBoxTitle>
    {children}
  </SettingsBoxWrapper>
);

SettingsBox.propTypes = {
  title: PropTypes.node.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node
};

export default SettingsBox;
