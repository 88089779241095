import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledButton = styled(Button)({
  position: 'absolute',
  top: 18,
  left: 'calc(20% + 12px)',
  zIndex: 1,
  [mediaQuery(700)]: {
    display: 'none'
  }
});

export default StyledButton;
