import PropTypes from 'prop-types';
import { RichUtils } from 'draft-js';
import StyledToolbar from './StyledToolbar';
import ToolbarButton from './ToolbarButton';
import { inlineStyles, blockTypes } from './toolbarOptions';
import ToolbarItemsSeparator from './ToolbarItemsSeparator';
import LinkControls from './LinkControls';
import Space from 'lib/components/Space';
import CancelButton from './CancelButton';
import SaveButton from './SaveButton';

const EditorToolbar = ({
  editorState,
  setEditorState,
  onCancel,
  onSave,
  saveDisabled,
  focusOnEditor,
  ...props
}) => {
  const toggleInlineStyle = inlineStyleIdentifier => {
    setEditorState(editorState =>
      RichUtils.toggleInlineStyle(editorState, inlineStyleIdentifier)
    );
  };

  const toggleBlockType = blockTypeIdentifier => {
    setEditorState(editorState =>
      RichUtils.toggleBlockType(editorState, blockTypeIdentifier)
    );
  };

  const currentInlineStyles = editorState.getCurrentInlineStyle();

  const currentBlockType = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    .getType();

  return (
    <StyledToolbar {...props}>
      {inlineStyles.map(style => (
        <ToolbarButton
          label={style.label}
          icon={style.icon}
          selected={currentInlineStyles.has(style.identifier)}
          onMouseDown={e => {
            e.preventDefault();

            toggleInlineStyle(style.identifier);
          }}
          key={style.identifier}
        />
      ))}
      <ToolbarItemsSeparator />
      {blockTypes.map(type => (
        <ToolbarButton
          label={type.label}
          icon={type.icon}
          selected={currentBlockType === type.identifier}
          onMouseDown={e => {
            e.preventDefault();

            toggleBlockType(type.identifier);
          }}
          key={type.identifier}
        />
      ))}
      <ToolbarItemsSeparator />
      <LinkControls
        editorState={editorState}
        setEditorState={setEditorState}
        focusOnEditor={focusOnEditor}
      />
      <Space width="auto" />
      {onCancel && <CancelButton onClick={onCancel} />}
      <SaveButton onClick={onSave} disabled={saveDisabled} />
    </StyledToolbar>
  );
};

EditorToolbar.propTypes = {
  editorState: PropTypes.object.isRequired,
  setEditorState: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  saveDisabled: PropTypes.bool.isRequired,
  focusOnEditor: PropTypes.func.isRequired
};

export default EditorToolbar;
