import { Tooltip } from '@material-ui/core';
import StyledClearButton from './StyledClearButton';
import { Clear as ClearIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ClearButton = props => (
  <Tooltip
    title={
      <FormattedMessage id="editor.model_editor.groups_manager.clear_button" />
    }
  >
    <StyledClearButton {...props}>
      <ClearIcon />
    </StyledClearButton>
  </Tooltip>
);

export default ClearButton;
