import PropTypes from 'prop-types';
import { FormControl, FormLabel, FormGroup } from '@material-ui/core';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const StandardGroupsCheckboxesWrapper = ({ children, ...props }) => (
  <FormControl component="fieldset" style={{ display: 'block' }} {...props}>
    <FormLabel component="legend">
      <FormattedMessage id="dialogs.checklist_prompt_with_group_select.standard_groups.label" />
    </FormLabel>
    <Space height={8} />
    <FormGroup>{children}</FormGroup>
  </FormControl>
);

StandardGroupsCheckboxesWrapper.propTypes = {
  children: PropTypes.node
};

export default StandardGroupsCheckboxesWrapper;
