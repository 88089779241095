import PropTypes from 'prop-types';
import App from 'lib/App';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const FormattedMessageWithChallengeNaming = ({ values, hasHtml, ...props }) => {
  const FormattedMessageComponent = hasHtml
    ? FormattedHTMLMessage
    : FormattedMessage;

  const {
    challengeNameSingular,
    challengeNameSingularCapitalized,
    challengeNamePlural
  } = App.instanceConfig.useChallengeNaming();

  return (
    <FormattedMessageComponent
      values={{
        challengeNameSingular,
        challengeNameSingularCapitalized,
        challengeNamePlural,
        ...values
      }}
      {...props}
    />
  );
};

FormattedMessageWithChallengeNaming.propTypes = {
  values: PropTypes.object,
  hasHtml: PropTypes.bool
};

FormattedMessageWithChallengeNaming.defaultProps = {
  hasHtml: false
};

export default FormattedMessageWithChallengeNaming;
