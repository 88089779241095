import { useDrop } from 'react-dnd';
import DragTypes from '../../../DragTypes';
import getDropResult from './getDropResult';

const usePhaseDrop = (phase, row, inTaskBoard) => {
  const [, attachDropHandlingRef] = useDrop({
    accept: inTaskBoard ? DragTypes.TASK : DragTypes.CHALLENGE,
    drop: item => {
      return getDropResult(item, phase, row, inTaskBoard);
    }
  });
  return attachDropHandlingRef;
};

export default usePhaseDrop;
