import PropTypes from 'prop-types';
import TitleWrapper from './TitleWrapper';
import DialogTitle from './DialogTitle';
import TitleSubtopicIcon from './TitleSubtopicIcon';
import CloseDialogButton from './CloseDialogButton';

const TitleWithSubtopicIcon = ({
  subtopicIcon,
  onClose,
  children,
  ...props
}) => (
  <TitleWrapper {...props}>
    {subtopicIcon && <TitleSubtopicIcon src={subtopicIcon} alt="" />}
    <DialogTitle>{children}</DialogTitle>
    <CloseDialogButton onClick={onClose} />
  </TitleWrapper>
);

TitleWithSubtopicIcon.propTypes = {
  subtopicIcon: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default TitleWithSubtopicIcon;
