import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import withSloPeriodExpandedState from './withSloPeriodExpandedState';

const SloPeriodWrapperHeader = styled('header')(
  ({ theme, clickable, expanded }) => ({
    padding: expanded ? 18 : 0,
    paddingBottom: 18,
    height: 56,
    borderBottom: `2px solid ${
      expanded ? theme.palette.divider : theme.palette.action.hover
    }`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    cursor: clickable ? 'pointer' : null,
    transition: theme.transitions.create()
  }),
  addHighContrastStyles(({ theme }) => ({
    borderBottomColor: theme.palette.divider
  }))
);

SloPeriodWrapperHeader.propTypes = {
  clickable: PropTypes.bool,
  expanded: PropTypes.bool.isRequired
};

SloPeriodWrapperHeader.defaultProps = {
  clickable: false
};

export default withSloPeriodExpandedState(SloPeriodWrapperHeader);
