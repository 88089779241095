import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import PageTitle from 'lib/components/PageTitle';
import Space from 'lib/components/Space';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ChallengeModelEditor from './ChallengeModelEditor';
import RubricsPortfolioTemplatesEditor from './RubricsPortfolioTemplatesEditor';

const EditorPage = () => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  return (
    <>
      <PageTitle id="editor.page_title" />
      <Space height={24} />
      {selectedPlatform === PlatformTypes.CHALLENGEMONITOR ? (
        <ChallengeModelEditor />
      ) : (
        <RubricsPortfolioTemplatesEditor />
      )}
    </>
  );
};

export default EditorPage;
