import PropTypes from 'prop-types';
import useKeydownListener from 'lib/utils/useKeydownListener';

const ArrowKeyListeners = ({
  onLeftArrowPress,
  onRightArrowPress,
  children
}) => {
  useKeydownListener(document, event => {
    if (event.key === 'ArrowLeft') {
      onLeftArrowPress();
    }

    if (event.key === 'ArrowRight') {
      onRightArrowPress();
    }
  });

  return children;
};

ArrowKeyListeners.propTypes = {
  onLeftArrowPress: PropTypes.func.isRequired,
  onRightArrowPress: PropTypes.func.isRequired,
  children: PropTypes.node
};

export default ArrowKeyListeners;
