import styled from '@emotion/styled';
import { ErrorOutline as MuiErrorIcon } from '@material-ui/icons';
import mediaQuery from 'lib/styling/mediaQuery';

const ErrorIcon = styled(MuiErrorIcon)(({ theme }) => ({
  marginRight: 48,
  width: 120,
  height: 120,
  color: theme.palette.danger,
  transition: theme.transitions.create(),
  [mediaQuery(768)]: {
    marginRight: 24,
    width: 88,
    height: 88
  },
  [mediaQuery(540)]: {
    display: 'none'
  }
}));

export default ErrorIcon;
