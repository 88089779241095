import { useContext } from 'react';
import PhaseContext from '../PhaseContext';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import getHighestFieldUiPos from '../getHighestFieldUiPos';

const useAllHighestUiPositions = () => {
  const { phase: currentPhase } = useContext(PhaseContext);

  const allHighestUiPositions = {
    [PhaseContentGroups.LEFT_CONTENT]: getHighestFieldUiPos(
      currentPhase[PhaseContentGroups.LEFT_CONTENT]
    ),
    [PhaseContentGroups.RIGHT_CONTENT]: getHighestFieldUiPos(
      currentPhase[PhaseContentGroups.RIGHT_CONTENT]
    ),
    [PhaseContentGroups.END_CONTENT]: getHighestFieldUiPos(
      currentPhase[PhaseContentGroups.END_CONTENT]
    )
  };

  return allHighestUiPositions;
};

export default useAllHighestUiPositions;
