import styled from '@emotion/styled';

const MakeCreateRowVisibleOnHover = styled('div')(({ theme }) => ({
  position: 'fixed',
  right: 17,
  bottom: 17,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  zIndex: 2,
  '#create-row-button': {
    marginBottom: 0,
    height: 0,
    overflow: 'hidden'
  },
  '#create-row-button #button-label': {
    opacity: 0,
    transition: 'all 1200ms cubic-bezier(.23, 1, .32, 1) 0ms'
  },
  '#create-row-button #icon-wrapper': {
    transform: 'scale(0)',
    transition: theme.transitions.create()
  },
  ':hover #create-row-button, #create-row-button:focus': {
    marginBottom: 16,
    height: 'auto',
    overflow: 'visible'
  },
  ':hover #create-row-button #button-label, #create-row-button:focus #button-label': {
    opacity: 1
  },
  ':hover #create-row-button #icon-wrapper, #create-row-button:focus #icon-wrapper': {
    transform: 'none'
  }
}));

export default MakeCreateRowVisibleOnHover;
