import useDefaultChallengeNaming from './useDefaultChallengeNaming';
import App from 'lib/App';
import capitalize from 'capitalize';

const useChallengeNaming = () => {
  const {
    defaultChallengeNameSingular,
    defaultChallengeNamePlural
  } = useDefaultChallengeNaming();

  const customChallengeNaming = App.instanceConfig.useContentSetting(
    'customChallengeNaming'
  );

  if (!customChallengeNaming.enabled) {
    return {
      challengeNameSingular: defaultChallengeNameSingular,
      challengeNameSingularCapitalized: capitalize(
        defaultChallengeNameSingular
      ),
      challengeNamePlural: defaultChallengeNamePlural
    };
  }

  const {
    customChallengeNameSingular,
    customChallengeNamePlural
  } = customChallengeNaming;

  const challengeNameSingular =
    customChallengeNameSingular || defaultChallengeNameSingular;

  return {
    challengeNameSingular,
    challengeNameSingularCapitalized: capitalize(challengeNameSingular),
    challengeNamePlural: customChallengeNamePlural || defaultChallengeNamePlural
  };
};

export default useChallengeNaming;
