import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import usePortfolio from '../utils/usePortfolio';
import useRubricsFieldParts from 'pages/EditorPage/ChallengeModelEditor/ModelEditorForModelType/ModelPhaseEditor/ModelFieldEditor/TypeSpecificEditors/RubricsEditor/useRubricsFieldParts';
import FieldName from '../utils/FieldName';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import Part from 'components/RubricsPortfolio/Portfolio/Part';
import RubricsLoadingSpinner from './RubricsLoadingSpinner';

const Rubrics = () => {
  const { field, challenge, modelType } = useContext(FieldContext);
  const [partsLoading, parts, portfolioTemplates] = useRubricsFieldParts(
    field.key,
    modelType
  );
  const [portfolioLoading, portfolio] = usePortfolio();
  return (
    <section>
      <FieldName />
      {partsLoading || portfolioLoading ? (
        <RubricsLoadingSpinner />
      ) : (
        <section>
          <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
            <PortfolioContext.Provider value={portfolio}>
              {parts.map((part, i) => (
                <Part
                  part={part}
                  partSubpath={challenge.key}
                  noFocusButton
                  mobileOutlined
                  key={part.id}
                />
              ))}
            </PortfolioContext.Provider>
          </PortfolioTemplatesContext.Provider>
        </section>
      )}
    </section>
  );
};

export default Rubrics;
