import App from 'lib/App';
import { ref, rowRef, userRef } from 'lib/firebase';
import fixChallengeDates from 'lib/utils/fixChallengeDates';

const useCreateChallenge = () => {
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const createChallenge = (name, phase, rowKey) => {
    const challenge = fixChallengeDates({
      name,
      phase,
      row: {
        [currentUserUsername]: rowKey
      },
      originalOwner: currentUserUsername,
      creationDate: Date.now(),
      author: currentUserUsername
    });

    const key = ref('challenges').push(challenge).key;
    rowRef(rowKey).child(`challenges/${phase}/${key}`).set(true);

    userRef(currentUserUsername).child(`authoredChallenges/${key}`).set(true);
  };

  return createChallenge;
};

export default useCreateChallenge;
