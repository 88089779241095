import { useContext } from 'react';
import PropTypes from 'prop-types';
import CollapsedRowsContext from '../CollapsedRowsContext';
import StyledButton from './StyledButton';
import CollapseIcon from './CollapseIcon';
import { FormattedMessage } from 'react-intl';

const CollapseAllRowsButton = ({ rowKeys, ...props }) => {
  const { isRowCollapsed, collapseAllRows, uncollapseAllRows } = useContext(
    CollapsedRowsContext
  );

  if (rowKeys.length <= 1) {
    return null;
  }

  const shouldUncollapseAllRows = rowKeys.reduce(
    (allRowsCollapsed, rowKey) => allRowsCollapsed && isRowCollapsed(rowKey),
    true
  );

  const toggleAllRowsCollapsed = () => {
    if (shouldUncollapseAllRows) {
      uncollapseAllRows();
      return;
    }

    collapseAllRows();
  };

  return (
    <StyledButton color="primary" onClick={toggleAllRowsCollapsed}>
      <CollapseIcon rotated={shouldUncollapseAllRows} />
      <FormattedMessage
        id={
          shouldUncollapseAllRows
            ? 'portfolio.challenge_portfolio.collapse_all_rows_button.uncollapse'
            : 'portfolio.challenge_portfolio.collapse_all_rows_button'
        }
      />
    </StyledButton>
  );
};

CollapseAllRowsButton.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CollapseAllRowsButton;
