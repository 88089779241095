import { useContext } from 'react';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';
import App from 'lib/App';
import SettingTitle from '../SettingTitle';
import { Select, MenuItem } from '@material-ui/core';
import ExpertiseName from './ExpertiseName';
import { FormattedMessage } from 'react-intl';

const ActiveExpertiseKeySelect = () => {
  const { activeExpertiseKey, setActiveExpertiseKey } = useContext(
    ExpertModeSettingsContext
  );

  const currentUser = App.authentication.useCurrentUser();
  const expertiseKeys = Object.keys(currentUser.expertiseKeys);

  return (
    <>
      <SettingTitle>
        <FormattedMessage id="coach.expert_mode_settings_bar.active_expertise_key_select.title" />
      </SettingTitle>
      <Select
        value={activeExpertiseKey}
        onChange={e => setActiveExpertiseKey(e.target.value)}
        style={{
          width: 120
        }}
      >
        {expertiseKeys.map(expertiseKey => (
          <MenuItem value={expertiseKey} key={expertiseKey}>
            <ExpertiseName expertiseKey={expertiseKey} />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default ActiveExpertiseKeySelect;
