import RowActionButton from '../RowActionButton';
import { Delete as DeleteIcon } from '@material-ui/icons';

const DeleteRowButton = props => (
  <RowActionButton dangerous {...props}>
    <DeleteIcon />
  </RowActionButton>
);

export default DeleteRowButton;
