import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloPeriodContext from '../SloPeriodContext';
import SloPeriodWrapper from '../SloPeriodWrapper';
import EditableSloPeriodName from '../EditableSloPeriodName';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import DeleteSloPeriod from './DeleteSloPeriod';
import Space from 'lib/components/Space';
import SloCount from './SloCount';
import SlosCollapseButton from './SlosCollapseButton';

const SloPeriodHeader = ({ slosCollapsed, setSlosCollapsed, ...props }) => {
  const sloPeriod = useContext(SloPeriodContext);
  return (
    <SloPeriodWrapper.Header
      onClick={() => setSlosCollapsed(!slosCollapsed)}
      clickable
      {...props}
    >
      <EditableSloPeriodName />
      <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
        <DeleteSloPeriod />
      </RestrictedToPortfolioMode>
      <Space width="auto" />
      <SloCount sloPeriod={sloPeriod} />
      <SlosCollapseButton
        slosCollapsed={slosCollapsed}
        setSlosCollapsed={setSlosCollapsed}
      />
    </SloPeriodWrapper.Header>
  );
};

SloPeriodHeader.propTypes = {
  slosCollapsed: PropTypes.bool.isRequired,
  setSlosCollapsed: PropTypes.func.isRequired
};

export default SloPeriodHeader;
