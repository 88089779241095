import PropTypes from 'prop-types';
import { ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import FlagIcon from '../../FlagIcon';
import { supportedLanguages } from 'lib/utils/getLanguage';

const removeDisabledStateTransparencyStyles = {
  opacity: 1
};

const LanguageItem = ({
  language,
  languageName,
  languageNameTranslated,
  selectedLanguage,
  setSelectedLanguage,
  ...props
}) => (
  <ListItem
    onClick={() => setSelectedLanguage(language)}
    selected={selectedLanguage === language}
    disabled={selectedLanguage === language}
    style={removeDisabledStateTransparencyStyles}
    button
  >
    <ListItemAvatar>
      <FlagIcon language={language} />
    </ListItemAvatar>
    <ListItemText primary={languageName} secondary={languageNameTranslated} />
  </ListItem>
);

LanguageItem.propTypes = {
  language: PropTypes.oneOf(supportedLanguages).isRequired,
  languageName: PropTypes.node.isRequired,
  languageNameTranslated: PropTypes.node,
  selectedLanguage: PropTypes.oneOf(supportedLanguages).isRequired,
  setSelectedLanguage: PropTypes.func.isRequired
};

export default LanguageItem;
