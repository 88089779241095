import App from 'lib/App';
import AuthProviders from 'lib/constants/AuthProviders';
import fetchMicrosoftProfilePicture from './fetchMicrosoftProfilePicture';

const useGetPhotoUrl = () => {
  const useProfilePictures = App.instanceConfig.useContentSetting(
    'useProfilePictures'
  );
  const authProvider = App.instanceConfig.useSetting('authProvider');

  const getPhotoUrl = (user, credential) => {
    if (!useProfilePictures) {
      return null;
    }

    if (authProvider !== AuthProviders.MICROSOFT) {
      return user.photoURL;
    }

    return fetchMicrosoftProfilePicture(credential);
  };

  return getPhotoUrl;
};

export default useGetPhotoUrl;
