import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const TopicWithDraggingTransparency = styled('section')(
  ({ theme, collapsed, dragging }) => ({
    opacity: dragging ? 0.5 : 1,
    transition: theme.transitions.create(),
    ':last-of-type': {
      marginBottom: collapsed ? -24 : null
    }
  })
);

TopicWithDraggingTransparency.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  dragging: PropTypes.bool.isRequired
};

export default TopicWithDraggingTransparency;
