import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const SloName = styled('h4')(
  ({ faded }) => ({
    marginRight: 'auto',
    fontWeight: faded ? 400 : 500,
    opacity: faded ? 0.8 : 1
  }),
  addHighContrastStyles({
    opacity: 1
  })
);

SloName.propTypes = {
  faded: PropTypes.bool.isRequired
};

export default SloName;
