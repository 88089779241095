import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';
import StyledFocusIcon from './StyledFocusIcon';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import PortfolioModes from 'lib/constants/PortfolioModes';
import StyledFocusButton from './StyledFocusButton';

const FocusButton = ({ hasFocus, partId, ...props }) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);

  const toggleFocus = () => {
    if (!portfolioIsReadOnly && portfolioMode === PortfolioModes.STUDENT) {
      ref(`portfolios/${portfolioUserUsername}/focus/${partId}`).set(!hasFocus);
    }
  };

  return (
    <StyledFocusButton
      disabled={portfolioIsReadOnly || portfolioMode === PortfolioModes.COACH}
      onClick={toggleFocus}
      {...props}
    >
      <StyledFocusIcon hasFocus={hasFocus} />
    </StyledFocusButton>
  );
};

FocusButton.propTypes = {
  hasFocus: PropTypes.bool.isRequired,
  partId: PropTypes.string.isRequired
};

export default FocusButton;
