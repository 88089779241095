import getSubtopicWithCorrespondingPartsAndProgress from './getSubtopicWithCorrespondingPartsAndProgress';

const getSubtopicsWithCorrespondingPartsAndProgress = (
  subtopics,
  portfolioTemplates,
  portfolio,
  challenge
) => {
  if (!subtopics || !portfolioTemplates || !portfolioTemplates.parts) {
    return {};
  }

  const subtopicsWithCorrespondingPartsAndProgress = {};

  for (const subtopicKey in subtopics) {
    const subtopic = subtopics[subtopicKey];
    subtopicsWithCorrespondingPartsAndProgress[
      subtopicKey
    ] = getSubtopicWithCorrespondingPartsAndProgress(
      subtopicKey,
      subtopic,
      portfolioTemplates,
      portfolio,
      challenge
    );
  }

  return subtopicsWithCorrespondingPartsAndProgress;
};

export default getSubtopicsWithCorrespondingPartsAndProgress;
