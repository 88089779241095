import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import StyledFieldWrapper from './StyledFieldWrapper';
import FieldName from '../utils/FieldName';
import InnerSelect from './InnerSelect';
import { safeEntries } from 'lib/utils/safeObjectFunctions';

const SelectField = () => {
  const portfolioMode = useContext(PortfolioModeContext);

  const { field } = useContext(FieldContext);
  const options = safeEntries(field.options);

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <StyledFieldWrapper>
      <FieldName />
      <InnerSelect disabled={portfolioMode === PortfolioModes.COACH} />
    </StyledFieldWrapper>
  );
};

export default SelectField;
