import { useState } from 'react';
import PropTypes from 'prop-types';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Close as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const DeleteGroup = ({ group, ...props }) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteGroupFromUsers = (users, groupsPath) =>
    safeKeys(users).map(username =>
      ref(`users/${username}/${groupsPath}/${group.key}`).remove()
    );

  const deleteGroup = () => {
    setConfirmationDialogOpen(false);

    deleteGroupFromUsers(group.users, 'groups');
    deleteGroupFromUsers(group.managers, 'managedGroups');
    ref(`groups/${group.key}`).remove();
  };

  return (
    <>
      <DangerousIconButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="group_manager.group.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="group_manager.group.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteGroup} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteGroup.propTypes = {
  group: PropTypes.shape({
    key: PropTypes.string.isRequired,
    users: PropTypes.objectOf(PropTypes.bool),
    managers: PropTypes.objectOf(PropTypes.bool)
  })
};

export default DeleteGroup;
