import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const GapminderPlaceholder = styled('section')(({ theme, visible }) => ({
  height: 114,
  backgroundColor: theme.palette.background.shaded,
  borderRadius: 8,
  display: visible ? 'block' : 'none'
}));

GapminderPlaceholder.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default GapminderPlaceholder;
