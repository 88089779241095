import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rgba } from 'lib/styling/colors';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledShade = styled('section')(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: rgba(theme.palette.text.primary, 0.024),
    borderRight: `1px solid ${rgba(theme.palette.text.primary, 0.036)}`,
    boxSizing: 'border-box',
    transition: theme.transitions.simple,
    zIndex: -1,
    [mediaQuery(700)]: {
      borderRight: `2px solid ${rgba(theme.palette.text.primary, 0.36)}`
    }
  }),
  ({ width }) => {
    const actualWidth = Math.max(width, 0);
    return {
      width: `${actualWidth}%`,
      borderRight: actualWidth === 0 ? 'none !important' : null
    };
  },
  addHighContrastStyles(({ theme }) => ({
    borderRightColor: theme.palette.divider
  }))
);

StyledShade.propTypes = {
  width: PropTypes.number.isRequired
};

export default StyledShade;
