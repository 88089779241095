import { useContext } from 'react';
import SloContext from '../../SloContext';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';
import { ref } from 'lib/firebase';
import useLivePortfolio from 'components/RubricsPortfolio/PortfolioWithHistoryProvider/useSelectedPortfolio/useLivePortfolio';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import Part from 'components/RubricsPortfolio/Portfolio/Part';

const AttachedParts = () => {
  const slo = useContext(SloContext);

  const [
    portfolioTemplatesLoading,
    portfolioTemplates
  ] = useUnsyncedFirebaseValue(ref('portfolioTemplates'));

  const [, portfolio] = useLivePortfolio();

  if (portfolioTemplatesLoading) {
    return null;
  }

  const attachedPartsIds = safeKeys(slo.attachedParts);
  const attachedParts = attachedPartsIds
    .map(partId => portfolioTemplates?.parts?.[partId])
    .filter(part => !!part);

  return (
    <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
      <PortfolioContext.Provider value={portfolio}>
        <section>
          {attachedParts.map(part => (
            <Part part={part} mobileOutlined key={part.id} />
          ))}
        </section>
      </PortfolioContext.Provider>
    </PortfolioTemplatesContext.Provider>
  );
};

export default AttachedParts;
