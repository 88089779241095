import { useState } from 'react';
import PropTypes from 'prop-types';
import App from 'lib/App';
import UserSearchWrapper from './UserSearchWrapper';
import UserSearchTextField from './UserSearchTextField';

const UserSearch = ({ onSearch, ...props }) => {
  const [searchInput, setSearchInput] = useState('');

  const search = e => {
    e.preventDefault();
    onSearch(searchInput);
  };

  const clearSearch = () => {
    setSearchInput('');
    onSearch(null);
  };

  const expertSystemEnabled = App.instanceConfig.useContentSetting(
    'enableExpertSystem'
  );

  return (
    <UserSearchWrapper
      expertSystemEnabled={expertSystemEnabled}
      onSubmit={search}
      {...props}
    >
      <UserSearchTextField
        value={searchInput}
        onChange={e => {
          const value = e.target.value;
          setSearchInput(value);

          if (value.trim() === '') {
            clearSearch();
          }
        }}
        onClearSearch={clearSearch}
        onSearchClick={() => onSearch(searchInput)}
      />
    </UserSearchWrapper>
  );
};

UserSearch.propTypes = {
  onSearch: PropTypes.func.isRequired
};

export default UserSearch;
