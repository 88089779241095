import styled from '@emotion/styled';
import PhaseName from './PhaseName';
import PhaseContentWrapper from './PhaseContentWrapper';
import LeftContent from './LeftContent';
import RightContent from './RightContent';
import EndContent from './EndContent';

const Phase = styled('section')({
  marginBottom: 32,
  ':last-child': {
    marginBottom: 0
  }
});

Phase.Name = PhaseName;
Phase.ContentWrapper = PhaseContentWrapper;
Phase.LeftContent = LeftContent;
Phase.RightContent = RightContent;
Phase.EndContent = EndContent;

export default Phase;
