import PropTypes from 'prop-types';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import StyledText from './StyledText';
import { FormattedMessage } from 'react-intl';

const GroupUsersText = ({ group, ...props }) => {
  const userCount = safeValues(group.users).length;
  return (
    <StyledText>
      {userCount === 1 ? (
        <FormattedMessage id="group_manager.group.user_count_singular" />
      ) : (
        <FormattedMessage
          id="group_manager.group.user_count_plural"
          values={{
            userCount
          }}
        />
      )}
    </StyledText>
  );
};

GroupUsersText.propTypes = {
  group: PropTypes.shape({
    users: PropTypes.objectOf(PropTypes.bool)
  }).isRequired
};

export default GroupUsersText;
