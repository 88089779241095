import { useContext } from 'react';
import App from 'lib/App';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import PlatformTypes from 'lib/constants/PlatformTypes';
import getOtherPlatformType from './getOtherPlatformType';
import { Tooltip } from '@material-ui/core';
import HeaderActionIconButton from '../HeaderActionIconButton';
import PlatformIcon from './PlatformIcon';
import { FormattedMessage } from 'react-intl';
import { modesWithPlatformDifferences } from 'App';

const PlatformSwitch = props => {
  const [selectedPlatform, setSelectedPlatform] = useContext(
    SelectedPlatformContext
  );

  const validPlatformTypes = Object.values(PlatformTypes);
  const selectedMode = App.navigation.useSelectedMode();

  if (
    !validPlatformTypes.includes(selectedPlatform) ||
    !modesWithPlatformDifferences.includes(selectedMode)
  ) {
    return null;
  }

  const otherPlatformType = getOtherPlatformType(selectedPlatform);

  return (
    <Tooltip
      title={
        <FormattedMessage
          id={`header.platform_switch.button.tooltip.${otherPlatformType}`}
        />
      }
    >
      <HeaderActionIconButton
        onClick={() => setSelectedPlatform(otherPlatformType)}
        {...props}
      >
        <PlatformIcon platformType={otherPlatformType} />
      </HeaderActionIconButton>
    </Tooltip>
  );
};

export default PlatformSwitch;
