import styled from '@emotion/styled';
import InputWithEscListener from 'lib/components/InputWithEscListener';

const FieldNameInput = styled(InputWithEscListener)(({ theme }) => ({
  flex: 1,
  fontFamily: 'CircularStd',
  fontWeight: 500
}));

export default FieldNameInput;
