import useSettings from './useSettings';

const useSetting = settingKey => {
  const instanceConfig = useSettings();

  const settingValue = instanceConfig?.[settingKey];

  if (typeof settingValue === 'undefined') {
    return null;
  }

  return settingValue;
};

export default useSetting;
