import { useContext } from 'react';
import PropTypes from 'prop-types';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import usePortfolioUser from './usePortfolioUser';
import PlatformTypes from 'lib/constants/PlatformTypes';

const EnsureChallengePortfolioControlsDesired = ({ children }) => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const portfolioUser = usePortfolioUser();

  const isDisplayingPortfolio = !!portfolioUser;
  const controlsRelevant = !!portfolioUser?.rows;

  if (
    selectedPlatform === PlatformTypes.CHALLENGEMONITOR &&
    isDisplayingPortfolio &&
    controlsRelevant
  ) {
    return children;
  }

  return null;
};

EnsureChallengePortfolioControlsDesired.propTypes = {
  children: PropTypes.node.isRequired
};

export default EnsureChallengePortfolioControlsDesired;
