import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import Groups from 'lib/constants/Groups';
import { useIntl } from 'react-intl';

const GroupIndicatingSnackbar = ({ group, ...props }) => {
  const intl = useIntl();
  return (
    <Snackbar
      message={intl.formatMessage(
        {
          id: 'editor.portfolio_template_editor_with_group_switcher.snackbar'
        },
        {
          group: intl.formatMessage({ id: `group_names.${group}` })
        }
      )}
      {...props}
    />
  );
};

GroupIndicatingSnackbar.propTypes = {
  group: PropTypes.oneOf(Object.values(Groups)).isRequired
};

export default GroupIndicatingSnackbar;
