import { useContext } from 'react';
import FieldContext from '../../../../FieldContext';
import SelectFieldPlaceholder from 'lib/components/SelectFieldPlaceholder';
import StyledIndividualValue from './StyledIndividualValue';
import { FormattedMessage } from 'react-intl';

const useRenderSelectValueOrPlaceholder = () => {
  const { field } = useContext(FieldContext);

  const cleanValue = value => {
    if (!Array.isArray(value) && !!field.options[value]) {
      return value;
    }

    if (!Array.isArray(value)) {
      return null;
    }

    const values = value;

    return values.filter(value => !!field.options[value]);
  };

  const renderSelectValueOrPlaceholder = dirtyValue => {
    const value = cleanValue(dirtyValue);

    if (!value || value.length === 0) {
      return (
        <SelectFieldPlaceholder>
          <FormattedMessage id="portfolio.challenge.select_field.label" />
        </SelectFieldPlaceholder>
      );
    }

    if (!Array.isArray(value)) {
      const selectedOption = field.options[value];
      return selectedOption?.name;
    }

    const values = value;
    return values.map(value => (
      <StyledIndividualValue key={value}>
        {field.options[value].name}
      </StyledIndividualValue>
    ));
  };

  return renderSelectValueOrPlaceholder;
};

export default useRenderSelectValueOrPlaceholder;
