import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import StyledUserAvatar from './StyledUserAvatar';
import StyledUserSearchInput from './StyledUserSearchInput';
import SearchIcon from './SearchIcon';
import { useIntl } from 'react-intl';

const UserSearchInput = forwardRef(
  (
    { selectedUser, keyboardSelectedUser, hasFocus, onFocus, ...props },
    ref
  ) => {
    const handleFocus = () => {
      if (!hasFocus) {
        onFocus();
      }
    };

    const intl = useIntl();
    return (
      <>
        {selectedUser && !hasFocus && (
          <StyledUserAvatar user={selectedUser} size={24} />
        )}
        <StyledUserSearchInput
          placeholder={intl.formatMessage({
            id: 'user_switcher.search.placeholder'
          })}
          onFocus={handleFocus}
          aria-controls={hasFocus ? 'user-result-list' : null}
          aria-autocomplete="list"
          aria-activedescendant={
            keyboardSelectedUser ? `user-result-${keyboardSelectedUser}` : null
          }
          ref={ref}
          {...props}
        />
        <SearchIcon />
      </>
    );
  }
);

UserSearchInput.propTypes = {
  selectedUser: PropTypes.object,
  keyboardSelectedUser: PropTypes.string,
  hasFocus: PropTypes.bool.isRequired,
  onFocus: PropTypes.func.isRequired
};

export default UserSearchInput;
