import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import device from 'current-device';
import { FormattedMessage } from 'react-intl';

const PromptTextField = ({ hasEmptyError, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (hasEmptyError) {
      inputRef.current.focus();
    }
  }, [hasEmptyError]);

  return (
    <TextField
      variant="filled"
      helperText={
        hasEmptyError && <FormattedMessage id="prompt.errors.name_empty" />
      }
      error={hasEmptyError}
      inputRef={inputRef}
      autoFocus={device.desktop()}
      fullWidth
      {...props}
    />
  );
};

PromptTextField.propTypes = {
  hasEmptyError: PropTypes.bool.isRequired
};

export default PromptTextField;
