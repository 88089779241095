import getEventXOffset from './getEventXOffset';
import ensurePercentage from './ensurePercentage';

const getProgressFromMousePosition = (e, reversed, sliderRef) => {
  const userClickedViewAllExpertProgressItemsButton = !!e.target.closest(
    'button.view-all-expert-progress-items-button'
  );

  if (userClickedViewAllExpertProgressItemsButton) {
    return null;
  }

  const sliderElement = sliderRef?.current;
  if (!sliderElement) {
    return null;
  }

  const boundingRect = sliderElement.getBoundingClientRect();
  const xOffset = getEventXOffset(e);

  if (typeof xOffset !== 'number') {
    return null;
  }

  const eventOffsetFromLeftWrapperBoundary = xOffset - boundingRect.left;
  const progressWithinWrapperBoundaries =
    eventOffsetFromLeftWrapperBoundary / boundingRect.width;
  const progressPercentage = Math.round(progressWithinWrapperBoundaries * 100);

  if (reversed) {
    return ensurePercentage(100 - progressPercentage);
  }

  return ensurePercentage(progressPercentage);
};
export default getProgressFromMousePosition;
