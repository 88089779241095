import { useRef } from 'react';
import PropTypes from 'prop-types';
import useElementIsInViewport from 'lib/utils/useElementIsInViewport';
import Roles from 'lib/constants/Roles';
import UserToolbarPlaceholder from './UserToolbarPlaceholder';
import UserToolbarWrapper from './UserToolbarWrapper';
import { IconButton } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import SelectedUsersMessage from './SelectedUsersMessage';
import MultipleUsersGroupSelect from './MultipleUsersGroupSelect';
import MultipleUsersRoleSelect from './MultipleUsersRoleSelect';
import DeleteUsers from './DeleteUsers';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const UserToolbar = ({
  users,
  selectedUsers,
  onSelectedUsersClear,
  onMarkUserAsDeleted,
  ...props
}) => {
  const nonFixedToolbarStartRef = useRef(null);
  const nonFixedToolbarInViewport = useElementIsInViewport(
    nonFixedToolbarStartRef
  );
  const isToolbarFixed = !nonFixedToolbarInViewport;

  const usernames = safeKeys(selectedUsers).filter(
    username => !!users[username]
  );

  if (usernames.length === 0) {
    return null;
  }

  const selectedUsersMatchRoles = roles =>
    usernames.reduce(
      (matchSoFar, username) =>
        matchSoFar && roles.includes(users[username].role),
      true
    );

  const selectedUsersAreStudents = selectedUsersMatchRoles([Roles.STUDENT]);
  const selectedUsersAreCoaches = selectedUsersMatchRoles([
    Roles.COACH,
    Roles.EDITOR,
    Roles.HALF_ADMIN
  ]);

  return (
    <>
      <div ref={nonFixedToolbarStartRef} />
      <UserToolbarPlaceholder visible={isToolbarFixed} />
      <UserToolbarWrapper fixed={isToolbarFixed} {...props}>
        <IconButton onClick={onSelectedUsersClear}>
          <ClearIcon />
        </IconButton>
        <SelectedUsersMessage selectedUsersCount={usernames.length} />
        {selectedUsersAreStudents && (
          <MultipleUsersGroupSelect usernames={usernames} />
        )}
        {selectedUsersAreCoaches && (
          <MultipleUsersGroupSelect usernames={usernames} asManagedGroups />
        )}
        <MultipleUsersRoleSelect usernames={usernames} />
        <DeleteUsers
          usernames={usernames}
          users={users}
          onSelectedUsersClear={onSelectedUsersClear}
          onMarkUserAsDeleted={onMarkUserAsDeleted}
        />
      </UserToolbarWrapper>
    </>
  );
};

UserToolbar.propTypes = {
  users: PropTypes.objectOf(
    PropTypes.shape({
      role: PropTypes.oneOf(Object.values(Roles)).isRequired
    })
  ),
  selectedUsers: PropTypes.objectOf(PropTypes.bool).isRequired,
  onSelectedUsersClear: PropTypes.func.isRequired,
  onMarkUserAsDeleted: PropTypes.func.isRequired
};

export default UserToolbar;
