import { useState } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import StyledLevel from 'components/RubricsPortfolioComponents/StyledLevel';
import TitleField from './TitleField';
import DescriptionField from './DescriptionField';
import LevelEditorActions from './LevelEditorActions';

const LevelEditor = ({ level, onClose, ...props }) => {
  const [title, setTitle] = useSyncedState(level.title || '');
  const [description, setDescription] = useSyncedState(level.description);
  const [showEmptyError, setShowEmptyError] = useState(false);

  const hasChanged =
    title.trim() !== (level.title || '') ||
    description.trim() !== (level.description || '');

  const saveEdits = () => {
    if (description.trim().length === 0) {
      setShowEmptyError(true);
      return;
    }

    setShowEmptyError(false);
    onClose();

    if (hasChanged) {
      ref(`portfolioTemplates/levels/${level.id}`).update({
        title: title.trim(),
        description: description.trim()
      });
    }
  };

  const resetAndClose = () => {
    setTitle(level.title || '');
    setDescription(level.description);
    setShowEmptyError(false);
    onClose();
  };

  return (
    <StyledLevel {...props}>
      <TitleField value={title} onChange={e => setTitle(e.target.value)} />
      <DescriptionField
        value={description}
        onChange={e => setDescription(e.target.value)}
        showEmptyError={showEmptyError}
        autoFocus
      />
      <LevelEditorActions onClose={resetAndClose} onSave={saveEdits} />
    </StyledLevel>
  );
};

LevelEditor.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default LevelEditor;
