import useGetUsernameFromEmail from './useGetUsernameFromEmail';

const useUserUsername = user => {
  const getUsernameFromEmail = useGetUsernameFromEmail();

  if (!user?.email) {
    return null;
  }

  return getUsernameFromEmail(user.email);
};

export default useUserUsername;
