import PropTypes from 'prop-types';
import useAttachedChallenges from './useAttachedChallenges';
import AttachedChallengesWrapper from './AttachedChallengesWrapper';
import AttachedChallenge from './AttachedChallenge';

const AttachedChallenges = ({ levelKey, ...props }) => {
  const attachedChallenges = useAttachedChallenges(levelKey);

  if (!attachedChallenges) {
    return null;
  }

  return (
    <AttachedChallengesWrapper {...props}>
      {Object.entries(attachedChallenges).map(([key, { url, asTask }]) => (
        <AttachedChallenge
          challengeKey={key}
          challengeUrl={url}
          asTask={asTask}
          levelKey={levelKey}
          key={key}
        />
      ))}
    </AttachedChallengesWrapper>
  );
};

AttachedChallenges.propTypes = {
  levelKey: PropTypes.string.isRequired
};

export default AttachedChallenges;
