import { useState } from 'react';
import GlobalStateProvider from 'components/App/GlobalStateProvider';
import AppModule from 'lib/App';
import AppSetup from 'components/App/AppSetup';
import AppWrapper from 'components/App/AppWrapper';
import PageTitleTemplate from 'components/App/PageTitleTemplate';
import OutdatedBrowserDetection from 'components/App/OutdatedBrowserDetection';
import AppHeader from 'components/App/AppHeader';
import AppContentWrapper from 'components/App/AppContentWrapper';
import SupportSystem from 'components/App/SupportSystem';
import AppFooter from 'components/App/AppFooter';
import AppContentRenderer from './AppContentRenderer';
import Modes from 'lib/constants/Modes';

export const modesWithPlatformDifferences = [
  Modes.PORTFOLIO,
  Modes.COACH,
  Modes.EDITOR
];

const App = () => {
  const [challengeSearchQuery, setChallengeSearchQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  return (
    <GlobalStateProvider
      challengeSearchQuery={challengeSearchQuery}
      selectedUser={selectedUser}
    >
      <AppModule.StatePopulator>
        <AppSetup>
          <AppWrapper>
            <PageTitleTemplate />
            <OutdatedBrowserDetection />
            <AppHeader
              setChallengeSearchQuery={setChallengeSearchQuery}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
            <SupportSystem />
            <AppContentWrapper>
              <AppContentRenderer />
            </AppContentWrapper>
            <AppFooter />
          </AppWrapper>
        </AppSetup>
      </AppModule.StatePopulator>
    </GlobalStateProvider>
  );
};

export default App;
