import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledButton = styled(stripProps(Button, 'noResponsivityHiding'))(
  {
    marginRight: 12
  },
  ({ noResponsivityHiding }) => {
    if (!noResponsivityHiding) {
      return {
        [mediaQuery(1200)]: {
          display: 'none'
        }
      };
    }

    return {
      [mediaQuery(700)]: {
        marginLeft: 'auto',
        marginRight: 0,
        width: 140,
        display: 'flex'
      }
    };
  }
);

StyledButton.propTypes = {
  noResponsivityHiding: PropTypes.bool.isRequired
};

export default StyledButton;
