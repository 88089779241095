import { useState } from 'react';
import AttachChallengesButton from './AttachChallengesButton';
import ChallengeAttachmentManager from './ChallengeAttachmentManager';

const AttachChallenges = props => {
  const [
    challengeAttachmentManagerOpen,
    setChallengeAttachmentManagerOpen
  ] = useState(false);

  return (
    <>
      <AttachChallengesButton
        onClick={() => setChallengeAttachmentManagerOpen(true)}
        {...props}
      />
      <ChallengeAttachmentManager
        open={challengeAttachmentManagerOpen}
        onClose={() => setChallengeAttachmentManagerOpen(false)}
      />
    </>
  );
};

export default AttachChallenges;
