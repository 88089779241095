import { useState } from 'react';
import useInterval from 'use-interval';

const usePreventFirstTimeSignInInfiniteLoadingScreen = (
  loggedIn,
  currentUserLoading
) => {
  const [updateForcingValue, setUpdateForcingValue] = useState(Math.random());

  const forceUserListenerReattachmentToPreventInfiniteLoadingScreen = () => {
    setUpdateForcingValue(Math.random());
  };

  useInterval(() => {
    if (loggedIn && currentUserLoading) {
      forceUserListenerReattachmentToPreventInfiniteLoadingScreen();
    }
  }, 5000);

  return updateForcingValue;
};

export default usePreventFirstTimeSignInInfiniteLoadingScreen;
