import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import SloPeriodExpandedContext from './SloPeriodExpandedContext';
import SloPeriodWrapperHeader from './SloPeriodWrapperHeader';
import SloPeriodWrapperContent from './SloPeriodWrapperContent';

const StyledWrapper = styled('section')(
  ({ theme, expanded }) => ({
    marginBottom: 18,
    backgroundColor: expanded ? theme.palette.action.hover : null,
    borderRadius: expanded ? 8 : null,
    overflow: 'hidden',
    transition: theme.transitions.create(),
    ':last-child': {
      marginBottom: 0
    }
  }),
  addHighContrastStyles(({ theme, expanded }) => {
    if (expanded) {
      return {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.palette.divider}`
      };
    }
  })
);

const SloPeriodWrapper = ({ expanded, ...props }) => (
  <SloPeriodExpandedContext.Provider value={expanded}>
    <StyledWrapper expanded={expanded} {...props} />
  </SloPeriodExpandedContext.Provider>
);

SloPeriodWrapper.propTypes = {
  expanded: PropTypes.bool
};

SloPeriodWrapper.defaultProps = {
  expanded: false
};

SloPeriodWrapper.Header = SloPeriodWrapperHeader;
SloPeriodWrapper.Content = SloPeriodWrapperContent;

export default SloPeriodWrapper;
