import { useContext } from 'react';
import ModelTypeContext from '../ModelTypeContext';
import PhaseContext from './PhaseContext';
import { ref } from 'lib/firebase';

const useCurrentPhaseRef = () => {
  const modelType = useContext(ModelTypeContext);
  const { phase } = useContext(PhaseContext);
  return ref(`models/${modelType}/phases/${phase.key}`);
};

export default useCurrentPhaseRef;
