import { useContext } from 'react';
import PhaseContext from '../PhaseContext';
import PhaseContentGroupContext from '../PhaseContentGroupContext';
import getHighestFieldUiPos from '../getHighestFieldUiPos';

const useHighestFieldUiPos = () => {
  const { phase: currentPhase } = useContext(PhaseContext);
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);

  const fields = currentPhase[currentPhaseContentGroup];
  return getHighestFieldUiPos(fields);
};

export default useHighestFieldUiPos;
