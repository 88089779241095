import common from '@material-ui/core/colors/common';
import { rgba } from 'lib/styling/colors';

const getHighContrastModeThemeAdjustments = palette => {
  if (!palette.isHighContrast) {
    return null;
  }

  const highContrastStyleOverrides = {
    MuiPopover: {
      paper: {
        border: `2px solid ${palette.divider}`
      }
    },
    MuiTooltip: {
      tooltip: {
        color: palette.background.default,
        backgroundColor: palette.primary.main,
        border: `2px solid ${palette.background.default}`
      }
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderColor: palette.divider
        }
      }
    },
    MuiFilledInput: {
      root: {
        border: `1px solid ${palette.divider}`,
        borderBottom: 'none'
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: palette.divider
      }
    },
    MuiSelect: {
      icon: {
        color: palette.text.primary
      }
    },
    MuiButton: {
      outlined: {
        borderColor: palette.divider
      }
    },
    MuiListItemIcon: {
      root: {
        color: palette.text.primary
      }
    },
    MuiListItem: {
      root: {
        '&:hover, &:focus': {
          textDecoration: 'underline !important'
        },
        '&$disabled': {
          display: 'none'
        },
        '&$selected': {
          backgroundColor: palette.background.default,
          textDecoration: 'underline'
        }
      }
    },
    MuiDialog: {
      paper: {
        border: `2px solid ${palette.divider}`
      }
    },
    MuiDrawer: {
      paper: {
        borderLeft: `2px solid ${palette.divider}`
      }
    },
    MuiSkeleton: {
      root: {
        backgroundColor: palette.text.primary
      },
      wave: {
        '&::after': {
          background: `linear-gradient(to right, transparent, ${rgba(
            palette.background.default,
            0.4
          )}, transparent)`
        }
      }
    },
    MuiAppBar: {
      root: {
        borderBottom: `2px solid ${palette.divider}`
      }
    },
    MuiIconButton: {
      root: {
        '&$disabled': {
          '&:hover': {
            backgroundColor: 'transparent !important'
          }
        }
      }
    }
  };

  if (palette.type !== 'dark') {
    return {
      overrides: {
        ...highContrastStyleOverrides,
        MuiIconButton: {
          root: {
            color: common.black
          }
        }
      }
    };
  }

  return {
    overrides: highContrastStyleOverrides
  };
};

export default getHighContrastModeThemeAdjustments;
