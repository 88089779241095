import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledAddButton = styled(ButtonBase)(
  ({ theme, disabled }) => ({
    marginTop: 4,
    marginBottom: 4,
    color: theme.palette.text.primary,
    fontStyle: 'italic',
    opacity: disabled ? 0.3 : 0.5,
    cursor: disabled ? 'not-allowed' : 'pointer',
    transition: theme.transitions.create(),
    ':not(:disabled):hover': {
      opacity: 0.8
    }
  }),
  addHighContrastStyles({
    opacity: 1,
    ':not(:disabled):hover': {
      opacity: 1,
      textDecoration: 'underline'
    }
  })
);

StyledAddButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default StyledAddButton;
