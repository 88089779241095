import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { useIntl } from 'react-intl';
import { ClassNames } from '@emotion/react';
import { DatePicker } from '@material-ui/pickers';
import device from 'current-device';

const disabledStyles = {
  cursor: 'not-allowed'
};

const dateIfPossible = timestamp =>
  typeof timestamp === 'number' ? new Date(timestamp) : null;

const WrappedDatePicker = ({ value, disabled, ...props }) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <ClassNames>
      {({ css }) => (
        <DatePicker
          value={dateIfPossible(value)}
          InputProps={{
            classes: {
              input: disabled
                ? css(disabledStyles)
                : css({
                    cursor: 'pointer'
                  }),
              underline: theme.palette.isHighContrast
                ? null
                : css({
                    '&:before': {
                      border: 'none !important'
                    },
                    '&:after': {
                      border: 'none !important'
                    }
                  })
            },
            style: disabled ? disabledStyles : null
          }}
          style={disabled ? disabledStyles : null}
          emptyLabel={intl.formatMessage({
            id: device.desktop()
              ? 'portfolio.challenge.date_field_click_text'
              : 'portfolio.challenge.date_field_tap_text'
          })}
          disabled={disabled}
          fullWidth
          {...props}
        />
      )}
    </ClassNames>
  );
};

WrappedDatePicker.propTypes = {
  value: PropTypes.any,
  disabled: PropTypes.bool
};

WrappedDatePicker.defaultProps = {
  disabled: false
};

export default WrappedDatePicker;
