import PropTypes from 'prop-types';
import useSelectableGroups from './useSelectableGroups';
import StyledGroupSelectorGroupList from './StyledGroupSelectorGroupList';
import ClearSelectedGroupButton from './ClearSelectedGroupButton';
import GroupSelectorGroup from './GroupSelectorGroup';

const GroupSelectorGroupList = ({
  groups,
  selectedGroupKey,
  onGroupChange,
  ...props
}) => {
  const selectableGroups = useSelectableGroups(groups);
  return (
    <StyledGroupSelectorGroupList {...props}>
      {selectedGroupKey && (
        <ClearSelectedGroupButton onClick={() => onGroupChange(null)} />
      )}
      {selectableGroups.map(group => (
        <li key={group.key}>
          <GroupSelectorGroup
            selected={selectedGroupKey === group.key}
            onClick={() => onGroupChange(group.key)}
          >
            {group.name}
          </GroupSelectorGroup>
        </li>
      ))}
    </StyledGroupSelectorGroupList>
  );
};

GroupSelectorGroupList.propTypes = {
  groups: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ).isRequired,
  selectedGroupKey: PropTypes.string,
  onGroupChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default GroupSelectorGroupList;
