import App from 'lib/App';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import { challengeRef, ref } from 'lib/firebase';

const useDeleteSlo = () => {
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const deleteSlo = (slo, calledFromSloDelete = false) => {
    for (const attachedChallengeKey of safeKeys(slo.attachedChallenges)) {
      challengeRef(attachedChallengeKey)
        .child(`attachedSlos/${slo.key}`)
        .remove();
    }

    for (const attachedPartId of safeKeys(slo.attachedParts)) {
      ref(
        `portfolios/${currentUserUsername}/partSloAttachment/${attachedPartId}/${slo.key}`
      ).remove();
    }

    if (!calledFromSloDelete) {
      ref(`sloPeriods/${slo.periodKey}/slos/${slo.key}`).remove();
    }

    ref(`slos/${slo.key}`).remove();
  };

  return deleteSlo;
};

export default useDeleteSlo;
