import { useTheme } from '@emotion/react';
import PlatformTypes from 'lib/constants/PlatformTypes';
import challengemonitorIconLightMode from './icons/challengemonitor-light-mode.svg';
import challengemonitorIconDarkMode from './icons/challengemonitor-dark-mode.svg';
import progressmonitorIconLightMode from './icons/progressmonitor-light-mode.svg';
import progressmonitorIconDarkMode from './icons/progressmonitor-dark-mode.svg';

const useActivePlatformIcons = () => {
  const theme = useTheme();

  return {
    [PlatformTypes.CHALLENGEMONITOR]: theme.isDark
      ? challengemonitorIconDarkMode
      : challengemonitorIconLightMode,
    [PlatformTypes.PROGRESSMONITOR]: theme.isDark
      ? progressmonitorIconDarkMode
      : progressmonitorIconLightMode
  };
};

export default useActivePlatformIcons;
