import { createContext } from 'react';
import useLatestVisibleWeekRangeShiftDirection from './useLatestVisibleWeekRangeShiftDirection';

export const ShiftDirections = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
};

const LatestVisibleWeekRangeShiftDirectionContext = createContext();

const AutomatedLatestVisibleWeekRangeShiftDirectionContextProvider = props => {
  const latestVisibleWeekRangeShiftDirection = useLatestVisibleWeekRangeShiftDirection();

  return (
    <LatestVisibleWeekRangeShiftDirectionContext.Provider
      value={latestVisibleWeekRangeShiftDirection}
      {...props}
    />
  );
};

LatestVisibleWeekRangeShiftDirectionContext.AutomatedProvider = AutomatedLatestVisibleWeekRangeShiftDirectionContextProvider;

export default LatestVisibleWeekRangeShiftDirectionContext;
