import { useState } from 'react';
import PropTypes from 'prop-types';
import DangerousButton from 'lib/components/DangerousButton';
import ConfirmationPrompt from './ConfirmationPrompt';
import { FormattedMessage } from 'react-intl';

const SaveButtonWithConfirmationPrompt = ({ onSave, ...props }) => {
  const [confirmationPromptOpen, setConfirmationPromptOpen] = useState(false);
  return (
    <>
      <DangerousButton
        variant="contained"
        onClick={() => setConfirmationPromptOpen(true)}
        disableElevation
        {...props}
      >
        <FormattedMessage id="platform_settings.save" />
      </DangerousButton>
      <ConfirmationPrompt
        open={confirmationPromptOpen}
        onCancel={() => setConfirmationPromptOpen(false)}
        onConfirm={() => {
          setConfirmationPromptOpen(false);
          onSave();
        }}
      />
    </>
  );
};

SaveButtonWithConfirmationPrompt.propTypes = {
  onSave: PropTypes.func.isRequired
};

export default SaveButtonWithConfirmationPrompt;
