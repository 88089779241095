import { createContext, useState, useContext } from 'react';
import getCollapsedPortfolioItemsCache from './getCollapsedPortfolioItemsCache';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';

const CollapsedPortfolioItemsContext = createContext({
  isPortfolioItemCollapsed: () => {},
  togglePortfolioItemCollapsed: () => {},
  collapseAllPortfolioItems: () => {}
});

const AutomatedCollapsedPortfolioItemsContextProvider = props => {
  const portfolioMode = useContext(PortfolioModeContext);
  const localStorageKeyPrefix = `portfolio-item-collapsed:${portfolioMode}:`;
  const hasCollapsedAllLocalStorageKey = `portfolio-item-collapsing:${portfolioMode}:has-collapsed-all`;

  const [collapsedPortfolioItems, setCollapsedPortfolioItems] = useState(
    getCollapsedPortfolioItemsCache(localStorageKeyPrefix)
  );

  const [hasCollapsedAll, setHasCollapsedAll] = useState(
    localStorage.getItem(hasCollapsedAllLocalStorageKey) === 'true'
  );

  const isPortfolioItemCollapsed = (itemId, uncollapsedByDefault) => {
    const isCollapsed = collapsedPortfolioItems[itemId];

    if (typeof isCollapsed === 'undefined') {
      return hasCollapsedAll ? true : !uncollapsedByDefault;
    }

    return isCollapsed;
  };

  const updatePortfolioItemCollapsedInCache = (itemId, collapsed) =>
    localStorage.setItem(`${localStorageKeyPrefix}${itemId}`, collapsed);

  const togglePortfolioItemCollapsed = (itemId, uncollapsedByDefault) => {
    const alreadyCollapsed = isPortfolioItemCollapsed(
      itemId,
      uncollapsedByDefault
    );

    updatePortfolioItemCollapsedInCache(itemId, !alreadyCollapsed);
    setCollapsedPortfolioItems({
      ...collapsedPortfolioItems,
      [itemId]: !alreadyCollapsed
    });
  };

  const canCollapseAnyPortfolioItem =
    !hasCollapsedAll || Object.values(collapsedPortfolioItems).includes(false);

  const collapseAllPortfolioItems = () => {
    for (const key in localStorage) {
      if (key.startsWith(localStorageKeyPrefix)) {
        localStorage.removeItem(key);
      }
    }

    setHasCollapsedAll(true);
    localStorage.setItem(hasCollapsedAllLocalStorageKey, true);
    setCollapsedPortfolioItems({});
  };

  return (
    <CollapsedPortfolioItemsContext.Provider
      value={{
        isPortfolioItemCollapsed,
        togglePortfolioItemCollapsed,
        canCollapseAnyPortfolioItem,
        collapseAllPortfolioItems
      }}
      {...props}
    />
  );
};

CollapsedPortfolioItemsContext.AutomatedProvider = AutomatedCollapsedPortfolioItemsContextProvider;

export default CollapsedPortfolioItemsContext;
