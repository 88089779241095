import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import common from '@material-ui/core/colors/common';

const StyledWrapper = styled(motion.section)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: theme.isDark ? common.black : common.white,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 100
}));

const LoadingScreenWrapper = props => (
  <StyledWrapper
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    {...props}
  />
);

export default LoadingScreenWrapper;
