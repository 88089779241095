import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ActionsWrapper = styled('footer')(({ theme, primaryButtons }) => {
  if (primaryButtons) {
    return {
      '.MuiIconButton-root': {
        color: theme.palette.text.primary
      }
    };
  }
});

ActionsWrapper.propTypes = {
  primaryButtons: PropTypes.bool.isRequired
};

export default ActionsWrapper;
