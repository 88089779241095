import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import LearningGoalsContext from '../LearningGoalsContext';
import LearningGoalsAppBar from 'components/LearningGoals/LearningGoalsAppBar';
import { Close as CloseIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const LearningGoalsHeader = ({ onClose, ...props }) => {
  const { field } = useContext(FieldContext);
  const { learningGoalsModel } = useContext(LearningGoalsContext);
  return (
    <LearningGoalsAppBar {...props}>
      <LearningGoalsAppBar.IconButton onClick={onClose}>
        <CloseIcon />
      </LearningGoalsAppBar.IconButton>
      <LearningGoalsAppBar.Title>
        {learningGoalsModel?.name || field.name || (
          <FormattedMessage id="portfolio.challenge.learning_goals.default_title" />
        )}
      </LearningGoalsAppBar.Title>
    </LearningGoalsAppBar>
  );
};

LearningGoalsHeader.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default LearningGoalsHeader;
