import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ButtonBase = styled('button')(({ disabled }) => ({
  padding: 0,
  textAlign: 'left',
  font: 'inherit',
  color: 'inherit',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: disabled ? 'default' : 'pointer',
  appearance: 'none'
}));

ButtonBase.propTypes = {
  disabled: PropTypes.bool
};

ButtonBase.defaultProps = {
  disabled: false
};

export default ButtonBase;
