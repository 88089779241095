import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Pagination } from '@material-ui/lab';

const StyledPagination = styled(Pagination)({
  display: 'flex',
  justifyContent: 'center'
});

const UserPagination = ({ onChange, ...props }) => (
  <StyledPagination
    onChange={(e, value) => onChange(value)}
    color="primary"
    {...props}
  />
);

UserPagination.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default UserPagination;
