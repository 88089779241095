import PropTypes from 'prop-types';
import App from 'lib/App';
import { useTheme } from '@emotion/react';
import ProgressBar from './ProgressBar';
import SliderIcon from './SliderIcon';
import IconVariants from './IconVariants';

const reversedBorderRadiusCorrectionStyles = progress => ({
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  borderTopLeftRadius: progress > 99 ? 4 : null,
  borderBottomLeftRadius: progress > 99 ? 4 : null
});

const normalBorderRadiusCorrectionStyles = progress => ({
  borderTopLeftRadius: 4,
  borderBottomLeftRadius: 4,
  borderTopRightRadius: progress > 99 ? 4 : null,
  borderBottomRightRadius: progress > 99 ? 4 : null
});

const StudentProgressBar = ({ progress, coachProgress, sliding, ...props }) => {
  const useReversedSliders = App.instanceConfig.useContentSetting(
    'useReversedSliders'
  );
  const theme = useTheme();
  return (
    <ProgressBar
      progress={progress}
      otherProgress={coachProgress}
      color={theme.palette.sliders.student}
      noTransition={sliding}
      style={
        useReversedSliders
          ? reversedBorderRadiusCorrectionStyles(progress)
          : normalBorderRadiusCorrectionStyles(progress)
      }
      {...props}
    >
      <SliderIcon variant={IconVariants.STUDENT} sliding={sliding} />
    </ProgressBar>
  );
};

StudentProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  coachProgress: PropTypes.number.isRequired,
  sliding: PropTypes.bool.isRequired
};

export default StudentProgressBar;
