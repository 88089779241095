import { useContext } from 'react';
import PropTypes from 'prop-types';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import WeekMarkingBackgroundLines from './WeekMarkingBackgroundLines';
import DayMarkingBackgroundLines from './DayMarkingBackgroundLines';
import HistoryIndicatingShade from './HistoryIndicatingShade';
import getAllChallengeKeysFromRow from './getAllChallengeKeysFromRow';
import Challenge from './Challenge';

const ChallengePlanning = ({ row, ...props }) => {
  const { isZoomedInOnWeek } = useContext(VisibleWeekRangeContext);
  return (
    <section {...props}>
      <WeekMarkingBackgroundLines />
      {isZoomedInOnWeek && <DayMarkingBackgroundLines />}
      <HistoryIndicatingShade />
      {getAllChallengeKeysFromRow(row).map(challengeKey => (
        <Challenge challengeKey={challengeKey} key={challengeKey} />
      ))}
    </section>
  );
};

ChallengePlanning.propTypes = {
  row: PropTypes.object.isRequired
};

export default ChallengePlanning;
