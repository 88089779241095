import PropTypes from 'prop-types';
import useProgressWithSelectedWeekSyncing from './useProgressWithSelectedWeekSyncing';
import weekFromDate from '../../../weekFromDate';
import StyledSlider from './StyledSlider';

const WeekSlider = ({
  selectedYear,
  weeks,
  selectedWeek,
  setSelectedWeek,
  ...props
}) => {
  const [progress, setProgress] = useProgressWithSelectedWeekSyncing(
    selectedWeek,
    weeks
  );

  const getSelectedWeekFromProgress = progress => {
    const firstWeek = Number(weeks[0]);
    const lastWeek = Number(weeks[weeks.length - 1]);
    const weekCount = lastWeek - firstWeek + 1;

    const selectedWeek = firstWeek + Math.floor((progress / 100) * weekCount);

    if (progress === 100) {
      return selectedWeek - 1;
    }

    return selectedWeek;
  };

  const ensureWeekIsNotInFuture = week => {
    const now = new Date();
    const currentWeek = weekFromDate(now) + 1;
    const currentYear = now.getFullYear();

    if (week > currentWeek && selectedYear === currentYear) {
      return currentWeek;
    }

    return week;
  };

  const updateSelectedWeek = progress => {
    const newSelectedWeek = ensureWeekIsNotInFuture(
      getSelectedWeekFromProgress(progress)
    );

    setSelectedWeek(newSelectedWeek);
  };

  return (
    <StyledSlider
      value={progress}
      onChange={newProgress => setProgress(newProgress)}
      onChangeCommitted={progress => updateSelectedWeek(progress)}
    />
  );
};

WeekSlider.propTypes = {
  selectedYear: PropTypes.number.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedWeek: PropTypes.number.isRequired,
  setSelectedWeek: PropTypes.func.isRequired
};

export default WeekSlider;
