import styled from '@emotion/styled';
import { ExpandLess as MuiCollapseIcon } from '@material-ui/icons';
import isPropValid from '@emotion/is-prop-valid';

const CollapseIcon = styled(MuiCollapseIcon, {
  shouldForwardProp: isPropValid
})(({ theme, rotated }) => ({
  marginRight: 6,
  transition: theme.transitions.create(),
  transform: rotated ? 'rotate(180deg)' : null
}));

export default CollapseIcon;
