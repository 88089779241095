import { useState } from 'react';
import arrToObj from 'lib/utils/arrToObj';

const useSelectedStandardGroupsState = initiallySelectedStandardGroups => {
  const [selectedStandardGroups, setSelectedStandardGroups] = useState(
    arrToObj(initiallySelectedStandardGroups, true)
  );

  const replaceSelectedStandardGroups = newlySelectedStandardGroups =>
    setSelectedStandardGroups(arrToObj(newlySelectedStandardGroups, true));

  const updateIndividualStandardGroupSelection = (groupKey, selected) => {
    setSelectedStandardGroups({
      ...selectedStandardGroups,
      [groupKey]: selected
    });
  };

  const getSelectedStandardGroupsAsKeysArray = () =>
    Object.entries(selectedStandardGroups)
      .filter(([groupKey, selected]) => !!selected)
      .map(([groupKey]) => groupKey);

  return {
    selectedStandardGroups,
    replaceSelectedStandardGroups,
    updateIndividualStandardGroupSelection,
    getSelectedStandardGroupsAsKeysArray
  };
};

export default useSelectedStandardGroupsState;
