import { ref } from 'lib/firebase';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import App from 'lib/App';
import sortByUiPos from 'lib/utils/sortByUiPos';

const useRubricsFieldParts = (fieldKey, modelType) => {
  const [portfolioTemplatesLoading, portfolioTemplates] = useFirebaseValue(
    ref('portfolioTemplates')
  );

  const models = App.useModels();

  if (portfolioTemplatesLoading) {
    return [true, null, null];
  }

  const allParts = portfolioTemplates?.parts;

  const model = models[modelType];
  const relevantPartsKeysObject = model?.rubrics?.[fieldKey];

  if (!allParts || !relevantPartsKeysObject) {
    return [false, [], portfolioTemplates];
  }

  const relevantPartsKeys = Object.keys(relevantPartsKeysObject);
  const relevantParts = relevantPartsKeys
    .map(partKey => portfolioTemplates.parts[partKey])
    .filter(part => !!part)
    .sort(sortByUiPos);

  return [false, relevantParts, portfolioTemplates];
};

export default useRubricsFieldParts;
