import { useState } from 'react';
import useCurrentPhaseRef from '../../useCurrentPhaseRef';
import Dialog from 'lib/components/Dialog';
import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { FormattedMessage } from 'react-intl';

const DeletePhase = props => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const phaseRef = useCurrentPhaseRef();
  const deletePhase = () => {
    setConfirmationDialogOpen(false);
    phaseRef.remove();
  };

  return (
    <>
      <CollapsibleActionMenu.DeleteButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <FormattedMessage id="editor.model_editor.actions.delete" />
      </CollapsibleActionMenu.DeleteButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.phase_editor.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.phase_editor.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deletePhase} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

export default DeletePhase;
