import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import Space from 'lib/components/Space';
import useCanDisplayPartAttachmentManager from './useCanDisplayPartAttachmentManager';
import CannotAttachPartMessage from './CannotAttachPartMessage';
import ManagerWrapper from './ManagerWrapper';
import Topic from './Topic';
import Subtopic from './Subtopic';
import Part from './Part';
import AttachPart from './AttachPart';
import sortByUiPos from 'lib/utils/sortByUiPos';

const PartAttachmentManager = ({ part, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const canDisplayPartAttachmentManager = useCanDisplayPartAttachmentManager();

  if (!canDisplayPartAttachmentManager) {
    return <CannotAttachPartMessage />;
  }

  const topics = Object.values(portfolioTemplates.topics).sort(sortByUiPos);

  return (
    <ManagerWrapper {...props}>
      <Space height={-12} />
      {topics.map(topic => (
        <Topic topic={topic} key={topic.id}>
          {subtopics =>
            subtopics.map(subtopic => (
              <Subtopic subtopic={subtopic} key={subtopic.id}>
                {(parts, highestPartUiPos) => (
                  <>
                    {parts.map(part => (
                      <Part part={part} subtopic={subtopic} key={part.id} />
                    ))}
                    <AttachPart
                      part={part}
                      subtopic={subtopic}
                      highestPartUiPos={highestPartUiPos}
                    />
                  </>
                )}
              </Subtopic>
            ))
          }
        </Topic>
      ))}
    </ManagerWrapper>
  );
};

PartAttachmentManager.propTypes = {
  part: PropTypes.object.isRequired
};

export { useCanDisplayPartAttachmentManager };

export default PartAttachmentManager;
