import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const LinkSharingTitle = ({ asTask, ...props }) => (
  <Dialog.Title {...props}>
    {asTask ? (
      <FormattedMessage id="portfolio.challenge.link_sharing.title.as_task" />
    ) : (
      <FormattedMessageWithChallengeNaming id="portfolio.challenge.link_sharing.title" />
    )}
  </Dialog.Title>
);

LinkSharingTitle.propTypes = {
  asTask: PropTypes.bool.isRequired
};

export default LinkSharingTitle;
