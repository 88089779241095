import { useIntl } from 'react-intl';

const useAddCopySuffix = () => {
  const intl = useIntl();
  const copySuffix = intl.formatMessage({
    id: 'copy_suffix'
  });

  const addCopySuffix = originalString => `${originalString} ${copySuffix}`;
  return addCopySuffix;
};

export default useAddCopySuffix;
