import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import ChallengeUsedAsProofIndicator from './ChallengeUsedAsProofIndicator';
import AutomatedLevelDialog from './AutomatedLevelDialog';

const Part = ({ part, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <TileGrid.Tile
      color={part.color}
      studentCompletion={part.progress.student}
      coachCompletion={part.progress.coach}
      hasCompletionFill
      {...props}
    >
      <TileGrid.Tile.ClickListener onClick={() => setOpen(true)} />
      <TileGrid.Tile.Title onClick={() => setOpen(true)}>
        {part.title}
        <ChallengeUsedAsProofIndicator
          part={part}
          onClick={() => setOpen(true)}
        />
      </TileGrid.Tile.Title>
      <AutomatedLevelDialog
        part={part}
        open={open}
        onClose={() => setOpen(false)}
      />
    </TileGrid.Tile>
  );
};

Part.propTypes = {
  part: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string,
    progress: PropTypes.shape({
      student: PropTypes.number,
      coach: PropTypes.number
    })
  })
};

export default Part;
