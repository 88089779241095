import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import Dialog from 'lib/components/Dialog';
import setupChildTextField from './setupChildTextField';
import PromptTextField from './PromptTextField';
import { FormattedMessage } from 'react-intl';

const Prompt = ({
  defaultValue,
  emptyError,
  showError: showErrorFn,
  onOk,
  onCancel,
  OkButton,
  multiline,
  persistInput,
  allowEmptyInput,
  children,
  ...props
}) => {
  const [input, setInput] = useSyncedState(defaultValue);
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setError(null);
    onCancel();
  };

  const showError = input => {
    if (emptyError && !input.trim()) {
      return <FormattedMessage id={emptyError} />;
    }

    const externalError = showErrorFn && showErrorFn(input);
    if (externalError) {
      return externalError;
    }

    return null;
  };

  const inputRef = useRef(null);

  const handleOk = () => {
    const error = showError(input);

    setError(error);

    if (error) {
      inputRef.current.focus();
      return;
    }

    if (!allowEmptyInput && !input.trim()) {
      handleCancel();
      return;
    }

    onOk(input.trim());

    if (!persistInput) {
      setInput('');
    }
  };

  return (
    <Dialog
      onClose={handleCancel}
      onEnterClose={handleOk}
      disableEnterClose={multiline}
      {...props}
    >
      {setupChildTextField(children, {
        value: input,
        onChange: e => setInput(e.target.value),
        error,
        inputRef,
        multiline
      })}
      <Dialog.Actions addTopMargin {...props}>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        <OkButton onClick={handleOk} />
      </Dialog.Actions>
    </Dialog>
  );
};

Prompt.propTypes = {
  defaultValue: PropTypes.string,
  emptyError: PropTypes.string,
  showError: PropTypes.func,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  OkButton: PropTypes.elementType,
  children: PropTypes.node,
  multiline: PropTypes.bool,
  persistInput: PropTypes.bool,
  allowEmptyInput: PropTypes.bool
};

Prompt.defaultProps = {
  defaultValue: '',
  OkButton: Dialog.Actions.SaveButton,
  multiline: false,
  persistInput: false,
  allowEmptyInput: false
};

Prompt.TextField = PromptTextField;

export default Prompt;
