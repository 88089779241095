import styled from '@emotion/styled';
import common from '@material-ui/core/colors/common';

const UploadLabel = styled('h4')(({ theme }) => ({
  position: 'relative',
  margin: 0,
  padding: theme.palette.isHighContrast ? 6 : null,
  color: common.white,
  backgroundColor: theme.palette.isHighContrast ? common.black : null,
  fontWeight: 400,
  textAlign: 'center',
  fontFamily: 'CircularStd',
  zIndex: 1
}));

export default UploadLabel;
