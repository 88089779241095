import { IntlProvider } from 'react-intl';
import getLanguage from 'lib/utils/getLanguage';
import translations from './translations';
import moment from 'moment';
import 'moment/locale/nl';
import 'moment/locale/fr';
import syncLanguageToHtmlElement from './syncLanguageToHtmlElement';

const LanguageProvider = props => {
  const language = getLanguage();
  moment.locale(language);

  syncLanguageToHtmlElement(language);

  return (
    <IntlProvider
      locale={language}
      messages={translations[language]}
      {...props}
    />
  );
};

export default LanguageProvider;
