import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ErrorScreenWrapper = styled('section')(({ visible }) => ({
  paddingLeft: 48,
  height: visible ? 'auto' : 0,
  flex: 1,
  maxWidth: '50%',
  boxSizing: 'border-box',
  [mediaQuery(768)]: {
    paddingLeft: 24
  },
  [mediaQuery(450)]: {
    paddingLeft: 16
  }
}));

ErrorScreenWrapper.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default ErrorScreenWrapper;
