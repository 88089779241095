import App from 'lib/App';
import { ref, userRef } from 'lib/firebase';

const useCreateRow = () => {
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const createRow = async (name, highestUiPos) => {
    const row = {
      name,
      user: currentUserUsername
    };

    const key = ref('rows').push().key;

    await Promise.all([
      ref(`rows/${key}`).set(row),
      userRef(currentUserUsername)
        .child(`rows/${key}`)
        .set(highestUiPos + 1)
    ]);

    return key;
  };

  return createRow;
};

export default useCreateRow;
