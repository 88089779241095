import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import { challengeRef, rowRef } from 'lib/firebase';

const useParentRef = (challenge, asTask) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  if (asTask) {
    const task = challenge;
    return challengeRef(task.challenge).child('tasks');
  }

  if (!challenge.row?.[portfolioUserUsername]) {
    return null;
  }

  return rowRef(challenge.row[portfolioUserUsername]).child('challenges');
};

export default useParentRef;
