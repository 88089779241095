import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const FieldWrapper = styled('article')(
  ({ theme }) => ({
    marginBottom: 18,
    padding: 12,
    backgroundColor: theme.palette.action.hover,
    boxSizing: 'border-box',
    borderRadius: 4,
    overflow: 'hidden'
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.default
  })),
  addBorderInHighContrastMode
);

export default FieldWrapper;
