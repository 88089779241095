import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const LoginErrorUnfoldingWrapper = styled('section')(
  ({ theme, showEmailNotAllowedError }) => ({
    padding: '36px 48px',
    transform: showEmailNotAllowedError ? 'translateX(-50%)' : 'none',
    width: '200%',
    display: 'flex',
    boxSizing: 'border-box',
    transition: theme.transitions.create(),
    [mediaQuery(768)]: {
      padding: 24
    },
    [mediaQuery(450)]: {
      padding: '24px 16px'
    }
  })
);

LoginErrorUnfoldingWrapper.propTypes = {
  showEmailNotAllowedError: PropTypes.bool.isRequired
};

export default LoginErrorUnfoldingWrapper;
