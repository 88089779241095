import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rgba } from 'lib/styling/colors';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledHtml = styled('div')(
  ({ theme, darkText }) => ({
    paddingTop: 12,
    color: darkText
      ? theme.palette.text.primary
      : rgba(theme.palette.text.primary, 0.7),
    wordBreak: 'break-word',
    p: {
      marginTop: 0,
      marginBottom: 0
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary
  }))
);

StyledHtml.propTypes = {
  darkText: PropTypes.bool.isRequired
};

export default StyledHtml;
