import styled from '@emotion/styled';

const ChallengeWrapper = styled('div')({
  position: 'relative',
  ':hover .detach-challenge-button': {
    transform: 'translate(10%, -30%) scale(1)'
  },
  ':active .detach-challenge-button': {
    opacity: 0
  }
});

export default ChallengeWrapper;
