import LinkButtonConfigFieldEditor from './LinkButtonConfigFieldEditor';

const LinkButtonEditor = props => (
  <section {...props}>
    <LinkButtonConfigFieldEditor id="label" />
    <LinkButtonConfigFieldEditor id="link" />
    <LinkButtonConfigFieldEditor id="iconUrl" />
  </section>
);

export default LinkButtonEditor;
