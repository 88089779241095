import App from 'lib/App';
import StyledPageTitle from './StyledPageTitle';
import { FormattedMessage } from 'react-intl';

const WelcomeMessage = props => {
  const currentUser = App.authentication.useCurrentUser();
  const firstName = currentUser.name.split(' ')[0];
  return (
    <StyledPageTitle {...props}>
      <FormattedMessage
        id="header.welcome_message"
        values={{
          firstName
        }}
      />
    </StyledPageTitle>
  );
};

export default WelcomeMessage;
