import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../SloContext';
import { value, challengeRef, ref } from 'lib/firebase';
import useInterval from 'use-interval';

const WithChallengeExistenceVerification = ({ challengeKey, children }) => {
  const [hasRunExistenceCheck, setHasRunExistenceCheck] = useState(false);
  const slo = useContext(SloContext);

  const challengeExists = async () => {
    try {
      await value(challengeRef(challengeKey).child('creationDate'));
      return true;
    } catch {
      return false;
    }
  };

  const attemptRemoveSloFromChallenge = () => {
    try {
      challengeRef(challengeKey).child(`attachedSlos/${slo.key}`).remove();
    } catch {}
  };

  const detachChallenge = () => {
    ref(`slos/${slo.key}/challengeAttachment/${challengeKey}`).remove();
    attemptRemoveSloFromChallenge(challengeKey);
  };

  useInterval(async () => {
    if (hasRunExistenceCheck) {
      return;
    }

    const challengeIsUsable = await challengeExists();
    if (!challengeIsUsable) {
      detachChallenge();
    }

    setHasRunExistenceCheck(true);
  }, 1000);

  return children;
};

WithChallengeExistenceVerification.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default WithChallengeExistenceVerification;
