import { useContext } from 'react';
import VisibleWeekRangeContext from '../../../VisibleWeekRangeContext';
import moment from 'moment';
import StyledTitle from './StyledTitle';

const YearIndicator = props => {
  const { visibleWeekRangeStartDate } = useContext(VisibleWeekRangeContext);

  const currentYear = moment().year();
  const weekRangeYear = visibleWeekRangeStartDate
    .clone()
    .add(1, 'weeks')
    .year();

  if (weekRangeYear === currentYear) {
    return null;
  }

  return <StyledTitle {...props}>{weekRangeYear}</StyledTitle>;
};

export default YearIndicator;
