import { useState, useEffect } from 'react';
import { challengeRef, taskRef } from 'lib/firebase';

const useChallenge = (challengeKey, asTask) => {
  const [challengeLoading, setChallengeLoading] = useState(true);
  const [challenge, setChallenge] = useState(null);

  useEffect(() => {
    const refFunction = asTask ? taskRef : challengeRef;
    const ref = refFunction(challengeKey);

    // This timeout is necessary because of some weird infinte loading bug that
    // otherwise occurs when dragging the challenge twice
    setTimeout(() => {
      ref.on('value', snapshot => {
        setChallengeLoading(false);

        const challenge = snapshot.val();

        if (!challenge) {
          setChallenge(null);
          return;
        }

        setChallenge({
          key: challengeKey,
          ...challenge
        });
      });
    }, 0);

    return () => ref.off();
  }, [challengeKey, asTask]);

  return [challengeLoading, challenge];
};

export default useChallenge;
