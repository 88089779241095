import { useMediaQuery } from '@material-ui/core';
import StyledButton from './StyledButton';

const DialogButton = props => {
  const shouldUseMobileVariant = useMediaQuery('(max-width: 640px)');
  return (
    <StyledButton
      variant="contained"
      fullWidth={shouldUseMobileVariant}
      disableElevation
      {...props}
    />
  );
};

export default DialogButton;
