import styled from '@emotion/styled';
import { FormControlLabel } from '@material-ui/core';

const StyledCheckboxWrapper = styled(FormControlLabel)({
  marginBottom: 2,
  ':last-child': {
    marginBottom: 12
  }
});

export default StyledCheckboxWrapper;
