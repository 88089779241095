import Dialog from 'lib/components/Dialog';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const IncludeEmailCheckbox = props => (
  <Dialog.Content>
    <FormControlLabel
      control={<Checkbox color="primary" {...props} />}
      label={
        <FormattedMessage id="support.dialog.feedback_form.include_email_checkbox.label" />
      }
    />
  </Dialog.Content>
);

export default IncludeEmailCheckbox;
