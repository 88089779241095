import PropTypes from 'prop-types';
import StyledHtml from './StyledHtml';
import escapeHtml from '../escapeHtml';

const HtmlRenderer = ({ source, ...props }) => (
  <StyledHtml
    className="html-field"
    dangerouslySetInnerHTML={{ __html: escapeHtml(source) }}
    {...props}
  />
);

HtmlRenderer.propTypes = {
  source: PropTypes.string.isRequired,
  darkText: PropTypes.bool
};

HtmlRenderer.defaultProps = {
  darkText: false
};

export default HtmlRenderer;
