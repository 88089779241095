import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const Color = styled('span')(
  ({ color }) => ({
    marginRight: 7,
    width: 20,
    height: 20,
    backgroundColor: color,
    display: 'inline-block',
    borderRadius: '100%'
  }),
  addBorderInHighContrastMode
);

Color.propTypes = {
  color: PropTypes.string.isRequired
};

export default Color;
