import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';

const Space = styled('div', {
  shouldForwardProp: isPropValid
})(({ width, height }) => {
  if (width) {
    return {
      marginRight: width,
      display: 'inline-block'
    };
  }

  return {
    marginBottom: height || 12
  };
});

Space.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default Space;
