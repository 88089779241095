import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import NameLoadingPlaceholder from './NameLoadingPlaceholder';

const ExpertiseName = ({ expertiseKey }) => {
  const [expertiseLoading, expertise] = useFirebaseValue(
    ref(`expertises/${expertiseKey}`)
  );

  if (expertiseLoading) {
    return <NameLoadingPlaceholder />;
  }

  return expertise.name;
};

ExpertiseName.propTypes = {
  expertiseKey: PropTypes.string.isRequired
};

export default ExpertiseName;
