import { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorScreen from './ErrorScreen';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    error: null,
    errorInfo: null
  };

  componentDidCatch = (error, errorInfo) => {
    this.setState({ error, errorInfo });
  };

  render = () => {
    const { error, errorInfo } = this.state;

    if (!error) {
      return this.props.children;
    }

    const errorComponentStack = errorInfo?.componentStack;
    return (
      <ErrorScreen
        error={error.toString()}
        errorComponentStack={errorComponentStack}
      />
    );
  };
}
