import { useContext } from 'react';
import PhaseContext from '../../PhaseContext';
import usePhaseMoving from './usePhaseMoving';
import MoveModeContext from '../../../../MoveModeContext';
import MoveButtons from '../../UiPosMoveButtons';

const PhaseUiPosMoving = props => {
  const { previousPhase, nextPhase } = useContext(PhaseContext);
  const [moveUp, moveDown] = usePhaseMoving();

  const inMoveMode = useContext(MoveModeContext);
  if (!inMoveMode) {
    return null;
  }

  return (
    <MoveButtons.Wrapper {...props}>
      {previousPhase && <MoveButtons.Up onClick={moveUp} />}
      {nextPhase && <MoveButtons.Down onClick={moveDown} />}
    </MoveButtons.Wrapper>
  );
};

export default PhaseUiPosMoving;
