import { useContext, useState, useEffect } from 'react';
import AppStateContext from 'lib/contexts/AppStateContext';
import useIsUserAllowed from '../useIsUserAllowed';
import firebase from 'firebase/compat/app';

const useFirebaseLoggedInState = () => {
  const appState = useContext(AppStateContext);
  const isUserAllowed = useIsUserAllowed();

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (appState.isLoading) {
      return;
    }

    const stopAuthStateListener = firebase.auth().onAuthStateChanged(user => {
      if (user && !isUserAllowed(user)) {
        firebase.auth().signOut();
        setLoggedIn(false);
        return;
      }

      setLoggedIn(!!user);
    });

    return () => stopAuthStateListener();
  }, [appState.isLoading, isUserAllowed]);

  return loggedIn;
};

export default useFirebaseLoggedInState;
