import { Button, Tooltip, useMediaQuery } from '@material-ui/core';
import StyledLinkIconButton from './StyledLinkIconButton';
import { Link as LinkIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const minWidthStyles = {
  minWidth: 180
};

const LinkButton = props => {
  const shouldUseIconButton = useMediaQuery('(max-width: 1250px)');

  if (shouldUseIconButton) {
    return (
      <Tooltip
        title={
          <FormattedMessage id="portfolio.challenge.link_sharing.button" />
        }
      >
        <StyledLinkIconButton color="primary" {...props}>
          <LinkIcon />
        </StyledLinkIconButton>
      </Tooltip>
    );
  }

  return (
    <Button
      startIcon={<LinkIcon color="primary" />}
      style={minWidthStyles}
      {...props}
    >
      <FormattedMessage id="portfolio.challenge.link_sharing.button" />
    </Button>
  );
};

export default LinkButton;
