import Phases from 'lib/constants/Phases';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const countRowChallenges = row => {
  if (!row.challenges) {
    return 0;
  }

  return Object.values(Phases).reduce((phaseKey, challengeCountSoFar) => {
    const challengesForPhase = row.challenges[phaseKey];
    return challengeCountSoFar + safeKeys(challengesForPhase).length;
  }, 0);
};

export default countRowChallenges;
