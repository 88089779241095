import ownYourDevelopmentAndGrowthIcon from './subtopicIcons/own-your-development-and-growth.png';
import discoverAndOrientateIcon from './subtopicIcons/discover-and-orientate.png';
import connectIcon from './subtopicIcons/connect.png';
import communicateIcon from './subtopicIcons/communicate.png';
import reflectIcon from './subtopicIcons/reflect.png';
import researchIcon from './subtopicIcons/research.png';
import shareIcon from './subtopicIcons/share.png';

const subtopicIcons = {
  'own your development/growth': ownYourDevelopmentAndGrowthIcon,
  'discover & orientate': discoverAndOrientateIcon,
  connect: connectIcon,
  communicate: communicateIcon,
  reflect: reflectIcon,
  research: researchIcon,
  share: shareIcon
};

const getSubtopicIconFromTitle = title => {
  const subtopicIcon = subtopicIcons[title.trim().toLowerCase()];
  return subtopicIcon || null;
};

export default getSubtopicIconFromTitle;
