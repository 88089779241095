import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const LabelText = styled('span')(({ theme, hasExpertiseName }) => ({
  maxWidth: 100,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  opacity: hasExpertiseName ? 1 : 0.8,
  transition: theme.transitions.create()
}));

LabelText.propTypes = {
  hasExpertiseName: PropTypes.bool.isRequired
};

export default LabelText;
