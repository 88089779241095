import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import useCurrentFieldRef from '../../../useCurrentFieldRef';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const InfoEditor = ({ onClose, ...props }) => {
  const { field } = useContext(FieldContext);

  const fieldRef = useCurrentFieldRef();
  const saveInfo = newInfo => {
    onClose();

    if (newInfo !== (field.info || '')) {
      fieldRef.child('info').set(newInfo.trim() || null);
    }
  };

  return (
    <Prompt
      defaultValue={field.info || ''}
      onOk={saveInfo}
      onCancel={onClose}
      multiline
      persistInput
      allowEmptyInput
      {...props}
    >
      <Dialog.Title>
        <FormattedMessage id="editor.model_editor.field_editor.info_editor.title" />
      </Dialog.Title>
      <Prompt.TextField
        label={
          <FormattedMessage id="editor.model_editor.field_editor.info_editor.input_label" />
        }
      />
    </Prompt>
  );
};

InfoEditor.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default InfoEditor;
