import styled from '@emotion/styled';

const StyledIndividualValue = styled('div')({
  marginBottom: 8,
  ':last-child': {
    marginBottom: 0
  }
});

export default StyledIndividualValue;
