import { useState } from 'react';
import PropTypes from 'prop-types';
import HeaderWrapper from './HeaderWrapper';
import FieldNameEditor from './FieldNameEditor';
import FieldName from './FieldName';
import FieldUiPosMoving from './FieldUiPosMoving';
import FieldActions from './FieldActions';

const FieldHeader = ({ allHighestUiPositions, ...props }) => {
  const [nameEditing, setNameEditing] = useState(false);
  return (
    <HeaderWrapper shadeBackground={nameEditing} {...props}>
      {nameEditing ? (
        <FieldNameEditor onClose={() => setNameEditing(false)} />
      ) : (
        <>
          <FieldName onClick={() => setNameEditing(true)} />
          <FieldUiPosMoving allHighestUiPositions={allHighestUiPositions} />
          <FieldActions onNameEdit={() => setNameEditing(true)} />
        </>
      )}
    </HeaderWrapper>
  );
};

FieldHeader.propTypes = {
  allHighestUiPositions: PropTypes.object.isRequired
};

export default FieldHeader;
