import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import Space from 'lib/components/Space';
import EditTopic from './EditTopic';
import DeleteTopic from './DeleteTopic';
import Subtopics from './Subtopics';
import AddSubtopic from './AddSubtopic';

const Topic = ({ topic, ...props }) => {
  const [open, setOpen] = useState(false);
  return (
    <TileGrid.Tile open={open} color={topic.color} {...props}>
      {!open && (
        <>
          <TileGrid.Tile.ClickListener onClick={() => setOpen(true)} />
          <TileGrid.Tile.Title>{topic.name}</TileGrid.Tile.Title>
          <TileGrid.Tile.OnlyShowOnHover>
            <Space width={9} />
          </TileGrid.Tile.OnlyShowOnHover>
          <TileGrid.Tile.OnlyShowOnHover>
            <EditTopic topicKey={topic.key} topic={topic} />
          </TileGrid.Tile.OnlyShowOnHover>
          <TileGrid.Tile.OnlyShowOnHover>
            <DeleteTopic topicKey={topic.key} topic={topic} />
          </TileGrid.Tile.OnlyShowOnHover>
        </>
      )}
      {open && (
        <>
          <TileGrid.AppBarMarginFix />
          <Subtopics topic={topic} onClose={() => setOpen(false)} />
          <AddSubtopic topicKey={topic.key} />
        </>
      )}
    </TileGrid.Tile>
  );
};

Topic.propTypes = {
  topic: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string
  }).isRequired
};

export default Topic;
