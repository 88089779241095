import PropTypes from 'prop-types';
import LoadingSpinner from 'lib/components/LoadingSpinner';

const PhaseLoadingSpinner = ({ collapsed, ...props }) => (
  <LoadingSpinner
    marginTop={collapsed ? 12 : 24}
    marginBottom={collapsed ? 20 : 32}
    centered
    {...props}
  />
);

PhaseLoadingSpinner.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default PhaseLoadingSpinner;
