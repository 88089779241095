import styled from '@emotion/styled';

const UserEmail = styled('p')(({ theme }) => ({
  marginTop: 0,
  marginBottom: 0,
  color: theme.palette.text.secondary,
  fontSize: 14
}));

export default UserEmail;
