import { useContext } from 'react';
import PropTypes from 'prop-types';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ArrowKeyListeners from './ArrowKeyListeners';
import UserSwitchArrow from './UserSwitchArrow';
import UserSwitchArrowIcon from './UserSwitchArrowIcon';
import { FormattedMessage } from 'react-intl';

const UserSwitchArrows = ({
  onPreviousUser,
  onNextUser,
  previousArrowDisabled,
  nextArrowDisabled,
  ...props
}) => {
  const [selectedPlatform] = useContext(SelectedPlatformContext);
  const floatingArrows = selectedPlatform === PlatformTypes.CHALLENGEMONITOR;
  return (
    <ArrowKeyListeners
      onLeftArrowPress={() => {
        !previousArrowDisabled && onPreviousUser();
      }}
      onRightArrowPress={() => {
        !nextArrowDisabled && onNextUser();
      }}
      {...props}
    >
      <UserSwitchArrow
        label={<FormattedMessage id="user_switcher.arrows.previous" />}
        floating={floatingArrows}
        disabled={previousArrowDisabled}
        onClick={onPreviousUser}
      >
        <UserSwitchArrowIcon floating={floatingArrows} left />
      </UserSwitchArrow>
      <UserSwitchArrow
        label={<FormattedMessage id="user_switcher.arrows.next" />}
        floating={floatingArrows}
        disabled={nextArrowDisabled}
        onClick={onNextUser}
        right
      >
        <UserSwitchArrowIcon floating={floatingArrows} right />
      </UserSwitchArrow>
    </ArrowKeyListeners>
  );
};

UserSwitchArrows.propTypes = {
  onPreviousUser: PropTypes.func.isRequired,
  onNextUser: PropTypes.func.isRequired,
  previousArrowDisabled: PropTypes.bool.isRequired,
  nextArrowDisabled: PropTypes.bool.isRequired
};

export default UserSwitchArrows;
