import { ref, value } from 'lib/firebase';

const deleteLevel = async id => {
  const levelRef = ref(`portfolioTemplates/levels/${id}`);
  const level = await value(levelRef);

  if (!level) {
    return;
  }

  deleteLevelFromPart(id, level.partId);
  levelRef.remove();
};

const deleteLevelFromPart = (id, partId) =>
  ref(`portfolioTemplates/parts/${partId}/levels/${id}`).remove();

export default deleteLevel;
