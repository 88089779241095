import { useState } from 'react';
import PropTypes from 'prop-types';
import LevelDialog from 'components/RubricsPortfolioComponents/LevelDialog';
import AttachedSlosContext from './AttachedSlosContext';
import AttachedSlos from './AttachedSlos';
import LevelSelectArrows from './LevelSelectArrows';
import CustomInnerContentWrapper from './CustomInnerContentWrapper';

const PreconfiguredLevelDialog = ({
  part,
  subtopicIcon,
  levelCount,
  onClose,
  children,
  ...props
}) => {
  const [visibleLevelIndex, setVisibleLevelIndex] = useState(0);

  return (
    <LevelDialog onClose={onClose} {...props}>
      <LevelDialog.Title subtopicIcon={subtopicIcon} onClose={onClose}>
        {part.title}
      </LevelDialog.Title>
      <LevelDialog.Content>
        <AttachedSlosContext.AutomatedProvider part={part}>
          <AttachedSlos />
          <LevelSelectArrows
            levelCount={levelCount}
            visibleLevelIndex={visibleLevelIndex}
            onChangeVisibleLevelIndex={index => setVisibleLevelIndex(index)}
          />
        </AttachedSlosContext.AutomatedProvider>
        <CustomInnerContentWrapper visibleLevelIndex={visibleLevelIndex}>
          {children}
        </CustomInnerContentWrapper>
      </LevelDialog.Content>
    </LevelDialog>
  );
};

PreconfiguredLevelDialog.propTypes = {
  part: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  subtopicIcon: PropTypes.string,
  levelCount: PropTypes.number,
  onClose: PropTypes.func,
  children: PropTypes.node
};

PreconfiguredLevelDialog.defaultProps = {
  levelCount: 0
};

export default PreconfiguredLevelDialog;
