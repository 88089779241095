import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const RowNameWrapper = styled('aside')(({ theme }) => ({
  position: 'relative',
  padding: 12,
  width: '20%',
  backgroundColor: theme.palette.background.default,
  borderRight: `2px solid ${theme.palette.background.border}`,
  transition: theme.transitions.create(['background-color', 'border']),
  boxSizing: 'border-box',
  zIndex: 1,
  [mediaQuery(700)]: {
    width: '100%',
    borderBottom: `2px solid ${theme.palette.background.border}`,
    borderRight: 'none'
  }
}));

export default RowNameWrapper;
