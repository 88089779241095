import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import SelectEditor from './TypeSpecificEditors/SelectEditor';
import LogEntryNameEditor from './TypeSpecificEditors/LogEntryNameEditor';
import LinkButtonEditor from './TypeSpecificEditors/LinkButtonEditor';
import RubricsEditor from './TypeSpecificEditors/RubricsEditor';
import LearningGoalsEditor from './TypeSpecificEditors/LearningGoalsEditor';
import ColorsEditor from './TypeSpecificEditors/ColorsEditor';

const typeSpecificEditors = {
  [FieldTypes.SELECT]: SelectEditor,
  [FieldTypes.LOGS]: LogEntryNameEditor,
  [FieldTypes.LINK_BUTTON]: LinkButtonEditor,
  [FieldTypes.RUBRICS]: RubricsEditor,
  [FieldTypes.LEARNING_GOALS]: LearningGoalsEditor,
  [FieldTypes.MANUAL_COLOR_PICKER]: ColorsEditor
};

const getTypeSpecificEditor = type => {
  const Editor = typeSpecificEditors[type];

  if (!Editor) {
    return null;
  }

  return <Editor />;
};

export default getTypeSpecificEditor;
