import PropTypes from 'prop-types';

const WeekMapper = ({ weekCount, children }) =>
  new Array(weekCount)
    .fill(null)
    .map((_, numberOfWeeksFromRangeStartDate) =>
      children(numberOfWeeksFromRangeStartDate)
    );

WeekMapper.propTypes = {
  weekCount: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired
};

export default WeekMapper;
