import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import PlatformTypes from 'lib/constants/PlatformTypes';
import { Tooltip } from '@material-ui/core';
import ActionButton from '../PortfolioTemplatesEditorActionButtons/ActionButton';
import { Link as AttachmentIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ManagedFromLearningGoals = props => {
  const [, setSelectedPlatform] = useContext(SelectedPlatformContext);
  return (
    <Tooltip
      title={
        <FormattedMessage id="portfolio_template_editor.part.managed_from_learning_goals.tooltip" />
      }
    >
      <ActionButton
        color="primary"
        onClick={() => setSelectedPlatform(PlatformTypes.CHALLENGEMONITOR)}
        {...props}
      >
        <AttachmentIcon />
      </ActionButton>
    </Tooltip>
  );
};

export default ManagedFromLearningGoals;
