import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import isValidUrl from 'lib/utils/isValidUrl';
import Dialog from 'lib/components/Dialog';
import Prompt from 'lib/components/Prompt';
import WarningText from './WarningText';
import DialogAddButton from './DialogAddButton';
import { FormattedMessage } from 'react-intl';

const ProofPrompt = ({ onOk, onCancel, children, ...props }) => {
  const [input, setInput] = useState('');
  const [showInvalidUrlWarning, setShowInvalidUrlWarning] = useState(false);
  const [showEmptyError, setShowEmptyError] = useState(null);

  const handleCancel = () => {
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
    onCancel();
  };

  const inputRef = useRef(null);

  const handleOk = () => {
    if (!input.trim()) {
      setShowEmptyError(true);
      inputRef.current.focus();
      return;
    }

    onOk(input.trim());

    setInput('');
    setShowInvalidUrlWarning(false);
    setShowEmptyError(false);
  };

  const handleInputChange = e => {
    const newInput = e.target.value;

    setInput(newInput);
    setShowEmptyError(false);

    const hasInput = !!newInput.trim();
    const urlIsValid = isValidUrl(newInput);
    setShowInvalidUrlWarning(hasInput && !urlIsValid);
  };

  return (
    <Dialog onClose={handleCancel} onEnterClose={handleOk} {...props}>
      {children}
      <Prompt.TextField
        label={<FormattedMessage id="portfolio.level.proof.add.input_label" />}
        value={input}
        onChange={handleInputChange}
        error={showEmptyError}
        helperText={
          (showInvalidUrlWarning && (
            <WarningText>
              <FormattedMessage id="portfolio.level.proof.add.invalid_url_warning" />
            </WarningText>
          )) ||
          (showEmptyError && (
            <FormattedMessage id="portfolio.level.proof.empty_error" />
          ))
        }
        inputRef={inputRef}
      />
      <Dialog.Actions addTopMargin {...props}>
        <Dialog.Actions.CancelButton onClick={handleCancel} />
        <DialogAddButton onClick={handleOk} />
      </Dialog.Actions>
    </Dialog>
  );
};

ProofPrompt.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ProofPrompt;
