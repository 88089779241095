import useChallengeNaming from './useChallengeNaming';
import useContentSetting from './useContentSetting';
import useInstanceLogo from './useInstanceLogo';
import useReadContentSetting from './useReadContentSetting';
import useSetting from './useSetting';
import useSettings from './useSettings';

const instanceConfig = {
  useChallengeNaming,
  useContentSetting,
  useInstanceLogo,
  useReadContentSetting,
  useSetting,
  useSettings
};

export default instanceConfig;
