import PropTypes from 'prop-types';
import StyledAddFeedbackButton from './StyledAddFeedbackButton';
import { AddComment as AddFeedbackIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';
import { useMediaQuery } from '@material-ui/core';

const AddFeedbackButton = props => {
  const visible = useMediaQuery('(min-width: 1250px)');

  if (!visible) {
    return null;
  }

  return (
    <StyledAddFeedbackButton
      variant="extended"
      color="primary"
      className="add-feedback"
      {...props}
    >
      <AddFeedbackIcon />
      <Space width={8} />
      <FormattedMessage id="portfolio.challenge.coach_feedback.add.button" />
    </StyledAddFeedbackButton>
  );
};

AddFeedbackButton.propTypes = {
  belowFeedbackList: PropTypes.bool.isRequired
};

export default AddFeedbackButton;
