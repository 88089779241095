import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../../../../../../SloContext';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { challengeRef, ref } from 'lib/firebase';
import ChallengeWrapper from './ChallengeWrapper';
import { Checkbox, ListItemText } from '@material-ui/core';
import Space from 'lib/components/Space';
import ChallengeNameLoadingPlaceholder from './ChallengeNameLoadingPlaceholder';

const ChallengeWithAttachmentHandling = ({ challengeKey, ...props }) => {
  const slo = useContext(SloContext);
  const [challengeLoading, challenge] = useFirebaseValue(
    challengeRef(challengeKey)
  );

  const challengeIsAttached = !!slo.attachedChallenges?.[challengeKey];
  const toggleChallengeAttachment = () => {
    const nextAttachmentState = challengeIsAttached ? null : true;

    ref(`slos/${slo.key}/attachedChallenges/${challengeKey}`).set(
      nextAttachmentState
    );

    challengeRef(challengeKey)
      .child(`attachedSlos/${slo.key}`)
      .set(nextAttachmentState);
  };

  return (
    <ChallengeWrapper
      button={!challengeLoading}
      onClick={() => !challengeLoading && toggleChallengeAttachment()}
      {...props}
    >
      <Checkbox
        color="primary"
        checked={challengeIsAttached}
        onChange={toggleChallengeAttachment}
        disabled={challengeLoading}
      />
      <Space width={6} />
      {challengeLoading ? (
        <ChallengeNameLoadingPlaceholder />
      ) : (
        <ListItemText primary={challenge.name} />
      )}
    </ChallengeWrapper>
  );
};

ChallengeWithAttachmentHandling.propTypes = {
  challengeKey: PropTypes.string.isRequired
};

export default ChallengeWithAttachmentHandling;
