import { useContext } from 'react';
import App from 'lib/App';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import { useIntl } from 'react-intl';
import { modesWithPlatformDifferences } from 'App';

const useProductName = () => {
  const loggedIn = App.authentication.useLoggedInState();
  const selectedMode = App.navigation.useSelectedMode();
  const [selectedPlatform] = useContext(SelectedPlatformContext);

  const intl = useIntl();

  if (!loggedIn || !modesWithPlatformDifferences.includes(selectedMode)) {
    return intl.formatMessage({
      id: 'software_name'
    });
  }

  return intl.formatMessage({
    id: `platform_names.${selectedPlatform.toLowerCase()}`
  });
};

export default useProductName;
