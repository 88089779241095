import PropTypes from 'prop-types';
import { ClassNames } from '@emotion/react';
import mediaQuery from 'lib/styling/mediaQuery';
import DialogWithEnterClose from './DialogWithEnterClose';
import BackdropWithDesaturation from '../BackdropWithDesaturation';
import ResponsiveDialogPaper from './ResponsiveDialogPaper';

const pushSmallScreenDialogToBottomStyles = {
  [mediaQuery(640)]: {
    alignItems: 'flex-end'
  }
};

const ResponsiveDialog = ({ dangerous, ...props }) => (
  <ClassNames>
    {({ css }) => (
      <DialogWithEnterClose
        classes={{
          container: css(pushSmallScreenDialogToBottomStyles)
        }}
        BackdropComponent={BackdropWithDesaturation}
        PaperComponent={ResponsiveDialogPaper}
        PaperProps={{
          dangerous
        }}
        fullWidth
        {...props}
      />
    )}
  </ClassNames>
);

ResponsiveDialog.propTypes = {
  dangerous: PropTypes.bool
};

ResponsiveDialog.defaultProps = {
  dangerous: false
};

export default ResponsiveDialog;
