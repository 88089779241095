import Dialog from 'lib/components/Dialog';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import { FormattedMessage } from 'react-intl';

const ManagerLoadingContent = () => (
  <>
    <Dialog.Title>
      <FormattedMessage id="slo_system.slo.part_attachment.management_dialog.title" />
    </Dialog.Title>
    <LoadingSpinner margin={48} centered />
  </>
);

export default ManagerLoadingContent;
