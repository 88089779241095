import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import reflectionModel from './reflection-model.json';
import sortByUiPos from 'lib/utils/sortByUiPos';
import usePhaseShifting from './usePhaseShifting';
import FullscreenView from '../../../../../../FullscreenView';
import ReflectionContent from './ReflectionContent';
import ReflectionPhasesWrapper from './ReflectionPhasesWrapper';
import ReflectionPhase from './ReflectionPhase';
import PhaseShiftArrows from './PhaseShiftArrows';
import { FormattedMessage } from 'react-intl';

const SloReflection = props => {
  const reflectionPhases = flattenFirebaseList(reflectionModel.phases).sort(
    sortByUiPos
  );

  const {
    outerWrapperRef,
    phasesWrapperRef,
    phaseShifts,
    canShiftPhasesLeft,
    canShiftPhasesRight,
    shiftPhasesLeft,
    shiftPhasesRight
  } = usePhaseShifting(reflectionPhases.length);

  return (
    <FullscreenView.Content {...props}>
      <FullscreenView.Content.Title>
        <FormattedMessage id="slo_system.slo.reflection_title" />
      </FullscreenView.Content.Title>
      <ReflectionContent ref={outerWrapperRef}>
        <ReflectionPhasesWrapper
          phaseCount={reflectionPhases.length}
          phaseShifts={phaseShifts}
          ref={phasesWrapperRef}
        >
          {reflectionPhases.map(phase => (
            <ReflectionPhase phase={phase} key={phase.key} />
          ))}
        </ReflectionPhasesWrapper>
        <PhaseShiftArrows
          canShiftLeft={canShiftPhasesLeft}
          canShiftRight={canShiftPhasesRight}
          onShiftLeft={shiftPhasesLeft}
          onShiftRight={shiftPhasesRight}
        />
      </ReflectionContent>
    </FullscreenView.Content>
  );
};

export default SloReflection;
