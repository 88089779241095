import PropTypes from 'prop-types';
import LoadingContentWrapper from './LoadingContentWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import Space from 'lib/components/Space';

const LoadingSpinnerWithText = ({ children, ...props }) => (
  <LoadingContentWrapper {...props}>
    <LoadingSpinner color="primary" />
    <Space width={18} />
    <h3>{children}</h3>
  </LoadingContentWrapper>
);

LoadingSpinnerWithText.propTypes = {
  children: PropTypes.node
};

export default LoadingSpinnerWithText;
