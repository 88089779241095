import PropTypes from 'prop-types';
import LearningGoalsWrapper from 'components/LearningGoals/LearningGoalsWrapper';
import LearningGoalsHeader from './LearningGoalsHeader';
import Topics from './Topics';

const UncollapsedLearningGoals = ({ onClose, ...props }) => (
  <LearningGoalsWrapper {...props}>
    <LearningGoalsHeader onClose={onClose} />
    <Topics />
  </LearningGoalsWrapper>
);

UncollapsedLearningGoals.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default UncollapsedLearningGoals;
