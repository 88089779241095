import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const SelectedLevelLabel = styled('div')({
  marginLeft: 24,
  marginRight: 24,
  fontFamily: 'CircularStd',
  [mediaQuery(500)]: {
    marginLeft: 0,
    marginRight: 0
  }
});

export default SelectedLevelLabel;
