import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import FloatingUserSwitchArrow from './FloatingUserSwitchArrow';
import NormalUserSwitchArrow from './NormalUserSwitchArrow';

const UserSwitchArrow = ({ floating, disabled, label, children, ...props }) => {
  if (floating && disabled) {
    return null;
  }

  if (floating) {
    return (
      <Tooltip title={label}>
        <FloatingUserSwitchArrow color="primary" {...props}>
          {children}
        </FloatingUserSwitchArrow>
      </Tooltip>
    );
  }

  return (
    <NormalUserSwitchArrow disabled={disabled} {...props}>
      {children}
      <NormalUserSwitchArrow.Label>{label}</NormalUserSwitchArrow.Label>
    </NormalUserSwitchArrow>
  );
};

UserSwitchArrow.propTypes = {
  label: PropTypes.node.isRequired,
  floating: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default UserSwitchArrow;
