import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../../../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';
import deletePartFromFirebase from 'lib/firebase/rubricsPortfolioTemplates/deletePart';

export const deletePart = (learningGoalsModelKey, subtopicKey, partId) => {
  ref('models')
    .child(`learningGoals/${learningGoalsModelKey}`)
    .child(`subtopics/${subtopicKey}/parts/${partId}`)
    .remove();

  deletePartFromFirebase(partId);
};

const DeletePart = ({ part, subtopicKey, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </TileGrid.Tile.ActionButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton
            onClick={() =>
              deletePart(learningGoalsModel.key, subtopicKey, part.id)
            }
          />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeletePart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    subtopicId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.bool)
    ])
  }).isRequired,
  subtopicKey: PropTypes.string.isRequired
};

export default DeletePart;
