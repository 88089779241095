import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const SelectableButton = ({ selected, ...props }) => (
  <Button
    {...props}
    variant={selected ? 'contained' : 'outlined'}
    color={selected ? 'secondary' : 'default'}
    disableElevation={selected}
  />
);

SelectableButton.propTypes = {
  selected: PropTypes.bool.isRequired
};

export default SelectableButton;
