import FullscreenView from '../../../../../../FullscreenView';
import AttachedParts from './AttachedParts';
import ManageAttachedParts from './ManageAttachedParts';
import { FormattedMessage } from 'react-intl';

const SloPartAttachment = props => (
  <FullscreenView.Content {...props}>
    <FullscreenView.Content.Title>
      <FormattedMessage id="slo_system.slo.part_attachment.title" />
    </FullscreenView.Content.Title>
    <AttachedParts />
    <ManageAttachedParts />
  </FullscreenView.Content>
);

export default SloPartAttachment;
