import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import MobileCoachFeedback from './MobileCoachFeedback';
import FeedbackList from './FeedbackList';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import AddFeedback from './AddFeedback';

const CoachFeedback = () => {
  const { field, challenge } = useContext(FieldContext);

  const feedback = challenge.coachComments?.[field.key] || {};

  const hasFeedback = Object.keys(feedback).length > 0;

  return (
    <>
      <MobileCoachFeedback feedback={feedback} />
      {hasFeedback ? (
        <FeedbackList feedback={feedback} />
      ) : (
        <RestrictedToPortfolioMode mode={PortfolioModes.COACH}>
          <AddFeedback />
        </RestrictedToPortfolioMode>
      )}
    </>
  );
};

export default CoachFeedback;
