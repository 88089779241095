import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { InfoOutlined as InfoIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ReleasesButton = props => (
  <ListItem button {...props}>
    <ListItemIcon>
      <InfoIcon />
    </ListItemIcon>
    <ListItemText
      primary={<FormattedMessage id="header.options_menu.options.releases" />}
    />
  </ListItem>
);

export default ReleasesButton;
