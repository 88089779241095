import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import useNumberOfVisibleLevels from './useNumberOfVisibleLevels';
import formatVisibleLevelCountString from './formatVisibleLevelCountString';
import LevelSelectArrowsWrapper from './LevelSelectArrowsWrapper';
import SelectedLevelLabel from './SelectedLevelLabel';
import { IconButton } from '@material-ui/core';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon
} from '@material-ui/icons';

const LevelSelectArrows = ({
  levelCount,
  visibleLevelIndex,
  onChangeVisibleLevelIndex,
  ...props
}) => {
  const numberOfVisibleLevels = useNumberOfVisibleLevels();

  if (levelCount === 0) {
    return null;
  }

  return (
    <LevelSelectArrowsWrapper {...props}>
      <IconButton
        onClick={() => onChangeVisibleLevelIndex(visibleLevelIndex - 1)}
        disabled={visibleLevelIndex === 0}
      >
        <LeftIcon />
      </IconButton>
      <SelectedLevelLabel>
        <FormattedMessage
          id="portfolio.level_select_arrows.label"
          values={{
            visibleLevelCount: formatVisibleLevelCountString(
              visibleLevelIndex,
              numberOfVisibleLevels
            ),
            totalLevelCount: levelCount
          }}
        />
      </SelectedLevelLabel>
      <IconButton
        onClick={() => onChangeVisibleLevelIndex(visibleLevelIndex + 1)}
        disabled={visibleLevelIndex + numberOfVisibleLevels === levelCount}
      >
        <RightIcon />
      </IconButton>
    </LevelSelectArrowsWrapper>
  );
};

LevelSelectArrows.propTypes = {
  levelCount: PropTypes.number.isRequired,
  visibleLevelIndex: PropTypes.number.isRequired,
  onChangeVisibleLevelIndex: PropTypes.func.isRequired
};

export default LevelSelectArrows;
