import PropTypes from 'prop-types';
import SettingsFieldTitle from '../../SettingsFieldTitle';
import AllowedEmailForm from './AllowedEmailForm';
import AddAllowedEmail from './AddAllowedEmail';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const AllowedEmailManager = ({
  allowedEmails,
  addAllowedEmail,
  editAllowedEmail,
  removeAllowedEmail,
  ...props
}) => (
  <>
    <SettingsFieldTitle>
      <FormattedMessage id="platform_settings.login_settings.manage_allowed_emails" />
    </SettingsFieldTitle>
    {allowedEmails.map(({ id, allowedEmail }) => (
      <AllowedEmailForm
        allowedEmail={allowedEmail}
        onEdit={newAllowedEmail => editAllowedEmail(id, newAllowedEmail)}
        onRemove={() => removeAllowedEmail(id)}
        key={id}
        {...props}
      />
    ))}
    {allowedEmails.length < 10 && (
      <AddAllowedEmail allowedEmails={allowedEmails} onAdd={addAllowedEmail} />
    )}
    <Space height={24} />
  </>
);

AllowedEmailManager.propTypes = {
  allowedEmails: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      allowedEmail: PropTypes.string.isRequired
    })
  ).isRequired,
  addAllowedEmail: PropTypes.func.isRequired,
  editAllowedEmail: PropTypes.func.isRequired,
  removeAllowedEmail: PropTypes.func.isRequired
};

export default AllowedEmailManager;
