/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';

const DialogWithEnterClose = ({
  open,
  onEnterClose,
  disableEnterClose,
  ...props
}) => {
  const closeOnEnterPress = event => {
    if (event.key === 'Enter') {
      onEnterClose(event);
    }
  };

  useEffect(() => {
    if (!open || !onEnterClose || disableEnterClose) {
      return;
    }

    document.addEventListener('keypress', closeOnEnterPress);
    return () => document.removeEventListener('keypress', closeOnEnterPress);
  }, [open, onEnterClose, disableEnterClose]);

  return <Dialog open={open} {...props} />;
};

DialogWithEnterClose.propTypes = {
  open: PropTypes.bool.isRequired,
  onEnterClose: PropTypes.func,
  disableEnterClose: PropTypes.bool
};

export default DialogWithEnterClose;
