import PropTypes from 'prop-types';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const SettingsCheckbox = ({ label, ...props }) => (
  <>
    <FormControlLabel
      control={<Checkbox color="primary" {...props} />}
      label={<FormattedMessage id={label} />}
    />
    <br />
  </>
);

SettingsCheckbox.propTypes = {
  label: PropTypes.string
};

export default SettingsCheckbox;
