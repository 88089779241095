import PropTypes from 'prop-types';
import SelectWrapper from './SelectWrapper';
import PrimaryIconButton from '../PrimaryIconButton';
import {
  ChevronLeft as LeftIcon,
  ChevronRight as RightIcon
} from '@material-ui/icons';

const YearSelect = ({ years, selectedYear, setSelectedYear, ...props }) => {
  const selectedYearIndex = years.indexOf(selectedYear.toString());

  const previousYear = years[selectedYearIndex - 1];
  const nextYear = years[selectedYearIndex + 1];

  const moveToPreviousYear = () => setSelectedYear(previousYear);
  const moveToNextYear = () => setSelectedYear(nextYear);

  return (
    <SelectWrapper {...props}>
      <PrimaryIconButton onClick={moveToPreviousYear} disabled={!previousYear}>
        <LeftIcon />
      </PrimaryIconButton>
      {selectedYear}
      <PrimaryIconButton onClick={moveToNextYear} disabled={!nextYear}>
        <RightIcon />
      </PrimaryIconButton>
    </SelectWrapper>
  );
};

YearSelect.propTypes = {
  years: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedYear: PropTypes.number.isRequired,
  setSelectedYear: PropTypes.func.isRequired
};

export default YearSelect;
