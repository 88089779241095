import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledSwitch = styled(Paper)(({ theme }) => ({
  position: 'relative',
  marginRight: 12,
  width: 200,
  fontFamily: 'CircularStd',
  border: `2px solid ${theme.palette.divider}`,
  transition: theme.transitions.create(['border']),
  boxShadow: 'none',
  overflow: 'hidden',
  [mediaQuery(700)]: {
    width: '100%',
    boxSizing: 'border-box'
  }
}));

export default StyledSwitch;
