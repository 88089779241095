import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const InnerHeaderWrapper = styled('section')({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(640)]: {
    alignItems: 'flex-start'
  }
});

export default InnerHeaderWrapper;
