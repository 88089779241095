import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import { safeEntries } from 'lib/utils/safeObjectFunctions';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import Space from 'lib/components/Space';
import OptionEditor from './OptionEditor';
import AddOptionForm from './AddOptionForm';
import MultipleOptionsSelectableCheckbox from './MultipleOptionsSelectableCheckbox';

const SelectEditor = props => {
  const { field } = useContext(FieldContext);
  const options = safeEntries(field.options);

  const fieldRef = useCurrentFieldRef();
  const optionsRef = fieldRef.child('options');

  return (
    <section {...props}>
      <Space height={12} />
      {options.map(([key, option]) => (
        <OptionEditor
          option={option}
          optionRef={optionsRef.child(key)}
          key={key}
        />
      ))}
      <AddOptionForm optionsRef={optionsRef} />
      <MultipleOptionsSelectableCheckbox />
    </section>
  );
};

export default SelectEditor;
