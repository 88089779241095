import EditField from './EditField';
import { useIntl } from 'react-intl';

const TitleField = props => {
  const intl = useIntl();
  return (
    <EditField
      placeholder={intl.formatMessage({
        id: 'portfolio_template_editor.level.edit.title_input_placeholder'
      })}
      {...props}
    />
  );
};

export default TitleField;
