import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ResponsiveTextField from './ResponsiveTextField';
import { useIntl, FormattedMessage } from 'react-intl';

const RowTextField = ({ hasError, challengeNameHasError, ...props }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (hasError && !challengeNameHasError) {
      inputRef.current.focus();
    }
  }, [hasError, challengeNameHasError]);

  const intl = useIntl();
  return (
    <ResponsiveTextField
      placeholder={intl.formatMessage({
        id:
          'portfolio.challenge_creation_flow.row_attachment.type_options.new_row.name_field.placeholder'
      })}
      error={hasError}
      helperText={
        hasError && (
          <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.new_row.name_field.errors.empty" />
        )
      }
      inputRef={inputRef}
      {...props}
    />
  );
};

RowTextField.propTypes = {
  hasError: PropTypes.bool.isRequired,
  challengeNameHasError: PropTypes.bool.isRequired
};

export default RowTextField;
