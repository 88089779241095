import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import getSubtopicsWithCorrespondingPartsAndProgress from './getSubtopicsWithCorrespondingPartsAndProgress';
import attachSubtopicsToParentTopics from './attachSubtopicsToParentTopics';

const buildLearningGoalsStructureWithProgress = (
  learningGoalsModel,
  portfolioTemplates,
  portfolio,
  challenge
) => {
  if (!learningGoalsModel || !learningGoalsModel.topics) {
    return [];
  }

  const topics = flattenFirebaseList(learningGoalsModel.topics);

  const subtopics = getSubtopicsWithCorrespondingPartsAndProgress(
    learningGoalsModel.subtopics,
    portfolioTemplates,
    portfolio,
    challenge
  );

  return attachSubtopicsToParentTopics(topics, subtopics);
};

export default buildLearningGoalsStructureWithProgress;
