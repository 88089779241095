import SubmenuContentWrapper from '../utils/SubmenuContentWrapper';
import AppearanceSelector from './AppearanceSelector';
import SettingsDivider from './SettingsDivider';
import HighContrastSwitch from './HighContrastSwitch';

const DisplaySettings = () => (
  <SubmenuContentWrapper>
    <AppearanceSelector />
    <SettingsDivider />
    <HighContrastSwitch />
  </SubmenuContentWrapper>
);

export default DisplaySettings;
