import styled from '@emotion/styled';
import { ListItem } from '@material-ui/core';

const PartWrapper = styled(ListItem)({
  paddingLeft: 15,
  paddingRight: 24,
  display: 'flex',
  alignItems: 'center'
});

export default PartWrapper;
