import styled from '@emotion/styled';

const StyledWorld = styled('div')({
  paddingTop: 4,
  paddingBottom: 4,
  display: 'flex',
  alignItems: 'center',
  ':first-child': {
    paddingTop: 0
  },
  ':last-child': {
    paddingBottom: 0
  }
});

export default StyledWorld;
