import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledUserList = styled('ul')(({ theme, collapsed }) => ({
  margin: '0 auto',
  padding: 0,
  maxHeight: 420,
  textAlign: 'left',
  listStyle: 'none',
  borderTop: `2px solid ${theme.palette.divider}`,
  display: collapsed ? 'none' : 'block',
  overflowY: 'auto'
}));

StyledUserList.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default StyledUserList;
