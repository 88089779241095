import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Fab } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import isPropValid from '@emotion/is-prop-valid';

const FloatingUserSwitchArrow = styled(Fab, {
  shouldForwardProp: isPropValid
})(({ right }) => ({
  position: 'fixed',
  top: '50%',
  left: right ? 'unset' : 30,
  right: right ? 30 : 'unset',
  transform: 'translateY(-50%)',
  [mediaQuery(1024)]: {
    display: 'none'
  }
}));

FloatingUserSwitchArrow.propTypes = {
  right: PropTypes.bool
};

FloatingUserSwitchArrow.defaultProps = {
  right: false
};

export default FloatingUserSwitchArrow;
