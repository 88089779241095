import PropTypes from 'prop-types';
import StudentProgressBar from './StudentProgressBar';
import CoachProgressBar from './CoachProgressBar';

const ProgressBars = ({
  studentProgress,
  coachProgress,
  sliding,
  ...props
}) => (
  <>
    <StudentProgressBar
      progress={studentProgress}
      coachProgress={coachProgress}
      sliding={sliding}
    />
    <CoachProgressBar
      progress={coachProgress}
      studentProgress={studentProgress}
      sliding={sliding}
    />
  </>
);

ProgressBars.propTypes = {
  studentProgress: PropTypes.number.isRequired,
  coachProgress: PropTypes.number.isRequired,
  sliding: PropTypes.bool.isRequired
};

export default ProgressBars;
