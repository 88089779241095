import { rowRef } from 'lib/firebase';
import { removeChallengeFromRow } from 'lib/firebase/challengePortfolio/useDeleteChallenge';

const updateChallengePhase = (
  challengeKey,
  oldPhase,
  newPhase,
  oldRow,
  newRow
) => {
  rowRef(newRow.key).child(`challenges/${newPhase}/${challengeKey}`).set(true);

  if (oldRow?.key) {
    removeChallengeFromRow(challengeKey, oldRow.key, oldPhase);
  }
};

export default updateChallengePhase;
