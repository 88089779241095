import styled from '@emotion/styled';
import { DialogActions, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const StyledDialogActions = styled(DialogActions)({
  padding: '16px 24px'
});

const CloseButton = props => (
  <StyledDialogActions>
    <Button color="primary" variant="contained" disableElevation {...props}>
      <FormattedMessage id="dialogs.actions.ok" />
    </Button>
  </StyledDialogActions>
);

export default CloseButton;
