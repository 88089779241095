import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

const StyledDropdownButton = styled(IconButton)(({ theme, open }) => ({
  position: 'absolute',
  top: -2,
  right: 3,
  cursor: 'pointer',
  transition: theme.transitions.create(),
  transform: open ? 'rotate(180deg)' : 'none',
  zIndex: 1
}));

StyledDropdownButton.propTypes = {
  open: PropTypes.bool.isRequired
};

export default StyledDropdownButton;
