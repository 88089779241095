import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';

const StyledButton = styled(Button)({
  width: 'fit-content'
});

const AddItemButton = ({ children, ...props }) => (
  <StyledButton color="secondary" startIcon={<AddIcon />} {...props}>
    {children}
  </StyledButton>
);

AddItemButton.propTypes = {
  children: PropTypes.node
};

export default AddItemButton;
