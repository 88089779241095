import { RadioGroup } from '@material-ui/core';
import ExistingRowOption from './ExistingRowOption';
import NewRowOption from './NewRowOption';

const RowAttachmentTypeOptions = ({
  selectedRowAttachmentType,
  onChangeSelectedRowAttachmentType,
  ...props
}) => (
  <RadioGroup
    name="row-attachment-type"
    value={selectedRowAttachmentType}
    onChange={e => onChangeSelectedRowAttachmentType(e.target.value)}
    {...props}
  />
);

RowAttachmentTypeOptions.ExistingRow = ExistingRowOption;
RowAttachmentTypeOptions.NewRow = NewRowOption;

export default RowAttachmentTypeOptions;
