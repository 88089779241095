import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const LogEntryDivider = styled('hr')(
  ({ theme }) => ({
    marginTop: 16,
    marginBottom: 0,
    border: 'none',
    borderBottom: `2px solid ${theme.palette.divider}`
  }),
  addHighContrastStyles({
    borderBottom: 'none'
  })
);

export default LogEntryDivider;
