import { useContext } from 'react';
import PropTypes from 'prop-types';
import PillButtons from '../PillButtons';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import useProof from '../useProof';
import { ref } from 'lib/firebase';
import AddProof from './AddProof';
import ProofMapper from '../ProofMapper';
import DeleteProof from './DeleteProof';
import { Link as LinkIcon } from '@material-ui/icons';

const PillButtonAsLink = PillButtons.Button.withComponent('a');

const LevelProof = ({ levelId, proofSubpath, ...props }) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const username = App.authentication.useUserUsername(portfolioUser);

  const proofRef = ref(`portfolios/${username}/proof`).child(
    proofSubpath ? `${levelId}/${proofSubpath}` : levelId
  );

  const proof = useProof(levelId, proofSubpath);

  return (
    <div {...props}>
      <ProofMapper proof={proof} proofRef={proofRef}>
        {([individualProofLink, individualProofRef], i) => (
          <PillButtons.Wrapper key={i}>
            <PillButtonAsLink
              leftHalf
              href={individualProofLink}
              target="_blank"
              {...props}
            >
              <LinkIcon />
            </PillButtonAsLink>
            <DeleteProof proofRef={individualProofRef} />
          </PillButtons.Wrapper>
        )}
      </ProofMapper>
      <AddProof proofRef={proofRef} proof={proof} />
    </div>
  );
};

LevelProof.propTypes = {
  levelId: PropTypes.string.isRequired,
  proofSubpath: PropTypes.string
};

export default LevelProof;
