import styled from '@emotion/styled';
import WorldImage from '../../WorldImage';

const StyledWorldImage = styled(WorldImage)({
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 12,
  width: 56,
  height: 56,
  display: 'block'
});

export default StyledWorldImage;
