import App from 'lib/App';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import sortByName from '../../sortByName';

const useSelectableGroups = groups => {
  const currentUser = App.authentication.useCurrentUser();

  if (!currentUser.managedGroups) {
    return [];
  }

  return safeKeys(currentUser.managedGroups)
    .filter(key => !!groups[key])
    .map(key => ({
      ...groups[key],
      key
    }))
    .sort(sortByName);
};

export default useSelectableGroups;
