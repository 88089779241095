import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import withSloPeriodExpandedState from './withSloPeriodExpandedState';

const SloPeriodWrapperContent = styled('section')(
  ({ theme, expanded, isActiveStep }) => ({
    paddingTop: expanded ? 18 : 0,
    paddingBottom: expanded ? 18 : 0,
    paddingLeft: 18,
    paddingRight: 18,
    width: '50%',
    height: expanded && isActiveStep ? 'auto' : 0,
    flexGrow: 0,
    flexShrink: 0,
    boxSizing: 'border-box',
    opacity: expanded && isActiveStep ? 1 : 0,
    transition: theme.transitions.create()
  })
);

SloPeriodWrapperContent.propTypes = {
  expanded: PropTypes.bool.isRequired
};

export default withSloPeriodExpandedState(SloPeriodWrapperContent);
