const forceCircularFontStyles = {
  fontFamily: 'CircularStd'
};

const fontConfig = {
  typography: {
    fontFamily: 'Inter',
    h1: forceCircularFontStyles,
    h2: forceCircularFontStyles,
    h3: forceCircularFontStyles,
    h4: forceCircularFontStyles,
    h5: forceCircularFontStyles,
    h6: forceCircularFontStyles,
    subtitle1: forceCircularFontStyles,
    subtitle2: forceCircularFontStyles,
    button: forceCircularFontStyles,
    caption: forceCircularFontStyles
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        fontFamily: 'Inter'
      }
    },
    MuiInputBase: {
      root: forceCircularFontStyles
    },
    MuiInputLabel: {
      root: forceCircularFontStyles
    },
    MuiMenuItem: {
      root: forceCircularFontStyles
    },
    MuiAvatar: {
      root: forceCircularFontStyles
    },
    MuiFormLabel: {
      root: forceCircularFontStyles
    },
    MuiFormControlLabel: {
      label: forceCircularFontStyles
    },
    MuiFormHelperText: {
      root: forceCircularFontStyles
    },
    MuiTooltip: {
      tooltip: forceCircularFontStyles
    },
    MuiListItemText: {
      primary: forceCircularFontStyles,
      secondary: forceCircularFontStyles
    },
    MuiSnackbarContent: {
      message: forceCircularFontStyles
    }
  }
};

export default fontConfig;
