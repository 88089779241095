import PropTypes from 'prop-types';
import useUserRowKeys from 'components/ChallengePortfolio/useUserRowKeys';
import Dialog from 'lib/components/Dialog';
import RowList from './RowList';
import SelectableRow from './SelectableRow';
import { FormattedMessage } from 'react-intl';

const RowSelector = ({ onSelectRow, ...props }) => {
  const rowKeysWithUiPos = useUserRowKeys();
  const rowKeys = rowKeysWithUiPos.map(([rowKey]) => rowKey);

  return (
    <>
      <Dialog.Title>
        <FormattedMessage id="slo_system.slo.challenge_attachment.management_dialog.title" />
      </Dialog.Title>
      {rowKeys.length === 0 ? (
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="slo_system.slo.challenge_attachment.management_dialog.no_challenges" />
          </Dialog.ContentText>
        </Dialog.Content>
      ) : (
        <RowList {...props}>
          {rowKeys.map(rowKey => (
            <SelectableRow
              rowKey={rowKey}
              onSelectRow={onSelectRow}
              key={rowKey}
            />
          ))}
        </RowList>
      )}
    </>
  );
};

RowSelector.propTypes = {
  onSelectRow: PropTypes.func.isRequired
};

export default RowSelector;
