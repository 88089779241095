import PropTypes from 'prop-types';
import SelectedUsersText from './SelectedUsersText';
import { FormattedMessage } from 'react-intl';

const SelectedUsersMessage = ({ selectedUsersCount, ...props }) => (
  <SelectedUsersText {...props}>
    {selectedUsersCount === 1 ? (
      <FormattedMessage id="user_manager.user_list.toolbar.user_count_singular" />
    ) : (
      <FormattedMessage
        id="user_manager.user_list.toolbar.user_count_plural"
        values={{
          userCount: selectedUsersCount
        }}
      />
    )}
  </SelectedUsersText>
);

SelectedUsersMessage.propTypes = {
  selectedUsersCount: PropTypes.number.isRequired
};

export default SelectedUsersMessage;
