import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const ColorPreview = styled('div')(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.text.primary}`,
    borderRadius: 4
  }),
  ({ color }) => ({
    backgroundColor: color
  }),
  ({ inButton }) => ({
    marginLeft: inButton ? 0 : 12,
    marginRight: inButton ? 10 : 0,
    width: inButton ? 18 : 24,
    minWidth: inButton ? 18 : 24,
    height: inButton ? 18 : 24
  })
);

ColorPreview.propTypes = {
  color: PropTypes.string.isRequired,
  inButton: PropTypes.bool
};

ColorPreview.defaultProps = {
  inButton: false
};

export default ColorPreview;
