import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const GroupSelectorWrapper = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: -2,
  left: -200,
  width: 200,
  border: `2px solid ${theme.palette.divider}`,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  boxSizing: 'border-box',
  boxShadow: 'none',
  [mediaQuery(1024)]: {
    left: -180,
    width: 180
  },
  [mediaQuery(850)]: {
    left: -140,
    width: 140
  },
  [mediaQuery(700)]: {
    display: 'none'
  }
}));

export default GroupSelectorWrapper;
