const formatVisibleLevelCountString = (
  visibleLevelIndex,
  numberOfVisibleLevels
) => {
  const visibleLevelCount = visibleLevelIndex + 1;

  const shouldDisplayVisibleLevelCountAsRange = numberOfVisibleLevels > 1;

  if (!shouldDisplayVisibleLevelCountAsRange) {
    return visibleLevelCount;
  }

  return `${visibleLevelCount}-${visibleLevelIndex + numberOfVisibleLevels}`;
};

export default formatVisibleLevelCountString;
