import { useState, useEffect } from 'react';
import { ref, value } from 'lib/firebase';

const useInstanceLogo = () => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    const cachedLogo = localStorage.getItem('cachedInstanceLogo');
    if (cachedLogo) {
      setLogo(cachedLogo);
    }

    const ensureLatestLogoPresentListener = async snapshot => {
      const latestLogoId = snapshot.val();
      const cachedLogoId = localStorage.getItem('cachedInstanceLogoId');

      const logoIsUpToDate = latestLogoId === cachedLogoId;
      if (!logoIsUpToDate) {
        const logo = await value(ref('instanceConfig/logo'));

        setLogo(logo);

        localStorage.setItem('cachedInstanceLogo', logo);
        localStorage.setItem('cachedInstanceLogoId', latestLogoId);
      }
    };

    ref('instanceConfig/logoId').on('value', ensureLatestLogoPresentListener);
    return () =>
      ref('instanceConfig/logoId').off(
        'value',
        ensureLatestLogoPresentListener
      );
  }, []);

  const logoLoading = !logo;
  return [logoLoading, logo];
};

export default useInstanceLogo;
