import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const buttonStyles = {
  //  position: 'absolute',
  //  top: 20,
  //  right: 24
};

const CloseDialogButton = ({ onClick }) => (
  <IconButton style={buttonStyles} onClick={onClick}>
    <CloseIcon />
  </IconButton>
);

CloseDialogButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default CloseDialogButton;
