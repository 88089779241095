import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import isPropValid from '@emotion/is-prop-valid';

const ProgressBar = styled(stripProps('div', 'onTop'), {
  shouldForwardProp: isPropValid
})(({ theme, progress, color, progressEqual, onTop, reversed }) => ({
  position: 'absolute',
  right: reversed ? 0 : 'unset',
  left: reversed ? 'unset' : 0,
  width: `${progress}%`,
  height: '100%',
  backgroundColor: progressEqual ? theme.palette.sliders.both : color,
  transition: theme.transitions.create(),
  zIndex: onTop ? 2 : 1
}));

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  progressEqual: PropTypes.bool.isRequired,
  onTop: PropTypes.bool.isRequired,
  reversed: PropTypes.bool.isRequired
};

export default ProgressBar;
