import PropTypes from 'prop-types';
import getChallengeUsers from '../../getChallengeUsers';
import AvatarList from './AvatarList';

const ChallengeAvatars = ({ challenge, ...props }) => {
  const usernames = getChallengeUsers(challenge).slice(0, 4);

  const currentUserIsOnlyUserInChallenge = usernames.length === 1;
  if (currentUserIsOnlyUserInChallenge) {
    return null;
  }

  return <AvatarList usernames={usernames} />;
};

ChallengeAvatars.propTypes = {
  challenge: PropTypes.shape({
    sharedWith: PropTypes.objectOf(PropTypes.bool),
    originalOwner: PropTypes.string
  }).isRequired
};

export default ChallengeAvatars;
