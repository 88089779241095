import { useState } from 'react';
import SloPeriodWrapper from './SloPeriodWrapper';
import { Skeleton } from '@material-ui/lab';

const SloPeriodLoadingPlaceholder = props => {
  const [randomNumber] = useState(Math.random());
  const nameLoadingPlaceholderWidth = 20 + randomNumber * 50;
  return (
    <SloPeriodWrapper {...props}>
      <SloPeriodWrapper.Header slosCollapsed>
        <Skeleton
          variant="text"
          width={`${nameLoadingPlaceholderWidth}%`}
          animation="wave"
        />
      </SloPeriodWrapper.Header>
    </SloPeriodWrapper>
  );
};

export default SloPeriodLoadingPlaceholder;
