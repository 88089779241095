import { useContext } from 'react';
import App from 'lib/App';
import SelectedUserContext from 'lib/contexts/SelectedUserContext';
import Modes from 'lib/constants/Modes';

const usePortfolioUser = () => {
  const selectedMode = App.navigation.useSelectedMode();
  const currentUser = App.authentication.useCurrentUser();
  const selectedUser = useContext(SelectedUserContext);

  if (selectedMode === Modes.PORTFOLIO) {
    return currentUser;
  }

  if (selectedMode === Modes.COACH) {
    return selectedUser;
  }

  return null;
};

export default usePortfolioUser;
