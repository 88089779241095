import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const TileWrapper = styled('section')(({ rows, columns }) => ({
  marginTop: 64,
  width: '100%',
  height: 'calc(100% - 64px)',
  display: 'grid',
  gridTemplateRows: `repeat(${rows}, 1fr)`,
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  [mediaQuery(900)]: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto'
  },
  [mediaQuery(600)]: {
    marginTop: 56,
    height: 'calc(100% - 56px)'
  }
}));

export default TileWrapper;
