import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';
import App from 'lib/App';
import Phase from './Phase';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import sortByUiPos from 'lib/utils/sortByUiPos';
import FieldRenderer from './FieldRenderer';
import itemIsVisibleToPortfolioUser from './itemIsVisibleToPortfolioUser';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const ChallengeFields = ({ asTask, challenge, challengeRef }) => {
  const portfolioUser = useContext(PortfolioUserContext);

  const modelType = asTask ? ModelTypes.TASK : ModelTypes.CHALLENGE;
  const models = App.useModels();
  const model = models?.[modelType];

  const fieldRendererProps = {
    modelType,
    challenge,
    challengeRef,
    asTask
  };

  return (
    <>
      {safeValues(model?.phases)
        .filter(phase => itemIsVisibleToPortfolioUser(phase, portfolioUser))
        .sort(sortByUiPos)
        .map((phase, i) => (
          <Phase key={i}>
            <Phase.Name>{phase.name}</Phase.Name>
            <Phase.ContentWrapper>
              <Phase.LeftContent>
                <FieldRenderer
                  fields={phase[PhaseContentGroups.LEFT_CONTENT]}
                  phaseContentGroup={PhaseContentGroups.LEFT_CONTENT}
                  {...fieldRendererProps}
                />
              </Phase.LeftContent>
              <Phase.RightContent>
                <FieldRenderer
                  fields={phase[PhaseContentGroups.RIGHT_CONTENT]}
                  phaseContentGroup={PhaseContentGroups.RIGHT_CONTENT}
                  {...fieldRendererProps}
                  noCoachFeedback
                />
              </Phase.RightContent>
            </Phase.ContentWrapper>
            {phase[PhaseContentGroups.END_CONTENT] && (
              <Phase.EndContent>
                <FieldRenderer
                  fields={phase[PhaseContentGroups.END_CONTENT]}
                  phaseContentGroup={PhaseContentGroups.END_CONTENT}
                  {...fieldRendererProps}
                />
              </Phase.EndContent>
            )}
          </Phase>
        ))}
    </>
  );
};

ChallengeFields.propTypes = {
  asTask: PropTypes.bool.isRequired,
  challenge: PropTypes.object.isRequired,
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default ChallengeFields;
