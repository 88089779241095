import appAuthentication from './authentication';
import appInstanceConfig from './instanceConfig';
import appNavigation from './navigation';
import useModels from './useModels';
import StatePopulator from './StatePopulator';

const App = {
  authentication: appAuthentication,
  instanceConfig: appInstanceConfig,
  navigation: appNavigation,
  useModels,
  StatePopulator
};

export default App;
