import PlatformTypes from 'lib/constants/PlatformTypes';

const getOtherPlatformType = currentPlatformType => {
  if (currentPlatformType === PlatformTypes.CHALLENGEMONITOR) {
    return PlatformTypes.PROGRESSMONITOR;
  }

  if (currentPlatformType === PlatformTypes.PROGRESSMONITOR) {
    return PlatformTypes.CHALLENGEMONITOR;
  }
};

export default getOtherPlatformType;
