import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StepsWrapper = styled('section')(({ theme, hasMovedToSecondStep }) => ({
  position: 'relative',
  left: hasMovedToSecondStep ? '-100%' : 0,
  width: '100%',
  display: 'flex',
  transition: theme.transitions.create()
}));

StepsWrapper.propTypes = {
  hasMovedToSecondStep: PropTypes.bool.isRequired
};

export default StepsWrapper;
