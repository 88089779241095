import SettingsFieldTitle from '../../SettingsFieldTitle';
import { RadioGroup } from '@material-ui/core';
import StyledRadioWrapper from './StyledRadioWrapper';
import RadioWithoutPadding from './RadioWithoutPadding';
import AuthProviders from 'lib/constants/AuthProviders';
import { FormattedMessage } from 'react-intl';

const AuthProviderPicker = props => (
  <>
    <SettingsFieldTitle>
      <FormattedMessage id="platform_settings.login_settings.change_auth_provider" />
    </SettingsFieldTitle>
    <RadioGroup name="auth-provider" {...props}>
      <StyledRadioWrapper
        value={AuthProviders.GOOGLE}
        control={<RadioWithoutPadding color="primary" />}
        label="Google"
      />
      <StyledRadioWrapper
        value={AuthProviders.MICROSOFT}
        control={<RadioWithoutPadding color="primary" />}
        label="Microsoft"
      />
    </RadioGroup>
  </>
);

export default AuthProviderPicker;
