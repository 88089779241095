import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import { Close as CancelIcon } from '@material-ui/icons';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: 6,
  padding: 6
}));

const StyledCancelIcon = styled(CancelIcon)({
  width: 20,
  height: 20
});

const SaveButton = props => (
  <StyledIconButton size="small" {...props}>
    <StyledCancelIcon />
  </StyledIconButton>
);

export default SaveButton;
