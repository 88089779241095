import { createContext } from 'react';

const FieldContext = createContext({
  field: null,
  fieldRef: null,
  phaseContentGroup: null,
  challenge: null,
  challengeRef: null,
  asTask: false,
  modelType: null
});

export default FieldContext;
