import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import AttachButton from './AttachButton';
import { LibraryAddOutlined as AttachIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const AttachChallenge = ({ levelKey, challenge, ...props }) => {
  const portfolio = useContext(PortfolioContext);
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const isAlreadyAttached = !!portfolio?.challengeProof?.[levelKey]?.[
    challenge.key
  ];

  if (isAlreadyAttached) {
    return null;
  }

  const attachChallenge = () => {
    ref(
      `portfolios/${portfolioUserUsername}/challengeProof/${levelKey}/${challenge.key}`
    ).set({
      attached: true,
      asTask: !!challenge.asTask
    });
  };

  return (
    <AttachButton onClick={attachChallenge} {...props}>
      <AttachIcon />
      <Space width={12} />
      <FormattedMessage
        id="portfolio.level.challenge_attachment.attach_button"
        values={{
          challengeName: challenge.name
        }}
      />
    </AttachButton>
  );
};

AttachChallenge.propTypes = {
  levelKey: PropTypes.string.isRequired,
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    asTask: PropTypes.bool
  })
};

export default AttachChallenge;
