import StyledClearButton from './StyledClearButton';
import ClearSelectedGroupIcon from './ClearSelectedGroupIcon';
import { FormattedMessage } from 'react-intl';

const ClearSelectedGroupButton = props => (
  <StyledClearButton {...props}>
    <ClearSelectedGroupIcon />
    <FormattedMessage id="user_switcher.group_selector.clear_button" />
  </StyledClearButton>
);

export default ClearSelectedGroupButton;
