import PropTypes from 'prop-types';
import CardWrapper from './CardWrapper';
import PhaseText from './PhaseText';
import phaseName from 'lib/utils/phaseName';
import ChallengeAvatars from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeCard/UserAvatars/ChallengeAvatars';
import Phases from 'lib/constants/Phases';

const ChallengePreviewCard = ({
  challenge,
  contentVisibilityCompensationDistance,
  ...props
}) => (
  <CardWrapper
    className="challenge-preview-card"
    contentVisibilityCompensationDistance={
      contentVisibilityCompensationDistance
    }
    {...props}
  >
    <h3>{challenge.name}</h3>
    <PhaseText>{phaseName(challenge.phase)}</PhaseText>
    <ChallengeAvatars challenge={challenge} />
  </CardWrapper>
);

ChallengePreviewCard.propTypes = {
  challenge: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phase: PropTypes.oneOf(Object.values(Phases)).isRequired
  }).isRequired,
  contentVisibilityCompensationDistance: PropTypes.number.isRequired
};

export default ChallengePreviewCard;
