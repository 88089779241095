import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const TextFieldWrapper = styled('footer')(
  ({ theme, noBackground }) => ({
    margin: noBackground ? 0 : -16,
    marginTop: 16,
    padding: noBackground ? 0 : 16,
    backgroundColor: noBackground ? 'none' : theme.palette.action.hover,
    display: 'flex',
    alignItems: 'center'
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderTop: `2px solid ${theme.palette.divider}`
  }))
);

TextFieldWrapper.propTypes = {
  noBackground: PropTypes.bool
};

TextFieldWrapper.defaultProps = {
  noBackground: false
};

export default TextFieldWrapper;
