/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { value } from 'lib/firebase';

const useUnsyncedFirebaseValue = ref => {
  const [loading, setLoading] = useState(true);
  const [firebaseValue, setFirebaseValue] = useState(null);

  const refAsString = ref.toString();
  useEffect(() => {
    setLoading(true);

    value(ref, true).then(value => {
      setFirebaseValue(value);
      setLoading(false);
    });
  }, [refAsString]);

  return [loading, firebaseValue];
};

export default useUnsyncedFirebaseValue;
