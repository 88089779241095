import HeaderActionIconButton from '../HeaderActionIconButton';
import { Search as SearchIcon } from '@material-ui/icons';

const SearchButton = props => (
  <HeaderActionIconButton type="submit" {...props}>
    <SearchIcon />
  </HeaderActionIconButton>
);

export default SearchButton;
