import App from 'lib/App';
import { useHistory } from 'react-router-dom';
import modeUrls from 'lib/mode/modeUrls';

const useIsDisplayingNotFoundPage = () => {
  const loggedIn = App.authentication.useLoggedInState();
  const selectedMode = App.navigation.useSelectedMode();

  const history = useHistory();
  const modePages = Object.values(modeUrls);
  const onModePage = modePages.includes(history.location.pathname);

  return !onModePage || (loggedIn && onModePage && !selectedMode);
};

export default useIsDisplayingNotFoundPage;
