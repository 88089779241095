import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import { darken } from 'lib/styling/colors';

const lightHighContrastPalette = {
  isHighContrast: true,
  primary: {
    main: common.black
  },
  secondary: {
    main: common.black
  },
  error: {
    main: common.black
  },
  warning: {
    main: common.black
  },
  danger: common.black,
  background: {
    default: common.white,
    shaded: grey[300],
    border: common.black
  },
  sliders: {
    student: lightBlue[300],
    coach: purple[500],
    both: indigo[800]
  },
  partFocusColor: common.black,
  partFocusTextColor: common.white,
  text: {
    primary: common.black,
    secondary: common.black,
    disabled: common.black
  },
  divider: common.black,
  increaseContrastWithBackground: (color, percentage) =>
    darken(color, percentage)
};

export default lightHighContrastPalette;
