import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const PageTitle = ({ id: pageTitleTranslationId, ...props }) => {
  const intl = useIntl();
  return (
    <Helmet
      title={intl.formatMessage({ id: pageTitleTranslationId })}
      {...props}
    />
  );
};

PageTitle.propTypes = {
  id: PropTypes.string.isRequired
};

export default PageTitle;
