import isBrowserOutdated from './isBrowserOutdated';
import OutdatedBrowserWarning from './OutdatedBrowserWarning';

const OutdatedBrowserDetection = props => {
  if (!isBrowserOutdated()) {
    return null;
  }

  return <OutdatedBrowserWarning {...props} />;
};

export default OutdatedBrowserDetection;
