/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import VisibleWeekRangeContext from '../../../../../VisibleWeekRangeContext';
import moment from 'moment';
import getChallengePositioning from './getChallengePositioning';
import RowChallengesVisibilityStateContext from '../../../RowChallengesVisibilityStateContext';
import PlanningBar from './PlanningBar';

const ChallengePlanningBar = ({ challenge, children, ...props }) => {
  const {
    visibleWeekRangeStartDate,
    visibleWeekRangeEndDate,
    visibleWeeksCount
  } = useContext(VisibleWeekRangeContext);

  const plannedStartDate = moment(challenge.plannedStartDate).startOf('day');
  const plannedEndDate = moment(challenge.plannedEndDate).startOf('day');
  const plannedDuration = plannedEndDate.diff(plannedStartDate, 'days') + 1;

  const {
    challengeDistanceFromLeft,
    challengeWidth,
    contentVisibilityCompensationDistance,
    contentMaxVisibleWidth
  } = getChallengePositioning(
    plannedStartDate,
    plannedEndDate,
    plannedDuration,
    visibleWeekRangeStartDate,
    visibleWeeksCount
  );

  const { registerChallengeVisibility } = useContext(
    RowChallengesVisibilityStateContext
  );

  const visible =
    plannedStartDate.isBefore(visibleWeekRangeEndDate) &&
    plannedEndDate.isAfter(visibleWeekRangeStartDate);

  useEffect(() => {
    registerChallengeVisibility(challenge.key, visible);
  }, [challenge.key, visible]);

  return (
    <PlanningBar
      challengeDistanceFromLeft={challengeDistanceFromLeft}
      challengeWidth={challengeWidth}
      visible={visible}
      disabled={!visible}
      {...props}
    >
      {children(contentVisibilityCompensationDistance, contentMaxVisibleWidth)}
    </PlanningBar>
  );
};

ChallengePlanningBar.propTypes = {
  challenge: PropTypes.shape({
    plannedStartDate: PropTypes.number.isRequired,
    plannedEndDate: PropTypes.number.isRequired
  }).isRequired,
  children: PropTypes.func.isRequired
};

export default ChallengePlanningBar;
