import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import FieldName from '../utils/FieldName';
import PickColorButton from './PickColorButton';
import { ClickAwayListener } from '@material-ui/core';
import PickerContent from './PickerContent';
import WHITE from './PickerContent/defaultColors';
import ColorPreview from 'pages/EditorPage/ChallengeModelEditor/ModelEditorForModelType/ModelPhaseEditor/ModelFieldEditor/TypeSpecificEditors/LearningGoalsEditor/LearningGoalsManager/PromptWithColors/ColorPicker/CustomColorTextField/ColorPreview';
import { FormattedMessage } from 'react-intl';

const ColorPicker = () => {
  const { challenge } = useContext(FieldContext);

  const portfolioMode = useContext(PortfolioModeContext);
  const [pickerOpen, setPickerOpen] = useState(false);

  const selectedColor = (challenge.color || WHITE).toString().toUpperCase();

  return (
    <section>
      <FieldName />
      <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
        <div style={{ position: 'relative' }}>
          <PickColorButton
            onClick={() => setPickerOpen(!pickerOpen)}
            disabled={portfolioMode === PortfolioModes.COACH}
          >
            <ColorPreview color={selectedColor} inButton />
            <FormattedMessage id="portfolio.challenge.color_picker.button" />
          </PickColorButton>
          {pickerOpen && <PickerContent />}
        </div>
      </ClickAwayListener>
    </section>
  );
};

export default ColorPicker;
