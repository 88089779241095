import PropTypes from 'prop-types';
import StyledInfoField from './StyledInfoField';
import StyledWorldImage from './StyledWorldImage';
import WorldName from './WorldName';
import WorldInfo from './WorldInfo';
import worlds from '../../worlds';
import { FormattedMessage } from 'react-intl';

const InfoField = ({ world, ...props }) => (
  <StyledInfoField {...props}>
    <StyledWorldImage world={world} />
    <WorldName>
      <FormattedMessage
        id={`portfolio.challenge.world_select.world_labels.${world}_world`}
      />
    </WorldName>
    <WorldInfo>
      <FormattedMessage
        id={`portfolio.challenge.world_select.world_info.${world}_world`}
      />
    </WorldInfo>
  </StyledInfoField>
);

InfoField.propTypes = {
  world: PropTypes.oneOf(worlds).isRequired
};

export default InfoField;
