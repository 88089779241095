import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ManageButton = props => (
  <Button size="small" variant="outlined" fullWidth {...props}>
    <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.button" />
  </Button>
);

export default ManageButton;
