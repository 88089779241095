import { useContext } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../../FieldContext';
import defaultColors from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/FieldRenderer/TypeSpecificFieldRenderers/ColorPicker/PickerContent/defaultColors';
import ColorBoxesWrapper from './ColorBoxesWrapper';
import ColorBox from './ColorBox';

const ColorBoxes = ({ selectedColor, onSelectColor, ...props }) => {
  const { field } = useContext(FieldContext);
  const colorOptions = field.colors || defaultColors;

  return (
    <ColorBoxesWrapper>
      {colorOptions.map(color => (
        <ColorBox
          color={color}
          selected={color === selectedColor}
          onClick={() => onSelectColor(color)}
          key={color}
        />
      ))}
    </ColorBoxesWrapper>
  );
};

ColorBoxes.propTypes = {
  selectedColor: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired
};

export default ColorBoxes;
