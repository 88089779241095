import App from 'lib/App';
import AuthProviders from 'lib/constants/AuthProviders';
import googleLogo from './authProviderLogos/google-logo.svg';
import microsoftLogo from './authProviderLogos/microsoft-logo.svg';
import getLogoMarginStyles from './getLogoMarginStyles';

const AuthProviderLogo = props => {
  const authProvider = App.instanceConfig.useSetting('authProvider');
  return (
    <img
      src={authProvider === AuthProviders.GOOGLE ? googleLogo : microsoftLogo}
      style={getLogoMarginStyles(authProvider)}
      alt=""
      {...props}
    />
  );
};

export default AuthProviderLogo;
