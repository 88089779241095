import PropTypes from 'prop-types';
import PageOverlay from './PageOverlay';
import CloseButton from './CloseButton';

const ClosablePageOverlay = ({ taskBoardOpen, onClose, ...props }) => (
  <PageOverlay onClick={onClose} {...props}>
    <CloseButton taskBoardOpen={taskBoardOpen} onClick={onClose} />
  </PageOverlay>
);

ClosablePageOverlay.propTypes = {
  taskBoardOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ClosablePageOverlay;
