import { useContext, useState } from 'react';
import SloPeriodContext from '../SloPeriodContext';
import useSyncedState from 'lib/utils/useSyncedState';
import { ref } from 'lib/firebase';
import { useIntl } from 'react-intl';
import WrapperForm from './WrapperForm';
import NameInput from './NameInput';
import CancelButton from 'lib/components/CancelButton';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon, Edit as EditIcon } from '@material-ui/icons';
import SloPeriodName from './SloPeriodName';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';

const EditableSloPeriodName = props => {
  const sloPeriod = useContext(SloPeriodContext);

  const [editing, setEditing] = useState(false);
  const [newSloPeriodName, setNewSloPeriodName] = useSyncedState(
    sloPeriod.name
  );

  const saveNameChange = () => {
    if (newSloPeriodName.trim() !== '') {
      ref(`sloPeriods/${sloPeriod.key}/name`).set(newSloPeriodName);
      setEditing(false);
    }
  };

  const cancelNameChange = () => {
    setNewSloPeriodName(sloPeriod.name);
    setEditing(false);
  };

  const preventPropagation = fn => (e, ...parameters) => {
    e.stopPropagation();
    fn(e, ...parameters);
  };

  const intl = useIntl();
  return (
    <WrapperForm onSubmit={e => e.preventDefault()} fullWidth={editing}>
      {editing ? (
        <>
          <NameInput
            placeholder={intl.formatMessage({
              id: 'slo_system.slo_period.edit.input_placeholder'
            })}
            value={newSloPeriodName}
            onChange={e => setNewSloPeriodName(e.target.value)}
            onEscPress={cancelNameChange}
            autoFocus
          />
          <CancelButton
            visible={newSloPeriodName !== sloPeriod.name}
            onClick={preventPropagation(cancelNameChange)}
          />
          <IconButton
            type="submit"
            onClick={preventPropagation(saveNameChange)}
          >
            <CheckIcon />
          </IconButton>
        </>
      ) : (
        <>
          <SloPeriodName>{sloPeriod.name}</SloPeriodName>
          <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
            <Space width={12} />
            <IconButton onClick={preventPropagation(() => setEditing(true))}>
              <EditIcon />
            </IconButton>
          </RestrictedToPortfolioMode>
        </>
      )}
    </WrapperForm>
  );
};

export default EditableSloPeriodName;
