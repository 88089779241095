import PropTypes from 'prop-types';
import ManagerWrapper from './ManagerWrapper';
import Assignee from './Assignee';
import AddAssignees from './AddAssignees';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const AssigneesManager = ({ task, challengeUsers, ...props }) => (
  <ManagerWrapper {...props}>
    {safeKeys(task.assignees)
      .sort()
      .map(username => (
        <Assignee username={username} taskKey={task.key} key={username} />
      ))}
    <AddAssignees task={task} challengeUsers={challengeUsers} />
  </ManagerWrapper>
);

AssigneesManager.propTypes = {
  task: PropTypes.shape({
    key: PropTypes.string.isRequired,
    assignees: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  challengeUsers: PropTypes.arrayOf(PropTypes.string)
};

export default AssigneesManager;
