import PropTypes from 'prop-types';
import modeInfo from 'lib/mode';
import ModeLink from './ModeLink';
import { FormattedMessage } from 'react-intl';

const Mode = ({ mode, selected, ...props }) => (
  <ModeLink to={!selected && modeInfo(mode).url} selected={selected} {...props}>
    <FormattedMessage id={`mode_switch.modes.${mode}`} />
  </ModeLink>
);

Mode.propTypes = {
  mode: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  modeSwitchOpen: PropTypes.bool.isRequired
};

export default Mode;
