import { useContext, useState } from 'react';
import SloContext from '../../SloContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import DescriptionEditor from './DescriptionEditor';
import DescriptionWrapper from './DescriptionWrapper';
import InnerDescriptionWrapper from './InnerDescriptionWrapper';
import DescriptionText from './DescriptionText';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import EditButton from './EditButton';
import AddButton from './AddButton';

const SloDescription = props => {
  const slo = useContext(SloContext);
  const sloHasDescription = !!slo.description?.trim();

  const [descriptionEditing, setDescriptionEditing] = useState(false);

  const portfolioMode = useContext(PortfolioModeContext);

  if (descriptionEditing) {
    return (
      <DescriptionWrapper {...props}>
        <DescriptionEditor onClose={() => setDescriptionEditing(false)} />
      </DescriptionWrapper>
    );
  }

  if (portfolioMode !== PortfolioModes.STUDENT && !sloHasDescription) {
    return null;
  }

  return (
    <DescriptionWrapper {...props}>
      {sloHasDescription ? (
        <InnerDescriptionWrapper>
          <DescriptionText>{slo.description}</DescriptionText>
          <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
            <EditButton onClick={() => setDescriptionEditing(true)} />
          </RestrictedToPortfolioMode>
        </InnerDescriptionWrapper>
      ) : (
        <AddButton onClick={() => setDescriptionEditing(true)} />
      )}
    </DescriptionWrapper>
  );
};

export default SloDescription;
