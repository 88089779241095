import copyChallengeTasks from './copyChallengeTasks';
import attachTasksToChallenge from './attachTasksToChallenge';

const copyOriginalChallengeTasksToChallengeCopy = async (
  originalChallenge,
  challengeCopyKey
) => {
  const copiedTasksKeysObject = await copyChallengeTasks(
    originalChallenge,
    challengeCopyKey
  );

  await attachTasksToChallenge(copiedTasksKeysObject, challengeCopyKey);
};

export default copyOriginalChallengeTasksToChallengeCopy;
