import PrimaryIconButton from './PrimaryIconButton';
import { Close as CloseIcon } from '@material-ui/icons';

const CloseButton = props => (
  <PrimaryIconButton edge="start" {...props}>
    <CloseIcon />
  </PrimaryIconButton>
);

export default CloseButton;
