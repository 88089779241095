import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import ChallengesWrapper from './ChallengesWrapper';
import Phases from 'lib/constants/Phases';
import RowPhaseChallengesMapper from './RowPhaseChallengesMapper';
import ChallengeWithAttachmentHandling from './ChallengeWithAttachmentHandling';

const RowChallengesWithAttachmentHandling = ({
  selectedRow,
  onGoBack,
  ...props
}) => {
  if (!selectedRow) {
    return null;
  }

  return (
    <>
      <Dialog.Steps.Step.TitleWithBackButton onBackButtonClick={onGoBack}>
        {selectedRow.name}
      </Dialog.Steps.Step.TitleWithBackButton>
      <ChallengesWrapper {...props}>
        {Object.values(Phases).map(phase => (
          <RowPhaseChallengesMapper row={selectedRow} phase={phase} key={phase}>
            {challengeKey => (
              <ChallengeWithAttachmentHandling
                challengeKey={challengeKey}
                key={challengeKey}
              />
            )}
          </RowPhaseChallengesMapper>
        ))}
      </ChallengesWrapper>
    </>
  );
};

RowChallengesWithAttachmentHandling.propTypes = {
  selectedRow: PropTypes.object,
  onGoBack: PropTypes.func.isRequired
};

export default RowChallengesWithAttachmentHandling;
