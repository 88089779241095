import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Paper } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import isPropValid from '@emotion/is-prop-valid';

const ChallengeViewCard = styled(Paper, {
  shouldForwardProp: isPropValid
})(
  ({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 150,
    width: 'calc(100% - 150px)',
    height: '100vh',
    display: 'flex',
    zIndex: 4,
    borderRadius: 0,
    transition: theme.transitions.create(),
    overflow: 'hidden',
    [mediaQuery(960)]: {
      left: 80,
      width: 'calc(100% - 80px)'
    },
    [mediaQuery(768)]: {
      top: 64,
      left: 0,
      width: '100%',
      height: 'calc(100% - 64px)'
    }
  }),
  ({ theme, taskBoardOpen }) => {
    if (taskBoardOpen) {
      return {
        backgroundColor: theme.palette.background.default,
        display: 'block',
        overflowY: 'auto',
        [mediaQuery(960)]: {
          width: 'calc(100% - 80px)'
        },
        [mediaQuery(768)]: {
          width: '100%'
        }
      };
    }
  },
  addHighContrastStyles(({ theme }) => ({
    borderLeft: `2px solid ${theme.palette.divider}`
  }))
);

ChallengeViewCard.propTypes = {
  taskBoardOpen: PropTypes.bool
};

ChallengeViewCard.defaultProps = {
  taskBoardOpen: false
};

export default ChallengeViewCard;
