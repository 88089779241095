import { useContext } from 'react';
import MoveModeContext from './MoveModeContext';
import { Button } from '@material-ui/core';
import { Close as CloseIcon, OpenWith as MoveIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const MoveModeButton = props => {
  const inMoveMode = useContext(MoveModeContext);
  return (
    <Button
      variant="outlined"
      startIcon={inMoveMode ? <CloseIcon /> : <MoveIcon />}
      style={{
        marginLeft: 'auto'
      }}
      {...props}
    >
      <FormattedMessage
        id={
          inMoveMode
            ? 'editor.model_editor.move_mode_button.leave_variant'
            : 'editor.model_editor.move_mode_button'
        }
      />
    </Button>
  );
};

export default MoveModeButton;
