import { Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const EditButton = props => (
  <Button size="small" color="secondary" startIcon={<EditIcon />} {...props}>
    <FormattedMessage id="slo_system.slo.description.edit_button" />
  </Button>
);

export default EditButton;
