import { createContext, useContext } from 'react';
import ChallengeInfoContext from '../ChallengeInfoContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import { ref } from 'lib/firebase';
import usePortfolio from '../../utils/usePortfolio';
import buildLearningGoalsStructureWithProgress from './buildLearningGoalsStructureWithProgress';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import ButtonLoadingSpinner from './ButtonLoadingSpinner';

const LearningGoalsContext = createContext({
  learningGoalsModel: null,
  learningGoalsStructure: null
});

const AutomatedLearningGoalsContextProvider = ({
  learningGoalsModel,
  ...props
}) => {
  const [portfolioTemplatesLoading, portfolioTemplates] = useFirebaseValue(
    ref('portfolioTemplates')
  );
  const [portfolioLoading, portfolio] = usePortfolio();
  const { challenge } = useContext(ChallengeInfoContext);

  if (portfolioTemplatesLoading || portfolioLoading) {
    return <ButtonLoadingSpinner />;
  }

  const learningGoalsStructure = buildLearningGoalsStructureWithProgress(
    learningGoalsModel,
    portfolioTemplates,
    portfolio,
    challenge
  );

  return (
    <PortfolioTemplatesContext.Provider value={portfolioTemplates}>
      <PortfolioContext.Provider value={portfolio}>
        <LearningGoalsContext.Provider
          value={{
            learningGoalsModel,
            learningGoalsStructure
          }}
          {...props}
        />
      </PortfolioContext.Provider>
    </PortfolioTemplatesContext.Provider>
  );
};

LearningGoalsContext.AutomatedProvider = AutomatedLearningGoalsContextProvider;

export default LearningGoalsContext;
