import PropTypes from 'prop-types';
import { ref } from 'lib/firebase';
import { Button } from '@material-ui/core';
import { ControlPointDuplicate as AttachIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const AttachPart = ({ part, subtopic, highestPartUiPos, ...props }) => {
  if (subtopic.parts?.[part.id]) {
    return null;
  }

  const attachPart = () => {
    ref(`portfolioTemplates/subtopics/${subtopic.id}/parts/${part.id}`).set(
      true
    );

    const partRef = ref(`portfolioTemplates/parts/${part.id}`);

    partRef.child('subtopicId').child(subtopic.id).set(true);

    partRef
      .child('uiPos')
      .child(subtopic.id)
      .set(Math.ceil(highestPartUiPos) + 1);
  };

  return (
    <Button
      variant="outlined"
      onClick={attachPart}
      startIcon={<AttachIcon />}
      {...props}
    >
      <FormattedMessage
        id="editor.model_editor.field_editor.learning_goals_editor.manager.part.attachment_manager.attach_button"
        values={{
          partTitle: part.title,
          subtopicTitle: subtopic.title
        }}
      />
    </Button>
  );
};

AttachPart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  subtopic: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    parts: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  highestPartUiPos: PropTypes.number
};

AttachPart.defaultProps = {
  highestPartUiPos: 0
};

export default AttachPart;
