import { useState } from 'react';
import PropTypes from 'prop-types';
import { MoreVert as MoreIcon } from '@material-ui/icons';
import { Menu } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import ActionMenuButton from './ActionMenuButton';
import ActionMenuDeleteButton from './ActionMenuDeleteButton';

const CollapsibleActionMenu = ({ ButtonComponent, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = !!anchorEl;

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <ButtonComponent edge="end" onClick={e => setAnchorEl(e.currentTarget)}>
        <MoreIcon />
      </ButtonComponent>
      <Menu
        open={menuOpen}
        onClick={closeMenu}
        onClose={closeMenu}
        anchorEl={anchorEl}
        keepMounted
        {...props}
      />
    </>
  );
};

CollapsibleActionMenu.propTypes = {
  ButtonComponent: PropTypes.elementType
};

CollapsibleActionMenu.defaultProps = {
  ButtonComponent: IconButton
};

CollapsibleActionMenu.Button = ActionMenuButton;
CollapsibleActionMenu.DeleteButton = ActionMenuDeleteButton;

export default CollapsibleActionMenu;
