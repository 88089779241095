import useSlideshowImage from './useSlideshowImage';
import { AnimatePresence } from 'framer-motion';
import SlideshowImage from './SlideshowImage';

const BackgroundSlideshow = props => {
  const displayedImage = useSlideshowImage();
  return (
    <AnimatePresence>
      <SlideshowImage src={displayedImage} key={displayedImage} {...props} />
    </AnimatePresence>
  );
};

export default BackgroundSlideshow;
