import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, Drawer, List, Divider } from '@material-ui/core';
import Dialog from 'lib/components/Dialog';
import ChallengeActionsButton from './ChallengeActionsButton';
import EditButton from './ActionButtons/EditButton';
import CopyButton from './ActionButtons/CopyButton';
import LinkSharingButton from './ActionButtons/LinkSharingButton';
import DeleteButton from './ActionButtons/DeleteButton';
import CopyChallenge from '../CopyChallenge';
import ChallengeLinkSharing from '../ChallengeLinkSharing';
import DeleteChallenge from '../DeleteChallenge';
import injectAdditionalProps from './injectAdditionalProps';
import setBodyOverflow from 'lib/styling/setBodyOverflow';

const MobileChallengeActions = ({
  challenge,
  parentKey,
  deleteChallenge,
  onEdit,
  asTask,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const usesMobileChallengeActions = useMediaQuery('(max-width: 640px)');

  const actuallyOpen = open && usesMobileChallengeActions;

  return (
    <>
      <ChallengeActionsButton onClick={() => setOpen(true)} {...props} />
      <Drawer
        anchor="right"
        open={actuallyOpen}
        BackdropComponent={Dialog.Backdrop}
        onClose={() => {
          setOpen(false);
          setBodyOverflow('hidden');
        }}
      >
        <List>
          <EditButton onClick={onEdit} asTask={asTask} />
          {!asTask && parentKey && (
            <CopyChallenge
              challenge={challenge}
              rowKey={parentKey}
              ButtonComponent={CopyButton}
            />
          )}
          <ChallengeLinkSharing
            challengeKey={challenge.key}
            parentKey={parentKey}
            asTask={asTask}
            ButtonComponent={injectAdditionalProps(LinkSharingButton, {
              asTask
            })}
          />
        </List>
        <Divider />
        <List>
          <DeleteChallenge
            challenge={challenge}
            parentKey={parentKey}
            deleteChallenge={deleteChallenge}
            ButtonComponent={injectAdditionalProps(DeleteButton, {
              asTask
            })}
            asTask={asTask}
          />
        </List>
      </Drawer>
    </>
  );
};

MobileChallengeActions.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    sharedWith: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  parentKey: PropTypes.string,
  deleteChallenge: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default MobileChallengeActions;
