import { useContext } from 'react';
import createCache from '@emotion/cache';
import AppStateContext from 'lib/contexts/AppStateContext';
import App from 'lib/App';
import useSyncDisplaySettingsToBody from './useSyncDisplaySettingsToBody';
import { StylesProvider as MuiStylesProvider } from '@material-ui/core/styles';
import { CacheProvider } from '@emotion/react';
import ThemeProvider from './ThemeProvider';
import XssWarningLogger from './XssWarningLogger';
import LanguageProvider from './LanguageProvider';
import LoadingScreen from './LoadingScreen';
import DialogManagerContext from 'lib/contexts/DialogManagerContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import CurrentUserContext from 'lib/contexts/CurrentUserContext';
import ErrorBoundary from './ErrorBoundary';
import { BrowserRouter as Router } from 'react-router-dom';
import IsUsingPlanningViewContext from 'lib/contexts/IsUsingPlanningViewContext';

const disableEmotionWarningsCache = createCache({
  key: 'styles'
});
disableEmotionWarningsCache.compat = true;

const AppSetup = props => {
  const appState = useContext(AppStateContext);
  const [
    currentUserLoading,
    currentUser
  ] = App.authentication.useCurrentUserPopulator();

  useSyncDisplaySettingsToBody();

  return (
    <MuiStylesProvider injectFirst>
      <CacheProvider value={disableEmotionWarningsCache}>
        <ThemeProvider>
          <XssWarningLogger>
            <LanguageProvider>
              <LoadingScreen active={appState.isLoading || currentUserLoading}>
                <DialogManagerContext.AutomatedProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <DndProvider backend={HTML5Backend}>
                      <SelectedPlatformContext.AutomatedProvider>
                        <CurrentUserContext.Provider value={currentUser}>
                          <ErrorBoundary>
                            <Router>
                              <IsUsingPlanningViewContext.AutomatedProvider
                                {...props}
                              />
                            </Router>
                          </ErrorBoundary>
                        </CurrentUserContext.Provider>
                      </SelectedPlatformContext.AutomatedProvider>
                    </DndProvider>
                  </MuiPickersUtilsProvider>
                </DialogManagerContext.AutomatedProvider>
              </LoadingScreen>
            </LanguageProvider>
          </XssWarningLogger>
        </ThemeProvider>
      </CacheProvider>
    </MuiStylesProvider>
  );
};

export default AppSetup;
