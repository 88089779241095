import { useState } from 'react';
import PropTypes from 'prop-types';
import PillButtons from '../../PillButtons';
import { Add as AddIcon } from '@material-ui/icons';
import ProofPrompt from './ProofPrompt';
import Dialog from 'lib/components/Dialog';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { FormattedMessage } from 'react-intl';

const AddProof = ({ proofRef, proof, ...props }) => {
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewProof = async newProof => {
    setAddPromptOpen(false);

    const oldProofIsLegacySingleProofLink = typeof proof === 'string';
    if (oldProofIsLegacySingleProofLink) {
      // This replaces the old string entry with a keyed object entry
      proofRef.push(proof);
    }

    proofRef.push(newProof);
  };

  return (
    <>
      <PillButtons.Button
        onClick={() => setAddPromptOpen(true)}
        standAlone
        {...props}
      >
        <AddIcon />
      </PillButtons.Button>
      <ProofPrompt
        open={addPromptOpen}
        onOk={saveNewProof}
        onCancel={() => setAddPromptOpen(false)}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.level.proof.add.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="portfolio.level.proof.add.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </ProofPrompt>
    </>
  );
};

AddProof.propTypes = {
  proofRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  proof: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.string)
  ])
};

export default AddProof;
