import ModelPropTypes from '../ModelPropTypes';
import PhaseContext from './PhaseContext';
import PhaseWithFirstPhaseNameFormMarginCorrection from './PhaseWithFirstPhaseNameFormMarginCorrection';
import Phase from 'components/ChallengePortfolio/NormalView/Challenge/ChallengeView/ChallengeFields/Phase';
import PhaseHeader from './PhaseHeader';
import PhaseContentGroup from './PhaseContentGroup';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import ModelFieldEditorList from './ModelFieldEditorList';
import AddModelField from './AddModelField';

const ModelPhaseEditor = ({ phase, previousPhase, nextPhase, ...props }) => (
  <PhaseContext.Provider value={{ phase, previousPhase, nextPhase }}>
    <PhaseWithFirstPhaseNameFormMarginCorrection>
      <PhaseHeader />
      <Phase.ContentWrapper>
        <PhaseContentGroup which={PhaseContentGroups.LEFT_CONTENT}>
          <ModelFieldEditorList />
          <AddModelField />
        </PhaseContentGroup>
        <PhaseContentGroup which={PhaseContentGroups.RIGHT_CONTENT}>
          <ModelFieldEditorList />
          <AddModelField />
        </PhaseContentGroup>
      </Phase.ContentWrapper>
      <PhaseContentGroup which={PhaseContentGroups.END_CONTENT}>
        <ModelFieldEditorList />
        <AddModelField />
      </PhaseContentGroup>
    </PhaseWithFirstPhaseNameFormMarginCorrection>
  </PhaseContext.Provider>
);

ModelPhaseEditor.propTypes = {
  phase: ModelPropTypes.phase.isRequired,
  previousPhase: ModelPropTypes.phase,
  nextPhase: ModelPropTypes.phase
};

export default ModelPhaseEditor;
