import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import ManagerWrapper from './ManagerWrapper';
import ExpertiseNameField from './ExpertiseNameField';
import DeleteExpertiseFromUser from './DeleteExpertiseFromUser';

const IndividualExpertiseManager = ({ expertiseKey, ...props }) => {
  const [expertiseLoading, expertise] = useFirebaseValue(
    ref(`expertises/${expertiseKey}`)
  );

  if (!expertiseLoading && !expertise) {
    return null;
  }

  return (
    <ManagerWrapper {...props}>
      <ExpertiseNameField expertiseKey={expertiseKey} expertise={expertise} />
      <DeleteExpertiseFromUser
        expertiseKey={expertiseKey}
        disabled={expertiseLoading}
      />
    </ManagerWrapper>
  );
};

IndividualExpertiseManager.propTypes = {
  expertiseKey: PropTypes.string.isRequired
};

export default IndividualExpertiseManager;
