import styled from '@emotion/styled';
import { Fab } from '@material-ui/core';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const StyledFab = styled(Fab)(
  ({ theme }) => ({
    margin: 0,
    padding: 8,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    display: 'inline-flex',
    ':hover, :focus': {
      backgroundColor: theme.palette.increaseContrastWithBackground(
        theme.palette.background.paper,
        10
      )
    }
  }),
  addBorderInHighContrastMode
);

const AddIconWrapper = props => (
  <StyledFab size="small" component="div" tabIndex={-1} {...props} />
);

export default AddIconWrapper;
