import PropTypes from 'prop-types';
import ShiftArrow from './ShiftArrow';

const PhaseShiftArrows = ({
  canShiftLeft,
  canShiftRight,
  onShiftLeft,
  onShiftRight
}) => (
  <>
    <ShiftArrow onClick={onShiftLeft} hidden={!canShiftLeft} isLeftArrow />
    <ShiftArrow onClick={onShiftRight} hidden={!canShiftRight} isRightArrow />
  </>
);

PhaseShiftArrows.propTypes = {
  canShiftLeft: PropTypes.bool.isRequired,
  canShiftRight: PropTypes.bool.isRequired,
  onShiftLeft: PropTypes.func.isRequired,
  onShiftRight: PropTypes.func.isRequired
};

export default PhaseShiftArrows;
