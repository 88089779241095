import App from 'lib/App';
import useGetPhotoUrl from './useGetPhotoUrl';
import firebase from 'firebase/compat/app';
import Roles from 'lib/constants/Roles';
import { ref } from 'lib/firebase';

const useLogin = () => {
  const isUserAllowed = App.authentication.useIsUserAllowed();
  const getUsernameFromEmail = App.authentication.useGetUsernameFromEmail();
  const getPhotoUrl = useGetPhotoUrl();

  const login = async (provider, onShowEmailNotAllowedError) => {
    try {
      const {
        user,
        additionalUserInfo,
        credential
      } = await firebase.auth().signInWithPopup(provider);

      if (!isUserAllowed(user)) {
        firebase.auth().signOut();
        user.delete();

        onShowEmailNotAllowedError();
        return;
      }

      const username = getUsernameFromEmail(user.email);
      const photoUrl = await getPhotoUrl(user, credential);

      const updates = {
        name: user.displayName || user.email,
        email: user.email,
        photoUrl: photoUrl,
        uid: user.uid
      };

      if (additionalUserInfo.isNewUser) {
        updates.role = Roles.STUDENT;
      }

      return ref(`users/${username}`).update(updates);
    } catch (error) {
      console.warn(error);
    }
  };

  return login;
};

export default useLogin;
