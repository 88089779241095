import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

const MoveButton = styled(IconButton)({
  padding: 6,
  width: 36,
  height: 36
});

export default MoveButton;
