import styled from '@emotion/styled';

const Separator = styled('div')(({ theme }) => ({
  marginBottom: 22.5,
  borderTop: `2px dashed ${theme.palette.divider}`,
  ':first-child': {
    display: 'none'
  }
}));

export default Separator;
