import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import StyledLevel from 'components/RubricsPortfolioComponents/StyledLevel';
import LevelTitle from 'components/RubricsPortfolioComponents/StyledLevel/LevelTitle';
import LevelDescription from 'components/RubricsPortfolioComponents/StyledLevel/LevelDescription';
import PortfolioModes from 'lib/constants/PortfolioModes';
import ProofWrapper from './ProofWrapper';
import LevelProof from './LevelProof';
import LevelProofReadOnly from './LevelProofReadOnly';
import ChallengeAttachmentManager from './ChallengeAttachmentManager';

const Level = ({ level, proofSubpath, challenge, ...props }) => {
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioTemplates = useContext(PortfolioTemplatesContext);

  const inLearningGoals = !!portfolioTemplates.parts[level.partId]
    .managedFromLearningGoals;

  return (
    <StyledLevel {...props}>
      {level.title && <LevelTitle>{level.title}</LevelTitle>}
      <LevelDescription>{level.description}</LevelDescription>
      <ProofWrapper>
        {inLearningGoals && (
          <ChallengeAttachmentManager
            levelKey={level.id}
            challenge={challenge}
          />
        )}
        {!challenge && portfolioMode === PortfolioModes.STUDENT && (
          <LevelProof levelId={level.id} proofSubpath={proofSubpath} />
        )}
        {!challenge && portfolioMode === PortfolioModes.COACH && (
          <LevelProofReadOnly levelId={level.id} proofSubpath={proofSubpath} />
        )}
      </ProofWrapper>
    </StyledLevel>
  );
};

Level.propTypes = {
  level: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  proofSubpath: PropTypes.string,
  challenge: PropTypes.object
};

export default Level;
