import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import isPropValid from '@emotion/is-prop-valid';

const ProgressBar = styled(stripProps('div', 'onTop'), {
  shouldForwardProp: isPropValid
})(
  ({
    theme,
    color,
    progress,
    progressEqual,
    reversed,
    noTransition,
    onTop
  }) => ({
    position: 'absolute',
    top: 0,
    left: reversed ? 'auto' : 0,
    right: reversed ? 0 : 'auto',
    width: `${progress || 0}%`,
    height: '100%',
    backgroundColor: progressEqual ? theme.palette.sliders.both : color,
    zIndex: onTop ? 2 : 1,
    transition: noTransition ? 'none' : theme.transitions.create()
  })
);

ProgressBar.propTypes = {
  color: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  progressEqual: PropTypes.bool.isRequired,
  reversed: PropTypes.bool.isRequired,
  noTransition: PropTypes.bool.isRequired,
  onTop: PropTypes.bool.isRequired
};

export default ProgressBar;
