import { motion } from 'framer-motion';

const OnlyShowOnHover = props => (
  <motion.div
    variants={{
      initial: {
        width: 0,
        opacity: 0
      },
      hover: {
        width: 'auto',
        opacity: 1
      }
    }}
    {...props}
  />
);

export default OnlyShowOnHover;
