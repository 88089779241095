import { useContext } from 'react';
import FieldContext from '../../FieldContext';
import DateField from '../DateField';
import isEarlier from './utils/isEarlier';

const PlannedEndDate = () => {
  const { challenge } = useContext(FieldContext);
  return (
    <DateField
      shouldDisableDate={date =>
        !!challenge.plannedStartDate &&
        isEarlier(date, new Date(challenge.plannedStartDate))
      }
    />
  );
};

export default PlannedEndDate;
