import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ActionsWrapper = styled('section')({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(700)]: {
    position: 'absolute',
    top: 13.5,
    right: 13
  }
});

export default ActionsWrapper;
