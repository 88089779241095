import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledEditField = styled(TextField)(
  ({ theme }) => ({
    marginBottom: 12,
    width: '100%',
    textAlign: 'left',
    ':last-child': {
      marginBottom: 0
    },
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.action.hover
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    '.MuiInputBase-root': {
      backgroundColor: theme.palette.background.default
    }
  }))
);

const EditField = props => (
  <StyledEditField variant="outlined" size="small" {...props} />
);

export default EditField;
