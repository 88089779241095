import { Skeleton } from '@material-ui/lab';
import Space from 'lib/components/Space';

const UserLoadingPlaceholder = () => (
  <>
    <Skeleton variant="circle" width={28} height={28} animation="wave" />
    <Space width={8} />
    <Skeleton variant="text" width="45%" animation="wave" />
  </>
);

export default UserLoadingPlaceholder;
