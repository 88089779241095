import StyledText from './StyledText';
import RestrictedToPortfolioMode from 'lib/components/RestrictedToPortfolioMode';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import { FormattedMessage } from 'react-intl';

const NotSharedText = props => (
  <StyledText {...props}>
    <FormattedMessage id="portfolio.challenge.sharing.not_shared" />
    <RestrictedToPortfolioMode mode={PortfolioModes.STUDENT}>
      <Space height={4} />
    </RestrictedToPortfolioMode>
  </StyledText>
);

export default NotSharedText;
