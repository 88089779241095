import PropTypes from 'prop-types';
import useUnsyncedFirebaseValue from 'lib/firebase/useUnsyncedFirebaseValue';
import { userRef } from 'lib/firebase';
import UserWrapper from './UserWrapper';
import FeedbackItemUserAvatar from './FeedbackItemUserAvatar';
import UserName from './UserName';

const FeedbackItemUser = ({ username, ...props }) => {
  const [loading, user] = useUnsyncedFirebaseValue(userRef(username));

  if (loading || !user) {
    return null;
  }

  return (
    <UserWrapper {...props}>
      <FeedbackItemUserAvatar user={user} />
      <UserName>{user.name}</UserName>
    </UserWrapper>
  );
};

FeedbackItemUser.propTypes = {
  username: PropTypes.string.isRequired
};

export default FeedbackItemUser;
