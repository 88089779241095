import EditorWrapper from './EditorWrapper';
import EditorLabel from './EditorLabel';
import EditorTextField from './EditorTextField';

const KeyValueEditor = {
  Wrapper: EditorWrapper,
  Label: EditorLabel,
  TextField: EditorTextField
};

export default KeyValueEditor;
