import { useState } from 'react';
import PropTypes from 'prop-types';
import TitleField from '../EditorLevel/LevelEditor/TitleField';
import DescriptionField from '../EditorLevel/LevelEditor/DescriptionField';
import LevelEditorActions from '../EditorLevel/LevelEditor/LevelEditorActions';
import { ref } from 'lib/firebase';

const LevelForm = ({
  partId,
  highestLevelUiPos,
  onClose,
  noParentAdd,
  ...props
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showEmptyError, setShowEmptyError] = useState(false);

  const resetAndClose = () => {
    setTitle('');
    setDescription('');
    setShowEmptyError(false);
    onClose();
  };

  const saveNewLevel = () => {
    if (description.trim().length === 0) {
      setShowEmptyError(true);
      return;
    }

    const id = ref('portfolioTemplates/levels').push().key;

    !noParentAdd &&
      ref(`portfolioTemplates/parts/${partId}/levels/${id}`).set(true);

    ref(`portfolioTemplates/levels/${id}`).set({
      partId,
      id,
      title: title.trim(),
      uiPos: Math.ceil(highestLevelUiPos) + 1,
      description: description.trim()
    });

    resetAndClose();
  };

  return (
    <>
      <TitleField value={title} onChange={e => setTitle(e.target.value)} />
      <DescriptionField
        value={description}
        onChange={e => setDescription(e.target.value)}
        showEmptyError={showEmptyError}
        autoFocus
      />
      <LevelEditorActions
        onClose={resetAndClose}
        onSave={saveNewLevel}
        primaryButtons
      />
    </>
  );
};

LevelForm.propTypes = {
  partId: PropTypes.string.isRequired,
  highestLevelUiPos: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  noParentAdd: PropTypes.bool
};

LevelForm.defaultProps = {
  noParentAdd: false
};

export default LevelForm;
