import { useContext } from 'react';
import PropTypes from 'prop-types';
import useMakeChallengeCopyWithoutTasks from './useMakeChallengeCopyWithoutTasks';
import PhaseContext from '../../../../PhaseContext';
import { rowRef } from 'lib/firebase';
import CloseChallengeContext from '../CloseChallengeContext';
import copyOriginalChallengeTasksToChallengeCopy from './copyOriginalChallengeTasksToChallengeCopy';
import CopyButton from './CopyButton';

const CopyChallenge = ({ challenge, rowKey, ButtonComponent, ...props }) => {
  const makeChallengeCopyWithoutTasks = useMakeChallengeCopyWithoutTasks();

  const currentPhase = useContext(PhaseContext);
  const attachChallengeToCurrentRowAndPhase = challengeKey =>
    rowRef(rowKey)
      .child(`challenges/${currentPhase}/${challengeKey}`)
      .set(true);

  const closeChallenge = useContext(CloseChallengeContext);

  const copyChallenge = async () => {
    const challengeCopyKey = makeChallengeCopyWithoutTasks(challenge, rowKey);

    await copyOriginalChallengeTasksToChallengeCopy(
      challenge,
      challengeCopyKey
    );

    await attachChallengeToCurrentRowAndPhase(challengeCopyKey);

    closeChallenge();
  };

  return <ButtonComponent onClick={copyChallenge} {...props} />;
};

CopyChallenge.propTypes = {
  challenge: PropTypes.object.isRequired,
  rowKey: PropTypes.string.isRequired,
  ButtonComponent: PropTypes.elementType
};

CopyChallenge.defaultProps = {
  ButtonComponent: CopyButton
};

export default CopyChallenge;
