import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import NoSloPeriods from './NoSloPeriods';
import SloPeriod from './SloPeriod';

const SloPeriodList = props => {
  const portfolioUser = useContext(PortfolioUserContext);

  if (!portfolioUser.sloPeriods) {
    return <NoSloPeriods />;
  }

  return Object.keys(portfolioUser.sloPeriods)
    .reverse()
    .map(sloPeriodKey => (
      <SloPeriod sloPeriodKey={sloPeriodKey} key={sloPeriodKey} />
    ));
};

export default SloPeriodList;
