import { useState } from 'react';
import PropTypes from 'prop-types';
import LinkButton from './LinkButton';
import LinkSharingDialog from './LinkSharingDialog';
import selectLinkInputContent from './selectLinkInputContent';

const ChallengeLinkSharing = ({
  challengeKey,
  asTask,
  ButtonComponent,
  ...props
}) => {
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  return (
    <>
      <ButtonComponent
        onClick={() => {
          selectLinkInputContent();
          setLinkDialogOpen(true);
        }}
      />
      <LinkSharingDialog
        challengeKey={challengeKey}
        asTask={asTask}
        open={linkDialogOpen}
        onClose={() => setLinkDialogOpen(false)}
      />
    </>
  );
};

ChallengeLinkSharing.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired,
  ButtonComponent: PropTypes.elementType
};

ChallengeLinkSharing.defaultProps = {
  ButtonComponent: LinkButton
};

export default ChallengeLinkSharing;
