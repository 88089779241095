import { TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const DescriptionTextField = props => (
  <TextField
    variant="filled"
    size="small"
    label={<FormattedMessage id="slo_system.slo.description.input_label" />}
    multiline
    rows={4}
    rowsMax={6}
    autoFocus
    fullWidth
    style={{
      marginBottom: 12
    }}
    {...props}
  />
);

export default DescriptionTextField;
