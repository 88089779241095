import Errors from './Errors';
import RowAttachmentTypes from './RowAttachmentTypes';

const getErrorsFromState = (
  challengeName,
  selectedRowAttachmentType,
  selectedExistingRow,
  newRowName
) => {
  const errors = [];

  if (challengeName.trim().length === 0) {
    errors.push(Errors.CHALLENGE_NAME_EMPTY);
  }

  if (
    selectedRowAttachmentType === RowAttachmentTypes.EXISTING_ROW &&
    !selectedExistingRow
  ) {
    errors.push(Errors.NO_ROW_SELECTED);
  }

  if (
    selectedRowAttachmentType === RowAttachmentTypes.NEW_ROW &&
    newRowName.trim().length === 0
  ) {
    errors.push(Errors.ROW_NAME_EMPTY);
  }

  return errors;
};

export default getErrorsFromState;
