import styled from '@emotion/styled';
import AddItemButton from '../AddItemButton';

const AddPartButton = styled(AddItemButton)({
  marginTop: 12,
  ':first-child': {
    marginTop: 0
  }
});

export default AddPartButton;
