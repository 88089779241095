import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import StyledActionButton from './StyledActionButton';

const smallIconStyles = {
  width: 16,
  height: 16
};

const RowActionButton = ({ small, children, onClick, ...props }) => {
  const handleClick = e => {
    if (typeof onClick === 'function') {
      // We don't want row action buttons to also trigger the row collapsing on
      // row header clicks
      e.stopPropagation();
      onClick(e);
    }
  };

  return (
    <StyledActionButton small={small} onClick={handleClick} {...props}>
      {Children.map(children, child =>
        cloneElement(child, {
          style: small
            ? { ...child.props.style, ...smallIconStyles }
            : child.props.style
        })
      )}
    </StyledActionButton>
  );
};

RowActionButton.propTypes = {
  small: PropTypes.bool,
  right: PropTypes.bool,
  correctRightMargin: PropTypes.bool,
  dangerous: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node
};

RowActionButton.defaultProps = {
  small: false,
  right: false,
  correctRightMargin: false,
  dangerous: false
};

export default RowActionButton;
