import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';
import SloPeriodLoadingPlaceholder from './SloPeriodLoadingPlaceholder';
import SloPeriodContext from './SloPeriodContext';
import SloPeriodStepsWrapper from './SloPeriodStepsWrapper';
import SloPeriodWrapper from './SloPeriodWrapper';
import SloPeriodHeader from './SloPeriodHeader';
import SlosOverview from './SlosOverview';
import SloPeriodActions from './SloPeriodActions';
import SloPeriodFeedback from './SloPeriodFeedback';

const SloPeriod = ({ sloPeriodKey, ...props }) => {
  const [slosCollapsed, setSlosCollapsed] = useState(true);
  const [sloPeriodLoading, sloPeriod] = useFirebaseValue(
    ref(`sloPeriods/${sloPeriodKey}`)
  );

  const [feedbackOpen, setFeedbackOpen] = useState(false);

  useEffect(() => {
    if (slosCollapsed) {
      setFeedbackOpen(false);
    }
  }, [slosCollapsed]);

  if (sloPeriodLoading) {
    return <SloPeriodLoadingPlaceholder />;
  }

  if (!sloPeriod) {
    return null;
  }

  sloPeriod.key = sloPeriodKey;

  return (
    <SloPeriodContext.Provider value={sloPeriod}>
      <SloPeriodWrapper expanded={!slosCollapsed}>
        <SloPeriodHeader
          slosCollapsed={slosCollapsed}
          setSlosCollapsed={setSlosCollapsed}
        />
        <SloPeriodStepsWrapper hasMovedToSecondStep={feedbackOpen}>
          <SloPeriodWrapper.Content isActiveStep={!feedbackOpen}>
            {!slosCollapsed && <SlosOverview />}
            <SloPeriodActions onOpenFeedback={() => setFeedbackOpen(true)} />
          </SloPeriodWrapper.Content>
          <SloPeriodWrapper.Content isActiveStep={feedbackOpen}>
            <SloPeriodFeedback onClose={() => setFeedbackOpen(false)} />
          </SloPeriodWrapper.Content>
        </SloPeriodStepsWrapper>
      </SloPeriodWrapper>
    </SloPeriodContext.Provider>
  );
};

SloPeriod.propTypes = {
  sloPeriodKey: PropTypes.string.isRequired
};

export default SloPeriod;
