import App from 'lib/App';
import { useMediaQuery } from '@material-ui/core';
import Modes from 'lib/constants/Modes';
import Roles from 'lib/constants/Roles';
import StyledButton from './StyledButton';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const CoachModeButton = props => {
  const currentUser = App.authentication.useCurrentUser();
  const selectedMode = App.navigation.useSelectedMode();
  const useOutlinedButton = useMediaQuery('(max-width: 700px)');

  if (selectedMode !== Modes.PORTFOLIO || currentUser.role === Roles.STUDENT) {
    return null;
  }

  const replacesModeSwitch = currentUser.role === Roles.COACH;

  return (
    <StyledButton
      variant={useOutlinedButton ? 'outlined' : 'text'}
      color="primary"
      component={Link}
      to="/coach"
      noResponsivityHiding={replacesModeSwitch}
    >
      <FormattedMessage id="coach_mode_button" />
    </StyledButton>
  );
};

export default CoachModeButton;
