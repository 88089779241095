import { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import StyledGapminderButton from './StyledGapminderButton';
import GapminderButtonLabel from './GapminderButtonLabel';
import { History as HistoryIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const GapminderButton = ({ disabled, ...props }) => {
  // We manage the tooltip state manually to prevent it from giving errors when
  // the gapminder button is disabled
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Tooltip
      title={
        <FormattedMessage id="portfolio.color_legend.gapminder_button_tooltip" />
      }
      placement="bottom-end"
      open={tooltipOpen}
      onOpen={() => setTooltipOpen(true)}
      onClose={() => setTooltipOpen(false)}
    >
      <StyledGapminderButton
        variant="extended"
        color="primary"
        disabled={disabled}
        {...props}
      >
        <GapminderButtonLabel>
          <FormattedMessage id="portfolio.color_legend.gapminder_button" />
        </GapminderButtonLabel>
        <HistoryIcon />
      </StyledGapminderButton>
    </Tooltip>
  );
};

GapminderButton.propTypes = {
  disabled: PropTypes.bool.isRequired
};

export default GapminderButton;
