import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const IndicatorWrapper = styled('figure')(({ side }) => ({
  marginTop: 0,
  marginBottom: 0,
  marginLeft: side === 'left' ? -12 : 'auto',
  marginRight: side === 'right' ? -6 : 0,
  opacity: 0.8,
  // This somehow fixes focus outline being off-key
  display: 'inline-flex'
}));

IndicatorWrapper.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired
};

export default IndicatorWrapper;
