import DialogButton from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogCancelButton = props => (
  <DialogButton variant="outlined" {...props}>
    <FormattedMessage id="dialogs.actions.cancel" />
  </DialogButton>
);

export default DialogCancelButton;
