import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import { Button } from '@material-ui/core';
import DangerColorThemeProvider from 'lib/components/DangerColorThemeProvider';

const DangerousButton = forwardRef(({ inDialog, ...props }, ref) => {
  const ButtonComponent = inDialog ? Dialog.Actions.Button : Button;
  return (
    <DangerColorThemeProvider>
      <ButtonComponent color="primary" ref={ref} {...props} />
    </DangerColorThemeProvider>
  );
});

DangerousButton.propTypes = {
  inDialog: PropTypes.bool
};

DangerousButton.defaultProps = {
  inDialog: false
};

export default DangerousButton;
