import PropTypes from 'prop-types';
import App from 'lib/App';
import currentUserIsViewingChallengeAsCoach from './currentUserIsViewingChallengeAsCoach';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';

const ChallengeUserStateSetup = ({ challenge, ...props }) => {
  const currentUser = App.authentication.useCurrentUser();
  const currentUserUsername = App.authentication.useCurrentUserUsername();
  const pretendCoachMode = currentUserIsViewingChallengeAsCoach(
    currentUserUsername,
    challenge
  );
  return (
    <PortfolioUserContext.Provider value={currentUser}>
      <PortfolioModeContext.Provider
        value={pretendCoachMode ? PortfolioModes.COACH : PortfolioModes.STUDENT}
      >
        <PortfolioIsReadOnlyContext.Provider
          value={pretendCoachMode}
          {...props}
        />
      </PortfolioModeContext.Provider>
    </PortfolioUserContext.Provider>
  );
};

ChallengeUserStateSetup.propTypes = {
  challenge: PropTypes.object.isRequired
};

export default ChallengeUserStateSetup;
