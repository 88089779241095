import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import Space from 'lib/components/Space';
import UserInfoWrapper from './UserInfoWrapper';
import LogEntryDate from './LogEntryDate';

const UserLoadingPlaceholder = ({ logEntry }) => (
  <>
    <Skeleton variant="circle" width={24} height={24} animation="wave" />
    <Space width={12} />
    <UserInfoWrapper>
      <Skeleton variant="text" width={160} animation="wave" />
      <LogEntryDate timestamp={logEntry.timestamp} />
    </UserInfoWrapper>
  </>
);

UserLoadingPlaceholder.propTypes = {
  logEntry: PropTypes.shape({
    timestamp: PropTypes.number.isRequired
  })
};

export default UserLoadingPlaceholder;
