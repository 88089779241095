import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const DialogAddButton = props => (
  <Dialog.Actions.Button color="primary" {...props}>
    <FormattedMessage id="portfolio.challenge.field.text_editor.link_prompt.add_button" />
  </Dialog.Actions.Button>
);

export default DialogAddButton;
