import { FormControl, InputLabel, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const PhaseSelectField = props => (
  <FormControl style={{ flex: 1 }}>
    <InputLabel htmlFor="phase-select">
      <FormattedMessage id="portfolio.challenge.phase_select.label" />
    </InputLabel>
    <Select id="phase-select" {...props} />
  </FormControl>
);

export default PhaseSelectField;
