import { NOW } from '../../../PortfolioWithHistoryProvider';
import { FormattedMessage } from 'react-intl';

const months = [
  <FormattedMessage id="months.january" />,
  <FormattedMessage id="months.february" />,
  <FormattedMessage id="months.march" />,
  <FormattedMessage id="months.april" />,
  <FormattedMessage id="months.may" />,
  <FormattedMessage id="months.june" />,
  <FormattedMessage id="months.july" />,
  <FormattedMessage id="months.august" />,
  <FormattedMessage id="months.september" />,
  <FormattedMessage id="months.october" />,
  <FormattedMessage id="months.november" />,
  <FormattedMessage id="months.december" />
];

const getDateText = (timestamp, selectedWeek) => {
  if (timestamp === NOW) {
    return <FormattedMessage id="times.now" />;
  }

  if (!timestamp) {
    return <FormattedMessage id="times.week" values={{ week: selectedWeek }} />;
  }

  const timestampDate = new Date(Number(timestamp));
  const month = months[timestampDate.getMonth()];

  return (
    <FormattedMessage
      id="portfolio.gapminder.date"
      values={{ date: timestampDate.getDate(), month }}
    />
  );
};

export default getDateText;
