import { Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import Space from 'lib/components/Space';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreateChallengeButton = props => (
  <Fab variant="extended" color="primary" {...props}>
    <AddIcon />
    <Space width={8} />
    <FormattedMessageWithChallengeNaming id="portfolio.challenge_creation_flow.create_button" />
  </Fab>
);

export default CreateChallengeButton;
