import styled from '@emotion/styled';
import FormattedText from 'lib/components/FormattedText';

const DescriptionText = styled(FormattedText)(({ theme }) => ({
  marginBottom: 6,
  color: theme.palette.text.secondary,
  fontSize: 15
}));

export default DescriptionText;
