import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ToggleWrapper = styled('section')({
  marginRight: 36,
  display: 'inline-flex',
  alignItems: 'center',
  [mediaQuery(640)]: {
    marginRight: 0,
    marginBottom: 12,
    flexDirection: 'column',
    alignItems: 'flex-start',
    ':last-child': {
      marginBottom: 0
    }
  }
});

export default ToggleWrapper;
