const getHighestRowUiPos = rowKeys => {
  const reversedRowKeys = [...rowKeys].reverse();
  const lastRow = reversedRowKeys[reversedRowKeys.length - 1];

  if (!lastRow) {
    return 0;
  }

  const lastRowUiPos = lastRow[1];
  return lastRowUiPos;
};

export default getHighestRowUiPos;
