import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import common from '@material-ui/core/colors/common';
import grey from '@material-ui/core/colors/grey';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import { Close as CloseIcon } from '@material-ui/icons';

const StyledDetachButton = styled(IconButton)(
  ({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    // Mimic Material-UI badge positioning
    // Also: scale effect is overridden by hover effect on ChallengeWrapper
    transform: 'translate(10%, -30%) scale(0)',
    color: theme.isDark ? common.white : common.black,
    backgroundColor: theme.isDark ? grey[600] : grey[300],
    transition: theme.transitions.create(),
    ':hover': {
      backgroundColor: theme.isDark ? grey[700] : grey[400]
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${theme.palette.divider}`
  }))
);

const DetachChallengeButton = props => (
  <StyledDetachButton
    className="detach-challenge-button"
    size="small"
    {...props}
  >
    <CloseIcon fontSize="inherit" />
  </StyledDetachButton>
);

export default DetachChallengeButton;
