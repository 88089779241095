import { useState } from 'react';
import UserEditorHeader from './UserEditorHeader';
import UserSearch from './UserSearch';
import UserList from './UserList';
import { FormattedMessage } from 'react-intl';

const UserManager = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  return (
    <section>
      <UserEditorHeader>
        <h2>
          <FormattedMessage id="user_manager.title" />
        </h2>
        <UserSearch onSearch={query => setSearchQuery(query)} />
      </UserEditorHeader>
      <UserList searchQuery={searchQuery} />
    </section>
  );
};

export default UserManager;
