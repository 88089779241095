import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import DangerousButton from 'lib/components/DangerousButton';
import { FormattedMessage } from 'react-intl';

const SettingsDialogActions = ({
  onClose,
  onSave,
  settingsHaveChanged,
  ...props
}) => (
  <Dialog.Actions {...props}>
    {settingsHaveChanged ? (
      <>
        <Dialog.Actions.CancelButton onClick={onClose} />
        <DangerousButton onClick={onSave} inDialog>
          <FormattedMessage id="dialogs.actions.save" />
        </DangerousButton>
      </>
    ) : (
      <Dialog.Actions.DoneButton onClick={onClose} />
    )}
  </Dialog.Actions>
);

SettingsDialogActions.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  settingsHaveChanged: PropTypes.bool.isRequired
};

export default SettingsDialogActions;
