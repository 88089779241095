import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const UserSwitcherPositioningCorrection = styled('div')({
  marginLeft: 12,
  height: 48,
  [mediaQuery(700)]: {
    marginBottom: 12,
    marginLeft: 0,
    width: '100%'
  }
});

export default UserSwitcherPositioningCorrection;
