/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { value, ref } from 'lib/firebase';
import StyledFeedbackItem from './StyledFeedbackItem';
import FeedbackItemUser from './FeedbackItemUser';
import FormattedText from 'lib/components/FormattedText';
import FeedbackItemFooter from './FeedbackItemFooter';

const FeedbackItem = ({
  feedbackKey,
  feedbackItem: feedbackItemFallback,
  ...props
}) => {
  const [loading, setLoading] = useState(true);
  const [feedbackItem, setFeedbackItem] = useState(
    typeof feedbackItemFallback === 'object' ? feedbackItemFallback : null
  );

  useEffect(() => {
    if (typeof feedbackItemFallback === 'object') {
      setLoading(false);

      return;
    }

    value(ref(`coachComments/${feedbackKey}`)).then(feedbackItem => {
      setFeedbackItem(feedbackItem);
      setLoading(false);
    });
  }, [feedbackKey]);

  if (loading || feedbackItem === null) {
    return null;
  }

  feedbackItem.key = feedbackKey;

  return (
    <StyledFeedbackItem {...props}>
      {feedbackItem.user && <FeedbackItemUser username={feedbackItem.user} />}
      <FormattedText>{feedbackItem.content}</FormattedText>
      <FeedbackItemFooter
        feedbackItem={feedbackItem}
        setFeedbackItem={setFeedbackItem}
        useInlineRef={typeof feedbackItemFallback === 'object'}
      />
    </StyledFeedbackItem>
  );
};

FeedbackItem.propTypes = {
  feedbackKey: PropTypes.string.isRequired,
  feedbackItem: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      content: PropTypes.string.isRequired
    })
  ])
};

export default FeedbackItem;
