import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TextField } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const ResponsiveTextField = styled(TextField)(({ error }) => ({
  marginBottom: error ? -22 : null,
  [mediaQuery(420)]: {
    width: '100%'
  }
}));

ResponsiveTextField.propTypes = {
  error: PropTypes.bool.isRequired
};

export default ResponsiveTextField;
