import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import arrToObj from 'lib/utils/arrToObj';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import CustomFieldName from './CustomFieldName';
import InfoDialog from './InfoDialog';
import WorldSelectField from './WorldSelectField';
import worlds from './worlds';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const WorldSelect = () => {
  const { field, fieldRef, challenge } = useContext(FieldContext);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const changeSelectedWorlds = e => {
    const newSelectedWorlds = arrToObj(e.target.value, true);
    fieldRef.set(newSelectedWorlds);
  };

  const selectedWorlds = safeKeys(challenge[field.key]);

  return (
    <section style={{ maxWidth: '100%' }}>
      {field.name && (
        <CustomFieldName onInfoButtonClick={() => setInfoDialogOpen(true)} />
      )}
      <WorldSelectField
        placeholder={
          <FormattedMessage id="portfolio.challenge.world_select.placeholder" />
        }
        value={selectedWorlds}
        onChange={changeSelectedWorlds}
      >
        {worlds.map(world => (
          <MenuItem value={world} key={world}>
            <FormattedMessage
              id={`portfolio.challenge.world_select.world_labels.${world}_world`}
            />
          </MenuItem>
        ))}
      </WorldSelectField>
      <InfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
      />
    </section>
  );
};

export default WorldSelect;
