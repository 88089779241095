import PropTypes from 'prop-types';
import stackDuplicateExpertProgress from './stackDuplicateExpertProgress';
import IndicatorsWrapper from './IndicatorsWrapper';
import ExpertProgressIndicator from './ExpertProgressIndicator';
import ExpertProgressStack from './ExpertProgressStack';

const ExpertProgressIndicators = ({ expertProgress, ...props }) => {
  if (!expertProgress) {
    return null;
  }

  const {
    normalExpertProgress,
    expertProgressStacks
  } = stackDuplicateExpertProgress(expertProgress);

  return (
    <IndicatorsWrapper {...props}>
      {normalExpertProgress.map((expertProgressItem, index) => (
        <ExpertProgressIndicator
          progress={expertProgressItem.progress}
          expertiseKey={expertProgressItem.expertiseKey}
          key={expertProgressItem.expertiseKey || index}
          {...props}
        />
      ))}
      {expertProgressStacks.map((expertProgressStack, index) => (
        <ExpertProgressStack
          expertProgressStack={expertProgressStack}
          key={expertProgressStack.key || index}
        />
      ))}
    </IndicatorsWrapper>
  );
};

ExpertProgressIndicators.propTypes = {
  expertProgress: PropTypes.objectOf(
    PropTypes.shape({
      progress: PropTypes.number.isRequired,
      expertiseKey: PropTypes.string
    })
  ),
  sliding: PropTypes.bool.isRequired
};

export default ExpertProgressIndicators;
