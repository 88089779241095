import { useContext } from 'react';
import VisibleWeekRangeContext from '../VisibleWeekRangeContext';
import StyledHeader from './StyledHeader';
import VisibleWeekRangeControls from './VisibleWeekRangeControls';
import AnimatedWeekRangeWrapper from './AnimatedWeekRangeWrapper';
import CancelWeekZoomButton from './CancelWeekZoomButton';
import WeekMapper from './WeekMapper';
import WeekMarker from './WeekMarker';

const PlanningHeader = () => {
  const {
    visibleWeekRangeStartDate,
    visibleWeeksCount,
    isZoomedInOnWeek
  } = useContext(VisibleWeekRangeContext);

  return (
    <StyledHeader>
      <VisibleWeekRangeControls />
      {isZoomedInOnWeek && <CancelWeekZoomButton />}
      <AnimatedWeekRangeWrapper key={visibleWeekRangeStartDate}>
        <WeekMapper weekCount={visibleWeeksCount}>
          {numberOfWeeksFromRangeStartDate => (
            <WeekMarker
              weekStartDate={visibleWeekRangeStartDate
                .clone()
                .add(numberOfWeeksFromRangeStartDate, 'weeks')}
              key={numberOfWeeksFromRangeStartDate}
            />
          )}
        </WeekMapper>
      </AnimatedWeekRangeWrapper>
    </StyledHeader>
  );
};

export default PlanningHeader;
