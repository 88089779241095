import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import { useMediaQuery } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const StyledButton = styled(Button)({
  [mediaQuery(640)]: {
    marginTop: 12
  }
});

const CreateSloPeriodButton = props => {
  const isMobile = useMediaQuery('(max-width: 640px)');
  return (
    <StyledButton
      variant="contained"
      color="primary"
      size={isMobile ? 'small' : 'medium'}
      startIcon={<AddIcon />}
      fullWidth={isMobile}
      disableElevation
      {...props}
    >
      <FormattedMessage id="slo_system.slo_period.create.button" />
    </StyledButton>
  );
};

export default CreateSloPeriodButton;
