import PropTypes from 'prop-types';
import App from 'lib/App';
import StyledProgressBar from './StyledProgressBar';

const ProgressBar = ({ progress, otherProgress, ...props }) => {
  const useReversedSliders = App.instanceConfig.useContentSetting(
    'useReversedSliders'
  );
  const progressEqual = progress === otherProgress;

  return (
    <StyledProgressBar
      progress={progress}
      progressEqual={progressEqual}
      reversed={useReversedSliders}
      onTop={progress <= otherProgress}
      {...props}
    />
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  otherProgress: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  noTransition: PropTypes.bool.isRequired
};

export default ProgressBar;
