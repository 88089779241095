import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Link as AttachmentIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import PartAttachmentManager, {
  useCanDisplayPartAttachmentManager
} from './PartAttachmentManager';
import { FormattedMessage } from 'react-intl';
import fullWidthAlertProps from './fullWidthAlertProps';

const ManageAttachments = ({ part, ...props }) => {
  const [managerOpen, setManagerOpen] = useState(false);
  const canDisplayPartAttachmentManager = useCanDisplayPartAttachmentManager();

  const additionalAlertProps = canDisplayPartAttachmentManager
    ? fullWidthAlertProps
    : null;

  const closeManager = () => setManagerOpen(false);

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setManagerOpen(true)}
        {...props}
      >
        <AttachmentIcon />
      </TileGrid.Tile.ActionButton>
      <Dialog
        open={managerOpen}
        onClose={closeManager}
        onEnterClose={closeManager}
        {...additionalAlertProps}
      >
        <Dialog.Title>
          <FormattedMessage
            id="editor.model_editor.field_editor.learning_goals_editor.manager.part.attachment_manager.title"
            values={{
              partTitle: part.title
            }}
          />
        </Dialog.Title>
        <PartAttachmentManager part={part} />
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={closeManager} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

ManageAttachments.propTypes = {
  part: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired
};

export default ManageAttachments;
