import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { IconButton } from '@material-ui/core';

const PossiblyDangerousIconButton = forwardRef(
  ({ dangerous, ...props }, ref) => {
    const IconButtonComponent = dangerous ? DangerousIconButton : IconButton;
    return <IconButtonComponent {...props} ref={ref} />;
  }
);

PossiblyDangerousIconButton.propTypes = {
  dangerous: PropTypes.bool
};

PossiblyDangerousIconButton.defaultProps = {
  dangerous: false
};

export default PossiblyDangerousIconButton;
