import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const LinkTextField = ({ link, ...props }) => (
  <TextField
    id="shareable-link"
    variant="filled"
    label={
      <FormattedMessage id="portfolio.challenge.link_sharing.input_label" />
    }
    InputProps={{
      readOnly: true
    }}
    value={link}
    onChange={() => {}}
    fullWidth
    autoFocus
    readOnly
    {...props}
  />
);

LinkTextField.propTypes = {
  link: PropTypes.string.isRequired
};

export default LinkTextField;
