import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { uiPosPropType } from '../useMakeDraggable/uiPosUtils';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import LevelDialog from 'components/RubricsPortfolioComponents/LevelDialog';
import EditorLevel from '../EditorLevel';
import AddLevel from '../AddLevel';
import StyledPart from 'components/RubricsPortfolioComponents/StyledPart';
import PartTitle from 'components/RubricsPortfolioComponents/StyledPart/PartTitle';
import sortByUiPos from 'lib/utils/sortByUiPos';
import getHighestUiPosFromSortedItems from 'pages/EditorPage/utils/getHighestUiPosFromSortedItems';
import isEditorOnly from '../isEditorOnly';
import EditPart from './EditPart';
import CopyPart from './CopyPart';
import DeletePart from './DeletePart';
import ManagedFromLearningGoals from './ManagedFromLearningGoals';
import RemoveLearningGoalsAttachment from './RemoveLearningGoalsAttachment';
import useMakeDraggable from '../useMakeDraggable';
import updatePartSubtopicAttachmentAfterDrag from './updatePartSubtopicAttachmentAfterDrag';
import PortfolioDragTypes from '../PortfolioDragTypes';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const EditorPart = ({
  part,
  previousPart,
  nextPart,
  highestUiPos,
  subtopicKey,
  subtopicUiPos,
  noGroups,
  noCopy,
  onDelete,
  ...props
}) => {
  const [isDragging, dragRef] = useMakeDraggable(
    PortfolioDragTypes.PART,
    part,
    previousPart,
    nextPart,
    highestUiPos,
    'parts',
    subtopicKey,
    subtopicUiPos,
    (oldSubtopicKey, newSubtopicKey) => {
      updatePartSubtopicAttachmentAfterDrag(
        part,
        oldSubtopicKey,
        newSubtopicKey
      );
    }
  );

  const [levelDialogOpen, setLevelDialogOpen] = useState(false);

  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const allLevels = portfolioTemplates?.levels;
  const desiredLevelsKeys = safeKeys(part.levels);
  const levels = desiredLevelsKeys
    .map(levelKey => allLevels?.[levelKey])
    .filter(level => !!level)
    .sort(sortByUiPos);

  const highestLevelUiPos = getHighestUiPosFromSortedItems(levels);

  const groups = safeKeys(part.groups);
  const editorOnly = isEditorOnly(groups);

  const managedFromLearningGoals = part.managedFromLearningGoals;

  return (
    <>
      <StyledPart editor dragging={isDragging} {...props} ref={dragRef}>
        <PartTitle
          editorOnly={editorOnly}
          onClick={() => setLevelDialogOpen(true)}
        >
          {part.title}
        </PartTitle>
        {!managedFromLearningGoals && (
          <>
            <EditPart part={part} noGroups={noGroups} />
            {!noCopy && (
              <CopyPart
                part={part}
                levels={levels}
                highestUiPos={highestUiPos}
              />
            )}
            <DeletePart part={part} onDelete={onDelete} />
          </>
        )}
        {managedFromLearningGoals && (
          <>
            <EditPart part={part} />
            <ManagedFromLearningGoals />
            <RemoveLearningGoalsAttachment
              part={part}
              subtopicKey={subtopicKey}
            />
          </>
        )}
      </StyledPart>
      <LevelDialog
        open={levelDialogOpen}
        onClose={() => setLevelDialogOpen(false)}
        editor
      >
        <LevelDialog.Title onClose={() => setLevelDialogOpen(false)}>
          {part.title}
        </LevelDialog.Title>
        <LevelDialog.Content>
          <LevelDialog.InnerContentWrapper>
            <LevelDialog.LevelWrapper>
              {levels.map((level, i) => (
                <EditorLevel
                  level={level}
                  previousLevel={levels[i - 1] || {}}
                  nextLevel={levels[i + 1] || {}}
                  highestUiPos={highestLevelUiPos}
                  editor
                  key={level.id}
                />
              ))}
              <AddLevel
                partId={part.id}
                highestLevelUiPos={highestLevelUiPos}
              />
            </LevelDialog.LevelWrapper>
          </LevelDialog.InnerContentWrapper>
        </LevelDialog.Content>
      </LevelDialog>
    </>
  );
};

EditorPart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    groups: PropTypes.objectOf(PropTypes.bool),
    dynamicGroups: PropTypes.objectOf(PropTypes.bool),
    uiPos: uiPosPropType.isRequired,
    subtopicId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf(PropTypes.bool)
    ]).isRequired,
    managedFromLearningGoals: PropTypes.bool,
    levels: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  previousPart: PropTypes.shape({
    uiPos: uiPosPropType
  }).isRequired,
  nextPart: PropTypes.shape({
    uiPos: uiPosPropType
  }).isRequired,
  highestUiPos: PropTypes.number.isRequired,
  subtopicKey: PropTypes.string.isRequired,
  subtopicUiPos: PropTypes.number.isRequired,
  noGroups: PropTypes.bool,
  noCopy: PropTypes.bool,
  onDelete: PropTypes.func
};

EditorPart.defaultProps = {
  noGroups: false
};

export default EditorPart;
