import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { RemoveRedEye as FocusIcon } from '@material-ui/icons';
import isPropValid from '@emotion/is-prop-valid';

const StyledFocusIcon = styled(FocusIcon, {
  shouldForwardProp: isPropValid
})(({ theme, hasFocus }) => ({
  width: 20,
  height: 20,
  color: hasFocus
    ? theme.palette.partFocusTextColor
    : theme.palette.text.disabled
}));

StyledFocusIcon.propTypes = {
  hasFocus: PropTypes.bool.isRequired
};

export default StyledFocusIcon;
