import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FooterLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.secondary,
  ':hover': {
    textDecoration: 'underline'
  },
  [mediaQuery(650)]: {
    marginBottom: 6,
    display: 'block',
    ':last-child': {
      marginBottom: 0
    }
  }
}));

export default FooterLink;
