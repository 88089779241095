import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledUserResult = styled(ButtonBase)(
  ({ theme, keyboardSelected }) => ({
    padding: '10px 15px',
    width: '100%',
    backgroundColor: keyboardSelected ? theme.palette.action.selected : null,
    transition: theme.transitions.create(),
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: theme.palette.action.hover
    }
  }),
  addHighContrastStyles(({ keyboardSelected }) => ({
    h4: {
      textDecoration: keyboardSelected ? 'underline' : null
    },
    ':hover h4': {
      textDecoration: 'underline'
    }
  }))
);

StyledUserResult.propTypes = {
  keyboardSelected: PropTypes.bool.isRequired
};

export default StyledUserResult;
