import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';
import ExpertModeSettingsContext from 'lib/contexts/ExpertModeSettingsContext';
import getInitialProgress from './getInitialProgress';
import PortfolioModes from 'lib/constants/PortfolioModes';
import App from 'lib/App';
import useProgress from './useProgress';
import { ref } from 'lib/firebase';
import RubricsSlider from './RubricsSlider';

const Slider = ({ partId, partSubpath, levelCount, ...props }) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const portfolio = useContext(PortfolioContext);
  const portfolioMode = useContext(PortfolioModeContext);
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const { activeExpertiseKey } = useContext(ExpertModeSettingsContext);

  const initialProgress = getInitialProgress(portfolio, partId, partSubpath);

  const isCoach = portfolioMode === PortfolioModes.COACH;
  const isExpertMode = App.navigation.useIsExpertMode();

  const [progress, updateProgress] = useProgress(
    initialProgress,
    isCoach,
    isExpertMode
  );

  const handleSlide = nextProgress => {
    if (!portfolioIsReadOnly) {
      updateProgress(nextProgress);
    }
  };

  const updateProgressInFirebase = progress => {
    const progressPath = `${portfolioUserUsername}/progress/${partId}${
      partSubpath ? `/${partSubpath}` : ''
    }`;
    const progressRef = ref(`portfolios/${progressPath}`);

    if (!isCoach) {
      progressRef.child('student').set(progress);
      return;
    }

    if (isCoach && !isExpertMode) {
      progressRef.child('coach').set(progress);
      return;
    }

    if (isCoach && isExpertMode) {
      progressRef.child(`experts/${activeExpertiseKey}`).set({
        progress,
        expertiseKey: activeExpertiseKey
      });
    }
  };

  const handleDragStop = progress => {
    if (!portfolioIsReadOnly) {
      handleSlide(progress);
      updateProgressInFirebase(progress);
    }
  };

  return (
    <RubricsSlider
      studentProgress={progress.student || 0}
      coachProgress={progress.coach || 0}
      expertProgress={progress.experts}
      levelCount={levelCount}
      onSlide={handleSlide}
      onDragStop={handleDragStop}
      readOnly={portfolioIsReadOnly}
      {...props}
    />
  );
};

Slider.propTypes = {
  partId: PropTypes.string.isRequired,
  partSubpath: PropTypes.string,
  levelCount: PropTypes.number
};

Slider.defaultProps = {
  partSubpath: '',
  levelCount: 0
};

export default Slider;
