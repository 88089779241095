import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import PossiblyDangerousIconButton from 'lib/components/PossiblyDangerousIconButton';

const FeedbackItemActionButton = styled(
  stripProps(PossiblyDangerousIconButton, 'pushToRight')
)({
  marginTop: -6,
  marginBottom: -6,
  padding: 6
});

FeedbackItemActionButton.propTypes = {
  dangerous: PropTypes.bool
};

FeedbackItemActionButton.defaultProps = {
  dangerous: false
};

export default FeedbackItemActionButton;
