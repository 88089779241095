import styled from '@emotion/styled';

const EditorWrapper = styled('section')({
  marginTop: 6,
  display: 'flex',
  alignItems: 'center',
  ':first-child': {
    marginTop: 12
  }
});

export default EditorWrapper;
