import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const StyledButton = styled(Button)({
  position: 'absolute',
  top: 7,
  right: 24,
  [mediaQuery(1024)]: {
    display: 'none'
  }
});

export default StyledButton;
