import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import mediaQuery from 'lib/styling/mediaQuery';
import device from 'current-device';

const StyledContentWrapper = styled(motion.section)(({ visible }) => ({
  paddingRight: 48,
  height: visible ? 'auto' : 0,
  flex: 1,
  maxWidth: '50%',
  boxSizing: 'border-box',
  [mediaQuery(768)]: {
    paddingRight: 24
  },
  [mediaQuery(450)]: {
    paddingRight: 16
  }
}));

const LoginContent = props => (
  <StyledContentWrapper
    initial={{
      margin: device.desktop() ? -24 : 0,
      opacity: device.desktop() ? 1 : 0
    }}
    animate={{ margin: 0, opacity: 1 }}
    transition={{
      delay: device.desktop() ? 0 : 0.2
    }}
    {...props}
  />
);

LoginContent.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default LoginContent;
