import { useState } from 'react';
import { Button } from '@material-ui/core';
import Space from 'lib/components/Space';
import SettingsDialog from './SettingsDialog';
import { FormattedMessage } from 'react-intl';

const ContentSettings = props => {
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  return (
    <>
      <Button
        variant="outlined"
        onClick={() => setSettingsDialogOpen(true)}
        {...props}
      >
        <FormattedMessage id="platform_settings.content_settings.button" />
      </Button>
      <Space height={16} />
      <SettingsDialog
        open={settingsDialogOpen}
        onClose={() => setSettingsDialogOpen(false)}
      />
    </>
  );
};

export default ContentSettings;
