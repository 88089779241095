import PortfolioItemWithGroupsPrompt from '../../PortfolioItemWithGroupsPrompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';

const PartPromptWithGroups = props => (
  <PortfolioItemWithGroupsPrompt
    label={
      <FormattedMessage id="portfolio_template_editor.part.edit.input_label" />
    }
    {...props}
  >
    <Dialog.Title>
      <FormattedMessage id="portfolio_template_editor.part.edit.title" />
    </Dialog.Title>
    <Dialog.Content>
      <Dialog.ContentText>
        <FormattedMessage id="portfolio_template_editor.part.edit.text" />
      </Dialog.ContentText>
    </Dialog.Content>
  </PortfolioItemWithGroupsPrompt>
);

export default PartPromptWithGroups;
