import { useState } from 'react';
import { useTheme } from '@emotion/react';
import getRandomColor from 'lib/styling/getRandomColor';
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme
} from '@material-ui/core/styles';

const RandomColorThemeProvider = props => {
  const [randomColor] = useState(getRandomColor());

  const theme = useTheme();
  const randomColorShade = theme.isDark ? 200 : 600;

  const randomColorTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme.palette.isHighContrast
          ? theme.palette.text.primary
          : randomColor[randomColorShade]
      }
    },
    typography: {
      fontFamily: 'CircularStd'
    }
  });

  return <MuiThemeProvider theme={randomColorTheme} {...props} />;
};

export default RandomColorThemeProvider;
