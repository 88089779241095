import { useContext } from 'react';
import LearningGoalsContext from '../../LearningGoalsContext';
import NoItemsMessage from 'components/LearningGoals/NoItemsMessage';
import TileGrid from 'components/LearningGoals/TileGrid';
import Topic from './Topic';
import { FormattedMessage } from 'react-intl';

const Topics = props => {
  const { learningGoalsStructure } = useContext(LearningGoalsContext);

  if (learningGoalsStructure.length === 0) {
    return (
      <NoItemsMessage>
        <FormattedMessage id="portfolio.challenge.learning_goals.no_topics" />
      </NoItemsMessage>
    );
  }

  return (
    <TileGrid {...props}>
      {learningGoalsStructure.map(topic => (
        <Topic topic={topic} key={topic.key} />
      ))}
    </TileGrid>
  );
};

export default Topics;
