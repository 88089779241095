import styled from '@emotion/styled';
import stripProps from 'lib/utils/stripProps';
import { Button } from '@material-ui/core';

const StyledAddFeedbackButton = styled(stripProps(Button, 'belowFeedbackList'))(
  {
    marginBottom: 24,
    marginLeft: 24,
    marginRight: 24,
    width: 'calc(100% - 48px)'
  }
);

export default StyledAddFeedbackButton;
