import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const UserSearchWrapper = styled('form')(({ theme, expertSystemEnabled }) => ({
  marginLeft: 'auto',
  width: expertSystemEnabled ? 480 : 432,
  transition: theme.transitions.create(),
  [mediaQuery(1200)]: {
    width: 228
  },
  [mediaQuery(768)]: {
    marginTop: 12,
    width: '100%'
  }
}));

UserSearchWrapper.propTypes = {
  expertSystemEnabled: PropTypes.bool.isRequired
};

export default UserSearchWrapper;
