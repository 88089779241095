import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import useInterval from 'use-interval';
import DeleteButton from './DeleteButton';
import CancelDeletionButtonWrapper from './CancelDeletionButtonWrapper';
import DeletionProgressIndicator from './DeletionProgressIndicator';
import CancelDeletionButton from './CancelDeletionButton';

const DeleteLogEntry = ({ logEntryRef, ...props }) => {
  const [deleting, setDeleting] = useState(false);
  const [deletionProgress, setDeletionProgress] = useState(0);

  useInterval(() => {
    if (deleting && deletionProgress < 110) {
      setDeletionProgress(deletionProgress + 10);
    }
  }, 500);

  useEffect(() => {
    if (!deleting) {
      setDeletionProgress(0);
    }
  }, [deleting]);

  const deleteLogEntry = () => logEntryRef.remove();

  if (deleting && deletionProgress === 110) {
    deleteLogEntry();
  }

  if (!deleting) {
    return <DeleteButton onClick={() => setDeleting(true)} {...props} />;
  }

  return (
    <CancelDeletionButtonWrapper>
      <DeletionProgressIndicator deletionProgress={deletionProgress} />
      <CancelDeletionButton onClick={() => setDeleting(false)} />
    </CancelDeletionButtonWrapper>
  );
};

DeleteLogEntry.propTypes = {
  logEntryRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default DeleteLogEntry;
