import { useState } from 'react';
import PropTypes from 'prop-types';
import useChallenge from './useChallenge';
import ChallengeView from './ChallengeView';
import setBodyOverflow from 'lib/styling/setBodyOverflow';
import ChallengeCard from './ChallengeCard';
import LoadingChallengeCard from './LoadingChallengeCard';
import useChallengeDrag from './useChallengeDrag';

const Challenge = ({
  challengeKey,
  asTask,
  row,
  collapsed,
  isNewlyShared,
  disableDrag,
  ...props
}) => {
  const [loading, challenge] = useChallenge(challengeKey, asTask);
  const [open, setOpen] = useState(false);

  const [isDragging, attachDragHandlingRef] = useChallengeDrag(
    challengeKey,
    asTask,
    row,
    disableDrag
  );

  if (loading) {
    return (
      <LoadingChallengeCard
        collapsed={collapsed}
        withPhaseText={isNewlyShared}
      />
    );
  }

  if (!challenge) {
    return null;
  }

  return (
    <>
      <ChallengeCard
        challenge={challenge}
        asTask={asTask}
        collapsed={collapsed}
        withPhaseText={isNewlyShared}
        dragging={isDragging}
        onClick={() => {
          setOpen(true);
          setBodyOverflow('hidden');
        }}
        ref={attachDragHandlingRef}
        {...props}
      />
      {open && (
        <ChallengeView
          challenge={challenge}
          asTask={asTask}
          isNewlyShared={isNewlyShared}
          onClose={() => {
            setOpen(false);
            setBodyOverflow('auto');
          }}
        />
      )}
    </>
  );
};

Challenge.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  asTask: PropTypes.bool,
  row: PropTypes.object,
  collapsed: PropTypes.bool,
  isNewlyShared: PropTypes.bool,
  disableDrag: PropTypes.bool
};

Challenge.defaultProps = {
  asTask: false,
  collapsed: false,
  isNewlyShared: false,
  disableDrag: false
};

export default Challenge;
