import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';

const ButtonWrapper = styled(ButtonBase)({
  marginBottom: 18,
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer'
});

export default ButtonWrapper;
