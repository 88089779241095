import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import ButtonBase from 'lib/components/ButtonBase';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const GroupSelectorGroup = styled(ButtonBase)(
  ({ theme }) => ({
    padding: '10px 15px',
    width: '100%',
    textAlign: 'left',
    transition: theme.transitions.create(),
    ':hover, :focus': {
      backgroundColor: theme.palette.action.hover
    },
    ':active': {
      backgroundColor: theme.palette.action.selected
    }
  }),
  ({ theme, selected }) => {
    if (selected) {
      return {
        fontWeight: 500,
        backgroundColor: theme.palette.action.selected
      };
    }
  },
  addHighContrastStyles(({ selected }) => ({
    backgroundColor: selected ? 'transparent' : null,
    textDecoration: selected ? 'underline' : null,
    ':hover, :focus': {
      textDecoration: 'underline'
    }
  }))
);

GroupSelectorGroup.propTypes = {
  selected: PropTypes.bool.isRequired
};

export default GroupSelectorGroup;
