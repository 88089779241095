import styled from '@emotion/styled';

const computeLevelTitleColor = theme =>
  theme.palette.increaseContrastWithBackground(theme.palette.primary.main, 20);

const LevelTitle = styled('h4')(({ theme }) => ({
  marginBottom: 18,
  color: computeLevelTitleColor(theme)
}));

LevelTitle.computeColor = computeLevelTitleColor;

export default LevelTitle;
