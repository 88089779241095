import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import ActionsWrapper from './ActionsWrapper';
import CreateSlo from './CreateSlo';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import FeedbackButton from './FeedbackButton';

const SloPeriodActions = ({ onOpenFeedback, ...props }) => {
  const isMobile = useMediaQuery('(max-width: 460px)');
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <ActionsWrapper {...props}>
      <CreateSlo
        variant={isMobile ? 'outlined' : 'text'}
        size={isMobile ? 'small' : 'medium'}
        disabled={portfolioMode !== PortfolioModes.STUDENT}
        fullWidth={isMobile}
      />
      {isMobile ? <Space height={12} /> : <Space width="auto" />}
      <FeedbackButton
        variant={isMobile ? 'outlined' : 'text'}
        size={isMobile ? 'small' : 'medium'}
        onClick={onOpenFeedback}
        fullWidth={isMobile}
      />
    </ActionsWrapper>
  );
};

SloPeriodActions.propTypes = {
  onOpenFeedback: PropTypes.func.isRequired
};

export default SloPeriodActions;
