import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import AttachedSlosContext from '../AttachedSlosContext';
import App from 'lib/App';

const StyledWrapper = styled('section')(({ fullWidth }) => ({
  marginBottom: 24,
  display: 'none',
  justifyContent: 'center',
  alignItems: 'center',
  [mediaQuery(900)]: {
    display: fullWidth ? 'flex' : 'inline-flex'
  },
  [mediaQuery(560)]: {
    display: 'flex'
  },
  [mediaQuery(500)]: {
    justifyContent: 'space-between'
  }
}));

StyledWrapper.propTypes = {
  fullWidth: PropTypes.bool.isRequired
};

const LevelSelectArrowsWrapper = props => {
  const attachedSlos = useContext(AttachedSlosContext);
  const sloSystemEnabled = App.instanceConfig.useContentSetting(
    'enableSloSystem'
  );

  const fullWidth = attachedSlos.length === 0 || !sloSystemEnabled;

  return <StyledWrapper fullWidth={fullWidth} {...props} />;
};

export default LevelSelectArrowsWrapper;
