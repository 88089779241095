import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { rgba } from 'lib/styling/colors';

const getBackgroundOpacity = deletionProgress =>
  0.6 + (deletionProgress / 100) * 0.4;

const CountdownOverlay = styled('section')(
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 'calc(100% - 2px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  ({ theme, deletionProgress }) => ({
    backgroundColor: rgba(
      theme.palette.background.default,
      getBackgroundOpacity(deletionProgress)
    ),
    backdropFilter: `saturate(50%) blur(${deletionProgress / 10}px)`
  })
);

CountdownOverlay.propTypes = {
  deletionProgress: PropTypes.number.isRequired
};

export default CountdownOverlay;
