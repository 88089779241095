import styled from '@emotion/styled';
import { Fab } from '@material-ui/core';
import common from '@material-ui/core/colors/common';
import deepPurple from '@material-ui/core/colors/deepPurple';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const StyledSupportButton = styled(Fab)(
  ({ theme }) => ({
    position: 'fixed',
    bottom: 200,
    right: 0,
    transform: 'translateX(calc(100% - 52px))',
    color: common.white,
    backgroundColor: deepPurple[theme.isDark ? 300 : 500],
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    zIndex: 3,
    transition: theme.transitions.create(),
    ':hover, :focus': {
      transform: 'none',
      backgroundColor: deepPurple[theme.isDark ? 400 : 700]
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    ':hover, :focus': {
      backgroundColor: theme.palette.primary.main
    }
  }))
);

export default StyledSupportButton;
