import PropTypes from 'prop-types';
import DialogContentWrapper from '../DialogContentWrapper';
import Dialog from 'lib/components/Dialog';
import SupportTilesWrapper from './SupportTilesWrapper';
import SupportTypes from '../SupportTypes';
import SupportTile from './SupportTile';
import {
  ContactSupportOutlined as ContactIcon,
  CommentOutlined as FeedbackIcon,
  ReportProblemOutlined as ReportProblemIcon
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const SupportTypeSelector = ({ onSelectSupportType, ...props }) => (
  <DialogContentWrapper>
    <Dialog.Title>
      <FormattedMessage id="support.dialog.support_type_selector.title" />
    </Dialog.Title>
    <Dialog.Content>
      <SupportTilesWrapper {...props}>
        <SupportTile onClick={() => onSelectSupportType(SupportTypes.CONTACT)}>
          <SupportTile.Icon icon={ContactIcon} />
          <SupportTile.Title>
            <FormattedMessage id="support.dialog.support_type_selector.contact_title" />
          </SupportTile.Title>
        </SupportTile>
        <SupportTile onClick={() => onSelectSupportType(SupportTypes.FEEDBACK)}>
          <SupportTile.Icon icon={FeedbackIcon} />
          <SupportTile.Title>
            <FormattedMessage id="support.dialog.support_type_selector.feedback_title" />
          </SupportTile.Title>
        </SupportTile>
        <SupportTile
          onClick={() => onSelectSupportType(SupportTypes.ISSUE_REPORTING)}
        >
          <SupportTile.Icon icon={ReportProblemIcon} />
          <SupportTile.Title>
            <FormattedMessage id="support.dialog.support_type_selector.issue_reporting_title" />
          </SupportTile.Title>
        </SupportTile>
      </SupportTilesWrapper>
    </Dialog.Content>
  </DialogContentWrapper>
);

SupportTypeSelector.propTypes = {
  onSelectSupportType: PropTypes.func
};

export default SupportTypeSelector;
