import styled from '@emotion/styled';
import InputWithEscListener from 'lib/components/InputWithEscListener';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const GroupNameInput = styled(InputWithEscListener)(
  ({ theme }) => ({
    marginRight: 6,
    padding: '6px 8px',
    flex: 1,
    backgroundColor: theme.palette.background.shaded,
    fontFamily: 'CircularStd',
    borderRadius: 4
  }),
  addBorderInHighContrastMode
);

export default GroupNameInput;
