import useUploadedLogoState from './useUploadedLogoState';
import saveImageInFirebase from './saveImageInFirebase';
import SettingsBox from '../SettingsBox';
import { Settings as SettingsIcon } from '@material-ui/icons';
import LogoUploadSection from './LogoUploadSection';
import ContentSettings from './ContentSettings';
import SaveButton from './SaveButton';
import { FormattedMessage } from 'react-intl';

const GeneralSettings = props => {
  const [logo, setLogo, logoHasChanged] = useUploadedLogoState();

  const saveChanges = async () => {
    await saveImageInFirebase('instanceConfig/logo', logo);
    window.location.reload();
  };

  return (
    <SettingsBox
      title={<FormattedMessage id="platform_settings.general_settings.title" />}
      icon={<SettingsIcon />}
      {...props}
    >
      <LogoUploadSection
        logo={logo}
        onSetLogoFiles={newLogoFiles => setLogo(newLogoFiles)}
      />
      <ContentSettings />
      <SaveButton onClick={saveChanges} disabled={!logoHasChanged} />
    </SettingsBox>
  );
};

export default GeneralSettings;
