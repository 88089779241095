import PropTypes from 'prop-types';
import NameWrapper from './NameWrapper';
import OverflowIndicator from './OverflowIndicator';
import NameOverflowWrapper from './NameOverflowWrapper';

const ChallengeName = ({
  contentVisibilityCompensationDistance,
  contentMaxVisibleWidth,
  children,
  ...props
}) => {
  const challengeOverflowsOnLeft = contentVisibilityCompensationDistance > 0;
  const challengeOverflowsOnRight =
    contentMaxVisibleWidth < 100 - contentVisibilityCompensationDistance;

  return (
    <NameWrapper
      contentVisibilityCompensationDistance={
        contentVisibilityCompensationDistance
      }
      contentMaxVisibleWidth={contentMaxVisibleWidth}
      {...props}
    >
      {challengeOverflowsOnLeft && <OverflowIndicator side="left" />}
      <NameOverflowWrapper>{children}</NameOverflowWrapper>
      {challengeOverflowsOnRight && <OverflowIndicator side="right" />}
    </NameWrapper>
  );
};

ChallengeName.propTypes = {
  contentVisibilityCompensationDistance: PropTypes.number.isRequired,
  contentMaxVisibleWidth: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default ChallengeName;
