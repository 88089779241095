import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import { rowRef } from 'lib/firebase';
import WrapperForm from './WrapperForm';
import RowNameInput from './RowNameInput';
import RowName from './RowName';
import CancelChangeButton from './CancelChangeButton';
import SaveButtonWithEnterTrigger from './SaveButtonWithEnterTrigger';
import EditButton from './EditButton';
import { useIntl } from 'react-intl';
import { useMediaQuery } from '@material-ui/core';

const EditableRowName = ({ row, editing, setEditing, ...props }) => {
  const [newRowName, setNewRowName] = useSyncedState(row.name);

  const saveNameChange = () => {
    if (newRowName.trim() !== '') {
      rowRef(row.key).child('name').set(newRowName.trim());
      setEditing(false);
    }
  };

  const cancelNameChange = () => {
    setNewRowName(row.name);
    setEditing(false);
  };

  const intl = useIntl();
  const editButtonIsInMenu = useMediaQuery('(max-width: 560px)');

  return (
    <WrapperForm onSubmit={e => e.preventDefault()} fullWidth={editing}>
      {editing ? (
        <>
          <RowNameInput
            placeholder={intl.formatMessage({
              id: 'portfolio.row.edit.input_placeholder'
            })}
            value={newRowName}
            onChange={e => setNewRowName(e.target.value)}
            onEscPress={cancelNameChange}
            autoFocus
          />
          <CancelChangeButton
            visible={newRowName !== row.name}
            onClick={cancelNameChange}
          />
          <SaveButtonWithEnterTrigger
            onClick={saveNameChange}
            disabled={!newRowName.trim()}
          />
        </>
      ) : (
        <>
          <RowName>{row.name}</RowName>
          {!editButtonIsInMenu && (
            <EditButton onClick={() => setEditing(true)} />
          )}
        </>
      )}
    </WrapperForm>
  );
};

EditableRowName.propTypes = {
  row: PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  editing: PropTypes.bool.isRequired,
  setEditing: PropTypes.func.isRequired
};

export default EditableRowName;
