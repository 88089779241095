import { value, taskRef, ref } from 'lib/firebase';
import arrToObj from 'lib/utils/arrToObj';
import Phases from 'lib/constants/Phases';

const copyTask = async (taskKey, newParentChallengeKey) => {
  const task = await value(taskRef(taskKey));

  if (!task) {
    return null;
  }

  const taskCopy = {
    ...task,
    key: null,
    creationDate: Date.now(),
    challenge: newParentChallengeKey,
    assignees: null
  };

  return ref('tasks').push(taskCopy).key;
};

const copyChallengeTasksForPhase = async (
  originalChallenge,
  phase,
  challengeCopyKey
) => {
  const tasksKeysObject = originalChallenge.tasks[phase];

  if (!tasksKeysObject) {
    return null;
  }

  const tasksKeys = Object.keys(tasksKeysObject);
  const copiedTasksKeys = await Promise.all(
    tasksKeys.map(taskKey => copyTask(taskKey, challengeCopyKey))
  );

  return arrToObj(
    copiedTasksKeys.filter(taskKey => !!taskKey),
    true
  );
};

const copyChallengeTasks = async (originalChallenge, challengeCopyKey) => {
  if (!originalChallenge.tasks) {
    return null;
  }

  const [todoTasksKeys, progressTasksKeys, doneTasksKeys] = await Promise.all([
    copyChallengeTasksForPhase(
      originalChallenge,
      Phases.TODO,
      challengeCopyKey
    ),
    copyChallengeTasksForPhase(
      originalChallenge,
      Phases.PROGRESS,
      challengeCopyKey
    ),
    copyChallengeTasksForPhase(originalChallenge, Phases.DONE, challengeCopyKey)
  ]);

  return {
    [Phases.TODO]: todoTasksKeys,
    [Phases.PROGRESS]: progressTasksKeys,
    [Phases.DONE]: doneTasksKeys
  };
};

export default copyChallengeTasks;
