import FullscreenView from '../../../../../../FullscreenView';
import AttachedChallenges from './AttachedChallenges';
import AttachChallenges from './AttachChallenges';
import { FormattedMessage } from 'react-intl';

const SloChallengeAttachment = props => (
  <FullscreenView.Content shaded {...props}>
    <FullscreenView.Content.Title>
      <FormattedMessage id="slo_system.slo.challenge_attachment.title" />
    </FullscreenView.Content.Title>
    <AttachedChallenges />
    <AttachChallenges />
  </FullscreenView.Content>
);

export default SloChallengeAttachment;
