import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FeedbackFieldsWrapper = styled('div')({
  display: 'flex',
  [mediaQuery(460)]: {
    display: 'block'
  }
});

export default FeedbackFieldsWrapper;
