import { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import HasMovedToSecondStepContext from '../HasMovedToSecondStepContext';
import DialogTitleWithBackButton from './DialogTitleWithBackButton';

const StyledDialogStep = styled('article')(({ visible }) => ({
  flex: 1,
  width: '50%',
  height: visible ? 'auto' : 0,
  boxSizing: 'border-box',
  overflow: !visible ? 'hidden' : null
}));

const DialogStep = ({ isFirstStep, ...props }) => {
  const hasMovedToSecondStep = useContext(HasMovedToSecondStepContext);
  return (
    <StyledDialogStep
      visible={isFirstStep ? !hasMovedToSecondStep : hasMovedToSecondStep}
      isFirstStep={isFirstStep}
      {...props}
    />
  );
};

DialogStep.propTypes = {
  isFirstStep: PropTypes.bool
};

DialogStep.defaultProps = {
  isFirstStep: false
};

DialogStep.TitleWithBackButton = DialogTitleWithBackButton;

export default DialogStep;
