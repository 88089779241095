import { useContext } from 'react';
import PortfolioContext from 'lib/contexts/PortfolioContext';
import ensureLinkIsFullUrl from 'lib/utils/ensureLinkIsFullUrl';

const correctProofUrls = proof => {
  if (!proof) {
    return null;
  }

  const isLegacySingleProofLink = typeof proof === 'string';
  if (isLegacySingleProofLink) {
    return ensureLinkIsFullUrl(proof);
  }

  const newProofObject = {};

  for (const individualProofKey in proof) {
    newProofObject[individualProofKey] = ensureLinkIsFullUrl(
      proof[individualProofKey]
    );
  }

  return newProofObject;
};

const useProof = (levelId, proofSubpath = null) => {
  const portfolio = useContext(PortfolioContext);

  const proofWithoutSubpath = portfolio?.proof?.[levelId];

  if (!proofWithoutSubpath) {
    return null;
  }

  if (!proofSubpath) {
    return correctProofUrls(proofWithoutSubpath);
  }

  const proof = proofWithoutSubpath[proofSubpath];

  if (!proof) {
    return null;
  }

  return correctProofUrls(proof);
};

export default useProof;
