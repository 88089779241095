import PropTypes from 'prop-types';
import NoChallengesMessage from './NoChallengesMessage';
import Row from './Row';

const Rows = ({ rowKeys, highestRowUiPos, ...props }) => {
  if (rowKeys.length === 0) {
    return <NoChallengesMessage />;
  }

  return rowKeys.map(([rowKey, uiPos], i) => (
    <Row
      rowKey={rowKey}
      uiPos={uiPos}
      previousRowKey={rowKeys[i - 1]?.[0]}
      previousRowUiPos={rowKeys[i - 1]?.[1]}
      nextRowKey={rowKeys[i + 1]?.[0]}
      nextRowUiPos={rowKeys[i + 1]?.[1]}
      highestUiPos={highestRowUiPos}
      key={rowKey}
    />
  ));
};

Rows.propTypes = {
  rowKeys: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ).isRequired,
  highestRowUiPos: PropTypes.number.isRequired
};

export default Rows;
