import PropTypes from 'prop-types';
import StyledTextareaWithAutosizing from './StyledTextareaWithAutosizing';

const FormattedText = ({ children, ...props }) => (
  <StyledTextareaWithAutosizing
    value={children}
    tabIndex={-1}
    readOnly
    {...props}
  />
);

FormattedText.propTypes = {
  children: PropTypes.string.isRequired
};

export default FormattedText;
