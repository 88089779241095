import { ref, value } from 'lib/firebase';
import deleteChildren from './deleteChildren';
import deleteLevel from './deleteLevel';

export const deleteLoadedPart = part => {
  const partRef = ref(`portfolioTemplates/parts/${part.id}`);

  deleteChildren(part.levels, deleteLevel);
  deletePartFromSubtopics(part.id, part.subtopicId);
  partRef.remove();
};

const deletePartFromSubtopics = (id, subtopicIdOrObject) => {
  if (!subtopicIdOrObject) {
    return;
  }

  if (typeof subtopicIdOrObject === 'string') {
    return deletePartFromSubtopic(id, subtopicIdOrObject);
  }

  return Object.keys(subtopicIdOrObject).map(subtopicId =>
    deletePartFromSubtopic(id, subtopicId)
  );
};

const deletePartFromSubtopic = (id, subtopicId) =>
  ref(`portfolioTemplates/subtopics/${subtopicId}/parts/${id}`).remove();

const deletePart = async id => {
  const partRef = ref(`portfolioTemplates/parts/${id}`);
  const part = await value(partRef);

  if (part) {
    return deleteLoadedPart(part);
  }
};

export default deletePart;
