import PropTypes from 'prop-types';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';

const fieldPropType = PropTypes.shape({
  name: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(FieldTypes)).isRequired,
  groups: PropTypes.objectOf(PropTypes.bool),
  info: PropTypes.string,
  uiPos: PropTypes.number,
  logEntryName: PropTypes.string,
  options: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string
    })
  ),
  multipleOptionsSelectable: PropTypes.bool,
  reusedLearningGoals: PropTypes.string
});

const phasePropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  groups: PropTypes.objectOf(PropTypes.bool),
  uiPos: PropTypes.number.isRequired,
  leftContent: PropTypes.objectOf(fieldPropType),
  rightContent: PropTypes.objectOf(fieldPropType),
  endContent: PropTypes.objectOf(fieldPropType)
});

const ModelPropTypes = {
  phase: phasePropType,
  field: fieldPropType
};

export default ModelPropTypes;
