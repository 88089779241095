import { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import StackSizeContext from '../StackSizeContext';
import StyledSliderRail from './StyledSliderRail';

const SliderRail = forwardRef(
  (
    { onMouseMoveActivate, onMouseMoveDeactivate, onMouseMove, ...props },
    ref
  ) => {
    const { largestStackSize } = useContext(StackSizeContext);
    return (
      <StyledSliderRail
        onMouseDown={onMouseMoveActivate}
        onMouseUp={onMouseMoveDeactivate}
        onMouseLeave={onMouseMoveDeactivate}
        onMouseMove={onMouseMove}
        onTouchStart={onMouseMoveActivate}
        onTouchEnd={onMouseMoveDeactivate}
        onTouchCancel={onMouseMoveDeactivate}
        onTouchMove={onMouseMove}
        largestStackSize={largestStackSize}
        ref={ref}
        {...props}
      />
    );
  }
);

SliderRail.propTypes = {
  levelCount: PropTypes.number.isRequired,
  onMouseMoveActivate: PropTypes.func.isRequired,
  onMouseMoveDeactivate: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default SliderRail;
