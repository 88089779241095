import PropTypes from 'prop-types';
import LearningGoalsAppBar from 'components/LearningGoals/LearningGoalsAppBar';
import { Close as CloseIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const LearningGoalsManagerHeader = ({ onClose, ...props }) => (
  <LearningGoalsAppBar {...props}>
    <LearningGoalsAppBar.IconButton onClick={onClose}>
      <CloseIcon />
    </LearningGoalsAppBar.IconButton>
    <LearningGoalsAppBar.Title>
      <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.title" />
    </LearningGoalsAppBar.Title>
  </LearningGoalsAppBar>
);

LearningGoalsManagerHeader.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default LearningGoalsManagerHeader;
