import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import itemIsVisibleToPortfolioUser from '../itemIsVisibleToPortfolioUser';
import sortByUiPos from 'lib/utils/sortByUiPos';

const useSortedVisibleFields = fieldsObject => {
  const portfolioUser = useContext(PortfolioUserContext);

  const fields = flattenFirebaseList(fieldsObject);
  const hasValidFieldType = field => !!FieldTypes[field.type];
  const isVisibleToPortfolioUser = field =>
    itemIsVisibleToPortfolioUser(field, portfolioUser);

  return fields
    .sort(sortByUiPos)
    .filter(hasValidFieldType)
    .filter(isVisibleToPortfolioUser);
};

export default useSortedVisibleFields;
