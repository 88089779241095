import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import useFormatMessageWithChallengeNaming from 'lib/utils/useFormatMessageWithChallengeNaming';
import StyledInput from './StyledInput';

const SearchInput = forwardRef(
  ({ onChange, onEmptied, onEscPress, ...props }, ref) => {
    const formatMessageWithChallengeNaming = useFormatMessageWithChallengeNaming();
    return (
      <StyledInput
        placeholder={formatMessageWithChallengeNaming({
          id: 'challenge_search_bar.placeholder'
        })}
        type="text"
        onChange={e => {
          const newValue = e.target.value;
          onChange(newValue);

          if (newValue === '') {
            onEmptied();
          }
        }}
        onEscPress={() => {
          onChange('');
          onEscPress();
        }}
        {...props}
        ref={ref}
      />
    );
  }
);

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onEmptied: PropTypes.func.isRequired,
  onEscPress: PropTypes.func.isRequired
};

export default SearchInput;
