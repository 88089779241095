import Roles from 'lib/constants/Roles';
import Groups from 'lib/constants/Groups';

const groupFromRole = role => {
  switch (role) {
    case Roles.STUDENT: {
      return Groups.STUDENTS;
    }
    case Roles.COACH:
    case Roles.EDITOR:
    case Roles.HALF_ADMIN: {
      return Groups.COACHES;
    }
    case Roles.ADMIN: {
      return Groups.ALL;
    }
    default: {
      return Groups.STUDENTS;
    }
  }
};

export default groupFromRole;
