import App from 'lib/App';
import StyledContentWrapper from './StyledContentWrapper';

const AppContentWrapper = props => {
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();
  return (
    <StyledContentWrapper
      wideContent={isDisplayingChallengePortfolio}
      {...props}
    />
  );
};

export default AppContentWrapper;
