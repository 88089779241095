import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useMediaQuery } from '@material-ui/core';

const StyledName = styled('h4')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 500
}));

const UserName = ({ name, ...props }) => {
  const shouldOnlyShowFirstName = useMediaQuery('(max-width: 450px)');

  const firstName = name.split(' ')[0];

  return (
    <StyledName {...props}>
      {shouldOnlyShowFirstName ? firstName : name}
    </StyledName>
  );
};

UserName.propTypes = {
  name: PropTypes.string.isRequired
};

export default UserName;
