import useLogin from './useLogin';
import App from 'lib/App';
import firebase from 'firebase/compat/app';
import { safeValues } from 'lib/utils/safeObjectFunctions';

const useLoginWithGoogle = () => {
  const login = useLogin();
  const allowedEmailsObject = App.instanceConfig.useSetting('allowedEmails');

  const loginWithGoogle = onShowEmailNotAllowedError => () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    const allowedEmails = safeValues(allowedEmailsObject);

    if (allowedEmails.length === 1) {
      provider.setCustomParameters({
        hd: allowedEmails[0].replace('@', '')
      });
    }

    return login(provider, onShowEmailNotAllowedError);
  };

  return loginWithGoogle;
};

export default useLoginWithGoogle;
