import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const FormWrapper = styled('form')(({ theme }) => ({
  margin: '-24px -48px',
  marginBottom: 12,
  padding: '24px 48px',
  backgroundColor: theme.palette.action.hover,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(560)]: {
    margin: -24,
    marginBottom: 12,
    padding: 24
  }
}));

export default FormWrapper;
