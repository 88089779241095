import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import sortByUiPos from 'lib/utils/sortByUiPos';
import Dialog from 'lib/components/Dialog';
import TopicList from './TopicList';
import SelectableTopic from './SelectableTopic';
import { FormattedMessage } from 'react-intl';

const TopicSelector = ({ onSelectTopic, ...props }) => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  const topics = safeValues(portfolioTemplates?.topics)
    .filter(userCanAccessItem(portfolioUser, true))
    .sort(sortByUiPos);

  return (
    <>
      <Dialog.Title>
        <FormattedMessage id="slo_system.slo.part_attachment.management_dialog.title" />
      </Dialog.Title>
      <TopicList {...props}>
        {topics.map(topic => (
          <SelectableTopic
            topic={topic}
            onClick={() => onSelectTopic(topic)}
            key={topic.id}
          />
        ))}
      </TopicList>
    </>
  );
};

TopicSelector.propTypes = {
  onSelectTopic: PropTypes.func.isRequired
};

export default TopicSelector;
