import { createContext, useState } from 'react';
import App from 'lib/App';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const ExpertModeSettingsContext = createContext({
  isViewingAsExpert: true,
  setIsViewingAsExpert: () => {},
  activeExpertiseKey: null,
  setActiveExpertiseKey: () => {}
});

const AutomatedExpertModeSettingsContextProvider = props => {
  const currentUser = App.authentication.useCurrentUser();

  const [isViewingAsExpert, setIsViewingAsExpert] = useState(
    !!currentUser.isExpert
  );

  const firstExpertiseKey = safeKeys(currentUser.expertiseKeys)[0] || null;
  const [activeExpertiseKey, setActiveExpertiseKey] = useState(
    firstExpertiseKey
  );

  return (
    <ExpertModeSettingsContext.Provider
      value={{
        isViewingAsExpert,
        setIsViewingAsExpert,
        activeExpertiseKey,
        setActiveExpertiseKey
      }}
      {...props}
    />
  );
};

ExpertModeSettingsContext.AutomatedProvider = AutomatedExpertModeSettingsContextProvider;

export default ExpertModeSettingsContext;
