import { useContext } from 'react';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import SloContext from '../../../SloContext';
import { Button } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';
import PortfolioModes from 'lib/constants/PortfolioModes';
import { FormattedMessage } from 'react-intl';

const AttachChallengesButton = props => {
  const portfolioMode = useContext(PortfolioModeContext);

  const slo = useContext(SloContext);
  const hasAttachedChallenges = !!slo.attachedChallenges;

  return (
    <Button
      startIcon={<AddIcon />}
      disabled={portfolioMode !== PortfolioModes.STUDENT}
      {...props}
    >
      <FormattedMessage
        id={`slo_system.slo.challenge_attachment.${
          hasAttachedChallenges ? 'attach_more_button' : 'attach_button'
        }`}
      />
    </Button>
  );
};

export default AttachChallengesButton;
