import { Button } from '@material-ui/core';
import { AddCircle as AddIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const AddButton = props => (
  <Button size="small" color="secondary" startIcon={<AddIcon />} {...props}>
    <FormattedMessage id="slo_system.slo.description.add_button" />
  </Button>
);

export default AddButton;
