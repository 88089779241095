import { useContext } from 'react';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PhaseContext from '../../PhaseContext';
import App from 'lib/App';
import { useDrag } from 'react-dnd';
import DragTypes from '../../DragTypes';
import PortfolioModes from 'lib/constants/PortfolioModes';
import { userRef, challengeRef, taskRef } from 'lib/firebase';
import fixChallengeDates from 'lib/utils/fixChallengeDates';
import updateChallengePhase from './updateChallengePhase';
import updateTaskPhase from './updateTaskPhase';

const useChallengeDrag = (challengeKey, asTask, row, disableDrag) => {
  const portfolioMode = useContext(PortfolioModeContext);
  const phase = useContext(PhaseContext);
  const currentUser = App.authentication.useCurrentUser();
  const currentUserUsername = App.authentication.useCurrentUserUsername();

  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: asTask ? DragTypes.TASK : DragTypes.CHALLENGE,
      row,
      phase
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      const { shouldDrop, newPhase, newRow } = dropResult || {};

      if (!dropResult || !shouldDrop) {
        return;
      }

      if (currentUser.newlyShared?.[challengeKey]) {
        userRef(currentUserUsername)
          .child(`newlyShared/${challengeKey}`)
          .remove();
      }

      const updates = {
        phase: newPhase
      };

      if (newRow) {
        updates[`row/${currentUserUsername}`] = newRow.key;
      }

      const getItem = asTask ? taskRef : challengeRef;
      const updateItemPhase = asTask ? updateTaskPhase : updateChallengePhase;

      getItem(challengeKey).update(fixChallengeDates(updates));
      updateItemPhase(challengeKey, phase, newPhase, row, newRow);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  if (portfolioMode === PortfolioModes.COACH || disableDrag) {
    return [false, null];
  }

  return [isDragging, dragRef];
};

export default useChallengeDrag;
