import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const ErrorTitle = styled('h1')({
  marginTop: 0,
  marginBottom: 12,
  display: 'flex',
  alignItems: 'center',
  [mediaQuery(400)]: {
    fontSize: 28,
    display: 'block'
  }
});

export default ErrorTitle;
