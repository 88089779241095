/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from 'react';
import App from 'lib/App';
import { safeValues } from 'lib/utils/safeObjectFunctions';

const useIsUserAllowed = () => {
  const allowedEmailsObject = App.instanceConfig.useSetting('allowedEmails');
  const allowedEmails = safeValues(allowedEmailsObject);
  const allowedEmailPrefix = App.instanceConfig.useSetting(
    'allowedEmailPrefix'
  );

  const isUserAllowed = useCallback(
    user => {
      const email = user.email;

      if (!email || !email.startsWith(allowedEmailPrefix)) {
        return false;
      }

      return allowedEmails.reduce(
        (allowedSoFar, currentAllowedEmail) =>
          allowedSoFar || email.endsWith(currentAllowedEmail),
        false
      );
    },
    [allowedEmailPrefix, allowedEmails.toString()]
  );

  return isUserAllowed;
};

export default useIsUserAllowed;
