import styled from '@emotion/styled';
import teal from '@material-ui/core/colors/teal';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const SuperAdminLabel = styled('span')(
  ({ theme }) => ({
    marginLeft: 10,
    color: teal[theme.isDark ? 200 : 500],
    display: 'inline-block',
    [mediaQuery(700)]: {
      display: 'none'
    }
  }),
  addHighContrastStyles(({ theme }) => ({
    color: theme.palette.text.primary,
    '::before': {
      content: '"("'
    },
    '::after': {
      content: '")"'
    }
  }))
);

export default SuperAdminLabel;
