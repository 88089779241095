import App from 'lib/App';
import LinkWithWhiteBackground from './LinkWithWhiteBackground';
import { Skeleton } from '@material-ui/lab';
import StyledLogo from './StyledLogo';
import { useIntl } from 'react-intl';

const HeaderLogo = props => {
  const [logoLoading, logo] = App.instanceConfig.useInstanceLogo();
  const intl = useIntl();

  return (
    <LinkWithWhiteBackground to="/" {...props}>
      {logoLoading ? (
        <Skeleton variant="rect" width={120} height={32} animation="wave" />
      ) : (
        <StyledLogo
          src={logo}
          alt={intl.formatMessage({ id: 'header.logo_alt' })}
        />
      )}
    </LinkWithWhiteBackground>
  );
};

export default HeaderLogo;
