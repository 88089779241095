const getInitialCollapsedState = localStorageValue => {
  if (localStorageValue === null) {
    return true;
  }

  return localStorageValue === 'true';
};

const getCollapsedPortfolioItemsCache = localStorageKeyPrefix => {
  const collapsedPortfolioItemsCache = {};

  for (const key in localStorage) {
    const isIndicatingPortfolioItemCollapsedState = key.startsWith(
      localStorageKeyPrefix
    );

    if (isIndicatingPortfolioItemCollapsedState) {
      const itemId = key.replace(localStorageKeyPrefix, '');
      collapsedPortfolioItemsCache[itemId] = getInitialCollapsedState(
        localStorage.getItem(key)
      );
    }
  }

  return collapsedPortfolioItemsCache;
};

export default getCollapsedPortfolioItemsCache;
