import HideOnLowWidth from 'lib/components/HideOnLowWidth';
import { IconButton } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

const EditButton = props => (
  <HideOnLowWidth width={640}>
    <IconButton {...props}>
      <EditIcon />
    </IconButton>
  </HideOnLowWidth>
);

export default EditButton;
