import { useContext } from 'react';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import userCanAccessItem from 'lib/rubricsPortfolioUtils/userCanAccessItem';
import sortByUiPos from 'lib/utils/sortByUiPos';

const useTopicSubtopics = topic => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const portfolioUser = useContext(PortfolioUserContext);

  if (!topic) {
    return [];
  }

  const allSubtopics = portfolioTemplates?.subtopics;
  const topicSubtopicsKeys = safeKeys(topic.subtopics);
  const topicSubtopics = topicSubtopicsKeys
    .map(subtopicKey => allSubtopics?.[subtopicKey])
    .filter(subtopic => !!subtopic)
    .filter(userCanAccessItem(portfolioUser))
    .sort(sortByUiPos);

  return topicSubtopics;
};

export default useTopicSubtopics;
