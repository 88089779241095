import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { taskRef } from 'lib/firebase';
import RandomColorThemeProvider from './RandomColorThemeProvider';
import { Chip } from '@material-ui/core';
import AutomatedAvatar from './AutomatedAvatar';
import PortfolioModes from 'lib/constants/PortfolioModes';

const chipStyle = {
  marginRight: 6,
  marginBottom: 6
};

const AssigneeChip = ({ username, user, taskKey, ...props }) => {
  const portfolioMode = useContext(PortfolioModeContext);

  const removeAssignee = () =>
    taskRef(taskKey).child(`assignees/${username}`).remove();

  return (
    <RandomColorThemeProvider>
      <Chip
        avatar={<AutomatedAvatar user={user} />}
        label={user.name}
        onDelete={
          portfolioMode === PortfolioModes.STUDENT ? removeAssignee : null
        }
        color="primary"
        style={chipStyle}
        {...props}
      />
    </RandomColorThemeProvider>
  );
};

AssigneeChip.propTypes = {
  username: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  taskKey: PropTypes.string.isRequired
};

export default AssigneeChip;
