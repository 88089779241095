const getDropResult = (draggedItem, newPhase, newRow, asTaskBoard) => {
  const { phase: oldPhase, row: oldRow } = draggedItem;

  if (
    (asTaskBoard && newPhase === oldPhase) ||
    (!newRow && newPhase === oldPhase) ||
    (newRow?.key === oldRow?.key && newPhase === oldPhase)
  ) {
    return {
      shouldDrop: false,
      newPhase: null,
      newRow: null
    };
  }

  return {
    shouldDrop: true,
    newPhase,
    newRow
  };
};

export default getDropResult;
