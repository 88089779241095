import { useContext, useState } from 'react';
import FieldContext from '../../FieldContext';
import FieldName from '../utils/FieldName';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import LogEntry from './LogEntry';
import AddLogEntry from './AddLogEntry';
import ViewOlderLogEntriesButton from './ViewOlderLogEntriesButton';

const LogEntries = () => {
  const { field, challenge } = useContext(FieldContext);
  const logEntryName = field.logEntryName || 'item';

  const [blocksShown, setBlocksShown] = useState(1);

  const BLOCK_SIZE = 5;
  const allLogEntries = flattenFirebaseList(challenge[field.key]);
  const shownLogEntries = allLogEntries.slice(
    Math.max(allLogEntries.length - BLOCK_SIZE * blocksShown, 0),
    allLogEntries.length
  );

  return (
    <section>
      <FieldName />
      {shownLogEntries.length !== allLogEntries.length && (
        <ViewOlderLogEntriesButton
          onClick={() => setBlocksShown(blocksShown + 1)}
        />
      )}
      {shownLogEntries.map((logEntry, index) => (
        <LogEntry
          logEntry={logEntry}
          isLast={shownLogEntries.length - 1 === index}
          key={logEntry.key}
        />
      ))}
      <AddLogEntry logEntryName={logEntryName} />
    </section>
  );
};

export default LogEntries;
