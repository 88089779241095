import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';

const StyledLabel = styled('label')(({ theme }) => ({
  marginRight: 12,
  fontFamily: 'CircularStd',
  cursor: 'pointer',
  transition: theme.transitions.create()
}));

const LearningGoalsModelSelectLabel = props => (
  <StyledLabel {...props}>
    <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.settings.reuse_learning_goals.title" />
  </StyledLabel>
);

export default LearningGoalsModelSelectLabel;
