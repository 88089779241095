import PropTypes from 'prop-types';
import App from 'lib/App';
import Roles from 'lib/constants/Roles';
import UserContext from './UserContext';
import UserWrapper from './UserWrapper';
import UserSelectCheckbox from './UserSelectCheckbox';
import StyledUserAvatar from './StyledUserAvatar';
import UserInfoWrapper from './UserInfoWrapper';
import UserName from './UserName';
import UserEmail from './UserEmail';
import GroupSelect from './GroupSelect';
import BasicUserControlsWrapper from './BasicUserControlsWrapper';
import RoleSelect from './RoleSelect';
import ExpertiseManager from './ExpertiseManager';
import DeleteUser from './DeleteUser';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';

const User = ({
  user: userFallback,
  selected,
  onSelectUser,
  onMarkAsDeleted,
  ...props
}) => {
  const getUsernameFromEmail = App.authentication.useGetUsernameFromEmail();
  const currentUser = App.authentication.useCurrentUser();
  const expertSystemEnabled = App.instanceConfig.useContentSetting(
    'enableExpertSystem'
  );

  const [, syncedUser] = useFirebaseValue(
    ref(`users/${getUsernameFromEmail(userFallback.email)}`)
  );
  const user = syncedUser || userFallback;

  // If fallbackUser is null, the user has been deleted
  if (userFallback === null) {
    return null;
  }

  const isSelf = user.email === currentUser?.email;
  const mutationControlsDisabled = isSelf || user.role === Roles.ADMIN;

  const isCoach = [
    Roles.COACH,
    Roles.EDITOR,
    Roles.HALF_ADMIN,
    Roles.ADMIN
  ].includes(user.role);

  return (
    <UserContext.Provider value={user}>
      <UserWrapper {...props}>
        <UserSelectCheckbox
          checked={selected}
          onChange={onSelectUser}
          color="primary"
          disabled={mutationControlsDisabled}
        />
        <StyledUserAvatar user={user} />
        <UserInfoWrapper>
          <UserName />
          <UserEmail>{user.email}</UserEmail>
        </UserInfoWrapper>
        <GroupSelect asManagedGroups={isCoach} />
        <BasicUserControlsWrapper>
          <RoleSelect disabled={mutationControlsDisabled} />
          {expertSystemEnabled && isCoach && <ExpertiseManager />}
          <DeleteUser
            onMarkAsDeleted={onMarkAsDeleted}
            disabled={mutationControlsDisabled}
          />
        </BasicUserControlsWrapper>
      </UserWrapper>
    </UserContext.Provider>
  );
};

User.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    role: PropTypes.oneOf(Object.values(Roles)).isRequired
  }),
  selected: PropTypes.bool.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  onMarkAsDeleted: PropTypes.func.isRequired
};

export default User;
