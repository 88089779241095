/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useFirebaseData from './useFirebaseData';
import arrToObj from 'lib/utils/arrToObj';
import * as Listeners from './listeners';

const useStatePopulator = ({ firebaseRefs }) => {
  const [firebaseData, updateFirebaseData] = useFirebaseData({});

  const [firebaseRefLoadingStates, setFirebaseRefLoadingStates] = useState(
    arrToObj(firebaseRefs, true)
  );
  const loading = Object.values(firebaseRefLoadingStates).includes(true);

  useEffect(() => {
    const firebaseListeners = Listeners.createStore();

    for (const ref of firebaseRefs) {
      const listener = Listeners.create(value => {
        setFirebaseRefLoadingStates(firebaseRefLoadingStates => ({
          ...firebaseRefLoadingStates,
          [ref]: false
        }));
        updateFirebaseData(ref, value);
      });

      Listeners.attach(ref, listener);
      firebaseListeners.register(ref, listener);
    }

    return () => firebaseListeners.unregisterAll();
  }, [firebaseRefs.toString()]);

  return [loading, firebaseData];
};

export default useStatePopulator;
