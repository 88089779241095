/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { value, challengeRef } from 'lib/firebase';
import { safeKeys } from 'lib/utils/safeObjectFunctions';

const loadChallenge = async challengeKey => {
  try {
    return await value(challengeRef(challengeKey));
  } catch {
    return [challengeKey, null];
  }
};

const useExistingNewlySharedChallengesKeys = currentUser => {
  const allNewlySharedChallengesKeys = safeKeys(currentUser?.newlyShared);

  const [loading, setLoading] = useState(true);
  const [
    existingNewlySharedChallengesKeys,
    setExistingNewlySharedChallengesKeys
  ] = useState([]);
  const [
    unavailableNewlySharedChallengesKeys,
    setUnavailableNewlySharedChallengesKeys
  ] = useState([]);

  useEffect(() => {
    if (allNewlySharedChallengesKeys.length === 0) {
      setLoading(false);
      setExistingNewlySharedChallengesKeys([]);
      return;
    }

    const updateExistingNewlySharedChallengesKeys = async () => {
      const allNewlySharedChallenges = await Promise.all(
        allNewlySharedChallengesKeys.map(loadChallenge)
      );

      const existingNewlySharedChallenges = allNewlySharedChallenges.filter(
        challenge => !!challenge
      );
      const existingNewlySharedChallengesKeys = existingNewlySharedChallenges.map(
        challenge => challenge.key
      );

      const unavailableNewlySharedChallengesKeys = allNewlySharedChallengesKeys.filter(
        challengeKey =>
          !existingNewlySharedChallengesKeys.includes(challengeKey)
      );

      setLoading(false);
      setExistingNewlySharedChallengesKeys(existingNewlySharedChallengesKeys);
      setUnavailableNewlySharedChallengesKeys(
        unavailableNewlySharedChallengesKeys
      );
    };

    updateExistingNewlySharedChallengesKeys();
  }, [JSON.stringify(allNewlySharedChallengesKeys)]);

  return [
    loading,
    existingNewlySharedChallengesKeys,
    unavailableNewlySharedChallengesKeys
  ];
};

export default useExistingNewlySharedChallengesKeys;
