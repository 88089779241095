import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const SearchWrapper = styled('form')(
  ({ theme }) => ({
    marginLeft: -36,
    fontFamily: 'CircularStd',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 24,
    transition: theme.transitions.create(),
    [mediaQuery(850)]: {
      display: 'none'
    }
  }),
  ({ theme, collapsed }) => {
    if (!collapsed) {
      return {
        marginLeft: 0,
        paddingLeft: 18,
        backgroundColor: theme.palette.action.hover
      };
    }
  },
  addHighContrastStyles(({ theme, collapsed }) => {
    if (!collapsed) {
      return {
        backgroundColor: theme.palette.background.default,
        border: `2px solid ${theme.palette.divider}`
      };
    }
  })
);

SearchWrapper.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default SearchWrapper;
