import PropTypes from 'prop-types';
import App from 'lib/App';
import ProgressIndicator from './ProgressIndicator';
import IndicatorLabel from './IndicatorLabel';

const ExpertProgressIndicator = ({ expertiseKey, ...props }) => {
  const reversed = App.instanceConfig.useContentSetting('useReversedSliders');
  return (
    <ProgressIndicator reversed={reversed} {...props}>
      <IndicatorLabel expertiseKey={expertiseKey} />
    </ProgressIndicator>
  );
};

ExpertProgressIndicator.propTypes = {
  progress: PropTypes.number.isRequired,
  expertiseKey: PropTypes.string,
  sliding: PropTypes.bool.isRequired
};

export default ExpertProgressIndicator;
