import styled from '@emotion/styled';
import { Add as UnstyledAddIcon } from '@material-ui/icons';
import LevelTitle from 'components/RubricsPortfolioComponents/StyledLevel/LevelTitle';

const AddIcon = styled(UnstyledAddIcon)(({ theme }) => ({
  marginBottom: 12,
  padding: 6,
  color: theme.palette.getContrastText(LevelTitle.computeColor(theme)),
  backgroundColor: LevelTitle.computeColor(theme),
  borderRadius: '50%'
}));

export default AddIcon;
