/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import LoadingSpinnerWrapper from './LoadingSpinnerWrapper';
import LoadingSpinner from 'lib/components/LoadingSpinner';
import FormWrapper from './FormWrapper';
import { TextField } from '@material-ui/core';
import SaveButton from './SaveButton';
import { Check as CheckIcon } from '@material-ui/icons';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';
import { useIntl, FormattedMessage } from 'react-intl';

const IndividualSettingsFieldForm = ({
  id,
  value,
  onChange,
  firebaseRef,
  ...props
}) => {
  const intl = useIntl();
  const [firebaseValueLoading, firebaseValue] = useFirebaseValue(firebaseRef);

  useEffect(() => {
    onChange(firebaseValue || '');
  }, [firebaseValue]);

  if (firebaseValueLoading) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner centered />
      </LoadingSpinnerWrapper>
    );
  }

  const saveValue = e => {
    e.preventDefault();
    firebaseRef.set(value);
  };

  return (
    <FormWrapper onSubmit={saveValue} {...props}>
      <TextField
        variant="outlined"
        margin="dense"
        placeholder={intl.formatMessage({
          id: `editor.model_editor.field_editor.learning_goals_editor.settings.fields.${id}.placeholder`
        })}
        helperText={
          <FormattedMessage
            id={`editor.model_editor.field_editor.learning_goals_editor.settings.fields.${id}.helper_text`}
          />
        }
        value={value}
        onChange={e => onChange(e.target.value)}
        fullWidth
      />
      <SaveButton type="submit" disabled={(firebaseValue || '') === value}>
        <CheckIcon />
      </SaveButton>
    </FormWrapper>
  );
};

IndividualSettingsFieldForm.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  firebaseRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired
};

export default IndividualSettingsFieldForm;
