/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

const usePhaseShifting = phaseCount => {
  const [phaseShifts, setPhaseShifts] = useState(0);
  const [canShiftPhasesLeft, setCanShiftPhasesLeft] = useState(false);
  const [canShiftPhasesRight, setCanShiftPhasesRight] = useState(true);

  const [phasesWrapperWidth, setPhasesWrapperWidth] = useState(0);

  const detemineShiftAvailability = () => {
    setCanShiftPhasesLeft(phaseShifts > 0);

    const phaseWidth = phasesWrapperWidth / phaseCount;
    const phasessLeftOffset = -phaseShifts * phaseWidth + 72;
    const phasesWrapperOverflows =
      phasessLeftOffset + phasesWrapperWidth > window.innerWidth;

    setCanShiftPhasesRight(phasesWrapperOverflows);
  };

  useEffect(() => {
    detemineShiftAvailability();
  }, [phaseShifts, phasesWrapperWidth]);

  const phasesWrapperRef = useRef(null);
  const { ref: outerWrapperRef } = useResizeObserver({
    onResize: () => {
      detemineShiftAvailability();

      if (!phasesWrapperRef.current) {
        return;
      }

      const newPhasesWrapperWidth = phasesWrapperRef.current.getBoundingClientRect()
        .width;

      if (newPhasesWrapperWidth !== phasesWrapperWidth) {
        setPhasesWrapperWidth(newPhasesWrapperWidth);
      }
    }
  });

  const shiftPhasesLeft = () => {
    setPhaseShifts(phaseShifts => {
      if (phaseShifts === 0) {
        return phaseShifts;
      }

      return phaseShifts - 1;
    });
  };

  const shiftPhasesRight = () => {
    setPhaseShifts(phaseShifts => {
      if (phaseShifts === phaseCount - 1) {
        return phaseShifts;
      }

      return phaseShifts + 1;
    });
  };

  return {
    outerWrapperRef,
    phasesWrapperRef,
    phaseShifts,
    canShiftPhasesLeft,
    canShiftPhasesRight,
    shiftPhasesLeft,
    shiftPhasesRight
  };
};

export default usePhaseShifting;
