import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ArrowDropDown as DropdownArrowIcon } from '@material-ui/icons';
import isPropValid from '@emotion/is-prop-valid';

const GroupSelectorButtonDropdownArrow = styled(DropdownArrowIcon, {
  shouldForwardProp: isPropValid
})(({ theme, rotated }) => ({
  marginLeft: 'auto',
  minWidth: 24,
  minHeight: 24,
  color: theme.palette.action.active,
  transition: theme.transitions.create(),
  transform: rotated ? 'rotate(180deg)' : 'none'
}));

GroupSelectorButtonDropdownArrow.propTypes = {
  rotated: PropTypes.bool.isRequired
};

export default GroupSelectorButtonDropdownArrow;
