import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import moment from 'moment';

const StyledDate = styled('h4')(({ theme }) => ({
  marginBottom: 0,
  color: theme.palette.text.secondary,
  fontWeight: 400
}));

const FeedbackItemDate = ({ timestamp, ...props }) => (
  <StyledDate>{moment(timestamp).format('L')}</StyledDate>
);

FeedbackItemDate.propTypes = {
  timestamp: PropTypes.number.isRequired
};

export default FeedbackItemDate;
