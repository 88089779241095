import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';

const ExpertProgressIndicator = styled('div')(
  ({ theme, progress, reversed }) => ({
    position: 'absolute',
    left: reversed ? null : `${progress}%`,
    right: reversed ? `${progress}%` : null,
    transform: 'translateX(-50%)',
    width: 2,
    height: '100%',
    backgroundColor: theme.palette.text.primary,
    transition: theme.transitions.create(),
    zIndex: 3
  }),
  addHighContrastStyles(({ theme }) => ({
    width: 4,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.background.default}`
  }))
);

ExpertProgressIndicator.propTypes = {
  progress: PropTypes.number.isRequired,
  reversed: PropTypes.bool.isRequired
};

export default ExpertProgressIndicator;
