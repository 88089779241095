import PropTypes from 'prop-types';
import getLanguage from 'lib/utils/getLanguage';
import LanguageItem from './LanguageItem';

const LanguagePicker = ({ onCloseSubmenu }) => {
  const selectedLanguage = getLanguage();

  const setSelectedLanguage = newLanguage => {
    onCloseSubmenu();

    // Give the submenu time to close
    setTimeout(() => {
      localStorage.setItem('language', newLanguage);
      window.location.reload();
    }, 400);
  };

  return (
    <>
      <LanguageItem
        language="nl"
        languageName="Nederlands"
        languageNameTranslated="Dutch"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <LanguageItem
        language="en"
        languageName="English"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
      <LanguageItem
        language="fr"
        languageName="Français"
        languageNameTranslated="French"
        selectedLanguage={selectedLanguage}
        setSelectedLanguage={setSelectedLanguage}
      />
    </>
  );
};

LanguagePicker.propTypes = {
  onCloseSubmenu: PropTypes.func.isRequired
};

export default LanguagePicker;
