import CollapsibleActionMenu from 'lib/components/CollapsibleActionMenu';
import { FormattedMessage } from 'react-intl';

const DeleteRowButton = props => (
  <CollapsibleActionMenu.DeleteButton {...props}>
    <FormattedMessage id="portfolio.row.mobile_row_actions.delete_button" />
  </CollapsibleActionMenu.DeleteButton>
);

export default DeleteRowButton;
