import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import FieldContext from '../../../FieldContext';
import StyledLogEntry from './StyledLogEntry';
import TextEditor from '../../utils/TextEditor';
import HtmlRenderer from '../../utils/HtmlRenderer';
import LogEntryHeader from './LogEntryHeader';
import LogEntryUser from './LogEntryUser';
import LogEntryActionsWrapper from './LogEntryActionsWrapper';
import EditButton from './EditButton';
import DeleteLogEntry from './DeleteLogEntry';
import LogEntryDivider from './LogEntryDivider';
import Space from 'lib/components/Space';

const LogEntry = ({ logEntry, isLast, ...props }) => {
  const { fieldRef } = useContext(FieldContext);

  const [editing, setEditing] = useState(false);

  const logEntryRef = fieldRef.child(logEntry.key);

  const handleSave = value => {
    setEditing(false);

    if (value === null) {
      return;
    }

    logEntryRef.update({
      content: value
    });
  };

  return (
    <StyledLogEntry className="log-entry" {...props}>
      <LogEntryHeader>
        <LogEntryUser logEntry={logEntry} />
        {!editing && (
          <LogEntryActionsWrapper>
            <EditButton onClick={() => setEditing(true)} />
            <DeleteLogEntry logEntryRef={logEntryRef} />
          </LogEntryActionsWrapper>
        )}
      </LogEntryHeader>
      {editing ? (
        <>
          <Space height={18} />
          <TextEditor
            initialValue={logEntry.content}
            onCancel={() => setEditing(false)}
            onSave={handleSave}
            disableSaveWhenEmpty
          />
        </>
      ) : (
        <HtmlRenderer source={logEntry.content} darkText />
      )}
      {!isLast && <LogEntryDivider />}
    </StyledLogEntry>
  );
};

LogEntry.propTypes = {
  logEntry: PropTypes.shape({
    key: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    timestamp: PropTypes.number.isRequired
  }).isRequired,
  isLast: PropTypes.bool.isRequired
};

export default LogEntry;
