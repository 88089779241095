import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import FeedbackFieldsWrapper from './FeedbackFieldsWrapper';
import FeedbackField from './FeedbackField';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Space from 'lib/components/Space';
import BackButton from './BackButton';

const SloPeriodFeedback = ({ onClose, ...props }) => {
  const isMobile = useMediaQuery('(max-width: 460px)');
  return (
    <>
      <FeedbackFieldsWrapper {...props}>
        <FeedbackField variant={PortfolioModes.COACH} />
        {isMobile ? <Space height={18} /> : <Space width={24} />}
        <FeedbackField variant={PortfolioModes.STUDENT} />
      </FeedbackFieldsWrapper>
      <Space height={12} />
      <BackButton onClick={onClose} />
    </>
  );
};

SloPeriodFeedback.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SloPeriodFeedback;
