const stackDuplicateExpertProgress = expertProgress => {
  const groupedExpertProgress = [];
  const normalExpertProgress = [];

  const expertProgressPerProgressValue = {};

  for (const expertProgressItem of Object.values(expertProgress)) {
    expertProgressPerProgressValue[expertProgressItem.progress] = [
      ...(expertProgressPerProgressValue[expertProgressItem.progress] || []),
      expertProgressItem
    ];
  }

  const expertProgressSets = Object.values(expertProgressPerProgressValue);

  for (const expertProgressSet of expertProgressSets) {
    if (expertProgressSet.length === 1) {
      const expertProgressItem = expertProgressSet[0];
      normalExpertProgress.push(expertProgressItem);
      continue;
    }

    groupedExpertProgress.push(expertProgressSet);
  }

  const expertProgressStacks = groupedExpertProgress.map(expertProgressSet => ({
    key: `stack-${expertProgressSet[0].progress}`,
    progress: expertProgressSet[0].progress,
    expertiseKeys: expertProgressSet.map(
      expertProgressItem => expertProgressItem.expertiseKey
    )
  }));

  return {
    normalExpertProgress,
    expertProgressStacks
  };
};

export default stackDuplicateExpertProgress;
