import PropTypes from 'prop-types';
import usePhaseSwitching from './usePhaseSwitching';
import Phases from 'lib/constants/Phases';
import HeaderContentWrapper from '../HeaderContentWrapper';
import PhaseSelectIconButton from './PhaseSelectIconButton';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';
import PhaseName from '../PhaseName';
import phaseName from 'lib/utils/phaseName';

const MobilePhaseHeader = ({ inTaskBoard, ...props }) => {
  const [
    visiblePhase,
    switchToPreviousPhase,
    switchToNextPhase
  ] = usePhaseSwitching(inTaskBoard);

  const previousPhaseButtonHidden = visiblePhase === Phases.TODO;
  const nextPhaseButtonHidden = visiblePhase === Phases.DONE;

  return (
    <HeaderContentWrapper {...props}>
      <PhaseSelectIconButton
        onClick={switchToPreviousPhase}
        hidden={previousPhaseButtonHidden}
      >
        <ArrowLeftIcon />
      </PhaseSelectIconButton>
      <PhaseName>{phaseName(visiblePhase)}</PhaseName>
      <PhaseSelectIconButton
        onClick={switchToNextPhase}
        hidden={nextPhaseButtonHidden}
      >
        <ArrowRightIcon />
      </PhaseSelectIconButton>
    </HeaderContentWrapper>
  );
};

MobilePhaseHeader.propTypes = {
  inTaskBoard: PropTypes.bool
};

MobilePhaseHeader.defaultProps = {
  inTaskBoard: false
};

export default MobilePhaseHeader;
