import Dialog from 'lib/components/Dialog';

const ColorsManagerDialog = props => (
  <Dialog
    PaperProps={{
      style: {
        maxWidth: 480
      }
    }}
    {...props}
  />
);

export default ColorsManagerDialog;
