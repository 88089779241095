import PageTitle from 'lib/components/PageTitle';
import BoxWrapper from './BoxWrapper';
import LoginSettings from './LoginSettings';
import GeneralSettings from './GeneralSettings';

const PlatformSettingsPage = () => (
  <>
    <PageTitle id="platform_settings.page_title" />
    <BoxWrapper>
      <LoginSettings />
      <GeneralSettings />
    </BoxWrapper>
  </>
);

export default PlatformSettingsPage;
