import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import useChallengeName from './useChallengeName';
import PillButtons from '../../../../PillButtons';
import { Link as LinkIcon } from '@material-ui/icons';
import ChallengeName from './ChallengeName';

const OpenAttachedChallenge = ({
  challengeKey,
  challengeUrl,
  asTask,
  ...props
}) => {
  const portfolioMode = useContext(PortfolioModeContext);

  const history = useHistory();
  const challengeName = useChallengeName(challengeKey, asTask);

  return (
    <PillButtons.Button
      onClick={() => history.push(challengeUrl)}
      leftHalf={portfolioMode === PortfolioModes.STUDENT}
      standAlone={portfolioMode === PortfolioModes.COACH}
      noStandAloneMargin
    >
      <LinkIcon />
      {challengeName && <ChallengeName>{challengeName}</ChallengeName>}
    </PillButtons.Button>
  );
};

OpenAttachedChallenge.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  challengeUrl: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default OpenAttachedChallenge;
