import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PhaseContext from '../../../../PhaseContext';
import Phases from 'lib/constants/Phases';
import PhaseSelectWrapper from './PhaseSelectWrapper';
import PhaseSelectField from './PhaseSelectField';
import { MenuItem, IconButton } from '@material-ui/core';
import phaseName from 'lib/utils/phaseName';
import Space from 'lib/components/Space';
import { Check as CheckIcon } from '@material-ui/icons';
import { challengeRef as getChallengeRef, rowRef } from 'lib/firebase';
import fixChallengeDates from 'lib/utils/fixChallengeDates';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const PhaseSelect = ({
  challenge,
  challengeRef,
  parentKey,
  asTask,
  ...props
}) => {
  const originalPhase = useContext(PhaseContext);
  const [selectedPhase, setSelectedPhase] = useState(originalPhase);

  const savePhaseChange = () => {
    if (selectedPhase === originalPhase) {
      return;
    }

    const phaseChangeWithDateCorrections = fixChallengeDates({
      phase: selectedPhase
    });

    challengeRef.update(phaseChangeWithDateCorrections);

    const getParentRef = asTask ? getChallengeRef : rowRef;
    const childrenPath = asTask ? 'tasks' : 'challenges';
    const childrenRef = getParentRef(parentKey).child(childrenPath);

    childrenRef.child(originalPhase).child(challenge.key).remove();
    childrenRef.child(selectedPhase).child(challenge.key).set(true);
  };

  return (
    <PhaseSelectWrapper {...props}>
      <PhaseSelectField
        value={selectedPhase}
        onChange={e => setSelectedPhase(e.target.value)}
      >
        {Object.values(Phases).map(phase => (
          <MenuItem value={phase} key={phase}>
            {phaseName(phase)}
          </MenuItem>
        ))}
      </PhaseSelectField>
      {selectedPhase !== originalPhase && (
        <>
          <Space width={6} />
          <IconButton onClick={savePhaseChange}>
            <CheckIcon />
          </IconButton>
        </>
      )}
    </PhaseSelectWrapper>
  );
};

PhaseSelect.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired
  }),
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  parentKey: PropTypes.string.isRequired,
  asTask: PropTypes.bool.isRequired
};

export default PhaseSelect;
