import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import stripProps from 'lib/utils/stripProps';
import { Clear as ClearIcon } from '@material-ui/icons';

const StyledButton = styled(stripProps(IconButton, 'visible'))(
  ({ theme, visible }) => ({
    transform: visible ? 'scale(1)' : 'scale(0)',
    transition: theme.transitions.create()
  })
);

const ClearButton = props => (
  <StyledButton edge="end" {...props}>
    <ClearIcon />
  </StyledButton>
);

ClearButton.propTypes = {
  visible: PropTypes.bool.isRequired
};

export default ClearButton;
