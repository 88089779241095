import { useState } from 'react';
import PropTypes from 'prop-types';
import TileGrid from 'components/LearningGoals/TileGrid';
import { Edit as EditIcon } from '@material-ui/icons';
import PromptWithColors from '../../../PromptWithColors';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const EditPart = ({ part, ...props }) => {
  const [editPromptOpen, setEditPromptOpen] = useState(false);

  const saveEdits = (title, color) => {
    setEditPromptOpen(false);

    ref(`portfolioTemplates/parts/${part.id}`).update({
      title,
      color
    });
  };

  return (
    <>
      <TileGrid.Tile.ActionButton
        onClick={() => setEditPromptOpen(true)}
        {...props}
      >
        <EditIcon />
      </TileGrid.Tile.ActionButton>
      <PromptWithColors
        open={editPromptOpen}
        defaultValue={part.title}
        color={part.color}
        textFieldLabel={
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.edit.input_label" />
        }
        onOk={saveEdits}
        onCancel={() => setEditPromptOpen(false)}
        persistInput
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.edit.title" />
        </Dialog.Title>
      </PromptWithColors>
    </>
  );
};

EditPart.propTypes = {
  part: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string
  }).isRequired
};

export default EditPart;
