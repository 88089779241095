import { useContext } from 'react';
import PropTypes from 'prop-types';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import { Route, Redirect } from 'react-router-dom';
import PlatformTypes from 'lib/constants/PlatformTypes';

const SelectedPlatformOverrideRoute = ({ path, platform, ...props }) => {
  const [, setSelectedPlatform] = useContext(SelectedPlatformContext);
  return (
    <Route
      path={path}
      render={({ location }) => {
        setSelectedPlatform(platform);

        const redirectPath = location.pathname.replace(path, '');
        return <Redirect to={redirectPath} />;
      }}
      {...props}
    />
  );
};

SelectedPlatformOverrideRoute.propTypes = {
  path: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(Object.values(PlatformTypes)).isRequired
};

export default SelectedPlatformOverrideRoute;
