import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import LegendOuterWrapper from './LegendOuterWrapper';
import LegendItems from './LegendItems';
import LegendItem from './LegendItem';
import Color from './Color';
import StyledFocusIcon from './StyledFocusIcon';
import GapminderButton from './GapminderButton';
import { FormattedMessage } from 'react-intl';

const ColorLegend = ({
  portfolioHistoryLoading,
  onOpenGapminder,
  ...props
}) => {
  const theme = useTheme();
  return (
    <LegendOuterWrapper {...props}>
      <LegendItems>
        <LegendItem>
          <Color color={theme.palette.sliders.student} />
          <FormattedMessage id="portfolio.color_legend.student" />
        </LegendItem>
        <LegendItem>
          <Color color={theme.palette.sliders.coach} />
          <FormattedMessage id="portfolio.color_legend.coach" />
        </LegendItem>
        <LegendItem>
          <Color color={theme.palette.sliders.both} />
          <FormattedMessage id="portfolio.color_legend.both" />
        </LegendItem>
        <LegendItem>
          <StyledFocusIcon />
          <FormattedMessage id="portfolio.color_legend.focus" />
        </LegendItem>
      </LegendItems>
      <GapminderButton
        onClick={onOpenGapminder}
        disabled={portfolioHistoryLoading}
      />
    </LegendOuterWrapper>
  );
};

ColorLegend.propTypes = {
  portfolioHistoryLoading: PropTypes.bool.isRequired,
  onOpenGapminder: PropTypes.func.isRequired
};

export default ColorLegend;
