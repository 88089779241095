import { useState } from 'react';
import LoginScreenPageTitle from './LoginScreenPageTitle';
import BackgroundSlideshowPlaceholder from './BackgroundSlideshowPlaceholder';
import BackgroundSlideshow from './BackgroundSlideshow';
import LoginCard from './LoginCard';
import LoginHeader from './LoginHeader';
import LoginErrorUnfoldingWrapper from './LoginErrorUnfoldingWrapper';
import LoginContent from './LoginContent';
import LoginWelcomeTitle from './LoginWelcomeTitle';
import LoginButtonWithHandlers from './LoginButtonWithHandlers';
import AllowedEmailText from './AllowedEmailText';
import AllowedEmailsMessage from './AllowedEmailsMessage';
import EmailNotAllowedErrorScreen from './EmailNotAllowedErrorScreen';
import PushFooterBelowInitialViewport from './PushFooterBelowInitialViewport';

const LoginScreen = props => {
  const [showEmailNotAllowedError, setShowEmailNotAllowedError] = useState(
    false
  );

  return (
    <>
      <LoginScreenPageTitle />
      <BackgroundSlideshowPlaceholder />
      <BackgroundSlideshow />
      <LoginCard {...props}>
        <LoginHeader />
        <LoginErrorUnfoldingWrapper
          showEmailNotAllowedError={showEmailNotAllowedError}
        >
          <LoginContent
            visible={!showEmailNotAllowedError}
            aria-hidden={showEmailNotAllowedError}
          >
            <LoginWelcomeTitle />
            <LoginButtonWithHandlers
              onShowEmailNotAllowedError={() =>
                setShowEmailNotAllowedError(true)
              }
              disabled={showEmailNotAllowedError}
            />
            <AllowedEmailText>
              <AllowedEmailsMessage />
            </AllowedEmailText>
          </LoginContent>
          <EmailNotAllowedErrorScreen
            visible={showEmailNotAllowedError}
            onDismissEmailNotAllowedError={() =>
              setShowEmailNotAllowedError(false)
            }
            aria-hidden={!showEmailNotAllowedError}
          />
        </LoginErrorUnfoldingWrapper>
      </LoginCard>
      <PushFooterBelowInitialViewport />
    </>
  );
};

export default LoginScreen;
