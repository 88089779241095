import { useState } from 'react';

const disallowedCharactersRegex = /[^0123456789ABCDEF]/g;

const sanitizeColor = color =>
  color.toUpperCase().replaceAll(disallowedCharactersRegex, '').slice(0, 6);

const useColorState = defaultColor => {
  const [color, setColor] = useState(sanitizeColor(defaultColor || ''));

  const changeColor = newColor => {
    setColor(sanitizeColor(newColor));
  };

  return [color, changeColor];
};

export default useColorState;
