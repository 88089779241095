import { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import { taskRef } from 'lib/firebase';
import StyledAddButton from './StyledAddButton';
import { AssignmentInd as AssigneeIcon } from '@material-ui/icons';
import PortfolioModes from 'lib/constants/PortfolioModes';
import Dialog from 'lib/components/Dialog';
import { List } from '@material-ui/core';
import UserListItem from './UserListItem';
import { FormattedMessage } from 'react-intl';

const AddAssignees = ({ task, challengeUsers, ...props }) => {
  const portfolioMode = useContext(PortfolioModeContext);
  const [dialogOpen, setDialogOpen] = useState(false);

  const assignees = safeKeys(task.assignees);

  const handleUserClick = username => () => {
    const assigneeRef = taskRef(task.key).child(`assignees/${username}`);

    if (assignees.includes(username)) {
      assigneeRef.remove();
      return;
    }

    assigneeRef.set(true);
  };

  const closeDialog = () => setDialogOpen(false);

  return (
    <>
      <StyledAddButton
        color="primary"
        onClick={() => setDialogOpen(true)}
        startIcon={<AssigneeIcon />}
        disabled={portfolioMode === PortfolioModes.COACH}
      >
        <FormattedMessage id="portfolio.task.assignment.add_assignees.button" />
      </StyledAddButton>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        onEnterClose={closeDialog}
      >
        <Dialog.Title>
          <FormattedMessage id="portfolio.task.assignment.add_assignees.title" />
        </Dialog.Title>
        <List>
          {challengeUsers.sort().map(username => (
            <UserListItem
              username={username}
              selected={assignees.includes(username)}
              onClick={handleUserClick(username)}
              key={username}
            />
          ))}
        </List>
        <Dialog.Actions>
          <Dialog.Actions.DoneButton onClick={closeDialog} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

AddAssignees.propTypes = {
  task: PropTypes.shape({
    key: PropTypes.string.isRequired,
    assignees: PropTypes.objectOf(PropTypes.bool)
  }).isRequired,
  challengeUsers: PropTypes.arrayOf(PropTypes.string)
};

export default AddAssignees;
