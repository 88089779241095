import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const FieldNameWrapper = styled('header')(({ theme, secondary }) => ({
  marginBottom: 8,
  color: secondary ? theme.palette.text.disabled : theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center'
}));

FieldNameWrapper.propTypes = {
  secondary: PropTypes.bool
};

FieldNameWrapper.defaultProps = {
  secondary: false
};

export default FieldNameWrapper;
