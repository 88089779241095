import { useContext } from 'react';
import FieldTypes from 'lib/constants/challengeModel/FieldTypes';
import FieldContext from '../FieldContext';

const fieldTypesWithoutFullWidthOnMobile = [
  FieldTypes.DATE,
  FieldTypes.MANUAL_PLANNED_START_DATE,
  FieldTypes.MANUAL_PLANNED_END_DATE,
  FieldTypes.MANUAL_ACTUAL_START_DATE,
  FieldTypes.MANUAL_ACTUAL_END_DATE
];

const useIsFullWidthOnMobile = () => {
  const { field } = useContext(FieldContext);
  return !fieldTypesWithoutFullWidthOnMobile.includes(field.type);
};

export default useIsFullWidthOnMobile;
