import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioModeContext from 'lib/contexts/PortfolioModeContext';
import PortfolioModes from 'lib/constants/PortfolioModes';
import AttachedChallenges from './AttachedChallenges';
import AttachChallenge from './AttachChallenge';

const ChallengeAttachmentManager = ({ levelKey, challenge }) => {
  const portfolioMode = useContext(PortfolioModeContext);
  return (
    <>
      <AttachedChallenges levelKey={levelKey} />
      {portfolioMode === PortfolioModes.STUDENT && !!challenge && (
        <AttachChallenge levelKey={levelKey} challenge={challenge} />
      )}
    </>
  );
};

ChallengeAttachmentManager.propTypes = {
  levelKey: PropTypes.string.isRequired,
  challenge: PropTypes.object
};

export default ChallengeAttachmentManager;
