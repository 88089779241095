import { useContext } from 'react';
import PropTypes from 'prop-types';
import useCreateChallenge from './useCreateChallenge';
import PhaseContext from '../../../../PhaseContext';
import CreateItem from '../CreateItem';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreateChallenge = ({ row, ...props }) => {
  const createChallenge = useCreateChallenge();
  const phase = useContext(PhaseContext);
  return (
    <CreateItem
      tooltip={
        <FormattedMessageWithChallengeNaming id="portfolio.create_challenge.tooltip" />
      }
      onSave={name => createChallenge(name, phase, row.key)}
      {...props}
    />
  );
};

CreateChallenge.propTypes = {
  row: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired
};

export default CreateChallenge;
