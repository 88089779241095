import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledFieldName = styled('h4')(({ bold }) => ({
  marginBottom: 0,
  fontWeight: bold ? 500 : 400
}));

StyledFieldName.propTypes = {
  bold: PropTypes.bool
};

StyledFieldName.defaultProps = {
  bold: true
};

export default StyledFieldName;
