import PropTypes from 'prop-types';
import useSortedVisibleFields from './useSortedVisibleFields';
import FieldContext from './FieldContext';
import FieldWrapper from './FieldWrapper';
import ModelTypes from 'lib/constants/challengeModel/ModelTypes';
import ModelPropTypes from 'pages/EditorPage/ChallengeModelEditor/ModelEditorForModelType/ModelPropTypes';
import IndividualFieldRenderer from './IndividualFieldRenderer';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';
import FirebaseDatabaseReference from 'lib/firebase/FirebaseDatabaseReference';

const FieldRenderer = ({ fields, challengeRef, noCoachFeedback, ...props }) => {
  const sortedVisibleFields = useSortedVisibleFields(fields);
  return sortedVisibleFields.map(field => (
    <FieldContext.Provider
      value={{
        field,
        fieldRef: challengeRef.child(field.key),
        challengeRef,
        ...props
      }}
      key={field.key}
    >
      <FieldWrapper noCoachFeedback={noCoachFeedback}>
        <IndividualFieldRenderer />
      </FieldWrapper>
    </FieldContext.Provider>
  ));
};

FieldRenderer.propTypes = {
  fields: PropTypes.objectOf(ModelPropTypes.field),
  phaseContentGroup: PropTypes.oneOf(Object.values(PhaseContentGroups))
    .isRequired,
  challenge: PropTypes.object.isRequired,
  challengeRef: PropTypes.instanceOf(FirebaseDatabaseReference).isRequired,
  asTask: PropTypes.bool.isRequired,
  modelType: PropTypes.oneOf([ModelTypes.CHALLENGE, ModelTypes.TASK])
    .isRequired,
  noCoachFeedback: PropTypes.bool
};

FieldRenderer.defaultProps = {
  noCoachFeedback: false
};

export default FieldRenderer;
