import PageTitle from 'lib/components/PageTitle';
import NotFoundTitle from './NotFoundTitle';
import NotFoundText from './NotFoundText';
import StyledHomeButton from './StyledHomeButton';
import { Link } from 'react-router-dom';
import { Undo as BackIcon } from '@material-ui/icons';
import NotFoundImage from './NotFoundImage';
import { FormattedMessage } from 'react-intl';

const NotFoundPage = () => (
  <>
    <PageTitle id="not_found.page_title" />
    <NotFoundTitle>
      <FormattedMessage id="not_found.not_found_title" />
    </NotFoundTitle>
    <NotFoundText>
      <FormattedMessage id="not_found.not_found_text" />
    </NotFoundText>
    <StyledHomeButton
      variant="contained"
      color="primary"
      disableElevation
      startIcon={<BackIcon />}
      component={Link}
      to="/"
    >
      <FormattedMessage id="not_found.home_button" />
    </StyledHomeButton>
    <NotFoundImage />
  </>
);

export default NotFoundPage;
