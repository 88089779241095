import PropTypes from 'prop-types';
import useActivePlatformIcons from 'lib/utils/useActivePlatformIcons';
import PlatformTypes from 'lib/constants/PlatformTypes';
import { useIntl } from 'react-intl';

const iconStyles = {
  width: 24,
  height: 24
};

const PlatformIcon = ({ platformType, ...props }) => {
  const activePlatformIcons = useActivePlatformIcons();
  const desiredPlatformIcon = activePlatformIcons[platformType];

  const intl = useIntl();
  return (
    <img
      src={desiredPlatformIcon}
      style={iconStyles}
      alt={intl.formatMessage({
        id: 'header.platform_switch.button.icon.alt'
      })}
      {...props}
    />
  );
};

PlatformIcon.propTypes = {
  platformType: PropTypes.oneOf(Object.values(PlatformTypes)).isRequired
};

export default PlatformIcon;
