import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import LearningGoalsModelContext from '../LearningGoalsModelContext';
import TileGrid from 'components/LearningGoals/TileGrid';
import PromptWithColors from '../PromptWithColors';
import Dialog from 'lib/components/Dialog';
import { ref } from 'lib/firebase';
import { FormattedMessage } from 'react-intl';

const AddSubtopic = ({ topicKey, ...props }) => {
  const learningGoalsModel = useContext(LearningGoalsModelContext);
  const [addPromptOpen, setAddPromptOpen] = useState(false);

  const saveNewSubtopic = (name, color) => {
    setAddPromptOpen(false);

    const learningGoalsModelRef = ref(
      `models/learningGoals/${learningGoalsModel.key}`
    );

    const subtopicKey = learningGoalsModelRef.child('subtopics').push({
      name,
      color,
      topicKey
    }).key;

    learningGoalsModelRef
      .child(`topics/${topicKey}/subtopics/${subtopicKey}`)
      .set(true);
  };

  return (
    <>
      <TileGrid.AddButton onClick={() => setAddPromptOpen(true)} {...props}>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_subtopic.button" />
      </TileGrid.AddButton>
      <PromptWithColors
        open={addPromptOpen}
        textFieldLabel={
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_subtopic.input_label" />
        }
        onOk={saveNewSubtopic}
        onCancel={() => setAddPromptOpen(false)}
        OkButton={Dialog.Actions.CreateButton}
      >
        <Dialog.Title>
          <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_subtopic.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.add_subtopic.text" />
          </Dialog.ContentText>
        </Dialog.Content>
      </PromptWithColors>
    </>
  );
};

AddSubtopic.propTypes = {
  topicKey: PropTypes.string.isRequired
};

export default AddSubtopic;
