import { useState } from 'react';
import PropTypes from 'prop-types';
import useDeleteUser from '../utils/useDeleteUser';
import DangerousIconButton from 'lib/components/DangerousIconButton';
import { Delete as DeleteIcon } from '@material-ui/icons';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

const DeleteUsers = ({
  usernames,
  users,
  onSelectedUsersClear,
  onMarkUserAsDeleted,
  ...props
}) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const deleteUserFromDatabase = useDeleteUser();

  const deleteUser = username => {
    const user = users[username];
    return deleteUserFromDatabase(user);
  };

  const deleteUsers = async () => {
    setConfirmationDialogOpen(false);

    await Promise.all(usernames.map(deleteUser));
    usernames.forEach(onMarkUserAsDeleted);
    onSelectedUsersClear();
  };

  return (
    <>
      <DangerousIconButton
        onClick={() => setConfirmationDialogOpen(true)}
        {...props}
      >
        <DeleteIcon />
      </DangerousIconButton>
      <Dialog
        open={confirmationDialogOpen}
        onClose={() => setConfirmationDialogOpen(false)}
        dangerous
      >
        <Dialog.Title>
          <FormattedMessage id="user_manager.user_list.toolbar.delete.title" />
        </Dialog.Title>
        <Dialog.Content>
          <Dialog.ContentText>
            <FormattedHTMLMessage id="user_manager.user_list.toolbar.delete.text" />
          </Dialog.ContentText>
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Actions.CancelButton
            onClick={() => setConfirmationDialogOpen(false)}
          />
          <Dialog.Actions.DeleteButton onClick={deleteUsers} />
        </Dialog.Actions>
      </Dialog>
    </>
  );
};

DeleteUsers.propTypes = {
  usernames: PropTypes.arrayOf(PropTypes.string).isRequired,
  users: PropTypes.objectOf(PropTypes.object).isRequired,
  onSelectedUsersClear: PropTypes.func.isRequired,
  onMarkUserAsDeleted: PropTypes.func.isRequired
};

export default DeleteUsers;
