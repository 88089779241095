import PropTypes from 'prop-types';
import useMarkCoachCommentsAsRead from './useMarkCoachCommentsAsRead';
import UnreadCoachCommentsBadge from './UnreadCoachCommentsBadge';

const UnreadCoachCommentsBadgeAndHandling = ({
  challenge,
  asTask,
  collapsed,
  ...props
}) => {
  const markCoachCommentsAsRead = useMarkCoachCommentsAsRead(
    challenge.key,
    asTask
  );

  return (
    <UnreadCoachCommentsBadge
      unreadCoachCommentsCount={challenge.unreadCoachCommentsCount}
      onClick={collapsed ? null : markCoachCommentsAsRead}
      collapsed={collapsed}
      {...props}
    />
  );
};

UnreadCoachCommentsBadgeAndHandling.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired,
    unreadCoachCommentsCount: PropTypes.number
  }).isRequired,
  asTask: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired
};

export default UnreadCoachCommentsBadgeAndHandling;
