import PropTypes from 'prop-types';
import App from 'lib/App';
import { useTheme } from '@emotion/react';
import SliderRail from './SliderRail';
import ProgressBar from './ProgressBar';
import ExpertProgressIndicators from './ExpertProgressIndicators';

const PartSlider = ({ progress, ...props }) => {
  const studentProgress = progress?.student || 0;
  const coachProgress = progress?.coach || 0;
  const expertProgress = progress?.experts;

  const progressEqual = studentProgress === coachProgress;

  const useReversedSliders = App.instanceConfig.useContentSetting(
    'useReversedSliders'
  );
  const expertSystemEnabled = App.instanceConfig.useContentSetting(
    'enableExpertSystem'
  );

  const theme = useTheme();

  return (
    <SliderRail {...props}>
      <ProgressBar
        progress={studentProgress}
        color={theme.palette.sliders.student}
        progressEqual={progressEqual}
        onTop={studentProgress <= coachProgress}
        reversed={useReversedSliders}
      />
      <ProgressBar
        progress={coachProgress}
        color={theme.palette.sliders.coach}
        progressEqual={progressEqual}
        onTop={coachProgress <= studentProgress}
        reversed={useReversedSliders}
      />
      {expertSystemEnabled && (
        <ExpertProgressIndicators
          expertProgress={expertProgress}
          reversed={useReversedSliders}
        />
      )}
    </SliderRail>
  );
};

PartSlider.propTypes = {
  progress: PropTypes.shape({
    student: PropTypes.number,
    coach: PropTypes.number
  })
};

export default PartSlider;
