import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Button } from '@material-ui/core';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const StyledButton = styled(Button)(
  ({ theme, fullWidth }) => ({
    minWidth: fullWidth ? null : 240,
    backgroundColor: theme.palette.action.hover,
    fontSize: 16,
    textTransform: 'none',
    ':hover': {
      backgroundColor: theme.palette.action.selected
    }
  }),
  addBorderInHighContrastMode
);

StyledButton.propTypes = {
  fullWidth: PropTypes.bool.isRequired
};

export default StyledButton;
