import weekFromDate from '../../weekFromDate';
import { NOW } from '../index';

const buildTimestampObject = progress => {
  const timestamps = {};

  for (const timestamp in progress) {
    const date = new Date(Number(timestamp));
    const year = date.getFullYear();
    const week = weekFromDate(date);

    const isPrematurelyIndicatingFirstWeek =
      week === 1 && date.getMonth() === 11;

    if (isPrematurelyIndicatingFirstWeek) {
      addTimestampToTimestampObject(timestamps, year, 53, timestamp);
      continue;
    }

    addTimestampToTimestampObject(timestamps, year, week, timestamp);
  }

  return addNowToTimestampObject(timestamps);
};

const addNowToTimestampObject = timestamps => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentWeek = weekFromDate(now) + 1;

  addTimestampToTimestampObject(timestamps, currentYear, currentWeek, NOW);

  return timestamps;
};

const addTimestampToTimestampObject = (timestamps, year, week, timestamp) => {
  if (!timestamps[year]) {
    timestamps[year] = {};
  }

  timestamps[year][week] = timestamp;

  return timestamps;
};

export default buildTimestampObject;
