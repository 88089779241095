import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

const ClearButton = styled(IconButton)({
  marginTop: 4,
  marginBottom: 'auto',
  marginLeft: 6
});

export default ClearButton;
