import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const GroupCountText = ({ groupCount, asManagedGroups }) => {
  const singularTranslationId = asManagedGroups
    ? 'user_manager.user_list.user.group_select.values.managed_groups_singular'
    : 'user_manager.user_list.user.group_select.values.groups_singular';
  const pluralTranslationId = asManagedGroups
    ? 'user_manager.user_list.user.group_select.values.managed_groups_plural'
    : 'user_manager.user_list.user.group_select.values.groups_plural';

  if (groupCount === 1) {
    return <FormattedMessage id={singularTranslationId} />;
  }

  return (
    <FormattedMessage
      id={pluralTranslationId}
      values={{ groupCount: groupCount }}
    />
  );
};

GroupCountText.propTypes = {
  groupCount: PropTypes.number.isRequired,
  asManagedGroups: PropTypes.bool.isRequired
};

export default GroupCountText;
