import styled from '@emotion/styled';
import Paper from '@material-ui/core/Paper';
import mediaQuery from 'lib/styling/mediaQuery';
import addBorderInHighContrastMode from 'lib/styling/addBorderInHighContrastMode';

const LoginCard = styled(Paper)(
  {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: 480,
    maxWidth: 'calc(100% - 50px)',
    lineHeight: 1,
    boxSizing: 'border-box',
    overflow: 'hidden',
    [mediaQuery(768)]: {
      width: 450
    },
    [mediaQuery(450)]: {
      top: 0,
      left: 0,
      transform: 'none',
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      borderRadius: 0
    }
  },
  addBorderInHighContrastMode
);

export default LoginCard;
