import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom';

const LinkListItem = ({ to, selected, modeSwitchOpen, ...props }) => {
  if (to) {
    return <ListItem component={Link} to={to} button {...props} />;
  }

  return <ListItem tabIndex={-1} button {...props} />;
};

const ModeLink = styled(LinkListItem)(
  ({ theme, selected, modeSwitchOpen }) => ({
    padding: '10px 15px',
    display: selected || modeSwitchOpen ? 'block' : 'none'
  })
);

ModeLink.propTypes = {
  to: PropTypes.any,
  selected: PropTypes.bool.isRequired,
  modeSwitchOpen: PropTypes.bool.isRequired
};

export default ModeLink;
