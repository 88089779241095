import { useContext, useState } from 'react';
import PhaseContext from '../PhaseContext';
import HeaderWrapper from './HeaderWrapper';
import PhaseNameEditor from './PhaseNameEditor';
import PhaseName from './PhaseName';
import PhaseUiPosMoving from './PhaseUiPosMoving';
import PhaseActions from './PhaseActions';

const PhaseHeader = props => {
  const { phase } = useContext(PhaseContext);
  const [nameEditing, setNameEditing] = useState(false);

  return (
    <HeaderWrapper {...props}>
      {nameEditing ? (
        <PhaseNameEditor onClose={() => setNameEditing(false)} />
      ) : (
        <>
          <PhaseName>{phase.name}</PhaseName>
          <PhaseUiPosMoving />
          <PhaseActions onNameEdit={() => setNameEditing(true)} />
        </>
      )}
    </HeaderWrapper>
  );
};

export default PhaseHeader;
