import { useContext } from 'react';
import ModelTypeContext from '../../../ModelTypeContext';
import PhaseContext from '../../PhaseContext';
import { ref } from 'lib/firebase';

const usePhaseMoving = () => {
  const modelType = useContext(ModelTypeContext);
  const { phase, previousPhase, nextPhase } = useContext(PhaseContext);

  const phasesRef = ref(`models/${modelType}/phases`);
  const updatePhaseUiPos = (phaseKey, uiPos) =>
    phasesRef.child(`${phaseKey}/uiPos`).set(uiPos);

  const swapUiPositionsWithPhase = phaseToSwapWith => {
    updatePhaseUiPos(phase.key, phaseToSwapWith.uiPos);
    updatePhaseUiPos(phaseToSwapWith.key, phase.uiPos);
  };

  const moveUp = () => swapUiPositionsWithPhase(previousPhase);
  const moveDown = () => swapUiPositionsWithPhase(nextPhase);

  return [moveUp, moveDown];
};

export default usePhaseMoving;
