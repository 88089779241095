import { useContext } from 'react';
import PropTypes from 'prop-types';
import useSyncedState from 'lib/utils/useSyncedState';
import FieldContext from '../../FieldContext';
import useCurrentFieldRef from '../../useCurrentFieldRef';
import FormWrapper from './FormWrapper';
import FieldNameInput from './FieldNameInput';
import CancelButton from 'lib/components/CancelButton';
import { IconButton } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { useIntl } from 'react-intl';

const FieldNameEditor = ({ onClose, ...props }) => {
  const { field } = useContext(FieldContext);
  const [newFieldName, setNewFieldName] = useSyncedState(field.name || '');

  const currentFieldRef = useCurrentFieldRef();
  const saveNameChange = e => {
    e.preventDefault();

    currentFieldRef.child('name').set(newFieldName.trim() || null);
    onClose();
  };

  const cancelNameChange = () => {
    onClose();
    setNewFieldName(field.name || '');
  };

  const intl = useIntl();
  return (
    <FormWrapper onSubmit={saveNameChange} {...props}>
      <FieldNameInput
        placeholder={intl.formatMessage({
          id: 'editor.model_editor.field_editor.edit_name.input_placeholder'
        })}
        value={newFieldName}
        onChange={e => setNewFieldName(e.target.value)}
        onEscPress={cancelNameChange}
        autoFocus
      />
      <CancelButton
        visible={newFieldName !== (field.name || '')}
        onClick={cancelNameChange}
      />
      <IconButton edge="end" type="submit">
        <CheckIcon />
      </IconButton>
    </FormWrapper>
  );
};

FieldNameEditor.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default FieldNameEditor;
