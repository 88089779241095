/* eslint-disable react/jsx-no-target-blank */

import PropTypes from 'prop-types';
import ReleaseWrapper from './ReleaseWrapper';
import ReleaseTitle from './ReleaseTitle';
import ReleaseSummary from './ReleaseSummary';
import ViewReleaseButton from './ViewReleaseButton';
import { FormattedMessage } from 'react-intl';

const Release = ({ release, ...props }) => (
  <ReleaseWrapper>
    <ReleaseTitle>{release.title}</ReleaseTitle>
    <ReleaseSummary>{release.summary}</ReleaseSummary>
    <ViewReleaseButton
      component="a"
      color="primary"
      href={release.link}
      target="_blank"
    >
      <FormattedMessage id="header.options_menu.releases_overview.release.view_button" />
    </ViewReleaseButton>
  </ReleaseWrapper>
);

Release.propTypes = {
  release: PropTypes.shape({
    title: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  }).isRequired
};

export default Release;
