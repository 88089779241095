import styled from '@emotion/styled';

const LevelWrapper = styled('section')({
  marginLeft: -12.5,
  marginRight: -12.5,
  marginBottom: 50,
  display: 'flex'
});

export default LevelWrapper;
