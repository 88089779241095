import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const WrapperForm = styled('form')(({ fullWidth }) => ({
  minWidth: 0,
  flex: fullWidth ? 1 : null,
  display: 'flex',
  alignItems: 'center'
}));

WrapperForm.propTypes = {
  fullWidth: PropTypes.bool.isRequired
};

export default WrapperForm;
