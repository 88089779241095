import { useContext } from 'react';
import PhaseContext from '../../../PhaseContext';
import PhaseContentGroupContext from '../../../PhaseContentGroupContext';
import FieldContext from '../../FieldContext';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const usePossibleMovesState = () => {
  const { previousPhase, nextPhase } = useContext(PhaseContext);
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const { previousField, nextField } = useContext(FieldContext);

  const moveUpImpossible =
    currentPhaseContentGroup !== PhaseContentGroups.END_CONTENT &&
    !previousPhase &&
    !previousField;

  const moveDownImpossible =
    currentPhaseContentGroup === PhaseContentGroups.END_CONTENT &&
    !nextPhase &&
    !nextField;

  const canMoveUp = !moveUpImpossible;
  const canMoveDown = !moveDownImpossible;
  return [canMoveUp, canMoveDown];
};

export default usePossibleMovesState;
