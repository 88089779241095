import styled from '@emotion/styled';
import { Select } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const ResponsiveSelect = styled(Select)({
  width: 168,
  [mediaQuery(420)]: {
    width: '100%'
  }
});

const StyledSelect = props => (
  <ResponsiveSelect
    MenuProps={{
      MenuListProps: {
        style: {
          minWidth: 256
        }
      }
    }}
    {...props}
  />
);

export default StyledSelect;
