import PropTypes from 'prop-types';
import detachPart from 'lib/firebase/rubricsPortfolioTemplates/detachPart';
import { Tooltip } from '@material-ui/core';
import ActionButton from '../PortfolioTemplatesEditorActionButtons/ActionButton';
import { LinkOff as RemoveAttachmentIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const RemoveLearningGoalsAttachment = ({ part, subtopicKey, ...props }) => {
  const removeAttachment = () => detachPart(part, subtopicKey);
  return (
    <Tooltip
      title={
        <FormattedMessage
          id="portfolio_template_editor.part.remove_learning_goals_attachment.tooltip"
          values={{
            partName: part.title
          }}
        />
      }
    >
      <ActionButton onClick={removeAttachment} {...props}>
        <RemoveAttachmentIcon />
      </ActionButton>
    </Tooltip>
  );
};

RemoveLearningGoalsAttachment.propTypes = {
  part: PropTypes.shape({
    title: PropTypes.string.isRequired
  }).isRequired,
  subtopicKey: PropTypes.string.isRequired
};

export default RemoveLearningGoalsAttachment;
