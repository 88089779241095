import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import HasMovedToSecondStepContext from './HasMovedToSecondStepContext';

const StyledStepsWrapper = styled('section')(
  ({ theme, hasMovedToSecondStep }) => ({
    width: '200%',
    transform: hasMovedToSecondStep ? 'translateX(-50%)' : 'none',
    display: 'flex',
    transition: theme.transitions.create()
  })
);

const DialogStepsWrapper = ({ hasMovedToSecondStep, ...props }) => (
  <HasMovedToSecondStepContext.Provider value={hasMovedToSecondStep}>
    <StyledStepsWrapper
      hasMovedToSecondStep={hasMovedToSecondStep}
      {...props}
    />
  </HasMovedToSecondStepContext.Provider>
);

DialogStepsWrapper.propTypes = {
  hasMovedToSecondStep: PropTypes.bool.isRequired
};

export default DialogStepsWrapper;
