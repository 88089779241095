import Modes from 'lib/constants/Modes';
import Roles from 'lib/constants/Roles';

const modeRoleRestrictions = {
  [Modes.COACH]: [Roles.COACH, Roles.EDITOR, Roles.HALF_ADMIN, Roles.ADMIN],
  [Modes.EDITOR]: [Roles.EDITOR, Roles.HALF_ADMIN, Roles.ADMIN],
  [Modes.ADMIN]: [Roles.HALF_ADMIN, Roles.ADMIN],
  [Modes.PLATFORM_SETTINGS]: [Roles.ADMIN]
};

export default modeRoleRestrictions;
