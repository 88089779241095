import App from 'lib/App';
import InfoWrapper from './InfoWrapper';
import UserAvatar from 'lib/components/UserAvatar';
import UserName from './UserName';
import UserEmail from './UserEmail';

const CurrentUserInfo = props => {
  const currentUser = App.authentication.useCurrentUser();
  return (
    <InfoWrapper {...props}>
      <UserAvatar user={currentUser} marginRight={16} />
      <div>
        <UserName>{currentUser.name}</UserName>
        <UserEmail>{currentUser.email}</UserEmail>
      </div>
    </InfoWrapper>
  );
};

export default CurrentUserInfo;
