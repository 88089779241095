import { useState } from 'react';
import PropTypes from 'prop-types';
import GapminderAnimationWrapper from './GapminderAnimationWrapper';
import ColorLegendAnimationWrapper from './ColorLegendAnimationWrapper';
import Gapminder from './Gapminder';
import ColorLegend from './ColorLegend';

const GapminderLegend = ({ portfolioHistoryLoading, ...props }) => {
  const [gapminderOpen, setGapminderOpen] = useState(false);

  if (gapminderOpen) {
    return (
      <GapminderAnimationWrapper key="gapminder-wrapper">
        <Gapminder onClose={() => setGapminderOpen(false)} {...props} />
      </GapminderAnimationWrapper>
    );
  }

  return (
    <ColorLegendAnimationWrapper key="color-legend-wrapper">
      <ColorLegend
        portfolioHistoryLoading={portfolioHistoryLoading}
        onOpenGapminder={() => setGapminderOpen(true)}
      />
    </ColorLegendAnimationWrapper>
  );
};

GapminderLegend.propTypes = {
  portfolioHistoryLoading: PropTypes.bool.isRequired
};

export default GapminderLegend;
