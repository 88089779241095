import { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'lib/components/Dialog';
import SupportTypes from './SupportTypes';
import StepsWrapper from './StepsWrapper';
import SupportTypeSelector from './SupportTypeSelector';
import SupportContentRenderer from './SupportContentRenderer';
import SupportDialogActions from './SupportDialogActions';
import BackButton from './BackButton';
import DoneButton from './DoneButton';

const dontShowScrollbarWhenAnimatingToNextStepStyles = {
  overflowX: 'hidden'
};

const SupportDialog = ({ onClose, ...props }) => {
  const [hasMovedToSecondStep, setHasMovedToSecondStep] = useState(false);
  const [selectedSupportType, setSelectedSupportType] = useState(null);
  const [customDialogActions, setCustomDialogActions] = useState(null);

  const goBack = () => {
    setHasMovedToSecondStep(false);
    setSelectedSupportType(null);
    setCustomDialogActions(null);
  };

  return (
    <Dialog
      onClose={onClose}
      onEnterClose={onClose}
      disableEnterClose={selectedSupportType === SupportTypes.FEEDBACK}
      PaperProps={{
        style: dontShowScrollbarWhenAnimatingToNextStepStyles
      }}
      {...props}
    >
      <StepsWrapper hasMovedToSecondStep={hasMovedToSecondStep}>
        <SupportTypeSelector
          onSelectSupportType={supportType => {
            setSelectedSupportType(supportType);
            setHasMovedToSecondStep(true);
          }}
        />
        <SupportContentRenderer
          selectedSupportType={selectedSupportType}
          setDialogActions={setCustomDialogActions}
          onGoBack={goBack}
          onDialogClose={onClose}
        />
      </StepsWrapper>
      <SupportDialogActions>
        {hasMovedToSecondStep && <BackButton onClick={goBack} />}
        {customDialogActions || <DoneButton onClick={onClose} />}
      </SupportDialogActions>
    </Dialog>
  );
};

SupportDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default SupportDialog;
