import { Button } from '@material-ui/core';
import { KeyboardArrowRight as ArrowRightIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const OpenSloButton = props => (
  <Button endIcon={<ArrowRightIcon />} {...props}>
    <FormattedMessage id="slo_system.slo.open_button" />
  </Button>
);

export default OpenSloButton;
