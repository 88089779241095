import PropTypes from 'prop-types';
import { Dialog } from '@material-ui/core';
import TitleWithSubtopicIcon from './TitleWithSubtopicIcon';
import LevelDialogContent from './LevelDialogContent';
import InnerContentWrapper from './InnerContentWrapper';
import LevelWrapper from './LevelWrapper';

const LevelDialog = ({ open, onClose, editor, children, ...props }) => (
  <Dialog
    open={open}
    onClose={onClose}
    PaperProps={{
      style: {
        marginLeft: 15,
        marginRight: 15,
        width: '100%',
        maxWidth: '100%'
      }
    }}
    disableBackdropClick={editor}
    disableEscapeKeyDown={editor}
    maxWidth={false}
    fullWidth
    {...props}
  >
    {children}
  </Dialog>
);

LevelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editor: PropTypes.bool,
  children: PropTypes.node
};

LevelDialog.defaultProps = {
  editor: false
};

LevelDialog.Title = TitleWithSubtopicIcon;
LevelDialog.Content = LevelDialogContent;
LevelDialog.InnerContentWrapper = InnerContentWrapper;
LevelDialog.LevelWrapper = LevelWrapper;

export default LevelDialog;
