import { useContext } from 'react';
import PropTypes from 'prop-types';
import SloContext from '../SloContext';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import SloListEntryWrapper from '../SloListEntryWrapper';
import SloName from './SloName';
import SloUserAvatar from './SloUserAvatar';
import OpenSloButton from './OpenSloButton';

const SloListEntry = ({ isActiveSlo, withGrouping, onOpenSlo, ...props }) => {
  const slo = useContext(SloContext);
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const shouldShowUserAvatar = slo.user !== portfolioUserUsername;

  return (
    <SloListEntryWrapper
      onClick={onOpenSlo}
      withGrouping={withGrouping}
      clickable
      {...props}
    >
      <SloName faded={!isActiveSlo}>{slo.name}</SloName>
      {shouldShowUserAvatar && <SloUserAvatar />}
      <OpenSloButton color={isActiveSlo ? 'primary' : 'default'} />
    </SloListEntryWrapper>
  );
};

SloListEntry.propTypes = {
  isActiveSlo: PropTypes.bool.isRequired,
  onOpenSlo: PropTypes.func.isRequired,
  withGrouping: PropTypes.bool.isRequired
};

export default SloListEntry;
