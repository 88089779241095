import { useContext } from 'react';
import PropTypes from 'prop-types';
import useIsFullWidthOnMobile from './useIsFullWidthOnMobile';
import StyledFieldWrapper from './StyledFieldWrapper';
import PortfolioIsReadOnlyContext from 'lib/contexts/PortfolioIsReadOnlyContext';
import CoachFeedback from './CoachFeedback';

const FieldWrapper = ({ noCoachFeedback, children, ...props }) => {
  const portfolioIsReadOnly = useContext(PortfolioIsReadOnlyContext);
  const isFullWidthOnMobile = useIsFullWidthOnMobile();
  return (
    <StyledFieldWrapper
      className={isFullWidthOnMobile ? 'mobile-full-width' : null}
      {...props}
    >
      {children}
      {!noCoachFeedback && !portfolioIsReadOnly && <CoachFeedback />}
    </StyledFieldWrapper>
  );
};

FieldWrapper.propTypes = {
  noCoachFeedback: PropTypes.bool.isRequired
};

export default FieldWrapper;
