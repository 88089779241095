import DangerColorThemeProvider from 'lib/components/DangerColorThemeProvider';
import { CircularProgress } from '@material-ui/core';

const DangerColorCircularProgress = props => (
  <DangerColorThemeProvider>
    <CircularProgress color="primary" size={40} {...props} />
  </DangerColorThemeProvider>
);

export default DangerColorCircularProgress;
