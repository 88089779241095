import blue from '@material-ui/core/colors/blue';
import grey from '@material-ui/core/colors/grey';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import common from '@material-ui/core/colors/common';
import { darken } from 'lib/styling/colors';

const lightPalette = {
  primary: blue,
  secondary: {
    main: grey[600]
  },
  warning: {
    main: orange[700]
  },
  danger: red[500],
  background: {
    default: '#F6F7FB',
    shaded: '#EDEFF7',
    border: '#DCE0EF'
  },
  sliders: {
    student: lightBlue[300],
    coach: purple[500],
    both: indigo[800]
  },
  partFocusColor: orange[500],
  partFocusTextColor: common.white,
  increaseContrastWithBackground: (color, percentage) =>
    darken(color, percentage)
};

export default lightPalette;
