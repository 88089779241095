import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const GroupListWrapper = styled('section')({
  marginLeft: -12,
  marginRight: -12,
  width: 'calc(100% + 24px)',
  display: 'flex',
  flexWrap: 'wrap',
  [mediaQuery(1024)]: {
    display: 'block'
  }
});

export default GroupListWrapper;
