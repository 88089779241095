import App from 'lib/App';
import StyledWrapper from './StyledWrapper';

const SettingsBarWrapper = props => {
  const isDisplayingChallengePortfolio = App.navigation.useIsDisplayingChallengePortfolio();
  return (
    <StyledWrapper
      isDisplayingChallengePortfolio={isDisplayingChallengePortfolio}
      {...props}
    />
  );
};

export default SettingsBarWrapper;
