import { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import PhaseContext from '../../PhaseContext';
import UnreadCoachCommentsBadgeAndHandling from './UnreadCoachCommentsBadgeAndHandling';
import ChallengeCardWithSearchMatching from './ChallengeCardWithSearchMatching';
import Phases from 'lib/constants/Phases';
import ContentWrapper from './ContentWrapper';
import ChallengeName from './ChallengeName';
import PhaseText from './PhaseText';
import UserAvatars from './UserAvatars';
import PlannedEndDatePreview from './PlannedEndDatePreview';

const ChallengeCard = forwardRef(
  ({ challenge, asTask, withPhaseText, collapsed, onClick, ...props }, ref) => {
    const phase = useContext(PhaseContext);
    return (
      <UnreadCoachCommentsBadgeAndHandling
        challenge={challenge}
        asTask={asTask}
        collapsed={collapsed}
      >
        <ChallengeCardWithSearchMatching
          challengeName={challenge.name}
          big={phase !== Phases.DONE || withPhaseText}
          collapsed={collapsed}
          color={challenge.color}
          onClick={onClick}
          disabled={collapsed}
          ref={ref}
          {...props}
        >
          <ContentWrapper hidden={collapsed}>
            <ChallengeName challenge={challenge} />
            {withPhaseText && <PhaseText challenge={challenge} />}
            {phase !== Phases.DONE &&
              challenge.plannedEndDate &&
              !withPhaseText && (
                <PlannedEndDatePreview
                  date={challenge.plannedEndDate}
                  challengeColor={challenge.color}
                />
              )}
            <UserAvatars challenge={challenge} asTask={asTask} />
          </ContentWrapper>
        </ChallengeCardWithSearchMatching>
      </UnreadCoachCommentsBadgeAndHandling>
    );
  }
);

ChallengeCard.propTypes = {
  challenge: PropTypes.shape({
    name: PropTypes.string.isRequired,
    plannedEndDate: PropTypes.number,
    color: PropTypes.string
  }).isRequired,
  asTask: PropTypes.bool.isRequired,
  withPhaseText: PropTypes.bool.isRequired,
  collapsed: PropTypes.bool.isRequired,
  dragging: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ChallengeCard;
