import PropTypes from 'prop-types';
import IndicatorWrapper from './IndicatorWrapper';
import {
  KeyboardArrowLeft as ArrowLeftIcon,
  KeyboardArrowRight as ArrowRightIcon
} from '@material-ui/icons';

const OverflowIndicator = ({ side, ...props }) => (
  <IndicatorWrapper side={side} {...props}>
    {side === 'left' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
  </IndicatorWrapper>
);

OverflowIndicator.propTypes = {
  side: PropTypes.oneOf(['left', 'right']).isRequired
};

export default OverflowIndicator;
