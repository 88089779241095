import styled from '@emotion/styled';
import mediaQuery from 'lib/styling/mediaQuery';

const LeftContent = styled('div')({
  marginRight: 96,
  flex: 1,
  [mediaQuery(720)]: {
    marginRight: 56
  },
  [mediaQuery(640)]: {
    marginRight: 0
  }
});

export default LeftContent;
