/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useLivePortfolio from './useLivePortfolio';
import { NOW } from '../index';

const useSelectedPortfolio = (
  portfolioHistory,
  timestamps,
  selectedYear,
  selectedWeek
) => {
  const [livePortfolioLoading, livePortfolio] = useLivePortfolio();

  const [selectedPortfolio, setSelectedPortfolio] = useState(null);
  const [snapshotTimestamp, setSnapshotTimestamp] = useState(Date.now());

  useEffect(() => {
    const newSnapshotTimestamp = timestamps[selectedYear][selectedWeek];
    setSnapshotTimestamp(newSnapshotTimestamp);

    if (newSnapshotTimestamp === NOW) {
      setSelectedPortfolio(livePortfolio);
      return;
    }

    const snapshot = portfolioHistory?.[newSnapshotTimestamp];
    setSelectedPortfolio(snapshot);
  }, [livePortfolio, timestamps, selectedYear, selectedWeek]);

  return [livePortfolioLoading, selectedPortfolio, snapshotTimestamp];
};

export default useSelectedPortfolio;
