import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';

const StyledIconButton = styled(IconButton)({
  marginTop: -6,
  marginBottom: -6,
  marginLeft: 6,
  padding: 6
});

export default StyledIconButton;
