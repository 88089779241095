import { useContext } from 'react';
import ModelTypeContext from '../../../../../ModelTypeContext';
import { ref } from 'lib/firebase';
import PhaseContext from '../../../../PhaseContext';
import useCurrentFieldRef from '../../../useCurrentFieldRef';
import FieldContext from '../../../FieldContext';
import flattenFirebaseList from 'lib/firebase/flattenFirebaseList';
import sortByUiPos from 'lib/utils/sortByUiPos';
import PhaseContentGroups from 'lib/constants/challengeModel/PhaseContentGroups';

const useCurrentFieldPhaseMoving = () => {
  const modelType = useContext(ModelTypeContext);
  const phasesRef = ref(`models/${modelType}/phases`);

  const { previousPhase, nextPhase } = useContext(PhaseContext);

  const currentFieldRef = useCurrentFieldRef();
  const { field } = useContext(FieldContext);

  const moveCurrentFieldToPreviousPhase = () => {
    currentFieldRef.remove();

    const previousPhaseFields = flattenFirebaseList(
      previousPhase[PhaseContentGroups.END_CONTENT]
    ).sort(sortByUiPos);
    const previousPhaseFieldsHighestUiPos =
      previousPhaseFields.reverse()[0]?.uiPos || 0;

    const newFieldUiPos = previousPhaseFieldsHighestUiPos + 1;
    phasesRef
      .child(previousPhase.key)
      .child(PhaseContentGroups.END_CONTENT)
      .child(field.key)
      .set({
        ...field,
        key: null,
        uiPos: newFieldUiPos
      });
  };

  const moveCurrentFieldToNextPhase = () => {
    currentFieldRef.remove();

    const nextPhaseFields = flattenFirebaseList(
      nextPhase[PhaseContentGroups.LEFT_CONTENT]
    ).sort(sortByUiPos);
    const nextPhaseFieldsLowestUiPos = nextPhaseFields[0]?.uiPos || 2;

    const newFieldUiPos = nextPhaseFieldsLowestUiPos / 2;
    phasesRef
      .child(nextPhase.key)
      .child(PhaseContentGroups.LEFT_CONTENT)
      .child(field.key)
      .set({
        ...field,
        key: null,
        uiPos: newFieldUiPos
      });
  };

  return [moveCurrentFieldToPreviousPhase, moveCurrentFieldToNextPhase];
};

export default useCurrentFieldPhaseMoving;
