import { useContext } from 'react';
import SloContext from '../../../SloContext';
import { safeKeys } from 'lib/utils/safeObjectFunctions';
import PretendDonePhaseForCorrectChallengeDisplay from './PretendDonePhaseForCorrectChallengeDisplay';
import AttachedChallengesWrapper from './AttachedChallengesWrapper';
import WithDetachChallengeButton from './WithDetachChallengeButton';
import WithChallengeExistenceVerification from './WithChallengeExistenceVerification';
import Challenge from 'components/ChallengePortfolio/NormalView/Challenge';

const AttachedChallenges = () => {
  const slo = useContext(SloContext);
  const attachedChallengesKeys = safeKeys(slo.attachedChallenges);

  if (!attachedChallengesKeys) {
    return null;
  }

  return (
    <PretendDonePhaseForCorrectChallengeDisplay>
      <AttachedChallengesWrapper>
        {attachedChallengesKeys.map(challengeKey => (
          <WithDetachChallengeButton
            challengeKey={challengeKey}
            key={challengeKey}
          >
            <WithChallengeExistenceVerification challengeKey={challengeKey}>
              <Challenge challengeKey={challengeKey} disableDrag />
            </WithChallengeExistenceVerification>
          </WithDetachChallengeButton>
        ))}
      </AttachedChallengesWrapper>
    </PretendDonePhaseForCorrectChallengeDisplay>
  );
};

export default AttachedChallenges;
