import styled from '@emotion/styled';
import { IconButton } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';
import { MoreVert as MoreIcon } from '@material-ui/icons';

const StyledButton = styled(IconButton)({
  marginLeft: 'auto',
  display: 'none',
  [mediaQuery(640)]: {
    display: 'inline-flex'
  }
});

const ChallengeActionsButton = props => (
  <StyledButton {...props}>
    <MoreIcon />
  </StyledButton>
);

export default ChallengeActionsButton;
