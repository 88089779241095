const getInitialProgress = (portfolio, partId, partSubpath) => {
  const partProgress = portfolio?.progress?.[partId];

  if (partSubpath) {
    return partProgress?.[partSubpath];
  }

  return partProgress;
};

export default getInitialProgress;
