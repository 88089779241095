import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import stripProps from 'lib/utils/stripProps';
import { IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import mediaQuery from 'lib/styling/mediaQuery';
import common from '@material-ui/core/colors/common';
import addHighContrastStyles from 'lib/styling/addHighContrastStyles';
import { useTheme } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const StyledCloseButton = styled(
  motion.custom(stripProps(IconButton, 'taskBoardOpen'))
)(
  ({ theme, taskBoardOpen }) => ({
    position: 'absolute',
    top: 0,
    left: 102,
    transition: theme.transitions.create(),
    [mediaQuery(960)]: {
      left: 32
    },
    [mediaQuery(768)]: {
      top: 8,
      left: taskBoardOpen ? 8 : null
    },
    [mediaQuery(560)]: {
      left: 8
    }
  }),
  addHighContrastStyles({
    backgroundColor: common.black,
    border: `2px solid ${common.white}`,
    ':hover, :focus': {
      backgroundColor: common.black,
      transform: 'scale(1.05) !important'
    }
  })
);

const CloseButton = props => {
  const theme = useTheme();
  const whiteTheme = theme.extend({
    palette: {
      primary: {
        main: common.white
      }
    }
  });

  return (
    <MuiThemeProvider theme={whiteTheme}>
      <StyledCloseButton
        color="primary"
        initial={{
          x: 10,
          opacity: 0
        }}
        animate={{
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.1,
            delay: 0.3
          }
        }}
        {...props}
      >
        <CloseIcon />
      </StyledCloseButton>
    </MuiThemeProvider>
  );
};

CloseButton.propTypes = {
  taskBoardOpen: PropTypes.bool.isRequired
};

export default CloseButton;
