import useUserRowKeys from '../../useUserRowKeys';
import useRowsVisibilityState from './useRowsVisibilityState';
import RowsVisibilityStateContext from './RowsVisibilityStateContext';
import NoPlannedChallengesInVisibleWeekRangeMessage from './NoPlannedChallengesInVisibleWeekRangeMessage';
import RowWithChallengePlanning from './RowWithChallengePlanning';

const RowsWithChallengePlanning = () => {
  const rowKeys = useUserRowKeys();
  const flattenedRowKeys = rowKeys.map(([rowKey]) => rowKey);

  const [noRowsVisible, registerRowVisibility] = useRowsVisibilityState();

  return (
    <RowsVisibilityStateContext.Provider value={{ registerRowVisibility }}>
      {flattenedRowKeys.map(rowKey => (
        <RowWithChallengePlanning rowKey={rowKey} key={rowKey} />
      ))}
      {noRowsVisible && <NoPlannedChallengesInVisibleWeekRangeMessage />}
    </RowsVisibilityStateContext.Provider>
  );
};

export default RowsWithChallengePlanning;
