import { useContext } from 'react';
import useCurrentPhaseRef from '../useCurrentPhaseRef';
import PhaseContentGroupContext from '../PhaseContentGroupContext';
import FieldContext from './FieldContext';

const useCurrentFieldRef = () => {
  const currentPhaseRef = useCurrentPhaseRef();
  const currentPhaseContentGroup = useContext(PhaseContentGroupContext);
  const { field } = useContext(FieldContext);

  return currentPhaseRef.child(currentPhaseContentGroup).child(field.key);
};

export default useCurrentFieldRef;
