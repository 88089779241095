import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import MenuContentBlock from './MenuContentBlock';

const MenuContent = styled('section')(({ theme, hasMovedToSubmenu }) => ({
  width: '200%',
  transform: hasMovedToSubmenu ? 'translateX(-50%)' : null,
  transition: theme.transitions.create(),
  display: 'flex'
}));

MenuContent.propTypes = {
  hasMovedToSubmenu: PropTypes.bool.isRequired
};

MenuContent.Initial = MenuContentBlock;
MenuContent.Submenu = MenuContentBlock;

export default MenuContent;
