import styled from '@emotion/styled';
import { Checkbox } from '@material-ui/core';
import mediaQuery from 'lib/styling/mediaQuery';

const UserSelectCheckbox = styled(Checkbox)({
  [mediaQuery(850)]: {
    display: 'none'
  }
});

export default UserSelectCheckbox;
