import itemMatchesVisibleGroup from '../itemMatchesVisibleGroup';
import groupFromRole from './groupFromRole';

const userCanAccessItem = (user, isTopic = false) => {
  const group = groupFromRole(user.role);
  return item =>
    itemMatchesVisibleGroup(group, isTopic)(item) ||
    userMatchesItemDynamicGroups(user, item);
};

export const userMatchesItemDynamicGroups = (user, item) => {
  if (!user.groups || !item.dynamicGroups) {
    return false;
  }

  const dynamicGroups = Object.keys(item.dynamicGroups);

  return Object.keys(user.groups).reduce(
    (hasMatch, group) => hasMatch || dynamicGroups.includes(group),
    false
  );
};

export default userCanAccessItem;
