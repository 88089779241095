import { safeEntries } from 'lib/utils/safeObjectFunctions';

const flattenFirebaseList = listObject => {
  const entries = safeEntries(listObject);

  if (entries.length === 0) {
    return [];
  }

  return entries.map(([key, item]) => ({
    key,
    ...item
  }));
};

export default flattenFirebaseList;
