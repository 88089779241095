import { useContext } from 'react';
import PropTypes from 'prop-types';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import { ref } from 'lib/firebase';
import PillButtons from '../../../PillButtons';
import { Delete as DeleteIcon } from '@material-ui/icons';

const DeleteAttachment = ({ challengeKey, levelKey, ...props }) => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );

  const deleteAttachment = () => {
    ref(
      `portfolios/${portfolioUserUsername}/challengeProof/${levelKey}/${challengeKey}`
    ).remove();
  };

  return (
    <PillButtons.Button
      onClick={deleteAttachment}
      rightHalf
      dangerous
      {...props}
    >
      <DeleteIcon />
    </PillButtons.Button>
  );
};

DeleteAttachment.propTypes = {
  challengeKey: PropTypes.string.isRequired,
  levelKey: PropTypes.string.isRequired
};

export default DeleteAttachment;
