const currentUserIsViewingChallengeAsCoach = (
  currentUserUsername,
  challenge
) => {
  if (!currentUserUsername || !challenge) {
    return true;
  }

  return (
    challenge.originalOwner !== currentUserUsername &&
    !challenge.sharedWith?.[currentUserUsername]
  );
};

export default currentUserIsViewingChallengeAsCoach;
