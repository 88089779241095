import { useContext } from 'react';
import PropTypes from 'prop-types';
import fixChallengeDates from 'lib/utils/fixChallengeDates';
import { ref, challengeRef } from 'lib/firebase';
import CreateItem from './CreateItem';
import { FormattedMessage } from 'react-intl';
import PhaseContext from '../../../PhaseContext';

const CreateTask = ({ challenge, ...props }) => {
  const phase = useContext(PhaseContext);
  return (
    <CreateItem
      tooltip={<FormattedMessage id="portfolio.create_task.tooltip" />}
      onSave={name => {
        const task = fixChallengeDates({
          name,
          phase,
          challenge: challenge.key,
          creationDate: Date.now()
        });

        const key = ref('tasks').push(task).key;
        challengeRef(challenge.key).child(`tasks/${phase}/${key}`).set(true);
      }}
      isCreateTaskButton
      {...props}
    />
  );
};

CreateTask.propTypes = {
  challenge: PropTypes.shape({
    key: PropTypes.string.isRequired
  }).isRequired
};

export default CreateTask;
