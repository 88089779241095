import { useContext } from 'react';
import PortfolioUserContext from 'lib/contexts/PortfolioUserContext';
import App from 'lib/App';
import useFirebaseValue from 'lib/firebase/useFirebaseValue';
import { ref } from 'lib/firebase';

const usePortfolio = () => {
  const portfolioUser = useContext(PortfolioUserContext);
  const portfolioUserUsername = App.authentication.useUserUsername(
    portfolioUser
  );
  return useFirebaseValue(ref(`portfolios/${portfolioUserUsername}`));
};

export default usePortfolio;
