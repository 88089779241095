/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import useSelectedTheme from 'lib/utils/useSelectedTheme';
import useDetermineDarkModeAutomatically from 'lib/utils/useDetermineDarkModeAutomatically';
import { FormControl, FormLabel, RadioGroup } from '@material-ui/core';
import ThemeOption from './ThemeOption';
import Themes from 'lib/constants/Themes';
import { FormattedMessage } from 'react-intl';

const APPEARANCE_DEVICE_THEME = 'APPEARANCE_DEVICE_THEME';

const AppearanceSelector = props => {
  const [selectedTheme, setSelectedTheme] = useSelectedTheme();
  const [
    determineDarkModeAutomatically,
    setDetermineDarkModeAutomatically
  ] = useDetermineDarkModeAutomatically();

  const initialThemeValue = determineDarkModeAutomatically
    ? APPEARANCE_DEVICE_THEME
    : selectedTheme;
  const [appearanceValue, setAppearanceValue] = useState(initialThemeValue);

  useEffect(() => {
    if (appearanceValue === APPEARANCE_DEVICE_THEME) {
      setDetermineDarkModeAutomatically(true);
      return;
    }

    setDetermineDarkModeAutomatically(false);
    setSelectedTheme(appearanceValue);
  }, [appearanceValue, selectedTheme, determineDarkModeAutomatically]);

  return (
    <FormControl component="fieldset" {...props}>
      <FormLabel component="legend">
        <FormattedMessage id="header.options_menu.display_settings.appearance.label" />
      </FormLabel>
      <RadioGroup
        name="appearance"
        value={appearanceValue}
        onChange={e => setAppearanceValue(e.target.value)}
      >
        <ThemeOption value={Themes.LIGHT} />
        <ThemeOption value={Themes.DARK} />
        <ThemeOption value={APPEARANCE_DEVICE_THEME} />
      </RadioGroup>
    </FormControl>
  );
};

export default AppearanceSelector;
