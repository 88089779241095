import { useContext } from 'react';
import SelectedPlatformContext from 'lib/contexts/SelectedPlatformContext';
import PlatformTypes from 'lib/constants/PlatformTypes';
import ManagerWrapper from './ManagerWrapper';
import Dialog from 'lib/components/Dialog';
import Space from 'lib/components/Space';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const CannotAttachPartMessage = props => {
  const [, setSelectedPlatform] = useContext(SelectedPlatformContext);
  return (
    <ManagerWrapper {...props}>
      <Space height={12} />
      <h3>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.attachment_manager.cannot_attach_message.title" />
      </h3>
      <Space height={12} />
      <Dialog.ContentText>
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.attachment_manager.cannot_attach_message.text" />
      </Dialog.ContentText>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setSelectedPlatform(PlatformTypes.PROGRESSMONITOR)}
      >
        <FormattedMessage id="editor.model_editor.field_editor.learning_goals_editor.manager.part.attachment_manager.cannot_attach_message.button" />
      </Button>
      <Space height={12} />
    </ManagerWrapper>
  );
};

export default CannotAttachPartMessage;
