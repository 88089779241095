import RowName from './RowName';
import SelectFieldPlaceholder from 'lib/components/SelectFieldPlaceholder';
import { FormattedMessage } from 'react-intl';

const showRowNameOrPlaceholder = selectValue => {
  if (!selectValue) {
    return (
      <SelectFieldPlaceholder>
        <FormattedMessage id="portfolio.challenge_creation_flow.row_attachment.type_options.existing_row.row_select.placeholder" />
      </SelectFieldPlaceholder>
    );
  }

  return <RowName rowKey={selectValue} />;
};

export default showRowNameOrPlaceholder;
