import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import purple from '@material-ui/core/colors/purple';
import orange from '@material-ui/core/colors/orange';
import teal from '@material-ui/core/colors/teal';
import grey from '@material-ui/core/colors/grey';
import pink from '@material-ui/core/colors/pink';
import indigo from '@material-ui/core/colors/indigo';
import brown from '@material-ui/core/colors/brown';
import deepPurple from '@material-ui/core/colors/deepPurple';

const colors = [
  red,
  blue,
  purple,
  orange,
  teal,
  grey,
  pink,
  indigo,
  brown,
  deepPurple
];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export default getRandomColor;
