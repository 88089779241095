import PropTypes from 'prop-types';
import StyledCollapseButton from './StyledCollapseButton';
import { KeyboardArrowDown as ArrowDownIcon } from '@material-ui/icons';

const CollapseButton = props => (
  <StyledCollapseButton {...props}>
    <ArrowDownIcon />
  </StyledCollapseButton>
);

CollapseButton.propTypes = {
  collapsed: PropTypes.bool.isRequired
};

export default CollapseButton;
