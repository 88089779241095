import { Button } from '@material-ui/core';
import { Comment as FeedbackIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const FeedbackButton = props => (
  <Button startIcon={<FeedbackIcon />} color="secondary" {...props}>
    <FormattedMessage id="slo_system.slo_period.feedback.button" />
  </Button>
);

export default FeedbackButton;
