import DialogButton from './DialogButton';
import { FormattedMessage } from 'react-intl';

const DialogDoneButton = props => (
  <DialogButton color="primary" {...props}>
    <FormattedMessage id="dialogs.actions.done" />
  </DialogButton>
);

export default DialogDoneButton;
