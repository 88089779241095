import { useState } from 'react';
import PropTypes from 'prop-types';
import CreateButton from './CreateButton';
import Prompt from 'lib/components/Prompt';
import Dialog from 'lib/components/Dialog';
import { FormattedMessage } from 'react-intl';
import FormattedMessageWithChallengeNaming from 'lib/components/FormattedMessageWithChallengeNaming';

const CreateItem = ({ tooltip, onSave, isCreateTaskButton, ...props }) => {
  const [createPromptOpen, setCreatePromptOpen] = useState(false);
  return (
    <>
      <CreateButton
        tooltip={tooltip}
        onClick={() => setCreatePromptOpen(true)}
        isCreateTaskButton={isCreateTaskButton}
      />
      <Prompt
        open={createPromptOpen}
        onOk={name => {
          setCreatePromptOpen(false);
          onSave(name);
        }}
        OkButton={Dialog.Actions.CreateButton}
        onCancel={() => setCreatePromptOpen(false)}
        emptyError="prompt.errors.name_empty"
      >
        <Dialog.Title>
          <FormattedMessageWithChallengeNaming
            id={
              isCreateTaskButton
                ? 'portfolio.create_task.title'
                : 'portfolio.create_challenge.title'
            }
          />
        </Dialog.Title>
        <Prompt.TextField
          label={
            <FormattedMessage
              id={
                isCreateTaskButton
                  ? 'portfolio.create_task.input_label'
                  : 'portfolio.create_challenge.input_label'
              }
            />
          }
        />
      </Prompt>
    </>
  );
};

CreateItem.propTypes = {
  tooltip: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  isCreateTaskButton: PropTypes.bool
};

CreateItem.defaultProps = {
  isCreateTaskButton: false
};

export default CreateItem;
