import styled from '@emotion/styled';

const AllowedEmailText = styled('p')(({ theme }) => ({
  marginTop: 24,
  marginBottom: 0,
  maxWidth: 350,
  color: theme.palette.text.secondary,
  fontFamily: 'CircularStd',
  fontSize: 15,
  lineHeight: 1.5,
  strong: {
    fontWeight: 500
  }
}));

export default AllowedEmailText;
