import { useContext } from 'react';
import PortfolioTemplatesContext from 'lib/contexts/PortfolioTemplatesContext';
import VisiblePortfolioTemplatesGroupContext from '../VisiblePortfolioTemplatesGroupContext';
import UseAggressiveRubricCollapsingContext from './UseAggressiveRubricCollapsingContext';
import EditorTopic from './EditorTopic';
import AddTopic from './AddTopic';
import sortByUiPos from 'lib/utils/sortByUiPos';
import itemMatchesVisibleGroup from 'lib/rubricsPortfolioUtils/itemMatchesVisibleGroup';
import { safeValues } from 'lib/utils/safeObjectFunctions';
import getHighestUiPosFromSortedItems from 'pages/EditorPage/utils/getHighestUiPosFromSortedItems';

const PortfolioTemplatesEditor = props => {
  const portfolioTemplates = useContext(PortfolioTemplatesContext);
  const allTopics = safeValues(portfolioTemplates?.topics).sort(sortByUiPos);

  const visibleGroup = useContext(VisiblePortfolioTemplatesGroupContext);
  const visibleTopics = allTopics.filter(
    itemMatchesVisibleGroup(visibleGroup, true)
  );

  const highestTopicUiPos = getHighestUiPosFromSortedItems(allTopics);

  return (
    <UseAggressiveRubricCollapsingContext.AutomatedProvider>
      {visibleTopics.map((topic, i) => (
        <EditorTopic
          topic={topic}
          previousTopic={visibleTopics[i - 1] || {}}
          nextTopic={visibleTopics[i + 1] || {}}
          highestUiPos={highestTopicUiPos}
          editor
          key={topic.id}
          {...props}
        />
      ))}
      <AddTopic highestTopicUiPos={highestTopicUiPos} />
    </UseAggressiveRubricCollapsingContext.AutomatedProvider>
  );
};

export default PortfolioTemplatesEditor;
